import classes from './AlternativeTaskIndicator.module.scss';
import {Typography} from '../../../../components';
import {AlternativeTaskIcon} from '../../../../icons';

export const AlternativeTaskIndicator = () => {
    return (
        <div className={classes.root}>
            <AlternativeTaskIcon className={classes.icon} />

            <Typography color="stone" transform="uppercase" hasGutters={false} weight={500} size={11} letterSpacing={1}>
                Alternative
            </Typography>
        </div>
    );
};
