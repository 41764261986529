import {createSelector} from 'reselect';

const selectActiveModals = state => state.ui.activeModals;

const createActiveModalSelector = modalKey => createSelector(
    [selectActiveModals],
    activeModals => activeModals[modalKey] ?? false,
);

const selectFormResets = state => state.ui.formResets;

const crateFormResetSelector = formKey => createSelector(
    [selectFormResets],
    formResets => formResets[formKey] ?? false,
);

const selectScrollToElements = state => state.ui.scrollToElements;

const createScrollToElementSelector = key => createSelector(
    [selectScrollToElements],
    scrollToElements => scrollToElements[key] ?? false,
);

export const UiSelectors = {
    selectActiveModals,
    createActiveModalSelector,
    selectFormResets,
    crateFormResetSelector,
    selectScrollToElements,
    createScrollToElementSelector,
};
