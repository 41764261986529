import {forwardRef} from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const CheckIcon = forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M18,3.6L5.7,15.9L0,10.2l1.5-1.4L5.7,13L16.5,2.1L18,3.6z" />
        </SvgIcon>
    );
});
