import {useRafState} from '@react-hookz/web';
import {useEffect} from 'react';

export const useScroll = ref => {
    if (process.env.NODE_ENV === 'development') {
        if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
            // eslint-disable-next-line no-console
            console.error('`useScroll` expects a single ref argument.');
        }
    }

    const [state, setState] = useRafState({
        x: 0,
        y: 0,
    });

    useEffect(() => {
        const currentRef = ref.current;

        const handler = () => {
            if (ref.current) {
                setState({
                    x: ref.current.scrollLeft,
                    y: ref.current.scrollTop,
                });
            }
        };

        if (ref.current) {
            ref.current.addEventListener('scroll', handler, {
                capture: false,
                passive: true,
            });
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handler);
            }
        };
    }, [ref, setState]);

    return state;
};
