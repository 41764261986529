import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import classes from './ResetPasswordScreen.module.scss';
import {PageHeader} from '../../../../components';
import {ResetPasswordFormSelectors} from '../../store/auth.selector';
import {ResetPasswordForm} from '../ResetPasswordForm/ResetPasswordForm';
import {SendCodeForm} from '../SendCodeForm/SendCodeForm';

export const ResetPasswordScreen = () => {
    const {t} = useTranslation('featuresAuth');

    const step = useSelector(ResetPasswordFormSelectors.selectStep);

    return (
        <div className={classes.root}>
            <PageHeader className={classes.header} subtitle={t('resetPasswordScreen.pageHeader.subtitle')} align="center">
                {t('resetPasswordScreen.pageHeader.title')}
            </PageHeader>

            {step === 1 ? <SendCodeForm /> : <ResetPasswordForm />}
        </div>
    );
};
