import {combineReducers} from 'redux';
import {CurrentTaskActionTypes} from './current-task.action-type';
import {createReducer} from '../../../utils';

export const currentTaskReducer = combineReducers({
    bedVolume: createReducer(2, CurrentTaskActionTypes.SET_BED_VOLUME),
    currentTaskId: createReducer(null, CurrentTaskActionTypes.SET_CURRENT_TASK_ID),
    currentTaskVariationId: createReducer(null, CurrentTaskActionTypes.SET_CURRENT_TASK_VARIATION_ID),
    currentPlaylistTaskKey: createReducer(null, CurrentTaskActionTypes.SET_CURRENT_PLAYLIST_TASK_KEY),
    loadedElements: createReducer([], CurrentTaskActionTypes.SET_LOADED_ELEMENTS),
    preProducedVolume: createReducer(2, CurrentTaskActionTypes.SET_PRE_PRODUCED_VOLUME),
    sfxVolume: createReducer(2, CurrentTaskActionTypes.SET_SFX_VOLUME),
    speakerText: createReducer('', CurrentTaskActionTypes.SET_SPEAKER_TEXT),
    arePreProducedElementsMuted: createReducer(false, CurrentTaskActionTypes.SET_ARE_PRE_PRODUCED_ELEMENTS_MUTED),
    areSfxElementsMuted: createReducer(false, CurrentTaskActionTypes.SET_ARE_SFX_ELEMENTS_MUTED),
    areBedElementsMuted: createReducer(false, CurrentTaskActionTypes.SET_ARE_BED_ELEMENTS_MUTED),
    isFreeModeEnabled: createReducer(false, CurrentTaskActionTypes.SET_IS_FREE_MODE_ENABLED),
    freeModeOriginalTaskVariationId: createReducer(
        false,
        CurrentTaskActionTypes.SET_FREE_MODE_ORIGINAL_TASK_VARIATION_ID,
    ),
});
