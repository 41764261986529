import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {PlayerActions} from '../../../features/player';
import recordingAlgorithm from '../../../features/recording-algorithm/RecordingAlgorithm';

export const useElementClick = ({elementType, index, elementId, title}) => {
    const dispatch = useDispatch();

    return useCallback(event => {
        switch (event.detail) {
            case 1: {
                if (recordingAlgorithm.isReadyToStart()) {
                    switch (elementType) {
                        case 'preProduced':
                            recordingAlgorithm.startTaskAndSchedulePreProducedAudio(index);
                            break;
                        case 'sfx':
                            recordingAlgorithm.startTaskAndScheduleSfx(index);
                            break;
                        case 'bed':
                            recordingAlgorithm.startTaskAndScheduleBed(index);
                            break;
                        default:
                            break;
                    }

                    return;
                }

                if (recordingAlgorithm.isInProgress()) {
                    switch (elementType) {
                        case 'preProduced':
                            recordingAlgorithm.playPreProducedAudio(index);
                            break;
                        case 'sfx':
                            recordingAlgorithm.playSoundEffect(index);
                            break;
                        case 'bed':
                            recordingAlgorithm.playMusicBed(index);
                            break;
                        default:
                            break;
                    }
                }

                break;
            }

            case 2: {
                if (recordingAlgorithm.isInProgress()) {
                    return;
                }

                dispatch(PlayerActions.loadItem(elementId, title));
                break;
            }

            default:
                break;
        }
    }, [elementType, index, dispatch, elementId, title]);
};
