import {Debug} from '../../../lib/debug';
import {FADE_OUT_VARS} from '../audioContextConstants';

/**
 * @typedef Metadata
 * @type {object}
 * @property {number} startAt
 * @property {number} stopAt
 * @property {Array<VolumeChangePoint>} volumeChangePoints
 */

class PlaybackNode {
    /**
     * @param {AudioContext} audioContext
     * @param {AudioBuffer} audioBuffer
     * @param {Metadata} metadata
     * @param {number} offset
     * @param {AUDIO_ELEMENT_TYPES} type
     * @param {number} id
     * @param {string} title
     * @param {number} volume
     * @param {MediaStreamAudioDestinationNode} destination
     * @param {Object} audioObject
     */
    constructor(audioContext, audioBuffer, metadata, offset, type, id, title = '', volume = 1, destination, audioObject) {
        this.#audioContext = audioContext;
        this.#sourceNode = this.#audioContext.createBufferSource();
        this.#gainNode = this.#audioContext.createGain();
        this.#offset = offset;
        this.#title = title;
        this.#id = id;

        if (audioObject) {
            this.#audioObject = audioObject;
        }

        this.#sourceNode.buffer = audioBuffer;
        this.#duration = audioBuffer.duration;

        this.#sourceNode.connect(this.#gainNode);
        this.#gainNode.connect(destination || this.#audioContext.destination);

        this.#gainNode.gain.setValueAtTime(volume, 0);

        this.#type = type;

        this.#startAt = metadata.startAt;

        if (metadata.stopAt) {
            this.#stopAt = metadata.stopAt;
        }

        this.#volumeChangePoints = metadata.volumeChangePoints;

        this.#scheduleEvents();

        Debug.debug('PlaybackNode', `Created node of type ${type} with start at ${this.#startAt} and stop at ${this.#stopAt} with offset ${offset} with initial volume of ${volume}.`);
    }

    /**
     * @type AudioContext
     */
    #audioContext;

    /**
     * @type AudioBufferSourceNode
     */
    #sourceNode;

    /**
     * @type GainNode
     */
    #gainNode;

    /**
     * @type number
     */
    #duration = 0;

    /**
     * @type number
     */
    #offset = 0;

    /**
     * @type string
     */
    #type;

    /**
     * @type number
     */
    #startAt;

    /**
     * @type number
     */
    #stopAt;

    /**
     * @type string
     */
    #title;

    /**
     * @type number
     */
    #id;

    /**
     * @type number
     */
    #index;

    /**
     * @type Array<VolumeChangePoint>
     */
    #volumeChangePoints;

    #audioObject;

    /**
     * @param {number} [playingOffset=0] playingOffset
     * @param startImmediately
     * @param overriddenStartAt
     */
    #scheduleEvents = (playingOffset = 0, startImmediately = false, overriddenStartAt = null) => {
        const adjustedStartAt = startImmediately ? this.#audioContext.currentTime : overriddenStartAt ?? this.#startAt;
        const adjustedOffset = this.#offset + playingOffset;

        let stopAt = this.#stopAt;

        if (!stopAt) {
            stopAt = this.#startAt + this.#duration - this.#offset;
        }

        if (playingOffset > 0) {
            const remainingDuration = this.#duration - playingOffset;

            if (remainingDuration > 0) {
                stopAt = adjustedStartAt + remainingDuration;
            } else {
                stopAt = adjustedStartAt;
            }
        }

        this.#sourceNode.start(adjustedStartAt, adjustedOffset);
        this.#sourceNode.stop(stopAt);

        const volumeChangeOffset = startImmediately
            ? this.#audioContext.currentTime - this.#startAt
            : overriddenStartAt
                ? overriddenStartAt - this.#startAt
                : 0;

        if (this.#volumeChangePoints && this.#volumeChangePoints.length) {
            this.#volumeChangePoints.forEach(volumeChangePoint => {
                const adjustedStartAt = volumeChangePoint.startAt + volumeChangeOffset;
                const adjustedStopAt = volumeChangePoint.stopAt + volumeChangeOffset;

                if (adjustedStartAt === this.#startAt && adjustedStopAt === this.#stopAt) {
                    // Handle initial volume value
                    this.#gainNode.gain.setValueAtTime(volumeChangePoint.fromVolume, this.#audioContext.currentTime);
                    return;
                }

                const toVolume = volumeChangePoint.toVolume === 0
                    ? FADE_OUT_VARS.FADE_OUT_END_VOLUME
                    : volumeChangePoint.toVolume;

                this.#gainNode.gain.setValueAtTime(volumeChangePoint.fromVolume, adjustedStartAt);
                this.#gainNode.gain.linearRampToValueAtTime(toVolume, adjustedStopAt);
            });
        }
    };


    get type() {
        return this.#type;
    }

    get duration() {
        return this.#duration;
    }

    get offset() {
        return this.#offset;
    }

    get startAt() {
        return this.#startAt;
    }

    set startAt(startAt) {
        this.#startAt = startAt;
    }

    get stopAt() {
        return this.#stopAt;
    }

    set stopAt(stopAt) {
        this.#stopAt = stopAt;
    }

    get title() {
        return this.#title;
    }

    set title(title) {
        this.#title = title;
    }

    get id() {
        return this.#id;
    }

    get index() {
        return this.#index;
    }

    set index(index) {
        this.#index = index;
    }

    get sourceNode() {
        return this.#sourceNode;
    }

    set volumeChangePoints(volumeChangePoints) {
        this.#volumeChangePoints = volumeChangePoints;
    }

    get audioObject() {
        return this.#audioObject;
    }

    reconnect = (seekingOffset = 0, startImmediately = false, overriddenStartAt = null) => {
        const buffer = this.#sourceNode.buffer;

        if (seekingOffset > buffer.duration) {
            return;
        }

        this.#sourceNode.disconnect(this.#gainNode);

        this.#sourceNode = this.#audioContext.createBufferSource();

        this.#sourceNode.buffer = buffer;

        this.#sourceNode.connect(this.#gainNode);

        this.#scheduleEvents(seekingOffset, startImmediately, overriddenStartAt);
    };

    reset = () => {
        const originalBuffer = this.#sourceNode.buffer;

        this.#sourceNode.disconnect(this.#gainNode);
        this.#sourceNode = this.#audioContext.createBufferSource();
        this.#sourceNode.buffer = originalBuffer;
        this.#sourceNode.connect(this.#gainNode);
    };
}


export default PlaybackNode;
