import {FADE_OUT_VARS} from './audioContextConstants';
import VolumeChangePoint from './VolumeChangePoint';

class PlaybackMetadataTransformer {
    transformPreviousElementMetadata = metadata => {
        const {
            duration,
            offset,
            nextElementStartAt,
            fadeOutDuration,
            lastRecordingStartAt,
            lastRecordingStopAt,
            duckDuration,
            duckVolume,
            startAndStopPositionsOfMusicBeds,
            previousElementObject,
        } = metadata;

        if (!duration) {
            return null;
        }

        let bumperPlayingLength = duration - offset / 1000;
        let stopAt;

        if (previousElementObject?.contentType === 'Music') {
            bumperPlayingLength = (previousElementObject?.cueOut - previousElementObject?.startNext) / 1000;
            bumperPlayingLength += 3;

            stopAt = bumperPlayingLength;
        } else {
            // Recalculate stop position
            stopAt = lastRecordingStopAt + fadeOutDuration;

            if (nextElementStartAt) {
                stopAt = nextElementStartAt + fadeOutDuration;

                if (bumperPlayingLength < stopAt) {
                    stopAt = bumperPlayingLength;
                }
            }
        }

        let fadeOutStartAt = stopAt - fadeOutDuration;

        if (previousElementObject?.contentType === 'Music') {
            fadeOutStartAt = previousElementObject?.cueOut - previousElementObject?.fadeOut;
        } else if (nextElementStartAt && fadeOutStartAt > nextElementStartAt) {
            fadeOutStartAt = nextElementStartAt;
        }

        const volumeChangePoints = [
            new VolumeChangePoint(lastRecordingStartAt, lastRecordingStartAt + duckDuration, 1, duckVolume),
            new VolumeChangePoint(fadeOutStartAt, stopAt, duckVolume, FADE_OUT_VARS.FADE_OUT_END_VOLUME),
        ];

        // Whenever music bed starts, fade out bumper
        startAndStopPositionsOfMusicBeds.forEach(musicBedData => {
            volumeChangePoints.push(new VolumeChangePoint(
                musicBedData.startAt,
                musicBedData.startAt + fadeOutDuration,
                duckVolume,
                FADE_OUT_VARS.FADE_OUT_END_VOLUME,
            ));

            volumeChangePoints.push(new VolumeChangePoint(
                musicBedData.stopAt - fadeOutDuration,
                musicBedData.stopAt,
                FADE_OUT_VARS.FADE_OUT_END_VOLUME,
                duckVolume,
            ));
        });

        return {
            startAt: 0, // bumper is always first, so it starts at 0
            stopAt,
            volumeChangePoints,
        };
    };

    transformNextElementMetadata = metadata => {
        let {
            introLength,
            cueIn,
            lastRecordingStartAt,
            lastRecordingStopAt,
            fadeOutDuration,
            duckVolume,
            duckDuration,
        } = metadata;

        introLength /= 1000;
        cueIn /= 1000;

        let startAt = lastRecordingStopAt - introLength + cueIn;

        if (startAt < 0) {
            startAt = lastRecordingStartAt;
        }

        let stopAt = startAt + introLength + 3;

        if (stopAt - startAt <= 3) {
            stopAt = startAt + 3;
        }

        const magicOffset = 0.3;

        let duckStartAt = lastRecordingStopAt - duckDuration - magicOffset;
        let duckStopAt = lastRecordingStopAt - magicOffset;

        if (duckStartAt < startAt) {
            duckStartAt = startAt;
            duckStopAt = startAt + duckDuration;
        }

        const volumeChangePoints = [
            new VolumeChangePoint(duckStartAt, duckStopAt, duckVolume, 1),
            new VolumeChangePoint(stopAt - fadeOutDuration, stopAt, 1, FADE_OUT_VARS.FADE_OUT_END_VOLUME),
        ];

        return {
            startAt,
            stopAt: stopAt,
            volumeChangePoints,
        };
    };
}

export default PlaybackMetadataTransformer;
