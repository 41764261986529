export const AdjacentElement = function (data) {
    this.id = data.id;
    this.title = data.title;
    this.length = data.length;
    this.cueIn = data.cueIn;
    this.cueOut = data.cueOut;
    this.fadeIn = data.fadeIn;
    this.fadeOut = data.fadeOut;
    this.startNext = data.startNext;
    this.introLength = data.introLength;
    this.elementType = data.elementType;
    this.contentType = data.contentType;
};

AdjacentElement.fromDto = dto => {
    return new AdjacentElement({
        id: dto.externalId,
        title: dto.description,
        length: parseInt(dto.length, 10),
        cueIn: parseInt(dto.cueIn, 10),
        cueOut: parseInt(dto.cueOut, 10),
        fadeIn: parseInt(dto.fadeIn, 10),
        fadeOut: parseInt(dto.fadeOut, 10),
        startNext: parseInt(dto.startNext, 10),
        introLength: parseInt(dto.introLength, 10),
        elementType: dto.elementType,
        contentType: dto.contentType,
    });
};
