import {forwardRef} from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const UploadingIcon = forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M9.886.5c4.054.403 7.285 3.617 7.671 7.689.472 4.714-2.957 8.897-7.671 9.368v-1.714c3.12-.369 5.571-2.846 5.965-5.966.463-3.763-2.202-7.183-5.965-7.646V.5zM8.17.526V2.24c-1.225.171-2.382.669-3.342 1.44L3.603 2.411C4.906 1.331 6.5.69 8.17.526zM2.394 3.62L3.62 4.846c-.763.968-1.243 2.125-1.406 3.343H.5c.171-1.68.814-3.266 1.894-4.569zM.51 9.903h1.714c.154 1.217.643 2.374 1.397 3.343L2.403 14.47C1.34 13.17.68 11.583.509 9.903zm4.32 4.603c.968.754 2.125 1.243 3.342 1.397v1.714c-1.68-.18-3.274-.857-4.568-1.937l1.226-1.174zm4.2-9.317L5.17 9.046h3v3.428h1.715V9.046h3L9.029 5.189z" />
        </SvgIcon>
    );
});
