import {useEffect, useRef, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';

export const useIsPlaying = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const subscriber = useRef(null);

    useEffect(() => {
        subscriber.current = playbackAlgorithm.subscribe(data => {
            setIsPlaying(data.isPlaying);
        }, SUBSCRIPTION_TYPES.IS_PLAYING);

        return () => {
            subscriber.current.unsubscribe();
        };
    }, []);

    return {isPlaying};
};
