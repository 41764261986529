import moment from 'moment';
import {useEffect, useState} from 'react';
import {EditorConfig} from '../../../features/editor/utils/constants';
import recordingAlgorithm from '../../../features/recording-algorithm/RecordingAlgorithm';

export const useProgress = (elementId, subscriptionType, length) => {
    const [progress, setProgress] = useState(0);
    const initialLength = moment.utc(moment.duration(length, 'ms').asMilliseconds()).format(EditorConfig.ElementTimeFormat);
    const [timeLeft, setTimeLeft] = useState(initialLength);

    useEffect(() => {
        const onUpdate = data => {
            const playingElement = data.playingElements.find(element => element.elementId === elementId);
            const element = data.elements.find(element => element.elementId === elementId);

            if (!playingElement) {
                setProgress(0);

                if (!element) {
                    setTimeLeft(initialLength);
                } else {
                    setTimeLeft(moment.utc(moment.duration(element.duration * 1000, 'ms').asMilliseconds()).format(EditorConfig.ElementTimeFormat));
                }

                return;
            }

            if (playingElement.elementId !== elementId) {
                return;
            }

            const newProgress = (playingElement.passedTime * 100) / playingElement.duration;

            if (newProgress > 100) {
                setProgress(0);
                setTimeLeft(moment.utc(moment.duration(initialLength, 'ms').asMilliseconds()).format(EditorConfig.ElementTimeFormat));

                return;
            }

            setProgress(newProgress);
            setTimeLeft(moment.utc(moment.duration((playingElement.duration - playingElement.passedTime) * 1000, 'ms').asMilliseconds()).format(EditorConfig.ElementTimeFormat));
        };

        const subscriber = recordingAlgorithm.subscribe(onUpdate, subscriptionType);

        return () => {
            subscriber.unsubscribe();
        };
    }, [elementId, initialLength, subscriptionType]);

    return {
        progress,
        timeLeft,
    };
};
