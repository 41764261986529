import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useDispatch, useSelector} from 'react-redux';
import classes from './ProcessingTaskModal.module.scss';
import {PrimaryButton, Typography} from '../../../../components';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../../../features/current-task';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';

export const ProcessingCompleteModal = () => {
    const dispatch = useDispatch();

    const taskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);

    const isOpen = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.PROCESSING_COMPLETE);

    return (
        <Modal
            open={isOpen}
            onClose={() => dispatch(UiActions.setModalState(ModalKeys.PROCESSING_COMPLETE, false))}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div>
                    <div className={classes.root}>
                        <Typography transform="uppercase" weight={500} color="regent" className={classes.title} variant="h1">
                            Processing done!
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={false}>
                            The audio of this task variation has been successfully processed.
                            The task variation is now completed!
                        </Typography>

                        <div className={classes.actions}>
                            <PrimaryButton
                                onClick={() => {
                                    dispatch(UiActions.setModalState(ModalKeys.PROCESSING_COMPLETE, false));
                                    dispatch(CurrentTaskActions.setCurrentTaskVariationId(taskVariationId));
                                }}
                            >
                                Continue
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
