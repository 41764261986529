import {formatMillisecondsAsTimestamp, isTaskUrgent} from '../../../utils';
import {TaskStatuses} from '../../task/utils/task-statuses';

const AdvertisingSlot = function (dto) {
    this.description = dto.description;
};

const Song = function (dto) {
    this.id = dto.id;
    this.name = dto.description;
    this.externalId = dto.externalId;
    this.length = dto.playingLength || 0;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);
    this.introLength = dto.introLength || 0;
    this.formattedIntroLength = formatMillisecondsAsTimestamp(this.introLength);
    this.version = dto.version || null;

    this.update = data => {
        return new Song(data);
    };
};

const Autoverpackung = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.externalId = dto.externalId;
    this.length = dto.playingLength || 0;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);

    this.update = data => {
        return new Autoverpackung(data);
    };
};

export const GenericTask = function (dto) {
    this.id = dto.id;
    this.title = dto.title;
    this.length = dto.length || 0;
    this.externalId = dto.externalId;
    this.status = dto.status;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);

    this.update = data => {
        return new GenericTask(data);
    };
};

export const Task = function (dto) {
    this.id = dto.id;
    this.name = dto.description || dto.name;
    this.filename = dto.filename;
    this.externalId = dto.externalId;
    this.length = dto.playingLength || dto.length || 0;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);
    this.taskVariationId = dto.taskVariationId;
    this.startTime = dto.startTime || null;
    this.isTaskCompleted = dto.isTaskCompleted;
    this.isCurrentUserTask = dto.isCurrentUserTask;
    this.status = dto.status || TaskStatuses.NOT_COMPLETED;
    this.hostRole = dto.hostRole;
    this.playlistItemId = dto.playlistItemId;
    this.firstPlanningFilter = dto.firstPlanningFilter;
    this.isDry = dto.isDry;

    if (this.isTaskCompleted) {
        this.status = TaskStatuses.COMPLETED;
    } else if (dto.isProcessing) {
        this.status = TaskStatuses.PROCESSING_AUDIO;
    } else if (!this.taskVariationId) {
        this.status = TaskStatuses.DELETED;
    }

    this.speakerText = dto.speakerText;

    this.update = data => {
        return new Task(data);
    };
};

Task.prototype.parseSpeakerText = function () {
    if (this.speakerText) {
        let text = this.speakerText;
        const nodes = [];

        if (text.startsWith('<hashtag>')) {
            text = text
                .replaceAll(/(?!^)<hashtag>/g, '</text><hashtag>')
                .replaceAll('</hashtag>', '</hashtag><text>');
            text += '</text>';
        } else {
            text = '<text>' + text;
            text = text
                .replaceAll('<hashtag>', '</text><hashtag>')
                .replaceAll('</hashtag>', '</hashtag><text>');
            text += '</text>';
        }

        // Remove all empty texts
        text = text.replaceAll('<text></text>', '');

        const matches = text.matchAll(/<(hashtag|text)>(.*?)<\/(hashtag|text)>/gm);

        for (const match of matches) {
            if (match[1]) {
                nodes.push({
                    value: match[2],
                    type: match[1],
                });
            }
        }

        this.speakerText = JSON.stringify({
            root: {
                children: [
                    {
                        children: [
                            ...nodes.map(node => {
                                if (node.type === 'hashtag') {
                                    return {
                                        type: 'hashtag',
                                        text: node.value,
                                        originalText: node.value,
                                    };
                                }

                                return {
                                    mode: 'normal',
                                    type: 'text',
                                    text: node.value,
                                    version: 1,
                                    detail: 0,
                                    format: 0,
                                    style: '',
                                };
                            }),
                        ],
                        direction: 'ltr',
                        format: '',
                        indent: 0,
                        type: 'paragraph',
                        version: 1,
                    },
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'root',
                version: 1,
            },
        });
    }
};

export const DdsItem = function (dto) {
    this.id = dto.id;
    this.name = dto.description;
    this.externalId = dto.externalId;
};

const PlaylistDdsItem = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.externalId = dto.externalId;
    this.length = dto.length;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);
    this.startTime = dto.startTime;

    this.update = data => {
        return new PlaylistDdsItem(data);
    };
};

PlaylistDdsItem.fromDto = dto => {
    return new PlaylistDdsItem({
        id: dto.id,
        name: dto.description,
        externalId: dto.externalId,
        length: dto.playingLength || 0,
        startTime: dto.startTime,
    });
};

export const DdsFilter = function (dto) {
    this.externalBrandId = dto.brandExternalId;
    this.externalChannelId = dto.channelExternalId;
    this.brandId = dto.brandId;
    this.channelId = dto.channelId;
    this.name = dto.description;
    this.id = dto.id;
    this.contentTypeId = dto.contentType;
    this.subContentTypeId = dto.subContentType;
    this.firstFilterId = dto.firstPlanningFilter;
    this.secondFilterId = dto.secondPlanningFilter;
    this.thirdFilterId = dto.thirdPlanningFilter;
    this.contentTypeName = dto.contentTypeName;
    this.subContentTypeName = dto.subContentTypeName;
    this.firstFilterName = dto.firstPlanningFilterName;
    this.secondFilterName = dto.secondPlanningFilterName;
    this.thirdFilterName = dto.thirdPlanningFilterName;
    this.offset = dto.offset;
    this.specificDay = dto.specificDay;
    this.specificTime = dto.specificTime;
};

const PlaylistDdsFilter = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.length = dto.length;
    this.formattedLength = formatMillisecondsAsTimestamp(this.length);
    this.startTime = dto.startTime;

    this.update = data => {
        return new PlaylistDdsFilter(data);
    };
};

PlaylistDdsFilter.fromDto = dto => {
    return new PlaylistDdsFilter({
        id: dto.id,
        name: dto.description,
        externalId: dto.externalId,
        length: dto.playingLength || 0,
        startTime: dto.startTime,
    });
};

const BlockProgramItem = function (dto) {
    this.id = dto.id;

    this.task = null;
    this.ddsItem = null;
    this.ddsFilter = null;
    this.advertisingSlot = null;
};

const BlockProgram = function (dto) {
    this.items = dto.items || [];
    this.name = dto.name || 'Default';
    this.numberOfOpenTasks = dto.numberOfOpenTasks;
    this.hasUrgentTasks = dto.hasUrgentTasks || false;
    this.numberOfTasks = dto.numberOfTasks;

    this.update = data => {
        return new BlockProgram(data);
    };
};

const SplitProgramItem = function (dto) {
    this.id = dto.id;
    this.length = dto.playingLength;
    this.name = dto.description;

    this.task = null;
    this.ddsItem = null;
    this.ddsFilter = null;
    this.advertisingSlot = null;
};

const SplitProgram = function (dto) {
    this.id = dto.id;
    this.key = dto.key || null;
    this.name = dto.name;
    this.numberOfOpenTasks = dto.numberOfOpenTasks;
    this.hasUrgentTasks = dto.hasUrgentTasks || false;
    this.numberOfTasks = dto.numberOfTasks;

    this.items = dto.items || [];

    this.update = data => {
        return new SplitProgram(data);
    };
};

const Block = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.numberOfOpenTasks = dto.numberOfOpenTasks;
    this.selectedRegionId = dto.selectedRegionId || null;
    this.hasUrgentTasks = dto.hasUrgentTasks || false;
    this.numberOfTasks = dto.numberOfTasks;

    this.blockProgramKey = dto.blockProgramKey || null;
    this.splitProgramKeys = dto.splitProgramKeys || [];

    this.update = data => {
        return new Block(data);
    };
};

const Entry = function (dto) {
    this.id = dto.id;
    this.startTime = dto.startTime;

    // TODO Transform this to our own enum values
    this.type = dto.type;

    this.blockKey = dto.blockKey || null;
    this.songKey = dto.songKey || null;
    this.autoverpackungKey = dto.autoverpackungKey || null;

    this.update = data => {
        return new Entry(data);
    };
};

const View = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.startTime = dto.startTime;
    this.endTime = dto.endTime;
    this.isExpanded = dto.isExpanded || false;
    this.entryKeys = dto.entryKeys || [];
    this.numberOfOpenTasks = dto.numberOfOpenTasks;
    this.hasUrgentTasks = dto.hasUrgentTasks || false;
    this.numberOfTasks = dto.numberOfTasks;

    this.update = data => {
        return new View(data);
    };
};

export const Show = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.color = dto.color;
    this.startTime = dto.startTime;
    this.endTime = dto.endTime;
    this.isPlaceholder = dto.isPlaceholder;
    this.viewKeys = [];

    this.update = data => {
        return new Show(data);
    };
};

/**
 * @typedef PlaylistEntities
 * @property blocks: {string, Block}
 * @property splitPrograms: {string, SplitProgram},
 * @property blockPrograms: {string, BlockProgram},
 * @property ddsItems: {string, PlaylistDdsItem},
 * @property entries: {string, Entry},
 * @property shows: {string, Show},
 * @property ddsFilters: {string, PlaylistDdsFilter},
 * @property songs: {string, Song},
 * @property splitProgramItems: {string, SplitProgramItem},
 * @property showKeys: string[],
 * @property autoverpackungs: {string, Autoverpackung},
 * @property blockProgramItems: {string, BlockProgramItem},
 * @property views: {string, View},
 * @property tasks: {string, Task}
 */

/**
 *
 * @param showDtos
 * @param {string} startDate
 * @param {array} failedUploadTaskIds
 * @returns {PlaylistEntities}
 */
export const transformPlaylistData = (showDtos, startDate, failedUploadTaskIds) => {
    const shows = {};
    const views = {};
    const entries = {};
    const songs = {};
    const autoverpackungs = {};
    const blocks = {};
    const blockPrograms = {};
    const blockProgramItems = {};
    const splitPrograms = {};
    const splitProgramItems = {};
    const ddsItems = {};
    const ddsFilters = {};
    const advertisingSlots = {};
    const genericTasks = {};
    const tasks = {};
    // const stats = new TaskStats();

    showDtos.forEach(showDto => {
        const show = new Show(showDto);

        showDto.mcViews.forEach(viewDto => {
            const viewKey = `${showDto.id}/${viewDto.id}`;
            const view = new View(viewDto);
            const viewTaskIds = [];

            views[viewKey] = view;

            viewDto.mcEntries.forEach(entryDto => {
                const entry = new Entry(entryDto);
                const entryKey = `${viewKey}/${entry.id}`;

                switch (entryDto.type) {
                    case 0: {
                        const block = new Block(entryDto.block);
                        const blockKey = `${entryKey}/${entryDto.block.id}`;
                        const blockTaskIds = [];
                        const blockProgramTaskIds = [];

                        const blockProgram = new BlockProgram(entryDto.block.blockProgram);
                        const blockProgramKey = `${blockKey}/0`;

                        entryDto.block.blockProgram.blockProgramItems.forEach(blockProgramItemDto => {
                            const blockProgramItem = new BlockProgramItem(blockProgramItemDto);
                            const blockProgramItemKey = `${blockProgramKey}/${blockProgramItemDto.id}`;

                            if (blockProgramItemDto.task) {
                                const task = new Task(blockProgramItemDto.task);
                                task.parseSpeakerText();

                                if (failedUploadTaskIds.includes(task.id)) {
                                    if (!task.isTaskCompleted) {
                                        task.status = TaskStatuses.UPLOAD_FAILED;
                                    }
                                }

                                const taskKey = `${blockProgramItemKey}/${task.id || task.playlistItemId}`;
                                task.startTime = blockProgramItemDto.startTime;
                                blockProgramItem.task = taskKey;
                                tasks[taskKey] = task;

                                const isUrgent = isTaskUrgent(startDate, blockProgramItemDto.startTime);

                                if (isUrgent && !task.isTaskCompleted) {
                                    view.hasUrgentTasks = true;
                                    block.hasUrgentTasks = true;
                                    blockProgram.hasUrgentTasks = true;
                                }

                                viewTaskIds.push(task.id);
                                blockTaskIds.push(task.id);
                                blockProgramTaskIds.push(task.id);

                                // stats.addTaskId(task.id, isUrgent, task.isTaskCompleted);
                            } else if (blockProgramItemDto.ddsItem) {
                                const ddsItem = PlaylistDdsItem.fromDto(blockProgramItemDto.ddsItem);
                                const ddsItemKey = `${blockProgramItemKey}/${blockProgramItemDto.ddsItem.id}`;
                                ddsItem.startTime = blockProgramItemDto.startTime;
                                blockProgramItem.ddsItem = ddsItemKey;
                                ddsItems[ddsItemKey] = ddsItem;
                            } else if (blockProgramItemDto.ddsFilter) {
                                const ddsFilter = PlaylistDdsFilter.fromDto(blockProgramItemDto.ddsFilter);
                                const ddsFilterKey = `${blockProgramItemKey}/${blockProgramItemDto.ddsFilter.id}`;
                                ddsFilter.startTime = blockProgramItemDto.startTime;
                                blockProgramItem.ddsFilter = ddsFilterKey;
                                ddsFilters[ddsFilterKey] = ddsFilter;
                            } else if (blockProgramItemDto.advertisingSlot) {
                                const advertisingSlot = new AdvertisingSlot({
                                    id: blockProgramItemDto.advertisingSlot.playlistItemId,
                                    description: blockProgramItemDto.advertisingSlot.description,
                                });
                                const advertisingSlotKey = `${blockProgramItemKey}/${blockProgramItemDto.advertisingSlot.playlistItemId}`;
                                advertisingSlot.startTime = blockProgramItemDto.startTime;
                                blockProgramItem.advertisingSlot = advertisingSlotKey;
                                advertisingSlots[advertisingSlotKey] = advertisingSlot;
                            } else if (blockProgramItemDto.genericTask) {
                                const genericTask = new GenericTask({
                                    id: blockProgramItemDto.genericTask.playlistItemId,
                                    title: blockProgramItemDto.genericTask.title,
                                    length: blockProgramItemDto.genericTask.length,
                                    externalId: blockProgramItemDto.genericTask.externalId,
                                    status: blockProgramItemDto.genericTask.status,
                                });
                                const genericTaskKey = `${blockProgramItemKey}/${blockProgramItemDto.genericTask.playlistItemId}`;
                                genericTask.startTime = blockProgramItemDto.startTime;
                                blockProgramItem.genericTask = genericTaskKey;
                                genericTasks[genericTaskKey] = genericTask;
                            }

                            blockProgram.items = [...blockProgram.items, blockProgramItemKey];
                            blockProgramItems[blockProgramItemKey] = blockProgramItem;
                        });

                        entryDto.block.splitPrograms.forEach(splitProgramDto => {
                            const splitProgramKey = `${blockKey}/${splitProgramDto.regionId}`;
                            const splitProgram = splitPrograms[splitProgramKey] ?? new SplitProgram(splitProgramDto);
                            const splitProgramTaskIds = [];

                            if (splitPrograms[splitProgramKey]) {
                                splitProgram.numberOfOpenTasks += splitProgramDto.numberOfOpenTasks;
                            }

                            splitProgramDto.splitProgramItems.forEach(splitProgramItemDto => {
                                const splitProgramItem = new SplitProgramItem(splitProgramItemDto);
                                const splitProgramItemKey = `${splitProgramKey}/${splitProgramItemDto.id}`;

                                if (splitProgramItemDto.task) {
                                    const task = new Task(splitProgramItemDto.task);
                                    task.parseSpeakerText();

                                    if (failedUploadTaskIds.includes(task.id)) {
                                        task.status = TaskStatuses.UPLOAD_FAILED;
                                    }

                                    const taskKey = `${splitProgramItemKey}/${task.id || task.playlistItemId}`;
                                    task.startTime = splitProgramItemDto.startTime;
                                    splitProgramItem.task = taskKey;
                                    tasks[taskKey] = task;

                                    const isUrgent = isTaskUrgent(startDate, splitProgramItemDto.startTime);

                                    if (isUrgent && !task.isTaskCompleted) {
                                        view.hasUrgentTasks = true;
                                        block.hasUrgentTasks = true;
                                        splitProgram.hasUrgentTasks = true;
                                    }

                                    viewTaskIds.push(task.id);
                                    blockTaskIds.push(task.id);
                                    splitProgramTaskIds.push(task.id);

                                    // stats.addTaskId(task.id, isUrgent, task.isTaskCompleted);
                                } else if (splitProgramItemDto.ddsItem) {
                                    const ddsItem = PlaylistDdsItem.fromDto(splitProgramItemDto.ddsItem);
                                    const ddsItemKey = `${splitProgramItemKey}/${splitProgramItemDto.ddsItem.id}`;
                                    ddsItem.startTime = splitProgramItemDto.startTime;
                                    splitProgramItem.ddsItem = ddsItemKey;
                                    ddsItems[ddsItemKey] = ddsItem;
                                } else if (splitProgramItemDto.ddsFilter) {
                                    const ddsFilter = PlaylistDdsFilter.fromDto(splitProgramItemDto.ddsFilter);
                                    const ddsFilterKey = `${splitProgramItemKey}/${splitProgramItemDto.ddsFilter.id}`;
                                    ddsFilter.startTime = splitProgramItemDto.startTime;
                                    splitProgramItem.ddsFilter = ddsFilterKey;
                                    ddsFilters[ddsFilterKey] = ddsFilter;
                                } else if (splitProgramItemDto.advertisingSlot) {
                                    const advertisingSlot = new AdvertisingSlot({
                                        id: splitProgramItemDto.advertisingSlot.playlistItemId,
                                        description: splitProgramItemDto.advertisingSlot.description,
                                    });
                                    const advertisingSlotKey = `${splitProgramItemKey}/${splitProgramItemDto.advertisingSlot.playlistItemId}`;
                                    advertisingSlot.startTime = splitProgramItemDto.startTime;
                                    splitProgramItem.advertisingSlot = advertisingSlotKey;
                                    advertisingSlots[advertisingSlotKey] = advertisingSlot;
                                } else if (splitProgramItemDto.genericTask) {
                                    const genericTask = new GenericTask({
                                        id: splitProgramItemDto.genericTask.playlistItemId,
                                        title: splitProgramItemDto.genericTask.title,
                                        length: splitProgramItemDto.genericTask.length,
                                        externalId: splitProgramItemDto.genericTask.externalId,
                                        status: splitProgramItemDto.genericTask.status,
                                    });
                                    const genericTaskKey = `${splitProgramItemKey}/${splitProgramItemDto.genericTask.playlistItemId}`;
                                    genericTask.startTime = splitProgramItemDto.startTime;
                                    splitProgramItemDto.genericTask = genericTaskKey;
                                    genericTasks[genericTaskKey] = genericTask;
                                }

                                splitProgram.items = [...splitProgram.items, splitProgramItemKey];
                                splitProgramItems[splitProgramItemKey] = splitProgramItem;
                            });

                            splitProgram.numberOfTasks = [...new Set(splitProgramTaskIds)].length;

                            splitProgram.key = splitProgramKey;
                            splitPrograms[splitProgramKey] = splitProgram;

                            if (!block.splitProgramKeys.includes(splitProgramKey)) {
                                block.splitProgramKeys = [...block.splitProgramKeys, splitProgramKey];
                            }
                        });

                        block.numberOfTasks = [...new Set(blockTaskIds)].length;
                        blockProgram.numberOfTasks = [...new Set(blockProgramTaskIds)].length;

                        block.blockProgramKey = blockProgramKey;
                        blockPrograms[blockProgramKey] = blockProgram;

                        block.selectedRegionId = blockProgramKey;
                        blocks[blockKey] = block;
                        entry.blockKey = blockKey;
                        break;
                    }

                    case 1: {
                        const song = new Song(entryDto.music);
                        const songKey = `${entryKey}/${entryDto.music.id}`;
                        songs[songKey] = song;
                        entry.songKey = songKey;
                        break;
                    }

                    case 2: {
                        const autoverpackung = new Autoverpackung(entryDto.autoverpackung);
                        const autoverpackungKey = `${entryKey}/${entryDto.autoverpackung.id}`;
                        autoverpackungs[autoverpackungKey] = autoverpackung;
                        entry.autoverpackungKey = autoverpackungKey;
                        break;
                    }
                }

                view.numberOfTasks = [...new Set(viewTaskIds)].length;

                entries[entryKey] = entry;
                view.entryKeys = [...view.entryKeys, entryKey];
            });

            show.viewKeys = [...show.viewKeys, viewKey];
        });

        shows[show.startTime] = show;
    });

    return {
        showKeys: Object.keys(shows),
        shows,
        views,
        entries,
        songs,
        autoverpackungs,
        blocks,
        blockPrograms,
        blockProgramItems,
        splitPrograms,
        splitProgramItems,
        ddsItems,
        ddsFilters,
        advertisingSlots,
        tasks,
        genericTasks,
        // stats: null,
    };
};
