import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import classes from './ElementsHeader.module.scss';
import {Presets} from './Presets';
import {PresetsDropdown} from './PresetsDropdown';
import {VolumeSliderDropdown} from './VolumeSliderDropdown';
import {IconButton, Typography} from '../../../../components';
import {VolumeOneIcon, VolumeThreeIcon, VolumeTwoIcon, VolumeZeroIcon} from '../../../../icons';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {VolumeSlider} from '../VolumeSlider/VolumeSlider';

const VolumeIcons = {
    0: VolumeZeroIcon,
    1: VolumeOneIcon,
    2: VolumeTwoIcon,
    3: VolumeThreeIcon,
};

const AudioTypeColors = {
    preProduced: 'rose',
    sfx: 'sun',
    bed: 'turquoise',
};

export const ElementsHeader = ({title, volume, audioType, onVolumeChange}) => {
    const VolumeIconComponent = useMemo(() => VolumeIcons[volume], [volume]);

    const areElementsPopped = useSelector(CockpitSelectors.selectAreElementsPopped);

    return (
        <div
            className={clsx(classes.root, {
                [classes.poppedOut]: areElementsPopped,
            })}
        >
            <div className={classes.topBar}>
                <Typography
                    hasGutters={false}
                    color="regent"
                    weight={500}
                    size={22}
                    transform="uppercase"
                    className={classes.topBarTitle}
                >
                    {title}
                </Typography>

                <div className={classes.topBarAction}>
                    <VolumeSliderDropdown VolumeIconComponent={VolumeIconComponent} isPoppedOut={areElementsPopped}>
                        <VolumeSlider orientation="vertical" color={AudioTypeColors[audioType]} value={volume} onChange={onVolumeChange} />
                    </VolumeSliderDropdown>

                    <PresetsDropdown audioType={audioType}>
                        <Presets audioType={audioType} />
                    </PresetsDropdown>
                </div>
            </div>

            <div className={classes.volumeSliderWrapper}>
                <div className={classes.volumeSliderInnerWrapper}>
                    <VolumeSlider color={AudioTypeColors[audioType]} value={volume} onChange={onVolumeChange} />
                </div>

                <IconButton
                    backgroundColor="transparent"
                    size={24}
                    iconSize={24}
                    color="white"
                    hoverStyle="color"
                >
                    <VolumeIconComponent />
                </IconButton>
            </div>
        </div>
    );
};

ElementsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    volume: PropTypes.number.isRequired,
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
    onVolumeChange: PropTypes.func.isRequired,
};
