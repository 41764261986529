const selectIsOpen = state => state.player.isOpen;

const selectSources = state => state.player.sources;

const selectTitle = state => state.player.title;

export const PlayerSelectors = {
    selectIsOpen,
    selectSources,
    selectTitle,
};
