import {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {useCanDrag} from '../../../features/editor/hooks/use-can-drag';

export const useSortableElement = ({elementType, elementId, index, onEndDrag, onMoveElement}) => {
    const ref = useRef(null);

    const {canDrag} = useCanDrag();

    const [{opacity}, drag] = useDrag({
        type: elementType,
        item: {
            id: elementId,
            index,
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
        end: (draggedItem, monitor) => {
            onEndDrag(monitor.didDrop(), draggedItem);
        },
        canDrag: () => {
            return !canDrag;
        },
    });

    const [{handlerId}, drop] = useDrop({
        accept: elementType,
        collect: monitor => {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            onMoveElement(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return {
        ref,
        handlerId,
        opacity,
    };
};
