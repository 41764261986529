export const DURATION = 225;

const ANIMATION_OFFSET = 125;

export const HOME_ANIMATION_DELAYS = {
    HEADER: 0,
    LOGO: 125,
    ACCOUNT_MENU: 125,
    PAGE_HEADER: 125,
    NAVIGATION: DURATION + ANIMATION_OFFSET,
    BRAND: DURATION + ANIMATION_OFFSET * 2,
    CHANNEL: DURATION + ANIMATION_OFFSET * 3,
};

export const COCKPIT_ANIMATION_DELAYS = {
    TASK_BAR: DURATION,
    VALIDITY: DURATION + ANIMATION_OFFSET,
    PREVIOUS_ELEMENT: DURATION + ANIMATION_OFFSET * 2,
    LENGTH: DURATION + ANIMATION_OFFSET * 3,
    MAIN_BUTTON: DURATION + ANIMATION_OFFSET * 4,
    CONTROL_BUTTON_1: DURATION + ANIMATION_OFFSET * 5,
    CONTROL_BUTTON_2: DURATION + ANIMATION_OFFSET * 6,
    CONTROL_BUTTON_3: DURATION + ANIMATION_OFFSET * 7,
    CONTROL_BUTTON_4: DURATION + ANIMATION_OFFSET * 8,
    NEXT_ELEMENT: DURATION + ANIMATION_OFFSET * 9,
};
