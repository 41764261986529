import queryString from 'query-string';
import {push} from 'redux-first-history';
import {BroadcastChannelActions} from '../../broadcast-channel';
import {CurrentTaskActions} from '../../current-task';

const width = window.screen.width;

const childWindowOptions = {
    left: width > 1920 ? width / 2 : 0,
    top: 0,
    height: window.screen.height === 2160 ? 1080 : window.screen.height, // TODO
    width: width === 1920 ? 1920 : width > 1920 ? width / 2 : width,
    location: false,
    menubar: false,
    resizable: false,
    scrollbars: false,
    status: false,
    toolbar: false,
};

class CockpitWindowController {
    #windowReference;
    #animationFrameNumber;
    #reduxDispatch;

    openWindow(path, reduxDispatch, queryParams) {
        this.#reduxDispatch = reduxDispatch;

        if (this.#windowReference) {
            // The window is already open, so just focus it and send push() through BroadcastChannel
            this.#windowReference.focus();

            reduxDispatch(BroadcastChannelActions.send({
                messageType: 'action',
                action: push({
                    pathname: path,
                    search: queryString.stringify(queryParams),
                }),
            }));

            return;
        }

        const urlParams = new URLSearchParams(queryParams);
        const url = new URL(window.location.href);
        url.pathname = path;
        url.search = urlParams.toString();

        this.#windowReference = window.open(url, 'cockpitWindow', this.#getWindowFeatures());

        window.addEventListener('beforeunload', this.#onMainWindowUnload);

        this.#animationFrameNumber = requestAnimationFrame(this.#checkWindowStatus);
    }

    isOpen = () => !!this.#windowReference;

    isCockpitWindow = () => {
        if (window.location.pathname.startsWith('/task/')) {
            return true;
        }

        return window.name === 'cockpitWindow';
    };

    closeWindow = () => {
        if (!this.isOpen()) {
            return;
        }

        this.#windowReference.close();
    };

    #getWindowFeatures = () => {
        return Object.getOwnPropertyNames(childWindowOptions)
            .map(key => `${key}=${childWindowOptions[key]}`)
            .join(',');
    };

    #onMainWindowUnload = () => {
        if (this.#windowReference && !this.#windowReference.closed) {
            this.#windowReference.close();
        }
    };

    #checkWindowStatus = () => {
        if (!this.#windowReference || this.#windowReference.closed) {
            this.#windowReference = undefined;

            window.removeEventListener('beforeunload', this.#onMainWindowUnload);

            cancelAnimationFrame(this.#animationFrameNumber);

            this.#reduxDispatch(CurrentTaskActions.setCurrentTaskVariationId(null));
            this.#reduxDispatch(CurrentTaskActions.setCurrentTaskId(null));

            return;
        }

        this.#animationFrameNumber = requestAnimationFrame(this.#checkWindowStatus);
    };
}

/**
 * Singleton.
 * @type {CockpitWindowController}
 */
export const cockpitWindowController = new CockpitWindowController();
