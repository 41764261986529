import {delay, put, race, take} from 'redux-saga/effects';
import {WebSocketActions} from './web-socket.action';
import {WebSocketActionTypes} from './web-socket.action-type';
import {Debug} from '../../../lib/debug';

export const initializeWebSocket = function* () {
    Debug.debug('web-socket.loader-saga', 'Opening connection...');

    while (true) {
        yield put(WebSocketActions.connect());

        const {error, success} = yield race({
            success: take(WebSocketActionTypes.CONNECTION_SUCCESSFULLY_ESTABLISHED),
            error: take(WebSocketActionTypes.CONNECTION_ERROR),
        });

        if (success) {
            Debug.debug('web-socket.loader-saga', 'Connection open!');

            yield take(WebSocketActionTypes.CONNECTION_CLOSED);
        } else if (error) {
            Debug.debug('web-socket.loader-saga', 'Retrying!');

            yield delay(15000);
        }
    }
};
