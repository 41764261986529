import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {BroadcastChannelActions} from './broadcast-channel.action';
import {BroadcastChannelActionTypes} from './broadcast-channel.action-type';
import {Debug} from '../../../lib/debug';
import {CockpitNavigationActions, CockpitNavigationSelectors} from '../../cockpit-navigation';
import {CurrentTaskActionTypes, CurrentTaskActions, CurrentTaskSelectors} from '../../current-task';
import {FailedUploadsActions} from '../../failed-uploads/store/failed-uploads.action';
import {FailedUploadsActionTypes} from '../../failed-uploads/store/failed-uploads.action-type';
import {PlaylistSelectors} from '../../playlist';
import {PlaylistActions} from '../../playlist/store/playlist.action';
import {TaskActions} from '../../task/store/task.action';
import {TaskActionTypes} from '../../task/store/task.action-type';
import {UiActions} from '../../ui/store/ui.action';
import {UiSelectors} from '../../ui/store/ui.selector';
import {ModalKeys} from '../../ui/utils/constants';
import {REQUEST_TYPES} from '../utils/constants';

const getLoadedTaskIds = function* () {
    return yield select(CockpitNavigationSelectors.selectIds);
};

const handleRequest = function* (requestType) {
    Debug.debug('broadcast-channel.saga', 'Received request: ', {requestType});

    switch (requestType) {
        case REQUEST_TYPES.LOADED_TASK_IDS: {
            const ids = yield call(getLoadedTaskIds);

            yield put(BroadcastChannelActions.send({
                messageType: 'action',
                action: CockpitNavigationActions.storeIds(ids),
            }));

            break;
        }

        case REQUEST_TYPES.CURRENT_TASK_ID: {
            const id = yield select(CurrentTaskSelectors.selectCurrentTaskId);

            yield put(BroadcastChannelActions.send({
                messageType: 'action',
                action: CurrentTaskActions.setCurrentTaskId(id),
            }));

            break;
        }

        case REQUEST_TYPES.TASK_DELETED_MODAL: {
            const isOpen = yield select(UiSelectors.createActiveModalSelector(ModalKeys.TASK_DELETED_MODAL));

            yield put(BroadcastChannelActions.send({
                messageType: 'action',
                action: UiActions.setModalState(ModalKeys.TASK_DELETED_MODAL, isOpen),
            }));

            break;
        }

        default:
            break;
    }
};

// TODO: Implement openTask action in playlist module and dispatch it here and move this logic to playlist module
const openTaskInPlaylistViewer = function* (taskKey) {
    if (!taskKey) {
        return;
    }

    const [showId, viewId, entryId, blockId, programKey] = taskKey.split('/');
    const viewKey = `${showId}/${viewId}`;
    const blockKey = `${viewKey}/${entryId}/${blockId}`;
    const regionKey = `${blockKey}/${programKey}`;

    yield put(PlaylistActions.setSelectedRegionForBlock(blockKey, regionKey));

    const view = yield select(PlaylistSelectors.createViewByKeySelector(viewKey));

    if (!view) {
        return;
    }

    if (!view.isExpanded) {
        yield put(PlaylistActions.toggleViewCollapse(viewKey));
    }

    yield delay(250);

    yield put(UiActions.setScrollToElement(taskKey, true));
};

const handleAction = function* (action) {
    Debug.debug('broadcast-channel.saga', 'Received action: ', {action});

    if (action.type === CurrentTaskActionTypes.SET_CURRENT_PLAYLIST_TASK_KEY) {
        yield call(openTaskInPlaylistViewer, action.payload);
    }

    yield put({
        ...action,
        isReceive: true,
    });
};

const handleReceive = function* (action) {
    switch (action.payload.messageType) {
        case 'request':
            yield call(handleRequest, action.payload.requestType);
            break;
        case 'action':
            yield call(handleAction, action.payload.action);
            break;
        default:
            break;
    }
};


const handleSideEffect = function* (action) {
    if (action.isReceive) {
        return;
    }

    switch (action.type) {
        case TaskActionTypes.SET_UPLOAD_PROGRESS: {
            yield put(BroadcastChannelActions.send({
                messageType: 'action',
                action: TaskActions.setUploadProgress(action.payload),
            }));

            break;
        }

        case FailedUploadsActionTypes.SET_STATUS: {
            yield put(BroadcastChannelActions.send({
                messageType: 'action',
                action: FailedUploadsActions.setStatus(action.payload),
            }));
            break;
        }

        default:
            break;
    }
};

export const broadcastChannelRootSaga = function* () {
    yield all([
        takeEvery(BroadcastChannelActionTypes.RECEIVE, handleReceive),
        takeEvery([
            TaskActionTypes.SET_UPLOAD_PROGRESS,
            FailedUploadsActionTypes.SET_STATUS,
        ], handleSideEffect),
    ]);
};
