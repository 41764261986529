import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button} from './Button';

import classes from './LargeButton.module.scss';

export const LargeButton = ({children, className, color, ...rest}) => {
    return (
        <Button className={clsx(classes.root, classes[`color-${color}`], className)} {...rest}>
            {children}
        </Button>
    );
};

LargeButton.propTypes = {
    startIcon: Button.propTypes.startIcon,
    endIcon: Button.propTypes.endIcon,
    className: Button.propTypes.className,
    color: PropTypes.oneOf(['primary', 'secondary']),
};

LargeButton.defaultProps = {
    startIcon: Button.defaultProps.startIcon,
    endIcon: Button.defaultProps.endIcon,
    className: Button.defaultProps.className,
    color: 'primary',
};
