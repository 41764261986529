import classes from './PopOutButton.module.scss';
import {PopOutIcon} from '../../../../icons';

export const PopOutButton = props => {
    return (
        <button type="button" className={classes.root} {...props}>
            <PopOutIcon color="stone" size={12} />
        </button>
    );
};
