import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import classes from './Block.module.scss';
import {Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {BlockIcon} from '../../../../icons';
import {BlockProgramItems} from '../BlockProgramItems/BlockProgramItems';
import {BlockProgramTabs} from '../BlockProgramTabs/BlockProgramTabs';
import {SplitProgramItems} from '../SplitProgramItems/SplitProgramItems';
import {TaskNumberIndicator} from '../TaskNumberIndicator/TaskNumberIndicator';

export const Block = ({blockKey}) => {
    const block = useMemoizedCreateSelector(PlaylistSelectors.createBlockByKeySelector, blockKey);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <BlockIcon className={classes.icon} color="radiance" />

                <Typography color="radiance" weight={500} size={12} className={classes.name}>
                    {block.name}
                </Typography>

                {!!block.numberOfTasks && (
                    <TaskNumberIndicator
                        count={block.numberOfOpenTasks}
                        color={block.hasUrgentTasks ? 'orange' : 'default'}
                        noTasksColor="green"
                    />
                )}
            </header>

            <div className={classes.content}>
                {!!block.splitProgramKeys.length && (
                    <BlockProgramTabs
                        blockKey={blockKey}
                        blockProgramKey={block.blockProgramKey}
                        splitProgramKeys={block.splitProgramKeys}
                        selectedRegionId={block.selectedRegionId}
                    />
                )}

                <div className={classes.items}>
                    {block.selectedRegionId === block.blockProgramKey ? (
                        <Fade in={true} key={block.blockProgramKey}>
                            <div>
                                <BlockProgramItems blockProgramKey={block.selectedRegionId} />
                            </div>
                        </Fade>
                    ) : (
                        <Fade in={true} key={block.selectedRegionId}>
                            <div>
                                <SplitProgramItems splitProgramKey={block.selectedRegionId} />
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </div>
    );
};

Block.propTypes = {
    blockKey: PropTypes.string.isRequired,
};
