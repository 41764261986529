import PropTypes from 'prop-types';
import classes from './AdvertisingSlot.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {CampaignIcon, PlayIcon} from '../../../../icons';

export const AdvertisingSlot = ({advertisingSlotKey}) => {
    const advertisingSlot = useMemoizedCreateSelector(
        PlaylistSelectors.createAdvertisingSlotByKeySelector,
        advertisingSlotKey,
    );

    return (
        <div className={classes.root}>
            <CampaignIcon className={classes.icon} color="violet" />

            <IconButton
                className={classes.playButton}
                color="ecstasy"
                backgroundColor="transparent"
                size={18}
                hoverStyle="color"
            >
                <PlayIcon />
            </IconButton>

            <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                {advertisingSlot.startTime}
            </Typography>

            <Typography weight={500} size={11} color="cello" className={classes.length}>
                00:00
            </Typography>

            <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                {advertisingSlot.description.replace('[ADVERTISING SLOT]', 'Advertising key')}
            </Typography>
        </div>
    );
};

AdvertisingSlot.propTypes = {
    advertisingSlotKey: PropTypes.string.isRequired,
};
