import {FilterActionTypes} from './home-filters.action-type';

export const FilterActions = {
    setFieldValue: (fieldKey, value, source) => ({
        type: FilterActionTypes.SET_FIELD_VALUE,
        payload: {
            fieldKey,
            value,
        },
        source,
    }),
};
