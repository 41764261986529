import Fade from '@mui/material/Fade';
import clsx from 'clsx';
import {motion} from 'framer-motion';
import {useDispatch, useSelector} from 'react-redux';
import classes from './Controls.module.scss';
import {ConfirmationDialog} from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {RecordingAlgorithmActions} from '../../../../features/recording-algorithm/store/recording-algorithm.action';
import {TaskSelectors} from '../../../../features/task';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {LoadingBars} from '../../../../icons';
import {CockpitActions} from '../../store/cockpit.action';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {ActionControls} from '../ActionControls/ActionControls';
import {NextElement} from '../AdjacentElement/NextElement';
import {PreviousElement} from '../AdjacentElement/PreviousElement';
import {MainButton} from '../MainButton/MainButton';
import {ModerationLength} from '../ModerationLength/ModerationLength';
import {PopOutButton} from '../PopOutButton/PopOutButton';

export const Controls = () => {
    const dispatch = useDispatch();

    const isDialogOpen = useMemoizedCreateSelector(
        UiSelectors.createActiveModalSelector,
        ModalKeys.MISSING_DEVICE_SETTINGS,
    );

    const areControlsPopped = useSelector(CockpitSelectors.selectAreControlsPopped);

    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);

    const task = useSelector(TaskSelectors.selectCurrentTask);

    return (
        <div
            className={clsx(classes.root, {
                [classes.poppedOut]: areControlsPopped,
                [classes.crossChannelTask]: task?.isCrossChannelTask,
            })}
        >
            <Fade in={!!isLoading} key="loading" timeout={225}>
                <div className={classes.loading}>
                    <LoadingBars size={48} color="regent" />
                </div>
            </Fade>

            {!isLoading && (
                <>
                    <motion.div
                        key="previous-element"
                        initial={{opacity: 0, translateY: '70px'}}
                        animate={{opacity: 1, translateY: 0}}
                        transition={{duration: 0.50, delay: 0.05 + 0.05}}
                    >
                        <PreviousElement />
                    </motion.div>

                    <div className={classes.innerContentWrapper}>
                        <div className={classes.firstRowContentWrapper}>
                            <motion.div
                                key="length"
                                initial={{opacity: 0, translateY: '70px'}}
                                animate={{opacity: 1, translateY: 0}}
                                transition={{duration: 0.50, delay: 2 * 0.05 + 0.05}}
                            >
                                <ModerationLength />
                            </motion.div>

                            <motion.div
                                key="main-button"
                                initial={{opacity: 0, translateY: '70px'}}
                                animate={{opacity: 1, translateY: 0}}
                                transition={{duration: 0.50, delay: 3 * 0.05 + 0.05}}
                            >
                                <MainButton />
                            </motion.div>
                        </div>

                        <div className={classes.secondRowContentWrapper}>
                            <ActionControls />
                        </div>
                    </div>

                    <motion.div
                        key="next-element"
                        initial={{opacity: 0, translateY: '70px'}}
                        animate={{opacity: 1, translateY: 0}}
                        transition={{duration: 0.50, delay: 8 * 0.05 + 0.05}}
                    >
                        <NextElement />
                    </motion.div>

                    {!areControlsPopped && (
                        <PopOutButton onClick={() => dispatch(CockpitActions.setAreControlsPopped(true))} />
                    )}

                    <ConfirmationDialog
                        confirm="Proceed"
                        onClose={() => {
                            dispatch(UiActions.setModalState(ModalKeys.MISSING_DEVICE_SETTINGS, false));
                        }}
                        isOpen={isDialogOpen}
                        onCancel={() => {
                            dispatch(UiActions.setModalState(ModalKeys.MISSING_DEVICE_SETTINGS, false));
                        }}
                        onSubmit={() => {
                            dispatch(UiActions.setModalState(ModalKeys.MISSING_DEVICE_SETTINGS, false));
                            dispatch(RecordingAlgorithmActions.executeNextCommand({shouldForce: true}));
                        }}
                        title="Incomplete or incorrect audio setup detected"
                        message="Our system has identified an issue with your current audio configuration, suggesting it might be incomplete or incorrect. Would you like to proceed nonetheless?"
                    />
                </>
            )}
        </div>
    );
};
