import axios from 'axios';
import {all, call, delay, put, race, select, take, takeLatest} from 'redux-saga/effects';
import {PreloadingActions} from './preloading.action';
import {PreloadingActionTypes} from './preloading.action-type';
import {CONFIG} from '../../../config';
import {handleSagaError} from '../../../error.saga';
import {ddsPlanningApi} from '../../../lib/axios';
import {IndexedDb} from '../../../lib/indexed-db';
import {PlaylistSelectors} from '../../playlist';
import {PlaylistActionTypes} from '../../playlist/store/playlist.action-type';
import {PreparedTaskVariationApi} from '../../prepared-task-variation/api/prepared-task-variation.api';
import {PRELOAD_WAIT_TIME, PreloadingStatuses} from '../utils/constants';

// TODO: Extract API requests
// TODO: Extract prepared task variation logic into a separate feature

// eslint-disable-next-line func-style
function CacheItem(key, data) {
    this.key = key;
    this.data = data;
    this.timestamp = new Date().getTime();

    this.isValid = function () {
        return this.timestamp && (new Date().getTime() - this.timestamp) < 60000;
    };
}

const caches = {
    getAudioSources: {},
    getPreviousElementId: {},
    getNextElementId: {},
};

const getAudioSources = ({externalId}) => {
    if (caches.getAudioSources[externalId]) {
        return caches.getAudioSources[externalId];
    }

    return ddsPlanningApi
        .get(`/v1/dds-items/audiofiles/${externalId}`)
        .then(result => {
            const audioUrls = [];

            if (Array.isArray(result.data) && result.data.length > 0) {
                // Sort audio sources, so OPUS format has priority
                result.data.forEach(audioSource => {
                    if (audioSource.type === 'opus') {
                        audioUrls[0] = audioSource.url;
                    }

                    if (audioSource.type === 'mp3') {
                        audioUrls[1] = audioSource.url;
                    }

                    if (audioSource.type === 'wav') {
                        audioUrls[2] = audioSource.url;
                    }
                });
            }

            caches.getAudioSources[externalId] = audioUrls;

            return audioUrls;
        });
};

const getAudioBlob = url => {
    return axios.get(url, {
        responseType: 'blob',
    }).then(result => result.data);
};

const getAndStoreAudio = function* ({externalId}) {
    const audio = yield call([IndexedDb, IndexedDb.getAudioBlob], externalId);

    if (audio) {
        return;
    }

    const audioSources = yield call(getAudioSources, {externalId});

    const opusUrl = audioSources[0];
    const mp3Url = audioSources[1];
    const wavUrl = audioSources[2];

    const url = opusUrl || mp3Url || wavUrl || null;

    if (!url) {
        return;
    }

    const blob = yield call(getAudioBlob, url);

    yield call([IndexedDb, IndexedDb.storeAudio], externalId, blob);
};

const getPreviousElementId = taskVariationId => {
    if (caches.getPreviousElementId[taskVariationId]) {
        if (caches.getPreviousElementId[taskVariationId].isValid()) {
            return caches.getPreviousElementId[taskVariationId].data;
        }
    }

    return ddsPlanningApi
        .get('/v1/playlist-item/previous', {
            params: {
                taskVariation: taskVariationId,
            },
        })
        .then(result => {
            caches.getPreviousElementId[taskVariationId] = new CacheItem(taskVariationId, result.data?.externalId);

            return result.data?.externalId;
        });
};

const getNextElementId = taskVariationId => {
    if (caches.getNextElementId[taskVariationId]) {
        if (caches.getNextElementId[taskVariationId].isValid()) {
            return caches.getNextElementId[taskVariationId].data;
        }
    }

    return ddsPlanningApi
        .get('/v1/playlist-item/next', {
            params: {
                taskVariation: taskVariationId,
            },
        })
        .then(result => {
            caches.getNextElementId[taskVariationId] = new CacheItem(taskVariationId, result.data?.externalId);

            return result.data?.externalId;
        });
};

const preloadAudiosPreparedTaskVariation = function* ({taskVariationId}) {
    try {
        const preparedTaskVariation = yield call(PreparedTaskVariationApi.getPreparedTaskVariation, {taskVariationId});

        const sagas = [
            ...preparedTaskVariation.preProducedIds.map(externalId => call(getAndStoreAudio, {externalId})),
            ...preparedTaskVariation.sfxIds.map(externalId => call(getAndStoreAudio, {externalId})),
            ...preparedTaskVariation.bedIds.map(externalId => call(getAndStoreAudio, {externalId})),
        ];

        if (sagas.length > 1) {
            let batch = sagas.splice(0, 3);

            while (batch.length) {
                yield all(batch);

                yield delay(250);

                batch = sagas.splice(0, 3);
            }
        }

        yield all(sagas);
    } catch (error) {
        if (error?.response?.status === 404) {
            return;
        }

        yield call(handleSagaError, error, 'preloadAudiosPreparedTaskVariation');
    }
};

const preloadPreviousElementAudio = function* ({taskVariationId}) {
    try {
        const externalId = yield call(getPreviousElementId, taskVariationId);

        yield call(getAndStoreAudio, {externalId});
    } catch (error) {
        if (error?.response?.status === 404) {
            return;
        }

        yield call(handleSagaError, error, 'preloadPreviousElementAudio');
    }
};

const preloadNextElementAudio = function* ({taskVariationId}) {
    try {
        const externalId = yield call(getNextElementId, taskVariationId);

        yield call(getAndStoreAudio, {externalId});
    } catch (error) {
        if (error?.response?.status === 404) {
            return;
        }

        yield call(handleSagaError, error, 'preloadNextElementAudio');
    }
};

const preloadTaskVariation = function* (taskVariationId) {
    yield all([
        call(preloadAudiosPreparedTaskVariation, {taskVariationId}),
        call(preloadPreviousElementAudio, {taskVariationId}),
        call(preloadNextElementAudio, {taskVariationId}),
    ]);
};

const extractTaskVariationIds = function* () {
    const playlistTaskEntities = yield select(PlaylistSelectors.selectTasks);

    const taskVariationIds = [];

    for (const playlistItem of Object.values(playlistTaskEntities)) {
        if (playlistItem.taskVariationId && !taskVariationIds.includes(playlistItem.taskVariationId)) {
            taskVariationIds.push(playlistItem.taskVariationId);
        }
    }

    return taskVariationIds;
};

const wait = function* () {
    yield put(PreloadingActions.setProgress(0));

    yield put(PreloadingActions.setStatus(PreloadingStatuses.WAITING));

    let seconds = PRELOAD_WAIT_TIME;

    while (seconds !== 0) {
        yield put(PreloadingActions.setRemainingWaitTime(seconds));

        yield delay(1000);

        seconds -= 1;
    }

    return true;
};

const preloadRunner = function* ({taskVariationIds}) {
    const sagas = taskVariationIds.map(taskVariationId => call(preloadTaskVariation, taskVariationId));

    yield put(PreloadingActions.setProgress(0));

    const totalNumberOfVariations = sagas.length;
    let batch = sagas.splice(0, 2);

    while (batch.length) {
        yield all(batch);

        yield delay(250);

        const completedNumber = totalNumberOfVariations - sagas.length;
        const percentage = completedNumber / totalNumberOfVariations * 100;
        yield put(PreloadingActions.setProgress(Math.ceil(percentage)));

        batch = sagas.splice(0, 2);
    }

    yield delay(1000);

    return true;
};

const preloadFlow = function* ({taskVariationIds}) {
    const {cancelledWaiting} = yield race({
        task: call(wait),
        cancelledWaiting: take(PreloadingActionTypes.CANCEL),
    });

    if (cancelledWaiting) {
        yield put(PreloadingActions.setStatus(PreloadingStatuses.CANCELLED));
        yield put(PreloadingActions.setProgress(0));

        return;
    }

    yield put(PreloadingActions.setStatus(PreloadingStatuses.RUNNING));

    const {cancelledPreloading} = yield race({
        task: call(preloadRunner, {taskVariationIds}),
        cancelledPreloading: take(PreloadingActionTypes.CANCEL),
    });

    if (cancelledPreloading) {
        yield put(PreloadingActions.setStatus(PreloadingStatuses.CANCELLED));
        yield put(PreloadingActions.setProgress(0));

        return;
    }

    yield put(PreloadingActions.setStatus(PreloadingStatuses.COMPLETED));
    yield put(PreloadingActions.setProgress(0));
};

const preloadWorker = function* ({source}) {
    if (!CONFIG.FEATURES.PRELOADING) {
        return;
    }

    if (source !== 'initial-load') {
        return;
    }

    const taskVariationIds = yield call(extractTaskVariationIds);

    if (!taskVariationIds.length) {
        return;
    }

    yield put(PreloadingActions.setIsAlertVisible(true));

    yield call(preloadFlow, {taskVariationIds});

    yield delay(3000);

    yield put(PreloadingActions.setIsAlertVisible(false));
};

export const preloadingSaga = function* () {
    yield all([
        takeLatest(PlaylistActionTypes.STORE_TASKS, preloadWorker),
    ]);
};
