import moment from 'moment';
import {call, put} from 'redux-saga/effects';
import {getPlaylistFlow} from './playlist.saga';
import {NOT_SELECTED} from '../../../utils';
import {LOADING_TYPES, LoadingActions} from '../../loading';

export const loadPlaylist = function* (filterState) {
    const channelId = filterState.channel && filterState.channel !== NOT_SELECTED ? filterState.channel : undefined;
    const startDate = filterState.startDate ? moment(filterState.startDate).format('YYYY-MM-DD') : undefined;

    if (!channelId || !startDate) {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.TASK_STATS, false));

        return;
    }

    yield call(getPlaylistFlow, channelId, startDate);
};
