import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import queryString from 'query-string';
import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import classes from './SpeakerText.module.scss';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {TaskSelectors} from '../../../../features/task';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {Debug} from '../../../../lib/debug';
import {HashtagNode} from '../../../../lib/lexical/nodes/HashtagNode';
import {MarkerNode} from '../../../../lib/lexical/nodes/MarkerNode';
import {VoiceLevelNode} from '../../../../lib/lexical/nodes/VoiceLevelNode';
import {AddVoiceLevelIconsCommand} from '../../../../lib/lexical/plugins/add-voice-level-icons-command';
import {HashtagMutationListener} from '../../../../lib/lexical/plugins/hashtag-mutation-listener';
import {PreProducedMarkerCommand} from '../../../../lib/lexical/plugins/pre-produced-marker-command';
import {RemoveVoiceLevelIconsCommand} from '../../../../lib/lexical/plugins/remove-voice-level-icons-command';
import {ResetTextCommand} from '../../../../lib/lexical/plugins/reset-text-command';
import {RootListener} from '../../../../lib/lexical/plugins/root-listener';
import {CockpitActions} from '../../store/cockpit.action';
import {drafts} from '../../utils/speaker-text-drafts';
import {EditTextMenu} from '../EditTextMenu/EditTextMenu';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = error => {
    Debug.error('SpeakerText', 'Error: ', error);
};

export const SpeakerText = () => {
    const task = useSelector(TaskSelectors.selectCurrentTask);
    const taskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const taskVariation = useMemoizedCreateSelector(TaskSelectors.createTaskVariationByIdSelector, taskVariationId);

    const [queryParams] = useSearchParams();
    const params = queryString.parse(queryParams.toString());
    const startTimeParam = params.startTime;

    const startTime = useMemo(() => {
        return new Date(startTimeParam);
    }, [startTimeParam]);

    const dispatch = useDispatch();

    const editorStateDraft = drafts.getDraft(taskVariationId);
    let editorState = editorStateDraft ? editorStateDraft.state : undefined;

    if (taskVariation && !editorStateDraft) {
        editorState = taskVariation.preparedSpeakerText || taskVariation.speakerText;
    }

    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);

    const handleInit = useCallback(() => {
        dispatch(CockpitActions.setIsEditingSpeakerText(true));
    }, [dispatch]);

    const handleChange = useCallback(editorState => {
        let validUntil = new Date(task.expirationDate);

        if (!task.isReusable) {
            validUntil = startTime;
        }

        const preparedText = taskVariation.preparedSpeakerText;
        const text = taskVariation.speakerText;

        const jsonState = JSON.stringify(editorState.toJSON());

        if (jsonState === preparedText || jsonState === text) {
            return;
        }

        drafts.storeDraft(taskVariationId, editorState.toJSON(), validUntil.toISOString());
    }, [startTime, task, taskVariationId, taskVariation]);

    return (
        <div className={classes.root}>
            {taskVariation && (taskVariation.preparedSpeakerText || taskVariation.speakerText) && (
                <LexicalComposer
                    // Forcing re-render of the whole Lexical editor to force the refresh of speaker text
                    key={taskVariation.id}
                    initialConfig={{
                        namespace: 'MyEditor',
                        onError,
                        nodes: [HashtagNode, MarkerNode, VoiceLevelNode],
                        theme: {
                            'hashtag': classes.hashtag,
                            'voice-level': classes.voiceLevel,
                            'marker': classes.marker,
                        },
                        editable: false,
                        editorState,
                    }}
                >
                    <OnChangePlugin
                        ignoreSelectionChange
                        ignoreHistoryMergeTagChange
                        onChange={handleChange}
                    />
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        placeholder={null}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ClearEditorPlugin />
                    <HistoryPlugin />
                    <PreProducedMarkerCommand />
                    <HashtagMutationListener />
                    <RemoveVoiceLevelIconsCommand />
                    <AddVoiceLevelIconsCommand />
                    <RootListener
                        taskVariationId={taskVariationId}
                        onInit={handleInit}
                        isLoading={isLoading}
                    />
                    <ResetTextCommand
                        originalSpeakerText={taskVariation.speakerText}
                        preparedSpeakerText={taskVariation.preparedSpeakerText}
                        onReset={isCancel => {
                            drafts.removeDraft(taskVariationId);

                            if (isCancel) {
                                dispatch(CurrentTaskActions.setSpeakerText(taskVariation.preparedSpeakerText ? JSON.parse(taskVariation.preparedSpeakerText) : null, 'user'));
                            } else {
                                dispatch(CurrentTaskActions.setSpeakerText(null, 'user'));
                            }
                        }}
                    />

                    {/* Not plugin */}
                    <EditTextMenu />
                </LexicalComposer>
            )}
        </div>
    );
};
