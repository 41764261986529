import {PreparedTaskVariationActionTypes} from './prepared-task-variation.action-type';

export const PreparedTaskVariationActions = {
    loadForTaskVariation: ({taskVariationId}) => ({
        type: PreparedTaskVariationActionTypes.LOAD_FOR_TASK_VARIATION,
        payload: {
            taskVariationId,
        },
    }),
    storeEntities: entities => ({
        type: PreparedTaskVariationActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    updateSpeakerText: (taskVariationId, speakerText, originalSpeakerText, source) => ({
        type: PreparedTaskVariationActionTypes.UPDATE_SPEAKER_TEXT,
        payload: {
            taskVariationId,
            originalSpeakerText,
            speakerText,
        },
        source,
    }),
    setPreparingTaskId: taskId => ({
        type: PreparedTaskVariationActionTypes.SET_PREPARING_TASK_ID,
        payload: taskId,
    }),
    setPreparingTaskVariationId: taskVariationId => ({
        type: PreparedTaskVariationActionTypes.SET_PREPARING_TASK_VARIATION_ID,
        payload: taskVariationId,
    }),
    setIsEditingSpeakerText: isEditingSpeakerText => ({
        type: PreparedTaskVariationActionTypes.SET_IS_EDITING_SPEAKER_TEXT,
        payload: isEditingSpeakerText,
    }),
    setOriginalSpeakerText: speakerText => ({
        type: PreparedTaskVariationActionTypes.SET_ORIGINAL_SPEAKER_TEXT,
        payload: speakerText,
    }),
    addElement: (elementId, elementType, taskVariationId) => ({
        type: PreparedTaskVariationActionTypes.ADD_ELEMENT,
        payload: {
            elementId,
            elementType,
            taskVariationId,
        },
    }),
    removeElement: (elementId, elementType, taskVariationId) => ({
        type: PreparedTaskVariationActionTypes.REMOVE_ELEMENT,
        payload: {
            elementId,
            elementType,
            taskVariationId,
        },
    }),
    storeAudioItemEntities: entities => ({
        type: PreparedTaskVariationActionTypes.STORE_AUDIO_ITEM_ENTITIES,
        payload: entities,
    }),
    sortElement: (dragIndex, hoverIndex, elementType, taskVariationId) => ({
        type: PreparedTaskVariationActionTypes.SORT_ELEMENT,
        payload: {
            dragIndex,
            hoverIndex,
            elementType,
            taskVariationId,
        },
    }),
    sortElementDrop: taskVariationId => ({
        type: PreparedTaskVariationActionTypes.SORT_ELEMENT_DROP,
        payload: {
            taskVariationId,
        },
    }),
    loadFromPreset: (presetId, taskVariationId, elementType) => ({
        type: PreparedTaskVariationActionTypes.LOAD_FROM_PRESET,
        payload: {
            presetId,
            taskVariationId,
            elementType,
        },
    }),
};
