import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath} from 'react-router-dom';
import classes from './Task.module.scss';
import {IconButton, Switch, Typography} from '../../../../components';
import {ROUTE_PATHS} from '../../../../config/route-paths';
import {userSwitcher} from '../../../../features/auth/utils/switch';
import {CurrentTaskSelectors} from '../../../../features/current-task';
import {useFilterValue} from '../../../../features/home-filters/hooks/use-filter-value';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {PlayerActions} from '../../../../features/player';
import {PlaylistSelectors} from '../../../../features/playlist';
import {PlaylistActions} from '../../../../features/playlist/store/playlist.action';
import {isMatchingPlaylistTask} from '../../../../features/playlist/util/is-matching-task';
import {
    PreparedTaskVariationActions,
} from '../../../../features/prepared-task-variation/store/prepared-task-variation.action';
import {
    PreparedTaskVariationSelectors,
} from '../../../../features/prepared-task-variation/store/prepared-task-variation.selector';
import {TaskStatuses} from '../../../../features/task/utils/task-statuses';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {UploadFileDialog} from '../../../../features/upload-file';
import {cockpitWindowController} from '../../../../features/window-manager';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {
    CheckIcon,
    ChevronDown,
    ErrorIcon,
    MicrophoneIcon,
    PlayIcon,
    ProcessingIcon,
    SoundIcon,
    UploadCompletedIcon,
    UploadIcon,
    UploadingIcon,
} from '../../../../icons';
import {InlineTaskPreparation} from '../InlineTaskPreparation/InlineTaskPreparation';

const getStatusIcon = status => {
    switch (status) {
        case TaskStatuses.UPLOAD_FAILED:
            return [ErrorIcon, {color: 'monza'}];

        case TaskStatuses.PROCESSING_AUDIO:
            return [ProcessingIcon, {color: 'radiance'}];

        case TaskStatuses.UPLOADING:
            return [UploadingIcon, {color: 'sun'}];

        case TaskStatuses.COMPLETED:
            return [CheckIcon, {color: 'jade'}];

        default:
            return [SoundIcon, {color: 'black'}];
    }
};

export const Task = ({taskKey}) => {
    const containerRef = useRef();
    const isScrollingRef = useRef(false);

    const task = useMemoizedCreateSelector(PlaylistSelectors.createTaskByKeySelector, taskKey);
    const currentOpenTask = useSelector(CurrentTaskSelectors.selectCurrentTaskId);

    const searchTerm = useSelector(PlaylistSelectors.selectSearchTerm);
    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);

    const shouldScrollTo = useMemoizedCreateSelector(UiSelectors.createScrollToElementSelector, taskKey);

    const isPreparingTask = useMemoizedCreateSelector(
        PreparedTaskVariationSelectors.createIsPreparingTaskSelector,
        task.id,
    );

    const playingTypeLoading = useIsLoading(LOADING_TYPES.UPDATE_PLAYING_TYPE);

    const brandId = useFilterValue('brand');
    const channelId = useFilterValue('channel');
    const startDate = useFilterValue('startDate');

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    let expirationDate;

    if (!task.isReusable) {
        expirationDate = moment(`${startDate} ${task.startTime}`, 'YYYY-MM-DD HH:mm:ss');
    } else if (task.isExpirationDateOverridden) {
        expirationDate = task.overriddenExpirationDate;
    } else if (task.expirationDate) {
        expirationDate = task.expirationDate;
    }

    const isCurrentlyOpenTask = task.id && currentOpenTask && currentOpenTask === task.id;

    useLayoutEffect(() => {
        let firstTimeout = null;
        let secondTimeout = null;

        if (shouldScrollTo) {
            if (isScrollingRef.current) {
                return;
            }

            firstTimeout = setTimeout(() => {
                isScrollingRef.current = true;

                containerRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

                secondTimeout = setTimeout(() => {
                    isScrollingRef.current = false;

                    dispatch(UiActions.setScrollToElement(taskKey, false));
                }, 225); // dispatch it at the end of the animation
            }, 225); // 0 to move it to the end of execution stack
        }

        return () => {
            if (firstTimeout) {
                clearTimeout(firstTimeout);
            }

            if (secondTimeout) {
                clearTimeout(secondTimeout);
            }
        };
    // eslint-disable-next-line
    }, [shouldScrollTo]);

    const [statusIcon, statusIconProps] = getStatusIcon(task.status);

    const handlePrepareTask = () => {
        if (isPreparingTask) {
            dispatch(PreparedTaskVariationActions.setPreparingTaskId(null));
            dispatch(PreparedTaskVariationActions.setPreparingTaskVariationId(null));

            return;
        }

        dispatch(PreparedTaskVariationActions.setPreparingTaskId(task.id));
        dispatch(PreparedTaskVariationActions.setPreparingTaskVariationId(task.taskVariationId));
        dispatch(PreparedTaskVariationActions.setOriginalSpeakerText(task.speakerText));
    };

    const isMatch = showStructured && isMatchingPlaylistTask(searchTerm, task);

    return (
        <div
            className={clsx(classes.root, {
                [classes.deleted]: task.status === TaskStatuses.DELETED,
                [classes.currentTask]: isCurrentlyOpenTask,
                [classes.notCurrentUsersTask]: !task.isCurrentUserTask,
                [classes.completed]: task.status === TaskStatuses.COMPLETED,
                [classes.uploading]: task.status === TaskStatuses.UPLOADING,
                [classes.uploadFailed]: task.status === TaskStatuses.UPLOAD_FAILED,
                [classes.isMatch]: isMatch,
            })}
        >
            <div className={classes.wrapper} ref={containerRef}>
                {statusIcon && (
                    <Fade key={task.status} in={true}>
                        {React.createElement(statusIcon, {
                            ...statusIconProps,
                            className: classes.icon,
                        })}
                    </Fade>
                )}

                <IconButton
                    className={classes.playButton}
                    color="alabaster"
                    backgroundColor="transparent"
                    size={18}
                    hoverStyle="color"
                    disabled={task.status !== TaskStatuses.COMPLETED || task.isProcessing}
                    onClick={() => dispatch(PlayerActions.loadItem(task.externalId, task.name))}
                >
                    <PlayIcon />
                </IconButton>

                <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                    {task.startTime}
                </Typography>

                <Typography weight={500} size={11} color="cello" className={classes.length}>
                    {task.formattedLength}
                </Typography>

                <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                    {task.filename || task.name}
                </Typography>

                <div className={classes.actions}>
                    <IconButton
                        color="black"
                        backgroundColor="transparent"
                        size={18}
                        hoverStyle="color"
                        disabled={task.status === TaskStatuses.DELETED
                            || task.status === TaskStatuses.UPLOADING
                            || !task.isCurrentUserTask
                            || isPreparingTask}
                        onClick={() => setIsOpen(true)}
                    >
                        {task.isTaskCompleted ? <UploadCompletedIcon /> : <UploadIcon />}
                    </IconButton>

                    <IconButton
                        color="black"
                        backgroundColor="transparent"
                        size={18}
                        hoverStyle="color"
                        disabled={task.status === TaskStatuses.DELETED
                            || task.status === TaskStatuses.UPLOADING
                            || !task.isCurrentUserTask
                            || isPreparingTask}
                        onClick={() => {
                            const path = generatePath(ROUTE_PATHS.TASK, {
                                taskId: task.id,
                            });

                            const params = {
                                brandId,
                                channelId,
                                taskVariationId: task.taskVariationId,
                                playlistTaskKey: taskKey,
                                startTime: `${startDate} ${task.startTime}`,
                            };

                            if (userSwitcher.getIsSwitching()) {
                                // eslint-disable-next-line no-underscore-dangle
                                params._switch = userSwitcher.getEmail();
                            }

                            cockpitWindowController.openWindow(path, dispatch, params);

                            setTimeout(() => {
                                containerRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                });
                            }, 0);
                        }}
                    >
                        <MicrophoneIcon />
                    </IconButton>

                    <IconButton
                        className={clsx(classes.prepareVariationButton, {
                            [classes.prepareVariationButtonActive]: isPreparingTask,
                        })}
                        backgroundColor="transparent"
                        hoverStyle="color"
                        color="cello"
                        size={24}
                        iconSize={14}
                        onClick={handlePrepareTask}
                        disabled={task.status === TaskStatuses.PROCESSING_AUDIO
                            || task.status === TaskStatuses.UPLOADING
                            || task.status === TaskStatuses.DELETED
                            || !task.isCurrentUserTask
                            || isCurrentlyOpenTask}
                    >
                        <ChevronDown />
                    </IconButton>

                    <Switch
                        id={`play-dry-${task.playlistItemId}`}
                        label="Play dry?"
                        isOn={task.isDry}
                        isDisabled={playingTypeLoading?.includes(task.playlistItemId)}
                        onChange={() => {
                            dispatch(PlaylistActions.updatePlayingType(task.playlistItemId, !task.isDry ? 'DRY' : 'DEFAULT'));
                        }}
                    />
                </div>

                <UploadFileDialog
                    isOpen={isOpen}
                    source="task-row"
                    taskId={task.id}
                    taskVariationId={task.taskVariationId}
                    expirationDate={expirationDate}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                />
            </div>

            <Collapse in={isPreparingTask} unmountOnExit mountOnEnter>
                <div className={classes.preparationWrapper}>
                    <InlineTaskPreparation task={task} />
                </div>
            </Collapse>
        </div>
    );
};

Task.propTypes = {
    taskKey: PropTypes.string.isRequired,
};
