import {GenericProductionOrder, GenericTask, GenericTaskVariation} from './generic-task.dto';
import {ddsPlanningApi} from '../../../lib/axios';
import {GENERIC_TASK_VARIATION_STATUSES} from '../utils/generic-task.constants';

export const getGenericTasksApi = () => {
    return ddsPlanningApi
        .get(`/v1/generic-task`)
        .then(response => {
            const genericProductionOrders = {};
            const genericTasks = {};
            const genericTaskVariations = {};

            let previousId = null;

            response.data.forEach(genericProductionOrderDto => {
                const genericProductionOrder = GenericProductionOrder.fromDto({
                    id: genericProductionOrderDto.id,
                    title: genericProductionOrderDto.title,
                    restrictions: genericProductionOrderDto.restrictions ?? [],
                    genericTaskIds: genericProductionOrderDto.genericTasks.map((genericTaskDto, genericTaskIndex) => {
                        let pendingVariationsCount = 0;

                        const genericTaskVariationIds = genericTaskDto.variations
                            .map((genericTaskVariationDto, genericTaskVariationIndex) => {
                                const genericTaskVariation = GenericTaskVariation.fromDto({
                                    id: genericTaskVariationDto.id,
                                    title: 'Variation ' + (genericTaskVariationIndex + 1),
                                    text: genericTaskVariationDto.text,
                                    status: genericTaskVariationDto.status,
                                    externalId: genericTaskVariationDto.externalId,
                                    genericTaskId: genericTaskDto.id,
                                    genericProductionOrderId: genericProductionOrderDto.id,
                                    previousGenericTaskVariationId: previousId,
                                    nextGenericTaskVariationId: null,
                                });

                                genericTaskVariations[genericTaskVariation.id] = genericTaskVariation;

                                if (genericTaskVariations[previousId]) {
                                    const previousVariation = genericTaskVariations[previousId];

                                    genericTaskVariations[previousId] = previousVariation.update({
                                        nextGenericTaskVariationId: genericTaskVariation.id,
                                    });
                                }

                                if (genericTaskVariation.status !== GENERIC_TASK_VARIATION_STATUSES.COMPLETED) {
                                    pendingVariationsCount += 1;
                                }

                                previousId = genericTaskVariation.id;

                                return genericTaskVariationDto.id;
                            });

                        const genericTask = GenericTask.fromDto({
                            id: genericTaskDto.id,
                            title: genericTaskDto.title,
                            initialVoiceRange: genericTaskDto.initialVoiceRange,
                            finalVoiceRange: genericTaskDto.finalVoiceRange,
                            voiceMood: genericTaskDto.voiceMood,
                            validUntil: genericTaskDto.validUntil,
                            brand: genericTaskDto?.brand?.id ? {
                                id: genericTaskDto.brand.id,
                                name: genericTaskDto.brand.name,
                            } : null,
                            channel: genericTaskDto?.channel?.id ? {
                                id: genericTaskDto.channel.id,
                                name: genericTaskDto.channel.name,
                            } : null,
                            weekday: genericTaskDto.weekday,
                            dayPart: genericTaskDto.dayPart,
                            genericProductionOrderId: genericProductionOrderDto.id,
                            genericTaskVariationIds,
                            pendingVariationsCount,
                        });

                        genericTasks[genericTask.id] = genericTask;

                        return genericTaskDto.id;
                    }),
                });

                genericProductionOrders[genericProductionOrder.id] = genericProductionOrder;
            });

            return {
                genericProductionOrders,
                genericTasks,
                genericTaskVariations,
            };
        });
};

export const completeGenericTaskVariation = ({genericTaskVariationId, blob}) => {
    const formData = new FormData();

    formData.append('file[audioFile]', blob);

    return ddsPlanningApi
        .post(`/v1/generic-task/variation/${genericTaskVariationId}/complete`, formData)
        .then(response => {
            return {
                id: response.data.id,
                text: response.data.text,
                status: response.data.status,
            };
        });
};
