import {PlayerActionTypes} from './player.action-type';

export const PlayerActions = {
    setIsOpen: isOpen => ({
        type: PlayerActionTypes.SET_IS_OPEN,
        payload: isOpen,
    }),
    setSources: sources => ({
        type: PlayerActionTypes.SET_SOURCES,
        payload: sources,
    }),
    setTitle: title => ({
        type: PlayerActionTypes.SET_TITLE,
        payload: title,
    }),
    loadItem: (externalId, title) => ({
        type: PlayerActionTypes.LOAD_ITEM,
        payload: {
            externalId,
            title,
        },
    }),
    loadDdsFilter: ddsFilterId => ({
        type: PlayerActionTypes.LOAD_DDS_FILTER,
        payload: {
            ddsFilterId,
        },
    }),
};
