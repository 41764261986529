import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {AddElement} from './AddElement';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useDisabledControls} from '../../hooks/use-disabled-controls';

export const AddBedElement = ({size}) => {
    const dispatch = useDispatch();
    const {areControlsDisabled} = useDisabledControls();

    const handleClick = () => {
        dispatch(UiActions.setModalState(ModalKeys.ADD_ELEMENT_MODAL, 'bed'));
    };

    return (
        <>
            <AddElement color="turquoise" size={size} onClick={handleClick} disabled={areControlsDisabled} />
        </>
    );
};

AddBedElement.propTypes = {
    size: PropTypes.oneOf([40, 84]).isRequired,
};
