import Slide from '@mui/material/Slide';
import {useDispatch, useSelector} from 'react-redux';
import classes from './GenericTaskController.module.scss';
import {GenericTaskVariationInfo} from './GenericTaskVariationInfo';
import {Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {VoiceLevelHighIcon, VoiceLevelLowIcon, VoiceLevelMediumIcon} from '../../../icons';
import {Recorder} from '../../recorder/component/Recorder';
import {GenericTaskActions} from '../store/generic-task.action';
import {GenericTaskSelectors} from '../store/generic-task.selector';
import {VOICE_MOODS} from '../utils/generic-task.constants';

const ExpirationDate = () => {
    const selectedGenericTaskVariationId = useSelector(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const genericTaskVariation = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector,
        selectedGenericTaskVariationId,
    );
    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        genericTaskVariation?.genericTaskId,
    );

    return (
        <Typography variant="h6" hasGutters={false} hasLineHeight={false}>
            <Typography color="white">
                Expires at&nbsp;
            </Typography>

            <Typography color="white" weight={500}>
                {genericTask?.formattedValidUntil}
            </Typography>
        </Typography>
    );
};

const VoiceMood = () => {
    const selectedGenericTaskVariationId = useSelector(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const genericTaskVariation = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector,
        selectedGenericTaskVariationId,
    );
    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        genericTaskVariation?.genericTaskId,
    );

    return (
        <div className={classes.voiceMoodRoot}>
            <Typography size={14} color="white" transform="uppercase" weight={500} hasLineHeight={false}>
                {VOICE_MOODS[genericTask?.voiceMood]}
            </Typography>
        </div>
    );
};

const SpeakerText = () => {
    const selectedGenericTaskVariationId = useSelector(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const genericTaskVariation = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector,
        selectedGenericTaskVariationId,
    );
    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        genericTaskVariation?.genericTaskId,
    );

    const ICONS = {
        LOW: <VoiceLevelLowIcon />,
        MIDDLE: <VoiceLevelMediumIcon />,
        HIGH: <VoiceLevelHighIcon />,
    };

    return (
        <div className={classes.speakerTextRoot}>
            <div className={classes.icon}>
                {ICONS[genericTask?.initialVoiceRange]}
            </div>

            <Typography size={14} color="white" transform="uppercase" weight={500} hasLineHeight={false}>
                {genericTaskVariation?.text}
            </Typography>

            <div className={classes.icon}>
                {ICONS[genericTask?.finalVoiceRange]}
            </div>
        </div>
    );
};

export const GenericTaskController = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector(GenericTaskSelectors.selectIsControllerOpen);

    return (
        <Slide in={isOpen} direction="up">
            <div className={classes.root}>
                <div className={classes.topRow}>
                    <div>
                        <GenericTaskVariationInfo />
                    </div>

                    <div>
                        <ExpirationDate />
                    </div>
                </div>

                <div className={classes.bottomRow}>
                    <div>
                        <VoiceMood />

                        <div>
                            <SpeakerText />
                        </div>
                    </div>

                    <div className={classes.recorder}>
                        <Recorder
                            onClose={() => {
                                dispatch(GenericTaskActions.setIsControllerOpen(false));
                            }}
                            // onPrevious={() => {
                            //     dispatch(GenericTaskActions.goToPreviousGenericTaskVariation());
                            // }}
                            // onNext={() => {
                            //     dispatch(GenericTaskActions.goToNextGenericTaskVariation());
                            // }}
                            onUpload={blob => {
                                dispatch(GenericTaskActions.scheduleUpload({
                                    blob,
                                }));
                            }}
                        />
                    </div>
                </div>
            </div>
        </Slide>
    );
};
