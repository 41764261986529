import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useDispatch} from 'react-redux';
import classes from './CrossChannelTaskModal.module.scss';
import {Checkbox, PrimaryButton, Typography} from '../../../../components';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {ReminderKeys, reminderStorage, useReminderStorageValue} from '../../../../utils/reminder-storage';

export const CrossChannelTaskModal = () => {
    const dispatch = useDispatch();

    const isOpen = useMemoizedCreateSelector(
        UiSelectors.createActiveModalSelector,
        ModalKeys.CROSS_CHANNEL_TASK_DIALOG,
    );

    const reminderValue = useReminderStorageValue(ReminderKeys.CROSS_CHANNEL_TASK_DIALOG);

    return (
        <Modal
            open={isOpen}
            onClose={() => {}}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div>
                    <div className={classes.root}>
                        <Typography
                            transform="uppercase"
                            weight={500}
                            color="regent"
                            className={classes.title}
                            variant="h1"
                        >
                            Cross channel task
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={true}>
                            The usage of this task is not restricted to a channel.
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={true}>
                            The task may potentially be re-used on multiple channels of this brand.
                        </Typography>

                        <div className={classes.actions}>
                            <Checkbox
                                checked={reminderValue}
                                onChange={event => {
                                    reminderStorage.setValue(
                                        ReminderKeys.CROSS_CHANNEL_TASK_DIALOG,
                                        event.target.checked,
                                    );
                                }}
                            >
                                Do not remind me again
                            </Checkbox>

                            <PrimaryButton
                                onClick={() => {
                                    dispatch(UiActions.setModalState(ModalKeys.CROSS_CHANNEL_TASK_DIALOG, false));
                                }}
                            >
                                Okay
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
