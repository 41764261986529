import UpdateSubscriber from './UpdateSubscriber';

class UpdateSubscriptions {
    #subscribers = [];

    #nextId = 0;

    #batchedSubscriptionTypes = new Set();
    #hasPendingUpdates = false;
    #queue = [];

    /**
     * @param callback
     * @param {SUBSCRIPTION_TYPES} subscriptionType
     * @param {boolean} shouldNotifyInitially [shouldNotifyInitially=true]
     * @param {object} options
     * @returns {UpdateSubscriber}
     */
    subscribe(callback, subscriptionType, shouldNotifyInitially = true, options) {
        const id = this.#nextId;
        this.#nextId += 1;

        const subscriber = new UpdateSubscriber(id, subscriptionType, callback, this, options);

        this.#subscribers.push(subscriber);

        if (shouldNotifyInitially) {
            // Notify for initial state
            subscriber.notify();
        }

        return subscriber;
    }

    /**
     * @param {UpdateSubscriber} updateSubscriber
     */
    unsubscribe(updateSubscriber) {
        this.#subscribers = this.#subscribers.filter(subscriber => subscriber.id !== updateSubscriber.id);
    }

    /**
     * @param {SUBSCRIPTION_TYPES[]} subscriptionTypes
     */
    notify(subscriptionTypes) {
        subscriptionTypes.forEach(type => this.#batchedSubscriptionTypes.add(type));

        this.#queue.push(() => {
            this.#subscribers.forEach(subscriber => {
                if (this.#batchedSubscriptionTypes.has(subscriber.subscriptionType)) {
                    subscriber.notify();
                }
            });

            this.#batchedSubscriptionTypes.clear();
        });

        if (!this.#hasPendingUpdates) {
            this.#hasPendingUpdates = true;
            Promise.resolve().then(() => this.#runQueue());
        }
    }

    #runQueue() {
        while (this.#queue.length) {
            const fn = this.#queue.shift();
            fn();
        }

        this.#hasPendingUpdates = false;
    }
}

export default UpdateSubscriptions;
