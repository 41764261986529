import clsx from 'clsx';
import {useSelector} from 'react-redux';
import classes from './TimeAnnouncementsScreen.module.scss';
import {Container, Typography} from '../../../components';
import {GenericProductionOrders} from '../../../features/generic-task/component/GenericProductionOrders';
import {GenericTaskController} from '../../../features/generic-task/component/GenericTaskController';
import {GenericTaskSelectors} from '../../../features/generic-task/store/generic-task.selector';
import {LOADING_TYPES, useIsLoading} from '../../../features/loading';
import {Player} from '../../../features/player';
import {LoadingBars} from '../../../icons';

const NoDataContainer = () => {
    const isLoading = useIsLoading(LOADING_TYPES.GENERIC_TASKS);
    const genericProductionOrderIds = useSelector(GenericTaskSelectors.selectGenericProductionOrderIds);

    if (genericProductionOrderIds.length || isLoading) {
        return null;
    }

    return (
        <Container>
            <div className={classes.allTasksDoneContainer}>
                <Typography weight={500} size={24} align="center" variant="body" color="radiance" hasGutters={false}>
                    Congratulations! 🎉
                </Typography>

                <Typography size={13} align="center" variant="body" color="trout">
                    You have no time announcement tasks at the moment.

                    <br />
                    <br />

                    The application will automatically load your tasks as soon as they become available.
                </Typography>
            </div>
        </Container>
    );
};

const MainContentWrapper = () => {
    const isLoading = useIsLoading(LOADING_TYPES.GENERIC_TASKS);

    if (isLoading) {
        return (
            <div className={classes.loading}>
                <LoadingBars size={48} color="radiance" />
            </div>
        );
    }

    return (
        <>
            <NoDataContainer />

            <GenericProductionOrders />
        </>
    );
};

export const TimeAnnouncementsScreen = () => {
    const isControllerOpen = useSelector(GenericTaskSelectors.selectIsControllerOpen);

    return (
        <Container>
            <div
                className={clsx(classes.root, {
                    [classes.isControllerOpen]: isControllerOpen,
                })}
            >
                <MainContentWrapper />
            </div>

            <div className={classes.drawer}>
                <GenericTaskController />

                <Player hasFixedPosition={false} />
            </div>
        </Container>
    );
};
