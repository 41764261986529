import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useDispatch} from 'react-redux';
import classes from './UploadFileDialog.module.scss';
import {Checkbox, Input, InputGroup, LargeButton, Typography} from '../../../../components';
import {CloseIcon, UploadIcon} from '../../../../icons';
import {toastInstance} from '../../../../lib/toast';
import {TaskActions} from '../../../task/store/task.action';

const getAudioDuration = async file => {
    return new Promise((resolve, reject) => {
        try {
            const objectUrl = URL.createObjectURL(file);
            const audio = new Audio(objectUrl);

            audio.addEventListener('loadedmetadata', () => {
                resolve(Math.floor(audio.duration * 1000));
                URL.revokeObjectURL(objectUrl);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const UploadFileDialog = ({isOpen, onClose, source, taskId, taskVariationId, expirationDate}) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [duration, setDuration] = useState(0);
    const [syncOut, setSyncOut] = useState('');
    const [isAudioWrapped, setIsAudioWrapped] = useState(false);

    const onDropAccepted = useCallback(async files => {
        setDuration(await getAudioDuration(files[0]));
        setFile(files[0]);
    }, []);

    const onDropRejected = useCallback(files => {
        files.forEach(({errors}) => {
            errors.forEach(error => {
                toastInstance.error(error.message);
            });
        });
    }, []);

    const handleSubmit = () => {
        dispatch(TaskActions.completeTaskVariation(
            taskId,
            taskVariationId,
            null,
            file,
            isAudioWrapped,
            expirationDate,
            syncOut,
            source,
        ));

        handleClose();
    };

    const handleAudioWrappedChange = event => {
        setIsAudioWrapped(event.target.checked);
        setSyncOut('');
    };

    const handleClose = () => {
        onClose();

        setTimeout(() => {
            setIsAudioWrapped(false);
            setDuration(0);
            setFile(null);
            setSyncOut('');
        }, 150); // animation delay
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDropAccepted,
        onDropRejected,
        maxFiles: 1,
        accept: {
            'audio/*': ['.mp3', '.wav'],
        },
        multiple: false,
    });

    const parsedSyncOut = parseInt(syncOut, 10);
    const isValid = (isNaN(parsedSyncOut) ? 0 : parsedSyncOut) <= duration;

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div className={classes.root}>
                    <div {...getRootProps({className: clsx(classes.dropzone, {[classes.active]: isDragActive})})}>
                        <input
                            {...getInputProps()}
                            style={{
                                clip: 'rect(0 0 0 0)',
                                clipPath: 'inset(50%)',
                                height: '1px',
                                overflow: 'hidden',
                                position: 'absolute',
                                visibility: 'hidden',
                                whiteSpace: 'nowrap',
                                width: '1px',
                            }}
                        />

                        <Typography color="white" weight={500}>
                            Drop file here or click to upload
                        </Typography>
                    </div>

                    <Collapse in={!!file}>
                        <div>
                            <Typography
                                variant="body"
                                color="white"
                                size={13}
                                className={classes.fileName}
                                weight={500}
                                hasGutters={false}
                            >
                                {file?.name || null}
                            </Typography>
                        </div>
                    </Collapse>

                    <div className={classes.contentWrapper}>
                        <Checkbox
                            checked={isAudioWrapped}
                            onChange={handleAudioWrappedChange}
                            className={classes.checkbox}
                        >
                            Audio file is wrapped?
                        </Checkbox>

                        <Collapse in={isAudioWrapped} unmountOnExit>
                            <InputGroup className={classes.syncOutInputGroup} label="Sync out" inputId="syncOut">
                                <Input
                                    value={syncOut}
                                    onChange={event => {
                                        if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
                                            setSyncOut(event.target.value);
                                        }
                                    }}
                                    helperText={isValid ? 'In milliseconds, from the beginning of the audio' : `The value can not be longed than the duration of the selected audio (${duration.toFixed(0)}).`}
                                    hasError={!isValid}
                                />
                            </InputGroup>
                        </Collapse>

                        <div className={classes.actions}>
                            <LargeButton
                                startIcon={<CloseIcon />}
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </LargeButton>

                            <LargeButton
                                startIcon={<UploadIcon />}
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!file}
                            >
                                Upload
                            </LargeButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

UploadFileDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    source: PropTypes.oneOf(['task-row', 'cockpit']).isRequired,
    taskId: PropTypes.number,
    taskVariationId: PropTypes.number,
    expirationDate: PropTypes.instanceOf(moment),
};

UploadFileDialog.defaultProps = {
    expirationDate: null,
    taskVariationId: null,
    taskId: null,
};
