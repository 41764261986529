import {AdjacentElement} from './adjacent-elements.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const getPreviousElementId = ({taskVariationId}) => {
    return ddsPlanningApi
        .get(`/v1/playlist-item/previous`, {
            params: {
                taskVariation: taskVariationId,
            },
        })
        .then(result => result.data?.externalId ?? null);
};

const getNextElementId = ({taskVariationId}) => {
    return ddsPlanningApi
        .get(`/v1/playlist-item/next`, {
            params: {
                taskVariation: taskVariationId,
            },
        })
        .then(result => result.data?.externalId ?? null);
};

const getElement = ({externalId}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/external/${externalId}`)
        .then(result => AdjacentElement.fromDto(result.data));
};

export const AdjacentElementsApi = {
    getPreviousElementId,
    getNextElementId,
    getElement,
};
