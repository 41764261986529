import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './SvgIcon.module.scss';
import {COLOR_NAMES} from '../../utils';

export const SvgIcon = React.forwardRef(({className, size, color, children, ...rest}, ref) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(classes.root, className, classes[`size-${size}`], {
                [classes[`color-${color}`]]: !!color,
            })}
            viewBox="0 0 18 18"
            ref={ref}
            {...rest}
        >
            {children}
        </svg>
    );
});

SvgIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf([12, 14, 18, 24, 30, 32, 48]),
    color: PropTypes.oneOf(COLOR_NAMES),
};

SvgIcon.defaultProps = {
    className: '',
    size: 18,
    color: null,
};
