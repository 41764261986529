import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {memo} from 'react';
import {useDispatch} from 'react-redux';
import classes from './GenericProductionOrder.module.scss';
import {GenericTask} from './GenericTask';
import {IconButton, Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {ChevronDown} from '../../../icons';
import {GenericTaskActions} from '../store/generic-task.action';
import {GenericTaskSelectors} from '../store/generic-task.selector';

export const GenericProductionOrder = memo(({id}) => {
    const dispatch = useDispatch();

    const genericProductionOrder = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericProductionOrderByIdSelector,
        id,
    );

    const isExpanded = genericProductionOrder.isExpanded;

    return (
        <div
            className={clsx(classes.root, {
                [classes.isExpanded]: isExpanded,
            })}
        >
            <header className={classes.header}>
                <div className={classes.headerContent}>
                    <Typography weight={500} size={14} color="white" hasGutters={false} hasLineHeight={false}>
                        {genericProductionOrder.title}
                    </Typography>

                    <Typography weight={400} size={12} color="white" hasGutters={false} hasLineHeight={false}>
                        {genericProductionOrder.formattedRestrictions}
                    </Typography>
                </div>

                <IconButton
                    backgroundColor="transparent"
                    color="white"
                    iconSize={14}
                    hoverStyle="color-opacity"
                    onClick={() => {
                        dispatch(GenericTaskActions.setGenericProductionOrderExpansion({
                            genericProductionOrderId: id,
                            isExpanded: !isExpanded,
                        }));
                    }}
                >
                    <ChevronDown />
                </IconButton>
            </header>

            <Collapse in={isExpanded} unmountOnExit>
                <ul className={classes.list}>
                    {genericProductionOrder.genericTaskIds.map(id => {
                        return (
                            <li key={id}>
                                <GenericTask id={id} />
                            </li>
                        );
                    })}
                </ul>
            </Collapse>
        </div>
    );
});

GenericProductionOrder.propTypes = {
    id: PropTypes.number.isRequired,
};
