import {UiActionTypes} from './ui.action-type';

export const UiActions = {
    setModalState: (modalKey, state) => ({
        type: UiActionTypes.SET_MODAL_STATE,
        payload: {
            modalKey,
            state,
        },
    }),
    setFormReset: (formKey, state) => ({
        type: UiActionTypes.SET_FORM_RESET,
        payload: {
            formKey,
            state,
        },
    }),
    setScrollToElement: (key, state) => ({
        type: UiActionTypes.SET_SCROLL_TO_ELEMENT,
        payload: {
            key,
            state,
        },
    }),
};
