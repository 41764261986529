import moment from 'moment';

/**
 * Determines if the task is schedule in the next 15 (default) minutes
 *
 * @param {string} startDate
 * @param {string} startTime
 * @returns {boolean}
 */
export const isTaskUrgent = (startDate, startTime) => {
    const now = moment();
    const endTime = moment().add(15, 'minute');
    const startDateTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');

    return startDateTime.isSameOrAfter(now) && startDateTime.isSameOrBefore(endTime);
};
