import {CockpitActionTypes} from './cockpit.action-type';

export const CockpitActions = {
    setMainContentComponent: component => ({
        type: CockpitActionTypes.SET_MAIN_CONTENT_COMPONENT,
        payload: component,
    }),
    setIsEditingSpeakerText: isEditingSpeakerText => ({
        type: CockpitActionTypes.SET_IS_EDITING_SPEAKER_TEXT,
        payload: isEditingSpeakerText,
    }),
    setAreControlsPopped: areControlsPopped => ({
        type: CockpitActionTypes.SET_ARE_CONTROLS_POPPED,
        payload: areControlsPopped,
    }),
    setAreElementsPopped: areElementsPopped => ({
        type: CockpitActionTypes.SET_ARE_ELEMENTS_POPPED,
        payload: areElementsPopped,
    }),
};
