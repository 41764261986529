import {useEffect, useRef, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';

export const useTaskDuration = () => {
    const [duration, setDuration] = useState(0);
    const subscriber = useRef(null);

    useEffect(() => {
        const onUpdate = ({duration}) => {
            setDuration(duration);
        };

        subscriber.current = playbackAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.TASK_DURATION);

        return () => {
            subscriber.current.unsubscribe();
        };
    }, []);

    return {duration: duration || 30000};
};
