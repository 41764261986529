import {combineReducers} from 'redux';
import {UiActionTypes} from './ui.action-type';

const activeModalsReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_MODAL_STATE) {
        return {
            ...state,
            [payload.modalKey]: payload.state,
        };
    }

    return state;
};

const formResetsReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_FORM_RESET) {
        return {
            ...state,
            [payload.formKey]: payload.state,
        };
    }

    return state;
};

const scrollToElementsReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_SCROLL_TO_ELEMENT) {
        return {
            ...state,
            [payload.key]: payload.state,
        };
    }

    return state;
};

export const uiReducer = combineReducers({
    activeModals: activeModalsReducer,
    formResets: formResetsReducer,
    scrollToElements: scrollToElementsReducer,
});
