import clsx from 'clsx';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import classes from './Link.module.scss';

export const Link = forwardRef(({className, children, ...rest}, ref) => {
    return (
        <RouterLink
            ref={ref}
            className={clsx(
                classes.root,
                className,
            )}
            {...rest}
        >
            {children}
        </RouterLink>
    );
});

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
};

Link.defaultProps = {
    className: '',
};
