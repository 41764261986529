import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './View.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {PlaylistActions} from '../../../../features/playlist/store/playlist.action';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {ChevronDown} from '../../../../icons';
import {Entry} from '../Entry/Entry';
import {TaskNumberIndicator} from '../TaskNumberIndicator/TaskNumberIndicator';

export const View = ({viewKey, viewIndex}) => {
    const view = useMemoizedCreateSelector(PlaylistSelectors.createViewByKeySelector, viewKey);

    const dispatch = useDispatch();

    return (
        <motion.div
            className={clsx(classes.root, {
                [classes.expanded]: view.isExpanded,
            })}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5, delay: viewIndex * 0.015}}
        >
            <header className={classes.header}>
                <IconButton
                    color="white"
                    backgroundColor="transparent"
                    size={32}
                    iconSize={18}
                    className={classes.expandButton}
                    onClick={() => dispatch(PlaylistActions.toggleViewCollapse(viewKey))}
                    hoverStyle="color"
                >
                    <ChevronDown />
                </IconButton>

                <Typography color="white" weight={500} variant="body" className={classes.times}>
                    [{view.startTime}&nbsp;&ndash;&nbsp;{view.endTime}]
                </Typography>

                <Typography color="white" weight={500} variant="body" className={classes.name}>
                    {view.name}
                </Typography>

                {!!view.numberOfTasks && (
                    <TaskNumberIndicator
                        count={view.numberOfOpenTasks}
                        noTasksColor="white"
                        color={view.hasUrgentTasks ? 'orange' : 'default'}
                    />
                )}
            </header>

            <Collapse in={view.isExpanded}>
                <div className={classes.content}>
                    <div className={classes.entries}>
                        {view.entryKeys.map(entryKey => <Entry key={entryKey} entryKey={entryKey} />)}
                    </div>
                </div>
            </Collapse>
        </motion.div>
    );
};

View.propTypes = {
    viewKey: PropTypes.string.isRequired,
    viewIndex: PropTypes.number.isRequired,
};
