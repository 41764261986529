const selectStatus = state => state.preloading.status;

const selectProgress = state => state.preloading.progress;

const selectIsAlertDisplayed = state => state.preloading.isAlertDisplayed;

const selectRemainingWaitTime = state => state.preloading.remainingWaitTime;

export const PreloadingSelectors = {
    selectStatus,
    selectProgress,
    selectIsAlertDisplayed,
    selectRemainingWaitTime,
};
