export const userSwitcher = (() => {
    let canSwitch = false;
    let email = null;

    const setEmail = userEmail => {
        email = userEmail;
    };

    const getEmail = () => {
        return email;
    };

    const setCanSwitch = ability => {
        canSwitch = ability;
    };

    const getCanSwitch = () => {
        return canSwitch;
    };

    const getIsSwitching = () => {
        return !!email && canSwitch;
    };

    return {
        setEmail,
        getEmail,
        setCanSwitch,
        getCanSwitch,
        getIsSwitching,
    };
})();
