import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {AdjacentElement} from './AdjacentElement';
import {SUBSCRIPTION_TYPES} from '../../../../constants';
import {AdjacentElementsSelectors} from '../../../../features/adjacent-elements/store/adjacent-elements.selector';
import recordingAlgorithm from '../../../../features/recording-algorithm/RecordingAlgorithm';

export const NextElement = () => {
    const nextElement = useSelector(AdjacentElementsSelectors.selectNextElement);
    const [progress, setProgress] = useState(0);
    const [length, setLength] = useState(0);
    const [playingPosition, setPlayingPosition] = useState(0);
    const [countdownDirection, setCountdownDirection] = useState('positive');

    const onUpdate = useCallback(({progress, length, countdownDirection, playingPosition, hasNode}) => {
        if (hasNode) {
            setProgress(progress);
            setLength(length);
            setCountdownDirection(countdownDirection);
            setPlayingPosition(playingPosition);
        } else {
            if (!nextElement) {
                return;
            }

            const introLength = nextElement.introLength ?? 0;
            const cueIn = nextElement.cueIn ?? 0;
            const duration = introLength - cueIn;

            setProgress(duration > 0 ? 100 : 0);
            setLength(duration / 1000);
            setCountdownDirection(duration > 0 ? 'negative' : 'positive');
            setPlayingPosition(duration / 1000);
        }
    }, [nextElement]);

    useEffect(() => {
        const subscriber = recordingAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA);

        return () => subscriber.unsubscribe();
    }, [onUpdate]);

    const calculatedLength = useMemo(() => {
        let duration = length - (length - playingPosition);

        if (duration < 0) {
            duration = 0;
        }

        if (countdownDirection === 'negative') {
            duration = playingPosition;
        }

        return duration;
    }, [length, countdownDirection, playingPosition]);

    return (
        <AdjacentElement
            position="next"
            elementId={nextElement?.id}
            title={nextElement?.title}
            length={calculatedLength}
            countdownDirection={countdownDirection}
            progress={progress}
        />
    );
};
