import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {AdjacentElement} from './AdjacentElement';
import {SUBSCRIPTION_TYPES} from '../../../../constants';
import {AdjacentElementsSelectors} from '../../../../features/adjacent-elements/store/adjacent-elements.selector';
import {calculateBumperOffset} from '../../../../features/recording-algorithm/audioContextHelpers';
import recordingAlgorithm from '../../../../features/recording-algorithm/RecordingAlgorithm';

export const PreviousElement = () => {
    const previousElement = useSelector(AdjacentElementsSelectors.selectPreviousElement);
    const [progress, setProgress] = useState(0);
    const [length, setLength] = useState(0);
    const [playingPosition, setPlayingPosition] = useState(0);
    const [countdownDirection, setCountdownDirection] = useState('positive');

    const onUpdate = useCallback(({progress, length, countdownDirection, playingPosition, hasNode}) => {
        if (hasNode) {
            setProgress(progress);
            setLength(length);
            setCountdownDirection(countdownDirection);
            setPlayingPosition(playingPosition);
        } else {
            if (!previousElement) {
                return;
            }

            const startNext = previousElement.startNext ?? 0;
            const cueIn = previousElement.cueIn ?? 0;
            const duration = (startNext + cueIn) / 1000;
            const offset = calculateBumperOffset(
                previousElement.length,
                previousElement.cueIn,
                previousElement.startNext,
            ) / 1000;

            setProgress(duration > 0 ? 100 : 0);
            setLength(duration - offset);
            setCountdownDirection(duration > 0 ? 'negative' : 'positive');
            setPlayingPosition(duration - offset);
        }
    }, [previousElement]);

    useEffect(() => {
        const subscriber = recordingAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA);

        return () => subscriber.unsubscribe();
    }, [onUpdate]);

    const calculatedLength = useMemo(() => {
        let duration = length - (length - playingPosition);

        if (duration < 0) {
            duration = 0;
        }

        if (countdownDirection === 'negative') {
            duration = playingPosition;
        }

        return duration;
    }, [length, countdownDirection, playingPosition]);

    return (
        <AdjacentElement
            position="previous"
            elementId={previousElement?.id}
            title={previousElement?.title}
            length={calculatedLength}
            countdownDirection={countdownDirection}
            progress={progress}
        />
    );
};
