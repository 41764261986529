import moment from 'moment';
import {schema} from 'normalizr';
import {formatMillisecondsAsTimestamp} from '../../../utils';
import {User} from '../../user';
import {TaskStatuses} from '../utils/task-statuses';

export const TaskMetadata = function TaskMetadata(dto) {
    this.contentTypeId = dto.contentTypeId;
    this.formattedScheduledLength = formatMillisecondsAsTimestamp(dto.scheduledLength);
    this.hostRoleName = dto.hostRoleName;
    this.scheduledLength = dto.scheduledLength;
    this.voiceLevelStart = dto.voiceLevelStart;
    this.voiceLevelEnd = dto.voiceLevelEnd;
    this.voiceMood = dto.voiceMood;
};

TaskMetadata.fromDto = dto => {
    const contentTypeId = dto.contentType;
    const hostRoleName = dto.hostRole.name;
    const scheduledLength = dto.scheduledLength;
    const voiceLevelStart = dto.voiceLevelStart;
    const voiceLevelEnd = dto.voiceLevelEnd;
    const voiceMood = dto.voiceMood;

    return new TaskMetadata({
        contentTypeId,
        hostRoleName,
        scheduledLength,
        voiceLevelStart,
        voiceLevelEnd,
        voiceMood,
    });
};

export const Tag = function Tag(tagDto) {
    this.id = tagDto.id;
};

Tag.fromDto = dto => {
    const id = dto.id;

    return new Tag({id});
};

export const Variation = function Variation(dto) {
    this.externalId = dto.externalId;
    this.id = dto.id;
    this.isCompleted = dto.isCompleted;
    this.duration = dto.duration;
    this.formattedDuration = this.duration ? formatMillisecondsAsTimestamp(this.duration) : 0;
    this.speakerText = dto.lexicalSpeakerText || dto.speakerText;
    this.preparedSpeakerText = dto.lexicalPreparedSpeakerText || dto.preparedSpeakerText;
    this.isProcessing = dto.isProcessing;
    this.hasMixingPlan = dto.hasMixingPlan;
    this.filename = dto.filename;

    if (dto.lexicalSpeakerText) {
        let text = this.speakerText;
        const nodes = [];

        if (text.startsWith('<hashtag>')) {
            text = text
                .replaceAll(/(?!^)<hashtag>/g, '</text><hashtag>')
                .replaceAll('</hashtag>', '</hashtag><text>');
            text += '</text>';
        } else {
            text = '<text>' + text;
            text = text
                .replaceAll('<hashtag>', '</text><hashtag>')
                .replaceAll('</hashtag>', '</hashtag><text>');
            text += '</text>';
        }

        // Remove all empty texts
        text = text.replaceAll('<text></text>', '');

        const matches = text.matchAll(/<(hashtag|text)>(.*?)<\/(hashtag|text)>/gm);

        for (const match of matches) {
            if (match[1]) {
                nodes.push({
                    value: match[2],
                    type: match[1],
                });
            }
        }

        this.speakerText = JSON.stringify({
            root: {
                children: [
                    {
                        children: [
                            ...nodes.map(node => {
                                if (node.type === 'hashtag') {
                                    return {
                                        type: 'hashtag',
                                        text: node.value,
                                        originalText: node.value,
                                    };
                                }

                                return {
                                    mode: 'normal',
                                    type: 'text',
                                    text: node.value,
                                    version: 1,
                                    detail: 0,
                                    format: 0,
                                    style: '',
                                };
                            }),
                        ],
                        direction: 'ltr',
                        format: '',
                        indent: 0,
                        type: 'paragraph',
                        version: 1,
                    },
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'root',
                version: 1,
            },
        });
    }
};

Variation.fromDto = dto => {
    const externalId = dto.taskItem?.audioExternalId;
    const id = dto.id;
    const isCompleted = dto.isCompleted;
    const isProcessing = dto.taskItem?.isProcessing;
    const duration = dto.taskItem?.duration;
    const lexicalSpeakerText = dto.lexicalSpeakerText;
    const lexicalPreparedSpeakerText = dto.lexicalPreparedSpeakerText;
    const hasMixingPlan = dto.taskItem?.hasMixingPlan;
    const filename = dto.taskItem?.fileName;

    return new Variation({
        externalId,
        id,
        isCompleted,
        isProcessing,
        duration,
        lexicalSpeakerText,
        lexicalPreparedSpeakerText,
        hasMixingPlan,
        filename,
    });
};

export const Task = function Task(dto) {
    this.description = dto.description;
    this.hasUncompletedVariations = dto.hasUncompletedVariations;
    this.hasVariations = dto.hasVariations;
    this.id = dto.id;
    this.isCompleted = dto.isCompleted;
    this.isReusable = dto.isReusable;
    this.metadata = dto.metadata;
    this.status = dto.status;
    // this.tag = dto.tag;
    this.user = dto.user;
    this.variations = dto.variations;
    this.isCrossChannelTask = dto.isCrossChannelTask;

    this.scheduledAt = dto.scheduledAt;
    this.formattedScheduledAt = this.scheduledAt.format('ddd. DD.MM. HH:mm');

    this.alternativeTaskIds = dto.alternativeTaskIds || [];

    this.expirationDate = dto.expirationDate;
    this.formattedExpirationDate = this.expirationDate ? this.expirationDate.format('DD.MM.YYYY HH:mm') : null;
    this.isExpirationDateOverridden = dto.isExpirationDateOverridden;
    this.overriddenExpirationDate = dto.overriddenExpirationDate;
    this.formattedOverriddenExpirationDate = this.overriddenExpirationDate ? this.overriddenExpirationDate.format('DD.MM.YYYY HH:mm') : null;
};

Task.fromDto = dto => {
    const description = dto.description;
    const hasUncompletedVariations = dto.variations.some(variation => !variation.isCompleted);
    const hasVariations = dto.variations.length === 3;
    const id = dto.id;
    const isCompleted = dto.isCompleted;
    const isReusable = !dto.metadata.isOneTimeItem;
    const metadata = TaskMetadata.fromDto(dto.metadata);
    const scheduledAt = moment(dto.scheduledAt);
    // const tag = Tag.fromDto(dto.tags);
    const user = User.fromDto(dto.user);
    const variations = dto.variations.map(variationDto => {
        return Variation.fromDto(variationDto);

        // const speakerText = JSON.parse(variation.speakerText);
        //
        // speakerText.root.children[0].children = [
        //     {
        //         type: 'voice-level',
        //         level: productionOrder.voiceLevelStart,
        //     },
        //     ...speakerText.root.children[0].children,
        //     {
        //         type: 'voice-level',
        //         level: productionOrder.voiceLevelEnd,
        //     },
        // ];
        //
        // variation.speakerText = JSON.stringify(speakerText);
        //
        // if (variation.preparedSpeakerText) {
        //     const preparedSpeakerText = JSON.parse(variation.preparedSpeakerText);
        //
        //     preparedSpeakerText.root.children[0].children = [
        //         {
        //             type: 'voice-level',
        //             level: productionOrder.voiceLevelStart,
        //         },
        //         ...preparedSpeakerText.root.children[0].children,
        //         {
        //             type: 'voice-level',
        //             level: productionOrder.voiceLevelEnd,
        //         },
        //     ];
        //
        //     variation.preparedSpeakerText = JSON.stringify(preparedSpeakerText);
        // }
        //
        // return variation;
    });

    const alternativeTaskIds = dto.alternativeTasks;
    const expirationDate = dto.expirationDate ? moment(dto.expirationDate) : null;
    const isExpirationDateOverridden = !!dto.overriddenExpirationDate;
    const overriddenExpirationDate = isExpirationDateOverridden ? moment(dto.overriddenExpirationDate) : null;
    const isProcessing = variations.some(variation => variation.isProcessing);
    const isCrossChannelTask = !dto.metadata.restrictions.includes('#channel');
    let status = TaskStatuses.NOT_COMPLETED;

    if (isProcessing) {
        status = TaskStatuses.PROCESSING_AUDIO;
    } else if (isCompleted) {
        status = TaskStatuses.COMPLETED;
    }

    return new Task({
        description,
        hasUncompletedVariations,
        hasVariations,
        id,
        isCompleted,
        isReusable,
        metadata,
        scheduledAt,
        status,
        // tag,
        user,
        variations,
        alternativeTaskIds,
        expirationDate,
        isExpirationDateOverridden,
        overriddenExpirationDate,
        isCrossChannelTask,
    });
};

export const AlternativeTask = function AlternativeTask(dto) {
    this.description = dto.description;
    this.hasVariations = dto.hasVariations;
    this.hasUncompletedVariations = dto.hasUncompletedVariations;
    this.isCompleted = dto.isCompleted;
    this.isReusable = dto.isReusable;
    this.id = dto.id;
    this.status = dto.status;
    this.variations = dto.variations;
    this.isAlternative = true;
    this.metadata = dto.metadata;
    // this.tag = dto.tag;
    this.user = dto.user;

    this.scheduledAt = dto.scheduledAt;
    this.formattedScheduledAt = this.scheduledAt.format('ddd. DD.MM. HH:mm');

    this.validFrom = dto.validFrom;
    this.formattedValidFrom = this.validFrom.format('DD.MM.YYYY HH:mm');
    this.validUntil = dto.validUntil;
    this.formattedValidUntil = this.validUntil.format('DD.MM.YYYY HH:mm');
};

AlternativeTask.fromDto = dto => {
    const description = dto.description;
    const hasVariations = dto.variations.length === 3;
    const hasUncompletedVariations = dto.variations.some(variation => !variation.isCompleted);
    const id = dto.id;
    const isReusable = !dto.metadata.isOneTimeItem;
    const isCompleted = dto.isCompleted;
    const validFrom = moment.utc(dto.validFrom);
    const validUntil = moment.utc(dto.validUntil);
    const variations = dto.variations.map(variationDto => {
        return Variation.fromDto(variationDto);

        // const speakerText = JSON.parse(variation.speakerText);
        //
        // speakerText.root.children[0].children = [
        //     {
        //         type: 'voice-level',
        //         level: productionOrder.voiceLevelStart,
        //     },
        //     ...speakerText.root.children[0].children,
        //     {
        //         type: 'voice-level',
        //         level: productionOrder.voiceLevelEnd,
        //     },
        // ];
        //
        // variation.speakerText = JSON.stringify(speakerText);
        //
        // if (variation.preparedSpeakerText) {
        //     const preparedSpeakerText = JSON.parse(variation.preparedSpeakerText);
        //
        //     preparedSpeakerText.root.children[0].children = [
        //         {
        //             type: 'voice-level',
        //             level: productionOrder.voiceLevelStart,
        //         },
        //         ...preparedSpeakerText.root.children[0].children,
        //         {
        //             type: 'voice-level',
        //             level: productionOrder.voiceLevelEnd,
        //         },
        //     ];
        //
        //     variation.preparedSpeakerText = JSON.stringify(preparedSpeakerText);
        // }
        //
        // return variation;
    });
    const metadata = TaskMetadata.fromDto(dto.metadata);
    // const tag = Tag.fromDto(dto.tags);
    const user = User.fromDto(dto.user);
    const scheduledAt = moment(dto.scheduledAt);
    const isProcessing = variations.some(variation => variation.isProcessing);
    let status = TaskStatuses.NOT_COMPLETED;

    if (isProcessing) {
        status = TaskStatuses.PROCESSING_AUDIO;
    } else if (isCompleted) {
        status = TaskStatuses.COMPLETED;
    }

    return new AlternativeTask({
        description,
        hasVariations,
        hasUncompletedVariations,
        id,
        isCompleted,
        isReusable,
        status,
        validFrom,
        validUntil,
        variations,
        metadata,
        // tag,
        user,
        scheduledAt,
    });
};

export const AlternativeTaskSchema = new schema.Entity('alternativeTasks');

export const TaskSchema = new schema.Entity('tasks', {alternativeTasks: [AlternativeTaskSchema]});
