export const getIsUsingPreferredDevices = () => {
    return new Promise((resolve, reject) => {
        try {
            const inputDeviceId = localStorage.getItem('inputDeviceId');

            window.navigator.mediaDevices.getUserMedia({
                audio: inputDeviceId ? {deviceId: inputDeviceId} : true,
            }).then(stream => {
                const outputDeviceId = localStorage.getItem('outputDeviceId');

                const audioElement = document.createElement('audio');
                audioElement.srcObject = stream;

                const tracks = stream.getTracks();
                const track = tracks[0];
                const settings = track.getSettings();
                const currentInputDeviceId = settings.deviceId;

                if (outputDeviceId) {
                    audioElement.setSinkId(outputDeviceId).then(() => {
                        const currentOutputDeviceId = audioElement.sinkId;

                        if (inputDeviceId !== currentInputDeviceId || outputDeviceId !== currentOutputDeviceId) {
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    });
                } else if (inputDeviceId !== currentInputDeviceId) {
                    resolve(false);
                } else {
                    resolve(false);
                }

                // Stop all tracks after we're done with them
                stream.getTracks().forEach(track => track.stop());
            });
        } catch (error) {
            reject(error);
        }
    });
};
