import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classes from './VolumeSlider.module.scss';

const constants = {
    orientation: {
        horizontal: {
            dimension: 'width',
            direction: 'left',
            coordinate: 'x',
        },
        vertical: {
            dimension: 'height',
            direction: 'top',
            coordinate: 'y',
        },
    },
};

const capitalize = str => str.charAt(0).toUpperCase() + str.substr(1);

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const grab = 18;

export const VolumeSlider = ({color, orientation, min, max, value, onChange}) => {
    const sliderRef = useRef();
    const handleRef = useRef();

    const [isActive, setIsActive] = useState(false);
    const [limit, setLimit] = useState(1);

    const handleUpdate = useCallback(() => {
        const dimension = capitalize(constants.orientation[orientation].dimension);
        const sliderPosition = sliderRef.current[`offset${dimension}`];
        const handlePosition = handleRef.current[`offset${dimension}`];

        setLimit(sliderPosition - handlePosition);
    }, [orientation]);

    const getValueFromPosition = useCallback(position => {
        const percentage = clamp(position, 0, limit) / (limit || 1);
        const baseVal = Math.round(percentage * (max - min));
        const value = orientation === 'horizontal' ? baseVal + min : max - baseVal;

        return clamp(value, min, max);
    }, [limit, max, min, orientation]);

    const getPositionFromValue = useCallback(value => {
        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;

        return Math.round(percentage * limit);
    }, [limit, max, min]);

    const calculatePosition = useCallback(event => {
        const reverse = false;

        const coordinateStyle = constants.orientation[orientation].coordinate;
        const directionStyle = constants.orientation[orientation].direction;
        const clientCoordinateStyle = `client${capitalize(coordinateStyle)}`;
        const coordinate = !event.touches ? event[clientCoordinateStyle] : event.touches[0][clientCoordinateStyle];
        const direction = sliderRef.current?.getBoundingClientRect()[directionStyle];
        const pos = reverse ? direction - coordinate - grab : coordinate - direction - grab;

        return getValueFromPosition(pos);
    }, [getValueFromPosition, orientation]);

    const handleDrag = useCallback(event => {
        event.stopPropagation();

        onChange(calculatePosition(event), event);
    }, [calculatePosition, onChange]);

    const handleEnd = useCallback(() => {
        setIsActive(false);

        sliderRef.current.removeEventListener('mousemove', handleDrag);
        sliderRef.current.removeEventListener('mouseup', handleEnd);
    }, [handleDrag]);

    const handleStart = useCallback(() => {
        sliderRef.current.addEventListener('mousemove', handleDrag);
        sliderRef.current.addEventListener('mouseup', handleEnd);

        setIsActive(true);
    }, [handleDrag, handleEnd]);

    const getCoordinates = useCallback(pos => {
        const value = getValueFromPosition(pos);
        const position = getPositionFromValue(value);
        const handlePosition = orientation === 'horizontal' ? position + grab : limit - position;
        const trackPosition = orientation === 'horizontal' ? handlePosition : handlePosition;

        return {
            track: trackPosition,
            handle: handlePosition,
        };
    }, [getPositionFromValue, getValueFromPosition, limit, orientation]);

    const coordinates = useMemo(() => {
        return getCoordinates(getPositionFromValue(value));
    }, [getCoordinates, getPositionFromValue, value]);

    const handleStyle = useMemo(() => {
        if (orientation === 'horizontal') {
            return {
                left: `${coordinates.handle + grab}px`,
            };
        }

        return {
            bottom: `${coordinates.handle + grab}px`,
        };
    }, [orientation, coordinates.handle]);

    const trackStyle = useMemo(() => {
        if (orientation === 'horizontal') {
            return {
                width: `${coordinates.track + grab}px`,
            };
        }

        return {
            height: `${coordinates.track + grab}px`,
        };
    }, [orientation, coordinates.track]);

    useEffect(() => {
        handleUpdate();

        const resizeObserver = new ResizeObserver(handleUpdate);
        resizeObserver.observe(sliderRef.current);

        const copiedSliderRef = sliderRef.current;

        return () => {
            resizeObserver.unobserve(copiedSliderRef);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={clsx(classes.root, classes[`color-${color}`], {
                [classes.vertical]: orientation === 'vertical',
                [classes.active]: isActive,
            })}
            ref={sliderRef}
        >
            <div
                className={classes.main}
                onMouseDown={handleDrag}
                onMouseUp={handleEnd}
                onTouchStart={handleStart}
                onTouchEnd={handleEnd}
            >
                <div className={classes.ticks} tabIndex={-1}>
                    <div className={classes.tick} />

                    <div className={classes.tick} />

                    <div className={classes.tick} />
                </div>

                <div style={trackStyle} className={classes.track} />

                <div className={classes.rail} />

                <div
                    style={handleStyle}
                    className={classes.handle}
                    ref={handleRef}
                    onMouseDown={handleStart}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleEnd}
                />
            </div>
        </div>
    );
};

VolumeSlider.propTypes = {
    color: PropTypes.oneOf(['rose', 'sun', 'turquoise']),
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
};

VolumeSlider.defaultProps = {
    color: 'rose',
    orientation: 'horizontal',
    min: 1,
    max: 3,
};
