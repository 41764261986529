import {useSelector} from 'react-redux';
import classes from './PlaylistView.module.scss';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {PlaylistSelectors} from '../../../../features/playlist';
import {LoadingBars} from '../../../../icons';
import {Show} from '../Show/Show';
import {TaskList} from '../TaskList/TaskList';

export const PlaylistView = () => {
    const showKeys = useSelector(PlaylistSelectors.selectShowKeys);
    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);
    const isLoading = useIsLoading(LOADING_TYPES.PLAYLIST);

    if (isLoading) {
        return (
            <div className={classes.root}>
                <div className={classes.loading}>
                    <LoadingBars size={48} color="radiance" />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {showStructured && (
                <div className={classes.content}>
                    {showKeys.map((showKey, index) => {
                        return (
                            <Show
                                key={showKey}
                                showKey={showKey}
                                showIndex={index}
                            />
                        );
                    })}
                </div>
            )}

            {!showStructured && (
                <TaskList />
            )}
        </div>
    );
};
