import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classes from './FailedUploadsAlert.module.scss';
import {Container, PrimaryButton} from '../../../components';
import {CheckIcon, ErrorIcon} from '../../../icons';
import {PreloadingActions} from '../../preloading/store/preloading.action';
import {FailedUploadsActions} from '../store/failed-uploads.action';
import {FailedUploadsSelectors} from '../store/failed-uploads.selector';
import {FailedUploadStatuses} from '../utils/constants';

export const FailedUploadsAlert = () => {
    const {t} = useTranslation('featuresFailedUploads');

    const dispatch = useDispatch();

    const status = useSelector(FailedUploadsSelectors.selectStatus);
    const progress = useSelector(FailedUploadsSelectors.selectProgress);

    return (
        <div
            className={clsx(classes.root, {
                [classes.completedAllUploads]: status === FailedUploadStatuses.COMPLETED_ALL_UPLOADS,
            })}
        >
            <Container>
                <div className={classes.topContent}>
                    <div className={classes.iconWrapper}>
                        {status !== FailedUploadStatuses.COMPLETED_ALL_UPLOADS ? (
                            <ErrorIcon size={24} className={classes.errorIcon} />
                        ) : (
                            <CheckIcon size={24} className={classes.checkIcon} />
                        )}
                    </div>

                    <div className={classes.messageWrapper}>
                        <div className={classes.title}>
                            {t('alertTitle')}
                        </div>

                        <div>
                            {t(`statuses.${status}`)}
                        </div>
                    </div>

                    <div className={classes.actionWrapper}>
                        {status === FailedUploadStatuses.RETRY_IN_PROGRESS && (
                            <PrimaryButton onClick={() => dispatch(PreloadingActions.cancel())}>
                                {t('cancelButtonTitle')}
                            </PrimaryButton>
                        )}

                        {status === FailedUploadStatuses.WAITING_FOR_RETRY && (
                            <PrimaryButton onClick={() => dispatch(FailedUploadsActions.retry())}>
                                {t('retryButtonTitle')}
                            </PrimaryButton>
                        )}

                        {status === FailedUploadStatuses.COMPLETED_ALL_UPLOADS && (
                            <PrimaryButton
                                onClick={() => {
                                    dispatch(FailedUploadsActions.setStatus(FailedUploadStatuses.NO_DATA));
                                }}
                            >
                                {t('closeButtonTitle')}
                            </PrimaryButton>
                        )}
                    </div>
                </div>

                <Collapse in={status === FailedUploadStatuses.RETRY_IN_PROGRESS}>
                    <div className={classes.progressWrapper}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: 4,
                                borderRadius: 0,
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: 'transparent',
                                },
                                [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: '#0080ff',
                                },
                            }}
                        />
                    </div>
                </Collapse>
            </Container>
        </div>
    );
};
