import {createBrowserHistory} from 'history';
// eslint-disable-next-line camelcase
import {unstable_batchedUpdates} from 'react-dom';
import {createReduxHistoryContext} from 'redux-first-history';

export const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
    history: createBrowserHistory(),
    // eslint-disable-next-line camelcase
    batch: unstable_batchedUpdates,
});
