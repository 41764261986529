import {SvgIcon} from '../SvgIcon/SvgIcon';

export const SuccessIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39z"
            />
        </SvgIcon>
    );
};
