import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {MixingPlanActions} from './mixing-plan.action';
import {MixingPlanActionTypes} from './mixing-plan.action-type';
import {MixingPlanSelectors} from './mixing-plan.selector';
import {handleSagaError} from '../../../error.saga';
import {CurrentTaskSelectors} from '../../current-task';
import {EditorSelectors} from '../../editor/store/editor.selector';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';
import {TaskSelectors} from '../../task';
import {MixingPlanApi} from '../dto/mixing-plan.api';

const updateElementsPositions = (element, step) => {
    return {
        ...element,
        startAt: Math.fround(element.startAt + step),
        volumePoints: element.volumePoints.map(volumePoint => {
            let startAt = Math.fround(volumePoint.startAt + step);
            let stopAt = Math.fround(volumePoint.stopAt + step);

            if (startAt < 0) {
                startAt = 0;
            }

            if (stopAt < 0) {
                stopAt = 0;
            }

            return {
                startAt,
                stopAt,
                fromVolume: volumePoint.fromVolume,
                toVolume: volumePoint.toVolume,
            };
        }),
    };
};

const onMoveElement = function* ({payload}) {
    try {
        const originalMixingPlan = yield select(MixingPlanSelectors.selectOriginalMixingPlan);
        const mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);

        if (!originalMixingPlan) {
            // This is the first edit of mixing plan, save the original in case the user wants to discard the change
            yield put(MixingPlanActions.storeOriginal(mixingPlan.clone()));
        }

        const {direction} = payload;
        let step = yield select(EditorSelectors.selectSteps);

        if (direction === 'backward') {
            step *= -1;
        }

        const selectedElementIndex = yield select(EditorSelectors.selectSelectedElementIndex);

        let selectedElement = mixingPlan.elements.find((element, index) => {
            return index === selectedElementIndex;
        });

        let offsetDiff = 0;
        const oldStartAt = selectedElement.startAt;

        // Move selected element
        const elements = mixingPlan.elements.map((element, index) => {
            if (index !== selectedElementIndex) {
                return element;
            }

            if (element.startAt === 0 && direction === 'backward') {
                offsetDiff = 0;

                return element;
            }

            if (element.startAt + step < 0 && direction === 'backward') {
                offsetDiff = step + Math.abs(element.startAt + step);

                return {
                    ...element,
                    ...updateElementsPositions(element, offsetDiff),
                };
            }

            selectedElement = {
                ...element,
                ...updateElementsPositions(element, step),
            };

            return selectedElement;
        }).map((element, index) => {
            if (index === selectedElementIndex) {
                return element;
            }

            if (element.startAt >= oldStartAt && direction !== 'backward') {
                return {
                    ...element,
                    ...updateElementsPositions(element, step),
                };
            }

            if (element.startAt >= oldStartAt && oldStartAt !== 0) {
                if (element.startAt + step < 0) {
                    offsetDiff = step + Math.abs(element.startAt + step);

                    return {
                        ...element,
                        ...updateElementsPositions(element, offsetDiff),
                    };
                }

                return {
                    ...element,
                    ...updateElementsPositions(element, step),
                };
            }

            return element;
        });

        const newMixingPlan = mixingPlan.clone();
        newMixingPlan.elements = elements;
        newMixingPlan.recordLength += step;

        if (!newMixingPlan.isEdited) {
            const taskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
            const taskVariation = yield select(TaskSelectors.createTaskVariationByIdSelector(taskVariationId));

            // If the task variation is completed, then the mixing plan is getting edited after upload
            // If not, then the mixing plan is not yet uploaded, and we don't treat the mixing plan as edited
            if (taskVariation.isCompleted) {
                newMixingPlan.isEdited = true;
            }
        }

        yield put(MixingPlanActions.store(newMixingPlan));
        yield call([playbackAlgorithm, playbackAlgorithm.setMixingPlan], newMixingPlan, false);
    } catch (error) {
        yield call(handleSagaError, error, 'onMoveElement');
    }
};

const onReset = function* () {
    const originalMixingPlan = yield select(MixingPlanSelectors.selectOriginalMixingPlan);
    const copy = originalMixingPlan.clone();

    yield all([
        put(MixingPlanActions.store(copy)),
        put(MixingPlanActions.storeOriginal(null)),
        call([playbackAlgorithm, playbackAlgorithm.setMixingPlan], copy, false),
    ]);
};

export const getMixingPlan = function* (taskVariationId) {
    try {
        const mixingPlan = yield call(MixingPlanApi.getMixingPlan, taskVariationId);

        yield put(MixingPlanActions.store(mixingPlan, 'api'));
    } catch (error) {
        yield call(handleSagaError, error, 'getMixingPlan');
    }
};

export const mixingPlanSagas = function* () {
    yield all([
        takeEvery(MixingPlanActionTypes.MOVE_ELEMENT, onMoveElement),
        takeEvery(MixingPlanActionTypes.RESET, onReset),
    ]);
};
