import {combineReducers} from 'redux';
import {PresetActionTypes} from './preset.action-type';
import {createReducer} from '../../../utils';

export const presetReducer = combineReducers({
    entities: createReducer({}, PresetActionTypes.STORE_ENTITIES),
    loadedPreProducedPresetId: createReducer(null, PresetActionTypes.SET_LOADED_PRE_PRODUCED_PRESET_ID),
    loadedSfxPresetId: createReducer(null, PresetActionTypes.SET_LOADED_SFX_PRESET_ID),
    loadedBedPresetId: createReducer(null, PresetActionTypes.SET_LOADED_BED_PRESET_ID),
});
