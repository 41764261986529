import {createSelector} from 'reselect';

const selectEntities = state => state.preparedTaskVariation.entities;

const selectPreparingTaskId = state => state.preparedTaskVariation.preparingTaskId;

const selectPreparingTaskVariationId = state => state.preparedTaskVariation.preparingTaskVariationId;

const selectIsEditingSpeakerText = state => state.preparedTaskVariation.isEditingSpeakerText;

const selectAudioItemEntities = state => state.preparedTaskVariation.audioItemEntities;

const selectOriginalSpeakerText = state => state.preparedTaskVariation.originalSpeakerText;

const createIsPreparingTaskSelector = taskId => createSelector(
    [selectPreparingTaskId],
    preparingTaskId => taskId && preparingTaskId && taskId === preparingTaskId,
);

const createPreparedTaskVariationByTaskVariationIdSelector = taskVariationId => createSelector(
    [selectEntities],
    entities => {
        return entities[taskVariationId];
    },
);

const createElementByIdSelector = elementId => createSelector(
    [selectAudioItemEntities],
    entities => {
        return entities[elementId];
    },
);

export const PreparedTaskVariationSelectors = {
    selectEntities,
    selectPreparingTaskId,
    selectPreparingTaskVariationId,
    selectIsEditingSpeakerText,
    selectAudioItemEntities,
    selectOriginalSpeakerText,
    createIsPreparingTaskSelector,
    createPreparedTaskVariationByTaskVariationIdSelector,
    createElementByIdSelector,
};
