import {call, delay, put, race, select, spawn, take} from 'redux-saga/effects';
import {UserActions} from './user.action';
import {UserActionTypes} from './user.action-type';
import {UserSelectors} from './user.selector';
import {Debug} from '../../../lib/debug';
import {withAbortController} from '../../../utils';
import {AuthActionTypes} from '../../auth/store/auth.action-type';
import {userSwitcher} from '../../auth/utils/switch';
import {getCurrentUserRequest} from '../api/user.provider';

const userRefreshWorker = function* () {
    let initialRun = true;

    while (true) {
        try {
            if (!initialRun) {
                Debug.debug('user.saga', `Refreshing...`);

                const user = yield call(withAbortController(getCurrentUserRequest));

                if (userSwitcher.getIsSwitching()) {
                    yield put(UserActions.storeSwitchingUser(user));
                } else {
                    yield put(UserActions.storeCurrentUser(user));
                }
            }

            // Refresh on every hh:mm:30
            const millisecondsToNextRefresh = 15 * 60 * 1000;

            Debug.debug('user.saga', `Waiting for ${millisecondsToNextRefresh} milliseconds to refresh.`);

            const {shouldStop} = yield race({
                shouldContinue: delay(millisecondsToNextRefresh),
                shouldStop: take(AuthActionTypes.LOG_OUT),
            });

            if (shouldStop) {
                yield take(UserActionTypes.STORE_CURRENT_USER);

                initialRun = true;

                continue;
            }

            initialRun = false;
        } catch (error) {
            // no-op
        }
    }
};

let currentUserRefreshJob = null;
export const getCurrentUser = function* () {
    const existingUser = yield select(UserSelectors.selectUserEntity);

    if (existingUser) {
        return existingUser;
    }

    const user = yield call(withAbortController(getCurrentUserRequest));
    yield put(UserActions.storeCurrentUser(user));

    if (!currentUserRefreshJob) {
        currentUserRefreshJob = yield spawn(userRefreshWorker);
    }

    return user;
};

export const getSwitchingUser = function* () {
    const existingUser = yield select(UserSelectors.selectSwitchingUserEntity);

    if (existingUser) {
        return existingUser;
    }

    const user = yield call(withAbortController(getCurrentUserRequest));
    yield put(UserActions.storeSwitchingUser(user));

    return user;
};
