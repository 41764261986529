import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import classes from './Alert.module.scss';
import {CloseIcon, ErrorIcon, SuccessIcon, WarningIcon} from '../../icons';
import {IconButton} from '../Button/IconButton';
import {Typography} from '../Typography/Typography';

export const Alert = React.forwardRef(({children, severity, onClose, ...rest}, ref) => {
    const icon = useMemo(() => {
        switch (severity) {
            case 'info':
                return <ErrorIcon size={18} className={classes.icon} />;
            case 'error':
                return <ErrorIcon size={18} className={classes.icon} />;
            case 'success':
                return <SuccessIcon size={18} className={classes.icon} />;
            case 'warning':
                return <WarningIcon size={18} className={classes.icon} />;

            default:
                return null;
        }
    }, [severity]);

    return (
        <div className={clsx(classes.root, classes[`severity-${severity}`])} ref={ref}>
            <div className={classes.iconAndTextContainer}>
                {icon}

                <Typography color="white" weight={500} size={14} lineClamp={3} className={classes.text}>
                    {children}
                </Typography>
            </div>

            <IconButton
                color="white"
                backgroundColor="transparent"
                onClick={onClose}
                size={22}
                iconSize={12}
                className={classes.closeButton}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
});

Alert.propTypes = {
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
};

Alert.defaultProps = {
    severity: 'info',
};
