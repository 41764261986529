import Slide from '@mui/material/Slide';
import classes from './Header.module.scss';
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';
import {Container} from '../../components';
import {AccountMenu} from '../../features/auth';
import {useInitialAnimation} from '../../hooks';
import {DURATION, HOME_ANIMATION_DELAYS} from '../../utils/animations';
import {Navigation} from '../Navigation/Navigation';

export const Header = () => {
    const containerAnimation = useInitialAnimation({delay: HOME_ANIMATION_DELAYS.HEADER});
    const logoAnimation = useInitialAnimation({delay: HOME_ANIMATION_DELAYS.LOGO});
    const navAnimation = useInitialAnimation({delay: HOME_ANIMATION_DELAYS.NAVIGATION});

    return (
        <Slide in={containerAnimation.isVisible} timeout={DURATION}>
            <header className={classes.root}>
                <Container>
                    <div className={classes.main}>
                        <Slide direction="down" in={logoAnimation.isVisible} timeout={DURATION}>
                            <div className={classes.topBar}>
                                <Logo className={classes.logo} />

                                <Slide in={navAnimation.isVisible} direction="down" timeout={DURATION}>
                                    <div>
                                        <Navigation />
                                    </div>
                                </Slide>
                            </div>
                        </Slide>

                        <AccountMenu />
                    </div>
                </Container>
            </header>
        </Slide>
    );
};
