import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getRoot} from 'lexical';
import {useEffect} from 'react';
import {REMOVE_VOICE_LEVEL_ICONS} from '../commands/remove-voice-level-icons';
import {$isVoiceLevelNode} from '../nodes/VoiceLevelNode';

export const RemoveVoiceLevelIconsCommand = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const removeVoiceLevelIconsCommand = editor.registerCommand(REMOVE_VOICE_LEVEL_ICONS, () => {
            editor.update(() => {
                const root = $getRoot();
                const paragraphNode = root.getChildren()[0];
                const children = paragraphNode.getChildren();

                for (const child of children) {
                    if ($isVoiceLevelNode(child)) {
                        child.remove();
                    }
                }
            });

            return true;
        }, 0);

        return () => {
            removeVoiceLevelIconsCommand();
        };
    }, [editor]);

    return null;
};
