import {TaskOverviewActionTypes} from './task-overview.action-type';

export const TaskOverviewActions = {
    storeEntities: entities => ({
        type: TaskOverviewActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    storeIds: ids => ({
        type: TaskOverviewActionTypes.STORE_IDS,
        payload: ids,
    }),
    getTaskOverview: () => ({
        type: TaskOverviewActionTypes.GET_TASK_OVERVIEW,
    }),
};
