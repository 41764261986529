export const VOLUME_LEVELS = {
    1: 60,
    2: 100,
    3: 120,
};

export const AUDIO_ELEMENT_TYPES = {
    PRE_PRODUCED_ELEMENTS: 'preProduced',
    SOUND_EFFECTS: 'soundEffect',
    MUSIC_BEDS: 'bed',
};

/**
 * @enum
 */
export const SUBSCRIPTION_TYPES = {
    PREVIOUS_ELEMENT_DATA: 'previousElementData',
    NEXT_ELEMENT_DATA: 'nextElementData',
    MASTER_BUTTON: 'masterButton',
    MODERATION_DURATION: 'moderationDuration',
    MUSIC_BED_DATA: 'musicBedData',
    SOUND_EFFECT_DATA: 'soundEffectData',
    PRE_PRODUCED_AUDIO_DATA: 'preProducedAudioData',
    PLAYBACK_DATA: 'playbackData',
    RECORDING_ALGORITHM_DONE: 'recordingAlgorithmDone',
    DISABLE_RECORDING_CONTROLS: 'disableRecordingControls',
    IS_RECORDING: 'isRecording',
    CURRENT_POSITION: 'currentPosition',
    TASK_DURATION: 'taskDuration',
    IS_PLAYING: 'isPlaying',
    CAN_BE_PLAYED: 'canBePlayed',
    SELECTED_NODE_DATA: 'selectedNodeData',
};

/**
 * @enum
 * @type {number[]}
 */
export const FINE_TUNE_STEPS = [
    10,
    50,
    100,
    250,
    500,
];
