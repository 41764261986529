import {TaskActionTypes} from './task.action-type';

export const TaskActions = {
    storeCurrentTask: currentTask => ({
        type: TaskActionTypes.STORE_CURRENT_TASK,
        payload: currentTask,
    }),
    setUploadProgress: uploadProgress => ({
        type: TaskActionTypes.SET_UPLOAD_PROGRESS,
        payload: uploadProgress,
    }),
    completeTaskVariation: (
        taskId,
        taskVariationId,
        mixingPlan,
        files,
        isAudioWrapped,
        expirationDate,
        syncOut,
        source,
    ) => ({
        type: TaskActionTypes.COMPLETE_TASK_VARIATION,
        payload: {
            taskId,
            taskVariationId,
            mixingPlan,
            files,
            isAudioWrapped,
            expirationDate,
            syncOut,
        },
        source,
    }),
    taskVariationCompleted: () => ({
        type: TaskActionTypes.TASK_VARIATION_COMPLETED,
    }),
    createAlternativeTask: (taskId, startDate, startTime, endDate, endTime) => ({
        type: TaskActionTypes.CREATE_ALTERNATIVE_TASK,
        payload: {
            taskId,
            startDate,
            startTime,
            endDate,
            endTime,
        },
    }),
    setAlternativeTaskTargetTaskId: taskId => ({
        type: TaskActionTypes.SET_ALTERNATIVE_TASK_TARGET_TASK_ID,
        payload: taskId,
    }),
    goToNextJob: () => ({
        type: TaskActionTypes.GO_TO_NEXT_JOB,
    }),
    overrideExpirationDate: (taskId, isOverridden, date) => ({
        type: TaskActionTypes.OVERRIDE_EXPIRATION_DATE,
        payload: {
            taskId,
            isOverridden,
            date,
        },
    }),
    overrideValidityPeriod: (taskId, startDate, startTime, endDate, endTime) => ({
        type: TaskActionTypes.OVERRIDE_VALIDITY_PERIOD,
        payload: {
            taskId,
            startDate,
            startTime,
            endDate,
            endTime,
        },
    }),
    updateMixingPlan: () => ({
        type: TaskActionTypes.UPDATE_MIXING_PLAN,
    }),
};

