import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useFirstMountState} from '@react-hookz/web';
import {useEffect, useRef, useState} from 'react';
import {RESET_TEXT} from '../commands/reset-text-command';

export const ResetTextCommand = ({originalSpeakerText, preparedSpeakerText, onReset}) => {
    const [editor] = useLexicalComposerContext();

    /**
     * We can't use Redux Dispatch in Lexical command, so we need this piece of state to run a useEffect that
     * is going to execute Redux Dispatch. Ugly, but works, for now.
     */
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const isCancelRef = useRef(false);
    const isFirstMount = useFirstMountState();

    useEffect(() => {
        const removeResetTextCommand = editor.registerCommand(RESET_TEXT, (data = {isCancel: false}) => {
            const {isCancel} = data;

            if (originalSpeakerText === preparedSpeakerText) {
                return true;
            }

            isCancelRef.current = isCancel;

            setShouldUpdate(true);

            const editorState = isCancel ? preparedSpeakerText || originalSpeakerText : originalSpeakerText;
            editor.setEditorState(editor.parseEditorState(editorState));

            return true;
        }, 0);

        return () => {
            removeResetTextCommand();
        };
    }, [editor, originalSpeakerText, preparedSpeakerText]);

    useEffect(() => {
        if (isFirstMount) {
            return;
        }

        onReset(isCancelRef.current);
    // eslint-disable-next-line
    }, [shouldUpdate]);

    return null;
};
