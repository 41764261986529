import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {WebSocketActionTypes} from './web-socket.action-type';
import {Debug} from '../../../lib/debug';
import {formatMillisecondsAsTimestamp} from '../../../utils';
import {CockpitNavigationActions, CockpitNavigationSelectors} from '../../cockpit-navigation';
import {CurrentTaskSelectors} from '../../current-task';
import {PlaylistSelectors} from '../../playlist';
import {updateTaskInPlaylist} from '../../playlist/store/playlist.saga';
import recordingAlgorithm from '../../recording-algorithm/RecordingAlgorithm';
import {loadCurrentTask} from '../../task';
import {TaskStatuses} from '../../task/utils/task-statuses';
import {UiActions} from '../../ui/store/ui.action';
import {ModalKeys} from '../../ui/utils/constants';
import {cockpitWindowController} from '../../window-manager';

const processMessageWorker = function* ({payload}) {
    const {message} = payload;

    Debug.debug('web-socket', 'Processing message...', {message});

    if (message.event === 'task.completed') {
        const {task, taskVariation, taskItem} = message.data;

        if (cockpitWindowController.isCockpitWindow()) {
            yield fork(processCompletedTaskInCockpit, task, taskVariation, taskItem);
        } else {
            yield fork(processCompletedTaskInPlaylist, task, taskVariation, taskItem);
        }
    } else if (message.event === 'task.deleted') {
        const {task} = message.data;

        if (!task) {
            return;
        }

        const {id} = task;

        if (!id) {
            return;
        }

        yield fork(processDeletedTask, id);
    } else if (message.event === 'task.cancelled') {
        const {task, taskVariation} = message.data;

        if (!task || !taskVariation) {
            return;
        }

        const {id: taskId} = task;
        const {id: taskVariationId} = taskVariation;

        if (!taskId || !taskVariation) {
            return;
        }

        yield fork(processCancelledTask, {taskId, taskVariationId});
    }
};

const processCompletedTaskInPlaylist = function* (task, taskVariation, taskItem) {
    let status = TaskStatuses.NOT_COMPLETED;

    if (taskVariation.isCompleted) {
        status = TaskStatuses.COMPLETED;
    } else if (taskItem.isProcessing) {
        status = TaskStatuses.PROCESSING_AUDIO;
    }

    yield call(updateTaskInPlaylist, task.id, {
        externalId: taskItem.externalId,
        length: taskItem.duration,
        formattedLength: formatMillisecondsAsTimestamp(taskItem.duration),
        isTaskCompleted: taskVariation.isCompleted,
        status,
        filename: taskItem.hasMixingPlan ? null : taskItem.filename,
    });
};

const processCompletedTaskInCockpit = function* (task, taskVariation) {
    const currentTaskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);

    if (currentTaskVariationId === taskVariation.id) {
        yield call(loadCurrentTask, task.id);

        yield put(UiActions.setModalState(ModalKeys.PROCESSING_COMPLETE, true));
    }
};

/**
 * Probably not needed anymore, as the API doesn't delete tasks if they are in use anywhere. Worth staying tho.
 */
const processDeletedTask = function* (taskId) {
    const isCockpitWindow = cockpitWindowController.isCockpitWindow();

    if (!isCockpitWindow) {
        const taskMap = yield select(PlaylistSelectors.selectTasks);
        const tasks = Object.values(taskMap);
        const task = tasks.find(task => task.id === taskId);

        if (task) {
            yield call(updateTaskInPlaylist, taskId, {
                status: TaskStatuses.DELETED,
            });
        }
    } else {
        const navigationIds = yield select(CockpitNavigationSelectors.selectIds);
        const hasId = !!Object.keys(navigationIds).find(id => id.startsWith(taskId));

        if (hasId) {
            yield put(CockpitNavigationActions.storeIds(
                Object.keys(navigationIds)
                    .filter(id => !id.startsWith(taskId))
                    .reduce((accumulator, current) => {
                        accumulator[current] = navigationIds[current];

                        return accumulator;
                    }, {}),
            ));

            const currentTaskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);

            if (taskId === currentTaskId) {
                yield call([recordingAlgorithm, recordingAlgorithm.setAllowKeyboardEvents], false);
                yield put(UiActions.setModalState(ModalKeys.TASK_DELETED_MODAL, true));
            }
        }
    }
};

const processCancelledTask = function* ({taskId, taskVariationId}) {
    const isCockpitWindow = cockpitWindowController.isCockpitWindow();
    const navigationIds = yield select(CockpitNavigationSelectors.selectIds);
    const hasId = !!Object.keys(navigationIds).find(id => id.startsWith(taskId));

    if (hasId) {
        yield put(CockpitNavigationActions.storeIds(
            Object.keys(navigationIds)
                .filter(id => !id.startsWith(taskId))
                .reduce((accumulator, current) => {
                    accumulator[current] = navigationIds[current];

                    return accumulator;
                }, {}),
        ));
    }

    if (!isCockpitWindow) {
        const taskMap = yield select(PlaylistSelectors.selectTasks);
        const tasks = Object.values(taskMap);
        const task = tasks.find(task => task.id === taskId);

        if (task) {
            yield call(updateTaskInPlaylist, taskId, {
                status: TaskStatuses.DELETED, // TODO: It should be CANCELLED, not DELETED, but not necessary now
            });
        }
    } else if (hasId) {
        const currentTaskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);
        const currentTaskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
        const hasTaskAndTaskVariation = currentTaskId && currentTaskVariationId;

        if (hasTaskAndTaskVariation && taskId === currentTaskId && currentTaskVariationId === taskVariationId) {
            yield call([recordingAlgorithm, recordingAlgorithm.setAllowKeyboardEvents], false);
            yield put(UiActions.setModalState(ModalKeys.TASK_DELETED_MODAL, true));
        }
    }
};

export const webSocketSaga = function* () {
    yield all([
        takeEvery(WebSocketActionTypes.PROCESS_MESSAGE, processMessageWorker),
    ]);
};
