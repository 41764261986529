import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import classes from './BlockProgramItems.module.scss';
import {Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {AdvertisingSlot} from '../AdvertisingSlot/AdvertisingSlot';
import {DdsFilter} from '../DdsFilter/DdsFilter';
import {DdsItem} from '../DdsItem/DdsItem';
import {GenericTask} from '../GenericTask/GenericTask';
import {Task} from '../Task/Task';

export const BlockProgramItems = ({blockProgramKey}) => {
    const blockProgram = useMemoizedCreateSelector(
        PlaylistSelectors.createBlockProgramByKeySelector,
        blockProgramKey,
    );

    const blockProgramItems = useMemoizedCreateSelector(
        PlaylistSelectors.createBlockProgramItemsByKeysSelector,
        blockProgram.items,
    );

    const showContent = useSelector(PlaylistSelectors.selectShowContent);

    const items = useMemo(() => {
        if (showContent) {
            return blockProgramItems;
        }

        return blockProgramItems.filter(item => !!item.task);
    }, [blockProgramItems, showContent]);

    const findIndexOfTaskInFilteredItems = taskId => {
        return items.findIndex(item => item.task.id === taskId);
    };

    const delay = Math.max(550 / items.length, 225);

    return (

        <div className={classes.root}>
            <Collapse in={!items.length}>
                <div className={classes.noElementsContainer}>
                    <Typography color="stone" size={13} weight={500} hasGutters>
                        {showContent ? 'This program has no planned elements.' : 'Items are hidden.'}
                    </Typography>
                </div>
            </Collapse>

            {blockProgramItems.map((blockProgramItem, index) => {
                if (blockProgramItem.task) {
                    let taskIndex = index;

                    if (!showContent) {
                        taskIndex = findIndexOfTaskInFilteredItems(blockProgramItem.task);
                    }

                    return (
                        <div key={blockProgramItem.task} className={classes.item}>
                            <Fade in={true} timeout={taskIndex * delay}>
                                <div>
                                    <Task taskKey={blockProgramItem.task} />
                                </div>
                            </Fade>
                        </div>
                    );
                } else if (blockProgramItem.ddsItem) {
                    return (
                        <Collapse
                            in={showContent}
                            key={blockProgramItem.ddsItem}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <DdsItem ddsItemKey={blockProgramItem.ddsItem} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (blockProgramItem.ddsFilter) {
                    return (
                        <Collapse
                            in={showContent}
                            key={blockProgramItem.ddsFilter}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <DdsFilter ddsFilterKey={blockProgramItem.ddsFilter} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (blockProgramItem.advertisingSlot) {
                    return (
                        <Collapse
                            in={showContent}
                            key={blockProgramItem.advertisingSlot}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <AdvertisingSlot advertisingSlotKey={blockProgramItem.advertisingSlot} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (blockProgramItem.genericTask) {
                    return (
                        <Collapse
                            in={showContent}
                            key={blockProgramItem.genericTask}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <GenericTask genericTaskKey={blockProgramItem.genericTask} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                }

                return null;
            })}
        </div>
    );
};

BlockProgramItems.propTypes = {
    blockProgramKey: PropTypes.string.isRequired,
};
