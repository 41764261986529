import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import {useWindowSize} from '@react-hookz/web';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import classes from './VolumeSliderDropdown.module.scss';
import {IconButton} from '../../../../components';

export const VolumeSliderDropdown = ({VolumeIconComponent, isPoppedOut, children}) => {
    const buttonRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const {width} = useWindowSize();

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (width < 1280) {
            setIsOpen(false);
        }
        // eslint-disable-next-line
    }, [width, isPoppedOut]);

    return (
        <div
            className={clsx(classes.root, {
                [classes.poppedOut]: isPoppedOut,
            })}
        >
            <IconButton
                backgroundColor="transparent"
                size={24}
                iconSize={24}
                color="white"
                hoverStyle="color"
                ref={buttonRef}
                onClick={() => setIsOpen(true)}
            >
                <VolumeIconComponent />
            </IconButton>

            {createPortal((
                <Fade in={isOpen} exit={false} mountOnEnter unmountOnExit>
                    <div
                        style={{
                            position: 'fixed',
                            top: buttonRef.current?.getBoundingClientRect().y,
                            left: buttonRef.current?.getBoundingClientRect().x,
                            zIndex: 1301,
                        }}
                    >
                        <VolumeIconComponent color="white" size={24} />
                    </div>
                </Fade>
            ), document.querySelector('#root'))}

            <Popover
                open={isOpen}
                anchorEl={buttonRef.current}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionComponent={Fade}
                TransitionProps={{
                    exit: false,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                elevation={0}
                PaperProps={{
                    sx: {
                        'borderRadius': '5px',
                        'boxShadow': '0 0 25px 0 rgb(0 0 0 / 60%)',
                        'border': '1px solid #606875',
                        'backgroundColor': '#262a30',
                        'padding': 3,
                        'marginTop': 2.5,
                        'overflow': 'visible',
                        'willChange': 'transform',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: -13,
                            left: '50%',
                            width: 13,
                            height: 13,
                            transform: 'translateX(-50%)',
                            borderLeft: '13px solid transparent',
                            borderRight: '13px solid transparent',
                            borderBottom: '13px solid #606875',
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: -12,
                            left: '50%',
                            width: 12,
                            height: 12,
                            transform: 'translateX(-50%)',
                            borderLeft: '12px solid transparent',
                            borderRight: '12px solid transparent',
                            borderBottom: '12px solid #262a30',
                        },
                    },
                }}
                BackdropProps={{
                    sx: {
                        // zIndex: 0,
                    },
                }}
            >
                {children}
            </Popover>

            <Backdrop exit={false} open={isOpen} />
        </div>
    );
};

VolumeSliderDropdown.propTypes = {
    VolumeIconComponent: PropTypes.func.isRequired,
    isPoppedOut: PropTypes.bool,
};

VolumeSliderDropdown.defaultProps = {
    isPoppedOut: false,
};
