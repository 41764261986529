import clsx from 'clsx';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import classes from './ScaleIndicator.module.scss';
import {PlayIcon} from '../../../icons';
import {useCurrentPosition} from '../hooks/use-current-position';
import {useIsPlaying} from '../hooks/use-is-playing';
import {EditorSelectors} from '../store/editor.selector';
import {EditorConfig} from '../utils/constants';

export const ScaleIndicator = ({editorWidth}) => {
    const zoomFactor = useSelector(EditorSelectors.selectZoomFactor);
    const isAutoscrollEnabled = useSelector(EditorSelectors.selectIsAutoscrollEnabled);
    const {position} = useCurrentPosition();
    const {isPlaying} = useIsPlaying();

    const transform = useMemo(() => {
        if (isAutoscrollEnabled) {
            return editorWidth / 2;
        }

        const marginLeft = position * zoomFactor * (EditorConfig.BaseColumnWidth / EditorConfig.MillisecondsPerColumn);

        if (isNaN(marginLeft)) {
            return 0;
        }

        return marginLeft;
    }, [editorWidth, isAutoscrollEnabled, position, zoomFactor]);

    return (
        <motion.div
            className={clsx(classes.root, {
                [classes.linearAnimation]: isPlaying,
                [classes.isAutoscrollEnabled]: isAutoscrollEnabled,
            })}
            style={{transform: `translate3d(${transform}px, 0, 0)`}}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 1}}
        >
            <PlayIcon className={classes.triangle} />
        </motion.div>
    );
};

ScaleIndicator.propTypes = {
    editorWidth: PropTypes.number,
};

ScaleIndicator.defaultProps = {
    editorWidth: 0,
};
