export const getDevices = () => {
    return new Promise((resolve, reject) => {
        try {
            const inputDevices = [];
            const outputDevices = [];
            let inputDeviceId = localStorage.getItem('inputDeviceId');
            let outputDeviceId = localStorage.getItem('outputDeviceId');

            window.navigator.mediaDevices.enumerateDevices().then(devices => {
                devices.forEach(device => {
                    if (device.deviceId === 'default') {
                        return;
                    }

                    if (device.kind === 'audioinput') {
                        inputDevices.push({
                            name: device.label,
                            id: device.deviceId,
                        });
                    } else if (device.kind === 'audiooutput') {
                        outputDevices.push({
                            name: device.label,
                            id: device.deviceId,
                        });
                    }
                });

                if (inputDeviceId && !inputDevices.find(device => device.id === inputDeviceId)) {
                    inputDeviceId = null;
                }

                if (outputDeviceId && !outputDevices.find(device => device.id === outputDeviceId)) {
                    outputDeviceId = null;
                }

                resolve({
                    inputDevices,
                    outputDevices,
                    inputDeviceId,
                    outputDeviceId,
                });
            });
        } catch (error) {
            reject(error);
        }
    });
};
