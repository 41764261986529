import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classes from './Entry.module.scss';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {Autoverpackung} from '../Autoverpackung/Autoverpackung';
import {Block} from '../Block/Block';
import {Song} from '../Song/Song';

export const Entry = ({entryKey}) => {
    const entry = useMemoizedCreateSelector(PlaylistSelectors.createEntryByKeySelector, entryKey);
    const showMusic = useSelector(PlaylistSelectors.selectShowMusic);
    const showContent = useSelector(PlaylistSelectors.selectShowContent);

    switch (entry.type) {
        case 0:
            return (
                <div className={classes.root}>
                    <Block
                        blockKey={entry.blockKey}
                        startTime={entry.startTime}
                    />
                </div>
            );
        case 1:
            return (
                <Collapse in={showMusic}>
                    <div className={classes.root}>
                        <Song
                            songKey={entry.songKey}
                            startTime={entry.startTime}
                        />
                    </div>
                </Collapse>
            );
        case 2:
            return (
                <Collapse in={showContent}>
                    <div className={classes.root}>
                        <Autoverpackung
                            autoverpackungKey={entry.autoverpackungKey}
                            startTime={entry.startTime}
                        />
                    </div>
                </Collapse>
            );
    }

    return null;
};

Entry.propTypes = {
    entryKey: PropTypes.string.isRequired,
};
