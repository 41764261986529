import PropTypes from 'prop-types';
import React from 'react';
import classes from './Marker.module.scss';
import {Typography} from '../../../components';

export const Marker = React.memo(({width, index, children, style, ...rest}) => {
    return (
        <div
            style={{width: `${width}px`, ...style}}
            className={classes.root}
            {...rest}
        >
            <div className={classes.innerContent}>
                <Typography weight={500} size={12} className={classes.title} color="stone">
                    {children}
                </Typography>

                <div className={classes.line} />
            </div>
        </div>
    );
});

Marker.propTypes = {
    width: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.object,
};

Marker.defaultProps = {
    style: {},
};
