import {motion} from 'framer-motion';
import classes from './TaskList.module.scss';
import {Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {Task} from '../Task/Task';

export const TaskList = () => {
    const taskKeys = useMemoizedCreateSelector(PlaylistSelectors.createTaskListKeysSelector);

    return (
        <div className={classes.root}>
            {!taskKeys.length && (
                <Typography size={13} align="center" variant="body" color="trout">
                    There are no tasks.
                </Typography>
            )}

            {taskKeys.map((taskKey, index) => {
                return (
                    <motion.div
                        key={taskKey}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5, delay: index * 0.015}}
                    >
                        <div>
                            <Task taskKey={taskKey} />
                        </div>
                    </motion.div>
                );
            })}
        </div>
    );
};
