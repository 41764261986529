/* eslint-disable no-console */
export const loggerMiddleware = store => next => action => {
    console.group(action.type);
    console.debug('[LoggerMiddleware] Dispatching: ', action);

    const result = next(action);
    console.debug('[LoggerMiddleware] Next state: ', store.getState());
    console.groupEnd();

    return result;
};
