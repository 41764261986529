import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import classes from './SplitProgramItems.module.scss';
import {Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {AdvertisingSlot} from '../AdvertisingSlot/AdvertisingSlot';
import {DdsFilter} from '../DdsFilter/DdsFilter';
import {DdsItem} from '../DdsItem/DdsItem';
import {Task} from '../Task/Task';

export const SplitProgramItems = ({splitProgramKey}) => {
    const splitProgram = useMemoizedCreateSelector(
        PlaylistSelectors.createSplitProgramByKeySelector,
        splitProgramKey,
    );

    const splitProgramItems = useMemoizedCreateSelector(
        PlaylistSelectors.createSplitProgramItemsByKeysSelector,
        splitProgram.items,
    );

    const showContent = useSelector(PlaylistSelectors.selectShowContent);

    const items = useMemo(() => {
        if (showContent) {
            return splitProgramItems;
        }

        return splitProgramItems.filter(item => !!item.task);
    }, [splitProgramItems, showContent]);

    const findIndexOfTaskInFilteredItems = taskId => {
        return items.findIndex(item => item.task.id === taskId);
    };

    const delay = Math.max(550 / items.length, 225);

    return (
        <div className={classes.root}>
            <Collapse in={!items.length}>
                <div className={classes.noElementsContainer}>
                    <Typography color="stone" size={13} weight={500} hasGutters>
                        {showContent ? 'This program has no planned elements.' : 'Items are hidden.'}
                    </Typography>
                </div>
            </Collapse>

            {splitProgramItems.map((splitProgramItem, index) => {
                if (splitProgramItem.task) {
                    let taskIndex = index;

                    if (!showContent) {
                        taskIndex = findIndexOfTaskInFilteredItems(splitProgramItem.task);
                    }

                    return (
                        <Collapse
                            key={splitProgramItem.task}
                            in={true}
                        >
                            <div className={classes.item}>
                                <Fade
                                    in={true}
                                    timeout={taskIndex * delay}
                                >
                                    <div>
                                        <Task taskKey={splitProgramItem.task} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (splitProgramItem.ddsItem) {
                    return (
                        <Collapse
                            in={showContent}
                            key={splitProgramItem.ddsItem}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <DdsItem ddsItemKey={splitProgramItem.ddsItem} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (splitProgramItem.ddsFilter) {
                    return (
                        <Collapse
                            in={showContent}
                            key={splitProgramItem.ddsFilter}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <DdsFilter ddsFilterKey={splitProgramItem.ddsFilter} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                } else if (splitProgramItem.advertisingSlot) {
                    return (
                        <Collapse
                            in={showContent}
                            key={splitProgramItem.advertisingSlot}
                        >
                            <div className={classes.item}>
                                <Fade in={true} timeout={index * delay}>
                                    <div>
                                        <AdvertisingSlot advertisingSlotKey={splitProgramItem.advertisingSlot} />
                                    </div>
                                </Fade>
                            </div>
                        </Collapse>
                    );
                }

                return null;
            })}
        </div>
    );
};

SplitProgramItems.propTypes = {
    splitProgramKey: PropTypes.string.isRequired,
};
