import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Marker} from './Marker';
import classes from './Markers.module.scss';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';
import {useCurrentPosition} from '../hooks/use-current-position';
import {useIsPlaying} from '../hooks/use-is-playing';
import {useTaskDuration} from '../hooks/use-task-duration';
import {EditorSelectors} from '../store/editor.selector';
import {EditorConfig} from '../utils/constants';

const getMarkersPerSecond = zoomFactor => {
    if (zoomFactor >= 0.5 && zoomFactor <= 1) {
        return 1;
    } else if (zoomFactor > 1 && zoomFactor <= 2.5) {
        return 2;
    } else if (zoomFactor > 2.5 && zoomFactor <= 6) {
        return 4;
    } else if (zoomFactor === EditorConfig.MaximalZoomFactor) {
        return 20;
    }

    return 10;
};

const scrollItemOffset = 10;

const calculateNumberOfMarkers = (taskDuration, zoomFactor, millisecondsPerColumn) => {
    if (!taskDuration) {
        return 60;
    }

    let currentTime = 0;
    let numberOfMarkers = 0;
    const markersPerSecond = getMarkersPerSecond(zoomFactor);

    while (taskDuration >= currentTime && (taskDuration - currentTime) >= 0) {
        currentTime += millisecondsPerColumn;
        numberOfMarkers += markersPerSecond;
    }

    if (currentTime - taskDuration < millisecondsPerColumn) {
        numberOfMarkers += 1;
    }

    return numberOfMarkers;
};

export const Markers = ({editorWidth, hasAnimation, wrapperScrollLeft}) => {
    const zoomFactor = useSelector(EditorSelectors.selectZoomFactor);
    const isAutoscrollEnabled = useSelector(EditorSelectors.selectIsAutoscrollEnabled);
    const {position} = useCurrentPosition();
    const {duration} = useTaskDuration();
    const {isPlaying} = useIsPlaying();

    const positionInSeconds = Math.floor(((position * 100) / 100));

    const markersPerSecond = useMemo(() => {
        return getMarkersPerSecond(zoomFactor);
    }, [zoomFactor]);

    const columnWidth = useMemo(() => {
        return (EditorConfig.BaseColumnWidth * zoomFactor) / markersPerSecond;
    }, [markersPerSecond, zoomFactor]);

    const leftColumnOffset = useMemo(() => {
        if (isAutoscrollEnabled && editorWidth) {
            const widthPerMillisecond = EditorConfig.BaseColumnWidth / EditorConfig.MillisecondsPerColumn;

            return columnWidth / 2 * -1 + editorWidth / 2 - positionInSeconds * widthPerMillisecond * zoomFactor;
        }

        return columnWidth / 2 * -1;
    }, [isAutoscrollEnabled, editorWidth, columnWidth, positionInSeconds, zoomFactor]);

    const millisecondsPerColumn = useMemo(() => {
        return EditorConfig.MillisecondsPerColumn / markersPerSecond;
    }, [markersPerSecond]);

    const numberOfMarkers = useMemo(() => {
        return calculateNumberOfMarkers(duration, zoomFactor, millisecondsPerColumn);
    }, [duration, zoomFactor, millisecondsPerColumn]);

    const width = useMemo(() => {
        return numberOfMarkers * columnWidth;
    }, [columnWidth, numberOfMarkers]);

    const items = useMemo(() => {
        const finalNumberOfMarkers = numberOfMarkers;

        /**
         * While scrolling, the user might see missing markers due to the time needed to re-render.
         * That's why we use scrollItemOffset, to render items in advance.
         */
        let startIndex = Math.floor(wrapperScrollLeft / columnWidth) - scrollItemOffset;

        if (startIndex < 0) {
            startIndex = 0;
        }

        const maxItems = finalNumberOfMarkers - 1;
        const numberOfRequiredItems = Math.floor((wrapperScrollLeft + editorWidth) / columnWidth);
        let endIndex = Math.min(maxItems, numberOfRequiredItems + 10);

        if (isAutoscrollEnabled) {
            startIndex = Math.floor(((editorWidth / 2) * -1 - leftColumnOffset) / columnWidth);
            endIndex = Math.min(
                finalNumberOfMarkers,
                Math.floor((editorWidth - leftColumnOffset + columnWidth * 3) / columnWidth),
            );
        }

        if (startIndex < 0) {
            startIndex = 0;
        }

        const items = [];

        for (let i = startIndex; i <= endIndex; i += 1) {
            const milliseconds = Math.round(i * millisecondsPerColumn);
            const duration = moment.duration(milliseconds, 'ms').asMilliseconds();
            const formattedDuration = moment.utc(duration).format(EditorConfig.TimeFormat);

            items.push((
                <Marker
                    key={formattedDuration}
                    width={columnWidth}
                    index={i}
                    style={{
                        position: 'absolute',
                        left: `${i * columnWidth}px`,
                        height: '100%',
                        top: 0,
                        bottom: 0,
                    }}
                    onClick={() => {
                        playbackAlgorithm.seek(milliseconds / 1000);
                    }}
                >
                    {formattedDuration}
                </Marker>
            ));
        }

        return items;
    }, [
        columnWidth,
        editorWidth,
        isAutoscrollEnabled,
        leftColumnOffset,
        millisecondsPerColumn,
        numberOfMarkers,
        wrapperScrollLeft,
    ]);

    return (
        <div
            className={clsx(classes.root, {
                [classes.disableAnimation]: !hasAnimation,
                [classes.linearAnimation]: isPlaying,
            })}
            style={{
                width,
                transform: `translate3d(${Math.round((leftColumnOffset + Number.EPSILON) * 100) / 100}px, -50%, 0)`,
            }}
        >
            {items}
        </div>
    );
};

Markers.propTypes = {
    editorWidth: PropTypes.number,
    wrapperScrollLeft: PropTypes.number,
    hasAnimation: PropTypes.bool.isRequired,
};

Markers.defaultProps = {
    editorWidth: 0,
    wrapperScrollLeft: 0,
};
