import {useTranslation} from 'react-i18next';
import classes from './NewPasswordScreen.module.scss';
import {PageHeader} from '../../../../components';
import {NewPasswordForm} from '../NewPasswordForm/NewPasswordForm';

export const NewPasswordScreen = () => {
    const {t} = useTranslation('featuresAuth');

    return (
        <div className={classes.root}>
            <PageHeader className={classes.header} subtitle={t('newPasswordScreen.pageHeader.subtitle')} align="center">
                {t('newPasswordScreen.pageHeader.title')}
            </PageHeader>

            <NewPasswordForm />
        </div>
    );
};
