import {combineReducers} from 'redux';
import {GenericTaskActionTypes} from './generic-task.action-type';
import {createReducer} from '../../../utils';

export const genericTaskReducer = combineReducers({
    genericProductionOrderIds: createReducer([], GenericTaskActionTypes.STORE_GENERIC_PRODUCTION_ORDER_IDS),
    genericProductionOrders: createReducer({}, GenericTaskActionTypes.STORE_GENERIC_PRODUCTION_ORDERS),
    genericTasks: createReducer({}, GenericTaskActionTypes.STORE_GENERIC_TASKS),
    genericTaskVariations: createReducer({}, GenericTaskActionTypes.STORE_GENERIC_TASK_VARIATIONS),
    selectedGenericTaskVariationId: createReducer(null, GenericTaskActionTypes.SET_SELECTED_GENERIC_TASK_VARIATION_ID),
    isControllerOpen: createReducer(false, GenericTaskActionTypes.SET_IS_CONTROLLER_OPEN),
});
