import clsx from 'clsx';
import PropTypes from 'prop-types';
import {memo} from 'react';

import classes from './Typography.module.scss';
import {COLOR_NAMES} from '../../utils';

const defaultVariantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    title: 'h6',
    subtitle: 'h6',
    body: 'p',
    label: 'label',
};

export const Typography = memo(({
    className,
    children,
    variant,
    hasGutters,
    align,
    color,
    weight,
    transform,
    size,
    letterSpacing,
    lineClamp,
    hasLineHeight,
    ...rest
}) => {
    const Component = defaultVariantMapping[variant] || 'span';

    return (
        <Component
            className={clsx(
                classes.root,
                classes[variant],
                classes[`align-${align}`],
                classes[`size-${size}`],
                classes[`color-${color}`],
                classes[`weight-${weight}`],
                classes[`transform-${transform}`],
                className, {
                    [classes.disableGutters]: !hasGutters,
                    [classes[`letter-spacing-${letterSpacing}`]]: Number.isInteger(letterSpacing),
                    [classes[`line-clamp-${lineClamp}`]]: Number.isInteger(lineClamp),
                    [classes.noLineHeight]: hasLineHeight === false,
                },
            )}
            {...rest}
        >
            {children}
        </Component>
    );
});

Typography.propTypes = {
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'title', 'subtitle', 'body', 'label']),
    hasGutters: PropTypes.bool,
    hasLineHeight: PropTypes.bool,
    className: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    transform: PropTypes.oneOf(['none', 'uppercase']),
    weight: PropTypes.oneOf([400, 500, 700]),
    size: PropTypes.oneOf([11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40]),
    letterSpacing: PropTypes.oneOf([1]),
    lineClamp: PropTypes.oneOf([1, 2, 3]),
    color: PropTypes.oneOf([...COLOR_NAMES, 'inherit']),
};

Typography.defaultProps = {
    variant: null,
    hasGutters: true,
    hasLineHeight: true,
    align: 'left',
    className: '',
    color: 'black',
    weight: 400,
    transform: undefined,
    size: 16,
    letterSpacing: null,
    lineClamp: null,
};
