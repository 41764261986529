import {PlaylistActionTypes} from './playlist.action-type';

export const PlaylistActions = {
    storeShowKeys: entities => ({
        type: PlaylistActionTypes.STORE_SHOW_KEYS,
        payload: entities,
    }),
    storeShows: entities => ({
        type: PlaylistActionTypes.STORE_SHOWS,
        payload: entities,
    }),
    storeViews: entities => ({
        type: PlaylistActionTypes.STORE_VIEWS,
        payload: entities,
    }),
    storeEntries: entities => ({
        type: PlaylistActionTypes.STORE_ENTRIES,
        payload: entities,
    }),
    storeAdvertisingSlots: entities => ({
        type: PlaylistActionTypes.STORE_ADVERTISING_SLOTS,
        payload: entities,
    }),
    storeSongs: entities => ({
        type: PlaylistActionTypes.STORE_SONGS,
        payload: entities,
    }),
    storeGenericTasks: entities => ({
        type: PlaylistActionTypes.STORE_GENERIC_TASKS,
        payload: entities,
    }),
    storeAutoverpackungs: entities => ({
        type: PlaylistActionTypes.STORE_AUTOVERPACKUNGS,
        payload: entities,
    }),
    storeBlocks: entities => ({
        type: PlaylistActionTypes.STORE_BLOCKS,
        payload: entities,
    }),
    storeBlockPrograms: entities => ({
        type: PlaylistActionTypes.STORE_BLOCK_PROGRAMS,
        payload: entities,
    }),
    storeBlockProgramItems: entities => ({
        type: PlaylistActionTypes.STORE_BLOCK_PROGRAM_ITEMS,
        payload: entities,
    }),
    storeSplitPrograms: entities => ({
        type: PlaylistActionTypes.STORE_SPLIT_PROGRAMS,
        payload: entities,
    }),
    storeSplitProgramItems: entities => ({
        type: PlaylistActionTypes.STORE_SPLIT_PROGRAM_ITEMS,
        payload: entities,
    }),
    storeDdsItems: entities => ({
        type: PlaylistActionTypes.STORE_DDS_ITEMS,
        payload: entities,
    }),
    storeDdsFilters: entities => ({
        type: PlaylistActionTypes.STORE_DDS_FILTERS,
        payload: entities,
    }),
    storeTasks: (entities, source) => ({
        type: PlaylistActionTypes.STORE_TASKS,
        payload: entities,
        source,
    }),
    toggleViewCollapse: viewKey => ({
        type: PlaylistActionTypes.TOGGLE_VIEW_COLLAPSE,
        payload: {
            viewKey,
        },
    }),
    expandView: viewKey => ({
        type: PlaylistActionTypes.EXPAND_VIEW,
        payload: {
            viewKey,
        },
    }),
    collapseView: viewKey => ({
        type: PlaylistActionTypes.COLLAPSE_VIEW,
        payload: {
            viewKey,
        },
    }),
    setSelectedRegionForBlock: (blockKey, regionId) => ({
        type: PlaylistActionTypes.SET_SELECTED_REGION_FOR_BLOCK,
        payload: {
            blockKey,
            regionId,
        },
    }),
    updateTaskInPlaylist: (taskId, task) => ({
        type: PlaylistActionTypes.UPDATE_TASK_IN_PLAYLIST,
        payload: {
            taskId,
            task,
        },
    }),
    setFirstPlanningFilter: firstPlanningFilter => ({
        type: PlaylistActionTypes.SET_FIRST_PLANNING_FILTER,
        payload: firstPlanningFilter,
    }),
    setRole: (role, source) => ({
        type: PlaylistActionTypes.SET_ROLE,
        payload: role,
        source,
    }),
    setShowContent: (showContent, source) => ({
        type: PlaylistActionTypes.SET_SHOW_CONTENT,
        payload: showContent,
        source,
    }),
    setShowStructured: (showStructured, source) => ({
        type: PlaylistActionTypes.SET_SHOW_STRUCTURED,
        payload: showStructured,
        source,
    }),
    setShowMusic: (showMusic, source) => ({
        type: PlaylistActionTypes.SET_SHOW_MUSIC,
        payload: showMusic,
        source,
    }),
    setSearchTerm: searchTerm => ({
        type: PlaylistActionTypes.SET_SEARCH_TERM,
        payload: searchTerm,
    }),
    setShowOnlyMatches: showOnlyMatches => ({
        type: PlaylistActionTypes.SET_SHOW_ONLY_MATCHES,
        payload: showOnlyMatches,
    }),
    updatePlayingType: (playlistItemId, playingType) => ({
        type: PlaylistActionTypes.UPDATE_PLAYING_TYPE,
        payload: {
            playlistItemId,
            playingType,
        },
    }),
};
