import {BroadcastChannelActionTypes} from './broadcast-channel.action-type';

export const BroadcastChannelActions = {
    sendRequest: (requestType, payload) => ({
        type: BroadcastChannelActionTypes.SEND_REQUEST,
        payload,
        requestType,
    }),
    send: data => ({
        type: BroadcastChannelActionTypes.SEND,
        payload: data,
    }),
};
