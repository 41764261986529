import {Debug} from '../../../lib/debug';
import {AudioReader, RingBuffer} from '../../../lib/ring-buffer';

class RecordingNode {
    /**
     * @param {AudioContext} audioContext
     */
    constructor(audioContext) {
        this.#audioContext = audioContext;
    }

    /**
     * @type {AudioContext}
     */
    #audioContext = null;

    /**
     * @type {MediaStreamAudioSourceNode}
     */
    #recordingNode = null;

    /**
     * @type {AudioWorkletNode}
     */
    #recordingWorklet = null;

    /**
     * @type {boolean}
     */
    #isRecording = false;

    /**
     * @type {Array<number>}
     */
    #startedAt = [];

    /**
     * @type {Array<number>}
     */
    #stoppedAt = [];

    /**
     * @type {SharedArrayBuffer}
     */
    #arrayBuffer;

    /**
     * @type {number}
     */
    #dequeueInterval;

    /**
     * @type {AudioReader}
     */
    #audioReader;

    /**
     * @type {Float32Array}
     */
    #staging;

    /**
     * @type {function}
     */
    #onAudioProcess;

    /**
     * @param {MediaStream} stream
     * @param {function} onAudioProcess
     */
    initialize = async (stream, onAudioProcess) => {
        this.#onAudioProcess = onAudioProcess;

        const byteLength = 8 + (this.#audioContext.sampleRate * 2 + 1) * Float32Array.BYTES_PER_ELEMENT;
        // eslint-disable-next-line no-undef
        this.#arrayBuffer = new SharedArrayBuffer(byteLength);

        this.#staging = new Float32Array(this.#arrayBuffer.byteLength / 4 / 4 / 2);

        this.#recordingNode = this.#audioContext.createMediaStreamSource(stream);

        await this.#audioContext.audioWorklet.addModule('/audio-recording-worklet.js');
        this.#recordingWorklet = new AudioWorkletNode(this.#audioContext, 'audio-recording-worklet', {
            numberOfInputs: 2,
            numberOfOutputs: 2,
            processorOptions: this.#arrayBuffer,
        });

        this.#recordingNode.connect(this.#recordingWorklet);

        this.#recordingWorklet.connect(this.#audioContext.destination);

        this.#audioReader = new AudioReader(new RingBuffer(this.#arrayBuffer, Float32Array));

        this.#dequeueInterval = setInterval(this.#readFromQueue, 100);
    };

    #readFromQueue = () => {
        const samplesRead = this.#audioReader.dequeue(this.#staging);

        if (!samplesRead) {
            return 0;
        }

        // if (!this.#isRecording) {
        //     return 0;
        // }

        const segment = new Int16Array(samplesRead);

        for (let i = 0; i < samplesRead; i += 1) {
            segment[i] = Math.min(Math.max(this.#staging[i], -1.0), 1.0) * (2 << 14);
        }

        this.#onAudioProcess(segment);

        // Debug.debug('RecordingNode', `Segment processed. Read: ${samplesRead}`);

        return samplesRead;
    };

    start = () => {
        this.#isRecording = true;

        this.#startedAt.push(this.#audioContext.currentTime);

        this.#recordingWorklet.port.postMessage({
            eventType: 'START_RECORDING',
        });
    };

    stop = () => {
        this.#isRecording = false;

        this.#stoppedAt.push(this.#audioContext.currentTime);

        this.#recordingNode.mediaStream.getAudioTracks().forEach(track => track.stop());

        Debug.debug('RecordingNode', `Clearing dequeue interval...`);

        clearInterval(this.#dequeueInterval);

        return new Promise((resolve, reject) => {
            try {
                while (this.#readFromQueue()) {
                    Debug.debug('RecordingNode', `Dequeueing...`);
                }

                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    stopCurrentRecording = () => {
        Debug.debug('RecordingNode', `Stopping current recording...`);

        this.#isRecording = false;

        this.#stoppedAt.push(this.#audioContext.currentTime);

        this.#recordingWorklet.port.postMessage({
            eventType: 'STOP_CURRENT_RECORDING',
        });

        Debug.debug('RecordingNode', `Clearing dequeue interval...`);

        clearInterval(this.#dequeueInterval);

        while (this.#readFromQueue()) {
            Debug.debug('RecordingNode', `Dequeueing...`);
        }

        this.#recordingWorklet.port.postMessage({
            eventType: 'STOP',
        });

        this.#recordingNode.disconnect(this.#recordingWorklet);
        this.#recordingWorklet.disconnect(this.#audioContext.destination);

        this.#arrayBuffer = null;
        this.#audioReader = null;
        this.#recordingWorklet = null;

        const byteLength = 8 + (this.#audioContext.sampleRate * 2 + 1) * Float32Array.BYTES_PER_ELEMENT;
        // eslint-disable-next-line no-undef
        this.#arrayBuffer = new SharedArrayBuffer(byteLength);
        this.#audioReader = new AudioReader(new RingBuffer(this.#arrayBuffer, Float32Array));
        this.#recordingWorklet = new AudioWorkletNode(this.#audioContext, 'audio-recording-worklet', {
            numberOfInputs: 2,
            numberOfOutputs: 2,
            processorOptions: this.#arrayBuffer,
        });
        this.#recordingNode.connect(this.#recordingWorklet);
        this.#recordingWorklet.connect(this.#audioContext.destination);
        this.#dequeueInterval = setInterval(this.#readFromQueue, 100);

        Debug.debug('RecordingNode', `Ready for next recording...`);
    };

    get recordingNode() {
        return this.#recordingNode;
    }

    set recordingNode(recordingNode) {
        this.#recordingNode = recordingNode;
    }

    get isRecording() {
        return this.#isRecording;
    }

    get startedAt() {
        return this.#startedAt;
    }

    get stoppedAt() {
        return this.#stoppedAt;
    }
}

export default RecordingNode;
