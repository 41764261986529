import {BroadcastChannelActionTypes} from '../store/broadcast-channel.action-type';

export const createBroadcastChannelMiddleware = channelName => {
    const broadcastChannel = new BroadcastChannel(channelName);

    return store => {
        broadcastChannel.addEventListener('message', event => {
            store.dispatch({
                type: BroadcastChannelActionTypes.RECEIVE,
                payload: event.data,
                isReceive: true,
            });
        });

        return next => action => {
            if (!action.isReceive && action.type === BroadcastChannelActionTypes.SEND) {
                broadcastChannel.postMessage(action.payload);
                return;
            } else if (!action.isReceive && action.type === BroadcastChannelActionTypes.SEND_REQUEST) {
                broadcastChannel.postMessage(action.payload);
                return;
            }

            return next(action);
        };
    };
};
