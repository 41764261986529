import clsx from 'clsx';
import {Button} from './Button';

import classes from './SecondaryButton.module.scss';

export const SecondaryButton = ({children, className, ...rest}) => {
    return (
        <Button className={clsx(classes.root, className)} {...rest}>
            {children}
        </Button>
    );
};

SecondaryButton.propTypes = {
    startIcon: Button.propTypes.startIcon,
    endIcon: Button.propTypes.endIcon,
    className: Button.propTypes.className,
};

SecondaryButton.defaultProps = {
    startIcon: Button.defaultProps.startIcon,
    endIcon: Button.defaultProps.endIcon,
    className: Button.defaultProps.className,
};
