import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import classes from './Pagination.module.scss';
import {ChevronLeft, ChevronRight} from '../../icons';
import {TextButton} from '../Button/TextButton';

const getPageNumbers = (count, currentPage, limit) => {
    const pageNumbers = [];
    let totalPages = Math.ceil(count / limit);

    let page = 1;

    if (totalPages > 5) {
        const maxPagesBeforeCurrentPage = 2;
        const maxPagesAfterCurrentPage = 2;

        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            page = 1;
            totalPages = 5;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            page = totalPages - 5 + 1;
        } else {
            // current page somewhere in the middle
            page = currentPage - maxPagesBeforeCurrentPage;
            totalPages = currentPage + maxPagesAfterCurrentPage;
        }
    }

    while (page <= totalPages) {
        pageNumbers.push(page);

        page += 1;
    }

    return pageNumbers;
};

export const Pagination = ({count, currentPage, limit, onPageChange}) => {
    const pageNumbers = useMemo(() => getPageNumbers(count, currentPage, limit), [count, currentPage, limit]);

    const createClickHandler = useCallback(page => () => {
        switch (page) {
            case 'first':
                onPageChange(0);
                break;

            case 'last':
                onPageChange(count - limit);
                break;

            case 'previous':
                onPageChange((currentPage - 1) * limit - limit);
                break;

            case 'next':
                onPageChange(currentPage * limit);
                break;

            default:
                onPageChange((page - 1) * limit);
                break;
        }
    }, [count, currentPage, limit, onPageChange]);

    return (
        <ul className={classes.root}>
            <li>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.button}
                    onClick={createClickHandler('first')}
                    disabled={currentPage === 1}
                >
                    First
                </TextButton>
            </li>

            <li>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.button}
                    startIcon={<ChevronLeft />}
                    onClick={createClickHandler('previous')}
                    disabled={currentPage === 1}
                >
                    Previous
                </TextButton>
            </li>

            {pageNumbers.map(pageNumber => (
                <li key={pageNumber}>
                    <TextButton
                        color="radiance"
                        hoverColor="cello"
                        className={clsx(classes.button, {
                            [classes.active]: pageNumber === currentPage,
                        })}
                        onClick={createClickHandler(pageNumber)}
                    >
                        {pageNumber}
                    </TextButton>
                </li>
            ))}

            <li>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.button}
                    endIcon={<ChevronRight />}
                    onClick={createClickHandler('next')}
                    disabled={currentPage === Math.ceil(count / limit)}
                >
                    Next
                </TextButton>
            </li>

            <li>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.button}
                    onClick={createClickHandler('last')}
                    disabled={currentPage === Math.ceil(count / limit)}
                >
                    Last
                </TextButton>
            </li>
        </ul>
    );
};

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
