import {all, call, put, select} from 'redux-saga/effects';
import {AdjacentElementsActions} from './adjacent-elements.action';
import {handleSagaError} from '../../../error.saga';
import {TaskSelectors} from '../../task';
import {AdjacentElementsApi} from '../api/adjacent-elements.api';

const getPreviousElementFlow = function* ({taskVariationId}) {
    return yield call(AdjacentElementsApi.getPreviousElementId, {taskVariationId});
};

const getNextElementFlow = function* ({taskVariationId}) {
    return yield call(AdjacentElementsApi.getNextElementId, {taskVariationId});
};

const getAudioFlow = function* ({externalId}) {
    return yield call(AdjacentElementsApi.getElement, {externalId});
};

export const loadPreviousElement = function* ({taskVariationId}) {
    try {
        let externalId = yield call(getPreviousElementFlow, {taskVariationId});
        const task = yield select(TaskSelectors.selectCurrentTask);

        if (!externalId) {
            if (taskVariationId === task.variations[0].id) {
                return;
            }

            externalId = yield call(getPreviousElementFlow, {taskVariationId: task.variations[0]});

            if (!externalId) {
                return;
            }
        }

        const element = yield call(getAudioFlow, {externalId});

        yield put(AdjacentElementsActions.storePreviousElement(element));
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(AdjacentElementsActions.storePreviousElement(null));

            // Unhandled
            return;
        }

        yield call(handleSagaError, error, 'loadPreviousElement');
    }
};

export const loadNextElement = function* ({taskVariationId}) {
    try {
        let externalId = yield call(getNextElementFlow, {taskVariationId});
        const task = yield select(TaskSelectors.selectCurrentTask);

        if (!externalId) {
            if (taskVariationId === task.variations[0].id) {
                return;
            }

            externalId = yield call(getNextElementFlow, {taskVariationId: task.variations[0]});

            if (!externalId) {
                return;
            }
        }

        const element = yield call(getAudioFlow, {externalId});

        yield put(AdjacentElementsActions.storeNextElement(element));
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(AdjacentElementsActions.storeNextElement(null));

            // Unhandled
            return;
        }

        yield call(handleSagaError, error, 'loadNextElement');
    }
};

export const loadAdjacentElements = function* ({taskVariationId}) {
    yield all([
        call(loadPreviousElement, {taskVariationId}),
        call(loadNextElement, {taskVariationId}),
    ]);
};
