import moment from 'moment';
import PropTypes from 'prop-types';
import classes from './StartEndTime.module.scss';
import {Typography} from '../../../components';
import {EditorConfig} from '../utils/constants';

export const StartEndTime = ({startTime, endTime}) => {
    const startTimeDuration = moment.duration(Math.floor(startTime), 'ms').asMilliseconds();
    const formattedStartTime = moment.utc(startTimeDuration).format(EditorConfig.TimeFormat);
    const endTimeDuration = moment.duration(Math.floor(endTime), 'ms').asMilliseconds();
    const formattedEndTime = moment.utc(endTimeDuration).format(EditorConfig.TimeFormat);

    return (
        <div className={classes.root}>
            <div>
                <Typography color="regent" transform="uppercase" size={12} className={classes.title} variant="body" weight={500}>
                    Start time
                </Typography>

                <Typography color="white" variant="body" className={classes.time} weight={500}>
                    {formattedStartTime}
                </Typography>
            </div>

            <div>
                <Typography color="regent" transform="uppercase" size={12} className={classes.title} variant="body" weight={500}>
                    End time
                </Typography>

                <Typography color="white" variant="body" className={classes.time} weight={500}>
                    {formattedEndTime}
                </Typography>
            </div>
        </div>
    );
};

StartEndTime.propTypes = {
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
};
