import {AdjacentElementsActionTypes} from './adjacent-elements.action-type';

export const AdjacentElementsActions = {
    storePreviousElement: previousElement => ({
        type: AdjacentElementsActionTypes.STORE_PREVIOUS_ELEMENT,
        payload: previousElement,
    }),
    storeNextElement: nextElement => ({
        type: AdjacentElementsActionTypes.STORE_NEXT_ELEMENT,
        payload: nextElement,
    }),
};
