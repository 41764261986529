import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './InputGroup.module.scss';
import {Typography} from '../Typography/Typography';

export const InputGroup = props => {
    const {children, label, inputId, isVerticallyCentered, isHorizontallyCentered, hasGutterBottom, className} = props;

    return (
        <div
            className={clsx(classes.root, className, {
                [classes.verticalCenter]: isVerticallyCentered,
                [classes.horizontalCenter]: isHorizontallyCentered,
                [classes.gutterBottom]: hasGutterBottom,
            })}
        >
            {!!label && (
                <Typography className={classes.label} htmlFor={inputId} variant="label" weight={500} size={14} color="stone">
                    {label}
                </Typography>
            )}

            {children}
        </div>
    );
};

InputGroup.propTypes = {
    label: PropTypes.string,
    inputId: PropTypes.string,
    className: PropTypes.string,
    isVerticallyCentered: PropTypes.bool,
    isHorizontallyCentered: PropTypes.bool,
    hasGutterBottom: PropTypes.bool,
};

InputGroup.defaultProps = {
    label: null,
    inputId: null,
    className: '',
    isVerticallyCentered: false,
    isHorizontallyCentered: false,
    hasGutterBottom: false,
};
