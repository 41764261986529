import React from 'react';

import {ROUTE_PATHS} from './route-paths';
import {LoginScreen, NewPasswordScreen, ResetPasswordScreen, requireAuth} from '../features/auth';
import {AppLayout, MinimalLayout} from '../layout';
import TaskScreen, {cockpitScreenLoaderSaga} from '../screens/cockpit';
import HomeScreen, {homeScreenLoaderSaga} from '../screens/home';
import {Icons} from '../screens/icons/Icons';
import {NotFoundScreen} from '../screens/not-found';
import {TimeAnnouncementsScreen} from '../screens/time-announcements/component/TimeAnnouncementsScreen';
import {timeAnnouncementsScreenLoaderSaga} from '../screens/time-announcements/store/time-announcements.loader-saga';

export const ROUTES = [
    {
        element: <AppLayout />,
        path: ROUTE_PATHS.HOME,
        locationChangeSideEffects: [
            [requireAuth],
        ],
        children: [
            {
                element: <HomeScreen />,
                index: true,
                locationChangeSideEffects: [
                    [homeScreenLoaderSaga],
                ],
            },
            {
                element: <TimeAnnouncementsScreen />,
                path: 'time-announcements',
                locationChangeSideEffects: [
                    [timeAnnouncementsScreenLoaderSaga],
                ],
            },
        ],
    },
    {
        element: <MinimalLayout />,
        children: [
            {
                element: <LoginScreen />,
                path: ROUTE_PATHS.LOGIN,
            },
            {
                element: <NewPasswordScreen />,
                path: ROUTE_PATHS.NEW_PASSWORD,
            },
            {
                element: <ResetPasswordScreen />,
                path: ROUTE_PATHS.RESET_PASSWORD,
            },
        ],
    },
    {
        element: <TaskScreen />,
        path: ROUTE_PATHS.TASK,
        locationChangeSideEffects: [
            [requireAuth],
            [cockpitScreenLoaderSaga],
        ],
    },
    {
        element: <Icons />,
        path: '/icons',
    },
    {
        element: <NotFoundScreen />,
        path: ROUTE_PATHS.NOT_FOUND,
    },
    {
        element: <NotFoundScreen />,
        path: '*',
    },
];
