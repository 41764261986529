import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import classes from './Button.module.scss';

export const Button = React.forwardRef(({children, isSubmit, className, startIcon, endIcon, ...rest}, ref) => {
    return (
        <button type={isSubmit ? 'submit' : 'button'} className={clsx(classes.root, className)} {...rest} ref={ref}>
            {startIcon && React.cloneElement(startIcon, {className: classes.startIcon})}

            {children}

            {endIcon && React.cloneElement(endIcon, {className: classes.endIcon})}
        </button>
    );
});

Button.propTypes = {
    isSubmit: PropTypes.bool,
    className: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
};

Button.defaultProps = {
    isSubmit: false,
    className: '',
    startIcon: null,
    endIcon: null,
};
