import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ChannelActions} from './channel.action';
import {ChannelActionTypes} from './channel.action-type';
import {ChannelSelectors} from './channel.selector';
import {handleSagaError} from '../../../error.saga';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {getChannelsRequest} from '../api/channel.provider';

export const getChannelsFlow = function* (brandId) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.CHANNELS, true));

        const oldEntities = yield select(ChannelSelectors.selectEntities);

        if (oldEntities.hasOwnProperty(brandId)) {
            return;
        }

        const {entities} = yield call(getChannelsRequest, brandId);

        yield all([
            put(ChannelActions.storeEntities({
                ...oldEntities,
                [brandId]: entities,
            })),
        ]);
    } catch (error) {
        yield call(handleSagaError, error, 'getChannelsFlow');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.CHANNELS, false));
    }
};

export const getChannelsWorker = function* ({payload}) {
    const {brandId} = payload;

    yield call(getChannelsFlow, brandId);
};

export const channelRootSaga = function* () {
    yield all([
        takeEvery(ChannelActionTypes.GET, getChannelsWorker),
    ]);
};
