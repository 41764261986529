import Fade from '@mui/material/Fade';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './LoadingWithOverlay.module.scss';
import {LoadingBars} from '../../icons';

export const LoadingWithOverlay = ({isLoading, className, ...rest}) => {
    return (
        <Fade in={isLoading} mountOnEnter unmountOnExit>
            <div className={clsx(classes.root, className)}>
                <LoadingBars {...rest} />
            </div>
        </Fade>
    );
};

LoadingWithOverlay.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};

LoadingWithOverlay.defaultProps = {
    isLoading: false,
    className: '',
};
