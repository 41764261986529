import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {PreparedTaskVariationActions} from './prepared-task-variation.action';
import {PreparedTaskVariationActionTypes} from './prepared-task-variation.action-type';
import {PreparedTaskVariationSelectors} from './prepared-task-variation.selector';
import {handleSagaError} from '../../../error.saga';
import {toastInstance} from '../../../lib/toast';
import {withAbortController} from '../../../utils';
import {AudioItemApi} from '../../audio-item/api/audio-item.api';
import {getItemFlow} from '../../audio-item/store/audio-item.saga';
import {CurrentTaskActionTypes, CurrentTaskSelectors} from '../../current-task';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {PresetSelectors} from '../../preset/store/preset.selector';
import {TaskSelectors} from '../../task';
import {Task, Variation} from '../../task/api/task.dto';
import {TaskActions} from '../../task/store/task.action';
import {cockpitWindowController} from '../../window-manager';
import {PreparedTaskVariationApi} from '../api/prepared-task-variation.api';
import {PreparedTaskVariation} from '../api/prepared-task-variation.dto';

const constructPreparedVariationDto = function ({data, originalSpeakerText}) {
    const {
        arePreProducedElementsMuted,
        preProducedAudioVolume,
        areSfxElementsMuted,
        sfxVolume,
        areBedElementsMuted,
        bedVolume,
        preProducedIds,
        sfxIds,
        bedIds,
        speakerText,
    } = data;

    const preparedTaskVariation = new PreparedTaskVariation({
        preProducedVolume: arePreProducedElementsMuted ? 0 : preProducedAudioVolume || null,
        sfxVolume: areSfxElementsMuted ? 0 : sfxVolume || null,
        bedVolume: areBedElementsMuted ? 0 : bedVolume || null,
        preProducedIds: preProducedIds || [],
        sfxIds: sfxIds || [],
        bedIds: bedIds || [],
        speakerText,
    });

    const preparedTaskVariationDto = preparedTaskVariation.toDto();

    if (preparedTaskVariationDto.speakerTextWithPreProducedAudioIndicators === originalSpeakerText) {
        preparedTaskVariationDto.speakerTextWithPreProducedAudioIndicators = null;
    }

    return preparedTaskVariationDto;
};

const updatePreparedTaskVariationFlow = function* ({taskVariationId, data}) {
    try {
        return yield call(withAbortController(PreparedTaskVariationApi.updatePreparedTaskVariation, {
            taskVariationId,
            data,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'updatePreparedTaskVariationFlow');
    }
};

// This saga will be triggered if the change of prepared task variation happens in cockpit window
const updatePreparedTaskVariationWorker = function* ({type, source}) {
    // When the source is not user, we don't want to update prepared task variation
    // When the source is user, event handler will dispatch the action with source
    if (source !== 'user') {
        return;
    }

    if (type === CurrentTaskActionTypes.SET_SPEAKER_TEXT) {
        // Wait a bit, as debounce, in case the user keeps changing something
        // yield delay(3000);

        // Just so that the animation doesn't look broken
        yield delay(550);
    }

    const taskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, taskVariationId));

    const preProducedAudioVolume = yield select(CurrentTaskSelectors.selectPreProducedVolume);
    const sfxVolume = yield select(CurrentTaskSelectors.selectSfxVolume);
    const bedVolume = yield select(CurrentTaskSelectors.selectBedVolume);
    const arePreProducedElementsMuted = yield select(CurrentTaskSelectors.selectArePreProducedElementsMuted);
    const areSfxElementsMuted = yield select(CurrentTaskSelectors.selectAreSfxElementsMuted);
    const areBedElementsMuted = yield select(CurrentTaskSelectors.selectAreBedElementsMuted);
    const preProducedIds = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('preProduced'));
    const sfxIds = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('sfx'));
    const bedIds = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('bed'));
    const speakerText = yield select(CurrentTaskSelectors.selectSpeakerText);

    const taskVariation = yield select(TaskSelectors.createTaskVariationByIdSelector(taskVariationId));

    const data = yield call(constructPreparedVariationDto, {data: {
        preProducedIds,
        sfxIds,
        bedIds,
        preProducedAudioVolume,
        sfxVolume,
        bedVolume,
        arePreProducedElementsMuted,
        areSfxElementsMuted,
        areBedElementsMuted,
        speakerText,
        originalSpeakerText: taskVariation?.speakerText,
    }});

    const preparedTaskVariation = yield call(updatePreparedTaskVariationFlow, {
        taskVariationId,
        data,
    });

    const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
    yield put(PreparedTaskVariationActions.storeEntities({
        ...entities,
        [taskVariationId]: preparedTaskVariation,
    }));

    const task = yield select(TaskSelectors.selectCurrentTask);
    yield put(TaskActions.storeCurrentTask(new Task({
        ...task,
        variations: task.variations.map(variation => {
            if (variation.id === taskVariationId) {
                return new Variation({
                    ...taskVariation,
                    preparedSpeakerText: speakerText ? JSON.stringify(speakerText) : null,
                });
            }

            return variation;
        }),
    })));

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
};

const loadForTaskVariationWorker = function* ({payload}) {
    const {taskVariationId} = payload;

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, taskVariationId));

    const preparedTaskVariation = yield call(PreparedTaskVariationApi.getPreparedTaskVariation, {
        taskVariationId,
    });

    const promises = [
        ...preparedTaskVariation.preProducedIds.map(id => call(getItemFlow, id, 'preProduced')),
        ...preparedTaskVariation.sfxIds.map(id => call(getItemFlow, id, 'sfx')),
        ...preparedTaskVariation.bedIds.map(id => call(getItemFlow, id, 'bed')),
    ];

    const audioItems = yield all(promises);
    const audioItemEntities = yield select(PreparedTaskVariationSelectors.selectAudioItemEntities);
    yield put(PreparedTaskVariationActions.storeAudioItemEntities({
        ...audioItemEntities,
        ...audioItems.reduce((accumulator, current) => {
            accumulator[current.id] = current;

            return accumulator;
        }, {}),
    }));

    const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
    yield put(PreparedTaskVariationActions.storeEntities({
        ...entities,
        [taskVariationId]: preparedTaskVariation,
    }));

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
};

const addElementWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEM, true));
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, payload.taskVariationId));

        let preparedTaskVariation = yield select(
            PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(
                payload.taskVariationId,
            ),
        );

        if (preparedTaskVariation && preparedTaskVariation[`${payload.elementType}Ids`].includes(payload.elementId)) {
            toastInstance.error('ITEM ALREADY LOADED');
            return;
        }

        const element = yield call(getItemFlow, payload.elementId, payload.elementType);

        const audioItemEntities = yield select(PreparedTaskVariationSelectors.selectAudioItemEntities);
        yield put(PreparedTaskVariationActions.storeAudioItemEntities({
            ...audioItemEntities,
            [payload.elementId]: element,
        }));

        const originalSpeakerText = yield select(PreparedTaskVariationSelectors.selectOriginalSpeakerText);

        if (!preparedTaskVariation) {
            preparedTaskVariation = yield call(constructPreparedVariationDto, {data: {
                originalSpeakerText,
                [`${payload.elementType}Ids`]: [payload.elementId],
            }});
        } else {
            preparedTaskVariation = preparedTaskVariation.clone();
            preparedTaskVariation[`${payload.elementType}Ids`] = [
                ...preparedTaskVariation[`${payload.elementType}Ids`],
                payload.elementId,
            ];
            preparedTaskVariation = preparedTaskVariation.toDto();
        }

        const data = yield call(updatePreparedTaskVariationFlow, {
            taskVariationId: payload.taskVariationId,
            data: preparedTaskVariation,
        });

        const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
        yield put(PreparedTaskVariationActions.storeEntities({
            ...entities,
            [payload.taskVariationId]: data,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'addElementWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEM, false));
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

const removeElementWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, payload.taskVariationId));

        let preparedTaskVariation = yield select(
            PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(
                payload.taskVariationId,
            ),
        );

        preparedTaskVariation = preparedTaskVariation.clone();
        const key = `${payload.elementType}Ids`;
        preparedTaskVariation[key] = preparedTaskVariation[key].filter(elementId => {
            return elementId !== payload.elementId;
        });
        preparedTaskVariation = preparedTaskVariation.toDto();

        const data = yield call(updatePreparedTaskVariationFlow, {
            taskVariationId: payload.taskVariationId,
            data: preparedTaskVariation,
        });

        const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
        yield put(PreparedTaskVariationActions.storeEntities({
            ...entities,
            [payload.taskVariationId]: data,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'removeElementWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

const sortElementWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, payload.taskVariationId));

        const {dragIndex, hoverIndex, elementType, taskVariationId} = payload;

        let preparedTaskVariation = yield select(
            PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(taskVariationId),
        );

        const draggedElement = preparedTaskVariation[`${elementType}Ids`][dragIndex];
        let newElements = [...preparedTaskVariation[`${elementType}Ids`]];
        newElements.splice(dragIndex, 1);

        newElements = [
            ...newElements.slice(0, hoverIndex),
            draggedElement,
            ...newElements.slice(hoverIndex),
        ];

        preparedTaskVariation = preparedTaskVariation.clone();
        preparedTaskVariation[`${elementType}Ids`] = newElements;

        const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
        yield put(PreparedTaskVariationActions.storeEntities({
            ...entities,
            [taskVariationId]: preparedTaskVariation,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'sortElementWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

const sortElementDropWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, payload.taskVariationId));

        const {taskVariationId} = payload;

        const preparedTaskVariation = yield select(
            PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(taskVariationId),
        );

        const data = yield call(updatePreparedTaskVariationFlow, {
            taskVariationId,
            data: preparedTaskVariation.toDto(),
        });

        const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
        yield put(PreparedTaskVariationActions.storeEntities({
            ...entities,
            [taskVariationId]: data,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'sortElementDropWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

const getAudioItemForElement = function* ({itemId, elementType}) {
    try {
        return yield call(AudioItemApi.getAudioItem, {
            itemId,
            elementType,
        });
    } catch (error) {
        yield call(handleSagaError, error, 'getAudioItemForElement');
    }
};

const loadFromPresetWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, payload.taskVariationId));

        const {presetId} = payload;
        const preset = yield select(PresetSelectors.createPresetsByIdSelector(presetId));

        const audioObjectRequests = [];

        preset.elementIds.forEach(elementId => {
            audioObjectRequests.push(call(getAudioItemForElement, {
                itemId: elementId,
                elementType: preset.audioType,
            }));
        });

        const items = yield all(audioObjectRequests);

        const entries = items.filter((item, index) => {
            const hasObject = !!item;

            if (!hasObject) {
                toastInstance.warning('featuresPreset:notifications.missingAudioObject', {
                    id: preset.elementIds[index],
                });
            }

            return hasObject;
        });

        if (entries.length) {
            const audioItemEntities = yield select(PreparedTaskVariationSelectors.selectAudioItemEntities);
            yield put(PreparedTaskVariationActions.storeAudioItemEntities({
                ...audioItemEntities,
                ...entries.reduce((accumulator, current) => {
                    accumulator[current.id] = current;

                    return accumulator;
                }, {}),
            }));

            let preparedTaskVariation = yield select(
                PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(
                    payload.taskVariationId,
                ),
            );

            const originalSpeakerText = yield select(PreparedTaskVariationSelectors.selectOriginalSpeakerText);

            if (!preparedTaskVariation) {
                preparedTaskVariation = yield call(constructPreparedVariationDto, {data: {
                    originalSpeakerText,
                    [`${payload.elementType}Ids`]: entries.map(entry => entry.id),
                }});
            } else {
                preparedTaskVariation = preparedTaskVariation.clone();
                preparedTaskVariation[`${payload.elementType}Ids`] = [
                    ...preparedTaskVariation[`${payload.elementType}Ids`],
                    ...entries
                        .filter(entry => !preparedTaskVariation[`${payload.elementType}Ids`].includes(entry.id))
                        .map(entry => entry.id),
                ];
                preparedTaskVariation = preparedTaskVariation.toDto();
            }

            const data = yield call(updatePreparedTaskVariationFlow, {
                taskVariationId: payload.taskVariationId,
                data: preparedTaskVariation,
            });

            const entities = yield select(PreparedTaskVariationSelectors.selectEntities);
            yield put(PreparedTaskVariationActions.storeEntities({
                ...entities,
                [payload.taskVariationId]: data,
            }));
        }
    } catch (error) {
        yield call(handleSagaError, error, 'loadFromPresetWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

// This saga will be triggered if the change of speaker text happens in the task list
const onUpdateSpeakerText = function* ({payload, source}) {
    try {
        // When the source is not user, we don't want to update prepared task variation
        // When the source is user, event handler will dispatch the action with source
        if (source !== 'user') {
            return;
        }

        const {taskVariationId, speakerText, originalSpeakerText} = payload;

        if (speakerText === originalSpeakerText) {
            return;
        }

        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, taskVariationId));

        const preparedTaskVariation = yield select(
            PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector(taskVariationId),
        );

        const data = yield call(constructPreparedVariationDto, {data: {
            ...preparedTaskVariation,
            speakerText,
            originalSpeakerText,
        }});

        const updatedPreparedTaskVariation = yield call(updatePreparedTaskVariationFlow, {
            taskVariationId,
            data,
        });
        const entities = yield select(PreparedTaskVariationSelectors.selectEntities);

        yield put(PreparedTaskVariationActions.storeEntities({
            ...entities,
            [taskVariationId]: updatedPreparedTaskVariation,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'onUpdateSpeakerText');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION, null));
    }
};

const onSetPreparingTaskVariationId = function* ({payload}) {
    const taskVariationId = payload;

    // If we are resetting (setting to null), we don't want to do the API call
    if (!taskVariationId) {
        return;
    }

    yield put(PreparedTaskVariationActions.loadForTaskVariation({
        taskVariationId,
    }));
};

const onCurrentTaskChange = function* ({payload}) {
    if (cockpitWindowController.isCockpitWindow()) {
        return;
    }

    const preparingTaskId = yield select(PreparedTaskVariationSelectors.selectPreparingTaskId);

    if (preparingTaskId === payload) {
        yield put(PreparedTaskVariationActions.setPreparingTaskId(null));
        yield put(PreparedTaskVariationActions.setPreparingTaskVariationId(null));
        yield put(PreparedTaskVariationActions.setOriginalSpeakerText(null));
    }
};

export const preparedTaskVariationSagas = function* () {
    yield all([
        takeEvery(PreparedTaskVariationActionTypes.LOAD_FOR_TASK_VARIATION, loadForTaskVariationWorker),
        takeEvery(PreparedTaskVariationActionTypes.UPDATE_SPEAKER_TEXT, onUpdateSpeakerText),
        takeEvery(PreparedTaskVariationActionTypes.SET_PREPARING_TASK_VARIATION_ID, onSetPreparingTaskVariationId),
        takeEvery(PreparedTaskVariationActionTypes.ADD_ELEMENT, addElementWorker),
        takeEvery(PreparedTaskVariationActionTypes.REMOVE_ELEMENT, removeElementWorker),
        takeEvery(PreparedTaskVariationActionTypes.SORT_ELEMENT, sortElementWorker),
        takeEvery(PreparedTaskVariationActionTypes.SORT_ELEMENT_DROP, sortElementDropWorker),
        takeEvery(PreparedTaskVariationActionTypes.LOAD_FROM_PRESET, loadFromPresetWorker),
        takeEvery(CurrentTaskActionTypes.SET_CURRENT_TASK_ID, onCurrentTaskChange),
        takeLatest([
            CurrentTaskActionTypes.SET_PRE_PRODUCED_VOLUME,
            CurrentTaskActionTypes.SET_SFX_VOLUME,
            CurrentTaskActionTypes.SET_BED_VOLUME,
            CurrentTaskActionTypes.SET_ARE_PRE_PRODUCED_ELEMENTS_MUTED,
            CurrentTaskActionTypes.SET_ARE_SFX_ELEMENTS_MUTED,
            CurrentTaskActionTypes.SET_ARE_BED_ELEMENTS_MUTED,
            CurrentTaskActionTypes.SET_SPEAKER_TEXT,
            CurrentTaskActionTypes.SET_LOADED_ELEMENTS,
            CurrentTaskActionTypes.CLEAR_LOADED_ELEMENTS,
        ], updatePreparedTaskVariationWorker),
    ]);
};
