import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './Show.module.scss';
import {Typography} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {View} from '../View/View';

export const Show = ({showKey}) => {
    const show = useMemoizedCreateSelector(PlaylistSelectors.createShowByKeySelector, showKey);

    return (
        <div className={clsx(classes.root, classes[`color-${show.color}`])}>
            <Typography variant="body" weight={500} size={11} color="stone" className={classes.times}>
                {show.startTime}&nbsp;&ndash;&nbsp;{show.endTime}
            </Typography>

            <Typography variant="body" weight={500} size={24} color="stone" hasGutters={false}>
                {show.name}
            </Typography>

            <div className={classes.views}>
                {show.viewKeys.map((viewKey, viewIndex) => {
                    return (
                        <View
                            key={viewKey}
                            viewIndex={viewIndex}
                            viewKey={viewKey}
                        />
                    );
                })}
            </div>
        </div>
    );
};

Show.propTypes = {
    showKey: PropTypes.string.isRequired,
};
