import {useEffect, useState} from 'react';

// >>> ReminderSubscriber
/**
 * @param {Integer} id
 * @param {ReminderKeysType} key
 * @param {function} callback
 * @constructor
 */
const ReminderSubscriber = function (id, key, callback) {
    this.id = id;
    this.key = key;
    this.callback = callback;
};

/**
 * @param {any} value
 */
ReminderSubscriber.prototype.notify = function (value) {
    this.callback(value);
};

/**
 * @returns {Integer}
 */
ReminderSubscriber.prototype.getId = function () {
    return this.id;
};

/**
 * @returns {ReminderKeysType}
 */
ReminderSubscriber.prototype.getReminderKey = function () {
    return this.key;
};
// <<< ReminderSubscriber

// >>> ReminderStorage
/**
 * @constructor
 */
const ReminderStorage = function () {
    this.state = {};
    /**
     * @type {number}
     */
    this.currentId = 1;
    /**
     * @type {number}
     */
    this.userId = null;
    /**
     * @type {ReminderSubscriber[]}
     */
    this.subscribers = [];
};

/**
 * @param {ReminderKeysType} reminderKey
 * @returns {boolean}
 */
ReminderStorage.prototype.shouldRemind = function (reminderKey) {
    return !(this.state.hasOwnProperty(reminderKey) && this.state[reminderKey]);
};

/**
 * @param {ReminderKeysType} reminderKey
 * @returns {boolean}
 */
ReminderStorage.prototype.getValue = function (reminderKey) {
    if (this.state.hasOwnProperty(reminderKey)) {
        return Boolean(this.state[reminderKey]);
    }

    return false;
};

/**
 * @param {ReminderKeysType} reminderKey
 * @param {any} value
 */
ReminderStorage.prototype.setValue = function (reminderKey, value) {
    this.state = {
        ...this.state,
        [reminderKey]: value,
    };

    localStorage.setItem(`${this.userId}/remindFlags`, JSON.stringify(this.state));

    this.subscribers.forEach(subscriber => {
        if (subscriber.getReminderKey() === reminderKey) {
            subscriber.notify(value);
        }
    });
};

/**
 * @param {ReminderKeysType} reminderKey
 * @param {function} callback
 * @returns {(function(): void)}
 */
ReminderStorage.prototype.subscribe = function (reminderKey, callback) {
    const subscriberId = this.currentId;

    this.currentId += 1;

    const subscriber = new ReminderSubscriber(subscriberId, reminderKey, callback);

    this.subscribers = [
        ...this.subscribers,
        subscriber,
    ];

    return () => {
        this.subscribers = this.subscribers.filter(subscriber => subscriber.getId() !== subscriberId);
    };
};

ReminderStorage.prototype.reset = function () {
    this.subscribers = [];
    this.currentId = 1;
    this.state = {};
};

/**
 * @param {Integer} userId
 */
ReminderStorage.prototype.initialize = function (userId) {
    this.userId = userId;
    this.state = JSON.parse(localStorage.getItem(`${this.userId}/remindFlags`) || '{}');

    this.subscribers.forEach(subscriber => {
        Object.keys(ReminderKeys).forEach(key => {
            if (subscriber.getReminderKey() === ReminderKeys[key]) {
                subscriber.notify(this.state[ReminderKeys[key]] || false);
            }
        });
    });
};
// <<< ReminderStorage

/**
 * @typedef {('crossChannelTaskDialog')} ReminderKeysType
 */
export const ReminderKeys = {
    CROSS_CHANNEL_TASK_DIALOG: 'crossChannelTaskDialog',
};

/**
 * @type {ReminderStorage}
 */
export const reminderStorage = new ReminderStorage();

/**
 * @param {ReminderKeysType} reminderKey
 * @returns {{value: boolean}}
 */
export const useReminderStorageValue = function (reminderKey) {
    const [value, setValue] = useState(reminderStorage.getValue(reminderKey));

    useEffect(() => {
        const unsubscribe = reminderStorage.subscribe(reminderKey, value => {
            setValue(value);
        });

        return () => {
            unsubscribe();
        };
    }, [reminderKey]);

    return value;
};
