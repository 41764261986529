import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './PageHeader.module.scss';
import {Typography} from '../Typography/Typography';

export const PageHeader = ({children, className, subtitle, align}) => {
    return (
        <div className={clsx(classes.root, className)}>
            {!!subtitle && (
                <Typography
                    variant="subtitle"
                    hasGutters={false}
                    weight={500}
                    size={11}
                    color="stone"
                    align={align}
                >
                    {subtitle}
                </Typography>
            )}

            <Typography
                variant="title"
                hasGutters={false}
                transform="none"
                size={30}
                color="cello"
                weight={500}
                align={align}
                className={classes.title}
            >
                {children}
            </Typography>
        </div>
    );
};

PageHeader.propTypes = {
    subtitle: PropTypes.string,
    className: PropTypes.string,
    align: Typography.propTypes.align,
};

PageHeader.defaultProps = {
    subtitle: null,
    className: '',
    align: Typography.defaultProps.align,
};
