import {createSelector} from 'reselect';

const selectLoadingState = state => state.loading;

const createLoadingByTypeSelector = loadingType => createSelector(
    [LOADING_SELECTOR.selectLoadingState],
    loadingState => {
        if (!loadingState.hasOwnProperty(loadingType)) {
            return false;
        }

        return loadingState[loadingType];
    },
);

export const LOADING_SELECTOR = {
    selectLoadingState,
    createLoadingByTypeSelector,
};
