import {ChannelActionTypes} from './channel.action-type';

export const ChannelActions = {
    storeEntities: entities => ({
        type: ChannelActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    get: brandId => ({
        type: ChannelActionTypes.GET,
        payload: {
            brandId,
        },
    }),
};
