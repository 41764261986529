import {ALL_COMMANDS} from './audioContextConstants';
import downloadImage from '../../assets/img/download.png';
import microphoneImageDone from '../../assets/img/microphone-done.png';
import microphoneImage from '../../assets/img/microphone.png';
import nextElementImage from '../../assets/img/next-element.png';
import nextImage from '../../assets/img/next.png';
import preProducedImage from '../../assets/img/pre-produced-marker.png';
import previousElementImage from '../../assets/img/previous-element.png';
import skipPreProducedImage from '../../assets/img/skip-pre-produced.png';
import uploadImage from '../../assets/img/upload.png';
import {ReactComponent as EndAlgorithmIcon} from '../../assets/svg/end-algorithm.svg';
import {ReactComponent as FadeNextElementIcon} from '../../assets/svg/fade-next-element.svg';
import {ReactComponent as LoadTaskIcon} from '../../assets/svg/load-task.svg';
import {ReactComponent as PlayPreviousElementIcon} from '../../assets/svg/play-previous-element.svg';
import {ReactComponent as RecordingIcon} from '../../assets/svg/recording.svg';
import {ReactComponent as SkipPreProducedAudioIcon} from '../../assets/svg/skip-pre-produced-audio.svg';
import {ReactComponent as StartNextElementIcon} from '../../assets/svg/start-next-element.svg';
import {ReactComponent as StartPreProducedAudioIcon} from '../../assets/svg/start-pre-produced-audio.svg';
import {ReactComponent as StartRecordingIcon} from '../../assets/svg/start-recording.svg';
import {ReactComponent as StopNextElementIcon} from '../../assets/svg/stop-next-element.svg';
import {ReactComponent as StopRecordingIcon} from '../../assets/svg/stop-recording.svg';
import {ReactComponent as UploadTaskIcon} from '../../assets/svg/upload-task.svg';
import {AUDIO_ELEMENT_TYPES, VOLUME_LEVELS} from '../../constants';


export const masterButtonTitleMap = {
    [ALL_COMMANDS.INIT]: 'Load audio',
    [ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT]: 'Start task',
    [ALL_COMMANDS.STOP_PREVIOUS_ELEMENT]: 'Stop previous element',
    [ALL_COMMANDS.START_RECORDING]: 'Start recording',
    [ALL_COMMANDS.STOP_RECORDING]: 'Stop recording',
    [ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO]: 'Start pre-prod audio',
    [ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO]: 'Skip audio',
    [ALL_COMMANDS.PLAY_NEXT_ELEMENT]: 'Start next element',
    [ALL_COMMANDS.FADE_IN_NEXT_ELEMENT]: 'Stop recording',
    [ALL_COMMANDS.STOP_NEXT_ELEMENT]: 'Stop next element',
    [ALL_COMMANDS.UPLOAD]: 'Upload task',
    [ALL_COMMANDS.END]: 'Next',
};

export const masterButtonSubtitleMap = {
    [ALL_COMMANDS.INIT]: '',
    [ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT]: '',
    [ALL_COMMANDS.STOP_PREVIOUS_ELEMENT]: 'Playing previous element...',
    [ALL_COMMANDS.START_RECORDING]: '',
    [ALL_COMMANDS.STOP_RECORDING]: '',
    [ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO]: 'Recording...',
    [ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO]: 'Recording...',
    [ALL_COMMANDS.PLAY_NEXT_ELEMENT]: 'Recording...',
    [ALL_COMMANDS.FADE_IN_NEXT_ELEMENT]: 'Recording...',
    [ALL_COMMANDS.STOP_NEXT_ELEMENT]: 'Playing next element...',
    [ALL_COMMANDS.UPLOAD]: '',
    [ALL_COMMANDS.END]: '',
};

export const masterButtonIconMap = {
    [ALL_COMMANDS.INIT]: LoadTaskIcon,
    [ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT]: PlayPreviousElementIcon,
    [ALL_COMMANDS.STOP_PREVIOUS_ELEMENT]: RecordingIcon,
    [ALL_COMMANDS.START_RECORDING]: StartRecordingIcon,
    [ALL_COMMANDS.STOP_RECORDING]: StopRecordingIcon,
    [ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO]: StartPreProducedAudioIcon,
    [ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO]: SkipPreProducedAudioIcon,
    [ALL_COMMANDS.PLAY_NEXT_ELEMENT]: StartNextElementIcon,
    [ALL_COMMANDS.FADE_IN_NEXT_ELEMENT]: FadeNextElementIcon,
    [ALL_COMMANDS.STOP_NEXT_ELEMENT]: StopNextElementIcon,
    [ALL_COMMANDS.UPLOAD]: UploadTaskIcon,
    [ALL_COMMANDS.END]: EndAlgorithmIcon,
};

export const masterButtonImageSourceMap = {
    [ALL_COMMANDS.INIT]: downloadImage,
    [ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT]: previousElementImage,
    [ALL_COMMANDS.STOP_PREVIOUS_ELEMENT]: microphoneImage,
    [ALL_COMMANDS.START_RECORDING]: microphoneImage,
    [ALL_COMMANDS.STOP_RECORDING]: microphoneImageDone,
    [ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO]: preProducedImage,
    [ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO]: skipPreProducedImage,
    [ALL_COMMANDS.PLAY_NEXT_ELEMENT]: nextElementImage,
    [ALL_COMMANDS.FADE_IN_NEXT_ELEMENT]: microphoneImageDone,
    [ALL_COMMANDS.STOP_NEXT_ELEMENT]: nextImage,
    [ALL_COMMANDS.UPLOAD]: uploadImage,
    [ALL_COMMANDS.END]: nextImage,
};

export const masterButtonColorMap = {
    [ALL_COMMANDS.INIT]: {
        colorName: 'stone',
        hex: '#747f88',
    },
    [ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT]: {
        colorName: 'radiance',
        hex: '#0080ff',
    },
    [ALL_COMMANDS.STOP_PREVIOUS_ELEMENT]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.START_RECORDING]: {
        colorName: 'radiance',
        hex: '#0080ff',
    },
    [ALL_COMMANDS.STOP_RECORDING]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.PLAY_NEXT_ELEMENT]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.FADE_IN_NEXT_ELEMENT]: {
        colorName: 'monza',
        hex: '#d0021b',
    },
    [ALL_COMMANDS.STOP_NEXT_ELEMENT]: {
        colorName: 'radiance',
        hex: '#0080ff',
    },
    [ALL_COMMANDS.UPLOAD]: {
        colorName: 'jade',
        hex: '#00bc3f',
    },
    [ALL_COMMANDS.END]: {
        colorName: 'jade',
        hex: '#00bc3f',
    },
};

export const calculateBumperOffset = (length, cueIn, startNext) => {
    if (!length) {
        return 0;
    }

    let bumperOffset = cueIn + startNext - 3000;

    if (parseInt(length, 10) < 3000) {
        bumperOffset = cueIn;
    }

    if (bumperOffset < 0) {
        return 0;
    }

    return bumperOffset;
};

const ADDITIONAL_BUMPER_COMMAND_ELEMENT_TYPES = ['BB', 'BED', 'DRN'];
const ADDITIONAL_BUMPER_COMMAND_CONTENT_TYPES = ['AudioBranding'];

// Hacking a bit because Regiocast DDS API doesn't return proper lengths/durations of audios.
// We have to decode the array buffer we've got and get the duration from there.
// TODO: Eventually, remove this workaround and get the durations from Regiocast API.
export const generateAlgorithmCommands = (
    preProducedAudioNodes,
    hasBumper,
    hasStinger,
    contentTypeOfPreviousElement,
    elementTypeOfPreviousElement,
) => {
    const isPreviousElementAudioBranding = ADDITIONAL_BUMPER_COMMAND_CONTENT_TYPES
        .includes(contentTypeOfPreviousElement);
    const isPreviousElementBumperBed = ADDITIONAL_BUMPER_COMMAND_ELEMENT_TYPES
        .includes(elementTypeOfPreviousElement);

    const commands = [
        ALL_COMMANDS.INIT,
    ];

    if (hasBumper) {
        commands.push(ALL_COMMANDS.PLAY_PREVIOUS_ELEMENT);

        commands.push([
            ALL_COMMANDS.DUCK_PREVIOUS_ELEMENT,
            ALL_COMMANDS.START_RECORDING,
        ]);
    } else {
        commands.push(ALL_COMMANDS.START_RECORDING);
    }

    // preProducedAudioNodes.forEach(audioNode => {
    //     commands.push(ALL_COMMANDS.PLAY_PRE_PRODUCED_AUDIO);
    //
    //     if (audioNode.duration > 5) {
    //         commands.push([
    //             ALL_COMMANDS.STOP_RECORDING,
    //             ALL_COMMANDS.START_RECORDING,
    //             ALL_COMMANDS.SKIP_PRE_PRODUCED_AUDIO,
    //         ]);
    //     }
    // });

    if (hasBumper && hasStinger) {
        commands.push([
            ALL_COMMANDS.STOP_PREVIOUS_ELEMENT,
            ALL_COMMANDS.PLAY_NEXT_ELEMENT,
        ]);
    } else if (hasBumper) {
        if (isPreviousElementAudioBranding && isPreviousElementBumperBed) {
            commands.push([
                ALL_COMMANDS.STOP_PREVIOUS_ELEMENT,
                ALL_COMMANDS.STOP_RECORDING,
            ]);
        }
    } else if (hasStinger) {
        commands.push(ALL_COMMANDS.PLAY_NEXT_ELEMENT);
    }

    if (hasStinger) {
        commands.push([
            ALL_COMMANDS.STOP_RECORDING,
            ALL_COMMANDS.FADE_IN_NEXT_ELEMENT,
        ]);
    } else if (!isPreviousElementAudioBranding || !isPreviousElementBumperBed) {
        commands.push(ALL_COMMANDS.STOP_RECORDING);
    }

    commands.push(ALL_COMMANDS.UPLOAD);

    return commands;
};

/**
 * Map of volume indexes and object keys in sound settings.
 * For example, volume index 0 is always mute. In the soundSettings.preProducedElement, there will be a key muteVolume
 * which holds a value of gain when the element is muted.
 * Same logic applies to lowVolume, normalVolume and loudVolume.
 *
 * @type {{0: string, 1: string, 2: string, 3: string}}
 */
const VOLUME_INDEX_KEYS = {
    0: 'muteVolume',
    1: 'lowVolume',
    2: 'normalVolume',
    3: 'loudVolume',
};

/**
 * Gets the volumeIndex and returns the volume value from sound settings based on element type.
 *
 * @param volumeIndex
 * @param soundSettings
 * @param elementType
 * @return {*}
 */
export const getVolume = (volumeIndex, soundSettings, elementType) => {
    switch (elementType) {
        case AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS:
            return soundSettings.preProducedElement[VOLUME_INDEX_KEYS[volumeIndex]];

        case AUDIO_ELEMENT_TYPES.SOUND_EFFECTS:
            return soundSettings.soundEffect[VOLUME_INDEX_KEYS[volumeIndex]];

        case AUDIO_ELEMENT_TYPES.MUSIC_BEDS:
            return soundSettings.musicBed[VOLUME_INDEX_KEYS[volumeIndex]];
    }

    return VOLUME_LEVELS[volumeIndex] / 100;
};

/**
 * Gets the volume value from sound settings and returns the volume index based on element type.
 *
 * @param soundSettings
 * @param elementType
 * @return {number}
 */
export const getDefaultVolumeIndex = (soundSettings, elementType) => {
    let elementTypeKey = null;

    switch (elementType) {
        case AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS:
            elementTypeKey = 'preProducedElement';

            break;

        case AUDIO_ELEMENT_TYPES.SOUND_EFFECTS:
            elementTypeKey = 'soundEffect';

            break;

        case AUDIO_ELEMENT_TYPES.MUSIC_BEDS:
            elementTypeKey = 'musicBed';

            break;
    }

    const defaultVolume = soundSettings[elementTypeKey].defaultVolume;

    if (defaultVolume === soundSettings[elementTypeKey][VOLUME_INDEX_KEYS[0]]) {
        return 0;
    } else if (defaultVolume === soundSettings[elementTypeKey][VOLUME_INDEX_KEYS[1]]) {
        return 1;
    } else if (defaultVolume === soundSettings[elementTypeKey][VOLUME_INDEX_KEYS[2]]) {
        return 2;
    } else if (defaultVolume === soundSettings[elementTypeKey][VOLUME_INDEX_KEYS[3]]) {
        return 3;
    }

    return 1;
};
