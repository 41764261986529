import {useEffect, useRef, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';

export const usePlaybackNodes = key => {
    const [nodes, setNodes] = useState([]);
    const subscriber = useRef(null);

    useEffect(() => {
        const onUpdate = data => {
            setNodes(data.hasOwnProperty(key) ? data[key] : data);
        };

        subscriber.current = playbackAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.PLAYBACK_DATA);

        return () => {
            subscriber.current.unsubscribe();
        };
    }, [key]);

    return {nodes};
};
