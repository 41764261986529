import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './Container.module.scss';

export const Container = ({children, hasGutters, className}) => {
    return (
        <div
            className={clsx(classes.root, className, {
                [classes.disableGutters]: !hasGutters,
            })}
        >
            {children}
        </div>
    );
};

Container.propTypes = {
    hasGutters: PropTypes.bool,
    className: PropTypes.string,
};

Container.defaultProps = {
    hasGutters: true,
    className: '',
};
