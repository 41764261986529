export const LOADING_TYPES = {
    TASKS: 'TASKS',
    URGENT_TASKS: 'URGENT_TASKS',
    BRANDS: 'BRANDS',
    CHANNELS: 'CHANNELS',
    PLAYLIST: 'PLAYLIST',
    TASK_STATS: 'TASK_STATS',
    PLAYER: 'PLAYER',
    CREATING_ALTERNATIVE_TASK: 'CREATING_ALTERNATIVE_TASK',
    AUDIO_ITEMS: 'AUDIO_ITEMS',
    AUDIO_ITEM: 'AUDIO_ITEM',
    PRESET_FORM: 'CREATE_PRESET',
    DELETE_PRESET: 'DELETE_PRESET',
    LOAD_ELEMENTS_FROM_PRESET: 'LOAD_ELEMENTS_FROM_PRESET',
    COCKPIT_DATA: 'COCKPIT_DATA',
    EDITOR: 'EDITOR',
    OVERRIDE_EXPIRATION_DATE_FORM: 'OVERRIDE_EXPIRATION_DATE_FORM',
    OVERRIDE_VALIDITY_PERIOD_FORM: 'OVERRIDE_VALIDITY_PERIOD_FORM',
    PREPARED_TASK_VARIATION: 'PREPARED_TASK_VARIATION',
    LOAD_PRESETS: 'LOAD_PRESETS',
    TASK_OVERVIEW: 'TASK_OVERVIEW',
    GENERIC_TASKS: 'GENERIC_TASKS',
    UPDATE_PLAYING_TYPE: 'UPDATE_PLAYING_TYPE',
};
