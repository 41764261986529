import React from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const ChevronUp = React.forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path transform="rotate(90 9 9)" d="M14.6,15.9L7.7,9l6.9-6.9L12.4,0l-9,9l9,9L14.6,15.9z" />
        </SvgIcon>
    );
});
