import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import {all, call, delay, put, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {RecordingAlgorithmActionTypes} from './recording-algorithm.action-type';
import {CONFIG} from '../../../config';
import {handleSagaError} from '../../../error.saga';
import {ddsPlanningApi} from '../../../lib/axios';
import {Debug} from '../../../lib/debug';
import {IndexedDb} from '../../../lib/indexed-db';
import {RouterSelector} from '../../../router.selector';
import {AdjacentElementsSelectors} from '../../adjacent-elements/store/adjacent-elements.selector';
import {loadEntities} from '../../audio-item/store/audio-item.saga';
import {AudioItemSelectors} from '../../audio-item/store/audio-item.selector';
import {CurrentTaskActionTypes, CurrentTaskSelectors} from '../../current-task';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {MixingPlanActions} from '../../mixing-plan/store/mixing-plan.action';
import {MixingPlanSelectors} from '../../mixing-plan/store/mixing-plan.selector';
import {PlayerActions} from '../../player';
import {getDevices} from '../../sound-device-configuration/utils/get-devices';
import {VolumeIndexMap} from '../../sound-settings/sound-settings.constants';
import {SoundSettingsSelectors} from '../../sound-settings/store/sound-settings.selector';
import {TaskSelectors} from '../../task';
import {TaskActions} from '../../task/store/task.action';
import {UiActions} from '../../ui/store/ui.action';
import {ModalKeys} from '../../ui/utils/constants';
import {
    ALL_COMMANDS,
    MUSIC_BED_AUDIO_FILE_NAME_PREFIX,
    PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX,
    SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX,
} from '../audioContextConstants';
import {calculateBumperOffset} from '../audioContextHelpers';
import MusicBedAudioNode from '../audioNodes/MusicBedAudioNode';
import NextElementAudioNode from '../audioNodes/NextElementAudioNode';
import PreProducedAudioNode from '../audioNodes/PreProducedAudioNode';
import PreviousElementAudioNode from '../audioNodes/PreviousElementAudioNode';
import SoundEffectAudioNode from '../audioNodes/SoundEffectAudioNode';
import playbackAlgorithm from '../PlaybackAlgorithm';
import recordingAlgorithm from '../RecordingAlgorithm';
import RecordingMetadataTransformer from '../recordingMetadataTransformer';
import {encodeWav} from '../utils/encode-wav';
import {getVolumeByIndex} from '../utils/get-volume-by-index';

if (CONFIG.NODE_ENVIRONMENT === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window._rec = recordingAlgorithm;
    // eslint-disable-next-line no-underscore-dangle
    window._play = playbackAlgorithm;
}

export const getAudioData = audioSources => {
    try {
        const audioUrls = [];

        // Sort audio sources, so OPUS format has priority
        audioSources.forEach(audioSource => {
            if (audioSource.type === 'opus') {
                audioUrls[0] = audioSource.url;
            } else if (audioSource.type === 'mp3') {
                audioUrls[1] = audioSource.url;
            } else if (audioSource.type === 'wav') {
                audioUrls[2] = audioSource.url;
            }
        });

        const opusUrl = audioUrls[0];
        const mp3Url = audioUrls[1];
        const wavUrl = audioUrls[2];

        return opusUrl || mp3Url || wavUrl || null;
    } catch (error) {
        // no-op
    }
};

const getAudioSources = ({externalId}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/audiofiles/${externalId}`)
        .then(result => {
            const audioFiles = [];

            if (Array.isArray(result.data) && result.data.length > 0) {
                // Sort audio sources, so OPUS format has priority
                result.data.forEach(audioSource => {
                    if (audioSource.type === 'opus') {
                        audioFiles[0] = audioSource;
                    }

                    if (audioSource.type === 'mp3') {
                        audioFiles[1] = audioSource;
                    }

                    if (audioSource.type === 'wav') {
                        audioFiles[2] = audioSource;
                    }
                });
            }

            return audioFiles;
        });
};

const getBlob = ({url}) => {
    return axios.get(url, {
        responseType: 'blob',
    }).then(response => response.data);
};

const downloadLoadedElements = function* (loadedElementIds) {
    if (!loadedElementIds) {
        loadedElementIds = yield select(CurrentTaskSelectors.createLoadedElementIdsSelector);
    }

    const previousElement = yield select(AdjacentElementsSelectors.selectPreviousElement);
    const nextElement = yield select(AdjacentElementsSelectors.selectNextElement);
    const ids = [];

    for (const id of loadedElementIds) {
        // Check if the file is available in the IndexedDB
        const audio = yield call([IndexedDb, IndexedDb.getAudioBlob], id);

        if (audio) {
            Debug.debug('recording-algorithm.saga', `The element ${id} has been already downloaded. Skipping...`);
            continue;
        }

        Debug.debug('recording-algorithm.saga', `Scheduling download for ${id}...`);
        ids.push(id);
    }

    if (previousElement && previousElement.id) {
        const previousElementAudio = yield call([IndexedDb, IndexedDb.getAudioBlob], previousElement.id);

        if (!previousElementAudio) {
            Debug.debug('recording-algorithm.saga', `Scheduling download of previous element for ${previousElement.id}...`);

            ids.push(previousElement.id);
        } else {
            Debug.debug('recording-algorithm.saga', `The previous element ${previousElement.id} has been already downloaded. Skipping...`);
        }
    }

    if (nextElement && nextElement.id) {
        const nextElementAudio = yield call([IndexedDb, IndexedDb.getAudioBlob], nextElement.id);

        if (!nextElementAudio) {
            Debug.debug('recording-algorithm.saga', `Scheduling download of next element for ${nextElement.id}...`);

            ids.push(nextElement.id);
        } else {
            Debug.debug('recording-algorithm.saga', `The next element ${nextElement.id} has been already downloaded. Skipping...`);
        }
    }

    const audioSources = yield all(ids.map(id => call(getAudioSources, {externalId: id})));
    const audioSourceUrls = yield all(audioSources.map(audioSources => call(getAudioData, audioSources)));
    const blobs = yield all(audioSourceUrls.map(url => call(getBlob, {url})));

    yield all(audioSourceUrls.map((audioUrl, index) => {
        return call([IndexedDb, IndexedDb.storeAudio], ids[index], blobs[index]);
    }));
};

const createNodes = function* () {
    const previousElement = yield select(AdjacentElementsSelectors.selectPreviousElement);
    const nextElement = yield select(AdjacentElementsSelectors.selectNextElement);

    const nodeCreationCalls = [];

    if (previousElement && previousElement.id) {
        nodeCreationCalls.push(call(
            [recordingAlgorithm, recordingAlgorithm.setupAudioNode],
            previousElement,
            'previousElementNode',
            new PreviousElementAudioNode(),
            1,
            calculateBumperOffset(previousElement.length, previousElement.cueIn, previousElement.startNext) / 1000,
        ));
    }

    if (nextElement && nextElement.id) {
        nodeCreationCalls.push(call(
            [recordingAlgorithm, recordingAlgorithm.setupAudioNode],
            nextElement,
            'nextElementNode',
            new NextElementAudioNode(),
            1,
            nextElement.cueIn / 1000,
        ));
    }

    const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);
    const preProduced = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('preProduced'));
    const sfx = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('sfx'));
    const bed = yield select(CurrentTaskSelectors.createLoadedElementIdsByTypeSelector('bed'));
    const preProducedElementsVolume = yield select(CurrentTaskSelectors.selectPreProducedVolume);
    const arePreProducedElementsMuted = yield select(CurrentTaskSelectors.selectArePreProducedElementsMuted);
    const sfxElementsVolume = yield select(CurrentTaskSelectors.selectSfxVolume);
    const areSfxElementsMuted = yield select(CurrentTaskSelectors.selectAreSfxElementsMuted);
    const bedElementsVolume = yield select(CurrentTaskSelectors.selectBedVolume);
    const areBedElementsMuted = yield select(CurrentTaskSelectors.selectAreBedElementsMuted);

    for (const index in preProduced) {
        const id = preProduced[index];
        const element = yield select(CurrentTaskSelectors.createLoadedElementByIdSelector(id));

        nodeCreationCalls.push(call(
            [recordingAlgorithm, recordingAlgorithm.setupAudioNode],
            element,
            `${PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX}${index}`,
            new PreProducedAudioNode(),
            getVolumeByIndex(soundSettings, arePreProducedElementsMuted ? 0 : preProducedElementsVolume, 'preProduced'),
            element.cueIn / 1000,
        ));
    }

    for (const index in sfx) {
        const id = sfx[index];
        const element = yield select(CurrentTaskSelectors.createLoadedElementByIdSelector(id));

        nodeCreationCalls.push(call(
            [recordingAlgorithm, recordingAlgorithm.setupAudioNode],
            element,
            `${SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX}${index}`,
            new SoundEffectAudioNode(),
            getVolumeByIndex(soundSettings, areSfxElementsMuted ? 0 : sfxElementsVolume, 'sfx'),
            element.cueIn / 1000,
        ));
    }

    for (const index in bed) {
        const id = bed[index];
        const element = yield select(CurrentTaskSelectors.createLoadedElementByIdSelector(id));

        nodeCreationCalls.push(call(
            [recordingAlgorithm, recordingAlgorithm.setupAudioNode],
            element,
            `${MUSIC_BED_AUDIO_FILE_NAME_PREFIX}${index}`,
            new MusicBedAudioNode(),
            getVolumeByIndex(soundSettings, areBedElementsMuted ? 0 : bedElementsVolume, 'bed'),
            element.cueIn / 1000,
        ));
    }

    yield all(nodeCreationCalls);
};

const initializeRecordingAlgorithm = function* () {
    yield call([recordingAlgorithm, recordingAlgorithm.executeNextCommand]);

    const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);

    yield call([recordingAlgorithm, recordingAlgorithm.setSoundSettings], soundSettings);
};

const executeNextCommandWorker = function* (action = {}) {
    const {shouldForce} = action;

    const isInProgress = yield call([recordingAlgorithm, recordingAlgorithm.isInProgress]);
    const nextCommand = yield call([recordingAlgorithm, recordingAlgorithm.getNextCommand]);

    if (nextCommand === ALL_COMMANDS.INIT && !shouldForce) {
        yield put(PlayerActions.setIsOpen(false));

        const inputDeviceId = yield call([localStorage, localStorage.getItem], 'inputDeviceId');
        const outputDeviceId = yield call([localStorage, localStorage.getItem], 'outputDeviceId');

        const hasPreferredDevices = !!inputDeviceId && !!outputDeviceId;
        const devices = yield call(getDevices);
        const isInputDeviceValid = devices.inputDevices.find(device => device.id === inputDeviceId);
        const isOutputDeviceValid = devices.outputDevices.find(device => device.id === outputDeviceId);

        if (!hasPreferredDevices || !isInputDeviceValid || !isOutputDeviceValid) {
            yield put(UiActions.setModalState(ModalKeys.MISSING_DEVICE_SETTINGS, true));

            return;
        }
    }

    if (isInProgress) {
        yield call([recordingAlgorithm, recordingAlgorithm.executeNextCommand]);

        return;
    } else if (nextCommand === ALL_COMMANDS.UPLOAD) {
        yield call(uploadFromRecordingAlgorithm);
        return;
    }

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, true));

    yield delay(225);

    yield call(resetAlgorithmWorker);

    yield call(downloadLoadedElements);
    yield call(initializeRecordingAlgorithm);
    yield take('ALGORITHM_INITIALIZED');
    yield call(createNodes);
    yield call([recordingAlgorithm, recordingAlgorithm.generateAlgorithmCommands]);
    yield call([recordingAlgorithm, recordingAlgorithm.setCanExecuteNextCommand], true);
    yield call([recordingAlgorithm, recordingAlgorithm.resumeContext]);

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, false));
};

const uploadFromRecordingAlgorithm = function* () {
    const taskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);
    const taskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const task = yield select(TaskSelectors.selectCurrentTask);

    let mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);

    const tries = 0;
    while (!mixingPlan) {
        mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);

        yield delay(100);

        if (tries === 5000) {
            throw new Error('Failed to fetch mixing plan.');
        }
    }

    const recordingElements = mixingPlan.elements.filter(element => element.type === 'recording');
    const tasks = recordingElements.map(element => call([IndexedDb, IndexedDb.getAudioBlob], element.fileName));
    const files = yield all(tasks);

    let expirationDate;

    if (!task.isReusable) {
        const {location} = yield select(RouterSelector.selectRouterState);
        const {search} = location;
        const queryParams = queryString.parse(search);

        expirationDate = moment(queryParams.startTime, 'YYYY-MM-DD HH:mm:ss').toDate();
    } else if (task.isExpirationDateOverridden) {
        expirationDate = task.overriddenExpirationDate;
    } else if (task.expirationDate) {
        expirationDate = task.expirationDate;
    }

    yield put(TaskActions.completeTaskVariation(
        taskId,
        taskVariationId,
        mixingPlan,
        files,
        null,
        expirationDate,
        null,
        'cockpit',
    ));
};

/**
 * @param {AudioContext} audioContext
 * @param buffers
 * @param {int} index
 * @param {int} taskVariationId
 */
const processStream = function* (audioContext, buffers, index, taskVariationId) {
    const filename = `${taskVariationId}_recording_${index}.wav`;

    Debug.debug('recording-algorithm.saga/processStream', 'Encoding WAV...');
    const blob = yield call(encodeWav, buffers, audioContext.sampleRate);
    Debug.debug('recording-algorithm.saga/processStream', 'Finished encoding WAV.');

    Debug.debug('recording-algorithm.saga/processStream', 'Storing audio...');
    yield call([IndexedDb, IndexedDb.storeAudio], filename, blob);
    Debug.debug('recording-algorithm.saga/processStream', 'Finished storing audio.');

    Debug.debug('recording-algorithm.saga/processStream', 'Getting ArrayBuffer from Blob...');
    const arrayBuffer = yield call([blob, blob.arrayBuffer]);
    Debug.debug('recording-algorithm.saga/processStream', 'Finished getting ArrayBuffer from Blob.');

    Debug.debug('recording-algorithm.saga/processStream', 'Decoding AudioBuffer from ArrayBuffer...');
    const audioBuffer = yield call([audioContext, audioContext.decodeAudioData], arrayBuffer);
    Debug.debug('recording-algorithm.saga/processStream', 'Finished decoding AudioBuffer from ArrayBuffer.');

    return {
        filename,
        duration: audioBuffer.duration,
    };
};

const createMixingPlan = function* (streams) {
    try {
        const taskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
        const audioContext = recordingAlgorithm.audioContext;
        const tasks = [];

        for (const index in streams) {
            const stream = streams[index];

            tasks.push(call(processStream, audioContext, stream.buffers, index, taskVariationId));
        }

        const metadata = yield all(tasks);

        const transformer = new RecordingMetadataTransformer(metadata.map(data => data.duration));

        return transformer.getMixingPlan({
            recordedFileNames: metadata.map(data => data.filename),
        });
    } catch (error) {
        yield call(handleSagaError, error, 'createMixingPlan');
    }
};

const endAlgorithmWorker = function* () {
    try {
        const streams = yield call([recordingAlgorithm, recordingAlgorithm.getRecordedStreams]);

        const mixingPlan = yield call(createMixingPlan, streams);

        yield call([recordingAlgorithm, recordingAlgorithm.resolveEndSagaPromise]);

        yield put(MixingPlanActions.store(mixingPlan, 'recordingAlgorithm'));

        yield call(loadElementsFromMixingPlan);
        yield call([playbackAlgorithm, playbackAlgorithm.setMixingPlan], mixingPlan.clone(), true);
        yield call([playbackAlgorithm, playbackAlgorithm.initialize]);
    } catch (error) {
        yield call(handleSagaError, error, 'endAlgorithmWorker');
    }
};

export const loadElementsFromMixingPlan = function* () {
    try {
        const mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);

        let uniqueItems = {};
        mixingPlan.elements.forEach(element => {
            // Locally saved recording, we don't want to load this
            if (element.hasOwnProperty('fileName')) {
                return;
            }

            if (uniqueItems[element.externalId]) {
                return;
            }

            uniqueItems[element.externalId] = {
                id: element.externalId,
                elementType: element.type,
            };
        });
        const uniqueItemKeys = Object.keys(uniqueItems);
        uniqueItems = Object.values(uniqueItems);

        try {
            yield all([
                call(loadEntities, uniqueItems),
                call(downloadLoadedElements, uniqueItemKeys),
            ]);
        } catch (error) {
            Debug.error('recording-algorithm.saga', 'Error: ', {error});
        }

        const previousElement = yield select(AdjacentElementsSelectors.selectPreviousElement);
        const nextElement = yield select(AdjacentElementsSelectors.selectNextElement);
        const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);
        const audioItems = Object.values(yield select(AudioItemSelectors.selectEntities));
        const {length, cueIn, startNext} = previousElement || {};

        const metadata = {
            previousElementObject: previousElement,
            previousElementOffset: calculateBumperOffset(length, cueIn, startNext),
            previousElementFadeOutDuration: soundSettings.previousElement.fadeOutOnSkipDuration,
            previousElementDuckVolume: soundSettings.previousElement.duckVolume,
            previousElementDuckDuration: soundSettings.previousElement.duckDuration,
            nextElementObject: nextElement,
            nextElementFadeOutDuration: soundSettings.nextElement.fadeOutOnSkipDuration,
            nextElementDuckVolume: soundSettings.nextElement.duckVolume,
            nextElementDuckDuration: soundSettings.nextElement.duckDuration,
            preProducedAudioObjects: mixingPlan.elements
                .filter(element => element.type === 'preProduced')
                .map(element => audioItems.find(item => item.id === element.externalId)),
            soundEffectAudioObjects: mixingPlan.elements
                .filter(element => element.type === 'sfx')
                .map(element => audioItems.find(item => item.id === element.externalId)),
            musicBedAudioObjects: mixingPlan.elements
                .filter(element => element.type === 'bed')
                .map(element => audioItems.find(item => item.id === element.externalId)),
        };

        yield call([playbackAlgorithm, playbackAlgorithm.setMetadata], metadata);
    } catch (error) {
        yield call(handleSagaError, error, 'loadElementsFromMixingPlan');
    }
};

const resetAlgorithmWorker = function* () {
    try {
        yield call([recordingAlgorithm, recordingAlgorithm.reset]);
        yield call([playbackAlgorithm, playbackAlgorithm.stop]);
        yield call([playbackAlgorithm, playbackAlgorithm.reset]);
        yield put(MixingPlanActions.store(null));
    } catch (error) {
        yield call(handleSagaError, error, 'resetAlgorithmWorker');
    }
};

const playAlgorithmWorker = function* () {
    try {
        yield call([playbackAlgorithm, playbackAlgorithm.play]);
    } catch (error) {
        yield call(handleSagaError, error, 'playAlgorithmWorker');
    }
};

const pauseAlgorithmWorker = function* () {
    try {
        yield call([playbackAlgorithm, playbackAlgorithm.play]);
    } catch (error) {
        yield call(handleSagaError, error, 'pauseAlgorithmWorker');
    }
};

const onSetPreProducedVolume = function* ({payload}) {
    const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);

    const volume = soundSettings.preProduced[VolumeIndexMap[payload]];

    const isInRecordMode = yield call([recordingAlgorithm, recordingAlgorithm.isInProgress]);

    if (isInRecordMode) {
        yield call(
            [recordingAlgorithm, recordingAlgorithm.setElementsVolume],
            PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX,
            volume,
        );
    }

    recordingAlgorithm.preProducedAudiosVolume = volume;
};

const onSetSfxVolume = function* ({payload}) {
    const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);

    const volume = soundSettings.sfx[VolumeIndexMap[payload]];

    const isInRecordMode = yield call([recordingAlgorithm, recordingAlgorithm.isInProgress]);

    if (isInRecordMode) {
        yield call(
            [recordingAlgorithm, recordingAlgorithm.setElementsVolume],
            SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX,
            volume,
        );
    }

    recordingAlgorithm.soundEffectAudiosVolume = volume;
};

const onSetBedVolume = function* ({payload}) {
    const soundSettings = yield select(SoundSettingsSelectors.selectSoundSettings);

    const volume = soundSettings.bed[VolumeIndexMap[payload]];

    const isInRecordMode = yield call([recordingAlgorithm, recordingAlgorithm.isInProgress]);

    if (isInRecordMode) {
        yield call(
            [recordingAlgorithm, recordingAlgorithm.setElementsVolume],
            MUSIC_BED_AUDIO_FILE_NAME_PREFIX,
            volume,
        );
    }

    recordingAlgorithm.musicBedAudiosVolume = volume;
};

export const recordingAlgorithmSaga = function* () {
    yield all([
        takeEvery(RecordingAlgorithmActionTypes.EXECUTE_NEXT_COMMAND, executeNextCommandWorker),
        takeLatest(RecordingAlgorithmActionTypes.END, endAlgorithmWorker),
        takeLatest(RecordingAlgorithmActionTypes.RESET, resetAlgorithmWorker),
        takeLatest(RecordingAlgorithmActionTypes.PLAY, playAlgorithmWorker),
        takeLatest(RecordingAlgorithmActionTypes.PAUSE, pauseAlgorithmWorker),
        takeLatest(CurrentTaskActionTypes.SET_PRE_PRODUCED_VOLUME, onSetPreProducedVolume),
        takeLatest(CurrentTaskActionTypes.SET_SFX_VOLUME, onSetSfxVolume),
        takeLatest(CurrentTaskActionTypes.SET_BED_VOLUME, onSetBedVolume),
    ]);
};
