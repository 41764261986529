import {forwardRef} from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const ProcessingIcon = forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M9.886.5V2.231c3.763.463 6.428 3.883 5.965 7.646-.394 3.12-2.845 5.597-5.965 5.966v1.714c4.714-.471 8.143-4.654 7.671-9.368C17.171 4.117 13.94.903 9.886.5zM8.17.526C6.5.689 4.906 1.33 3.603 2.41L4.829 3.68c.96-.771 2.117-1.269 3.342-1.44V.526zM2.394 3.62C1.314 4.923.671 6.509.5 8.189h1.714c.163-1.218.643-2.375 1.406-3.343L2.394 3.62zM.51 9.903c.171 1.68.831 3.266 1.894 4.568l1.217-1.225c-.754-.969-1.243-2.126-1.397-3.343H.509zm4.32 4.603L3.603 15.68c1.294 1.08 2.888 1.757 4.568 1.937v-1.714c-1.217-.154-2.374-.643-3.342-1.397zm8.331-2.726L9.637 8.257c.352-.891.154-1.937-.583-2.666-.771-.78-1.928-.934-2.863-.505l1.663 1.663-1.157 1.165-1.706-1.671c-.462.934-.248 2.091.506 2.871.737.737 1.783.926 2.674.583l3.523 3.514c.155.163.386.163.54 0l.892-.882c.188-.155.188-.429.034-.549z" />
        </SvgIcon>
    );
});
