import clsx from 'clsx';
import {useSelector} from 'react-redux';
import classes from './HorizontalLineLoader.module.scss';
import {TaskSelectors} from '../../features/task';

export const HorizontalLineLoader = () => {
    const progress = useSelector(TaskSelectors.selectUploadProgress);

    return (
        <div className={clsx(classes.root, {[classes.disableTransition]: progress === 0})}>
            <div className={classes.line} style={{width: `${progress ?? 0}%`}} />
        </div>
    );
};
