import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect, useRef} from 'react';
import {drafts} from '../../../screens/cockpit/utils/speaker-text-drafts';

const isFocusable = node => {
    return node && !node.disabled && (node.tabIndex >= 0 || node.isContentEditable) && isVisible(node);
};

const isVisible = element => {
    return !!element && !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

export const RootListener = ({taskVariationId, onInit, isLoading}) => {
    const [editor] = useLexicalComposerContext();

    const isDraftInitialized = useRef(false);

    useEffect(() => {
        const removeRootListener = editor.registerRootListener(rootElement => {
            if (rootElement && !isLoading && !isDraftInitialized.current) {
                const draft = drafts.getDraft(taskVariationId);

                if (draft && draft.state) {
                    onInit();

                    editor.setEditable(true);

                    const callback = () => {
                        if (isFocusable(rootElement)) {
                            editor.focus();
                        } else {
                            window.requestAnimationFrame(callback);
                        }
                    };

                    window.requestAnimationFrame(callback);

                    isDraftInitialized.current = true;
                }
            }
        }, 0);

        return () => {
            removeRootListener();
        };
    }, [editor, isLoading, onInit, taskVariationId]);

    return null;
};
