import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import classes from './SendCodeForm.module.scss';
import {Input, InputGroup, LoadingButton} from '../../../../components';
import {ResetPasswordFormActions} from '../../store/auth.action';
import {ResetPasswordFormSelectors} from '../../store/auth.selector';

export const SendCodeForm = () => {
    const {t} = useTranslation('featuresAuth');

    const schema = Yup.object().shape({
        email: Yup.string().required().email().label(t('labels.email')),
    });

    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            email: '',
        },
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = useCallback(data => {
        dispatch(ResetPasswordFormActions.submitSendCodeForm(data.email));
    }, [dispatch]);

    const isLoading = useSelector(ResetPasswordFormSelectors.selectIsLoading);

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                    <Controller
                        name="email"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.email')}
                                hasError={!!errors.email}
                                helperText={errors?.email?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <div className={classes.footer}>
                    <LoadingButton isSubmit isLoading={isLoading}>
                        {t('labels.sendCodeButton')}
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};
