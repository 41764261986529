import {CurrentTaskActionTypes} from './current-task.action-type';

export const CurrentTaskActions = {
    setCurrentTaskId: id => ({
        type: CurrentTaskActionTypes.SET_CURRENT_TASK_ID,
        payload: id,
    }),
    setCurrentTaskVariationId: (id, source) => ({
        type: CurrentTaskActionTypes.SET_CURRENT_TASK_VARIATION_ID,
        payload: id,
        source,
    }),
    setCurrentPlaylistTaskKey: (key, source) => ({
        type: CurrentTaskActionTypes.SET_CURRENT_PLAYLIST_TASK_KEY,
        payload: key,
        source,
    }),
    setLoadedElements: (elements, source) => ({
        type: CurrentTaskActionTypes.SET_LOADED_ELEMENTS,
        payload: elements,
        source,
    }),
    setPreProducedVolume: (volume, source) => ({
        type: CurrentTaskActionTypes.SET_PRE_PRODUCED_VOLUME,
        payload: volume,
        source,
    }),
    setSfxVolume: (volume, source) => ({
        type: CurrentTaskActionTypes.SET_SFX_VOLUME,
        payload: volume,
        source,
    }),
    setBedVolume: (volume, source) => ({
        type: CurrentTaskActionTypes.SET_BED_VOLUME,
        payload: volume,
        source,
    }),
    setSpeakerText: (speakerText, source) => ({
        type: CurrentTaskActionTypes.SET_SPEAKER_TEXT,
        payload: speakerText,
        source,
    }),
    // setArePreProducedElementsMuted: areMuted => ({
    //     type: CurrentTaskActionTypes.SET_ARE_PRE_PRODUCED_ELEMENTS_MUTED,
    //     payload: areMuted,
    // }),
    // setAreSfxElementsMuted: areMuted => ({
    //     type: CurrentTaskActionTypes.SET_ARE_SFX_ELEMENTS_MUTED,
    //     payload: areMuted,
    // }),
    // setAreBedElementsMuted: areMuted => ({
    //     type: CurrentTaskActionTypes.SET_ARE_BED_ELEMENTS_MUTED,
    //     payload: areMuted,
    // }),
    loadElement: (elementId, elementType) => ({
        type: CurrentTaskActionTypes.LOAD_ELEMENT,
        payload: {
            elementId,
            elementType,
        },
    }),
    clearLoadedElements: elementType => ({
        type: CurrentTaskActionTypes.CLEAR_LOADED_ELEMENTS,
        payload: {
            elementType,
        },
    }),
    removeLoadedElement: (elementId, elementType) => ({
        type: CurrentTaskActionTypes.REMOVE_LOADED_ELEMENT,
        payload: {
            elementId,
            elementType,
        },
    }),
    setOrderOfLoadedElements: (elements, elementType) => ({
        type: CurrentTaskActionTypes.SET_ORDER_OF_LOADED_ELEMENTS,
        payload: {
            elements,
            elementType,
        },
    }),
    setIsFreeModeEnabled: (isEnabled, source) => ({
        type: CurrentTaskActionTypes.SET_IS_FREE_MODE_ENABLED,
        payload: isEnabled,
        source,
    }),
    setFreeModeOriginalTaskVariationId: taskVariationId => ({
        type: CurrentTaskActionTypes.SET_FREE_MODE_ORIGINAL_TASK_VARIATION_ID,
        payload: taskVariationId,
    }),
};
