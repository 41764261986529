import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$insertNodes} from 'lexical';
import {useEffect} from 'react';
import {INSERT_PRE_PRODUCED_MARKER_COMMAND} from '../commands/insert-pre-produced-marker-command';
import {$createMarkerNode} from '../nodes/MarkerNode';

export const PreProducedMarkerCommand = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const removeInsertPreProducedMarkerCommand = editor.registerCommand(INSERT_PRE_PRODUCED_MARKER_COMMAND, () => {
            editor.update(() => {
                $insertNodes([$createMarkerNode()]);
            });

            return true;
        }, 0);

        return () => {
            removeInsertPreProducedMarkerCommand();
        };
    }, [editor]);

    return null;
};
