import {createSelector} from 'reselect';
import {CurrentTaskSelectors} from '../../current-task';

const selectIds = state => state.cockpitNavigation.ids;

const createNextTaskSelector = () => createSelector(
    [selectIds, CurrentTaskSelectors.selectCurrentTaskId, CurrentTaskSelectors.selectCurrentTaskVariationId],
    (ids, currentTaskId, currentTaskVariationId) => {
        const currentId = `${currentTaskId}/${currentTaskVariationId}`;
        const idKeys = Object.keys(ids);
        const idValues = Object.values(ids);
        const currentIndex = idKeys.findIndex(id => id === currentId);

        if (currentIndex === -1) {
            if (idKeys.length) {
                return idValues[0];
            }

            return null;
        }

        return idValues[currentIndex + 1];
    },
);

const createPreviousTaskSelector = () => createSelector(
    [selectIds, CurrentTaskSelectors.selectCurrentTaskId, CurrentTaskSelectors.selectCurrentTaskVariationId],
    (ids, currentTaskId, currentTaskVariationId) => {
        const currentId = `${currentTaskId}/${currentTaskVariationId}`;
        const idKeys = Object.keys(ids);
        const idValues = Object.values(ids);
        const currentIndex = idKeys.findIndex(id => id === currentId);

        if (currentIndex === -1) {
            return null;
        }

        return idValues[currentIndex - 1];
    },
);

export const CockpitNavigationSelectors = {
    selectIds,
    createNextTaskSelector,
    createPreviousTaskSelector,
};
