import {AUDIO_ELEMENT_TYPES, FADE_OUT_VARS} from './audioContextConstants';
import PlaybackNode from './audioNodes/PlaybackNode';
import PlaybackMetadataTransformer from './playbackMetadataTransformer';
import recordingAlgorithm from './RecordingAlgorithm';
import UpdateSubscriptions from './UpdateSubscriptions';
import {SUBSCRIPTION_TYPES} from '../../constants';
import {Debug} from '../../lib/debug';
import {IndexedDb} from '../../lib/indexed-db';
import {toastInstance} from '../../lib/toast';
import {EditorActions} from '../editor/store/editor.action';
import {LOADING_TYPES, LoadingActions} from '../loading';

class PlaybackAlgorithm extends UpdateSubscriptions {
    /**
     * @var {AudioContext|null}
     * @private
     */
    #audioContext = null;

    /**
     * @type {PlaybackNode[]}
     * @private
     */
    #nodes = [];

    /**
     * @type {PlaybackMetadataTransformer}
     * @private
     */
    #transformer;

    /**
     * @type {number}
     * @private
     */
    #playbackDuration = 0;

    /**
     * @type {number}
     * @private
     */
    #onPlaybackEndTimeout = 0;

    /**
     * @type {number}
     * @private
     */
    #updateInterfaceInterval = 0;

    /**
     * @type {boolean}
     * @private
     */
    #isInitialized = false;

    #reduxDispatch;

    /**
     * @type {object}
     * @private
     */
    #mixingPlan = null;

    /**
     * @type {number}
     * @private
     */
    #mainOffset = 0;

    /**
     * @type {HTMLAudioElement}
     * @private
     */
    #audio;

    /**
     * @type {MediaStreamAudioDestinationNode}
     * @private
     */
    #destination;

    /**
     * @type {number}
     * @private
     */
    #requestAnimationFrameCallback;

    metadata;

    startTime = 0;

    #createNextElementNode = async (lastRecordingStartAt, lastRecordingStopAt) => {
        const {
            nextElementObject,
            nextElementDuckDuration,
            nextElementDuckVolume,
            nextElementFadeOutDuration,
        } = this.metadata;

        // There's no next element!
        if (!nextElementObject || !nextElementObject.id) {
            return;
        }

        const nextElementMetadata = this.#transformer.transformNextElementMetadata({
            introLength: nextElementObject.introLength,
            cueIn: nextElementObject.cueIn,
            lastRecordingStartAt,
            lastRecordingStopAt,
            fadeOutDuration: nextElementFadeOutDuration,
            duckVolume: nextElementDuckVolume,
            duckDuration: nextElementDuckDuration,
        });

        // Check if we have the buffer in recording algorithm
        let audioBuffer = recordingAlgorithm.getNextElementNode()
            && recordingAlgorithm.getNextElementNode().sourceNode.buffer;

        if (!audioBuffer) {
            const nextElementArrayBuffer = await IndexedDb.getAudioBlob(nextElementObject.id)
                .then(file => file.arrayBuffer());

            audioBuffer = await this.#audioContext.decodeAudioData(nextElementArrayBuffer);
        }

        this.#nodes.push(new PlaybackNode(
            this.#audioContext,
            audioBuffer,
            nextElementMetadata,
            nextElementObject.cueIn / 1000,
            AUDIO_ELEMENT_TYPES.STINGER,
            nextElementObject.id,
            '',
            1,
            this.#destination,
        ));

        return nextElementMetadata;
    };

    #createPreviousElementNode = async (nextElementStartAt, lastRecordingStartAt, lastRecordingStopAt) => {
        const {
            previousElementObject,
            previousElementFadeOutDuration,
            previousElementOffset,
            previousElementDuckDuration,
            previousElementDuckVolume,
        } = this.metadata;

        // There's no previous element!
        if (!previousElementObject || !previousElementObject.id) {
            return;
        }

        // Check if we have the buffer in recording algorithm
        let audioBuffer = recordingAlgorithm.getPreviousElementNode()
            && recordingAlgorithm.getPreviousElementNode().sourceNode.buffer;

        if (!audioBuffer) {
            const previousElementArrayBuffer = await IndexedDb.getAudioBlob(previousElementObject.id)
                .then(file => file.arrayBuffer());

            audioBuffer = await this.#audioContext.decodeAudioData(previousElementArrayBuffer);
        }

        const musicBedAudioNodes = this.getMusicBedAudioNodes();
        const startAndStopPositionsOfMusicBeds = musicBedAudioNodes.map(musicBedAudioNode => ({
            startAt: musicBedAudioNode.startAt,
            stopAt: musicBedAudioNode.stopAt,
        }));

        let duration = audioBuffer.duration;

        if (previousElementObject.contentType === 'Music') {
            duration = (previousElementObject?.cueOut || 0) / 1000;
        }

        const previousElementMetadata = this.#transformer.transformPreviousElementMetadata({
            duration,
            offset: previousElementOffset,
            nextElementStartAt,
            fadeOutDuration: previousElementFadeOutDuration,
            lastRecordingStartAt,
            lastRecordingStopAt,
            duckDuration: previousElementDuckDuration,
            duckVolume: previousElementDuckVolume,
            startAndStopPositionsOfMusicBeds,
            previousElementObject,
        });

        this.#nodes.push(new PlaybackNode(
            this.#audioContext,
            audioBuffer,
            previousElementMetadata,
            previousElementOffset / 1000,
            AUDIO_ELEMENT_TYPES.BUMPER,
            previousElementObject.id,
            '',
            1,
            this.#destination,
            previousElementObject,
        ));
    };

    #createRecordingAudioNodes = async () => {
        const indexes = [];
        const recordingElements = this.#mixingPlan.elements
            .filter((element, index) => {
                if (element.type === AUDIO_ELEMENT_TYPES.RECORDING) {
                    indexes.push(index);
                }

                return element.type === AUDIO_ELEMENT_TYPES.RECORDING;
            });

        const fileNames = this.#mixingPlan.elements
            .filter(element => element.type === AUDIO_ELEMENT_TYPES.RECORDING)
            .map(element => {
                if (element.hasOwnProperty('fileName')) {
                    return element.fileName;
                } else if (element.hasOwnProperty('externalId')) {
                    return element.externalId;
                }

                return null;
            });

        for (let i = 0; i < recordingElements.length; i += 1) {
            if (!fileNames[i]) {
                toastInstance.error('Oops, something went wrong while we were loading the recording (file missing in local file system). Please, reload this window and try again.');

                continue;
            }

            try {
                const arrayBuffer = await IndexedDb.getAudioBlob(fileNames[i])
                    .then(file => file.arrayBuffer());

                await this.#audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
                    const metadata = {
                        ...recordingElements[i],
                        startAt: recordingElements[i].startAt + this.#mainOffset,
                        stopAt: recordingElements[i].startAt + audioBuffer.duration + this.#mainOffset,
                    };

                    const node = new PlaybackNode(
                        this.#audioContext,
                        audioBuffer,
                        metadata,
                        0,
                        AUDIO_ELEMENT_TYPES.RECORDING,
                        fileNames[i],
                        '',
                        1,
                        this.#destination,
                    );

                    node.index = indexes[i];

                    this.#nodes.push(node);
                });
            } catch (error) {
                toastInstance.error('One of recorded audio files is missing, are they deleted?');
            }
        }
    };

    #createPreProducedAudioNodes = async () => {
        const indexes = [];
        const preProducedAudioElements = this.#mixingPlan.elements
            .filter((element, index) => {
                if (element.type === AUDIO_ELEMENT_TYPES.PRE_PROD) {
                    indexes.push(index);
                }

                return element.type === AUDIO_ELEMENT_TYPES.PRE_PROD;
            });

        for (let i = 0; i < preProducedAudioElements.length; i += 1) {
            const preProducedAudioElement = preProducedAudioElements[i];

            try {
                const arrayBuffer = await IndexedDb.getAudioBlob(preProducedAudioElement.externalId)
                    .then(file => file.arrayBuffer());

                await this.#audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
                    const audioObject = this.metadata.preProducedAudioObjects.find(audioObject => {
                        return audioObject.id === preProducedAudioElement.externalId;
                    });

                    const cueIn = audioObject ? audioObject.cueIn / 1000 : 0;

                    const metadata = {
                        ...preProducedAudioElement,
                        startAt: preProducedAudioElement.startAt + this.#mainOffset,
                        stopAt: preProducedAudioElement.startAt + audioBuffer.duration - cueIn + this.#mainOffset,
                        volumeChangePoints: preProducedAudioElement.volumePoints,
                    };

                    const node = new PlaybackNode(
                        this.#audioContext,
                        audioBuffer,
                        metadata,
                        audioObject ? audioObject.cueIn / 1000 : 0,
                        AUDIO_ELEMENT_TYPES.PRE_PROD,
                        preProducedAudioElement.externalId,
                        audioObject ? audioObject.title : '',
                        1,
                        this.#destination,
                    );

                    node.index = indexes[i];

                    this.#nodes.push(node);
                });
            } catch (error) {
                // no-op
            }
        }
    };

    #createSoundEffectAudioNodes = async () => {
        const indexes = [];
        const soundEffectAudioElements = this.#mixingPlan.elements
            .filter((element, index) => {
                if (element.type === AUDIO_ELEMENT_TYPES.SOUND_EFFECT) {
                    indexes.push(index);
                }

                return element.type === AUDIO_ELEMENT_TYPES.SOUND_EFFECT;
            });

        for (let i = 0; i < soundEffectAudioElements.length; i += 1) {
            const soundEffectAudioElement = soundEffectAudioElements[i];

            try {
                const arrayBuffer = await IndexedDb.getAudioBlob(soundEffectAudioElement.externalId)
                    .then(file => file.arrayBuffer());

                await this.#audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
                    const audioObject = this.metadata.soundEffectAudioObjects.find(audioObject => {
                        return audioObject.id === soundEffectAudioElement.externalId;
                    });

                    let title = '';

                    if (audioObject) {
                        title = audioObject.title;
                    }

                    this.#createMusicBedOrSoundEffectNode(
                        soundEffectAudioElement,
                        audioBuffer,
                        AUDIO_ELEMENT_TYPES.SOUND_EFFECT,
                        indexes[i],
                        title,
                        audioObject,
                    );
                });
            } catch (error) {
                // no-op
            }
        }
    };

    #createMusicBedAudioNodes = async () => {
        const indexes = [];
        const musicBedAudioElements = this.#mixingPlan.elements
            .filter((element, index) => {
                if (element.type === AUDIO_ELEMENT_TYPES.MUSIC_BED) {
                    indexes.push(index);
                }

                return element.type === AUDIO_ELEMENT_TYPES.MUSIC_BED;
            });

        for (let i = 0; i < musicBedAudioElements.length; i += 1) {
            const musicBedAudioElement = musicBedAudioElements[i];

            try {
                const arrayBuffer = await IndexedDb.getAudioBlob(musicBedAudioElement.externalId)
                    .then(file => file.arrayBuffer());

                await this.#audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
                    const audioObject = this.metadata.musicBedAudioObjects.find(audioObject => {
                        return audioObject.id === musicBedAudioElement.externalId;
                    });

                    let title = '';

                    if (audioObject) {
                        title = audioObject.title;
                    }

                    this.#createMusicBedOrSoundEffectNode(
                        musicBedAudioElement,
                        audioBuffer,
                        AUDIO_ELEMENT_TYPES.MUSIC_BED,
                        indexes[i],
                        title,
                        audioObject,
                    );
                });
            } catch (error) {
                // no-op
            }
        }
    };

    /**
     * @param {Object} element
     * @param {AudioBuffer} audioBuffer
     * @param {AUDIO_ELEMENT_TYPES} type
     * @param {Number} index
     * @param {String} [title=''] title
     * @param {Object} audioObject
     */
    #createMusicBedOrSoundEffectNode = (element, audioBuffer, type, index, title = '', audioObject) => {
        const cueIn = audioObject ? audioObject.cueIn / 1000 : 0;
        let stopAt = element.startAt + audioBuffer.duration - cueIn + this.#mainOffset;

        const fadeOutVolumeChangePoint = element.volumePoints
            .find(volumeChangePoint => volumeChangePoint.startAt !== 0
                && volumeChangePoint.stopAt !== 0
                && volumeChangePoint.toVolume === 0);

        if (fadeOutVolumeChangePoint) {
            stopAt = fadeOutVolumeChangePoint.stopAt - cueIn + this.#mainOffset;
        }

        const metadata = {
            ...element,
            startAt: element.startAt + this.#mainOffset,
            stopAt,
            volumeChangePoints: this.#transformVolumeChangePoints(element.volumePoints),
        };

        const node = new PlaybackNode(
            this.#audioContext,
            audioBuffer,
            metadata,
            cueIn,
            type,
            element.externalId,
            title,
            1,
            this.#destination,
            audioObject,
        );

        node.index = index;

        this.#nodes.push(node);
    };

    #calculatePlaybackDuration = () => {
        let playbackDuration = 0;

        if (this.getBumperNode() && this.getBumperNode().stopAt > playbackDuration) {
            playbackDuration = this.getBumperNode().stopAt;
        }

        if (this.getStingerNode() && this.getStingerNode().stopAt > playbackDuration) {
            playbackDuration = this.getStingerNode().stopAt;
        }

        this.getRecordingNodes().forEach(recordingNode => {
            if (recordingNode.stopAt > playbackDuration) {
                playbackDuration = recordingNode.stopAt;
            }
        });

        this.getPreProducedAudioNodes().forEach(preProducedAudioNode => {
            if (preProducedAudioNode.stopAt > playbackDuration) {
                playbackDuration = preProducedAudioNode.stopAt;
            }
        });

        this.getSoundEffectAudioNodes().forEach(soundEffectAudioNode => {
            if (soundEffectAudioNode.stopAt > playbackDuration) {
                playbackDuration = soundEffectAudioNode.stopAt;
            }
        });

        this.getMusicBedAudioNodes().forEach(musicBedAudioNode => {
            if (musicBedAudioNode.stopAt > playbackDuration) {
                playbackDuration = musicBedAudioNode.stopAt;
            }
        });

        Debug.debug('PlaybackAlgorithm', `Calculated playback duration of ${playbackDuration} milliseconds.`);

        return playbackDuration;
    };

    #calculateMainOffset = (startNext, cueIn, previousElementId) => {
        if (!previousElementId) {
            return 0;
        }

        const baseOffset = 3000;
        let mainOffset = startNext - (cueIn + startNext - baseOffset);

        if (mainOffset < 0) {
            mainOffset = baseOffset;
        }

        mainOffset /= 1000;

        return mainOffset;
    };

    #updateNodeStartTimes = () => {
        this.#nodes.forEach(node => {
            const nodeStartAt = node.startAt;

            this.#mixingPlan.elements.forEach((element, index) => {
                if (node.index !== index) {
                    return;
                }

                const elementStartAt = element.startAt + this.#mainOffset;
                const difference = elementStartAt - nodeStartAt;

                node.startAt = Math.round((node.startAt + difference + Number.EPSILON) * 1000) / 1000;
                node.stopAt = Math.round((node.stopAt + difference + Number.EPSILON) * 1000) / 1000;
                node.volumeChangePoints = element.volumePoints;

                node.reconnect();
            });
        });

        this.#updateNextElementNode();

        this.#updatePreviousElementNode();

        this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
    };

    #updateNodeTitles = () => {
        this.#nodes.forEach(node => {
            const nodeTitle = node.title;

            let element = null;

            switch (node.type) {
                case AUDIO_ELEMENT_TYPES.BUMPER:
                    element = this.metadata.previousElementObject;

                    break;

                case AUDIO_ELEMENT_TYPES.STINGER:
                    element = this.metadata.nextElementObject;

                    break;

                case AUDIO_ELEMENT_TYPES.PRE_PROD:
                    element = this.metadata.preProducedAudioObjects
                        .find(object => object.externalId === node.id);

                    break;

                case AUDIO_ELEMENT_TYPES.MUSIC_BED:
                    element = this.metadata.musicBedAudioObjects
                        .find(object => object.externalId === node.id);

                    break;

                case AUDIO_ELEMENT_TYPES.SOUND_EFFECT:
                    element = this.metadata.soundEffectAudioObjects
                        .find(object => object.externalId === node.id);

                    break;

                default:
                    break;
            }

            if (element && element.hasOwnProperty('title') && nodeTitle !== element.title) {
                node.title = element.title;

                this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
            }
        });
    };

    #updateNextElementNode = () => {
        const {
            nextElementObject,
            nextElementDuckDuration,
            nextElementDuckVolume,
            nextElementFadeOutDuration,
        } = this.metadata;

        // There's no next element!
        if (!nextElementObject || !nextElementObject.id) {
            return;
        }

        const recordingNodes = this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.RECORDING);
        const lastRecordingNode = recordingNodes.sort((a, b) => a.stopAt - b.stopAt)[recordingNodes.length - 1];

        const lastRecordingStartAt = lastRecordingNode ? lastRecordingNode.startAt : 0;
        const lastRecordingStopAt = lastRecordingNode ? lastRecordingNode.stopAt : 0;

        const nextElementMetadata = this.#transformer.transformNextElementMetadata({
            introLength: nextElementObject.introLength,
            cueIn: nextElementObject.cueIn,
            lastRecordingStartAt,
            lastRecordingStopAt,
            fadeOutDuration: nextElementFadeOutDuration,
            duckVolume: nextElementDuckVolume,
            duckDuration: nextElementDuckDuration,
        });

        const nextElementNode = this.#nodes.find(node => node.type === AUDIO_ELEMENT_TYPES.STINGER);

        nextElementNode.startAt = nextElementMetadata.startAt;
        nextElementNode.stopAt = nextElementMetadata.stopAt;
        nextElementNode.volumeChangePoints = nextElementMetadata.volumeChangePoints;

        nextElementNode.reconnect();

        this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
    };

    #updatePreviousElementNode = () => {
        const {
            previousElementObject,
            previousElementFadeOutDuration,
            previousElementOffset,
            previousElementDuckDuration,
            previousElementDuckVolume,
        } = this.metadata;

        // There's no previous element!
        if (!previousElementObject || !previousElementObject.id) {
            return;
        }

        const recordingNodes = this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.RECORDING);
        const lastRecordingNode = recordingNodes.sort((a, b) => a.stopAt - b.stopAt)[recordingNodes.length - 1];

        const lastRecordingStartAt = lastRecordingNode ? lastRecordingNode.startAt : 0;
        const lastRecordingStopAt = lastRecordingNode ? lastRecordingNode.stopAt : 0;

        const musicBedAudioNodes = this.getMusicBedAudioNodes();
        const startAndStopPositionsOfMusicBeds = musicBedAudioNodes.map(musicBedAudioNode => ({
            startAt: musicBedAudioNode.startAt,
            stopAt: musicBedAudioNode.stopAt,
        }));

        const previousElementNode = this.#nodes.find(node => node.type === AUDIO_ELEMENT_TYPES.BUMPER);
        const nextElementNode = this.#nodes.find(node => node.type === AUDIO_ELEMENT_TYPES.STINGER);

        const previousElementMetadata = this.#transformer.transformPreviousElementMetadata({
            duration: previousElementNode.sourceNode.buffer.duration,
            offset: previousElementOffset,
            nextElementStartAt: nextElementNode.startAt,
            fadeOutDuration: previousElementFadeOutDuration,
            lastRecordingStartAt,
            lastRecordingStopAt,
            duckDuration: previousElementDuckDuration,
            duckVolume: previousElementDuckVolume,
            startAndStopPositionsOfMusicBeds,
        });

        previousElementNode.startAt = previousElementMetadata.startAt;
        previousElementNode.stopAt = previousElementMetadata.stopAt;
        previousElementNode.volumeChangePoints = previousElementMetadata.volumeChangePoints;

        nextElementNode.reconnect();

        this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
    };

    #transformVolumeChangePoints = volumeChangePoints => {
        return volumeChangePoints.map(volumePoint => {
            const {startAt, stopAt, fromVolume, toVolume} = volumePoint;

            return {
                startAt: startAt + this.#mainOffset,
                stopAt: stopAt + this.#mainOffset,
                fromVolume: fromVolume === 0 ? FADE_OUT_VARS.FADE_OUT_END_VOLUME : fromVolume,
                toVolume: toVolume === 0 ? FADE_OUT_VARS.FADE_OUT_END_VOLUME : toVolume,
            };
        });
    };

    setReduxDispatch = reduxDispatch => {
        this.#reduxDispatch = reduxDispatch;
    };

    get isInitialized() {
        return this.#isInitialized;
    }

    setMetadata = metadata => {
        Debug.debug('PlaybackAlgorithm', 'Metadata: ', metadata);

        this.metadata = metadata;

        this.#updateNodeTitles();
    };

    setupNodes = async () => {
        this.#transformer = new PlaybackMetadataTransformer();

        let {previousElementObject} = this.metadata;

        if (!previousElementObject) {
            previousElementObject = {};
        }

        const {startNext = 0, cueIn = 0, id} = previousElementObject;

        this.#mainOffset = this.#calculateMainOffset(startNext, cueIn, id);

        try {
            await this.#createRecordingAudioNodes();
        } catch (error) {
            toastInstance.error('Something went wrong while creating recorded audio nodes.');
        }

        await Promise.all([
            this.#createPreProducedAudioNodes(),
            this.#createSoundEffectAudioNodes(),
            this.#createMusicBedAudioNodes(),
        ]);

        const recordingNodes = this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.RECORDING);
        const bedNodes = this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.MUSIC_BED);
        const lastRecordingNode = recordingNodes.sort((a, b) => a.stopAt - b.stopAt)[recordingNodes.length - 1];
        const lastBedNode = bedNodes.sort((a, b) => a.stopAt - b.stopAt)[bedNodes.length - 1];

        const lastRecordingStartAt = lastRecordingNode ? lastRecordingNode.startAt : 0;
        const lastRecordingStopAt = lastRecordingNode ? lastRecordingNode.stopAt : 0;
        const lastBedStartAt = lastBedNode ? lastBedNode.startAt : 0;

        let lastElementStartAt = lastRecordingStartAt;
        let lastElementStopAt = lastRecordingStopAt;

        if (lastBedNode) {
            if (!['BED', 'BB', 'DRN'].includes(lastBedNode.audioObject.objectElementType)) {
                const {nextElementObject} = this.metadata;

                if (nextElementObject) {
                    lastElementStartAt = lastBedStartAt;
                    const startNext = lastBedNode.audioObject.startNext / 1000;
                    const introLength = nextElementObject.introLength / 1000;
                    const cueIn = nextElementObject.cueIn / 1000;
                    lastElementStopAt = lastBedStartAt + startNext + introLength - cueIn;
                }
            }
        }

        let nextElementMetadata = null;
        let nextElementStartAt = 0;

        try {
            nextElementMetadata = await this.#createNextElementNode(lastElementStartAt, lastElementStopAt);
            nextElementStartAt = nextElementMetadata ? nextElementMetadata.startAt : 0;
        } catch (error) {
            // no-op
        }

        try {
            await this.#createPreviousElementNode(nextElementStartAt, lastElementStartAt, lastElementStopAt);
        } catch (error) {
            // no-op
        }

        Debug.debug('PlaybackAlgorithm', 'Nodes created: ', this.#nodes);
    };

    /**
     * @returns {Promise<void>}
     */
    initialize = async () => {
        if (!this.#mixingPlan || !this.metadata) {
            toastInstance.error('Mixing plan or metadata is missing!');

            return;
        }

        this.#reduxDispatch(LoadingActions.setIsLoading(LOADING_TYPES.EDITOR, true));

        this.#nodes = [];

        if (this.#audioContext && this.#audioContext.state === 'running') {
            this.#audioContext.close().then(() => {
                if (this.#onPlaybackEndTimeout) {
                    clearTimeout(this.#onPlaybackEndTimeout);
                }

                this.notify([SUBSCRIPTION_TYPES.IS_PLAYING]);
            });
        }

        this.#audioContext = new window.AudioContext({
            latencyHint: 'playback',
        });

        await this.#audioContext.suspend();

        this.notify([SUBSCRIPTION_TYPES.IS_PLAYING]);

        if (!this.#audioContext.setSinkId) {
            this.#audio = new Audio();

            this.#destination = new MediaStreamAudioDestinationNode(this.#audioContext);

            this.#audio.srcObject = this.#destination.stream;

            await this.#audio.play();
        }

        const outputDeviceId = localStorage.getItem('outputDeviceId');

        if (outputDeviceId) {
            this.setOutputDevice(outputDeviceId);
        }

        await this.setupNodes();

        // Calculate playback duration
        this.#playbackDuration = this.#calculatePlaybackDuration();

        this.#isInitialized = true;

        this.#reduxDispatch(EditorActions.setIsUserGestureRequired(false));

        this.#reduxDispatch({
            type: 'PLAYBACK_ALGORITHM_INITIALIZED',
        });

        this.notify([
            SUBSCRIPTION_TYPES.PLAYBACK_DATA,
            SUBSCRIPTION_TYPES.CURRENT_POSITION,
            SUBSCRIPTION_TYPES.TASK_DURATION,
        ]);

        this.#reduxDispatch(LoadingActions.setIsLoading(LOADING_TYPES.EDITOR, false));
    };

    setOutputDevice = deviceId => {
        if (!this.#audio) {
            return;
        }

        this.#audio.setSinkId(deviceId)
            .then(() => {
                // eslint-disable-next-line no-console
                console.warn(`Success, audio output device attached: ${deviceId}`);
            })
            .catch(error => {
                let errorMessage = error;

                if (error.name === 'SecurityError') {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }

                // eslint-disable-next-line no-console
                console.error(errorMessage);
            });
    };

    #onAnimationFrameCallback = () => {
        // if ((timestamp - this.#requestAnimationFrameTimestamp) < this.#updateStep) {
        //     this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);
        //     return;
        // }

        const types = [
            SUBSCRIPTION_TYPES.CURRENT_POSITION,
        ];

        // if (this.#nodes.previousElementNode) {
        //     types.push(SUBSCRIPTION_TYPES.PREVIOUS_ELEMENT_DATA);
        // }
        //
        // if (this.#nodes.nextElementNode) {
        //     types.push(SUBSCRIPTION_TYPES.NEXT_ELEMENT_DATA);
        // }
        //
        // if (this.#isPlayingPreProducedAudio) {
        //     types.push(SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA);
        // }
        //
        // if (this.#isPlayingSoundEffect) {
        //     types.push(SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA);
        // }
        //
        // if (this.#isPlayingMusicBed) {
        //     types.push(SUBSCRIPTION_TYPES.MUSIC_BED_DATA);
        // }

        this.notify(types);

        this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);
    };

    /**
     * @returns {Promise<void>}
     */
    play = async () => {
        if (this.#audioContext && this.#audioContext.state === 'running') {
            this.#audioContext.suspend().then(() => {
                this.notify([SUBSCRIPTION_TYPES.IS_PLAYING]);
            });

            if (this.#onPlaybackEndTimeout) {
                clearTimeout(this.#onPlaybackEndTimeout);
            }

            cancelAnimationFrame(this.#requestAnimationFrameCallback);

            this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);

            return;
        }

        if (this.#audioContext && this.#audioContext.state === 'suspended') {
            // this.#updateInterfaceInterval = setInterval(() => {
            //     this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
            // }, UI_UPDATE_INTERVAL);

            this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);

            this.#onPlaybackEndTimeout = setTimeout(() => {
                this.#audioContext.close().then(() => {
                    this.#isInitialized = false;

                    this.notify([
                        SUBSCRIPTION_TYPES.PLAYBACK_DATA,
                        SUBSCRIPTION_TYPES.IS_PLAYING,
                        SUBSCRIPTION_TYPES.CURRENT_POSITION,
                    ]);
                });
            }, (this.#playbackDuration - this.#audioContext.currentTime) * 1000);

            this.#audioContext.resume().then(() => {
                this.notify([SUBSCRIPTION_TYPES.IS_PLAYING]);
            });

            return;
        }

        if (this.#updateInterfaceInterval) {
            clearInterval(this.#updateInterfaceInterval);
        }

        if (this.#onPlaybackEndTimeout) {
            clearTimeout(this.#onPlaybackEndTimeout);
        }

        if (!this.#isInitialized) {
            await this.initialize();

            await new Promise(resolve => setTimeout(resolve, 225));

            this.#audioContext.resume().then((() => {
                this.startTime = this.#audioContext.currentTime;
                this.notify([SUBSCRIPTION_TYPES.IS_PLAYING]);
            }));
        }

        // this.#updateInterfaceInterval = setInterval(() => {
        //     this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA]);
        // }, UI_UPDATE_INTERVAL);
        this.#requestAnimationFrameCallback = window.requestAnimationFrame(this.#onAnimationFrameCallback);

        this.#onPlaybackEndTimeout = setTimeout(() => {
            this.#audioContext.close().then(() => {
                this.#isInitialized = false;

                if (this.#updateInterfaceInterval) {
                    clearInterval(this.#updateInterfaceInterval);
                }

                this.notify([SUBSCRIPTION_TYPES.PLAYBACK_DATA, SUBSCRIPTION_TYPES.IS_PLAYING]);
            });
        }, this.#playbackDuration * 1000);
    };

    stop = () => {
        this.reset();
    };

    getBumperNode() {
        return this.#nodes.find(node => node.type === AUDIO_ELEMENT_TYPES.BUMPER);
    }

    getStingerNode() {
        return this.#nodes.find(node => node.type === AUDIO_ELEMENT_TYPES.STINGER);
    }

    getPreProducedAudioNodes() {
        return this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.PRE_PROD);
    }

    getSoundEffectAudioNodes() {
        return this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.SOUND_EFFECT);
    }

    getMusicBedAudioNodes() {
        return this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.MUSIC_BED);
    }

    getRecordingNodes() {
        return this.#nodes.filter(node => node.type === AUDIO_ELEMENT_TYPES.RECORDING);
    }

    getPlaybackDuration() {
        return this.#playbackDuration;
    }

    setMixingPlan(mixingPlan, shouldReinitialize = true) {
        if (mixingPlan) {
            this.#mixingPlan = mixingPlan.clone();

            this.#mixingPlan.recordLength /= 1000;

            this.#mixingPlan.elements = this.#mixingPlan.elements.map(element => ({
                ...element,
                startAt: element.startAt / 1000,
                stopAt: element.stopAt / 1000,
                volumePoints: element.volumePoints.map(volumePoint => {
                    return {
                        ...volumePoint,
                        startAt: volumePoint.startAt / 1000,
                        stopAt: volumePoint.stopAt / 1000,
                    };
                }),
            }));
        }

        if (shouldReinitialize) {
            this.#isInitialized = false;
        } else {
            this.#updateNodeStartTimes();
        }

        this.#playbackDuration = this.#calculatePlaybackDuration();

        this.notify([
            SUBSCRIPTION_TYPES.PLAYBACK_DATA,
            SUBSCRIPTION_TYPES.CAN_BE_PLAYED,
            SUBSCRIPTION_TYPES.SELECTED_NODE_DATA,
        ]);
    }

    reset() {
        this.#nodes = [];

        this.startTime = 0;

        this.#isInitialized = false;

        if (this.#updateInterfaceInterval) {
            clearInterval(this.#updateInterfaceInterval);
        }

        if (this.#onPlaybackEndTimeout) {
            clearTimeout(this.#onPlaybackEndTimeout);
        }

        this.metadata = null;
        this.#mixingPlan = null;

        this.#playbackDuration = 0;

        if (this.#audioContext && (this.#audioContext.state === 'running' || this.#audioContext.state === 'suspended')) {
            this.#audioContext.close().then(() => {
                this.notify([
                    SUBSCRIPTION_TYPES.PLAYBACK_DATA,
                    SUBSCRIPTION_TYPES.IS_PLAYING,
                    SUBSCRIPTION_TYPES.CAN_BE_PLAYED,
                    SUBSCRIPTION_TYPES.CURRENT_POSITION,
                ]);
            });
        }
    }

    isPlaying() {
        return this.#audioContext && this.#audioContext.state === 'running';
    }

    seek(offset) {
        if (!this.#audioContext || this.#audioContext.state === 'closed') {
            return;
        }

        if (offset < 0 || offset > this.#playbackDuration) {
            return;
        }

        this.#nodes.forEach(node => node.reset());

        this.#nodes.forEach(node => {
            const nodeOffset = offset - node.startAt;

            if (nodeOffset >= 0 && nodeOffset <= node.duration) {
                // The node should be playing at the new current time.
                // We need to adjust the start time and offset within the node.
                node.reconnect(nodeOffset, true);
            } else if (nodeOffset < 0) {
                // The node should start playing in the future.
                // We need to adjust the start time.
                node.reconnect(0, false, this.#audioContext.currentTime - nodeOffset);
            }
        });

        this.startTime = this.#audioContext.currentTime - offset;

        this.notify([
            SUBSCRIPTION_TYPES.PLAYBACK_DATA,
            SUBSCRIPTION_TYPES.CURRENT_POSITION,
        ]);
    }

    // seek(offset) {
    //     if (!this.#audioContext || this.#audioContext.state === 'closed') {
    //         return;
    //     }
    //
    //     if (offset < 0 || offset > this.#playbackDuration) {
    //         return;
    //     }
    //
    //     this.#nodes.forEach(node => node.reset());
    //
    //     const currentAudioTime = this.#audioContext.currentTime;
    //
    //     this.#nodes.forEach(node => {
    //         const nodeOffset = offset - node.startAt;
    //
    //         if (nodeOffset >= 0 && nodeOffset <= node.duration) {
    //             const adjustedStartAt = currentAudioTime - nodeOffset;
    //             node.reconnect(nodeOffset, true, adjustedStartAt);
    //         } else if (nodeOffset < 0) {
    //             const delay = node.startAt - offset;
    //             const adjustedStartAt = currentAudioTime + delay;
    //             node.reconnect(0, false, adjustedStartAt);
    //         }
    //     });
    //
    //     this.startTime = currentAudioTime - offset;
    // }

    getSubscriptionData = (subscriptionType, options = {}) => {
        switch (subscriptionType) {
            case SUBSCRIPTION_TYPES.PLAYBACK_DATA: {
                return {
                    // isReady: !!this.#mixingPlan,
                    // bumperNode: this.getBumperNode(),
                    // stingerNode: this.getStingerNode(),
                    // recordingNodes: this.getRecordingNodes(),
                    // preProducedAudioNodes: this.getPreProducedAudioNodes(),
                    // soundEffectAudioNodes: this.getSoundEffectAudioNodes(),
                    // musicBedAudioNodes: this.getMusicBedAudioNodes(),
                    // v2
                    previousAndNextElements: [this.getBumperNode(), this.getStingerNode()],
                    recordingElements: this.getRecordingNodes(),
                    preProducedElements: this.getPreProducedAudioNodes(),
                    sfxElements: this.getSoundEffectAudioNodes(),
                    bedElements: this.getMusicBedAudioNodes(),
                };
            }

            case SUBSCRIPTION_TYPES.CURRENT_POSITION: {
                if (this.#audioContext && this.#audioContext.state !== 'closed') {
                    const elapsedTime = this.#audioContext.currentTime - this.startTime;

                    return {
                        position: Math.min(elapsedTime * 1000, this.#playbackDuration * 1000),
                    };
                }

                return {
                    position: 0,
                };
            }

            case SUBSCRIPTION_TYPES.TASK_DURATION: {
                return {
                    duration: this.getPlaybackDuration() * 1000,
                };
            }

            case SUBSCRIPTION_TYPES.IS_PLAYING: {
                return {
                    isPlaying: this.#audioContext && this.#audioContext.state === 'running',
                };
            }

            case SUBSCRIPTION_TYPES.CAN_BE_PLAYED: {
                return {
                    canBePlayed: !!this.#mixingPlan,
                };
            }

            case SUBSCRIPTION_TYPES.SELECTED_NODE_DATA: {
                const {index} = options;

                return this.getNodeByIndex(index);
            }

            default:
                break;
        }
    };

    getAllNodes() {
        return [
            ...this.getRecordingNodes(),
            ...this.getPreProducedAudioNodes(),
            ...this.getSoundEffectAudioNodes(),
            ...this.getMusicBedAudioNodes(),
        ];
    }

    getNodeByIndex(index) {
        return this.getAllNodes().find(node => node.index === index);
    }
}

export default new PlaybackAlgorithm();
