/* eslint no-underscore-dangle: 0 */
import {addClassNamesToElement} from '@lexical/utils';
import {DecoratorNode} from 'lexical';
import {MarkerText} from '../../../icons';

export class MarkerNode extends DecoratorNode {
    static getType() {
        return 'marker';
    }

    static clone(node) {
        return new MarkerNode();
    }

    createDOM(_config, _editor) {
        const element = document.createElement('span');

        addClassNamesToElement(element, _config.theme.marker);

        element.style.display = 'inline-block';

        return element;
    }

    isKeyboardSelectable() {
        return false;
    }

    isInline() {
        return true;
    }

    isIsolated() {
        return true;
    }

    isSelected() {
        return false;
    }

    updateDOM(_prevNode, _dom, _config) {
        return false;
    }

    decorate(editor, config) {
        return (
            <span>
                <MarkerText size={30} />
            </span>
        );
    }

    exportJSON() {
        return {
            key: this.__key,
            type: this.__type,
        };
    }

    static importJSON() {
        return $createMarkerNode();
    }
}

export const $createMarkerNode = () => {
    return new MarkerNode();
};

export const $isMarkerNode = node => {
    return node instanceof MarkerNode;
};
