import {combineReducers} from 'redux';
import {PreparedTaskVariationActionTypes} from './prepared-task-variation.action-type';
import {createReducer} from '../../../utils';

export const preparedTaskVariationReducer = combineReducers({
    entities: createReducer({}, PreparedTaskVariationActionTypes.STORE_ENTITIES),
    preparingTaskId: createReducer(null, PreparedTaskVariationActionTypes.SET_PREPARING_TASK_ID),
    preparingTaskVariationId: createReducer(null, PreparedTaskVariationActionTypes.SET_PREPARING_TASK_VARIATION_ID),
    isEditingSpeakerText: createReducer(false, PreparedTaskVariationActionTypes.SET_IS_EDITING_SPEAKER_TEXT),
    audioItemEntities: createReducer({}, PreparedTaskVariationActionTypes.STORE_AUDIO_ITEM_ENTITIES),
    originalSpeakerText: createReducer(null, PreparedTaskVariationActionTypes.SET_ORIGINAL_SPEAKER_TEXT),
});
