export const PreparedTaskVariation = function (dto) {
    this.preProducedVolume = dto.preProducedVolume ?? null;
    this.sfxVolume = dto.sfxVolume ?? null;
    this.bedVolume = dto.bedVolume ?? null;

    this.preProducedIds = dto.preProducedIds ?? [];
    this.sfxIds = dto.sfxIds ?? [];
    this.bedIds = dto.bedIds ?? [];

    this.speakerText = dto.speakerText ?? null;

    this.toDto = () => {
        return {
            preProducedAudiosVolume: this.preProducedVolume,
            soundEffectAudiosVolume: this.sfxVolume,
            musicBedAudiosVolume: this.bedVolume,
            preProducedAudioIds: this.preProducedIds,
            soundEffectAudioIds: this.sfxIds,
            musicBedAudioIds: this.bedIds,
            speakerTextWithPreProducedAudioIndicators: this.speakerText && typeof this.speakerText !== 'string'
                ? JSON.stringify(this.speakerText)
                : typeof this.speakerText === 'string'
                    ? this.speakerText
                    : null,
        };
    };

    this.clone = function () {
        return new PreparedTaskVariation({
            preProducedVolume: this.preProducedVolume,
            sfxVolume: this.sfxVolume,
            bedVolume: this.bedVolume,
            preProducedIds: this.preProducedIds,
            sfxIds: this.sfxIds,
            bedIds: this.bedIds,
            speakerText: this.speakerText,
        });
    };
};

PreparedTaskVariation.fromDto = function (dto) {
    return new PreparedTaskVariation({
        preProducedVolume: dto.preProducedAudiosVolume,
        sfxVolume: dto.soundEffectAudiosVolume,
        bedVolume: dto.musicBedAudiosVolume,
        preProducedIds: dto.preProducedAudioIds,
        sfxIds: dto.soundEffectAudioIds,
        bedIds: dto.musicBedAudioIds,
        speakerText: dto.speakerTextWithPreProducedAudioIndicators,
    });
};
