import {yupResolver} from '@hookform/resolvers/yup';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import classes from './Presets.module.scss';
import {IconButton, Input, Select, Typography} from '../../../../components';
import {ConfirmationDialog} from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import {CurrentTaskActions} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {PresetActions} from '../../../../features/preset/store/preset.action';
import {PresetSelectors} from '../../../../features/preset/store/preset.selector';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {FormKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {
    CheckIcon, ClearIcon,
    DeleteIcon,
    DragIcon,
    EditIcon,
    FolderIcon,
    LoadingBars,
    SaveIcon,
} from '../../../../icons';

const TABS = {
    LOAD_PRESET: 'load-preset',
    SAVE_PRESET: 'save-preset',
};

const LoadPreset = ({audioType}) => {
    const dispatch = useDispatch();

    const presets = useMemoizedCreateSelector(PresetSelectors.createDropdownOptionsByTypeSelector, audioType);
    const isLoading = useIsLoading(LOADING_TYPES.LOAD_ELEMENTS_FROM_PRESET);

    const [presetId, setPresetId] = useState(null);

    useEffect(() => {
        if (presetId) {
            dispatch(PresetActions.loadElementsFromPreset(presetId));
            setPresetId(null);
        }
    }, [dispatch, presetId]);

    return (
        <div className={classes.loadPreset}>
            <Typography color="regent" weight={500} transform="uppercase">
                Load preset
            </Typography>

            <Typography color="regent" weight={500} size={12} transform="uppercase" className={classes.label}>
                Choose from the list
            </Typography>

            <Select
                inputId="presets"
                onChange={preset => {
                    setPresetId(preset);
                }}
                options={presets}
                value={presetId}
                isLoading={isLoading}
                isDisabled={isLoading}
                InputProps={{
                    theme: 'dark',
                    autoComplete: 'off',
                }}
            />
        </div>
    );
};

LoadPreset.propTypes = {
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
};

const DeleteButtonWithConfirmationDialog = ({presetId}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('featuresPreset');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <IconButton
                size={18}
                iconSize={14}
                backgroundColor="transparent"
                color="fiord"
                onClick={() => {
                    setIsDialogOpen(true);
                }}
            >
                <DeleteIcon />
            </IconButton>

            <ConfirmationDialog
                isOpen={isDialogOpen}
                onCancel={() => setIsDialogOpen(false)}
                onClose={() => setIsDialogOpen(false)}
                onSubmit={() => dispatch(PresetActions.deletePreset(presetId))}
                title={t('deleteConfirmation.title')}
                message={t('deleteConfirmation.message')}
                cancel={t('deleteConfirmation.cancel')}
                confirm={t('deleteConfirmation.confirm')}
            />
        </>
    );
};

DeleteButtonWithConfirmationDialog.propTypes = {
    presetId: PropTypes.string.isRequired,
};

const SavePreset = ({audioType}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('featuresPreset');

    const presets = useMemoizedCreateSelector(PresetSelectors.createPresetsByTypeSelector, audioType, false);
    const shouldResetForm = useMemoizedCreateSelector(UiSelectors.crateFormResetSelector, FormKeys.SAVE_PRESET);
    const isLoading = useIsLoading(LOADING_TYPES.PRESET_FORM);

    const [selectedPresetId, setSelectedPresetId] = useState(null);

    const {control, handleSubmit: createSubmitHandler, formState: {errors}, reset, setValue} = useForm({
        defaultValues: {
            name: '',
        },
        resolver: yupResolver(Yup.object({
            name: Yup.string().required(),
        })),
    });

    const handleSubmit = data => {
        if (selectedPresetId) {
            // Update preset
            dispatch(PresetActions.updatePreset(selectedPresetId, data.name, audioType));
            setSelectedPresetId(null);

            return;
        }

        // Create preset
        dispatch(PresetActions.createPreset(data.name, audioType));
    };

    useEffect(() => {
        if (shouldResetForm) {
            reset();
            dispatch(UiActions.setFormReset(FormKeys.SAVE_PRESET, null));
        }
    }, [dispatch, reset, shouldResetForm]);

    return (
        <div className={classes.savePreset}>
            <Typography color="regent" weight={500} transform="uppercase">
                Save preset
            </Typography>

            <Typography color="regent" weight={500} size={12} transform="uppercase" className={classes.label}>
                Preset name
            </Typography>

            <div className={classes.formWrapper}>
                <form onSubmit={createSubmitHandler(handleSubmit)}>
                    <div className={classes.formRow}>
                        <Controller
                            name="name"
                            control={control}
                            render={({field}) => (
                                <Input
                                    {...field}
                                    hasError={!!errors.name}
                                    helperText={errors?.name?.message}
                                    theme="dark"
                                    disabled={isLoading}
                                />
                            )}
                        />

                        <IconButton
                            type="submit"
                            disabled={isLoading}
                            backgroundColor="radiance"
                            color="white"
                            className={classes.submitButton}
                        >
                            {isLoading ? <LoadingBars /> : <CheckIcon />}
                        </IconButton>
                    </div>
                </form>

                {presets.length ? (
                    <div className={classes.listWrapper}>
                        <ul className={classes.list}>
                            {presets.map(preset => (
                                <li
                                    key={preset.id}
                                    className={clsx(classes.listItem, {
                                        [classes.selected]: preset.id === selectedPresetId,
                                    })}
                                >
                                    <DragIcon className={classes.icon} color="fiord" size={14} />

                                    <Typography className={classes.name} color="fiord" lineClamp={1} hasLineHeight={false} size={14}>
                                        {preset.name}
                                    </Typography>

                                    <div className={classes.actions}>
                                        <IconButton
                                            size={18}
                                            iconSize={14}
                                            backgroundColor="transparent"
                                            color="fiord"
                                            onClick={() => {
                                                setSelectedPresetId(preset.id);
                                                setValue('name', preset.name);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <DeleteButtonWithConfirmationDialog presetId={preset.id} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <Typography variant="body" color="regent" size={12} className={classes.noPresets} hasGutters={false}>
                        {t('noPresets')}
                    </Typography>
                )}
            </div>
        </div>
    );
};

SavePreset.propTypes = {
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
};

export const Presets = ({audioType}) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(TABS.LOAD_PRESET);

    const loadedPresetId = useMemoizedCreateSelector(PresetSelectors.createLoadedPresetIdByTypeSelector, audioType);
    const loadedPreset = useMemoizedCreateSelector(PresetSelectors.createPresetsByIdSelector, loadedPresetId);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <Typography weight={500} size={12} transform="uppercase" color="regent" hasLineHeight={false}>
                    Preset
                </Typography>

                <div className={classes.presetNameWrapper}>
                    <Typography color={loadedPreset ? 'white' : 'stone'} size={14} hasLineHeight={false} lineClamp={1}>
                        {loadedPreset ? loadedPreset.name : 'Untitled'}
                    </Typography>
                </div>

                <div className={classes.headerButtons}>
                    <IconButton
                        className={clsx(classes.headerButton, {
                            [classes.active]: activeTab === TABS.LOAD_PRESET,
                        })}
                        backgroundColor="mako"
                        color="white"
                        onClick={() => setActiveTab(TABS.LOAD_PRESET)}
                    >
                        <FolderIcon />
                    </IconButton>

                    <IconButton
                        className={clsx(classes.headerButton, {
                            [classes.active]: activeTab === TABS.SAVE_PRESET,
                        })}
                        backgroundColor="mako"
                        color="white"
                        onClick={() => setActiveTab(TABS.SAVE_PRESET)}
                    >
                        <SaveIcon />
                    </IconButton>

                    <IconButton
                        className={classes.headerButton}
                        backgroundColor="mako"
                        color="white"
                        onClick={() => {
                            if (audioType === 'preProduced') {
                                dispatch(PresetActions.setLoadedPreProducedPresetId(null));
                            } else if (audioType === 'sfx') {
                                dispatch(PresetActions.setLoadedSfxPresetId(null));
                            } else if (audioType === 'bed') {
                                dispatch(PresetActions.setLoadedBedPresetId(null));
                            }

                            dispatch(CurrentTaskActions.clearLoadedElements(audioType));
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </header>

            <main className={classes.main}>
                {activeTab === TABS.LOAD_PRESET && (
                    <LoadPreset audioType={audioType} />
                )}

                {activeTab === TABS.SAVE_PRESET && (
                    <SavePreset audioType={audioType} />
                )}
            </main>
        </div>
    );
};

Presets.propTypes = {
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
};
