import {call, put} from 'redux-saga/effects';
import {SoundSettingsActions} from './sound-settings.action';
import {handleSagaError} from '../../../error.saga';
import {SoundSettingsApi} from '../api/sound-settings.api';

const getSoundSettingsCache = {};

const getSoundSettings = function* ({channelId}) {
    try {
        const cached = getSoundSettingsCache[channelId];

        if (cached) {
            return new Promise(resolve => {
                resolve(cached);
            });
        }

        const soundSettings = yield call(SoundSettingsApi.getSoundSettings, {channelId});

        getSoundSettingsCache[channelId] = soundSettings;

        yield put(SoundSettingsActions.store(soundSettings));
    } catch (error) {
        yield call(handleSagaError, error, 'getSoundSettings');
    }
};

export const loadSoundSettings = function* ({channelId}) {
    yield call(getSoundSettings, {channelId});
};
