import Collapse from '@mui/material/Collapse';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import {useDebouncedEffect} from '@react-hookz/web';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './HomeScreen.module.scss';
import {
    Container,
    Input,
    InputGroup,
    PageHeader,
    PrimaryButton,
    Select,
    Switch,
    Typography,
} from '../../../../components';
import {FailedUploadsAlert} from '../../../../features/failed-uploads/components/FailedUploadsAlert';
import {FailedUploadsSelectors} from '../../../../features/failed-uploads/store/failed-uploads.selector';
import {FailedUploadStatuses} from '../../../../features/failed-uploads/utils/constants';
import {FilterSelectors} from '../../../../features/home-filters';
import {HomeViews} from '../../../../features/home-filters/home-filters.consts';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {Player} from '../../../../features/player';
import {PlayerSelectors} from '../../../../features/player/store/player.selector';
import {PlaylistSelectors} from '../../../../features/playlist';
import {PlaylistActions} from '../../../../features/playlist/store/playlist.action';
import {PreloadingStatus} from '../../../../features/preloading/components/PreloadingStatus/PreloadingStatus';
import {PreloadingSelectors} from '../../../../features/preloading/store/preloading.selector';
import {BrandContainer} from '../../../../features/task-overview/component/BrandContainer';
import {UrgentTasksAlert} from '../../../../features/task-overview/component/UrgentTasksAlert';
import {TaskOverviewSelectors} from '../../../../features/task-overview/store/task-overview.selectors';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {LoadingBars, SearchIcon} from '../../../../icons';
import {LOADING_STATES, NOT_SELECTED} from '../../../../utils';
import {DURATION} from '../../../../utils/animations';
import {PlaylistHeader} from '../PlaylistHeader/PlaylistHeader';
import {PlaylistView} from '../PlaylistView/PlaylistView';

const TaskOverviewContainer = () => {
    const brandIds = useSelector(TaskOverviewSelectors.selectBrandIds);

    return (
        <div className={classes.overview}>
            {brandIds.map((brandId, index) => (
                <BrandContainer
                    brandId={brandId}
                    key={brandId}
                    initialDelay={index * 75}
                />
            ))}
        </div>
    );
};

const SearchTerm = ({hasLabel}) => {
    const dispatch = useDispatch();

    const isLoadingPlaylist = useIsLoading(LOADING_TYPES.PLAYLIST);

    const searchTerm = useSelector(PlaylistSelectors.selectSearchTerm);

    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

    useDebouncedEffect(() => {
        dispatch(PlaylistActions.setSearchTerm(localSearchTerm));
    }, [localSearchTerm], 200, 0);

    return (
        <InputGroup label={hasLabel ? 'Search' : null} inputId="search">
            <Input
                id="search"
                startIcon={<SearchIcon />}
                value={localSearchTerm}
                onChange={event => {
                    setLocalSearchTerm(event.target.value);
                }}
                disabled={isLoadingPlaylist}
                placeholder="Search"
            />
        </InputGroup>
    );
};

SearchTerm.propTypes = {
    hasLabel: PropTypes.bool.isRequired,
};

const PreloadingStatusContainer = () => {
    const hasPreloadingAlert = useSelector(PreloadingSelectors.selectIsAlertDisplayed);

    return (
        <Collapse
            in={hasPreloadingAlert}
            timeout={DURATION}
            className={classes.alertContainer}
            unmountOnExit
            mountOnEnter
        >
            <PreloadingStatus />
        </Collapse>
    );
};

const FailedUploadsAlertContainer = () => {
    const status = useSelector(FailedUploadsSelectors.selectStatus);

    return (
        <Collapse
            in={status !== FailedUploadStatuses.NO_DATA}
            timeout={DURATION}
            className={classes.alertContainer}
            unmountOnExit
            mountOnEnter
        >
            <FailedUploadsAlert />
        </Collapse>
    );
};

const UrgentTasksAlertContainer = () => {
    const hasUrgentTasks = useMemoizedCreateSelector(TaskOverviewSelectors.createHasUrgentTasksSelector);

    return (
        <Collapse
            in={hasUrgentTasks}
            timeout={DURATION}
            className={classes.alertContainer}
            unmountOnExit
            mountOnEnter
        >
            <UrgentTasksAlert />
        </Collapse>
    );
};

const OverviewLoadingContainer = () => {
    const overviewLoadingState = useIsLoading(LOADING_TYPES.TASK_OVERVIEW);

    if (overviewLoadingState !== LOADING_STATES.LOADING) {
        return null;
    }

    return (
        <Container>
            <motion.div
                key="loading"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.5}}
            >
                <div className={classes.loading}>
                    <LoadingBars size={48} color="radiance" />
                </div>
            </motion.div>
        </Container>
    );
};

const NoDataContainer = () => {
    const overviewLoadingState = useIsLoading(LOADING_TYPES.TASK_OVERVIEW);
    const brandIds = useSelector(TaskOverviewSelectors.selectBrandIds);

    if (brandIds.length || overviewLoadingState === LOADING_STATES.LOADING) {
        return null;
    }

    return (
        <Container>
            <div className={classes.allTasksDoneContainer}>
                <Typography weight={500} size={24} align="center" variant="body" color="radiance" hasGutters={false}>
                    Congratulations! 🎉
                </Typography>

                <Typography size={13} align="center" variant="body" color="trout">
                    You have no tasks at the moment.

                    <br />
                    <br />

                    The application will automatically load your tasks as soon as they become available.
                </Typography>
            </div>
        </Container>
    );
};

const ShowStructure = () => {
    const dispatch = useDispatch();

    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);

    return (
        <Switch
            id="structure"
            label="Show structure"
            isOn={showStructured}
            onChange={value => {
                dispatch(PlaylistActions.setShowStructured(value));
                dispatch(PlaylistActions.setShowOnlyMatches(false));
            }}
        />
    );
};

const Role = () => {
    const dispatch = useDispatch();

    const isLoadingPlaylist = useIsLoading(LOADING_TYPES.PLAYLIST);
    const role = useSelector(PlaylistSelectors.selectRole);
    const hostRoles = useMemoizedCreateSelector(PlaylistSelectors.createHostRoleDropdownSelector);

    return (
        <InputGroup label="Role" inputId="role">
            <Select
                inputId="role"
                options={[{id: NOT_SELECTED, name: 'Not selected'}, ...hostRoles]}
                value={role}
                isLoading={isLoadingPlaylist}
                isDisabled={isLoadingPlaylist}
                onChange={value => {
                    dispatch(PlaylistActions.setRole(value));
                }}
            />
        </InputGroup>
    );
};

const ShowContent = () => {
    const dispatch = useDispatch();

    const showContent = useSelector(PlaylistSelectors.selectShowContent);
    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);

    return (
        <Switch
            id="content"
            label="Show content"
            isOn={showStructured ? showContent : false}
            isDisabled={!showStructured}
            onChange={value => {
                dispatch(PlaylistActions.setShowContent(value));
            }}
        />
    );
};

const ShowMusic = () => {
    const dispatch = useDispatch();

    const showMusic = useSelector(PlaylistSelectors.selectShowMusic);
    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);

    return (
        <Switch
            id="music"
            label="Show music"
            isOn={showStructured ? showMusic : false}
            isDisabled={!showStructured}
            onChange={value => {
                dispatch(PlaylistActions.setShowMusic(value));
            }}
        />
    );
};

const OverviewContainer = () => {
    const activeView = useMemoizedCreateSelector(FilterSelectors.createFilterFieldValueSelector, 'activeView');

    if (activeView !== HomeViews.OVERVIEW) {
        return null;
    }

    return (
        <Container>
            <motion.div
                key={HomeViews.OVERVIEW}
                initial={{opacity: 0, y: 60}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -60}}
                transition={{duration: 0.5}}
            >
                <div>
                    <PageHeader subtitle="Tasks" className={classes.pageHeader}>
                        Overview
                    </PageHeader>

                    <TaskOverviewContainer />
                </div>
            </motion.div>
        </Container>
    );
};

const PlaylistContainer = () => {
    const activeView = useMemoizedCreateSelector(FilterSelectors.createFilterFieldValueSelector, 'activeView');

    const showStructured = useSelector(PlaylistSelectors.selectShowStructured);

    if (activeView !== HomeViews.PLAYLIST) {
        return null;
    }

    return (
        <motion.div
            key={HomeViews.PLAYLIST}
            initial={{opacity: 0, y: 60}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: -60}}
            transition={{duration: 0.5}}
        >
            <div>
                <div className={classes.filtersContainer}>
                    <Container>
                        <div
                            className={clsx(classes.filters, {
                                [classes.noStructure]: !showStructured,
                            })}
                        >
                            <Grow in={!!showStructured} unmountOnExit exit={false}>
                                <div>
                                    <SearchTerm hasLabel={false} />
                                </div>
                            </Grow>

                            <div className={classes.left}>
                                <ShowMusic />

                                <ShowContent />

                                <ShowStructure />
                            </div>
                        </div>
                    </Container>
                </div>

                <Collapse in={!showStructured} unmountOnExit mountOnEnter>
                    <Container>
                        <div className={classes.secondFilters}>
                            <SearchTerm hasLabel />

                            {/* TODO: Remove everything related to PlanningFilterOne */}
                            {/* <PlanningFilterOne /> */}

                            <Role />
                        </div>
                    </Container>
                </Collapse>

                <Container>
                    <PlaylistView />
                </Container>
            </div>
        </motion.div>
    );
};

const ContentContainer = () => {
    const overviewLoadingState = useIsLoading(LOADING_TYPES.TASK_OVERVIEW);

    const brandIds = useSelector(TaskOverviewSelectors.selectBrandIds);

    if (!brandIds.length || overviewLoadingState === LOADING_STATES.LOADING) {
        return null;
    }

    return (
        <AnimatePresence>
            <OverviewContainer key="overview" />

            <PlaylistContainer key="playlist" />
        </AnimatePresence>
    );
};

const BodyPaddingWorkaround = () => {
    const hasPreloadingAlert = useSelector(PreloadingSelectors.selectIsAlertDisplayed);
    const status = useSelector(FailedUploadsSelectors.selectStatus);
    const hasUrgentTasks = useMemoizedCreateSelector(TaskOverviewSelectors.createHasUrgentTasksSelector);
    const isOpen = useSelector(PlayerSelectors.selectIsOpen);

    let paddingBottom = 8;

    if (hasPreloadingAlert) {
        paddingBottom += 70;
    }

    if (status !== FailedUploadStatuses.NO_DATA) {
        paddingBottom += 70;
    }

    if (hasUrgentTasks) {
        paddingBottom += 70;
    }

    if (isOpen) {
        paddingBottom += 55;
    }

    return (
        <GlobalStyles
            styles={{
                'body': {
                    paddingBottom,
                    transition: 'padding-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
            }}
        />
    );
};

const NewVersion = () => {
    const hasNewVersion = useSelector(state => state.hasNewVersion);

    return (
        <Slide direction="left" in={hasNewVersion}>
            <div className={classes.newVersionRoot}>
                <Typography
                    color="white"
                    size={16}
                    hasLineHeight={false}
                    hasGutters={false}
                    align="center"
                >
                    A new version of the application is available.
                </Typography>

                <PrimaryButton
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    Reload
                </PrimaryButton>
            </div>
        </Slide>
    );
};

export const HomeScreen = () => {
    const activeView = useMemoizedCreateSelector(FilterSelectors.createFilterFieldValueSelector, 'activeView');

    return (
        <>
            <Collapse in={activeView === HomeViews.PLAYLIST} unmountOnExit>
                <PlaylistHeader />
            </Collapse>

            <div className={classes.fixedBottomContentContainer}>
                <PreloadingStatusContainer />
                <FailedUploadsAlertContainer />
                <UrgentTasksAlertContainer />
                <Player hasFixedPosition={false} />

                <BodyPaddingWorkaround />
            </div>

            <OverviewLoadingContainer />

            <NoDataContainer />

            <ContentContainer />

            <NewVersion />
        </>
    );
};
