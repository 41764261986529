import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './DdsItem.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {PlayIcon, SoundIcon} from '../../../../icons';

export const DdsItem = ({ddsItemKey}) => {
    const ddsItem = useMemoizedCreateSelector(PlaylistSelectors.createDdsItemByKeySelector, ddsItemKey);

    if (!ddsItemKey.name) {
        // console.warn({ddsItemKey, ddsItem});
    }

    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <SoundIcon className={classes.icon} color="ecstasy" />

            <IconButton
                className={classes.playButton}
                color="ecstasy"
                backgroundColor="transparent"
                size={18}
                hoverStyle="color"
                onClick={() => dispatch(PlayerActions.loadItem(ddsItem.externalId, ddsItem.name))}
            >
                <PlayIcon />
            </IconButton>

            <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                {ddsItem.startTime}
            </Typography>

            <Typography weight={500} size={11} color="cello" className={classes.length}>
                {ddsItem.formattedLength}
            </Typography>

            <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                {ddsItem.name}
            </Typography>
        </div>
    );
};

DdsItem.propTypes = {
    ddsItemKey: PropTypes.string.isRequired,
};
