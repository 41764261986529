class VolumeChangePoint {
    /**
     *
     * @param {number} startAt
     * @param {number} stopAt
     * @param {number} fromVolume
     * @param {number} toVolume
     */
    constructor(startAt, stopAt, fromVolume, toVolume) {
        this.#startAt = startAt;
        this.#stopAt = stopAt;
        this.#fromVolume = fromVolume;
        this.#toVolume = toVolume;
    }

    /**
     * @type number
     */
    #startAt;

    /**
     * @type number
     */
    #stopAt;

    /**
     * @type number
     */
    #fromVolume;

    /**
     * @type number
     */
    #toVolume;

    get startAt() {
        return this.#startAt;
    }

    set startAt(value) {
        this.#startAt = value;
    }

    get stopAt() {
        return this.#stopAt;
    }

    set stopAt(value) {
        this.#stopAt = value;
    }

    get fromVolume() {
        return this.#fromVolume;
    }

    set fromVolume(value) {
        this.#fromVolume = value;
    }

    get toVolume() {
        return this.#toVolume;
    }

    set toVolume(value) {
        this.#toVolume = value;
    }

    toObject() {
        return {
            startAt: this.startAt,
            stopAt: this.stopAt,
            fromVolume: this.fromVolume,
            toVolume: this.toVolume,
        };
    }
}

export default VolumeChangePoint;
