import queryString from 'query-string';
import {all, call, delay, put, select} from 'redux-saga/effects';
import {CockpitActions} from './cockpit.action';
import {loadAdjacentElements} from '../../../features/adjacent-elements/store/adjacent-elements.saga';
import {BroadcastChannelActions, REQUEST_TYPES} from '../../../features/broadcast-channel';
import {getChannelsFlow} from '../../../features/channel/store/channel.saga';
import {CurrentTaskActions} from '../../../features/current-task';
import {clearLoadedElements, loadPreparedTaskVariation} from '../../../features/current-task/store/current-task.saga';
import {EditorActions} from '../../../features/editor/store/editor.action';
import {LOADING_TYPES, LoadingActions} from '../../../features/loading';
import {MixingPlanActions} from '../../../features/mixing-plan/store/mixing-plan.action';
import {loadMixingPlan} from '../../../features/mixing-plan/store/mixing-plan.loader-saga';
import {MixingPlanSelectors} from '../../../features/mixing-plan/store/mixing-plan.selector';
import {
    createMixingPlanForPreProducedAudio,
} from '../../../features/mixing-plan/utils/create-mixing-plan-for-pre-produced-audio';
import {loadElementsFromPreset, loadPresetsForCurrentTask} from '../../../features/preset/store/preset.saga';
import {PresetSelectors} from '../../../features/preset/store/preset.selector';
import playbackAlgorithm from '../../../features/recording-algorithm/PlaybackAlgorithm';
import {RecordingAlgorithmActions} from '../../../features/recording-algorithm/store/recording-algorithm.action';
import {loadElementsFromMixingPlan} from '../../../features/recording-algorithm/store/recording-algorithm.saga';
import {loadSoundSettings} from '../../../features/sound-settings/store/sound-settings.saga';
import {TaskSelectors, loadCurrentTask} from '../../../features/task';
import {UiActions} from '../../../features/ui/store/ui.action';
import {ModalKeys} from '../../../features/ui/utils/constants';
import {ReminderKeys, reminderStorage} from '../../../utils/reminder-storage';
import {MainContentComponents} from '../utils/constants';

let previousTaskId = null;
export const cockpitScreenLoaderSaga = function* ({payload}) {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, true));

    yield delay(225); // animation delay

    const {brandId, channelId, taskId, taskVariationId, playlistTaskKey} = yield call(extractParams, {payload});

    yield put(CurrentTaskActions.setCurrentTaskVariationId(taskVariationId));

    const effects = [
        put(BroadcastChannelActions.send({
            messageType: 'request',
            requestType: REQUEST_TYPES.LOADED_TASK_IDS,
        })),
        put(CockpitActions.setIsEditingSpeakerText(false)),
        put(BroadcastChannelActions.send({
            messageType: 'action',
            action: CurrentTaskActions.setCurrentPlaylistTaskKey(playlistTaskKey),
        })),
        put(BroadcastChannelActions.send({
            messageType: 'action',
            action: CurrentTaskActions.setCurrentTaskId(taskId),
        })),
        put(CurrentTaskActions.setCurrentTaskId(taskId)),
        put(CurrentTaskActions.setCurrentPlaylistTaskKey(playlistTaskKey)),
        put(EditorActions.setSelectedElementIndex(null)),
        put(RecordingAlgorithmActions.reset()),
    ];

    /**
     * No need to fetch more stuff as the task is the same, so, optimization.
     * Also, we want to display to the user that the context didn't change when the user is jumping between variations
     * When the user opens the task in cockpit window, it always shows the context from the playlist, because that's
     * where the task is planned. And we want to keep the context, even for other variations.
     * This is the only way we can achieve this.
     */
    if (previousTaskId !== taskId) {
        effects.push(call(loadCurrentTask, taskId));
        effects.push(call(loadAdjacentElements, {taskVariationId}));
        effects.push(call(getChannelsFlow, brandId));
        effects.push(call(loadSoundSettings, {channelId}));
        effects.push(call(loadPresetsForCurrentTask, {brandId, presetType: 'preProduced'}));
        effects.push(call(loadPresetsForCurrentTask, {brandId, presetType: 'sfx'}));
        effects.push(call(loadPresetsForCurrentTask, {brandId, presetType: 'bed'}));
        effects.push(put(CurrentTaskActions.setIsFreeModeEnabled(false)));
    }

    yield all(effects);

    yield call(loadPreparedTaskVariation, {taskVariationId});

    const task = yield select(TaskSelectors.selectCurrentTask);
    const taskVariation = yield select(TaskSelectors.createTaskVariationByIdSelector(taskVariationId));

    if (taskVariation.isCompleted || taskVariation.isProcessing) {
        if (taskVariation.hasMixingPlan) {
            yield call(loadMixingPlan, taskVariationId);
        }

        yield put(CockpitActions.setMainContentComponent(MainContentComponents.EDITOR));
    } else {
        yield put(CockpitActions.setMainContentComponent(MainContentComponents.MODERATION));
    }

    yield all([
        call(preserveSelectedPresets, {brandId}),
        call(instantiateMixingPlan, {taskVariation}),
        call(showCrossChannelTaskDialog, {task}),
    ]);

    previousTaskId = taskId;

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, false));
};


// eslint-disable-next-line func-style
function* preserveSelectedPresets({brandId}) {
    const preProducedPresetId = yield select(PresetSelectors.selectLoadedPreProducedPresetId);
    const sfxPresetId = yield select(PresetSelectors.selectLoadedSfxPresetId);
    const bedPresetId = yield select(PresetSelectors.selectLoadedBedPresetId);

    if (preProducedPresetId) {
        yield call(clearLoadedElements, {elementType: 'preProduced'});
        yield call(loadElementsFromPreset, {presetId: preProducedPresetId});
    }

    if (sfxPresetId) {
        yield call(clearLoadedElements, {brandId, presetType: 'sfx'});
        yield call(loadElementsFromPreset, {presetId: sfxPresetId});
    }

    if (bedPresetId) {
        yield call(clearLoadedElements, {brandId, presetType: 'bed'});
        yield call(loadElementsFromPreset, {presetId: bedPresetId});
    }
}

// eslint-disable-next-line func-style
function extractParams({payload}) {
    const {location, params} = payload;
    const {search} = location;

    const queryParams = queryString.parse(search);

    const brandId = parseInt(queryParams.brandId, 10);
    const channelId = parseInt(queryParams.channelId, 10);
    const taskId = parseInt(params.taskId, 10);
    const taskVariationId = parseInt(queryParams.taskVariationId, 10);
    const playlistTaskKey = queryParams.playlistTaskKey;

    return {
        brandId,
        channelId,
        taskVariationId,
        playlistTaskKey,
        taskId,
    };
}

// eslint-disable-next-line func-style
function* instantiateMixingPlan({taskVariation}) {
    let mixingPlan;

    if (taskVariation.hasMixingPlan) {
        mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);
    } else if (taskVariation.isCompleted) {
        mixingPlan = yield call(createMixingPlanForPreProducedAudio, {
            externalId: taskVariation.externalId,
        });

        yield put(MixingPlanActions.store(mixingPlan, 'api'));
    }

    if (mixingPlan) {
        yield call(loadElementsFromMixingPlan);
        yield call([playbackAlgorithm, playbackAlgorithm.setMixingPlan], mixingPlan.clone(), true);
        yield call([playbackAlgorithm, playbackAlgorithm.initialize]);
    }
}

// eslint-disable-next-line func-style
function* showCrossChannelTaskDialog({task}) {
    const shouldRemind = yield call(
        [reminderStorage, reminderStorage.shouldRemind],
        ReminderKeys.CROSS_CHANNEL_TASK_DIALOG,
    );

    if (task.isCrossChannelTask && shouldRemind) {
        yield put(UiActions.setModalState(ModalKeys.CROSS_CHANNEL_TASK_DIALOG, true));
    }
}
