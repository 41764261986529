export const CurrentTaskActionTypes = {
    SET_CURRENT_TASK_ID: 'currentTask/SET_CURRENT_TASK_ID',
    SET_CURRENT_TASK_VARIATION_ID: 'currentTask/SET_CURRENT_TASK_VARIATION_ID',
    SET_LOADED_ELEMENTS: 'currentTask/SET_LOADED_ELEMENTS',
    SET_PRE_PRODUCED_VOLUME: 'currentTask/SET_PRE_PRODUCED_VOLUME',
    SET_SFX_VOLUME: 'currentTask/SET_SFX_VOLUME',
    SET_BED_VOLUME: 'currentTask/SET_BED_VOLUME',
    SET_SPEAKER_TEXT: 'currentTask/SET_SPEAKER_TEXT',
    UPDATE_PREPARED_TASK_VARIATION: 'currentTask/UPDATE_PREPARED_TASK_VARIATION',
    SET_ARE_PRE_PRODUCED_ELEMENTS_MUTED: 'currentTask/SET_ARE_PRE_PRODUCED_ELEMENTS_MUTED',
    SET_ARE_SFX_ELEMENTS_MUTED: 'currentTask/SET_ARE_SFX_ELEMENTS_MUTED',
    SET_ARE_BED_ELEMENTS_MUTED: 'currentTask/SET_ARE_BED_ELEMENTS_MUTED',
    LOAD_ELEMENT: 'currentTask/LOAD_ELEMENT',
    CLEAR_LOADED_ELEMENTS: 'currentTask/CLEAR_LOADED_ELEMENTS',
    REMOVE_LOADED_ELEMENT: 'currentTask/REMOVE_LOADED_ELEMENT',
    SET_ORDER_OF_LOADED_ELEMENTS: 'currentTask/SET_ORDER_OF_LOADED_ELEMENTS',
    SET_CURRENT_PLAYLIST_TASK_KEY: 'currentTask/SET_CURRENT_PLAYLIST_TASK_KEY',
    SET_IS_FREE_MODE_ENABLED: 'currentTask/SET_IS_FREE_MODE_ENABLED',
    SET_FREE_MODE_ORIGINAL_TASK_VARIATION_ID: 'currentTask/SET_FREE_MODE_ORIGINAL_TASK_VARIATION_ID',
};
