import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import {useWindowSize} from '@react-hookz/web';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {useDispatch} from 'react-redux';
import classes from './PresetsDropdown.module.scss';
import {IconButton} from '../../../../components';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {PresetIcon} from '../../../../icons';
import {useDisabledControls} from '../../hooks/use-disabled-controls';

export const PresetsDropdown = ({audioType, children}) => {
    const buttonRef = useRef();
    const paperRef = useRef();

    const dispatch = useDispatch();

    const {areControlsDisabled} = useDisabledControls();

    const modalAudioType = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.PRESET_DROPDOWN);
    const isLoadingForm = useIsLoading(LOADING_TYPES.PRESET_FORM);
    const isLoadingElements = useIsLoading(LOADING_TYPES.LOAD_ELEMENTS_FROM_PRESET);

    const isOpen = modalAudioType === audioType;

    const {width} = useWindowSize();

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (width < 1280) {
            dispatch(UiActions.setModalState(ModalKeys.PRESET_DROPDOWN, null));
        }
        // eslint-disable-next-line
    }, [width]);

    return (
        <div className={classes.root}>
            <IconButton
                backgroundColor="transparent"
                size={24}
                iconSize={24}
                color="white"
                hoverStyle="color"
                ref={buttonRef}
                onClick={() => dispatch(UiActions.setModalState(ModalKeys.PRESET_DROPDOWN, audioType))}
                disabled={areControlsDisabled}
            >
                <PresetIcon />
            </IconButton>

            {createPortal((
                <Fade in={isOpen} exit={false} mountOnEnter unmountOnExit>
                    <div
                        style={{
                            position: 'fixed',
                            top: buttonRef.current?.getBoundingClientRect().y,
                            left: buttonRef.current?.getBoundingClientRect().x,
                            zIndex: 1301,
                        }}
                    >
                        <PresetIcon color="white" size={24} />
                    </div>
                </Fade>
            ), document.querySelector('#root'))}

            <Popover
                open={isOpen}
                anchorEl={buttonRef.current}
                onClose={() => {
                    if (isLoadingForm || isLoadingElements) {
                        return;
                    }

                    dispatch(UiActions.setModalState(ModalKeys.PRESET_DROPDOWN, null));
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionProps={{
                    exit: false,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={0}
                PaperProps={{
                    ref: paperRef,
                    sx: {
                        'borderRadius': '5px',
                        'boxShadow': '0 0 25px 0 rgb(0 0 0 / 60%)',
                        'border': '1px solid #606875',
                        'backgroundColor': '#262a30',
                        'padding': 1,
                        'marginTop': 1,
                        'overflow': 'visible',
                        'willChange': 'transform',
                    },
                }}
                BackdropProps={{
                    sx: {
                        // zIndex: 0,
                    },
                }}
            >
                {children}
            </Popover>

            <Backdrop exit={false} open={isOpen} />
        </div>
    );
};

PresetsDropdown.propTypes = {
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
};
