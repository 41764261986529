const selectStatus = state => state.failedUploads.status;

const selectProgress = state => state.failedUploads.progress;

const selectFailedUploadTaskIds = state => state.failedUploads.failedUploadTaskIds;

export const FailedUploadsSelectors = {
    selectStatus,
    selectProgress,
    selectFailedUploadTaskIds,
};
