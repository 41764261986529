import BaseAudioNode from './BaseAudioNode';

class PreviousElementAudioNode extends BaseAudioNode {
    /**
     * Converts startNext value to seconds if available
     * Returns zero if not
     *
     * @returns {number}
     */
    #getStartNextInSeconds = () => {
        const cueIn = super.audioObject && super.audioObject.cueIn > 0 ? super.audioObject.cueIn / 1000 : 0;
        const startNext = super.audioObject && super.audioObject.startNext > 0 ? super.audioObject.startNext / 1000 : 0;

        return cueIn + startNext;
    };

    /**
     * Calculates total playing length of node in different scenarios
     *
     * @returns {number}
     */
    getDuration() {
        const startNext = this.#getStartNextInSeconds();

        if (super.didEnd()) {
            return 0;
        }

        if (!super.isPlaying) {
            if (startNext) {
                return startNext - super.offset;
            }

            if (super.audioObject?.contentType === 'Music') {
                return super.audioObject?.cueOut / 1000 + super.audioObject?.fadeOut / 1000 + 3;
            }

            return super.duration;
        }

        if (this.getCountdownDirection() === 'negative' && startNext) {
            return startNext - super.offset;
        }

        if (super.audioObject?.contentType === 'Music') {
            return super.audioObject?.cueOut / 1000 + super.audioObject?.fadeOut / 1000 + 3;
        }

        return super.duration;
    }

    /**
     * Calculates passed (playing) time of node in different scenarios
     *
     * @returns {number}
     */
    getPassedTime() {
        let startNext = this.#getStartNextInSeconds();

        if (startNext - super.offset < 0) {
            startNext = 0;
        }

        if (super.didEnd()) {
            return 0;
        }

        if (!super.isPlaying) {
            if (startNext) {
                return startNext - super.offset;
            }

            return super.offset;
        }

        if (this.getCountdownDirection() === 'negative') {
            return startNext - super.offset - super.audioContext.currentTime + super.lastStartedAt();
        }

        return super.audioContext.currentTime - super.lastStartedAt() + super.offset;
    }

    /**
     * Determines if we need to count from negative value to zero, or from zero to positive value.
     *
     * @returns {string}
     */
    getCountdownDirection() {
        let startNext = this.#getStartNextInSeconds();

        if (startNext - super.offset < 0) {
            startNext = 0;
        }

        if (startNext && super.calculatePlayingTime() <= startNext) {
            return 'negative';
        }

        return 'positive';
    }
}

export default PreviousElementAudioNode;
