import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './Song.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {MusicIcon, PlayIcon} from '../../../../icons';

export const Song = ({songKey, startTime}) => {
    const song = useMemoizedCreateSelector(PlaylistSelectors.createSongByKeySelector, songKey);

    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <MusicIcon className={classes.icon} color="sun" />

            <IconButton
                className={classes.playButton}
                color="sun"
                backgroundColor="transparent"
                size={18}
                iconSize={18}
                hoverStyle="color"
                onClick={() => dispatch(PlayerActions.loadItem(song.externalId, `${song.name}${song.version ? ` ${song.version}` : ''}`))}
            >
                <PlayIcon />
            </IconButton>

            <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                {startTime}
            </Typography>

            <Typography weight={500} size={11} color="cello" className={classes.length}>
                {song.formattedLength}
            </Typography>

            <Typography size={11} color="cello" className={classes.introLength}>
                {song.formattedIntroLength}
            </Typography>

            <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                <span>
                    {song.name}
                </span>

                {song.version && (
                    <span>
                        &nbsp;[{song.version}]
                    </span>
                )}
            </Typography>
        </div>
    );
};

Song.propTypes = {
    songKey: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
};
