import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from './Button';
import classes from './TextButton.module.scss';
import {COLOR_NAMES} from '../../utils/color-names';

export const TextButton = React.forwardRef(({children, className, color, hoverColor, ...rest}, ref) => {
    return (
        <Button
            className={clsx(
                classes.textButton,
                className,
                classes[`color-${color}`],
                classes[`hover-color-${hoverColor}`],
            )}
            ref={ref}
            {...rest}
        >
            {children}
        </Button>
    );
});

TextButton.propTypes = {
    ...Button.propTypes,
    className: Button.propTypes.className,
    color: PropTypes.oneOf(COLOR_NAMES),
    hoverColor: PropTypes.oneOf(COLOR_NAMES),
};

TextButton.defaultProps = {
    ...Button.defaultProps,
    className: Button.defaultProps.className,
    color: 'white',
    hoverColor: 'radiance',
};
