import BaseAudioNode from './BaseAudioNode';

class NextElementAudioNode extends BaseAudioNode {
    /**
     * Converts introLength value to seconds if available
     * Returns zero if not
     *
     * @returns {number}
     */
    #getIntroLengthInSeconds = () => {
        return super.audioObject && super.audioObject.introLength > 0 ? super.audioObject.introLength / 1000 : 0;
    };

    /**
     * Calculates total playing length of node in different scenarios
     *
     * @returns {number}
     */
    getDuration() {
        const introLength = this.#getIntroLengthInSeconds();

        if (super.didEnd()) {
            return 0;
        }

        if (!super.isPlaying) {
            if (introLength) {
                return introLength - super.offset;
            }

            return super.duration;
        }

        if (this.getCountdownDirection() === 'negative' && introLength) {
            return introLength - super.offset;
        }

        return super.duration;
    }

    /**
     * Calculates passed (playing) time of node in different scenarios
     *
     * @returns {number}
     */
    getPassedTime() {
        const introLength = this.#getIntroLengthInSeconds();

        if (super.didEnd()) {
            return 0;
        }

        if (!super.isPlaying) {
            if (introLength) {
                return introLength - super.offset;
            }

            return super.offset;
        }

        if (this.getCountdownDirection() === 'negative') {
            return introLength - super.offset - super.audioContext.currentTime + super.lastStartedAt();
        }

        return super.audioContext.currentTime - super.lastStartedAt() + super.offset;
    }

    /**
     * Determines if we need to count from negative value to zero, or from zero to positive value.
     *
     * @returns {string}
     */
    getCountdownDirection() {
        const introLength = this.#getIntroLengthInSeconds();

        if (introLength && super.calculatePlayingTime() <= introLength) {
            return 'negative';
        }

        return 'positive';
    }
}

export default NextElementAudioNode;
