import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import classes from './Recorder.module.scss';
import {Button, Typography} from '../../../components';
import {CheckIcon, MicrophoneIcon} from '../../../icons';
import {formatMillisecondsAsTimestamp} from '../../../utils';
import {recorder} from '../recorder';
import {RECORDER_STATUS} from '../recorder.constants';

export const Recorder = function ({onClose, /* onPrevious, onNext , */ onUpload}) {
    const [status, setStatus] = useState(null);
    const [isProcessing, setIsProcessing] = useState(null);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const unsubscribeStatus = recorder.subscribe('status', ({status}) => {
            setStatus(status);
        });

        const unsubscribeProcessing = recorder.subscribe('isProcessing', ({isProcessing}) => {
            setIsProcessing(isProcessing);
        });

        const unsubscribeDuration = recorder.subscribe('duration', ({duration}) => {
            setDuration(duration);
        });

        return () => {
            unsubscribeStatus();
            unsubscribeProcessing();
            unsubscribeDuration();
        };
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.actions}>
                {/* <IconButton */}
                {/*     backgroundColor="radiance" */}
                {/*     color="white" */}
                {/*     onClick={onPrevious} */}
                {/* > */}
                {/*     <ChevronLeft /> */}
                {/* </IconButton> */}

                <Button
                    className={clsx(classes.mainButton, {
                        [classes.monza]: status === RECORDER_STATUS.RECORDING,
                    })}
                    startIcon={status === RECORDER_STATUS.LOAD || status === RECORDER_STATUS.LOADED
                        ? <MicrophoneIcon />
                        : status === RECORDER_STATUS.RECORDING
                            ? <MicrophoneIcon />
                            : <CheckIcon />}
                    disabled={isProcessing}
                    onClick={async () => {
                        if (status === RECORDER_STATUS.LOAD) {
                            await recorder.init();
                            await recorder.start();
                        } else if (status === RECORDER_STATUS.RECORDING) {
                            await recorder.stop();
                        } else if (status === RECORDER_STATUS.RECORDING_READY) {
                            const blob = await recorder.getBlob();

                            onUpload(blob);

                            await recorder.clear();
                        }
                    }}
                >
                    {status === RECORDER_STATUS.LOAD || status === RECORDER_STATUS.LOADED
                        ? 'Start recording'
                        : status === RECORDER_STATUS.RECORDING
                            ? 'Stop recording'
                            : 'Complete task'}
                </Button>

                <Button
                    className={classes.resetButton}
                    disabled={isProcessing || status === RECORDER_STATUS.LOAD}
                    onClick={async () => {
                        await recorder.clear();
                    }}
                >
                    Reset
                </Button>

                <Typography className={classes.duration} color="white" size={40} hasGutters={false} hasLineHeight={false}>
                    {formatMillisecondsAsTimestamp(duration, 'mm:ss.S')}
                </Typography>

                {/* <IconButton */}
                {/*     backgroundColor="radiance" */}
                {/*     color="white" */}
                {/* > */}
                {/*     <PlayIcon /> */}
                {/* </IconButton> */}

                {/* <IconButton */}
                {/*     backgroundColor="radiance" */}
                {/*     color="white" */}
                {/*     onClick={onNext} */}
                {/* > */}
                {/*     <ChevronRight /> */}
                {/* </IconButton> */}

                {/* <IconButton */}
                {/*     backgroundColor="radiance" */}
                {/*     color="white" */}
                {/*     onClick={() => { */}
                {/*         onClose(); */}
                {/*     }} */}
                {/* > */}
                {/*     <CloseIcon /> */}
                {/* </IconButton> */}
            </div>
        </div>
    );
};

Recorder.propTypes = {
    onClose: PropTypes.func.isRequired,
    // onPrevious: PropTypes.func.isRequired,
    // onNext: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
};
