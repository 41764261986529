import {combineReducers} from 'redux';
import {
    LoginFormActionTypes,
    NewPasswordFormActionTypes,
    ResetPasswordFormActionTypes,
} from './auth.action-type';
import {createReducer} from '../../../utils';

const loginFormReducer = combineReducers({
    isLoading: createReducer(false, LoginFormActionTypes.SET_IS_LOADING),
});

const newPasswordFormReducer = combineReducers({
    isLoading: createReducer(false, NewPasswordFormActionTypes.SET_IS_LOADING),
});

const resetPasswordFormReducer = combineReducers({
    isLoading: createReducer(false, ResetPasswordFormActionTypes.SET_IS_LOADING),
    step: createReducer(1, ResetPasswordFormActionTypes.SET_STEP),
    email: createReducer(null, ResetPasswordFormActionTypes.SET_EMAIL),
});

export const authReducer = combineReducers({
    loginForm: loginFormReducer,
    newPasswordForm: newPasswordFormReducer,
    resetPasswordForm: resetPasswordFormReducer,
});
