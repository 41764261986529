import React from 'react';
import {CONFIG} from './config';
import {logTailService} from './features/log-tail/log-tail.service';

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    async componentDidCatch(error, errorInfo) {
        await logTailService.error('UI ERROR: ', {
            ...error,
            errorInfo,
            version: CONFIG.APP_VERSION,
        });
    }

    render() {
        if (this.state.hasError) {
            return <p>Something went wrong! Try reloading the page.</p>;
        }

        return this.props.children;
    }
}

export {ErrorBoundary};
