import clsx from 'clsx';
import {forwardRef, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import classes from './MainButton.module.scss';
import {Button, Typography} from '../../../../components';
import {SUBSCRIPTION_TYPES} from '../../../../constants';
import recordingAlgorithm from '../../../../features/recording-algorithm/RecordingAlgorithm';
import {RecordingAlgorithmActions} from '../../../../features/recording-algorithm/store/recording-algorithm.action';

export const MainButton = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [IconComponent, setIconComponent] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [isDisabled, setIsDisabled] = useState('');
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        recordingAlgorithm.subscribe(data => {
            setBackgroundColor(data.color);
            setIconComponent(data.icon);
            setIsDisabled(data.isDisabled);
            setSubtitle(data.subtitle);
            setTitle(data.title);
        }, SUBSCRIPTION_TYPES.MASTER_BUTTON);
    }, []);

    return (
        <Button
            className={clsx(classes.root, classes[`background-color-${backgroundColor}`])}
            disabled={isDisabled}
            ref={ref}
            onClick={() => {
                dispatch(RecordingAlgorithmActions.executeNextCommand());
            }}
        >
            <Typography className={classes.subtitle} size={11} weight={500} transform="uppercase" color="white">
                {subtitle}
            </Typography>

            <div className={classes.title}>
                {IconComponent && <IconComponent className={classes.icon} />}

                <Typography weight={500} color="white" size={18} transform="uppercase">
                    {title}
                </Typography>
            </div>
        </Button>
    );
});
