import {createSelector} from 'reselect';

const selectMixingPlan = state => state.mixingPlan.object;

const selectOriginalMixingPlan = state => state.mixingPlan.originalObject;

const selectIsEditing = state => !!state.mixingPlan.originalObject;

const createHasMixingPlanChangesSelector = () => createSelector(
    [selectMixingPlan, selectOriginalMixingPlan],
    (mixingPlan, originalMixingPlan) => {
        if (!mixingPlan) {
            return true;
        }

        if (!originalMixingPlan) {
            return true;
        }

        return mixingPlan.recordLength !== originalMixingPlan.recordLength
            || JSON.stringify(mixingPlan.elements) !== JSON.stringify(originalMixingPlan.elements);
    },
);

export const MixingPlanSelectors = {
    selectMixingPlan,
    selectOriginalMixingPlan,
    selectIsEditing,
    createHasMixingPlanChangesSelector,
};
