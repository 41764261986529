import {createSelector} from 'reselect';
import {isMatchingListTask} from '../util/is-matching-task';

const selectEntities = state => state.playlist.entities;

const selectShowKeys = state => state.playlist.entities.showKeys;

const selectShows = state => state.playlist.entities.shows;

const selectViews = state => state.playlist.entities.views;

const selectAdvertisingSlots = state => state.playlist.entities.advertisingSlots;

const selectEntries = state => state.playlist.entities.entries;

const selectSongs = state => state.playlist.entities.songs;

const selectAutoverpackungs = state => state.playlist.entities.autoverpackungs;

const selectBlocks = state => state.playlist.entities.blocks;

const selectBlockPrograms = state => state.playlist.entities.blockPrograms;

const selectBlockProgramItems = state => state.playlist.entities.blockProgramItems;

const selectSplitPrograms = state => state.playlist.entities.splitPrograms;

const selectSplitProgramItems = state => state.playlist.entities.splitProgramItems;

const selectDdsItems = state => state.playlist.entities.ddsItems;

const selectGenericTasks = state => state.playlist.entities.genericTasks;

const selectDdsFilters = state => state.playlist.entities.ddsFilters;

const selectTasks = state => state.playlist.entities.tasks;

const selectFirstPlanningFilter = state => state.playlist.firstPlanningFilter;

const selectRole = state => state.playlist.role;

const selectShowContent = state => state.playlist.showContent;

const selectShowStructured = state => state.playlist.showStructured;

const selectShowMusic = state => state.playlist.showMusic;

const selectShowOnlyMatches = state => state.playlist.showOnlyMatches;

const selectSearchTerm = state => state.playlist.searchTerm;

const createShowByKeySelector = key => createSelector(
    [selectShows],
    entities => entities[key],
);

const createViewByKeySelector = key => createSelector(
    [selectViews],
    entities => entities[key],
);

const createEntryByKeySelector = key => createSelector(
    [selectEntries],
    entities => entities[key],
);

const createSongByKeySelector = key => createSelector(
    [selectSongs],
    entities => entities[key],
);

const createAdvertisingSlotByKeySelector = key => createSelector(
    [selectAdvertisingSlots],
    entities => entities[key],
);

const createAutoverpackungByKeySelector = key => createSelector(
    [selectAutoverpackungs],
    entities => entities[key],
);

const createBlockByKeySelector = key => createSelector(
    [selectBlocks],
    entities => entities[key],
);

const createBlockProgramByKeySelector = key => createSelector(
    [selectBlockPrograms],
    entities => entities[key],
);

const createSplitProgramsByKeySelector = keys => createSelector(
    [selectSplitPrograms],
    entities => keys.reduce((accumulator, currentKey) => {
        return [...accumulator, entities[currentKey]];
    }, []),
);

const createBlockProgramItemsByKeysSelector = keys => createSelector(
    [selectBlockProgramItems],
    entities => keys.reduce((accumulator, currentKey) => {
        return [...accumulator, entities[currentKey]];
    }, []),
);

const createTaskByKeySelector = key => createSelector(
    [selectTasks],
    entities => entities[key],
);

const createSplitProgramByKeySelector = key => createSelector(
    [selectSplitPrograms],
    entities => entities[key],
);

const createSplitProgramItemsByKeysSelector = keys => createSelector(
    [selectSplitProgramItems],
    entities => keys.reduce((accumulator, currentKey) => {
        return [...accumulator, entities[currentKey]];
    }, []),
);

const createDdsItemByKeySelector = key => createSelector(
    [selectDdsItems],
    entities => entities[key],
);

const createGenericTaskByKeySelector = key => createSelector(
    [selectGenericTasks],
    entities => entities[key],
);

const createDdsFilterByKeySelector = key => createSelector(
    [selectDdsFilters],
    entities => entities[key],
);

const createTaskKeysSelector = () => createSelector(
    [selectTasks],
    entities => {
        const taskKeys = Object.keys(entities);

        return taskKeys.filter(taskKey => {
            const task = entities[taskKey];

            return task.isCurrentUserTask;
        });
    },
);

const createTaskListKeysSelector = () => createSelector(
    [selectTasks, selectShowStructured, selectRole, selectSearchTerm, selectFirstPlanningFilter],
    (entities, showStructured, roleId, searchTerm, firstPlanningFilter) => {
        const taskKeys = Object.keys(entities);

        if (!showStructured) {
            return taskKeys.filter(taskKey => {
                const task = entities[taskKey];

                return isMatchingListTask(roleId, searchTerm, firstPlanningFilter, task);
            });
        }

        return taskKeys;
    },
);

const createHostRoleDropdownSelector = () => createSelector(
    [selectTasks],
    entities => {
        return Object.values(entities).reduce((accumulator, current) => {
            if (!current.hostRole || !current.isCurrentUserTask) {
                return accumulator;
            }

            const isAlreadyIncluded = accumulator.find(role => role.id === current.hostRole.id);

            if (!isAlreadyIncluded) {
                accumulator = [
                    ...accumulator,
                    current.hostRole,
                ];
            }

            return accumulator;
        }, []);
    },
);

const createFirstPlanningFilterDropdownSelector = () => createSelector(
    [selectTasks],
    entities => {
        return Object.values(entities).reduce((accumulator, current) => {
            if (!current.firstPlanningFilter || !current.isCurrentUserTask) {
                return accumulator;
            }

            const isAlreadyIncluded = accumulator.find(firstPlanningFilter => {
                return firstPlanningFilter.id === current.firstPlanningFilter;
            });

            if (!isAlreadyIncluded) {
                accumulator = [
                    ...accumulator,
                    {
                        id: current.firstPlanningFilter,
                        name: current.firstPlanningFilter,
                    },
                ];
            }

            return accumulator;
        }, []);
    },
);

export const PlaylistSelectors = {
    createAdvertisingSlotByKeySelector,
    createAutoverpackungByKeySelector,
    createBlockByKeySelector,
    createBlockProgramByKeySelector,
    createBlockProgramItemsByKeysSelector,
    createDdsFilterByKeySelector,
    createDdsItemByKeySelector,
    createEntryByKeySelector,
    createFirstPlanningFilterDropdownSelector,
    createGenericTaskByKeySelector,
    createHostRoleDropdownSelector,
    createShowByKeySelector,
    createSongByKeySelector,
    createSplitProgramByKeySelector,
    createSplitProgramItemsByKeysSelector,
    createSplitProgramsByKeySelector,
    createTaskByKeySelector,
    createTaskKeysSelector,
    createTaskListKeysSelector,
    createViewByKeySelector,
    selectAdvertisingSlots,
    selectAutoverpackungs,
    selectBlockProgramItems,
    selectBlockPrograms,
    selectBlocks,
    selectDdsFilters,
    selectDdsItems,
    selectEntities,
    selectEntries,
    selectFirstPlanningFilter,
    selectGenericTasks,
    selectRole,
    selectSearchTerm,
    selectShowContent,
    selectShowKeys,
    selectShowMusic,
    selectShowOnlyMatches,
    selectShows,
    selectShowStructured,
    selectSongs,
    selectSplitProgramItems,
    selectSplitPrograms,
    selectTasks,
    selectViews,
};
