import PropTypes from 'prop-types';
import React from 'react';
import classes from './ActionButton.module.scss';
import {Button} from '../../../../components';

export const ActionButton = ({icon, children, ...rest}) => {
    return (
        <Button className={classes.root} {...rest}>
            {React.cloneElement(icon, {className: classes.icon})}

            {children}
        </Button>
    );
};

ActionButton.propTypes = {
    icon: PropTypes.element.isRequired,
};
