import * as icons from '../../icons';

export const Icons = () => {
    return (
        <div
            style={{
                display: 'grid',
                width: '100%',
                margin: '0 auto',
                padding: 50,
                gridTemplateColumns: 'repeat(auto-fill, 10%)',
                justifyContent: 'center',
                gridGap: 50,
            }}
        >
            {Object.keys(icons).map(iconName => {
                if (iconName === 'SvgIcon') {
                    return null;
                }

                const Icon = icons[iconName];

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                        key={iconName}
                    >
                        <p style={{fontSize: 12}}>
                            {iconName}
                        </p>

                        <Icon size={24} />
                    </div>
                );
            })}
        </div>
    );
};
