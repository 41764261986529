import clsx from 'clsx';
import classes from './Switch.module.scss';
import {Typography} from '../Typography/Typography';

// eslint-disable-next-line react/prop-types
export const Switch = ({isOn, label, id, onChange, isDisabled}) => {
    return (
        <div
            className={clsx(classes.root, {
                [classes.isOn]: isOn,
                [classes.isDisabled]: isDisabled,
            })}
        >
            <input
                className={classes.checkbox}
                type="checkbox"
                id={id}
                checked={isOn}
                disabled={isDisabled}
                onChange={event => {
                    onChange(event.target.checked);
                }}
            />

            <Typography
                className={classes.label}
                htmlFor={id}
                variant="label"
                color={isOn ? 'radiance' : 'stone'}
                hasLineHeight={false}
                size={14}
                weight={500}
            >
                <div className={classes.switch}>
                    <div className={classes.track} />
                    <div className={classes.handle} />
                </div>

                {label}
            </Typography>
        </div>
    );
};
