import React from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const ClearIcon = React.forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path transform="translate(2)" d="M9,0 L1.8,0 C0.80588745,0 0,0.80588745 0,1.8 L0,16.2 C0,17.1941125 0.80588745,18 1.8,18 L12.6,18 C13.5941125,18 14.4,17.1941125 14.4,16.2 L14.4,5.4 L9,0 M12.6,16.2 L1.8,16.2 L1.8,1.8 L8.1,1.8 L8.1,6.3 L12.6,6.3 L12.6,16.2 Z" id="path-1" />
        </SvgIcon>
    );
});
