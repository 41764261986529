import {VOLUME_INDEX_KEYS, VOLUME_LEVELS} from './constants';

export const getVolumeByIndex = (soundSettings, volumeIndex, audioType) => {
    const defaultVolume = VOLUME_LEVELS[volumeIndex] / 100;

    switch (audioType) {
        case 'preProduced':
            return soundSettings?.preProduced[VOLUME_INDEX_KEYS[volumeIndex]] ?? defaultVolume;
        case 'sfx':
            return soundSettings?.sfx[VOLUME_INDEX_KEYS[volumeIndex]] ?? defaultVolume;
        case 'bed':
            return soundSettings?.bed[VOLUME_INDEX_KEYS[volumeIndex]] ?? defaultVolume;
    }

    return defaultVolume;
};
