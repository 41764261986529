export const GenericTaskActionTypes = {
    STORE_GENERIC_PRODUCTION_ORDER_IDS: 'generic-task/STORE_GENERIC_PRODUCTION_ORDER_IDS',
    STORE_GENERIC_PRODUCTION_ORDERS: 'generic-task/STORE_GENERIC_PRODUCTION_ORDERS',
    STORE_GENERIC_TASKS: 'generic-task/STORE_GENERIC_TASKS',
    STORE_GENERIC_TASK_VARIATIONS: 'generic-task/STORE_GENERIC_TASK_VARIATIONS',
    SET_SELECTED_GENERIC_TASK_VARIATION_ID: 'generic-task/SET_SELECTED_GENERIC_TASK_VARIATION_ID',
    SET_IS_CONTROLLER_OPEN: 'generic-task/SET_IS_CONTROLLER_OPEN',
    SET_GENERIC_PRODUCTION_ORDER_EXPANSION: 'generic-task/SET_GENERIC_PRODUCTION_ORDER_EXPANSION',
    SET_GENERIC_TASK_EXPANSION: 'generic-task/SET_GENERIC_TASK_EXPANSION',
    GO_TO_PREVIOUS_GENERIC_TASK_VARIATION: 'generic-task/GO_TO_PREVIOUS_GENERIC_TASK_VARIATION',
    GO_TO_NEXT_GENERIC_TASK_VARIATION: 'generic-task/GO_TO_NEXT_GENERIC_TASK_VARIATION',
    SCHEDULE_UPLOAD: 'generic-task/SCHEDULE_UPLOAD',
    UPLOAD: 'generic-task/UPLOAD',
};
