import moment from 'moment';

export const getClosestDayInPast = (targetDay, targetTime) => {
    // Is time in the past or in the future?
    const isSpecificTimeInThePast = moment().isSameOrAfter(moment(targetTime, 'HH:mm'));
    const isSameDay = moment().isoWeekday() === parseInt(targetDay, 10);
    const isDayInThePastOfCurrentWeek = moment().isoWeekday() > parseInt(targetDay, 10);

    if (isSameDay) {
        // The desired day is the today's day, let's determine if the time is in the past or in the future
        if (isSpecificTimeInThePast) {
            // In the past - when in the past, use that time to calculate the unix timestamp
            return moment(targetTime, 'HH:mm').day(parseInt(targetDay, 10)).format('X');
        }

        return moment(targetTime, 'HH:mm').day(parseInt(targetDay, 10) - 7).format('X');
    }

    if (isDayInThePastOfCurrentWeek) {
        // Get the selected day to calculate the unix timestamp
        return moment(targetTime, 'HH:mm').day(parseInt(targetDay, 10)).format('X');
    }

    // Get the last week's day to calculate the unix timestamp
    return moment(targetTime, 'HH:mm').day(parseInt(targetDay, 10) - 7).format('X');
};
