import moment from 'moment';
import {AlternativeTask, Task} from './task.dto';
import {ddsPlanningApi} from '../../../lib/axios';

export const getTaskByIdRequest = taskId => {
    return ddsPlanningApi
        .get(`/v1/tasks/${taskId}`)
        .then(response => {
            if (response.data.isAlternative) {
                return AlternativeTask.fromDto(response.data);
            }

            return Task.fromDto(response.data);
        });
};

export const completeTaskVariationRequest = (
    taskVariationId,
    file,
    onUploadProgress,
    isAudioWrapped = false,
    syncOut,
) => {
    const formData = new FormData();

    formData.append('file[audioFile]', file);

    return ddsPlanningApi
        .post(`/v1/task-variation/${taskVariationId}/complete`, formData, {
            params: {
                isModerationCockpit: 1,
                isAudioWrapped,
                syncOut,
            },
            onUploadProgress,
        })
        .then(response => {
            if (response.data.isAlternative) {
                return AlternativeTask.fromDto(response.data);
            }

            return Task.fromDto(response.data);
        });
};

export const uploadRecordingsRequest = (taskVariationId, files, onUploadProgress) => {
    const formData = new FormData();

    files.forEach((file, index) => {
        formData.append(`files[${index}][audioFile]`, file);
    });

    return ddsPlanningApi
        .post(`/v1/task-variation/${taskVariationId}/upload-files`, formData, {
            params: {
                isModerationCockpit: 1,
            },
            onUploadProgress,
        })
        .then(response => response.data);
};

export const uploadMixingPlan = (taskVariationId, mixingPlan) => {
    return ddsPlanningApi
        .post(`/v1/task-variation/${taskVariationId}/complete-mixing-plan`, mixingPlan, {
            params: {
                isModerationCockpit: 1,
            },
        })
        .then(response => {
            if (response.data.isAlternative) {
                return AlternativeTask.fromDto(response.data);
            }

            return Task.fromDto(response.data);
        });
};

export const createAlternativeTaskRequest = (taskId, startDate, startTime, endDate, endTime) => {
    return ddsPlanningApi
        .post(`/v1/tasks/${taskId}/alternative`, {
            validFrom: moment(startDate).set({
                hours: moment(startTime, 'HH:mm').hours(),
                minutes: moment(startTime, 'HH:mm').minutes(),
                seconds: 0,
                milliseconds: 0,
            }),
            validUntil: moment(endDate).set({
                hours: moment(endTime, 'HH:mm').hours(),
                minutes: moment(endTime, 'HH:mm').minutes(),
                seconds: 0,
                milliseconds: 0,
            }),
        })
        .then(response => AlternativeTask.fromDto(response.data));
};

export const overrideExpirationDate = ({taskId, isOverridden, date}) => {
    return ddsPlanningApi
        .patch(`/v1/tasks/${taskId}/override-expiry-date`, {
            isOverridden,
            overriddenExpirationDate: date,
        }).then(response => Task.fromDto(response.data));
};

export const overrideValidityPeriod = ({taskId, startDate, startTime, endDate, endTime}) => {
    const validFromDate = moment(startDate).format('YYYY-MM-DD');
    const validUntilDate = moment(endDate).format('YYYY-MM-DD');

    const validFrom = `${validFromDate} ${startTime}:00`;
    const validUntil = `${validUntilDate} ${endTime}:00`;

    return ddsPlanningApi
        .patch(`/v1/tasks/${taskId}/alternative`, {
            validFrom,
            validUntil,
        }).then(response => AlternativeTask.fromDto(response.data));
};

