import {SvgIcon} from '../SvgIcon/SvgIcon';

export const VoiceLevelLowIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <polygon
                transform="translate(12, 12) rotate(-315) translate(-12, -12)"
                fill="#747f88"
                points="0 8.97 0 15.03 13.636 15.03 8.333 20.333 12 24 24 12 12 0 8.333 3.667 13.636 8.97"
            />
        </SvgIcon>
    );
};
