import PropTypes from 'prop-types';
import {useLayoutEffect, useRef, useState} from 'react';
import {useMatch, useResolvedPath, useSearchParams} from 'react-router-dom';
import classes from './Navigation.module.scss';
import {Link, Typography} from '../../components';
import {userSwitcher} from '../../features/auth/utils/switch';

const NavigationItem = ({title, path, onBecomingActive}) => {
    const resolvedPath = useResolvedPath(path);
    const match = useMatch({path: resolvedPath.pathname, end: true});
    const ref = useRef(null);
    const [searchParams] = useSearchParams();

    useLayoutEffect(() => {
        if (match && ref.current) {
            onBecomingActive(ref.current);
        }
    });

    let to = path;

    if (userSwitcher.getIsSwitching()) {
        to = {
            pathname: path,
            search: '?' + searchParams.toString(),
        };
    }

    return (
        <Link to={to} ref={ref} data-is-active={!!match}>
            <div className={classes.navigationItemRoot}>
                <Typography weight={500} color="white" size={14}>
                    {title}
                </Typography>
            </div>
        </Link>
    );
};

NavigationItem.propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    onBecomingActive: PropTypes.func.isRequired,
};

const NAVIGATION_ITEMS = [{
    title: 'Home page',
    path: '/',
}, {
    title: 'Time announcements',
    path: '/time-announcements',
}];

export const Navigation = () => {
    const [activeItem, setActiveItem] = useState(null);

    const width = activeItem ? activeItem.offsetWidth : 0;
    const left = activeItem ? activeItem.offsetLeft : 0;

    return (
        <ul className={classes.root}>
            {NAVIGATION_ITEMS.map((item, index) => (
                <NavigationItem
                    key={item.path}
                    title={item.title}
                    path={item.path}
                    onBecomingActive={element => {
                        setActiveItem(element);
                    }}
                />
            ))}

            {width && left && (
                <div
                    className={classes.track}
                    style={{
                        width,
                        left,
                    }}
                />
            )}
        </ul>
    );
};
