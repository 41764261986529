import {useSelector} from 'react-redux';
import {GenericProductionOrder} from './GenericProductionOrder';
import classes from './GenericProductionOrders.module.scss';
import {GenericTaskSelectors} from '../store/generic-task.selector';

export const GenericProductionOrders = () => {
    const genericProductionOrderIds = useSelector(GenericTaskSelectors.selectGenericProductionOrderIds);

    return (
        <div className={classes.root}>
            <ul className={classes.list}>
                {genericProductionOrderIds.map(id => {
                    return (
                        <li key={id}>
                            <GenericProductionOrder id={id} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
