import {push} from 'redux-first-history';
import {put, spawn} from 'redux-saga/effects';
import {CONFIG} from './config';
import {ROUTE_PATHS} from './config/route-paths';
import {logTailService} from './features/log-tail/log-tail.service';
import {Debug} from './lib/debug';
import {toastInstance} from './lib/toast';

export const handleSagaError = function* (error, callKey) {
    if (error?.response?.status) {
        switch (error.response.status) {
            case 500:
                Debug.error('handleSagaError', 'Unexpected server: ', {error});
                // TODO: Add redirect
                break;
            case 404:
                Debug.error('handleSagaError', 'Not found: ', {error});
                yield put(push(ROUTE_PATHS.NOT_FOUND));
                break;
            case 401:
                Debug.error('handleSagaError', 'Unauthorized: ', {error});
                // TODO: Log out
                // TODO: Add redirect
                break;
            case 403:
                Debug.error('handleSagaError', 'Forbidden: ', {error});
                // TODO: Add redirect
                break;
            default:
                Debug.error('handleSagaError', 'Unhandled: ', {error});
                // TODO: Find a way to log this somewhere
                break;
        }
    }

    // eslint-disable-next-line no-underscore-dangle
    const apiErrorType = error?.name;

    if (apiErrorType) {
        switch (apiErrorType) {
            case 'NotAuthorizedException':
            case 'UserNotFoundException':
                toastInstance.error('featuresAuth:notifications.invalidCredentials');
                return;
            default:
                break;
        }
    }

    yield spawn([logTailService, logTailService.error], 'ERROR: ', {
        error: Object.keys(error).length
            ? {...error}
            : error?.hasOwnProperty('toJSON') && typeof error.toJSON === 'function'
                ? error.toJSON()
                : {
                    message: 'Unknown error occurred.',
                },
        request: error?.request || {},
        response: error?.response || {},
        logKey: 'handleSagaError',
        callKey,
        version: CONFIG.APP_VERSION,
    });

    Debug.error('handleSagaError', 'Unhandled: ', {error});
};
