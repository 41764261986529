export const TaskActionTypes = {
    STORE_CURRENT_TASK: 'task/currentTask/STORE_CURRENT_TASK',
    COMPLETE_TASK_VARIATION: 'task/COMPLETE_TASK_VARIATION',
    SET_UPLOAD_PROGRESS: 'task/SET_UPLOAD_PROGRESS',
    TASK_VARIATION_COMPLETED: 'task/TASK_VARIATION_COMPLETED',
    CREATE_ALTERNATIVE_TASK: 'task/CREATE_ALTERNATIVE_TASK',
    SET_ALTERNATIVE_TASK_TARGET_TASK_ID: 'task/SET_ALTERNATIVE_TASK_TARGET_TASK_ID',
    GO_TO_NEXT_JOB: 'task/GO_TO_NEXT_JOB',
    OVERRIDE_EXPIRATION_DATE: 'task/OVERRIDE_EXPIRATION_DATE',
    OVERRIDE_VALIDITY_PERIOD: 'task/OVERRIDE_VALIDITY_PERIOD',
    UPDATE_MIXING_PLAN: 'task/UPDATE_MIXING_PLAN',
};
