import {call, put} from 'redux-saga/effects';
import {getTaskOverview} from './task-overview.saga';
import {LOADING_STATES} from '../../../utils';
import {LOADING_TYPES, LoadingActions} from '../../loading';

export const loadTaskOverview = function* () {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.TASK_OVERVIEW, LOADING_STATES.LOADING));

    yield call(getTaskOverview);

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.TASK_OVERVIEW, LOADING_STATES.LOADING_DONE));
};
