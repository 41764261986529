/**
 * Map of volume indexes and object keys in sound settings.
 * For example, volume index 0 is always mute. In the soundSettings.preProducedElement, there will be a key muteVolume
 * which holds a value of gain when the element is muted.
 * Same logic applies to lowVolume, normalVolume and loudVolume.
 *
 * @type {{0: string, 1: string, 2: string, 3: string}}
 */
export const VOLUME_INDEX_KEYS = {
    0: 'muteVolume',
    1: 'lowVolume',
    2: 'normalVolume',
    3: 'loudVolume',
};

// TODO: Fetch this from API.
// API is not ready yet. Refer to ticket: PLG-584
// Can't even remember what happened to this one... TODO: Check it sometime...
export const VOLUME_LEVELS = {
    1: 60,
    2: 100,
    3: 120,
};
