import moment from 'moment';

const RESTRICTIONS_PRIORITY = {
    '#brand': 1,
    '#channel': 1,
    '#weekday': 1,
    '#dayPart': 1,
};

export const GenericProductionOrder = function GenericProductionOrder(dto) {
    this.id = dto.id;
    this.title = dto.title;
    this.genericTaskIds = dto.genericTaskIds;
    this.isExpanded = dto.isExpanded;
    this.restrictions = dto.restrictions;
    this.formattedRestrictions = dto.restrictions.sort((previousRestriction, nextRestriction) => {
        return RESTRICTIONS_PRIORITY[previousRestriction] - RESTRICTIONS_PRIORITY[nextRestriction];
    }).join(' / ');

    this.update = genericProductionOrder => {
        return new GenericProductionOrder({
            ...this,
            ...genericProductionOrder,
        });
    };
};

GenericProductionOrder.fromDto = dto => {
    const id = dto.id;
    const title = dto.title;
    const genericTaskIds = dto.genericTaskIds;
    const restrictions = dto.restrictions;

    return new GenericProductionOrder({
        id,
        title,
        genericTaskIds,
        restrictions,
    });
};

export const GenericTask = function GenericTask(dto) {
    this.id = dto.id;
    this.title = dto.title;
    this.genericTaskVariationIds = dto.genericTaskVariationIds;
    this.initialVoiceRange = dto.initialVoiceRange;
    this.finalVoiceRange = dto.finalVoiceRange;
    this.voiceMood = dto.voiceMood;
    this.isExpanded = dto.isExpanded;
    this.brand = dto.brand;
    this.channel = dto.channel;
    this.weekday = dto.weekday;
    this.dayPart = dto.dayPart;
    this.validUntil = moment(dto.validUntil);
    this.formattedValidUntil = this.validUntil.format('DD.MM.YYYY HH:mm');
    this.pendingVariationsCount = dto.pendingVariationsCount;

    this.update = genericTask => {
        return new GenericTask({
            ...this,
            ...genericTask,
        });
    };
};

GenericTask.fromDto = dto => {
    const id = dto.id;
    const title = dto.title;
    const genericTaskVariationIds = dto.genericTaskVariationIds;
    const initialVoiceRange = dto.initialVoiceRange;
    const finalVoiceRange = dto.finalVoiceRange;
    const voiceMood = dto.voiceMood;
    const brand = dto.brand;
    const channel = dto.channel;
    const weekday = dto.weekday;
    const dayPart = dto.dayPart;
    const validUntil = dto.validUntil;
    const pendingVariationsCount = dto.pendingVariationsCount;

    return new GenericTask({
        id,
        title,
        genericTaskVariationIds,
        initialVoiceRange,
        finalVoiceRange,
        voiceMood,
        brand,
        channel,
        weekday,
        dayPart,
        validUntil,
        pendingVariationsCount,
    });
};

export const GenericTaskVariation = function GenericTaskVariation(dto) {
    this.id = dto.id;
    this.title = dto.title;
    this.text = dto.text;
    this.status = dto.status;
    this.externalId = dto.externalId;
    this.genericTaskId = dto.genericTaskId;
    this.genericProductionOrderId = dto.genericProductionOrderId;
    this.previousGenericTaskVariationId = dto.previousGenericTaskVariationId;
    this.nextGenericTaskVariationId = dto.nextGenericTaskVariationId;

    this.update = genericTaskVariation => {
        return new GenericTaskVariation({
            ...this,
            ...genericTaskVariation,
        });
    };
};

GenericTaskVariation.fromDto = dto => {
    const id = dto.id;
    const title = dto.title;
    const text = dto.text;
    const status = dto.status;
    const externalId = dto.externalId;
    const genericTaskId = dto.genericTaskId;
    const genericProductionOrderId = dto.genericProductionOrderId;
    const previousGenericTaskVariationId = dto.previousGenericTaskVariationId;
    const nextGenericTaskVariationId = dto.nextGenericTaskVariationId;

    return new GenericTaskVariation({
        id,
        title,
        text,
        status,
        externalId,
        genericTaskId,
        genericProductionOrderId,
        previousGenericTaskVariationId,
        nextGenericTaskVariationId,
    });
};
