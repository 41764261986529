import Collapse from '@mui/material/Collapse';
import Slider from '@mui/material/Slider';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './Player.module.scss';
import {Container, IconButton, Typography} from '../../../../components';
import {CloseIcon, LoadingBars, MusicIcon, PauseIcon, PlayIcon} from '../../../../icons';
import {LOADING_TYPES, useIsLoading} from '../../../loading';
import {PlayerActions} from '../../store/player.action';
import {PlayerSelectors} from '../../store/player.selector';

const PlayerInner = () => {
    const [duration, setDuration] = useState(0);
    const [position, setPosition] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const isLoading = useIsLoading(LOADING_TYPES.PLAYER);
    const sources = useSelector(PlayerSelectors.selectSources);
    const title = useSelector(PlayerSelectors.selectTitle);

    const dispatch = useDispatch();

    const audioRef = useRef();

    const handleLoadedMetadata = event => {
        setDuration(event.currentTarget.duration);
    };

    const handleTimeUpdate = () => {
        if (isDragging) {
            return;
        }

        setPosition(audioRef.current.currentTime);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        setPosition(0);
    };

    useEffect(() => {
        if (!sources || !sources.length) {
            setPosition(0);
            setDuration(0);

            return;
        }

        audioRef.current.pause();
        setPosition(0);
        setDuration(0);
        audioRef.current.play();
    }, [sources]);

    return (
        <Container className={classes.container}>
            {!!sources.length && (
                <audio
                    crossOrigin="anonymous"
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onPause={handlePause}
                    onPlay={handlePlay}
                    onEnded={handleEnded}
                    ref={audioRef}
                >
                    {sources.map(source => (
                        <source key={source.url} src={source.url} type={source.type} />
                    ))}
                </audio>
            )}

            <IconButton
                color="white"
                backgroundColor="transparent"
                size={24}
                hoverStyle="color"
                disabled={isLoading}
                onClick={() => {
                    if (isPlaying) {
                        audioRef.current.pause();
                        return;
                    }

                    audioRef.current.play();
                }}
            >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </IconButton>

            <Typography className={classes.times} size={13} color="white" weight={500} variant="body">
                {moment.utc(position * 1000).format('mm:ss')}&nbsp;/&nbsp;{moment.utc(duration * 1000).format('mm:ss')}
            </Typography>

            <Slider
                size="small"
                valueLabelDisplay="off"
                min={0}
                max={duration || 1}
                step={1}
                value={position}
                sx={{
                    color: '#fff',
                }}
                disabled={isLoading}
                onChange={(_, value) => {
                    setIsDragging(true);
                    setPosition(value);
                }}
                onChangeCommitted={(_, value) => {
                    setIsDragging(false);
                    audioRef.current.currentTime = value;
                }}
            />

            <div className={classes.title}>
                {isLoading ? (
                    <LoadingBars size={18} color="white" />
                ) : (
                    <MusicIcon color="white" />
                )}

                <Typography className={classes.times} size={13} color="white" weight={500} variant="body" lineClamp={2}>
                    {isLoading ? 'Loading...' : title}
                </Typography>
            </div>

            <IconButton
                color="white"
                backgroundColor="transparent"
                size={24}
                hoverStyle="color"
                onClick={() => dispatch(PlayerActions.setIsOpen(false))}
            >
                <CloseIcon />
            </IconButton>
        </Container>
    );
};

export const Player = ({hasFixedPosition}) => {
    const isOpen = useSelector(PlayerSelectors.selectIsOpen);

    return (
        <Collapse in={isOpen} mountOnEnter unmountOnExit>
            <div
                className={clsx(classes.root, {
                    [classes.fixed]: hasFixedPosition,
                })}
            >
                <PlayerInner />
            </div>
        </Collapse>
    );
};

Player.propTypes = {
    hasFixedPosition: PropTypes.bool,
};

Player.defaultProps = {
    hasFixedPosition: true,
};
