import {createSelector} from 'reselect';

const selectEntities = state => state.preset.entities;

const selectLoadedPreProducedPresetId = state => state.preset.loadedPreProducedPresetId;

const selectLoadedSfxPresetId = state => state.preset.loadedSfxPresetId;

const selectLoadedBedPresetId = state => state.preset.loadedBedPresetId;

const createPresetsByTypeSelector = (audioType, shouldIncludeShared = true) => createSelector(
    [selectEntities],
    entities => {
        return Object.values(entities).filter(preset => {
            if (shouldIncludeShared) {
                return preset.audioType === audioType;
            }

            return preset.audioType === audioType && !preset.isSharedPreset;
        });
    },
);

const createDropdownOptionsByTypeSelector = audioType => createSelector(
    [selectEntities],
    entities => {
        return Object.values(entities)
            .filter(entity => entity.audioType === audioType)
            .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}));
    },
);

const createPresetsByIdSelector = presetId => createSelector(
    [selectEntities],
    entities => entities[presetId],
);

const createIsElementInLoadedPresetSelector = elementId => createSelector(
    [selectEntities, selectLoadedPreProducedPresetId, selectLoadedSfxPresetId, selectLoadedBedPresetId],
    (entities, loadedPreProducedPresetId, loadedSfxPresetId, loadedBedPresetId) => {
        let isLoaded = false;

        if (loadedPreProducedPresetId) {
            isLoaded = entities[loadedPreProducedPresetId].elementIds.includes(elementId);
        }

        if (!isLoaded && loadedSfxPresetId) {
            isLoaded = entities[loadedSfxPresetId].elementIds.includes(elementId);
        }

        if (!isLoaded && loadedBedPresetId) {
            isLoaded = entities[loadedBedPresetId].elementIds.includes(elementId);
        }

        return isLoaded;
    },
);

const createLoadedPresetIdByTypeSelector = presetType => createSelector(
    [selectLoadedPreProducedPresetId, selectLoadedSfxPresetId, selectLoadedBedPresetId],
    (loadedPreProducedPresetId, loadedSfxPresetId, loadedBedPresetId) => {
        if (presetType === 'preProduced') {
            return loadedPreProducedPresetId;
        } else if (presetType === 'sfx') {
            return loadedSfxPresetId;
        } else if (presetType === 'bed') {
            return loadedBedPresetId;
        }

        return null;
    },
);

export const PresetSelectors = {
    selectEntities,
    selectLoadedPreProducedPresetId,
    selectLoadedSfxPresetId,
    selectLoadedBedPresetId,
    createPresetsByTypeSelector,
    createDropdownOptionsByTypeSelector,
    createPresetsByIdSelector,
    createIsElementInLoadedPresetSelector,
    createLoadedPresetIdByTypeSelector,
};
