import {useTranslation} from 'react-i18next';
import classes from './NotFoundScreen.module.scss';

import {Link, PageHeader, PrimaryButton, Typography} from '../../../components';
import {ROUTE_PATHS} from '../../../config/route-paths';
import {cockpitWindowController} from '../../../features/window-manager';
import {ChevronLeft, CloseIcon} from '../../../icons';

export const NotFoundScreen = () => {
    const {t} = useTranslation('screensNotFound');

    const isCockpitWindow = cockpitWindowController.isCockpitWindow();

    return (
        <div className={classes.root}>
            <PageHeader subtitle={t('error')} align="center">
                404
            </PageHeader>

            <Typography variant="body" className={classes.text} align="center">
                {t('pageNotFound')}
            </Typography>

            {isCockpitWindow ? (
                <PrimaryButton startIcon={<CloseIcon />}>
                    {t('closeWindow')}
                </PrimaryButton>
            ) : (
                <Link to={ROUTE_PATHS.HOME}>
                    <PrimaryButton startIcon={<ChevronLeft />}>
                        {t('backToHome')}
                    </PrimaryButton>
                </Link>
            )}
        </div>
    );
};
