/* eslint no-underscore-dangle: 0 */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getNodeByKey} from 'lexical';
import {useEffect} from 'react';
import {HashtagNode} from '../nodes/HashtagNode';

export const HashtagMutationListener = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const removeHashtagMutationListener = editor.registerMutationListener(HashtagNode, nodes => {
            for (const [nodeKey, mutation] of nodes) {
                if (mutation === 'created') {
                    return;
                }

                if (mutation === 'updated') {
                    editor.update(() => {
                        const node = $getNodeByKey(nodeKey);

                        if (node.getLatest().__text !== node.getLatest().__originalText) {
                            node.getWritable().__text = node.getLatest().__originalText;
                        }
                    });
                }
            }
        });

        return () => {
            removeHashtagMutationListener();
        };
    }, [editor]);

    return null;
};
