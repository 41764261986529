import {SvgIcon} from '../SvgIcon/SvgIcon';

export const FolderIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path transform="translate(0 2)" d="M3.355 4.91l-1.719 6.545V3.273h13.91c0-.904-.733-1.637-1.637-1.637H8.182L6.545 0H1.636C.733 0 0 .733 0 1.636v9.819c0 .903.733 1.636 1.636 1.636H13.91c.736 0 1.391-.491 1.555-1.227l1.881-6.955H3.355zm10.554 6.545H3.273l1.309-4.91h10.636l-1.309 4.91z" />
        </SvgIcon>
    );
};

