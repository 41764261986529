import {call, put} from 'redux-saga/effects';
import {loadGenericTasks} from '../../../features/generic-task/store/generic-task.saga';
import {LOADING_TYPES, LoadingActions} from '../../../features/loading';

export const timeAnnouncementsScreenLoaderSaga = function* () {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.GENERIC_TASKS, true));

    yield call(loadGenericTasks);

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.GENERIC_TASKS, false));
};
