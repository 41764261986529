import BaseAudioNode from './BaseAudioNode';

class PreProducedAudioNode extends BaseAudioNode {
    /**
     * @type {number}
     */
    #skippedAt = 0;

    /**
     * @type {number}
     */
    #skippedDuration = 0;

    get skippedAt() {
        return this.#skippedAt;
    }

    // /**
    //  * @return {number}
    //  */
    // getPassedTime() {
    //     if (this.#skippedAt && this.isPlaying) {
    //         return super.audioContext.currentTime + super.offset - this.#skippedAt;
    //     }
    //
    //     if (!super.isPlaying || super.didEnd()) {
    //         return 0;
    //     }
    //
    //     return super.audioContext.currentTime - super.lastStartedAt();
    // }

    /**
     * @return {number}
     */
    getPassedTime() {
        if (!super.isPlaying) {
            return 0;
        }

        return super.audioContext.currentTime - super.lastStartedAt();
    }

    /**
     * @returns {number}
     */
    getSkippedDuration() {
        return this.#skippedDuration;
    }

    /**
     * Reconnects the nodes and skips the pre-produced audio to the cueIn + startNext - 3000
     */
    skip() {
        super.offset = (super.audioObject.cueIn + super.audioObject.startNext - 3000) / 1000;

        if (super.offset < 0) {
            super.offset = super.duration - 3;
        }

        this.#skippedAt = super.audioContext.currentTime;

        const buffer = super.sourceNode.buffer;

        super.sourceNode.stop();

        super.sourceNode = super.audioContext.createBufferSource();
        super.sourceNode.buffer = buffer;

        super.sourceNode.connect(super.gainNode);

        super.sourceNode.start(0, super.offset);

        super.isPlaying = true;

        const startedAt = super.startedAt[0];
        const skippedAt = this.#skippedAt;

        this.#skippedDuration = super.offset - (skippedAt - startedAt);
    }

    /**
     * @param offset
     */
    scheduleStart(offset) {
        super.play(offset);

        super.didPlayOnStart = true;
    }
}

export default PreProducedAudioNode;
