export const Preset = function (data) {
    this.id = data.id;
    this.name = data.name;
    this.audioType = data.audioType;
    this.brandId = data.brandId;
    this.elementIds = data.elementIds;
    this.hasUnsavedChanges = data.hasUnsavedChanges;
    this.isSharedPreset = data.isSharedPreset;

    this.set = function (data) {
        return new Preset({
            ...this,
            ...data,
        });
    };
};

Preset.fromDto = function (dto) {
    let audioType = null;

    switch (dto.type) {
        case 'pre-produced-elements':
            audioType = 'preProduced';
            break;
        case 'sound-effects':
            audioType = 'sfx';
            break;
        case 'music-bed':
            audioType = 'bed';
            break;
        default:
            break;
    }

    return new Preset({
        id: dto.id,
        name: dto.name,
        brandId: dto.brand.id,
        audioType,
        elementIds: dto.items,
        isSharedPreset: dto.isSharedPreset,
    });
};

export const PresetDto = function ({name, audioType, brandId, elementIds}) {
    this.brand = brandId;
    this.name = name;
    this.items = elementIds;

    switch (audioType) {
        case 'preProduced':
            this.type = 'pre-produced-elements';
            break;
        case 'sfx':
            this.type = 'sound-effects';
            break;
        case 'bed':
            this.type = 'music-bed';
            break;
        default:
            break;
    }
};
