import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './Input.module.scss';
import {Typography} from '../Typography/Typography';

export const Input = React.forwardRef((props, ref) => {
    const {startIcon, endIcon, loadingIcon, className, hasError, helperText, theme, ...rest} = props;

    return (
        <div
            className={clsx(classes.root, className, {
                [classes.withError]: hasError,
                [classes.dark]: theme === 'dark',
            })}
        >
            <div className={classes.inputWrapper}>
                {startIcon && React.cloneElement(startIcon, {
                    className: clsx(classes.startIcon, startIcon.props.className),
                })}

                <input
                    className={clsx(classes.input, {
                        [classes.withStartIcon]: !!startIcon,
                        [classes.withEndIcon]: !!endIcon,
                        [classes.withLoadingIcon]: !!loadingIcon,
                        [classes.withLoadingAndEndIcon]: !!loadingIcon && !!endIcon,
                        [classes.withError]: hasError,
                    })}
                    ref={ref}
                    {...rest}
                />

                {loadingIcon && React.cloneElement(loadingIcon, {
                    className: clsx(classes.loadingIcon, loadingIcon.props.className),
                })}

                {endIcon && React.cloneElement(endIcon, {className: clsx(classes.endIcon, endIcon.props.className)})}
            </div>

            {!!helperText && (
                <Typography className={classes.helperText} size={12} weight={500} variant="body">
                    {helperText}
                </Typography>
            )}
        </div>
    );
});

Input.propTypes = {
    className: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    loadingIcon: PropTypes.element,
    hasError: PropTypes.bool,
    helperText: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'dark']),
};

Input.defaultProps = {
    className: '',
    startIcon: null,
    endIcon: null,
    hasError: false,
    helperText: null,
    loadingIcon: null,
    theme: 'light',
};
