import {forwardRef} from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const SoundIcon = forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M16.5 5a.5.5 0 0 0-.5.5v7a.5.5 0 1 0 1 0v-7a.5.5 0 0 0-.5-.5zm-10 0a.5.5 0 0 0-.5.5v7a.5.5 0 1 0 1 0v-7a.5.5 0 0 0-.5-.5zm-4 2a.5.5 0 0 0-.5.5v3a.5.5 0 1 0 1 0v-3a.5.5 0 0 0-.5-.5zm12-4a.5.5 0 0 0-.5.5v11a.5.5 0 1 0 1 0v-11a.5.5 0 0 0-.5-.5zm-4 0a.5.5 0 0 0-.5.5v11a.5.5 0 1 0 1 0v-11a.5.5 0 0 0-.5-.5zm-6 0a.5.5 0 0 0-.5.5v11a.5.5 0 1 0 1 0v-11a.5.5 0 0 0-.5-.5zm8-2a.5.5 0 0 0-.5.5v15a.5.5 0 1 0 1 0v-15a.5.5 0 0 0-.5-.5zm-4 0a.5.5 0 0 0-.5.5v15a.5.5 0 1 0 1 0v-15a.5.5 0 0 0-.5-.5z" />
        </SvgIcon>
    );
});
