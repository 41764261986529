/* eslint no-underscore-dangle: 0 */
import {addClassNamesToElement} from '@lexical/utils';
import {DecoratorNode} from 'lexical';

export class HashtagNode extends DecoratorNode {
    /** @internal */
    __text;
    /** @internal */
    __originalText;

    constructor(text, originalText, key) {
        super(key);

        this.__text = text;
        this.__originalText = originalText || text;
    }

    static getType() {
        return 'hashtag';
    }

    static clone(node) {
        return new HashtagNode(node.__text, node.__originalText, node.__key);
    }

    createDOM(_config, _editor) {
        const element = document.createElement('span');

        addClassNamesToElement(element, _config.theme.hashtag);

        element.style.display = 'inline-block';

        return element;
    }

    updateDOM(_prevNode, _dom, _config) {
        return false;
    }

    isKeyboardSelectable() {
        return false;
    }

    isIsolated() {
        return false;
    }

    isInline() {
        return true;
    }

    isSelected() {
        return false;
    }

    remove(preserveEmptyParent) {
    }

    decorate(editor, config) {
        return (
            <span>
                {this.__text}
            </span>
        );
    }

    exportJSON() {
        return {
            type: this.__type,
            text: this.__text,
            originalText: this.__originalText,
        };
    }

    static importJSON(serializedNode) {
        return $createHashtagNode(serializedNode.text);
    }
}

export const $createHashtagNode = (text = '') => {
    return new HashtagNode(text);
};

export const $isHashtagNode = node => {
    return node instanceof HashtagNode;
};
