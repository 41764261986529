import Grow from '@mui/material/Grow';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './AccountMenu.module.scss';
import {Divider, PrimaryButton, TextButton, Typography} from '../../../../components';
import {useInitialAnimation} from '../../../../hooks';
import {DURATION, HOME_ANIMATION_DELAYS} from '../../../../utils/animations';
import {UserSelectors} from '../../../user';
import {AuthActions} from '../../store/auth.action';
import {userSwitcher} from '../../utils/switch';

export const AccountMenu = () => {
    const [buttonRef, setButtonRef] = useState(null);

    const user = useSelector(UserSelectors.selectUserEntity);
    const switchingUser = useSelector(UserSelectors.selectSwitchingUserEntity);

    const isSwitching = userSwitcher.getIsSwitching();

    const dispatch = useDispatch();

    const accountMenuAnimation = useInitialAnimation({delay: HOME_ANIMATION_DELAYS.ACCOUNT_MENU});

    return (
        <Grow in={accountMenuAnimation.isVisible && !!user && !!user.id} timeout={DURATION} mountOnEnter unmountOnExit>
            <div
                className={clsx(classes.root, {
                    [classes.quitSwitchingContainer]: isSwitching,
                })}
            >
                <Typography color="white" size={14}>
                    Signed in as&nbsp;
                </Typography>

                <TextButton color="radiance" hoverColor="white" className={classes.button} onClick={event => setButtonRef(event.currentTarget)}>
                    {switchingUser?.firstName || user?.firstName}
                </TextButton>

                {isSwitching && (
                    <div className={classes.quitSwitchingContainer}>
                        <PrimaryButton
                            className={classes.quitSwitchingButton}
                            onClick={() => {
                                window.location.href = window.location.origin + window.location.pathname;
                            }}
                        >
                            Quit impersonating
                        </PrimaryButton>
                    </div>
                )}

                <Popover
                    open={Boolean(buttonRef)}
                    anchorEl={buttonRef}
                    onClose={() => setButtonRef(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disablePortal
                    elevation={0}
                    PaperProps={{
                        sx: {
                            borderRadius: '5px',
                            boxShadow: '0 2px 10px 0 rgb(0 0 0 / 10%)',
                            border: '1px solid #e0e0e0',
                        },
                    }}
                >
                    <div className={classes.content}>
                        <Typography variant="body" color="dusty" size={13} hasGutters={false}>
                            Logged in as:
                        </Typography>

                        <Typography variant="body" color="stone" size={14} className={classes.name}>
                            {switchingUser?.getName() || user?.getName()}
                        </Typography>

                        <Typography variant="body" color="stone" size={14} hasGutters={false}>
                            {switchingUser?.email || user?.email}
                        </Typography>

                        <Divider className={classes.divider} backgroundColor="gainsboro" />

                        <PrimaryButton className={classes.actionButton} onClick={() => dispatch(AuthActions.logOut())}>
                            Log out
                        </PrimaryButton>
                    </div>
                </Popover>
            </div>
        </Grow>
    );
};
