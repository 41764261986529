import queryString from 'query-string';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {AudioItemActions} from './audio-item.action';
import {AudioItemActionTypes} from './audio-item.action-type';
import {AudioItemSelectors} from './audio-item.selector';
import {handleSagaError} from '../../../error.saga';
import {RouterSelector} from '../../../router.selector';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {AudioItemApi} from '../api/audio-item.api';

const ApiFunctionsByType = {
    preProduced: AudioItemApi.getPreProducedAudioItems,
    sfx: AudioItemApi.getSoundEffectAudioItems,
    bed: AudioItemApi.getBedAudioItems,
};

const getItemsFlow = function* ({type, brandId, searchTerm}) {
    try {
        const {ids, entities} = yield call(ApiFunctionsByType[type], {brandId, searchTerm});

        yield put(AudioItemActions.storeEntities(entities));
        yield put(AudioItemActions.storeIds(ids));
    } catch (error) {
        yield call(handleSagaError, error, 'getItemsFlow');
    }
};

export const getItemFlow = function* (itemId, elementType) {
    try {
        return yield call(AudioItemApi.getAudioItem, {itemId, elementType});
    } catch (error) {
        yield call(handleSagaError, error, 'getItemFlow');
    }
};

const getItemsWorker = function* ({payload}) {
    if (!payload.type) {
        return;
    }

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEMS, true));

    let brandId = null;

    if (payload.source === 'cockpit') {
        const {location} = yield select(RouterSelector.selectRouterState);
        const {search} = location;
        const queryParams = queryString.parse(search);

        brandId = parseInt(queryParams.brandId, 10);
    }

    yield call(getItemsFlow, {brandId, type: payload.type, searchTerm: payload.searchTerm});

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEMS, false));
};

export const loadEntities = function* (items) {
    const entities = yield select(AudioItemSelectors.selectEntities);

    // Filter out items that we already have
    const effects = items.filter(item => !entities[item.id]).map(item => call(getItemFlow, item.id, item.elementType));
    const audioItems = yield all(effects);

    yield put(AudioItemActions.storeEntities({
        ...entities,
        ...audioItems.reduce((accumulator, current) => {
            accumulator[current.id] = current;

            return accumulator;
        }, {}),
    }));
};

export const audioItemSaga = function* () {
    yield all([
        takeLatest(AudioItemActionTypes.GET_ITEMS, getItemsWorker),
    ]);
};
