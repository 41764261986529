import {combineReducers} from 'redux';
import {PreloadingActionTypes} from './preloading.action-type';
import {createReducer} from '../../../utils';
import {PRELOAD_WAIT_TIME, PreloadingStatuses} from '../utils/constants';

export const preloadingReducer = combineReducers({
    status: createReducer(PreloadingStatuses.WAITING, PreloadingActionTypes.SET_STATUS),
    progress: createReducer(0, PreloadingActionTypes.SET_PROGRESS),
    isAlertDisplayed: createReducer(false, PreloadingActionTypes.SET_IS_ALERT_DISPLAYED),
    remainingWaitTime: createReducer(PRELOAD_WAIT_TIME, PreloadingActionTypes.SET_REMAINING_WAIT_TIME),
});
