export const ModalKeys = {
    ADD_ELEMENT_MODAL: 'ADD_ELEMENT_MODAL',
    PRESET_DROPDOWN: 'PRESET_DROPDOWN',
    PROCESSING_TASK: 'PROCESSING_TASK',
    PROCESSING_COMPLETE: 'PROCESSING_COMPLETE',
    TASK_DELETED_MODAL: 'TASK_DELETED_MODAL',
    MISSING_DEVICE_SETTINGS: 'MISSING_DEVICE_SETTINGS',
    LOAD_PRESET: 'LOAD_PRESET',
    ALL_TASKS_COMPLETED: 'ALL_TASKS_COMPLETED',
    CROSS_CHANNEL_TASK_DIALOG: 'CROSS_CHANNEL_TASK_DIALOG',
};

export const FormKeys = {
    SAVE_PRESET: 'SAVE_PRESET',
};
