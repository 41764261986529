import React from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const ViewIcon = React.forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M9.86980471,0 L9.86980471,0.0180270406 L9.86980471,1.8207311 C13.8267401,2.30746119 16.6299449,5.90385578 16.1432148,9.86079119 C15.7285929,13.1417126 13.1507261,15.7466199 9.86980471,16.1342013 L9.86980471,17.9369054 C14.8272409,17.4411617 18.432649,13.0425638 17.9369054,8.08512769 C17.5312969,3.80370556 14.1331998,0.423635453 9.86980471,0 L9.86980471,0 Z M8.06710065,0.0270405608 C6.3094642,0.198297446 4.63294942,0.874311467 3.26289434,2.01001502 L4.55182774,3.34401602 C5.56134201,2.5327992 6.77816725,2.01001502 8.06710065,1.82974462 L8.06710065,0.0270405608 L8.06710065,0.0270405608 Z M1.99198798,3.28092138 C0.856284427,4.65097646 0.180270406,6.31847772 0,8.08512769 L1.80270406,8.08512769 C1.97396094,6.80520781 2.47871808,5.58838257 3.28092138,4.56985478 L1.99198798,3.28092138 L1.99198798,3.28092138 Z M0.00901352028,9.88783175 C0.189283926,11.6544817 0.883324987,13.321983 2.0010015,14.6920381 L3.28092138,13.4031047 C2.4877316,12.3845769 1.97396094,11.1677516 1.81171758,9.88783175 L0.00901352028,9.88783175 L0.00901352028,9.88783175 Z M4.55182774,14.7280921 L3.26289434,15.9629444 C4.6239359,17.098648 6.30045068,17.8107161 8.06710065,18 L8.06710065,16.1972959 C6.78718077,16.0350526 5.57035553,15.5212819 4.55182774,14.7280921 L4.55182774,14.7280921 Z M9.41912869,4.47971958 L9.41912869,9.21181773 L13.4752128,11.6184276 L12.7991988,12.7270906 L8.06710065,9.88783175 L8.06710065,4.47971958 L9.41912869,4.47971958 Z" />
        </SvgIcon>
    );
});
