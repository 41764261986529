import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import classes from './Elements.module.scss';
import {ElementsHeader} from './ElementsHeader';
import {CockpitSelectors} from '../../store/cockpit.selector';

export const Elements = React.forwardRef(({title, volume, children, audioType, onVolumeChange, size, listRef}, ref) => {
    const areElementsPopped = useSelector(CockpitSelectors.selectAreElementsPopped);

    return (
        <div
            className={clsx(classes.root, classes[`size-${size}`], {
                [classes.poppedOut]: areElementsPopped,
            })}
            ref={ref}
        >
            <ElementsHeader volume={volume} title={title} audioType={audioType} onVolumeChange={onVolumeChange} />

            <div className={classes.list} ref={listRef}>
                <div className={classes.innerListWrapper}>
                    <div className={classes.innerList}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
});

Elements.propTypes = {
    audioType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
    size: PropTypes.oneOf([40, 84]).isRequired,
    title: PropTypes.string.isRequired,
    volume: PropTypes.number.isRequired,
    onVolumeChange: PropTypes.func.isRequired,
    listRef: PropTypes.any,
};

Elements.defaultProps = {
    listRef: null,
};
