export const ALL_COMMANDS = {
    INIT: 'init',
    PLAY_PREVIOUS_ELEMENT: 'play-previous-element',
    DUCK_PREVIOUS_ELEMENT: 'duck-previous-element',
    STOP_PREVIOUS_ELEMENT: 'stop-previous-element',
    START_RECORDING: 'start-recording',
    STOP_RECORDING: 'stop-recording',
    PLAY_PRE_PRODUCED_AUDIO: 'play-pre-produced-audio',
    SKIP_PRE_PRODUCED_AUDIO: 'skip-pre-produced-audio',
    PLAY_NEXT_ELEMENT: 'play-next-element',
    FADE_IN_NEXT_ELEMENT: 'fade-in-next-element',
    STOP_NEXT_ELEMENT: 'stop-next-element',
    UPLOAD: 'upload',
    END: 'end',
};

export const FADE_OUT_VARS = {
    FADE_OUT_END_VOLUME: 0.000000000001,
};

/**
 * @enum {string}
 */
export const AUDIO_ELEMENT_TYPES = {
    BUMPER: 'bumper',
    STINGER: 'stinger',
    RECORDING: 'recording',
    PRE_PROD: 'preProduced',
    SOUND_EFFECT: 'sfx',
    MUSIC_BED: 'bed',
};

export const PRE_PRODUCED_AUDIO_FILE_NAME_PREFIX = 'pre_produced_';
export const SOUND_EFFECT_AUDIO_FILE_NAME_PREFIX = 'sound_effect_';
export const MUSIC_BED_AUDIO_FILE_NAME_PREFIX = 'music_bed_';
