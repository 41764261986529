import {FailedUploadsActionTypes} from './failed-uploads.action-type';

export const FailedUploadsActions = {
    setStatus: status => ({
        type: FailedUploadsActionTypes.SET_STATUS,
        payload: status,
    }),
    cancel: () => ({
        type: FailedUploadsActionTypes.CANCEL,
    }),
    retry: () => ({
        type: FailedUploadsActionTypes.RETRY,
    }),
    setProgress: progress => ({
        type: FailedUploadsActionTypes.SET_PROGRESS,
        payload: progress,
    }),
    storeFailedUploadTaskIds: taskIds => ({
        type: FailedUploadsActionTypes.STORE_FAILED_UPLOAD_TASK_IDS,
        payload: taskIds,
    }),
};
