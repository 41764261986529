import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {DateIcon} from '../../icons';
import {Input} from '../Input/Input';
import './react-datepicker.scss';

export const DatePicker = React.forwardRef(({id, value, InputProps, ...rest}, ref) => {
    return (
        <ReactDatePicker
            selected={value}
            id={id}
            customInput={<Input startIcon={<DateIcon />} {...InputProps} />}
            ref={ref}
            {...rest}
        />
    );
});

DatePicker.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
    InputProps: PropTypes.shape(Input.propTypes),
};

DatePicker.defaultProps = {
    InputProps: {},
};
