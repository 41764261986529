import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import classes from './LoginForm.module.scss';
import {Input, InputGroup, Link, LoadingButton} from '../../../../components';
import {ROUTE_PATHS} from '../../../../config/route-paths';
import {LoginFormActions} from '../../store/auth.action';
import {LoginFormSelectors} from '../../store/auth.selector';

export const LoginForm = () => {
    const {t} = useTranslation('featuresAuth');

    const schema = Yup.object().shape({
        email: Yup.string().required().email().label(t('labels.email')),
        password: Yup.string().required().label(t('labels.password')),
    });

    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = useCallback(data => {
        dispatch(LoginFormActions.submit(data.email, data.password));
    }, [dispatch]);

    const isLoading = useSelector(LoginFormSelectors.selectIsLoading);

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup hasGutterBottom>
                    <Controller
                        name="email"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.email')}
                                hasError={!!errors.email}
                                helperText={errors?.email?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <InputGroup>
                    <Controller
                        name="password"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.password')}
                                type="password"
                                hasError={!!errors.password}
                                helperText={errors?.password?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <div className={classes.forgotPasswordContainer}>
                    <Link to={ROUTE_PATHS.RESET_PASSWORD}>
                        {t('forgotPasswordLinkText')}
                    </Link>
                </div>

                <div className={classes.footer}>
                    <LoadingButton isSubmit isLoading={isLoading}>
                        {t('labels.logInButton')}
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};
