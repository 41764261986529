import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import clsx from 'clsx';
import {useState} from 'react';
import classes from './ChannelContainer.module.scss';
import {DateContainer} from './DateContainer';
import {IconButton, Typography} from '../../../components';
import {useInitialAnimation, useMemoizedCreateSelector} from '../../../hooks';
import {ChevronDown} from '../../../icons';
import {DURATION} from '../../../utils/animations';
import {TaskOverviewSelectors} from '../store/task-overview.selectors';

// eslint-disable-next-line react/prop-types
export const ChannelContainer = ({brandId, channelId, initialDelay}) => {
    const channel = useMemoizedCreateSelector(TaskOverviewSelectors.createChannelByIdSelector, channelId);
    const stats = useMemoizedCreateSelector(TaskOverviewSelectors.createConsolidatedStatsForChannel, channelId);

    const [isExpanded, setIsExpanded] = useState(false);

    const animation = useInitialAnimation({
        delay: initialDelay,
    });

    return (
        <Fade in={animation.isVisible} timeout={DURATION}>
            <div
                className={clsx(classes.root, {
                    [classes.expanded]: isExpanded,
                    [classes.hasUrgentTasks]: channel.hasUrgentTasks,
                })}
            >
                <div className={classes.header}>
                    <div className={classes.left}>
                        <Typography
                            weight={500}
                            size={14}
                            color="black"
                            hasLineHeight={false}
                            variant="body"
                            hasGutters={false}
                            className={classes.stats}
                        >
                            {channel.name}
                        </Typography>
                    </div>

                    <div className={classes.right}>
                        <Typography
                            weight={500}
                            size={14}
                            color="stone"
                            hasLineHeight={false}
                            variant="body"
                            hasGutters={false}
                            className={classes.stats}
                        >
                            {stats.completedCount} / {stats.count}
                        </Typography>

                        <IconButton
                            backgroundColor="transparent"
                            color="black"
                            size={22}
                            iconSize={12}
                            onClick={() => {
                                setIsExpanded(prevState => !prevState);
                            }}
                        >
                            <ChevronDown className={classes.expandIcon} size={14} />
                        </IconButton>
                    </div>
                </div>

                <Collapse in={isExpanded} unmountOnExit mountOnEnter>
                    <div className={classes.content}>
                        {channel.dates.map(dateId => (
                            <DateContainer
                                brandId={brandId}
                                channelId={channelId}
                                dateId={dateId}
                                key={dateId}
                            />
                        ))}
                    </div>
                </Collapse>
            </div>
        </Fade>
    );
};
