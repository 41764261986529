import {motion} from 'framer-motion';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classes from './ActionControls.module.scss';
import {Typography} from '../../../../components';
import {ConfirmationDialog} from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import {CurrentTaskSelectors} from '../../../../features/current-task';
import {useCanBePlayed} from '../../../../features/editor/hooks/use-can-be-played';
import {useIsPlaying} from '../../../../features/editor/hooks/use-is-playing';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {RecordingAlgorithmActions} from '../../../../features/recording-algorithm/store/recording-algorithm.action';
import {TaskSelectors} from '../../../../features/task';
import {UploadFileDialog} from '../../../../features/upload-file';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {DeleteIcon, EditIcon, PauseIcon, PlayIcon, SvgIcon, UploadIcon} from '../../../../icons';
import {useDisabledControls} from '../../hooks/use-disabled-controls';
import {CockpitActions} from '../../store/cockpit.action';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {MainContentComponents} from '../../utils/constants';
import {ActionButton} from '../ActionButton/ActionButton';

const ResetModeration = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('screensCockpit');

    const currentTaskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const currentTaskVariation = useMemoizedCreateSelector(
        TaskSelectors.createTaskVariationByIdSelector,
        currentTaskVariationId,
    );

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleSubmit = () => {
        dispatch(RecordingAlgorithmActions.reset());
    };

    const handleClick = () => {
        setIsDialogOpen(true);
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <ActionButton icon={<DeleteIcon />} onClick={handleClick} />

            <ConfirmationDialog
                confirm={t('confirmResetDialog.confirmButton')}
                onClose={handleClose}
                isOpen={isDialogOpen}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                title={t('confirmResetDialog.title')}
                message={t('confirmResetDialog.message')}
            >
                {currentTaskVariation?.isCompleted && (
                    <Typography variant="body" weight={500} size={13} color="monza">
                        {t('confirmResetDialog.moderationDeletionNote')}
                    </Typography>
                )}
            </ConfirmationDialog>
        </>
    );
};

const SwitchMainContent = () => {
    const dispatch = useDispatch();

    const mainContentComponent = useSelector(CockpitSelectors.selectMainContentComponent);

    const {areControlsDisabled} = useDisabledControls();
    const {canBePlayed} = useCanBePlayed();

    const handleClick = () => {
        if (mainContentComponent === MainContentComponents.MODERATION) {
            dispatch(CockpitActions.setMainContentComponent(MainContentComponents.EDITOR));
        } else if (mainContentComponent === MainContentComponents.EDITOR) {
            dispatch(CockpitActions.setMainContentComponent(MainContentComponents.MODERATION));
        }
    };

    return (
        <ActionButton
            icon={<EditIcon />}
            onClick={handleClick}
            disabled={!canBePlayed || (areControlsDisabled && canBePlayed)}
        />
    );
};

const PlayButton = () => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);
    const {isPlaying} = useIsPlaying();
    const {canBePlayed} = useCanBePlayed();
    const {areControlsDisabled} = useDisabledControls();

    const handleClick = () => {
        if (isPlaying) {
            dispatch(RecordingAlgorithmActions.pause());
            return;
        }

        dispatch(CockpitActions.setMainContentComponent(MainContentComponents.EDITOR));
        dispatch(RecordingAlgorithmActions.play());
    };

    return (
        <ActionButton
            icon={(
                <SvgIcon>
                    {isPlaying ? <PauseIcon key="paused" /> : <PlayIcon key="playing" />}
                </SvgIcon>
            )}
            onClick={handleClick}
            disabled={isLoading || !canBePlayed || (areControlsDisabled && canBePlayed)}
        />
    );
};

const OpenUploadFileDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    const taskId = useSelector(CurrentTaskSelectors.selectCurrentTaskId);
    const taskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const task = useSelector(TaskSelectors.selectCurrentTask);
    const {areControlsDisabled} = useDisabledControls();

    let expirationDate;

    if (task) {
        if (!task.isReusable) {
            expirationDate = task.scheduledAt;
        } else if (task.isExpirationDateOverridden) {
            expirationDate = task.overriddenExpirationDate;
        } else if (task.expirationDate) {
            expirationDate = task.expirationDate;
        }
    }

    return (
        <>
            <ActionButton icon={<UploadIcon />} onClick={() => setIsOpen(true)} disabled={areControlsDisabled} />

            <UploadFileDialog
                isOpen={isOpen}
                source="cockpit"
                taskId={taskId}
                taskVariationId={taskVariationId}
                expirationDate={expirationDate}
                onClose={() => {
                    setIsOpen(false);
                }}
            />
        </>
    );
};

export const ActionControls = () => {
    return (
        <div className={classes.root}>
            <motion.div
                key="button-1"
                initial={{opacity: 0, translateY: '70px'}}
                animate={{opacity: 1, translateY: 0}}
                transition={{duration: 0.50, delay: 4 * 0.05 + 0.05}}
            >
                <OpenUploadFileDialog />
            </motion.div>

            <motion.div
                key="button-2"
                initial={{opacity: 0, translateY: '70px'}}
                animate={{opacity: 1, translateY: 0}}
                transition={{duration: 0.50, delay: 5 * 0.05 + 0.05}}
            >
                <SwitchMainContent />
            </motion.div>

            <motion.div
                key="button-3"
                initial={{opacity: 0, translateY: '70px'}}
                animate={{opacity: 1, translateY: 0}}
                transition={{duration: 0.50, delay: 6 * 0.05 + 0.05}}
            >
                <PlayButton />
            </motion.div>

            <motion.div
                key="button-4"
                initial={{opacity: 0, translateY: '70px'}}
                animate={{opacity: 1, translateY: 0}}
                transition={{duration: 0.50, delay: 7 * 0.05 + 0.05}}
            >
                <ResetModeration />
            </motion.div>
        </div>
    );
};
