import {combineReducers} from 'redux';
import {PlaylistActionTypes} from './playlist.action-type';
import {NOT_SELECTED, createReducer} from '../../../utils';

const entitiesReducer = combineReducers({
    showKeys: createReducer([], PlaylistActionTypes.STORE_SHOW_KEYS),
    shows: createReducer({}, PlaylistActionTypes.STORE_SHOWS),
    views: createReducer({}, PlaylistActionTypes.STORE_VIEWS),
    entries: createReducer({}, PlaylistActionTypes.STORE_ENTRIES),
    songs: createReducer({}, PlaylistActionTypes.STORE_SONGS),
    autoverpackungs: createReducer({}, PlaylistActionTypes.STORE_AUTOVERPACKUNGS),
    blocks: createReducer({}, PlaylistActionTypes.STORE_BLOCKS),
    blockPrograms: createReducer({}, PlaylistActionTypes.STORE_BLOCK_PROGRAMS),
    blockProgramItems: createReducer({}, PlaylistActionTypes.STORE_BLOCK_PROGRAM_ITEMS),
    splitPrograms: createReducer({}, PlaylistActionTypes.STORE_SPLIT_PROGRAMS),
    splitProgramItems: createReducer({}, PlaylistActionTypes.STORE_SPLIT_PROGRAM_ITEMS),
    ddsItems: createReducer({}, PlaylistActionTypes.STORE_DDS_ITEMS),
    ddsFilters: createReducer({}, PlaylistActionTypes.STORE_DDS_FILTERS),
    tasks: createReducer({}, PlaylistActionTypes.STORE_TASKS),
    advertisingSlots: createReducer({}, PlaylistActionTypes.STORE_ADVERTISING_SLOTS),
    genericTasks: createReducer({}, PlaylistActionTypes.STORE_GENERIC_TASKS),
});

export const playlistReducer = combineReducers({
    entities: entitiesReducer,
    // Filter stuff
    firstPlanningFilter: createReducer(NOT_SELECTED, PlaylistActionTypes.SET_FIRST_PLANNING_FILTER),
    role: createReducer(NOT_SELECTED, PlaylistActionTypes.SET_ROLE),
    showContent: createReducer(true, PlaylistActionTypes.SET_SHOW_CONTENT),
    showStructured: createReducer(true, PlaylistActionTypes.SET_SHOW_STRUCTURED),
    showMusic: createReducer(true, PlaylistActionTypes.SET_SHOW_MUSIC),
    showOnlyMatches: createReducer(false, PlaylistActionTypes.SET_SHOW_ONLY_MATCHES),
    searchTerm: createReducer('', PlaylistActionTypes.SET_SEARCH_TERM),
});
