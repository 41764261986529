import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './SelectedElement.module.scss';
import {Typography} from '../../../components';

export const SelectedElement = ({nodeType, title}) => {
    return (
        <div
            className={clsx(classes.root, classes[`type-${nodeType}`])}
        >
            <div className={classes.line} />

            <Typography color="white" lineClamp={3} title={title} weight={500}>
                {title}
            </Typography>
        </div>
    );
};

SelectedElement.propTypes = {
    nodeType: PropTypes.oneOf(['preProduced', 'sfx', 'bed', 'recording']),
    title: PropTypes.string,
};

SelectedElement.defaultProps = {
    nodeType: null,
    title: '',
};
