import update from 'immutability-helper';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {CurrentTaskActions} from '../../../features/current-task';

export const useSortableElements = ({initialElements, elementType}) => {
    const dispatch = useDispatch();

    // For performance reasons - we need to copy elements to local state
    const [elements, setElements] = useState(initialElements);

    const handleEndDrag = useCallback(didDrop => {
        if (!didDrop) {
            setElements(initialElements);
            return;
        }

        dispatch(CurrentTaskActions.setOrderOfLoadedElements(elements, elementType));
    }, [dispatch, elements, elementType, initialElements]);

    const handleMoveElement = useCallback((dragIndex, dropIndex) => {
        setElements(prevCards => update(prevCards, {
            $splice: [
                [dragIndex, 1],
                [dropIndex, 0, prevCards[dragIndex]],
            ],
        }));
    }, []);

    useEffect(() => {
        setElements(initialElements);
    }, [initialElements, setElements]);

    return {
        elements,
        onEndDrag: handleEndDrag,
        onMoveElement: handleMoveElement,
    };
};
