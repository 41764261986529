import {forwardRef} from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const CogIcon = forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
            <path d="M15.443 9.873l1.9 1.494a.454.454 0 0 1 .107.576l-1.8 3.114a.452.452 0 0 1-.549.198l-2.24-.909c-.469.36-.955.657-1.522.891l-.333 2.385a.455.455 0 0 1-.45.378h-3.6a.455.455 0 0 1-.45-.378l-.333-2.385a6.25 6.25 0 0 1-1.52-.891l-2.242.909a.452.452 0 0 1-.549-.198l-1.8-3.114a.444.444 0 0 1 .108-.576l1.9-1.494L2.005 9l.063-.9L.17 6.633a.444.444 0 0 1-.108-.576l1.8-3.114a.442.442 0 0 1 .55-.198l2.24.9c.468-.351.954-.657 1.521-.882L6.506.378A.455.455 0 0 1 6.956 0h3.6c.225 0 .414.162.45.378l.333 2.385c.567.225 1.053.531 1.521.882l2.241-.9a.442.442 0 0 1 .55.198l1.8 3.114a.454.454 0 0 1-.109.576L15.443 8.1l.063.9-.063.873zM3.806 9c0 .522.081 1.017.225 1.494l-1.863 1.53.675 1.17 2.268-.846a4.933 4.933 0 0 0 2.565 1.485l.396 2.367h1.35l.396-2.367a4.946 4.946 0 0 0 2.583-1.476l2.26.846.674-1.17-1.863-1.53c.153-.477.234-.981.234-1.503a5.07 5.07 0 0 0-.225-1.485l1.854-1.521-.675-1.17-2.25.837a4.973 4.973 0 0 0-2.583-1.494L9.431 1.8h-1.35l-.396 2.367a4.973 4.973 0 0 0-2.583 1.494l-2.25-.846-.675 1.17 1.854 1.53A5.07 5.07 0 0 0 3.806 9zm4.95-3.15a3.15 3.15 0 1 1 0 6.3 3.15 3.15 0 0 1 0-6.3zm0 1.8a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7z" />
        </SvgIcon>
    );
});
