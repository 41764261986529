export const CONFIG = {
    AMPLIFY: {
        REGION: process.env.REACT_APP_AMPLIFY_REGION,
        USER_POOL_ID: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
        CLIENT_ID: process.env.REACT_APP_AMPLIFY_CLIENT_ID,
    },
    NODE_ENVIRONMENT: process.env.NODE_ENV,
    DDS_PLANNING: {
        API_URL: process.env.REACT_APP_DDS_PLANNING_API_URL,
        WEB_SOCKET_URL: process.env.REACT_APP_DDS_PLANNING_WEB_SOCKET_URL,
    },
    LOG_TAIL: {
        TOKEN: process.env.REACT_APP_LOG_TAIL_TOKEN,
    },
    FEATURES: {
        PRELOADING: String(process.env.REACT_APP_DISABLE_PRELOADING).toLowerCase() !== 'true',
    },
    APP_VERSION: process.env.REACT_APP_VERSION,
};
