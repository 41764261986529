/**
 * Parses a JWT token
 *
 * @param {string} token
 * @returns {Object} Parsed JWT token
 */
export const parseJsonWebToken = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    return JSON.parse(window.atob(base64));
};
