export const PreparedTaskVariationActionTypes = {
    LOAD_FOR_TASK_VARIATION: 'preparedTaskVariation/LOAD_FOR_TASK_VARIATION',
    STORE_ENTITIES: 'preparedTaskVariation/STORE_ENTITIES',
    UPDATE_SPEAKER_TEXT: 'preparedTaskVariation/UPDATE_SPEAKER_TEXT',
    SET_PREPARING_TASK_ID: 'preparedTaskVariation/SET_PREPARING_TASK_ID',
    SET_PREPARING_TASK_VARIATION_ID: 'preparedTaskVariation/SET_PREPARING_TASK_VARIATION_ID',
    SET_IS_EDITING_SPEAKER_TEXT: 'preparedTaskVariation/SET_IS_EDITING_SPEAKER_TEXT',
    SET_ORIGINAL_SPEAKER_TEXT: 'preparedTaskVariation/SET_ORIGINAL_SPEAKER_TEXT',
    ADD_ELEMENT: 'preparedTaskVariation/ADD_ELEMENT',
    REMOVE_ELEMENT: 'preparedTaskVariation/REMOVE_ELEMENT',
    STORE_AUDIO_ITEM_ENTITIES: 'preparedTaskVariation/STORE_AUDIO_ITEM_ENTITIES',
    SORT_ELEMENT: 'preparedTaskVariation/SORT_ELEMENT',
    SORT_ELEMENT_DROP: 'preparedTaskVariation/SORT_ELEMENT_DROP',
    LOAD_FROM_PRESET: 'preparedTaskVariation/LOAD_FROM_PRESET',
};
