import {AudioItem} from './audio-item.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const getPreProducedAudioItems = ({brandId, searchTerm}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/search`, {
            params: {
                brand: brandId,
                term: searchTerm,
            },
        })
        .then(result => {
            const entities = {};
            const ids = [];

            if (!result.data['Category']) {
                return {ids, entities};
            }

            const items = result.data['Category'].slice(0, 30);

            items.forEach(dto => {
                entities[dto.externalId] = new AudioItem(dto, 'preProduced');
                ids.push(dto.externalId);
            });

            return {ids, entities};
        });
};

const getSoundEffectAudioItems = ({brandId, searchTerm}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/search-moderation-cockpit`, {
            params: {
                brand: brandId,
                type: 'sfx',
                term: searchTerm,
            },
        })
        .then(result => {
            const entities = {};
            const ids = [];

            const items = result.data.slice(0, 30);

            items.forEach(dto => {
                entities[dto.externalId] = new AudioItem(dto, 'sfx');
                ids.push(dto.externalId);
            });

            return {ids, entities};
        });
};

const getBedAudioItems = ({brandId, searchTerm}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/search-moderation-cockpit`, {
            params: {
                brand: brandId,
                type: 'bed',
                term: searchTerm,
            },
        })
        .then(result => {
            const entities = {};
            const ids = [];

            const items = result.data.slice(0, 30);

            items.forEach(dto => {
                entities[dto.externalId] = new AudioItem(dto, 'bed');
                ids.push(dto.externalId);
            });

            return {ids, entities};
        });
};

const getAudioItem = ({itemId, elementType}) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/external/${itemId}`)
        .then(result => new AudioItem(result.data, elementType));
};

export const AudioItemApi = {
    getPreProducedAudioItems,
    getSoundEffectAudioItems,
    getBedAudioItems,
    getAudioItem,
};
