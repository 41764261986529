import Slide from '@mui/material/Slide';
import moment from 'moment';
import queryString from 'query-string';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useSearchParams} from 'react-router-dom';
import {push} from 'redux-first-history';
import classes from './TaskBar.module.scss';
import {IconButton, Typography} from '../../../../components';
import {ROUTE_PATHS} from '../../../../config/route-paths';
import {ChannelSelectors} from '../../../../features/channel';
import {CockpitNavigationSelectors} from '../../../../features/cockpit-navigation';
import {CurrentTaskSelectors} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {TaskSelectors} from '../../../../features/task';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useInitialAnimation, useMemoizedCreateSelector} from '../../../../hooks';
import {ChevronLeft, ChevronRight, OneTimeTaskIcon, ReusableTaskIcon} from '../../../../icons';
import {COCKPIT_ANIMATION_DELAYS, DURATION} from '../../../../utils/animations';
import {useDisabledControls} from '../../hooks/use-disabled-controls';
import {AlternativeTaskIndicator} from '../AlternativeTaskIndicator/AlternativeTaskIndicator';

const Title = React.memo(({children}) => {
    return (
        <Typography
            color="white"
            weight={500}
            size={24}
            hasGutters={false}
            lineClamp={1}
            title={children}
        >
            {children}
        </Typography>
    );
});

const ScheduledAt = React.memo(({children}) => {
    return (
        <Typography
            color="white"
            weight={500}
            size={18}
            hasGutters={false}
            title={children}
        >
            {children}
        </Typography>
    );
});

const Channel = React.memo(({children}) => {
    const [queryParams] = useSearchParams();
    const brandId = parseInt(queryParams.get('brandId'), 10);
    const channelId = parseInt(queryParams.get('channelId'), 10);

    const channel = useMemoizedCreateSelector(ChannelSelectors.createChannelByIdSelector, brandId, channelId);

    return (
        <Typography
            color="white"
            weight={500}
            size={14}
            hasGutters={false}
            title={children}
        >
            {channel?.name}
        </Typography>
    );
});

export const TaskBar = () => {
    const task = useSelector(TaskSelectors.selectCurrentTask);
    const isFreeModeEnabled = useSelector(CurrentTaskSelectors.selectIsFreeModeEnabled);
    const previousTask = useMemoizedCreateSelector(CockpitNavigationSelectors.createPreviousTaskSelector);
    const nextTask = useMemoizedCreateSelector(CockpitNavigationSelectors.createNextTaskSelector);
    const isTaskDeletedModalOpen = useMemoizedCreateSelector(
        UiSelectors.createActiveModalSelector,
        ModalKeys.TASK_DELETED_MODAL,
    );

    const [queryParams] = useSearchParams();
    const params = queryString.parse(queryParams.toString());

    const startTime = params.startTime;

    const dispatch = useDispatch();

    const {areControlsDisabled} = useDisabledControls();

    const ReusabilityIcon = task && task.isReusable ? ReusableTaskIcon : OneTimeTaskIcon;

    const initialAnimation = useInitialAnimation({delay: COCKPIT_ANIMATION_DELAYS.TASK_BAR});

    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);

    const formattedStartTime = useMemo(() => {
        return moment(startTime, 'YYYY-MM-DD HH:mm:ss').format('ddd. DD.MM. HH:mm');
    }, [startTime]);

    useEffect(() => {
        const handleKeydown = event => {
            if (areControlsDisabled || isTaskDeletedModalOpen || isFreeModeEnabled) {
                return;
            }

            if (document.activeElement.tagName === 'INPUT' || document.activeElement?.dataset?.lexicalEditor === 'true') {
                return;
            }

            let taskId = null;
            let taskVariationId = null;
            let playlistTaskKey = null;
            let startTime = null;

            if (event.code === 'ArrowLeft' && previousTask) {
                taskId = previousTask.taskId;
                taskVariationId = previousTask.taskVariationId;
                playlistTaskKey = previousTask.playlistTaskKey;
                startTime = `${previousTask.startDate} ${previousTask.startTime}`;
            } else if (event.code === 'ArrowRight' && nextTask) {
                taskId = nextTask.taskId;
                taskVariationId = nextTask.taskVariationId;
                playlistTaskKey = nextTask.playlistTaskKey;
                startTime = `${nextTask.startDate} ${nextTask.startTime}`;
            }

            if (!taskId || !taskVariationId) {
                return;
            }

            const path = generatePath(ROUTE_PATHS.TASK, {taskId});

            const urlParams = new URLSearchParams({
                ...params,
                taskVariationId,
                playlistTaskKey,
                startTime,
            });

            dispatch(push({
                pathname: path,
                search: urlParams.toString(),
            }));
        };

        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
        // eslint-disable-next-line
    }, [areControlsDisabled, nextTask, previousTask, isTaskDeletedModalOpen]);

    return (
        <div className={classes.root}>
            {!isLoading && (
                <Slide direction="down" in={initialAnimation.isVisible} timeout={DURATION}>
                    <div className={classes.content}>
                        <IconButton
                            className={classes.navigationButton}
                            backgroundColor="transparent"
                            color="white"
                            hoverStyle="color"
                            disabled={!previousTask || areControlsDisabled || isFreeModeEnabled}
                            onClick={() => {
                                if (!previousTask) {
                                    return;
                                }

                                const path = generatePath(ROUTE_PATHS.TASK, {taskId: previousTask.taskId});

                                const urlParams = new URLSearchParams({
                                    ...queryString.parse(queryParams.toString()),
                                    taskVariationId: previousTask.taskVariationId,
                                    playlistTaskKey: previousTask.playlistTaskKey,
                                    startTime: `${previousTask.startDate} ${previousTask.startTime}`,
                                });

                                dispatch(push({
                                    pathname: path,
                                    search: urlParams.toString(),
                                }));
                            }}
                        >
                            <ChevronLeft />
                        </IconButton>

                        <div className={classes.innerContent}>
                            <div className={classes.firstRow}>
                                <ReusabilityIcon className={classes.taskTypeIcon} size={24} />

                                <Title>
                                    {task?.description}
                                </Title>
                            </div>

                            <div className={classes.secondRow}>
                                <ScheduledAt>
                                    {formattedStartTime}
                                </ScheduledAt>

                                <Channel />

                                {task?.isAlternative && <AlternativeTaskIndicator />}
                            </div>
                        </div>

                        <IconButton
                            className={classes.navigationButton}
                            backgroundColor="transparent"
                            color="white"
                            hoverStyle="color"
                            disabled={!nextTask || areControlsDisabled || isFreeModeEnabled}
                            onClick={() => {
                                if (!nextTask) {
                                    return;
                                }

                                const path = generatePath(ROUTE_PATHS.TASK, {taskId: nextTask.taskId});

                                const urlParams = new URLSearchParams({
                                    ...queryString.parse(queryParams.toString()),
                                    taskVariationId: nextTask.taskVariationId,
                                    playlistTaskKey: nextTask.playlistTaskKey,
                                    startTime: `${nextTask.startDate} ${nextTask.startTime}`,
                                });

                                dispatch(push({
                                    pathname: path,
                                    search: urlParams.toString(),
                                }));
                            }}
                        >
                            <ChevronRight />
                        </IconButton>
                    </div>
                </Slide>
            )}
        </div>
    );
};
