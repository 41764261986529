import {useEffect, useRef, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import recordingAlgorithm from '../../recording-algorithm/RecordingAlgorithm';

export const useCanDrag = () => {
    const [canDrag, setCanDrag] = useState(false);
    const subscriber = useRef(null);

    useEffect(() => {
        const onUpdate = ({areControlsDisabled}) => {
            setCanDrag(areControlsDisabled);
        };

        subscriber.current = recordingAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS);

        return () => {
            subscriber.current.unsubscribe();
        };
    }, []);

    return {canDrag};
};
