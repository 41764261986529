export const LoginFormSelectors = {
    selectIsLoading: state => state.auth.loginForm.isLoading,
};

export const NewPasswordFormSelectors = {
    selectIsLoading: state => state.auth.newPasswordForm.isLoading,
};

export const ResetPasswordFormSelectors = {
    selectIsLoading: state => state.auth.resetPasswordForm.isLoading,
    selectStep: state => state.auth.resetPasswordForm.step,
    selectEmail: state => state.auth.resetPasswordForm.email,
};
