import {WebSocketActionTypes} from './web-socket.action-type';

export const WebSocketActions = {
    connect: () => ({
        type: WebSocketActionTypes.CONNECT,
    }),
    setIsConnected: isConnected => ({
        type: WebSocketActionTypes.SET_IS_CONNECTED,
        payload: isConnected,
    }),
    processMessage: message => ({
        type: WebSocketActionTypes.PROCESS_MESSAGE,
        payload: {
            message,
        },
    }),
};
