import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './Divider.module.scss';
import {COLOR_NAMES} from '../../utils/color-names';

export const Divider = ({isVertical, backgroundColor, className}) => {
    return (
        <div
            className={clsx(classes.root, className, classes[`background-color-${backgroundColor}`], {
                [classes.vertical]: isVertical,
            })}
        />
    );
};

Divider.propTypes = {
    isVertical: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(COLOR_NAMES),
    className: PropTypes.string,
};

Divider.defaultProps = {
    isVertical: false,
    backgroundColor: 'mako',
    className: '',
};
