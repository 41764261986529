import {User} from './user.dto';
import {ddsPlanningApi} from '../../../lib/axios';

/**
 * @returns {Promise<User>}
 */
export const getCurrentUserRequest = (options = {}) => {
    return ddsPlanningApi
        .get('/v1/users/me', {
            ...options,
        })
        .then(response => new User(response.data));
};
