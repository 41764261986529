import {MixingPlan} from '../dto/mixing-plan.dto';

export const createMixingPlanForPreProducedAudio = ({externalId}) => {
    return new MixingPlan({
        recordLength: 0,
        isEdited: false,
        elements: [{
            startAt: 0,
            externalId,
            type: 'recording',
            volumePoints: [{
                fromVolume: 1,
                toVolume: 1,
                startAt: 0,
                stopAt: 0,
            }],
        }],
    });
};
