import moment from 'moment';

/**
 * This function is used to generate timestamps until the end of the day based on time interval.
 *
 * @param {string} startFrom
 * @param {number} minuteInterval
 * @param {string} endAt
 * @param {array} prependOptions
 * @returns {Array}
 */
export const generateTimeStamps = (startFrom = '00:00', minuteInterval = 30, endAt = '00:00', prependOptions = []) => {
    const startDate = moment();
    const startTime = moment(startFrom, 'HH:mm');
    const endTime = endAt === '00:00' ? moment(endAt, 'HH:mm').add(1, 'day') : moment(endAt, 'HH:mm');

    startDate.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: 0,
    });

    const endDate = startDate.clone();

    if ((startFrom === '00:00' && endAt === '00:00') || endAt === '00:00') {
        endDate.add(1, 'day');
    }

    endDate.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: 0,
    });

    const timeStamps = [...prependOptions];

    while (startDate.isBefore(endDate, 'minutes')) {
        timeStamps.push({
            id: startDate.clone().format('HH:mm'),
            name: startDate.clone().format('HH:mm'),
        });

        startDate.add(minuteInterval, 'minutes');
    }


    return timeStamps;
};
