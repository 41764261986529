import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './AddElement.module.scss';
import {AddIcon} from '../../../../icons';
import {useDisabledControls} from '../../hooks/use-disabled-controls';

export const AddElement = ({color, disabled, ...rest}) => {
    const {areControlsDisabled} = useDisabledControls();

    return (
        <button
            className={clsx(classes.root, classes[`color-${color}`])}
            type="button"
            disabled={areControlsDisabled || disabled}
            {...rest}
        >
            <AddIcon className={classes.icon} />
        </button>
    );
};

AddElement.propTypes = {
    color: PropTypes.oneOf(['sun', 'turquoise', 'rose']).isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: PropTypes.bool,
};

AddElement.defaultProps = {
    disabled: false,
};
