import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import classes from './ResetPasswordForm.module.scss';
import {Input, InputGroup, LoadingButton} from '../../../../components';
import {ResetPasswordFormActions} from '../../store/auth.action';
import {ResetPasswordFormSelectors} from '../../store/auth.selector';

export const ResetPasswordForm = () => {
    const {t} = useTranslation('featuresAuth');

    const schema = Yup.object().shape({
        code: Yup.string().matches(/^\d{6}$/, t('errors.invalidCode')).required().label(t('labels.code')),
        password: Yup.string().min(8).required().label(t('labels.password')),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('errors.passwordsMustMatch')).label(t('labels.confirmPassword')),
    });

    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            code: '',
            password: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = useCallback(data => {
        dispatch(ResetPasswordFormActions.submit(data.code, data.password));
    }, [dispatch]);

    const isLoading = useSelector(ResetPasswordFormSelectors.selectIsLoading);

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup hasGutterBottom>
                    <Controller
                        name="code"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.code')}
                                hasError={!!errors.code}
                                helperText={errors?.code?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <InputGroup hasGutterBottom>
                    <Controller
                        name="password"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.password')}
                                type="password"
                                hasError={!!errors.password}
                                helperText={errors?.password?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <InputGroup>
                    <Controller
                        name="confirmPassword"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                placeholder={t('labels.confirmPassword')}
                                type="password"
                                hasError={!!errors.confirmPassword}
                                helperText={errors?.confirmPassword?.message}
                                disabled={isLoading}
                            />
                        )}
                    />
                </InputGroup>

                <div className={classes.footer}>
                    <LoadingButton isSubmit isLoading={isLoading}>
                        {t('labels.resetPasswordButton')}
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};
