import {WebSocketActions} from './web-socket.action';
import {WebSocketActionTypes} from './web-socket.action-type';
import {CONFIG} from '../../../config';
import {Debug} from '../../../lib/debug';

export const webSocketMiddleware = store => {
    let socket;

    return next => {
        return action => {
            if (action.type === WebSocketActionTypes.CONNECT) {
                socket = new WebSocket(CONFIG.DDS_PLANNING.WEB_SOCKET_URL);

                socket.addEventListener('open', () => {
                    Debug.debug('web-socket', 'Connection successfully opened!');

                    store.dispatch(WebSocketActions.setIsConnected(true));
                });

                socket.addEventListener('close', () => {
                    Debug.debug('web-socket', 'Connection closed!');

                    store.dispatch(WebSocketActions.setIsConnected(false));
                });

                socket.addEventListener('message', event => {
                    Debug.debug('web-socket', 'Received message!');

                    store.dispatch(WebSocketActions.processMessage(JSON.parse(event.data)));
                });

                socket.addEventListener('error', event => {
                    Debug.debug('web-socket', 'Error!');
                });

                setTimeout(() => {
                    socket.send(JSON.stringify({
                        'test': true,
                    }));
                }, 5000);
            }

            next(action);
        };
    };
};
