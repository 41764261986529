import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './LoadingDots.module.scss';

const LoadingDot = () => {
    return (
        <span
            className={classes.dot}
        />
    );
};

export const LoadingDots = ({className}) => {
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingDot />
            <LoadingDot />
            <LoadingDot />
        </div>
    );
};

LoadingDots.propTypes = {
    className: PropTypes.string,
};

LoadingDots.defaultProps = {
    className: '',
};
