import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Tabs} from '../../../../components';
import {PlaylistSelectors} from '../../../../features/playlist';
import {PlaylistActions} from '../../../../features/playlist/store/playlist.action';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {BlockProgramTab} from '../BlockProgramTab/BlockProgramTab';

export const BlockProgramTabs = ({blockKey, blockProgramKey, splitProgramKeys, selectedRegionId}) => {
    const blockProgram = useMemoizedCreateSelector(
        PlaylistSelectors.createBlockProgramByKeySelector,
        blockProgramKey,
    );
    const splitPrograms = useMemoizedCreateSelector(
        PlaylistSelectors.createSplitProgramsByKeySelector,
        splitProgramKeys,
    );

    const dispatch = useDispatch();

    const tabs = useMemo(() => {
        return [
            {
                id: blockProgramKey,
                label: blockProgram.name,
                TabComponent: BlockProgramTab,
                TabProps: {
                    count: blockProgram.numberOfOpenTasks,
                    total: blockProgram.numberOfTasks,
                    hasUrgentTasks: blockProgram.hasUrgentTasks,
                },
            },
            ...splitPrograms.map(splitProgram => ({
                id: splitProgram.key,
                label: splitProgram.name,
                TabComponent: BlockProgramTab,
                TabProps: {
                    count: splitProgram.numberOfOpenTasks,
                    total: splitProgram.numberOfTasks,
                    hasUrgentTasks: splitProgram.hasUrgentTasks,
                },
            })),
        ];
    }, [blockProgramKey, blockProgram, splitPrograms]);

    return (
        <Tabs
            onChange={programKey => {
                dispatch(PlaylistActions.setSelectedRegionForBlock(blockKey, programKey));
            }}
            activeTab={selectedRegionId}
            tabs={tabs}
        />
    );
};

BlockProgramTabs.propTypes = {
    blockKey: PropTypes.string.isRequired,
    blockProgramKey: PropTypes.string.isRequired,
    splitProgramKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedRegionId: PropTypes.string.isRequired,
};
