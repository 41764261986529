import {actionChannel, all, call, delay, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import {GenericTaskActions} from './generic-task.action';
import {GenericTaskActionTypes} from './generic-task.action-type';
import {GenericTaskSelectors} from './generic-task.selector';
import {CONFIG} from '../../../config';
import {handleSagaError} from '../../../error.saga';
import {logTailService} from '../../log-tail/log-tail.service';
import {PlayerActions} from '../../player';
import {UiActions} from '../../ui/store/ui.action';
import {completeGenericTaskVariation, getGenericTasksApi} from '../api/generic-task.provider';
import {GENERIC_TASK_VARIATION_STATUSES} from '../utils/generic-task.constants';

const onGenericTaskVariationChange = function* () {
    yield put(GenericTaskActions.setIsControllerOpen(true));
};

const onExpandGenericProductionOrder = function* ({payload}) {
    const genericProductionOrders = yield select(GenericTaskSelectors.selectGenericProductionOrders);

    yield put(GenericTaskActions.storeGenericProductionOrders({
        ...genericProductionOrders,
        [payload.genericProductionOrderId]: genericProductionOrders[payload.genericProductionOrderId].update({
            isExpanded: payload.isExpanded,
        }),
    }));
};

const onExpandGenericTask = function* ({payload}) {
    const genericTasks = yield select(GenericTaskSelectors.selectGenericTasks);

    yield put(GenericTaskActions.storeGenericTasks({
        ...genericTasks,
        [payload.genericTaskId]: genericTasks[payload.genericTaskId].update({
            isExpanded: payload.isExpanded,
        }),
    }));
};

const onGoToPreviousGenericTaskVariation = function* () {
    const variationId = yield select(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const variation = yield select(GenericTaskSelectors.createGenericTaskVariationByIdSelector(variationId));
    const previousVariationId = variation.previousGenericTaskVariationId;

    if (!previousVariationId) {
        return;
    }

    const previousVariation = yield select(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector(previousVariationId),
    );

    yield all([
        put(GenericTaskActions.setGenericProductionOrderExpansion({
            genericProductionOrderId: previousVariation.genericProductionOrderId,
            isExpanded: true,
        })),
        put(GenericTaskActions.setGenericTaskExpansion({
            genericTaskId: previousVariation.genericTaskId,
            isExpanded: true,
        })),
    ]);

    yield delay(225); // 225ms is animation duration

    yield put(UiActions.setScrollToElement(previousVariationId, true));

    yield put(GenericTaskActions.setSelectedGenericTaskVariationId(previousVariationId));
};

const onGoToNextGenericTaskVariation = function* () {
    const variationId = yield select(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const variation = yield select(GenericTaskSelectors.createGenericTaskVariationByIdSelector(variationId));
    const nextVariationId = variation.nextGenericTaskVariationId;

    if (!nextVariationId) {
        return;
    }

    const nextVariation = yield select(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector(nextVariationId),
    );

    yield all([
        put(GenericTaskActions.setGenericProductionOrderExpansion({
            genericProductionOrderId: nextVariation.genericProductionOrderId,
            isExpanded: true,
        })),
        put(GenericTaskActions.setGenericTaskExpansion({
            genericTaskId: nextVariation.genericTaskId,
            isExpanded: true,
        })),
    ]);

    yield delay(225); // 225ms is animation duration

    yield put(UiActions.setScrollToElement(nextVariationId, true));

    yield put(GenericTaskActions.setSelectedGenericTaskVariationId(nextVariationId));
};

const onControllerOpen = function* ({payload}) {
    if (payload) {
        yield put(PlayerActions.setIsOpen(false));
        yield put(PlayerActions.setSources([]));
    }
};

const onUpload = function* ({payload}) {
    const {blob} = payload;

    const genericTaskVariationId = yield select(GenericTaskSelectors.selectSelectedGenericTaskVariationId);

    yield put(GenericTaskActions.upload({
        genericTaskVariationId,
        blob,
    }));

    yield put(GenericTaskActions.goToNextGenericTaskVariation());
};

const uploadWorker = function* ({genericTaskVariationId, blob}) {
    let retry = 1;

    while (true) {
        try {
            const genericTaskVariation = yield call(completeGenericTaskVariation, {
                genericTaskVariationId,
                blob,
            });

            yield call(updateGenericTaskVariation, genericTaskVariationId, genericTaskVariation);

            break;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error});

            yield call([logTailService, logTailService.error], 'ERROR: ', {
                error: {...error},
                request: error?.request || {},
                response: error?.response || {},
                version: CONFIG.APP_VERSION,
            });

            if (retry === 3) {
                break;
            }

            retry += 1;

            yield delay(15000);
        }
    }
};

const uploadWatcher = function* () {
    const requestChannel = yield actionChannel(GenericTaskActionTypes.UPLOAD);

    while (true) {
        const {payload} = yield take(requestChannel);

        yield call(updateGenericTaskVariation, payload.genericTaskVariationId, {
            status: GENERIC_TASK_VARIATION_STATUSES.UPLOADING,
        });

        yield call(uploadWorker, {
            genericTaskVariationId: payload.genericTaskVariationId,
            blob: payload.blob,
        });
    }
};

const updateGenericTaskVariation = function* (genericTaskVariationId, genericTaskVariation) {
    const genericTaskVariations = yield select(GenericTaskSelectors.selectGenericTaskVariations);

    yield put(GenericTaskActions.storeGenericTaskVariations({
        ...genericTaskVariations,
        [genericTaskVariationId]: genericTaskVariations[genericTaskVariationId].update({
            ...genericTaskVariation,
        }),
    }));
};

export const getGenericTasksSaga = function* () {
    try {
        const {genericProductionOrders, genericTasks, genericTaskVariations} = yield call(getGenericTasksApi);
        const genericProductionOrderIds = Object.values(genericProductionOrders).map(entity => entity.id);

        yield all([
            put(GenericTaskActions.storeGenericTaskVariations(genericTaskVariations)),
            put(GenericTaskActions.storeGenericTasks(genericTasks)),
            put(GenericTaskActions.storeGenericProductionOrders(genericProductionOrders)),
            put(GenericTaskActions.storeGenericProductionOrderIds(genericProductionOrderIds)),
        ]);
    } catch (error) {
        yield call(handleSagaError, error, 'getGenericTasksSaga');
    }
};

export const loadGenericTasks = function* loadGenericTasks() {
    yield call(getGenericTasksSaga);
};

export const genericTaskRootSaga = function* () {
    yield all([
        takeLatest(GenericTaskActionTypes.SET_SELECTED_GENERIC_TASK_VARIATION_ID, onGenericTaskVariationChange),
        takeLatest(GenericTaskActionTypes.SET_GENERIC_PRODUCTION_ORDER_EXPANSION, onExpandGenericProductionOrder),
        takeLatest(GenericTaskActionTypes.SET_GENERIC_TASK_EXPANSION, onExpandGenericTask),
        takeLatest(GenericTaskActionTypes.GO_TO_PREVIOUS_GENERIC_TASK_VARIATION, onGoToPreviousGenericTaskVariation),
        takeLatest(GenericTaskActionTypes.GO_TO_NEXT_GENERIC_TASK_VARIATION, onGoToNextGenericTaskVariation),
        takeLatest(GenericTaskActionTypes.SET_IS_CONTROLLER_OPEN, onControllerOpen),
        takeLatest(GenericTaskActionTypes.SCHEDULE_UPLOAD, onUpload),
        fork(uploadWatcher),
    ]);
};
