import {LoadingActionTypes} from './loading.action-type';
import {LOADING_STATES} from '../../../utils';
import {LOADING_TYPES} from '../utils/loading.constants';

const initialState = {
    [LOADING_TYPES.TASK_STATS]: true,
    [LOADING_TYPES.BRANDS]: true,
    [LOADING_TYPES.PLAYLIST]: true,
    [LOADING_TYPES.TASKS]: true,
    [LOADING_TYPES.COCKPIT_DATA]: true,
    [LOADING_TYPES.GENERIC_TASKS]: true,
    [LOADING_TYPES.TASK_OVERVIEW]: LOADING_STATES.LOADING,
    [LOADING_TYPES.UPDATE_PLAYING_TYPE]: [],
};

export const loadingReducer = (state = initialState, action) => {
    if (action.type === LoadingActionTypes.SET_IS_LOADING) {
        return {
            ...state,
            [action.payload.loadingType]: action.payload.isLoading,
        };
    }

    return state;
};
