import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import clsx from 'clsx';
import {useCallback, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classes from './EditTextMenu.module.scss';
import {IconButton, TextButton} from '../../../../components';
import {ConfirmationDialog} from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {CheckIcon, CloseIcon, EditIcon, LoadingBars, RestoreIcon} from '../../../../icons';
import {RESET_TEXT} from '../../../../lib/lexical/commands/reset-text-command';
import {useDisabledControls} from '../../hooks/use-disabled-controls';
import {CockpitActions} from '../../store/cockpit.action';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {drafts} from '../../utils/speaker-text-drafts';

export const EditTextMenu = () => {
    const dispatch = useDispatch();

    const [editor] = useLexicalComposerContext();

    const {t} = useTranslation('screensCockpit');

    const isEditingSpeakerText = useSelector(CockpitSelectors.selectIsEditingSpeakerText);
    const taskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);

    const {areControlsDisabled} = useDisabledControls();

    const isLoading = useIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION);

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    // const loadedPreProducedElements = useMemoizedCreateSelector(
    //     CurrentTaskSelectors.createLoadedElementIdsByTypeSelector,
    //     'preProduced'
    // );

    const handleClick = useCallback(() => {
        dispatch(CockpitActions.setIsEditingSpeakerText(true));

        // editor.dispatchCommand(REMOVE_VOICE_LEVEL_ICONS, {});
        editor.setEditable(true);
        editor.focus();
    }, [dispatch, editor]);

    const handleClose = useCallback(() => {
        dispatch(CockpitActions.setIsEditingSpeakerText(false));

        editor.dispatchCommand(RESET_TEXT, {
            isCancel: true,
        });
        editor.setEditable(false);
        // editor.dispatchCommand(ADD_VOICE_LEVEL_ICONS, {});
    }, [dispatch, editor]);

    const handleReset = useCallback(() => {
        dispatch(CockpitActions.setIsEditingSpeakerText(false));
        editor.setEditable(false);
        // editor.dispatchCommand(ADD_VOICE_LEVEL_ICONS, {});
        editor.dispatchCommand(RESET_TEXT, {});
    }, [dispatch, editor]);

    const handleSave = useCallback(() => {
        drafts.removeDraft(taskVariationId);
        const editorState = editor.getEditorState();
        const payload = editorState.toJSON();

        dispatch(CurrentTaskActions.setSpeakerText(payload, 'user'));
        dispatch(CockpitActions.setIsEditingSpeakerText(false));
        editor.setEditable(false);
        // editor.dispatchCommand(ADD_VOICE_LEVEL_ICONS, {});
    }, [dispatch, editor, taskVariationId]);

    // const handleInsertMarker = useCallback(() => {
    //     if (!loadedPreProducedElements.length) {
    //         toastInstance.info('There are no content bits loaded.');
    //         return;
    //     }
    //
    //     const editorState = editor.getEditorState();
    //     let markerCount = 0;
    //
    //     editorState.read(() => {
    //         const root = $getRoot();
    //         const paragraphNode = root.getChildren()[0];
    //         const children = paragraphNode.getChildren();
    //
    //         for (const child of children) {
    //             if ($isMarkerNode(child)) {
    //                 markerCount += 1;
    //             }
    //         }
    //     });
    //
    //     if (markerCount === loadedPreProducedElements.length) {
    //         toastInstance.info('All loaded content bits have a matching marker.');
    //         return;
    //     }
    //
    //     editor.dispatchCommand(INSERT_PRE_PRODUCED_MARKER_COMMAND, {});
    // }, [editor, loadedPreProducedElements]);

    if (!document.querySelector('#edit-menu')) {
        return null;
    }

    return createPortal(
        <div
            className={clsx(classes.root, {
                [classes.active]: isEditingSpeakerText,
            })}
        >
            <TextButton
                startIcon={isLoading ? <LoadingBars size={18} /> : <EditIcon />}
                className={classes.textButton}
                onClick={handleClick}
                disabled={areControlsDisabled || isLoading}
            >
                {t('editTextButtonTitle')}
            </TextButton>

            <div className={classes.menu}>
                <IconButton
                    color="white"
                    backgroundColor="mako"
                    onClick={handleClose}
                    disabled={areControlsDisabled || isLoading}
                >
                    <CloseIcon />
                </IconButton>

                <IconButton
                    color="white"
                    backgroundColor="mako"
                    onClick={() => setIsConfirmationDialogOpen(true)}
                    disabled={areControlsDisabled || isLoading}
                >
                    <RestoreIcon />
                </IconButton>

                {/* <IconButton */}
                {/*     color="white" */}
                {/*     backgroundColor="rose" */}
                {/*     onClick={handleInsertMarker} */}
                {/*     disabled={areControlsDisabled || isLoading} */}
                {/* > */}
                {/*     <MarkerIcon /> */}
                {/* </IconButton> */}

                <IconButton
                    color="white"
                    backgroundColor="radiance"
                    onClick={handleSave}
                    disabled={areControlsDisabled || isLoading}
                >
                    <CheckIcon />
                </IconButton>
            </div>

            <ConfirmationDialog
                message="This will discard all changes to the text and revert it to the original."
                confirm="Reset"
                title="Are you sure?"
                isOpen={isConfirmationDialogOpen}
                onSubmit={handleReset}
                onCancel={() => setIsConfirmationDialogOpen(false)}
                onClose={() => setIsConfirmationDialogOpen(false)}
            />
        </div>,
        document.querySelector('#edit-menu'),
    );
};

EditTextMenu.propTypes = {};

EditTextMenu.defaultProps = {};
