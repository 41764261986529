import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CockpitActions} from './cockpit.action';
import {cockpitScreenLoaderSaga} from './cockpit.loader-saga';
import {CurrentTaskActionTypes, CurrentTaskSelectors} from '../../../features/current-task';
import {
    RecordingAlgorithmActionTypes,
} from '../../../features/recording-algorithm/store/recording-algorithm.action-type';
import {RouterSelector} from '../../../router.selector';
import {MainContentComponents} from '../utils/constants';

const onRecordingAlgorithmStart = function* () {
    yield put(CockpitActions.setMainContentComponent(MainContentComponents.MODERATION));
};

const onRecordingAlgorithmReset = function* () {
    yield put(CockpitActions.setMainContentComponent(MainContentComponents.MODERATION));
};

const onSetCurrentTaskVariationId = function* ({payload: taskVariationId, source}) {
    if (source !== 'complete-task-variation') {
        return;
    }

    const taskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);
    const routerState = yield select(RouterSelector.selectRouterState);

    yield call(cockpitScreenLoaderSaga, {
        payload: {
            params: {
                taskId,
            },
            taskVariationId,
            ...routerState,
        },
    });
};

export const cockpitSaga = function* () {
    yield all([
        takeEvery('ALGORITHM_INITIALIZED', onRecordingAlgorithmStart),
        takeEvery(RecordingAlgorithmActionTypes.RESET, onRecordingAlgorithmReset),
        takeEvery(CurrentTaskActionTypes.SET_CURRENT_TASK_VARIATION_ID, onSetCurrentTaskVariationId),
    ]);
};
