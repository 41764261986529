import moment from 'moment';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {FilterActions} from './home-filters.action';
import {FilterActionTypes} from './home-filters.action-type';
import {FilterSelectors} from './home-filters.selector';
import {NOT_SELECTED} from '../../../utils';
import {userSwitcher} from '../../auth/utils/switch';
import {ChannelActions} from '../../channel';
import {PlaylistSelectors} from '../../playlist';
import {PlaylistActions} from '../../playlist/store/playlist.action';
import {PlaylistActionTypes} from '../../playlist/store/playlist.action-type';
import {loadPlaylist} from '../../playlist/store/playlist.loader-saga';
import {UserSelectors} from '../../user';
import {HomeViews} from '../home-filters.consts';

const filterStateVersion = 3;

const onFieldValueChangeWorker = function* ({payload, source}) {
    const {fieldKey, value} = payload;

    if (fieldKey === 'brand') {
        if (value && value !== NOT_SELECTED) {
            yield put(ChannelActions.get(value));
        }

        yield put(FilterActions.setFieldValue('channel', NOT_SELECTED));
    }

    const filterState = yield select(FilterSelectors.selectFilterState);

    yield call(saveFilterState, {source});

    const activeView = filterState.activeView;

    if (activeView === HomeViews.PLAYLIST) {
        yield call(loadPlaylist, filterState);
    }
};

export const restoreFilterState = function* () {
    let user = yield select(UserSelectors.selectUserEntity);
    let userId = user.id;

    if (userSwitcher.getIsSwitching()) {
        user = yield select(UserSelectors.selectSwitchingUserEntity);
        userId = user.id;
    }

    const jsonState = localStorage.getItem(`${userId}/filterState`);

    if (!jsonState) {
        return;
    }

    const initialState = JSON.parse(jsonState);
    const createdAt = moment(initialState.createdAt, 'YYYY-MM-DD HH:mm:ss');
    const validUntil = createdAt.clone().add(15, 'minute');

    if (moment().isAfter(validUntil) || !initialState.version || initialState.version !== filterStateVersion) {
        localStorage.removeItem(`${userId}/filterState`);

        return;
    }

    yield all([
        put(FilterActions.setFieldValue('brand', initialState.brand, 'system')),
        put(FilterActions.setFieldValue('channel', initialState.channel, 'system')),
        put(FilterActions.setFieldValue('startDate', moment(initialState.startDate).format('YYYY-MM-DD'), 'system')),
        put(FilterActions.setFieldValue('activeView', initialState.activeView, 'system')),
        put(PlaylistActions.setRole(initialState.role, 'system')),
        put(PlaylistActions.setShowContent(initialState.showContent, 'system')),
        put(PlaylistActions.setShowMusic(initialState.showMusic, 'system')),
        put(PlaylistActions.setShowStructured(initialState.showStructured, 'system')),
        put(PlaylistActions.setShowOnlyMatches(initialState.showOnlyMatches, 'system')),
    ]);
};

export const saveFilterState = function* ({source}) {
    if (source === 'system') {
        return;
    }

    let user = yield select(UserSelectors.selectUserEntity);
    let userId = user.id;

    if (userSwitcher.getIsSwitching()) {
        user = yield select(UserSelectors.selectSwitchingUserEntity);
        userId = user.id;
    }

    const filterState = yield select(FilterSelectors.selectFilterState);
    const role = yield select(PlaylistSelectors.selectRole);
    const showContent = yield select(PlaylistSelectors.selectShowContent);
    const showStructured = yield select(PlaylistSelectors.selectShowStructured);
    const showMusic = yield select(PlaylistSelectors.selectShowMusic);
    const showOnlyMatches = yield select(PlaylistSelectors.selectShowOnlyMatches);

    localStorage.setItem(`${userId}/filterState`, JSON.stringify({
        brand: filterState.brand,
        channel: filterState.channel,
        startDate: moment(filterState.startDate).format('YYYY-MM-DD HH:mm:ss'),
        role,
        showMusic,
        showContent,
        showStructured,
        showOnlyMatches,
        activeView: filterState.activeView,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        version: filterStateVersion,
    }));
};

export const filterRootSaga = function* () {
    yield all([
        takeLatest([
            FilterActionTypes.SET_FIELD_VALUE,
            PlaylistActionTypes.SET_ROLE,
            PlaylistActionTypes.SET_SHOW_CONTENT,
            PlaylistActionTypes.SET_SHOW_STRUCTURED,
            PlaylistActionTypes.SET_SHOW_ONLY_MATCHES,
            PlaylistActionTypes.SET_SHOW_MUSIC,
        ], saveFilterState),
        takeLatest(FilterActionTypes.SET_FIELD_VALUE, onFieldValueChangeWorker),
    ]);
};
