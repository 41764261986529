import moment from 'moment/moment';
import queryString from 'query-string';
import {generatePath} from 'react-router-dom';
import {push} from 'redux-first-history';
import {END, eventChannel} from 'redux-saga';
import {actionChannel, all, call, delay, fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {TaskActions} from './task.action';
import {TaskActionTypes} from './task.action-type';
import {TaskSelectors} from './task.selector';
import {CONFIG} from '../../../config';
import {ROUTE_PATHS} from '../../../config/route-paths';
import {handleSagaError} from '../../../error.saga';
import {IndexedDb} from '../../../lib/indexed-db';
import {toastInstance} from '../../../lib/toast';
import {RouterSelector} from '../../../router.selector';
import {BroadcastChannelActions} from '../../broadcast-channel';
import {CockpitNavigationSelectors} from '../../cockpit-navigation';
import {CurrentTaskSelectors} from '../../current-task';
import {FailedUploadsActions} from '../../failed-uploads/store/failed-uploads.action';
import {FailedUploadStatuses} from '../../failed-uploads/utils/constants';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {logTailService} from '../../log-tail/log-tail.service';
import {MixingPlan} from '../../mixing-plan/dto/mixing-plan.dto';
import {MixingPlanSelectors} from '../../mixing-plan/store/mixing-plan.selector';
import {PlaylistActions} from '../../playlist/store/playlist.action';
import {updateTaskInPlaylist} from '../../playlist/store/playlist.saga';
import {UiActions} from '../../ui/store/ui.action';
import {ModalKeys} from '../../ui/utils/constants';
import {cockpitWindowController} from '../../window-manager';
import {
    completeTaskVariationRequest,
    getTaskByIdRequest,
    overrideExpirationDate,
    overrideValidityPeriod,
    uploadMixingPlan,
    uploadRecordingsRequest,
} from '../api/task.provider';
import {TaskStatuses} from '../utils/task-statuses';

export const getCurrentTaskFlow = function* (taskId) {
    try {
        const task = yield call(getTaskByIdRequest, taskId);

        yield put(TaskActions.storeCurrentTask(task));
    } catch (error) {
        yield call(handleSagaError, error, 'getCurrentTaskFlow');
    }
};

const overrideExpirationDateWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.OVERRIDE_EXPIRATION_DATE_FORM, true));

        const {taskId, isOverridden, date} = payload;

        const task = yield call(overrideExpirationDate, {
            taskId,
            isOverridden,
            date,
        });

        yield put(TaskActions.storeCurrentTask(task));

        toastInstance.success('featuresTask:notifications.updatedExpirationDate');
    } catch (error) {
        yield call(handleSagaError, error, 'overrideExpirationDateWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.OVERRIDE_EXPIRATION_DATE_FORM, false));
    }
};

const overrideValidityPeriodWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.OVERRIDE_VALIDITY_PERIOD_FORM, true));

        const {taskId, startDate, startTime, endDate, endTime} = payload;

        const task = yield call(overrideValidityPeriod, {
            taskId,
            startDate,
            startTime,
            endDate,
            endTime,
        });

        yield put(TaskActions.storeCurrentTask(task));

        toastInstance.success('featuresTask:notifications.updatedValidityPeriod');
    } catch (error) {
        yield call(handleSagaError, error, 'overrideValidityPeriodWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.OVERRIDE_VALIDITY_PERIOD_FORM, false));
    }
};

/**
 * Takes care of updating the task depending in which window we are and based on home filters.
 * When this is executed in the main window, we don't need to send anything to the cockpit window, because
 * we move the user to the next task or task variation in the cockpit window, in case he has the updated task open.
 * @param taskId
 * @param task
 * @returns {Generator<*, void, *>}
 */
export const updateTask = function* (taskId, task) {
    if (cockpitWindowController.isCockpitWindow()) {
        // This method is executed in the cockpit window, so we want to send the update action to the main window
        yield put(BroadcastChannelActions.send({
            messageType: 'action',
            action: PlaylistActions.updateTaskInPlaylist(taskId, task),
        }));

        const currentTaskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);

        if (taskId === currentTaskId) {
            yield call(loadCurrentTask, currentTaskId);
        }
    } else {
        // This method is executed in the main window, so we just update the task in the loaded playlist
        yield call(updateTaskInPlaylist, taskId, task);
    }
};

const shouldStoreFailedUpload = function (error) {
    const status = error?.response?.status;

    // Probably not an error on the server, something weird happened on the client
    if (!status) {
        return true;
    }

    // Server errors
    if (status >= 500) {
        return true;
    }

    // Refresh token issue or something? Save it just in case.
    return status === 403 || status === 401;
};

export const goToNextJobWorker = function* () {
    const currentTaskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);
    const currentTaskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const nextTask = yield select(CockpitNavigationSelectors.createNextTaskSelector());
    const isFreeModeEnabled = yield select(CurrentTaskSelectors.selectIsFreeModeEnabled);

    if (isFreeModeEnabled) {
        const task = yield select(TaskSelectors.selectCurrentTask);
        let nextVariationId = null;

        for (const variation of task.variations) {
            if (!variation.isCompleted && variation.id !== currentTaskVariationId) {
                nextVariationId = variation.id;

                break;
            }
        }

        if (nextVariationId) {
            const {location} = yield select(RouterSelector.selectRouterState);
            const {search} = location;
            const queryParams = queryString.parse(search);

            yield put(push({
                pathname: generatePath(ROUTE_PATHS.TASK, {taskId: currentTaskId}),
                search: queryString.stringify({
                    ...queryParams,
                    taskVariationId: nextVariationId,
                }),
            }));

            return;
        }
    }

    if (nextTask) {
        const {location} = yield select(RouterSelector.selectRouterState);
        const {search} = location;
        const queryParams = queryString.parse(search);

        yield put(push({
            pathname: generatePath(ROUTE_PATHS.TASK, {taskId: nextTask.taskId}),
            search: queryString.stringify({
                ...queryParams,
                taskVariationId: nextTask.taskVariationId,
                playlistTaskKey: nextTask.playlistTaskKey,
                startTime: `${nextTask.startDate} ${nextTask.startTime}`,
            }),
        }));

        return;
    }

    yield put(UiActions.setModalState(ModalKeys.ALL_TASKS_COMPLETED, true));
};

/**
 * Sends a request including the user-selected file to complete the task variation.
 * @param {Number} taskId
 * @param {Number} taskVariationId
 * @param {File} file
 * @param {Boolean} isAudioWrapped
 * @param syncOut
 * @returns {Generator<*, void, *>}
 */
const uploadFile = function* (taskId, taskVariationId, file, isAudioWrapped, syncOut) {
    const createSingleFileUploadChannel = () => {
        return eventChannel(emit => {
            const onUploadProgress = event => {
                emit({uploadProgress: Math.round((event.loaded * 100) / event.total)});
            };

            completeTaskVariationRequest(taskVariationId, file, onUploadProgress, isAudioWrapped, syncOut)
                .then(data => {
                    emit({data});
                    emit(END);
                }).catch(error => {
                    emit({error});
                    emit(END);
                });

            return () => {
                emit(END);
            };
        });
    };

    const channel = yield call(createSingleFileUploadChannel);

    while (true) {
        const {uploadProgress = 0, error, data} = yield take(channel);

        yield put(TaskActions.setUploadProgress(uploadProgress));

        if (error) {
            // yield call(updateTask, taskId, {status: TaskStatuses.UPLOAD_FAILED});

            throw error;
        }

        if (data) {
            yield call(updateTask, taskId, {
                hasUncompletedVariations: data.hasUncompletedVariations,
                status: data.status,
                variations: data.variations,
                isCompleted: data.isCompleted,
                description: data.description,
                filename: data.filename,
            });
        }
    }
};

/**
 * Manages retries, stores a failed upload to IndexedDB and triggers uploadFile saga
 * @param {Number} taskId
 * @param {Number} taskVariationId
 * @param {File} file
 * @param {Boolean} isAudioWrapped
 * @param {moment.Moment} expirationDate
 * @param {Number|undefined} expirationDate
 * @param syncOut
 * @returns {Generator<*, void, *>}
 */
const byPreRecordedAudioFlow = function* (taskId, taskVariationId, file, isAudioWrapped, expirationDate, syncOut) {
    let retryId = 1;

    while (true) {
        try {
            yield call(uploadFile, taskId, taskVariationId, file, isAudioWrapped, syncOut);

            break;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error});

            yield call([logTailService, logTailService.error], 'ERROR: ', {
                error: {...error},
                request: error?.request || {},
                response: error?.response || {},
                logKey: 'byPreRecordedAudioFlow',
                version: CONFIG.APP_VERSION,
            });

            if (retryId === 3) {
                yield call(updateTask, taskId, {status: TaskStatuses.UPLOAD_FAILED});

                const shouldStore = yield call(shouldStoreFailedUpload, error);

                if (shouldStore) {
                    yield all([
                        call([IndexedDb, IndexedDb.storeFailedUpload], {
                            taskId,
                            taskVariationId,
                            files: [file],
                            date: moment.utc(expirationDate),
                            isAudioWrapped,
                            syncOut,
                        }),
                        put(FailedUploadsActions.setStatus(FailedUploadStatuses.WAITING_FOR_RETRY)),
                    ]);
                }

                break;
            }

            retryId += 1;

            yield delay(15000);
        }
    }
};

/**
 * Runs the upload of recording audios provided by the recording algorithm.
 * @param {Number} taskId
 * @param {Number} taskVariationId
 * @param {Array<Blob>} files
 * @returns {Generator<*, *, *>}
 */
const uploadRecordings = function* (taskId, taskVariationId, files) {
    const createMultiFileUploadChannel = () => {
        return eventChannel(emit => {
            const onUploadProgress = event => {
                emit({uploadProgress: Math.round((event.loaded * 100) / event.total)});
            };

            uploadRecordingsRequest(taskVariationId, files, onUploadProgress)
                .then(data => {
                    emit({data});
                    emit(END);
                }).catch(error => {
                    emit({error});
                    emit(END);
                });

            return () => {
                emit(END);
            };
        });
    };

    const channel = yield call(createMultiFileUploadChannel, taskVariationId, files);

    while (true) {
        const {uploadProgress = 0, error, data} = yield take(channel);

        yield put(TaskActions.setUploadProgress(uploadProgress));

        if (error) {
            // yield call(updateTask, taskId, {status: TaskStatuses.UPLOAD_FAILED});
            throw error;
        }

        if (data) {
            return data;
        }
    }
};

/**
 * Manages retries, stores a failed upload to IndexedDB and triggers uploadRecordings saga and sends the mixing plan
 * object to the API.
 * @param {Number} taskId
 * @param {Number} taskVariationId
 * @param {Array<Blob>} files
 * @param {MixingPlan} mixingPlan
 * @param {moment.Moment} expirationDate
 * @returns {Generator<*, void, *>}
 */
const byMixingPlanFlow = function* (taskId, taskVariationId, files, mixingPlan, expirationDate) {
    let retryId = 1;

    while (true) {
        try {
            const recordingIds = yield call(uploadRecordings, taskId, taskVariationId, files);

            const plan = new MixingPlan({
                ...mixingPlan,
                elements: mixingPlan.elements.map((element, index) => ({
                    startAt: element.startAt,
                    externalId: element.type === 'recording' && recordingIds[index]
                        ? recordingIds[index]
                        : element.externalId,
                    type: element.type,
                    volumePoints: element.volumePoints,
                })),
            });

            const task = yield call(uploadMixingPlan, taskVariationId, plan.toDto());

            yield call(updateTask, taskId, {
                status: task.status,
                variations: task.variations,
                hasUncompletedVariations: task.hasUncompletedVariations,
                isCompleted: task.isCompleted,
                description: task.description,
                filename: null,
            });

            break;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error});

            yield call([logTailService, logTailService.error], 'ERROR: ', {
                error: {...error},
                request: error?.request || {},
                response: error?.response || {},
                logKey: 'byMixingPlanFlow',
                version: CONFIG.APP_VERSION,
            });

            if (retryId === 3) {
                yield call(updateTask, taskId, {status: TaskStatuses.UPLOAD_FAILED});

                const shouldStore = yield call(shouldStoreFailedUpload, error);

                if (shouldStore) {
                    yield all([
                        call([IndexedDb, IndexedDb.storeFailedUpload], {
                            taskId,
                            taskVariationId,
                            files,
                            mixingPlan: mixingPlan.toDto(),
                            date: moment.utc(expirationDate),
                        }),
                        put(FailedUploadsActions.setStatus(FailedUploadStatuses.WAITING_FOR_RETRY)),
                    ]);
                }

                break;
            }

            retryId += 1;

            yield delay(15000);
        }
    }
};

/**
 * Entry saga. Listens to the action channel, runs task completion.
 * @returns {Generator<*, void, *>}
 */
const completeTaskVariationWatcher = function* () {
    const requestChannel = yield actionChannel(TaskActionTypes.COMPLETE_TASK_VARIATION);

    while (true) {
        const {payload} = yield take(requestChannel);
        const {taskId, taskVariationId, expirationDate, files, mixingPlan} = payload;

        const failedUpload = yield call([IndexedDb, IndexedDb.getFailedUpload], taskVariationId);
        if (failedUpload) {
            yield call([IndexedDb, IndexedDb.deleteFailedUpload], taskVariationId);
        }

        if (mixingPlan) {
            yield call(byMixingPlanFlow, taskId, taskVariationId, files, mixingPlan, expirationDate);
        } else {
            let {isAudioWrapped, syncOut} = payload;

            if (syncOut === '0') {
                syncOut = 0;
            } else if (!syncOut) {
                syncOut = undefined;
            } else {
                syncOut = parseInt(syncOut, 10);

                if (isNaN(syncOut)) {
                    syncOut = undefined;
                }
            }

            yield call(byPreRecordedAudioFlow, taskId, taskVariationId, files, isAudioWrapped, expirationDate, syncOut);
        }
    }
};

const updateMixingPlanWorker = function* () {
    try {
        const taskVariationId = yield select(CurrentTaskSelectors.selectCurrentTaskVariationId);
        const mixingPlan = yield select(MixingPlanSelectors.selectMixingPlan);

        yield call(uploadMixingPlan, taskVariationId, mixingPlan.toDto());
    } catch (error) {
        yield call(handleSagaError, error, 'updateMixingPlanWorker');
    }
};

/**
 * Entry saga. Listens to the action channel, used as delegator.
 * @returns {Generator<*, void, *>}
 */
export const goToNextJobWatcher = function* () {
    const requestChannel = yield actionChannel(TaskActionTypes.COMPLETE_TASK_VARIATION);

    while (true) {
        const {source, payload} = yield take(requestChannel);
        const {taskId} = payload;

        // This is here because we don't want to update the status sequentially
        yield call(updateTask, taskId, {status: TaskStatuses.UPLOADING});

        if (source === 'cockpit') {
            // Whenever the source is cockpit, move the user to the next job
            yield fork(goToNextJobWorker);
        } else if (source === 'task-row') {
            // Whenever the source is task-row, send via BroadcastChannel a message to move the user to the next job
            const currentTaskId = yield select(CurrentTaskSelectors.selectCurrentTaskId);

            if (taskId === currentTaskId) {
                yield put(BroadcastChannelActions.send({
                    messageType: 'action',
                    action: TaskActions.goToNextJob(),
                }));
            }
        }
    }
};

export const loadCurrentTask = function* (taskId) {
    yield call(getCurrentTaskFlow, taskId);
};

export const taskRootSaga = function* () {
    yield all([
        takeEvery(TaskActionTypes.OVERRIDE_EXPIRATION_DATE, overrideExpirationDateWorker),
        takeEvery(TaskActionTypes.OVERRIDE_VALIDITY_PERIOD, overrideValidityPeriodWorker),
        takeEvery(TaskActionTypes.UPDATE_MIXING_PLAN, updateMixingPlanWorker),
        fork(completeTaskVariationWatcher),
        fork(goToNextJobWatcher),
        takeEvery(TaskActionTypes.GO_TO_NEXT_JOB, goToNextJobWorker),
    ]);
};
