import {Outlet} from 'react-router-dom';
import classes from './MinimalLayout.module.scss';
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';

export const MinimalLayout = () => {
    return (
        <>
            <header className={classes.header}>
                <Logo className={classes.logo} />
            </header>

            <main className={classes.main}>
                <Outlet />
            </main>
        </>
    );
};
