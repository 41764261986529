import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './AudioElementItem.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import {PlayIcon, SoundIcon} from '../../../../icons';

export const AudioElementItem = ({className, id, name, isSelected, ...rest}) => {
    const dispatch = useDispatch();

    return (
        <li
            className={clsx(classes.root, className, {
                [classes.selected]: isSelected,
            })}
            {...rest}
        >
            <div className={classes.iconWrapper}>
                <SoundIcon size={14} />
            </div>

            <Typography size={14} color="inherit" className={classes.name}>
                {name}
            </Typography>

            <IconButton
                color="fiord"
                backgroundColor="transparent"
                hoverStyle="color"
                size={24}
                iconSize={14}
                onClick={event => {
                    event.stopPropagation();

                    dispatch(PlayerActions.loadItem(id, name));
                }}
            >
                <PlayIcon />
            </IconButton>
        </li>
    );
};

AudioElementItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

AudioElementItem.defaultProps = {
    className: '',
};
