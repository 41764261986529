import {Amplify, Auth} from 'aws-amplify';
import {push} from 'redux-first-history';
import {all, call, put, select, take, takeEvery} from 'redux-saga/effects';
import {LoginFormActions, NewPasswordFormActions, ResetPasswordFormActions} from './auth.action';
import {
    AuthActionTypes,
    LoginFormActionTypes,
    NewPasswordFormActionTypes,
    ResetPasswordFormActionTypes,
} from './auth.action-type';
import {ResetPasswordFormSelectors} from './auth.selector';
import {CONFIG} from '../../../config';
import {ROUTE_PATHS} from '../../../config/route-paths';
import {handleSagaError} from '../../../error.saga';
import {IndexedDb} from '../../../lib/indexed-db';
import {toastInstance} from '../../../lib/toast';
import {reminderStorage} from '../../../utils/reminder-storage';
import {userSwitcher} from '../utils/switch';

const signInFlow = function* (username, password) {
    try {
        yield put(LoginFormActions.setIsLoading(true));

        const cognitoUser = yield call([Auth, Auth.signIn], username, password);

        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // Force the user to reset the password

            yield put(push(ROUTE_PATHS.NEW_PASSWORD));

            yield call(submitNewPasswordFormWorker, cognitoUser);
        }

        return true;
    } catch (error) {
        yield call(handleSagaError, error, 'signInFlow');
    } finally {
        yield put(LoginFormActions.setIsLoading(false));
    }
};

const newPasswordFlow = function* (cognitoUser, password) {
    try {
        yield put(NewPasswordFormActions.setIsLoading(true));

        yield call([Auth, Auth.completeNewPassword], cognitoUser, password);

        return true;
    } catch (error) {
        yield call(handleSagaError, error, 'newPasswordFlow');
    } finally {
        yield put(NewPasswordFormActions.setIsLoading(false));
    }
};

const sendCodeFlow = function* (email) {
    try {
        yield put(ResetPasswordFormActions.setIsLoading(true));

        yield call([Auth, Auth.forgotPassword], email);

        return true;
    } catch (error) {
        yield call(handleSagaError, error, 'sendCodeFlow');
    } finally {
        yield put(ResetPasswordFormActions.setIsLoading(false));
    }
};

const resetPasswordFlow = function* (email, code, password) {
    try {
        yield put(ResetPasswordFormActions.setIsLoading(true));

        yield call([Auth, Auth.forgotPasswordSubmit], email, code, password);

        return true;
    } catch (error) {
        yield call(handleSagaError, error, 'resetPasswordFlow');
    } finally {
        yield put(ResetPasswordFormActions.setIsLoading(false));
    }
};

const submitLoginFormWorker = function* ({payload}) {
    const {
        username,
        password,
    } = payload;

    const result = yield call(signInFlow, username, password);

    if (result) {
        yield put(push(ROUTE_PATHS.HOME));
    }
};

const submitNewPasswordFormWorker = function* (cognitoUser) {
    const {payload: {password}} = yield take(NewPasswordFormActionTypes.SUBMIT);

    const result = yield call(newPasswordFlow, cognitoUser, password);

    if (result) {
        toastInstance.success('featuresAuth:notifications.passwordChanged');
    }
};

const sendCodeFormWorker = function* ({payload}) {
    const {email} = payload;

    const result = yield call(sendCodeFlow, email);

    if (result) {
        yield put(ResetPasswordFormActions.setEmail(email));
        yield put(ResetPasswordFormActions.setStep(2));

        toastInstance.success('featuresAuth:notifications.codeSent');
    }
};

const logOutWorker = function* () {
    yield put(push({
        pathname: ROUTE_PATHS.LOGIN,
        state: null,
    }));

    yield call([userSwitcher, userSwitcher.setEmail], null);
    yield call([userSwitcher, userSwitcher.setCanSwitch], false);

    yield call([reminderStorage, reminderStorage.reset]);

    yield call([IndexedDb, IndexedDb.deleteAllFailedUploads]);

    yield call([Auth, Auth.signOut]);

    yield put({
        type: 'RESET_REDUCERS',
    });
};

const resetPasswordFormWorker = function* ({payload}) {
    const {
        code,
        password,
    } = payload;
    const email = yield select(ResetPasswordFormSelectors.selectEmail);

    const result = yield call(resetPasswordFlow, email, code, password);

    if (result) {
        yield put(push(ROUTE_PATHS.LOGIN));
        yield put(ResetPasswordFormActions.setEmail(null));
        yield put(ResetPasswordFormActions.setStep(1));

        toastInstance.success('featuresAuth:notifications.passwordChanged');
    }
};

export const configureAuth = function* () {
    yield call([Amplify, Amplify.configure], {
        Auth: {
            mandatorySignIn: true,
            region: CONFIG.AMPLIFY.REGION,
            userPoolId: CONFIG.AMPLIFY.USER_POOL_ID,
            userPoolWebClientId: CONFIG.AMPLIFY.CLIENT_ID,
        },
    });
};

export const isSignedIn = function* () {
    let isUserLoggedIn = false;

    try {
        const session = yield call([Auth, Auth.currentSession]);

        isUserLoggedIn = session && !!session.getIdToken().getJwtToken();
    } catch (error) {
        // no-op
    }

    return isUserLoggedIn;
};

export const authRootSaga = function* () {
    yield all([
        takeEvery(LoginFormActionTypes.SUBMIT, submitLoginFormWorker),
        takeEvery(ResetPasswordFormActionTypes.SUBMIT_SEND_CODE_FORM, sendCodeFormWorker),
        takeEvery(ResetPasswordFormActionTypes.SUBMIT, resetPasswordFormWorker),
        takeEvery(AuthActionTypes.LOG_OUT, logOutWorker),
    ]);
};
