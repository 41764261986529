export const GENERIC_TASK_VARIATION_STATUSES = {
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    UPLOADING: 'UPLOADING',
    FAILED: 'FAILED',
};

export const VOICE_MOODS = {
    WARM_AND_FRIENDLY: 'Warm & Friendly',
    RELAXED: 'Relaxed',
    ADULT: 'Adult',
    ENERGIZED: 'Energized',
};
