import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import clsx from 'clsx';
import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AllTasksCompletedModal} from './AllTasksCompletedModal';
import {CrossChannelTaskModal} from './CrossChannelTaskModal';
import {ProcessingCompleteModal} from './ProcessingCompleteModal';
import {ProcessingTaskModal} from './ProcessingTaskModal';
import classes from './TaskScreen.module.scss';
import {Typography} from '../../../../components';
import {NewWindow} from '../../../../components/NewWindow/NewWindow';
import {Editor} from '../../../../features/editor/components/Editor';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {Player} from '../../../../features/player';
import {TaskSelectors} from '../../../../features/task';
import {LoadingBars, RecurringTaskIcon} from '../../../../icons';
import {CockpitActions} from '../../store/cockpit.action';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {MainContentComponents} from '../../utils/constants';
import {Controls} from '../Controls/Controls';
import {AddElementModal} from '../Elements/AddElementModal';
import {ElementsWrapper} from '../Elements/ElementsWrapper';
import {Moderation} from '../Moderation/Moderation';
import {TaskBar} from '../TaskBar/TaskBar';
import {Validity} from '../Validity/Validity';

const MainContent = () => {
    const dispatch = useDispatch();

    const mainContentComponent = useSelector(CockpitSelectors.selectMainContentComponent);
    const areElementsPopped = useSelector(CockpitSelectors.selectAreElementsPopped);

    const mainContentWrapper = useRef();

    let component = null;

    switch (mainContentComponent) {
        default:
        case MainContentComponents.MODERATION:
            component = (
                <Slide
                    key="moderation"
                    direction="up"
                    container={mainContentWrapper.current}
                    in
                    unmountOnExit
                    mountOnEnter
                >
                    <div
                        className={clsx(classes.moderationWrapper, {
                            [classes.poppedElements]: areElementsPopped,
                        })}
                    >
                        <Moderation />

                        {areElementsPopped ? (
                            <NewWindow
                                onUnload={() => {
                                    dispatch(CockpitActions.setAreElementsPopped(false));
                                }}
                                features={{width: 469, height: 657}}
                            >
                                <ElementsWrapper />
                            </NewWindow>
                        ) : <ElementsWrapper />}
                    </div>
                </Slide>
            );
            break;
        case MainContentComponents.EDITOR:
            component = (
                <div className={classes.editorWrapper}>
                    <Slide key="editor" direction="up" container={mainContentWrapper.current} in unmountOnExit mountOnEnter>
                        <Editor />
                    </Slide>
                </div>
            );
            break;
    }

    return (
        <div ref={mainContentWrapper} className={classes.slideWrapper}>
            {component}
        </div>
    );
};

export const TaskScreen = () => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);

    const areControlsPopped = useSelector(CockpitSelectors.selectAreControlsPopped);
    const areElementsPopped = useSelector(CockpitSelectors.selectAreElementsPopped);

    const task = useSelector(TaskSelectors.selectCurrentTask);

    return (
        <div className={classes.root}>
            <Fade in={!!isLoading} key="loading" timeout={225}>
                <div className={classes.loading}>
                    <LoadingBars size={48} color="regent" />
                </div>
            </Fade>

            <Fade in={!isLoading} key="content" timeout={225}>
                <div
                    className={clsx(classes.content, {
                        [classes.poppedControls]: areControlsPopped,
                        [classes.crossChannelTask]: task?.isCrossChannelTask,
                    })}
                >
                    <Collapse in={task?.isCrossChannelTask || false} unmountOnExit>
                        <div className={classes.topContainer}>
                            <RecurringTaskIcon size={24} />

                            <Typography color="white" weight={500} transform="uppercase">
                                <em>
                                    Recurring task
                                </em>
                            </Typography>
                        </div>
                    </Collapse>

                    <TaskBar />

                    <Validity />

                    <div
                        className={clsx(classes.mainContentWrapper, {
                            [classes.poppedElements]: areElementsPopped,
                        })}
                    >
                        <MainContent />
                    </div>

                    {areControlsPopped ? (
                        <NewWindow
                            onUnload={() => {
                                dispatch(CockpitActions.setAreControlsPopped(false));
                            }}
                            features={{width: 1920, height: 143}}
                        >
                            <Controls />
                        </NewWindow>
                    ) : <Controls />}

                    <Player />

                    <AddElementModal source="cockpit" />
                    <ProcessingTaskModal />
                    <ProcessingCompleteModal />
                    <AllTasksCompletedModal />
                    <CrossChannelTaskModal />
                </div>
            </Fade>
        </div>
    );
};
