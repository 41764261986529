// eslint-disable-next-line func-style
function Drafts() {
    this.version = 1;
    this.timeout = null;
    this.cache = {};
    this.keys = JSON.parse(localStorage.getItem('draft.keys') ?? '[]');
}

Drafts.prototype.generateKey = function (id) {
    return `draft.${id}`;
};

Drafts.prototype.storeDraft = function (id, state, validUntil) {
    if (this.timeout) {
        clearTimeout(this.timeout);
    }

    const draft = {
        state: JSON.stringify(state),
        validUntil,
        version: this.version,
    };
    const jsonDraft = JSON.stringify(draft);

    this.cache[id] = draft;

    this.timeout = setTimeout(() => {
        const key = this.generateKey(id);

        if (!this.keys.includes(key)) {
            this.keys = [...new Set([...this.keys, key])];

            localStorage.setItem('draft.keys', JSON.stringify(this.keys));
        }

        localStorage.setItem(key, jsonDraft);
    }, 375);
};

Drafts.prototype.getDraft = function (id) {
    if (this.cache[id]) {
        return this.cache[id];
    }

    const draft = localStorage.getItem(this.generateKey(id));

    if (!draft) {
        return null;
    }

    const parsedDraft = JSON.parse(draft);

    this.cache[id] = parsedDraft;

    return parsedDraft;
};

Drafts.prototype.removeDraft = function (id) {
    const key = this.generateKey(id);

    this.keys = this.keys.filter(storedKey => storedKey !== key);

    delete this.cache[id];

    localStorage.setItem('draft.keys', JSON.stringify(this.keys));
    localStorage.removeItem(this.generateKey(id));
};

Drafts.prototype.deleteExpiredDrafts = function () {
    for (const key of this.keys) {
        const draft = localStorage.getItem(key);

        if (!draft) {
            continue;
        }

        const parsedDraft = JSON.parse(draft);
        const validUntil = new Date(parsedDraft.validUntil);
        const version = parsedDraft.version;
        const now = new Date();

        if (validUntil.getTime() < now.getTime() || version !== this.version) {
            this.keys = this.keys.filter(storedKey => storedKey !== key);

            localStorage.setItem('draft.keys', JSON.stringify(this.keys));
            localStorage.removeItem(key);
        }
    }
};

export const drafts = new Drafts();
