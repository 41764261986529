import {RECORDER_STATUS} from './recorder.constants';
import {RecordingNode} from './RecordingNode';
import {encodeWav} from '../recording-algorithm/utils/encode-wav';

const Recorder = function () {
    /**
     * @type {AudioContext|null}
     */
    this.audioContext = null;

    /**
     * @type {RecordingNode|null}
     */
    this.recordingNode = null;

    this.isProcessing = false;

    this.status = RECORDER_STATUS.LOAD;

    this.blob = null;

    this.buffer = [];

    this.subscribers = [];

    this.animationFrame = null;

    this.startTime = 0;
    this.duration = 0;

    this.init = () => {
        this.setIsProcessing(true);

        return new Promise((resolve, reject) => {
            try {
                const inputDeviceId = localStorage.getItem('inputDeviceId');
                const outputDeviceId = localStorage.getItem('outputDeviceId');

                window.navigator.mediaDevices
                    .getUserMedia({
                        audio: {
                            autoGainControl: false,
                            echoCancellation: false,
                            noiseSuppression: false,
                            deviceId: {
                                exact: inputDeviceId || undefined,
                            },
                        },
                    }).then(async stream => {
                        this.audioContext = new AudioContext({
                            latencyHint: 'interactive',
                        });

                        await this.audioContext.suspend();

                        if (!this.audioContext.setSinkId) {
                            this.audio = new Audio();

                            this.destination = new MediaStreamAudioDestinationNode(this.audioContext);

                            this.audio.srcObject = this.destination.stream;

                            await this.audio.play();
                        }

                        if (outputDeviceId) {
                            this.setOutputDevice(outputDeviceId);
                        }

                        this.recordingNode = new RecordingNode(this.audioContext, stream);

                        await this.recordingNode.init(buffer => {
                            this.buffer.push(buffer);
                        });

                        this.setStatus(RECORDER_STATUS.LOADED);

                        this.animationFrame = window.requestAnimationFrame(this.onAnimationFrame);

                        resolve();
                    });
            } catch (error) {
                reject(error);
            } finally {
                this.setIsProcessing(false);
            }
        });
    };

    this.onAnimationFrame = () => {
        if (!this.audioContext) {
            return;
        }

        this.setDuration((this.audioContext.currentTime - this.startTime) * 1000);

        this.animationFrame = window.requestAnimationFrame(this.onAnimationFrame);
    };

    this.setOutputDevice = deviceId => {
        if (!this.audioContext) {
            return;
        }

        this.setIsProcessing(true);

        let contextOrAudio = this.audioContext;

        if (!this.audioContext.setSinkId) {
            contextOrAudio = this.audio;
        }

        contextOrAudio.setSinkId(deviceId)
            .then(() => {
                // eslint-disable-next-line no-console
                console.warn(`Success, audio output device attached: ${deviceId}`);
            })
            .catch(error => {
                let errorMessage = error;

                if (error.name === 'SecurityError') {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }

                // eslint-disable-next-line no-console
                console.error(errorMessage);
            })
            .finally(() => {
                this.setIsProcessing(false);
            });
    };

    this.start = async () => {
        this.setIsProcessing(true);

        await this.recordingNode.start();

        await this.audioContext.resume();

        this.startTime = this.audioContext.currentTime;

        this.setStatus(RECORDER_STATUS.RECORDING);

        this.setIsProcessing(false);
    };

    this.stop = async () => {
        this.setIsProcessing(true);

        await this.audioContext.close();

        await this.recordingNode.stop();

        if (process.env.NODE_ENV === 'development') {
            window.DOWNLOAD_WAV_FILES = true;
        }

        this.blob = await encodeWav(this.buffer, this.audioContext.sampleRate);

        this.setStatus(RECORDER_STATUS.RECORDING_READY);

        this.setIsProcessing(false);

        this.recordingNode = null;
        this.audioContext = null;
    };

    this.getBlob = () => {
        return this.blob;
    };

    this.clear = async () => {
        this.setIsProcessing(true);

        if (this.status === RECORDER_STATUS.RECORDING) {
            await this.stop();
        }

        this.setStatus(RECORDER_STATUS.LOAD);

        cancelAnimationFrame(this.animationFrame);

        this.file = null;

        this.setDuration(0);

        this.buffer = [];

        this.setIsProcessing(false);
    };

    this.setStatus = status => {
        this.status = status;

        this.notify('status', {status});
    };

    this.setIsProcessing = isProcessing => {
        this.isProcessing = isProcessing;

        this.notify('isProcessing', {isProcessing});
    };

    this.setDuration = duration => {
        this.duration = duration;

        this.notify('duration', {duration});
    };

    this.notify = (subscriptionType, data) => {
        this.subscribers.forEach(subscriber => {
            if (subscriptionType === subscriber.subscriptionType) {
                subscriber.callback(data);
            }
        });
    };

    this.subscribe = (subscriptionType, callback) => {
        const id = Math.max(...this.subscribers.map(subscriber => subscriber.id), 1) + 1;

        this.subscribers.push({
            id,
            subscriptionType,
            callback,
        });

        this.notify(subscriptionType, {[subscriptionType]: this[subscriptionType]});

        return () => {
            this.subscribers = this.subscribers.filter(subscriber => {
                return subscriber.id !== id;
            });
        };
    };
};

export const recorder = new Recorder();
