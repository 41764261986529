import Grow from '@mui/material/Grow';
import {useClickOutside} from '@react-hookz/web';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import classes from './Select.module.scss';
import {SelectIcon} from '../../icons';
import {composeRefs} from '../../utils/compose-refs';
import {Input} from '../Input/Input';
import {LoadingDots} from '../LoadingDots/LoadingDots';
import {Typography} from '../Typography/Typography';

const trim = value => value.replace(/^\s+|\s+$/g, '');
const defaultStringify = option => `${option.id} ${option.name}`;

const Menu = ({onClose, onSelect, options, selectedOption, isLoading, inputRef, isOpen}) => {
    const menuRef = useRef();
    const [isUpwards, setIsUpwards] = useState(false);

    useClickOutside(menuRef, event => {
        if (event instanceof KeyboardEvent && event.code !== 'Escape') {
            return;
        }

        const isMouseDownEvent = event instanceof MouseEvent && event.type === 'mousedown';

        if (isMouseDownEvent && inputRef.current.parentNode.contains(event.target)) {
            return;
        }

        onClose();
    }, ['mousedown', 'touchstart', 'keydown']);

    useLayoutEffect(() => {
        if (!isOpen) {
            return;
        }

        // Flag to ensure we only calculate once after opening
        let calculated = false;

        const observer = new MutationObserver(() => {
            if (calculated) return;

            // After the menu opens and the animation completes, calculate its position
            const animationFrameId = requestAnimationFrame(() => {
                const menuRect = menuRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                // Check if the bottom of the menu is outside the viewport
                if (menuRect.bottom > viewportHeight && !calculated) {
                    setIsUpwards(true);
                    calculated = true;
                }
            });

            // Cleanup the requestAnimationFrame
            return () => cancelAnimationFrame(animationFrameId);
        });

        observer.observe(menuRef.current, {attributes: true, childList: true, subtree: true});

        // Clean up the observer when done
        return () => {
            observer.disconnect();
        };
    }, [isOpen]);

    return (
        <Grow in={isOpen} mountOnEnter unmountOnExit>
            <div
                className={clsx(classes.menuRoot, {
                    [classes.isUpwards]: isUpwards,
                })}
                ref={menuRef}
            >
                {isLoading && (
                    <Typography className={classes.menuNote} weight={500} size={14} align="center" color="stone">
                        Loading...
                    </Typography>
                )}

                {!isLoading && !options.length && (
                    <Typography className={classes.menuNote} weight={500} size={14} align="center" color="stone">
                        No options
                    </Typography>
                )}

                {!isLoading && !!options.length && (
                    <ul className={classes.menuList}>
                        {options.map(({id, name}) => (
                            <li
                                tabIndex={0}
                                key={id}
                                className={clsx(classes.menuItem, {
                                    [classes.selected]: id === selectedOption,
                                })}
                                onClick={() => onSelect(id)}
                            >
                                {name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Grow>
    );
};

Menu.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    ]).isRequired,
};

Menu.defaultProps = {
    selectedOption: null,
};

export const Select = React.forwardRef((props, ref) => {
    const {
        options,
        value,
        onChange,
        inputId,
        isLoading,
        isDisabled,
        InputProps,
        onInputValueChange,
    } = props;
    const defaultOption = options?.find(option => option.id === value);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputValue, setInputValue] = useState(defaultOption?.name || '');
    const [suggestions, setSuggestions] = useState(options);

    const isInitialRender = useRef(true);
    const inputRef = useRef();
    const timeoutRef = useRef(null);

    const handleClick = () => {
        setIsMenuOpen(true);
    };

    const handleClose = () => {
        setIsMenuOpen(false);

        if (!value) {
            setInputValue('');
        }
    };

    const handleChange = event => {
        if (!isMenuOpen) {
            setIsMenuOpen(true);
        }

        const newValue = event.target.value;

        setInputValue(() => {
            const option = options.find(option => option.id === newValue);

            if (option?.name === inputValue) {
                return event.nativeEvent.data || '';
            }

            return newValue;
        });

        if (onInputValueChange && typeof onInputValueChange === 'function') {
            onInputValueChange(newValue, event);
        }
    };

    const handleSelect = id => {
        const option = options.find(option => option.id === id);

        setInputValue(option.name);

        onChange(option.id);

        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;

            return;
        }

        if (inputValue) {
            const trimmedValue = trim(inputValue).toLowerCase();
            const newSuggestions = options.filter(option => {
                const optionString = defaultStringify(option).toLowerCase();

                return optionString.indexOf(trimmedValue) > -1;
            });

            // if (newSuggestions.length === 1 && newSuggestions[0].id === value) {
            //     setSuggestions(options);
            //
            //     return;
            // }

            setSuggestions(newSuggestions);
        } else {
            setSuggestions(options);
        }
    }, [value, inputValue, options]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;

            return;
        }

        if (!isMenuOpen) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setSuggestions(options);
            }, 225);

            const option = options.find(option => option.id === value);

            setInputValue(option?.name || '');

            inputRef.current?.blur();
        }
    }, [isMenuOpen, options, value]);

    useEffect(() => {
        const option = options.find(option => option.id === value);

        setInputValue(option?.name || '');
        // eslint-disable-next-line
    }, [value]);

    return (
        <div className={classes.root}>
            <Input
                type="text"
                endIcon={<SelectIcon className={classes.selectIcon} color="silver" />}
                loadingIcon={isLoading ? <LoadingDots /> : null}
                value={inputValue}
                onClick={handleClick}
                onChange={handleChange}
                id={inputId}
                ref={composeRefs(inputRef, ref)}
                disabled={isDisabled}
                {...InputProps}
            />

            <Menu
                options={suggestions}
                onClose={handleClose}
                onSelect={handleSelect}
                selectedOption={value}
                isLoading={isLoading}
                inputRef={inputRef}
                isOpen={isMenuOpen}
            />
        </div>
    );
});

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    onInputValueChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    InputProps: PropTypes.shape(Input.propTypes),
    hasSuggestionFilter: PropTypes.bool,
};

Select.defaultProps = {
    isLoading: false,
    isDisabled: false,
    InputProps: {},
    onInputValueChange: null,
    value: null,
    hasSuggestionFilter: true,
};
