import GlobalStyles from '@mui/material/GlobalStyles';
import {useWindowSize} from '@react-hookz/web';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AddBedElement} from './AddBedElement';
import {AddPreProducedElement} from './AddPreProducedElement';
import {AddSfxElement} from './AddSfxElement';
import {SortableElement} from './Element';
import {Elements} from './Elements';
import classes from './ElementsWrapper.module.scss';
import {SUBSCRIPTION_TYPES} from '../../../../constants';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../../../features/current-task';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {useElementClick} from '../../hooks/use-element-click';
import {useProgress} from '../../hooks/use-progress';
import {useRemoveElement, useRemoveElementFromPreset} from '../../hooks/use-remove-element';
import {useSortableElements} from '../../hooks/use-sortable-elements';
import {CockpitActions} from '../../store/cockpit.action';
import {CockpitSelectors} from '../../store/cockpit.selector';
import {PopOutButton} from '../PopOutButton/PopOutButton';

// const useScrollableElements = ({elements, containerHeight, elementHeight, offset}) => {
//     const allElements = [...elements, 'add'];
//     let visibleElements = [];
//
//     if (!containerHeight) {
//         return {visibleElements};
//     }
//
//     const gridGap = 8;
//     let filledHeight = 0;
//     let currentElementIndex = offset;
//
//     while (filledHeight <= containerHeight) {
//         const newHeight = filledHeight + gridGap + elementHeight;
//         const newHeightAfterThisItem = newHeight + gridGap + elementHeight;
//
//         if (newHeightAfterThisItem > containerHeight) {
//             if (visibleElements.length <= allElements.length) {
//                 if (offset !== 0) {
//                     if (visibleElements[visibleElements.length - 1] !== 'add') {
//                         visibleElements.pop();
//                     }
//
//                     visibleElements = [
//                         'arrow-up',
//                         ...visibleElements,
//                     ];
//                 }
//
//                 if (visibleElements[visibleElements.length - 1] !== 'add') {
//                     visibleElements.push('arrow-down');
//                 }
//             }
//
//             break;
//         }
//
//         filledHeight = newHeight;
//
//         visibleElements.push(allElements[currentElementIndex]);
//         currentElementIndex += 1;
//     }
//
//     // visibleElements.push('add');
//
//     return {visibleElements};
// };

const SfxElement = ({elementId, title, length, index, onEndDrag, onMoveElement}) => {
    const {progress, timeLeft} = useProgress(elementId, SUBSCRIPTION_TYPES.SOUND_EFFECT_DATA, length);
    const handleRemove = useRemoveElement({elementId, elementType: 'sfx'});
    const handleRemoveFromPreset = useRemoveElementFromPreset({elementId, elementType: 'sfx'});
    const handleClick = useElementClick({elementId, elementType: 'sfx', index, title});

    return (
        <SortableElement
            index={index}
            elementId={elementId}
            color="sun"
            title={title}
            length={moment.utc(length).format('mm:ss')}
            progress={progress}
            timeLeft={timeLeft}
            elementType="sfx"
            onEndDrag={onEndDrag}
            onMoveElement={onMoveElement}
            onRemove={handleRemove}
            onRemoveFromPreset={handleRemoveFromPreset}
            onClick={handleClick}
        />
    );
};

SfxElement.propTypes = {
    title: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onMoveElement: PropTypes.func.isRequired,
};

const SfxElements = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('screensCockpit');

    const sfxElements = useMemoizedCreateSelector(
        CurrentTaskSelectors.createLoadedElementsByTypeSelector,
        'sfx',
    );

    const {
        elements,
        onEndDrag,
        onMoveElement,
    } = useSortableElements({
        initialElements: sfxElements,
        elementType: 'sfx',
    });

    // const [measurements, ref] = useMeasure();

    // const [offset, setOffset] = useState(0);

    const volume = useSelector(CurrentTaskSelectors.selectSfxVolume);

    const handleVolumeChange = useCallback(volume => {
        dispatch(CurrentTaskActions.setSfxVolume(volume, 'user'));
    }, [dispatch]);

    // const {visibleElements} = useScrollableElements({
    //     elements: sfxElements,
    //     containerHeight: measurements?.height,
    //     elementHeight: 84,
    //     offset,
    // });

    const {width} = useWindowSize();

    return (
        <Elements
            title={t('sfxBarTitle')}
            volume={volume}
            onVolumeChange={handleVolumeChange}
            audioType="sfx"
            size={width < 1280 ? 40 : 84}
        >
            {elements.map((element, index) => {
                return (
                    <SfxElement
                        key={element.id}
                        elementId={element.id}
                        index={index}
                        title={element.title}
                        length={element.length - element.cueIn}
                        onEndDrag={onEndDrag}
                        onMoveElement={onMoveElement}
                    />
                );
            })}

            <AddSfxElement size={width < 1280 ? 40 : 84} />
        </Elements>
    );
};

const PreProducedElement = ({elementId, title, length, index, onEndDrag, onMoveElement}) => {
    const {progress, timeLeft} = useProgress(elementId, SUBSCRIPTION_TYPES.PRE_PRODUCED_AUDIO_DATA, length);
    const handleRemove = useRemoveElement({elementId, elementType: 'preProduced'});
    const handleRemoveFromPreset = useRemoveElementFromPreset({elementId, elementType: 'preProduced'});
    const handleClick = useElementClick({elementId, elementType: 'preProduced', index, title});

    return (
        <SortableElement
            index={index}
            elementId={elementId}
            color="rose"
            title={title}
            length={moment.utc(length).format('mm:ss')}
            progress={progress}
            timeLeft={timeLeft}
            elementType="preProduced"
            onEndDrag={onEndDrag}
            onMoveElement={onMoveElement}
            onRemove={handleRemove}
            onRemoveFromPreset={handleRemoveFromPreset}
            onClick={handleClick}
        />
    );
};

PreProducedElement.propTypes = {
    title: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onMoveElement: PropTypes.func.isRequired,
};

const PreProducedElements = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('screensCockpit');

    const preProducedElements = useMemoizedCreateSelector(
        CurrentTaskSelectors.createLoadedElementsByTypeSelector,
        'preProduced',
    );

    const {
        elements,
        onEndDrag,
        onMoveElement,
    } = useSortableElements({
        initialElements: preProducedElements,
        elementType: 'preProduced',
    });

    // const [measurements, ref] = useMeasure();

    // const [offset, setOffset] = useState(0);

    const volume = useSelector(CurrentTaskSelectors.selectPreProducedVolume);

    const handleVolumeChange = useCallback(volume => {
        dispatch(CurrentTaskActions.setPreProducedVolume(volume, 'user'));
    }, [dispatch]);

    // const {visibleElements} = useScrollableElements({
    //     elements: sfxElements,
    //     containerHeight: measurements?.height,
    //     elementHeight: 84,
    //     offset,
    // });

    const {width} = useWindowSize();

    return (
        <Elements
            title={t('preProdBarTitle')}
            volume={volume}
            onVolumeChange={handleVolumeChange}
            audioType="preProduced"
            size={width < 1280 ? 40 : 84}
        >
            {elements.map((element, index) => {
                return (
                    <PreProducedElement
                        key={element.id}
                        elementId={element.id}
                        index={index}
                        title={element.title}
                        length={element.length - element.cueIn}
                        onEndDrag={onEndDrag}
                        onMoveElement={onMoveElement}
                    />
                );
            })}

            <AddPreProducedElement size={width < 1280 ? 40 : 84} />
        </Elements>
    );
};

const BedElement = ({elementId, title, length, index, onEndDrag, onMoveElement}) => {
    const {progress, timeLeft} = useProgress(elementId, SUBSCRIPTION_TYPES.MUSIC_BED_DATA, length);
    const handleRemove = useRemoveElement({elementId, elementType: 'bed'});
    const handleRemoveFromPreset = useRemoveElementFromPreset({elementId, elementType: 'bed'});
    const handleClick = useElementClick({elementId, elementType: 'bed', index, title});

    return (
        <SortableElement
            index={index}
            elementId={elementId}
            color="turquoise"
            title={title}
            length={moment.utc(length).format('mm:ss')}
            progress={progress}
            timeLeft={timeLeft}
            elementType="bed"
            onEndDrag={onEndDrag}
            onMoveElement={onMoveElement}
            onRemove={handleRemove}
            onRemoveFromPreset={handleRemoveFromPreset}
            onClick={handleClick}
        />
    );
};

BedElement.propTypes = {
    title: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onMoveElement: PropTypes.func.isRequired,
};

const BedElements = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('screensCockpit');

    // const [measurements, ref] = useMeasure();

    const bedElements = useMemoizedCreateSelector(
        CurrentTaskSelectors.createLoadedElementsByTypeSelector,
        'bed',
    );

    const {
        elements,
        onEndDrag,
        onMoveElement,
    } = useSortableElements({
        initialElements: bedElements,
        elementType: 'bed',
    });

    const volume = useSelector(CurrentTaskSelectors.selectBedVolume);

    const handleVolumeChange = useCallback(volume => {
        dispatch(CurrentTaskActions.setBedVolume(volume, 'user'));
    }, [dispatch]);

    // const {visibleElements} = useScrollableElements({
    //     elements: bedElements,
    //     containerHeight: measurements?.height,
    //     elementHeight: 84,
    // });

    const {width} = useWindowSize();

    return (
        <Elements
            title={t('bedBarTitle')}
            volume={volume}
            onVolumeChange={handleVolumeChange}
            audioType="bed"
            size={width < 1280 ? 40 : 84}
        >
            {elements.map((element, index) => {
                return (
                    <BedElement
                        key={element.id}
                        index={index}
                        elementId={element.id}
                        title={element.title}
                        length={element.length - element.cueIn}
                        onEndDrag={onEndDrag}
                        onMoveElement={onMoveElement}
                    />
                );
            })}

            <AddBedElement size={width < 1280 ? 40 : 84} />
        </Elements>
    );
};

export const ElementsWrapper = () => {
    const dispatch = useDispatch();

    const areElementsPopped = useSelector(CockpitSelectors.selectAreElementsPopped);

    return (
        <div
            className={clsx(classes.root, {
                [classes.poppedOut]: areElementsPopped,
            })}
        >
            <PreProducedElements />

            <SfxElements />

            <BedElements />

            {!areElementsPopped && (
                <PopOutButton onClick={() => dispatch(CockpitActions.setAreElementsPopped(true))} />
            )}

            {areElementsPopped && (
                <GlobalStyles
                    styles={{
                        body: {
                            backgroundColor: '#1c1d21',
                        },
                    }}
                />
            )}
        </div>
    );
};
