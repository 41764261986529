import {matchRoutes} from 'react-router-dom';
import {actionChannel, all, call, cancel, delay, fork, put, take} from 'redux-saga/effects';
import {configureAuth} from './features/auth/store/auth.saga';
import {BroadcastChannelActions, REQUEST_TYPES} from './features/broadcast-channel';
import {
    checkFailedUploads,
    extractInitialFailedUploadTaskIds,
} from './features/failed-uploads/store/failed-uploads.saga';
import {LOADING_TYPES, LoadingActions} from './features/loading';
import {recordingAlgorithmSaga} from './features/recording-algorithm/store/recording-algorithm.saga';
import {initializeWebSocket} from './features/web-socket/store/web-socket.loader-saga';
import {IndexedDb} from './lib/indexed-db';
import {drafts} from './screens/cockpit/utils/speaker-text-drafts';

export const END_SIDE_EFFECTS_RUNNING = '@@connected-router/END_SIDE_EFFECTS_RUNNING';

export const LOCATION_CHANGE_SIDE_EFFECTS = '@@connected-router/LOCATION_CHANGE_SIDE_EFFECTS';

const locationChangeSideEffects = payload => ({
    type: LOCATION_CHANGE_SIDE_EFFECTS,
    payload,
});

const prepareRouteState = function* (routes, location) {
    const matchedRouteBranch = matchRoutes(routes, location.pathname);

    if (!matchedRouteBranch) {
        return;
    }

    const branchSagas = getBranchSagas(matchedRouteBranch, location);

    yield* runBranchSagas(branchSagas);
};

const runBranchSagas = function* (branchSagas) {
    const subBranchSagas = [...branchSagas];

    let branchRouteSagas = subBranchSagas.pop() || [];

    if (branchRouteSagas.length) {
        branchRouteSagas = branchRouteSagas.reverse();
    }

    let shouldContinueRunning;

    while (branchRouteSagas.length > 0) {
        const [saga, ...args] = branchRouteSagas.pop();

        const {payload} = args[0];

        let effect = call;

        if (payload && payload.hasOwnProperty('isParallel') && payload.isParallel) {
            effect = fork;
        }

        shouldContinueRunning = yield effect(saga, ...args);

        if (shouldContinueRunning === END_SIDE_EFFECTS_RUNNING) {
            break;
        }
    }

    if (subBranchSagas.length > 0 && shouldContinueRunning !== END_SIDE_EFFECTS_RUNNING) {
        yield* runBranchSagas(subBranchSagas);
    }
};

const getBranchSagas = function (routeBranch, location) {
    const branchSagas = [];

    routeBranch
        .filter(branchItem => typeof branchItem.route.locationChangeSideEffects !== 'undefined'
            && Array.isArray(branchItem.route.locationChangeSideEffects))
        .forEach(branchItem => {
            const {route, params} = branchItem;
            const branchItemSideEffect = [];

            route.locationChangeSideEffects.forEach(sideEffect => {
                branchItemSideEffect.push([
                    sideEffect[0],
                    locationChangeSideEffects({
                        ...sideEffect[1],
                        params,
                        location,
                    }),
                ]);
            });

            branchSagas.push(branchItemSideEffect);
        });

    return branchSagas.reverse();
};

const purgeExpiredFlow = function* () {
    while (true) {
        yield all([
            call([IndexedDb, IndexedDb.purgeExpiredObjects]),
            call([IndexedDb, IndexedDb.deleteExpiredFailedUploads]),
            call([drafts, drafts.deleteExpiredDrafts]),
        ]);

        yield delay(60000);
    }
};

export const routerSaga = function* (routes) {
    const requestChannel = yield actionChannel('@@router/LOCATION_CHANGE');

    // Execute all the necessary things like configuration/initialization of libs before executing first side effect
    yield all([
        call(configureAuth),
        call([IndexedDb, IndexedDb.initialize]),
    ]);

    yield all([
        fork(purgeExpiredFlow),
        fork(initializeWebSocket),
    ]);

    let loader;

    while (true) {
        const {payload} = yield take(requestChannel);

        if (payload.action === 'POP' && payload.location.pathname.startsWith('/task')) {
            yield fork(recordingAlgorithmSaga);
        } else if (payload.action === 'POP' && payload.location.pathname === '/') {
            yield fork(checkFailedUploads);
            yield fork(extractInitialFailedUploadTaskIds);
            yield put(BroadcastChannelActions.send({
                messageType: 'request',
                requestType: REQUEST_TYPES.CURRENT_TASK_ID,
            }));
        } else if (payload.location.pathname.startsWith('/task')) {
            yield put(LoadingActions.setIsLoading(LOADING_TYPES.COCKPIT_DATA, true));
        }

        if (loader) {
            yield cancel(loader);
        }

        loader = yield fork(prepareRouteState, routes, payload.location);
    }
};
