import {useRoutes} from 'react-router-dom';

import {HorizontalLineLoader, ToastContainer} from './components';
import {ROUTES} from './config/routes';
import {ErrorBoundary} from './ErrorBoundary';
import {TaskDeletedModal} from './screens/cockpit/components/TaskScreen/TaskDeletedModal';

export const App = () => {
    const routes = useRoutes(ROUTES);

    return (
        <ErrorBoundary>
            <ToastContainer />

            {routes}

            <HorizontalLineLoader />

            <TaskDeletedModal />
        </ErrorBoundary>
    );
};
