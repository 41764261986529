import {UserActionTypes} from './user.action-type';

export const UserActions = {
    storeCurrentUser: currentUser => ({
        type: UserActionTypes.STORE_CURRENT_USER,
        payload: currentUser,
    }),
    storeSwitchingUser: switchingUser => ({
        type: UserActionTypes.STORE_SWITCHING_USER,
        payload: switchingUser,
    }),
};
