import {toast} from 'react-toastify';
import {i18n} from './i18next';
import {Alert} from '../components';

const Toast = function Toast() {
    this.success = function (text, params) {
        return toast(({closeToast}) => (
            <Alert onClose={closeToast} severity="success">
                {i18n.t(text, params)}
            </Alert>
        ));
    };

    this.error = function (text, params) {
        return toast(({closeToast}) => (
            <Alert onClose={closeToast} severity="error">
                {i18n.t(text, params)}
            </Alert>
        ));
    };

    this.warning = function (text, params) {
        return toast(({closeToast}) => (
            <Alert onClose={closeToast} severity="warning">
                {i18n.t(text, params)}
            </Alert>
        ));
    };

    this.info = function (text, params, options) {
        return toast(({closeToast}) => (
            <Alert onClose={closeToast}>
                {i18n.t(text, params)}
            </Alert>
        ), options);
    };

    this.dismiss = function (toastId) {
        toast.dismiss(toastId);
    };

    this.isActive = function (toastId) {
        return toast.isActive(toastId);
    };
};

export const toastInstance = new Toast();
