import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './Autoverpackung.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {PlayIcon, SoundIcon} from '../../../../icons';

export const Autoverpackung = ({autoverpackungKey, startTime}) => {
    const autoverpackung = useMemoizedCreateSelector(
        PlaylistSelectors.createAutoverpackungByKeySelector,
        autoverpackungKey,
    );

    const dispatch = useDispatch();

    const isPlaceholder = autoverpackung.name === 'Back2Back Placeholder Position';

    return (
        <div
            className={clsx(classes.root, {
                [classes.backToBackPlaceholder]: isPlaceholder,
            })}
        >
            <SoundIcon className={classes.icon} color="ecstasy" />

            <IconButton
                className={classes.playButton}
                color="ecstasy"
                backgroundColor="transparent"
                size={18}
                hoverStyle="color"
                onClick={() => dispatch(PlayerActions.loadItem(autoverpackung.externalId, autoverpackung.name))}
            >
                <PlayIcon />
            </IconButton>

            <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                {startTime}
            </Typography>

            <Typography weight={500} size={11} color="cello" className={classes.length}>
                {autoverpackung.formattedLength}
            </Typography>

            <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                {autoverpackung.name}
            </Typography>
        </div>
    );
};

Autoverpackung.propTypes = {
    autoverpackungKey: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
};
