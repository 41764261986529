import {
    AuthActionTypes,
    LoginFormActionTypes,
    NewPasswordFormActionTypes,
    ResetPasswordFormActionTypes,
} from './auth.action-type';

export const LoginFormActions = {
    submit: (username, password) => ({
        type: LoginFormActionTypes.SUBMIT,
        payload: {
            username,
            password,
        },
    }),
    setIsLoading: isLoading => ({
        type: LoginFormActionTypes.SET_IS_LOADING,
        payload: isLoading,
    }),
};

export const NewPasswordFormActions = {
    submit: password => ({
        type: NewPasswordFormActionTypes.SUBMIT,
        payload: {
            password,
        },
    }),
    setIsLoading: isLoading => ({
        type: NewPasswordFormActionTypes.SET_IS_LOADING,
        payload: isLoading,
    }),
    success: () => ({
        type: NewPasswordFormActionTypes.SUCCESS,
    }),
};

export const ResetPasswordFormActions = {
    submit: (code, password) => ({
        type: ResetPasswordFormActionTypes.SUBMIT,
        payload: {
            code,
            password,
        },
    }),
    submitSendCodeForm: email => ({
        type: ResetPasswordFormActionTypes.SUBMIT_SEND_CODE_FORM,
        payload: {
            email,
        },
    }),
    setIsLoading: isLoading => ({
        type: ResetPasswordFormActionTypes.SET_IS_LOADING,
        payload: isLoading,
    }),
    setStep: step => ({
        type: ResetPasswordFormActionTypes.SET_STEP,
        payload: step,
    }),
    setEmail: email => ({
        type: ResetPasswordFormActionTypes.SET_EMAIL,
        payload: email,
    }),
};

export const AuthActions = {
    logOut: () => ({
        type: AuthActionTypes.LOG_OUT,
    }),
};
