import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {CurrentTaskActions} from '../../../features/current-task';
import {PresetActions} from '../../../features/preset/store/preset.action';

export const useRemoveElement = ({elementId, elementType}) => {
    const dispatch = useDispatch();

    return useCallback(event => {
        event.stopPropagation();

        dispatch(CurrentTaskActions.removeLoadedElement(elementId, elementType));
    }, [dispatch, elementId, elementType]);
};

export const useRemoveElementFromPreset = ({elementId, elementType}) => {
    const dispatch = useDispatch();

    return useCallback(event => {
        event.stopPropagation();

        dispatch(PresetActions.removeElementFromLoadedPreset(elementId, elementType));
    }, [dispatch, elementId, elementType]);
};
