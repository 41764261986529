export const EditorConfig = {
    BaseColumnWidth: 100,
    MillisecondsPerColumn: 1000,
    ZoomFactorStep: 0.25,
    MinimalZoomFactor: 0.75,
    DefaultZoomFactor: 0.75,
    MaximalZoomFactor: 16,
    TimeFormat: 'mm:ss.SSS',
    ElementTimeFormat: 'mm:ss.SS',
};
