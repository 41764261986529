import moment from 'moment';
import {createSelector} from 'reselect';

const selectEntities = state => state.taskOverview.entities;

const selectBrandIds = state => state.taskOverview.ids;

const createBrandByIdSelector = brandId => createSelector(
    [selectEntities],
    entities => {
        return entities.brands[brandId];
    },
);

const createChannelByIdSelector = channelId => createSelector(
    [selectEntities],
    entities => {
        return entities.channels[channelId];
    },
);

const createDateByIdSelector = dateId => createSelector(
    [selectEntities],
    entities => {
        return entities.dates[dateId];
    },
);

const createDatesSelector = (channelId, selectedDate) => createSelector(
    [selectEntities],
    entities => {
        return Object.keys(entities.dates)
            .filter(dateId => {
                return parseInt(dateId.split('/')[0], 10) === channelId;
            })
            .reduce((accumulator, current, index, array) => {
                const momentSelectedDate = moment(selectedDate);
                const activeDateIndex = array.findIndex(id => {
                    return moment(id.split('/')[1], 'YYYY-MM-DD').isSame(momentSelectedDate, 'day');
                });

                if (index === activeDateIndex) {
                    accumulator.current = entities.dates[current];
                } else if (index === activeDateIndex - 1) {
                    accumulator.previous = entities.dates[current];
                } else if (index === activeDateIndex + 1) {
                    accumulator.next = entities.dates[current];
                }

                return accumulator;
            }, {
                previous: null,
                current: null,
                next: null,
            });
    },
);

// TODO: REMOVE
const createConsolidatedStatsForChannel = channelId => createSelector(
    [selectEntities],
    entities => {
        const channelStats = {
            count: 0,
            completedCount: 0,
        };

        if (!entities.dates) {
            return channelStats;
        }

        const channel = entities.channels[channelId];

        channel.dates.forEach(dateId => {
            channelStats.count += entities.dates[dateId].count;
            channelStats.completedCount += entities.dates[dateId].completedCount;
        });

        return channelStats;
    },
);

const createCumulativeAnimationDelaySelector = brandId => createSelector(
    [selectEntities, selectBrandIds],
    (entities, brandIds) => {
        let delay = 0;

        for (const id of brandIds) {
            if (id === brandId) {
                break;
            }

            const brand = entities.brands[id];

            delay += (brand.channels.length + 1) * 75 + 75;
        }

        return delay;
    },
);

const createHasUrgentTasksSelector = () => createSelector(
    [selectEntities],
    entities => {
        return !!Object.values(entities.channels).find(channel => channel.hasUrgentTasks);
    },
);

export const TaskOverviewSelectors = {
    selectEntities,
    selectBrandIds,
    createBrandByIdSelector,
    createChannelByIdSelector,
    createDateByIdSelector,
    createConsolidatedStatsForChannel,
    createCumulativeAnimationDelaySelector,
    createHasUrgentTasksSelector,
    createDatesSelector,
};
