import {useSelector} from 'react-redux';
import classes from './GenericTaskVariationInfo.module.scss';
import {Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {GenericTaskSelectors} from '../store/generic-task.selector';
import {formatRestrictions} from '../utils/format-restrictions';

export const GenericTaskVariationInfo = () => {
    const selectedGenericTaskVariationId = useSelector(GenericTaskSelectors.selectSelectedGenericTaskVariationId);
    const genericTaskVariation = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector,
        selectedGenericTaskVariationId,
    );
    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        genericTaskVariation?.genericTaskId,
    );

    if (!genericTaskVariation) {
        return null;
    }

    const brandName = genericTask?.brand?.name;
    const channelName = genericTask?.channel?.name;
    const weekday = genericTask?.weekday;
    const dayPart = genericTask?.dayPart;

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6" hasGutters={false} weight={500} size={16} color="white">
                    {genericTask.title}
                </Typography>

                <Typography weight={400} color="white" hasGutters={false} hasLineHeight={false}>
                    {formatRestrictions(brandName, channelName, weekday, dayPart)}
                </Typography>

                <Typography weight={400} color="white" hasGutters={false} hasLineHeight={false}>
                    {genericTaskVariation.title}
                </Typography>
            </div>
        </div>
    );
};
