import {useEffect, useState} from 'react';
import classes from './ModerationLength.module.scss';
import {Divider, Typography} from '../../../../components';
import {SUBSCRIPTION_TYPES} from '../../../../constants';
import recordingAlgorithm from '../../../../features/recording-algorithm/RecordingAlgorithm';
import {TaskSelectors} from '../../../../features/task';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {formatMillisecondsAsTimestamp} from '../../../../utils';

const EstimatedLength = () => {
    const estimatedLength = useMemoizedCreateSelector(TaskSelectors.createEstimatedLengthSelector);

    return (
        <Typography color="white" size={24} weight={500}>
            {estimatedLength}
        </Typography>
    );
};

const CurrentLength = () => {
    const [length, setLength] = useState('00:00');

    useEffect(() => {
        const onUpdate = ({moderationDuration}) => {
            setLength(formatMillisecondsAsTimestamp(moderationDuration));
        };

        const subscriber = recordingAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.MODERATION_DURATION);

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return (
        <Typography size={40} color="white" hasGutters={false} weight={500} align="right">
            {length}
        </Typography>
    );
};

export const ModerationLength = () => {
    return (
        <div className={classes.root}>
            <CurrentLength />

            <Divider isVertical />

            <div className={classes.estimatedLengthWrapper}>
                <Typography transform="uppercase" color="stone" size={12} weight={500} letterSpacing={1}>
                    Est.&nbsp;length
                </Typography>

                <EstimatedLength />
            </div>
        </div>
    );
};
