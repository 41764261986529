import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import toCamelCase from 'lodash.camelcase';
import {initReactI18next} from 'react-i18next';

const SUPPORTED_LANGUAGES = [
    'en',
    'de',
];

const TRANSLATION_NAMESPACES = [
    'features/auth',
    'features/preloading',
    'features/preset',
    'features/task',
    'features/recording-algorithm',
    'features/failed-uploads',
    'features/current-task',
    'features/task-overview',
    'screens/not-found',
    'screens/home',
    'screens/cockpit',
];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
    });

// Add resources to i18n instance
SUPPORTED_LANGUAGES.forEach(language => {
    TRANSLATION_NAMESPACES.forEach(namespaceKey => {
        const resources = require(`../${namespaceKey}/locale/${language}.json`);

        i18n.addResourceBundle(language, toCamelCase(namespaceKey), resources);
    });
});

export {i18n};

