import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useCallback, useRef, useState} from 'react';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classes from './InlineTaskPreparation.module.scss';
import {IconButton, PrimaryButton, SecondaryButton, Select, Tabs, TextButton, Typography} from '../../../../components';
import {ConfirmationDialog} from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import {TabPanels} from '../../../../components/Tabs/Tabs';
import {AUDIO_ELEMENT_TYPES} from '../../../../constants';
import {FilterSelectors} from '../../../../features/home-filters';
import {useFilterValue} from '../../../../features/home-filters/hooks/use-filter-value';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {Task} from '../../../../features/playlist/api/playlist.dto';
import {
    PreparedTaskVariationActions,
} from '../../../../features/prepared-task-variation/store/prepared-task-variation.action';
import {
    PreparedTaskVariationSelectors,
} from '../../../../features/prepared-task-variation/store/prepared-task-variation.selector';
import {PresetActions} from '../../../../features/preset/store/preset.action';
import {PresetSelectors} from '../../../../features/preset/store/preset.selector';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {CloseIcon, FolderIcon, LoadingBars, RestoreIcon, SoundIcon} from '../../../../icons';
import {Debug} from '../../../../lib/debug';
import {RESET_TEXT} from '../../../../lib/lexical/commands/reset-text-command';
import {HashtagNode} from '../../../../lib/lexical/nodes/HashtagNode';
import {MarkerNode} from '../../../../lib/lexical/nodes/MarkerNode';
import {HashtagMutationListener} from '../../../../lib/lexical/plugins/hashtag-mutation-listener';
import {PreProducedMarkerCommand} from '../../../../lib/lexical/plugins/pre-produced-marker-command';
import {ResetTextCommand} from '../../../../lib/lexical/plugins/reset-text-command';
import {RootListener} from '../../../../lib/lexical/plugins/root-listener';
import {AddElementModal} from '../../../cockpit/components/Elements/AddElementModal';
import {drafts} from '../../../cockpit/utils/speaker-text-drafts';

const LoadPresetDialog = ({taskVariationId}) => {
    const dispatch = useDispatch();

    const elementType = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.LOAD_PRESET);
    const isOpen = !!elementType;

    const presets = useMemoizedCreateSelector(PresetSelectors.createDropdownOptionsByTypeSelector, elementType);

    const loadingElementType = useIsLoading(LOADING_TYPES.LOAD_PRESETS);

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                dispatch(UiActions.setModalState(ModalKeys.LOAD_PRESET, null));
            }}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div className={classes.loadPresetRoot}>
                    <div className={classes.loadPresetContent}>
                        <Typography weight={500} color="regent" transform="uppercase">
                            Load audio elements from preset
                        </Typography>

                        <Typography
                            color="regent"
                            weight={500}
                            size={12}
                            transform="uppercase"
                            className={classes.label}
                        >
                            Choose from the list
                        </Typography>

                        <Select
                            inputId="presets"
                            onChange={preset => {
                                dispatch(UiActions.setModalState(ModalKeys.LOAD_PRESET, null));
                                dispatch(PreparedTaskVariationActions.loadFromPreset(
                                    preset,
                                    taskVariationId,
                                    elementType,
                                ));
                            }}
                            options={presets}
                            value={null}
                            isLoading={loadingElementType === elementType}
                            isDisabled={loadingElementType === elementType}
                            InputProps={{
                                theme: 'dark',
                            }}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

LoadPresetDialog.propTypes = {
    taskVariationId: PropTypes.number.isRequired,
};

const AudioTypeTab = ({isActive, label, onClick}) => {
    return (
        <button
            type="button"
            className={clsx(classes.tab, {
                [classes.active]: isActive,
            })}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

AudioTypeTab.propTypes = {
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

const Element = ({type, id, taskVariationId, index}) => {
    const dispatch = useDispatch();

    const element = useMemoizedCreateSelector(PreparedTaskVariationSelectors.createElementByIdSelector, id);

    const ref = useRef(null);

    const [{handlerId}, drop] = useDrop({
        accept: 'ELEMENT',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            let elementType = null;

            if (type === AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS) {
                elementType = 'preProduced';
            } else if (type === AUDIO_ELEMENT_TYPES.SOUND_EFFECTS) {
                elementType = 'sfx';
            } else if (type === AUDIO_ELEMENT_TYPES.MUSIC_BEDS) {
                elementType = 'bed';
            }

            if (!elementType) {
                // TODO: Add toast here?
                return;
            }

            dispatch(PreparedTaskVariationActions.sortElement(dragIndex, hoverIndex, elementType, taskVariationId));

            item.index = hoverIndex;
        },
        drop: item => {
            return {
                item,
                index,
            };
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: 'ELEMENT',
        item: () => {
            return {
                id,
                index,
                originalIndex: index,
            };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: (draggedItem, monitor) => {
            const didDrop = monitor.didDrop();
            const item = monitor.getItem();

            if (didDrop && index !== item.originalIndex) {
                dispatch(PreparedTaskVariationActions.sortElementDrop(taskVariationId));
            } else {
                let elementType = null;

                if (type === AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS) {
                    elementType = 'preProduced';
                } else if (type === AUDIO_ELEMENT_TYPES.SOUND_EFFECTS) {
                    elementType = 'sfx';
                } else if (type === AUDIO_ELEMENT_TYPES.MUSIC_BEDS) {
                    elementType = 'bed';
                }

                dispatch(PreparedTaskVariationActions.sortElement(
                    index,
                    item.originalIndex,
                    elementType,
                    taskVariationId,
                ));
            }
        },
    });

    const opacity = isDragging ? 0.25 : 1;

    drag(drop(ref));

    return (
        <div ref={ref} style={{opacity}} data-handler-id={handlerId} className={clsx(classes.element, classes[type])}>
            <SoundIcon className={classes.icon} />

            <Typography className={classes.title} lineClamp={2} weight={500} size={12}>
                {element?.title}
            </Typography>

            <IconButton
                backgroundColor="transparent"
                color="cello"
                size={18}
                iconSize={14}
                hoverStyle="color-opacity"
                onClick={() => {
                    let elementType = null;

                    if (type === AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS) {
                        elementType = 'preProduced';
                    } else if (type === AUDIO_ELEMENT_TYPES.SOUND_EFFECTS) {
                        elementType = 'sfx';
                    } else if (type === AUDIO_ELEMENT_TYPES.MUSIC_BEDS) {
                        elementType = 'bed';
                    }

                    dispatch(PreparedTaskVariationActions.removeElement(id, elementType, taskVariationId));
                }}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
};

Element.propTypes = {
    type: PropTypes.oneOf(Object.values(AUDIO_ELEMENT_TYPES)).isRequired,
    id: PropTypes.string.isRequired,
    taskVariationId: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

const ElementList = ({type, ids, taskVariationId}) => {
    return (
        <div
            className={clsx(classes.elementList, {
                [classes.empty]: !ids.length,
            })}
        >
            {!ids.length && (
                <Typography size={13} weight={500} color="mako">
                    There are no loaded elements.
                </Typography>
            )}

            {ids.map((id, index) => (
                <Element
                    key={id}
                    type={type}
                    id={id}
                    taskVariationId={taskVariationId}
                    index={index}
                />
            ))}
        </div>
    );
};

ElementList.propTypes = {
    type: PropTypes.oneOf(Object.values(AUDIO_ELEMENT_TYPES)).isRequired,
    taskVariationId: PropTypes.number.isRequired,
    ids: PropTypes.array,
};

ElementList.defaultProps = {
    ids: [],
};

const PreProducedElements = ({elements, brandId, taskVariationId}) => {
    const dispatch = useDispatch();

    return (
        <div>
            <ElementList
                type={AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS}
                ids={elements}
                taskVariationId={taskVariationId}
            />

            <div className={classes.audioActions}>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    onClick={() => {
                        dispatch(UiActions.setModalState(ModalKeys.ADD_ELEMENT_MODAL, 'preProduced'));
                    }}
                >
                    + Add element
                </TextButton>

                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    startIcon={<FolderIcon />}
                    onClick={() => {
                        dispatch(PresetActions.loadPresets(brandId, 'preProduced'));
                        dispatch(UiActions.setModalState(ModalKeys.LOAD_PRESET, 'preProduced'));
                    }}
                >
                    Load preset
                </TextButton>
            </div>
        </div>
    );
};

PreProducedElements.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.string).isRequired,
    brandId: PropTypes.number.isRequired,
    taskVariationId: PropTypes.number.isRequired,
};

const SfxElements = ({elements, brandId, taskVariationId}) => {
    const dispatch = useDispatch();

    return (
        <div>
            <ElementList
                type={AUDIO_ELEMENT_TYPES.SOUND_EFFECTS}
                ids={elements}
                taskVariationId={taskVariationId}
            />

            <div className={classes.audioActions}>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    onClick={() => {
                        dispatch(UiActions.setModalState(ModalKeys.ADD_ELEMENT_MODAL, 'sfx'));
                    }}
                >
                    + Add element
                </TextButton>

                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    startIcon={<FolderIcon />}
                    onClick={() => {
                        dispatch(PresetActions.loadPresets(brandId, 'sfx'));
                        dispatch(UiActions.setModalState(ModalKeys.LOAD_PRESET, 'sfx'));
                    }}
                >
                    Load preset
                </TextButton>
            </div>
        </div>
    );
};

SfxElements.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.string).isRequired,
    taskVariationId: PropTypes.number.isRequired,
    brandId: PropTypes.number.isRequired,
};

const BedElements = ({elements, brandId, taskVariationId}) => {
    const dispatch = useDispatch();

    return (
        <div>
            <ElementList
                type={AUDIO_ELEMENT_TYPES.MUSIC_BEDS}
                ids={elements}
                taskVariationId={taskVariationId}
            />

            <div className={classes.audioActions}>
                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    onClick={() => {
                        dispatch(UiActions.setModalState(ModalKeys.ADD_ELEMENT_MODAL, 'bed'));
                    }}
                >
                    + Add element
                </TextButton>

                <TextButton
                    color="radiance"
                    hoverColor="cello"
                    className={classes.addAudioButton}
                    startIcon={<FolderIcon />}
                    onClick={() => {
                        dispatch(PresetActions.loadPresets(brandId, 'bed'));
                        dispatch(UiActions.setModalState(ModalKeys.LOAD_PRESET, 'bed'));
                    }}
                >
                    Load preset
                </TextButton>
            </div>
        </div>
    );
};

BedElements.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.string).isRequired,
    taskVariationId: PropTypes.number.isRequired,
    brandId: PropTypes.number.isRequired,
};

const SpeakerTextToolbar = ({taskVariationId, originalSpeakerText}) => {
    const dispatch = useDispatch();

    const [editor] = useLexicalComposerContext();

    const isEditingSpeakerText = useSelector(PreparedTaskVariationSelectors.selectIsEditingSpeakerText);

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    return (
        <div className={classes.toolbar}>
            <AnimatePresence exitBeforeEnter>
                {!isEditingSpeakerText && (
                    <motion.div
                        key="div1"
                        initial={{opacity: 0, y: -40}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -40}}
                        style={{position: 'absolute', bottom: 8, right: 8, width: '100%'}}
                    >
                        <PrimaryButton
                            className={clsx(classes.editTextButton, classes.toolbarButton)}
                            onClick={() => {
                                editor.setEditable(true);
                                editor.focus();
                                dispatch(PreparedTaskVariationActions.setIsEditingSpeakerText(true));
                            }}
                        >
                            Edit text
                        </PrimaryButton>
                    </motion.div>
                )}

                {isEditingSpeakerText && (
                    <motion.div
                        key="div2"
                        initial={{opacity: 0, y: 40}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 40}}
                        style={{position: 'absolute', bottom: 8, right: 8, width: 'calc(100% - 16px)'}}
                    >
                        <div className={classes.toolbarControlsContainer}>
                            <div className={classes.toolbarControls}>
                                <SecondaryButton
                                    className={classes.toolbarButton}
                                    onClick={() => {
                                        dispatch(PreparedTaskVariationActions.setIsEditingSpeakerText(false));

                                        editor.dispatchCommand(RESET_TEXT, {
                                            isCancel: true,
                                        });
                                        editor.setEditable(false);
                                    }}
                                >
                                    Cancel
                                </SecondaryButton>

                                <SecondaryButton
                                    startIcon={<RestoreIcon />}
                                    className={classes.toolbarButton}
                                    onClick={() => setIsConfirmationDialogOpen(true)}
                                >
                                    Reset
                                </SecondaryButton>
                            </div>

                            <div className={classes.toolbarControlsRight}>
                                <PrimaryButton
                                    className={classes.toolbarButton}
                                    onClick={() => {
                                        drafts.removeDraft(taskVariationId);

                                        const editorState = editor.getEditorState();
                                        const speakerText = editorState.toJSON();

                                        dispatch(PreparedTaskVariationActions.updateSpeakerText(taskVariationId, speakerText, originalSpeakerText, 'user'));

                                        editor.setEditable(false);
                                        dispatch(PreparedTaskVariationActions.setIsEditingSpeakerText(false));
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <ConfirmationDialog
                message="This will discard all changes to the text and revert it to the original."
                confirm="Reset"
                title="Are you sure?"
                isOpen={isConfirmationDialogOpen}
                onCancel={() => setIsConfirmationDialogOpen(false)}
                onClose={() => setIsConfirmationDialogOpen(false)}
                onSubmit={() => {
                    dispatch(PreparedTaskVariationActions.setIsEditingSpeakerText(false));

                    editor.dispatchCommand(RESET_TEXT, {});
                    editor.setEditable(false);
                }}
            />
        </div>
    );
};

SpeakerTextToolbar.propTypes = {
    taskVariationId: PropTypes.number.isRequired,
    originalSpeakerText: PropTypes.string.isRequired,
};

SpeakerTextToolbar.defaultProps = {};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = error => {
    Debug.error('SpeakerText', 'Error: ', error);
};

const SpeakerText = ({task}) => {
    const dispatch = useDispatch();

    const startDate = useFilterValue('startDate');

    const preparedTaskVariation = useMemoizedCreateSelector(
        PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector,
        task.taskVariationId,
    );

    const preparedText = preparedTaskVariation?.speakerText;
    const text = task.speakerText;

    const editorStateDraft = drafts.getDraft(task.taskVariationId);
    let editorState = editorStateDraft ? editorStateDraft.state : undefined;

    if (preparedTaskVariation && !editorStateDraft) {
        editorState = preparedTaskVariation?.speakerText || task.speakerText;
    }

    const loadingTaskVariationId = useIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION);
    const isLoading = task.taskVariationId === loadingTaskVariationId;

    const handleInit = useCallback(() => {
        dispatch(PreparedTaskVariationActions.setIsEditingSpeakerText(true));
    }, [dispatch]);

    const handleChange = useCallback(editorState => {
        // TODO: Figure out if the task is reusable or not. If it is, use task.expirationDate instead of this.
        // Reason: The flag isReusable is missing on the API at the moment.
        const validUntil = new Date(`${startDate} ${task.startTime}`);

        const jsonState = JSON.stringify(editorState.toJSON());

        if (jsonState === preparedText || jsonState === text) {
            return;
        }

        drafts.storeDraft(task.taskVariationId, editorState.toJSON(), validUntil.toISOString());
    }, [preparedText, startDate, task, text]);

    return (
        <div className={classes.speakerText}>
            <div className={classes.header}>
                <Typography weight={500} color="cello">
                    Speaker text
                </Typography>
            </div>

            <div className={classes.textareaWrapper}>
                <LexicalComposer
                    initialConfig={{
                        namespace: 'MyEditor',
                        onError,
                        nodes: [HashtagNode, MarkerNode],
                        theme: {
                            'hashtag': classes.hashtag,
                            'marker': classes.marker,
                        },
                        editable: false,
                        editorState: editorState,
                    }}
                >
                    <OnChangePlugin
                        ignoreSelectionChange
                        ignoreHistoryMergeTagChange
                        onChange={handleChange}
                    />
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        placeholder={null}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ClearEditorPlugin />
                    <HistoryPlugin />
                    <PreProducedMarkerCommand />
                    <RootListener
                        taskVariationId={task.taskVariationId}
                        onInit={handleInit}
                        isLoading={isLoading}
                    />
                    <HashtagMutationListener />
                    <ResetTextCommand
                        originalSpeakerText={task.speakerText}
                        preparedSpeakerText={preparedTaskVariation?.speakerText}
                        onReset={isCancel => {
                            drafts.removeDraft(task.taskVariationId);

                            if (isCancel) {
                                dispatch(PreparedTaskVariationActions.updateSpeakerText(task.taskVariationId, preparedTaskVariation?.speakerText || null, task.speakerText, 'user'));
                            } else {
                                dispatch(PreparedTaskVariationActions.updateSpeakerText(task.taskVariationId, null, task.speakerText, 'user'));
                            }
                        }}
                    />

                    <SpeakerTextToolbar
                        taskVariationId={task.taskVariationId}
                        originalSpeakerText={task.speakerText}
                    />
                </LexicalComposer>
            </div>
        </div>
    );
};

SpeakerText.propTypes = {
    task: PropTypes.oneOfType([PropTypes.instanceOf(Task)]).isRequired,
};

export const InlineTaskPreparation = ({task}) => {
    const [audioType, setAudioType] = useState(AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS);

    const {t} = useTranslation('screensHome');

    const brandId = useMemoizedCreateSelector(FilterSelectors.createFilterFieldValueSelector, 'brand');

    const loadingTaskVariationId = useIsLoading(LOADING_TYPES.PREPARED_TASK_VARIATION);
    const isLoading = task.taskVariationId === loadingTaskVariationId;

    const preparedTaskVariation = useMemoizedCreateSelector(
        PreparedTaskVariationSelectors.createPreparedTaskVariationByTaskVariationIdSelector,
        task.taskVariationId,
    );

    return (
        <div
            className={clsx(classes.root, {
                [classes.isLoading]: isLoading,
            })}
        >
            {isLoading && (
                <LoadingBars />
            )}

            <div className={classes.rootContent} style={{visibility: isLoading ? 'hidden' : 'visible'}}>
                {!isLoading && (
                    <SpeakerText task={task} />
                )}

                <div className={classes.elements}>
                    <Typography weight={500} color="cello">
                        Audio elements
                    </Typography>

                    <Tabs
                        onChange={value => setAudioType(value)}
                        activeTab={audioType}
                        tabs={Object.values(AUDIO_ELEMENT_TYPES).map(item => ({
                            id: item,
                            label: t(`preparation.tabs.${item}`),
                            TabComponent: AudioTypeTab,
                        }))}
                    />

                    <DndProvider backend={HTML5Backend}>
                        <TabPanels
                            activeTab={audioType}
                            tabPanels={[
                                {
                                    id: AUDIO_ELEMENT_TYPES.PRE_PRODUCED_ELEMENTS,
                                    component: PreProducedElements,
                                    props: {
                                        elements: preparedTaskVariation?.preProducedIds ?? [],
                                        taskVariationId: task.taskVariationId,
                                        brandId,
                                    },
                                },
                                {
                                    id: AUDIO_ELEMENT_TYPES.SOUND_EFFECTS,
                                    component: SfxElements,
                                    props: {
                                        elements: preparedTaskVariation?.sfxIds ?? [],
                                        taskVariationId: task.taskVariationId,
                                        brandId,
                                    },
                                },
                                {
                                    id: AUDIO_ELEMENT_TYPES.MUSIC_BEDS,
                                    component: BedElements,
                                    props: {
                                        elements: preparedTaskVariation?.bedIds ?? [],
                                        taskVariationId: task.taskVariationId,
                                        brandId,
                                    },
                                },
                            ]}
                        />
                    </DndProvider>
                </div>
            </div>

            <AddElementModal
                source="taskList"
                brandId={brandId}
                taskVariationId={task.taskVariationId}
            />

            <LoadPresetDialog
                taskVariationId={task.taskVariationId}
            />
        </div>
    );
};

InlineTaskPreparation.propTypes = {
    task: PropTypes.oneOfType([PropTypes.instanceOf(Task)]).isRequired,
};
