import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useDebouncedEffect} from '@react-hookz/web';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import classes from './AddElementModal.module.scss';
import {AudioElementItem} from './AudioElementItem';
import {IconButton, Input, LoadingButton, Typography} from '../../../../components';
import {AudioItemActions} from '../../../../features/audio-item/store/audio-item.action';
import {AudioItemSelectors} from '../../../../features/audio-item/store/audio-item.selector';
import {CurrentTaskActions} from '../../../../features/current-task';
import {LOADING_TYPES, LoadingActions, useIsLoading} from '../../../../features/loading';
import {
    PreparedTaskVariationActions,
} from '../../../../features/prepared-task-variation/store/prepared-task-variation.action';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {CheckIcon, CloseIcon, LoadingBars} from '../../../../icons';
import {toastInstance} from '../../../../lib/toast';

export const AddElementModal = ({source, brandId, taskVariationId}) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState('');
    const dispatch = useDispatch();

    const elementType = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.ADD_ELEMENT_MODAL);
    const isOpen = !!elementType;
    const options = useMemoizedCreateSelector(AudioItemSelectors.createSelectOptionsSelector);
    const isLoading = useIsLoading(LOADING_TYPES.AUDIO_ITEMS);
    const isLoadingElement = useIsLoading(LOADING_TYPES.AUDIO_ITEM);

    const handleClose = () => {
        dispatch(UiActions.setModalState(ModalKeys.ADD_ELEMENT_MODAL, false));
        dispatch(AudioItemActions.storeIds([]));
        dispatch(AudioItemActions.storeEntities({}));
        setInputValue('');
        setValue('');
    };

    const handleClick = id => {
        setValue(id);
    };

    const handleInputValueChange = event => {
        dispatch(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEMS, true));
        dispatch(AudioItemActions.storeIds([]));
        dispatch(AudioItemActions.storeEntities({}));
        setInputValue(event.target.value);
        setValue('');
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (!value) {
            toastInstance.error('screensCockpit:missingElement');

            return;
        }

        if (source === 'taskList') {
            dispatch(PreparedTaskVariationActions.addElement(value, elementType, taskVariationId));
        } else {
            dispatch(CurrentTaskActions.loadElement(value, elementType));
        }

        setValue('');
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(AudioItemActions.getItems(elementType, undefined, source, brandId));
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useDebouncedEffect(() => {
        dispatch(AudioItemActions.getItems(elementType, inputValue, source, brandId));
    }, [inputValue], 375);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div
                    className={clsx(classes.root, {
                        [classes.light]: source === 'taskList',
                    })}
                >
                    <header className={classes.header}>
                        <Typography transform="uppercase" weight={500} color="regent" className={classes.title}>
                            Load audio
                        </Typography>

                        <IconButton
                            color="white"
                            backgroundColor="transparent"
                            size={22}
                            onClick={handleClose}
                            disabled={isLoadingElement}
                        >
                            <CloseIcon />
                        </IconButton>
                    </header>

                    <form onSubmit={handleSubmit}>
                        <Typography
                            htmlFor="audioElement"
                            variant="label"
                            size={12}
                            weight={500}
                            color="regent"
                            transform="uppercase"
                            className={classes.label}
                        >
                            Choose audio
                        </Typography>

                        <div>
                            <Input
                                value={inputValue}
                                onChange={handleInputValueChange}
                                theme="dark"
                                placeholder="Type to search..."
                                autoFocus
                                disabled={isLoadingElement}
                            />

                            <div className={classes.listWrapper}>
                                <ul className={classes.list}>
                                    {isLoading ? (
                                        <div className={classes.loading}>
                                            <LoadingBars size={48} color="white" />
                                        </div>
                                    ) : options.map(option => (
                                        <React.Fragment key={option.id}>
                                            <AudioElementItem
                                                id={option.id}
                                                name={option.name}
                                                isSelected={option.id === value}
                                                onClick={() => handleClick(option.id)}
                                            />

                                            <div className={classes.divider} />
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </div>

                            <LoadingButton
                                type="submit"
                                className={classes.submitButton}
                                startIcon={<CheckIcon />}
                                isDisabled={isLoadingElement}
                                isLoading={isLoadingElement}
                            >
                                Load
                            </LoadingButton>
                        </div>
                    </form>
                </div>
            </Fade>
        </Modal>
    );
};

AddElementModal.propTypes = {
    source: PropTypes.oneOf(['taskList', 'cockpit']).isRequired,
    brandId: PropTypes.number,
    taskVariationId: PropTypes.number,
};

AddElementModal.defaultProps = {
    brandId: null,
    taskVariationId: null,
};
