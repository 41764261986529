import {MixingPlanActionTypes} from './mixing-plan.action-type';

export const MixingPlanActions = {
    store: (mixingPlan, source) => ({
        type: MixingPlanActionTypes.STORE,
        payload: mixingPlan,
        source,
    }),
    storeOriginal: mixingPlan => ({
        type: MixingPlanActionTypes.STORE_ORIGINAL,
        payload: mixingPlan,
    }),
    moveElement: direction => ({
        type: MixingPlanActionTypes.MOVE_ELEMENT,
        payload: {
            direction,
        },
    }),
    reset: () => ({
        type: MixingPlanActionTypes.RESET,
    }),
};
