import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {PresetActions} from './preset.action';
import {PresetActionTypes} from './preset.action-type';
import {PresetSelectors} from './preset.selector';
import {handleSagaError} from '../../../error.saga';
import {toastInstance} from '../../../lib/toast';
import {RouterSelector} from '../../../router.selector';
import {AudioItemApi} from '../../audio-item/api/audio-item.api';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../current-task';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {FormKeys, ModalKeys} from '../../ui/utils/constants';
import {PresetApi} from '../api/preset.api';

const getAudioItemForElementCache = {};
const getPresetsCache = {};

const createPresetFlow = function* ({name, audioType, brandId, elementIds}) {
    try {
        const createdPreset = yield call(PresetApi.createPreset, {
            name,
            audioType,
            brandId,
            elementIds,
        });

        const presetEntities = yield select(PresetSelectors.selectEntities);

        yield put(PresetActions.storeEntities({
            ...presetEntities,
            [createdPreset.id]: createdPreset,
        }));

        toastInstance.success('featuresPreset:notifications.created');

        yield put(UiActions.setFormReset(FormKeys.SAVE_PRESET, true));

        switch (audioType) {
            case 'preProduced':
                yield put(PresetActions.setLoadedPreProducedPresetId(createdPreset.id));
                break;
            case 'sfx':
                yield put(PresetActions.setLoadedSfxPresetId(createdPreset.id));
                break;
            case 'bed':
                yield put(PresetActions.setLoadedBedPresetId(createdPreset.id));
                break;
            default:
                break;
        }
    } catch (error) {
        yield call(handleSagaError, error, 'createPresetFlow');
    }
};

const updatePresetFlow = function* ({presetId, name, elementIds}) {
    try {
        const preset = yield select(PresetSelectors.createPresetsByIdSelector(presetId));

        if (preset.name !== name || !isEqual(preset.elementIds, elementIds)) {
            const updatedPreset = yield call(PresetApi.updatePreset, {
                presetId,
                name,
                elementIds,
            });

            const presetEntities = yield select(PresetSelectors.selectEntities);

            yield put(PresetActions.storeEntities({
                ...presetEntities,
                [updatedPreset.id]: updatedPreset,
            }));

            toastInstance.success('featuresPreset:notifications.updated');
        }

        yield put(UiActions.setFormReset(FormKeys.SAVE_PRESET, true));
    } catch (error) {
        yield call(handleSagaError, error, 'updatePresetFlow');
    }
};

const deletePresetFlow = function* ({presetId}) {
    try {
        yield call(PresetApi.deletePreset, {presetId});

        const presetEntities = yield select(PresetSelectors.selectEntities);
        const updatedEntities = Object.values(presetEntities)
            .filter(preset => preset.id !== presetId)
            .reduce((accumulator, current) => {
                accumulator[current.id] = current;
                return accumulator;
            }, {});

        yield put(PresetActions.storeEntities(updatedEntities));

        toastInstance.success('featuresPreset:notifications.deleted');

        const loadedPreProducedPresetId = yield select(PresetSelectors.selectLoadedPreProducedPresetId);
        const loadedSfxPresetId = yield select(PresetSelectors.selectLoadedSfxPresetId);
        const loadedBedPresetId = yield select(PresetSelectors.selectLoadedBedPresetId);

        if (loadedPreProducedPresetId === presetId) {
            yield put(PresetActions.setLoadedPreProducedPresetId(null));
        } else if (loadedSfxPresetId === presetId) {
            yield put(PresetActions.setLoadedSfxPresetId(null));
        } else if (loadedBedPresetId === presetId) {
            yield put(PresetActions.setLoadedBedPresetId(null));
        }
    } catch (error) {
        yield call(handleSagaError, error, 'deletePresetFlow');
    }
};

const getPresetsFlow = function* ({brandId, presetType}) {
    try {
        const cacheKey = `${presetType}/${brandId}`;
        const cached = getPresetsCache[cacheKey];

        if (cached) {
            return new Promise(resolve => {
                resolve(cached);
            });
        }

        const presets = yield call(PresetApi.getPresets, {brandId, presetType});

        const presetEntities = yield select(PresetSelectors.selectEntities);

        getPresetsCache[cacheKey] = presets;

        yield put(PresetActions.storeEntities({
            ...presetEntities,
            ...presets,
        }));
    } catch (error) {
        yield call(handleSagaError, error, 'getPresetsFlow');
    }
};

const createPresetWorker = function* ({payload}) {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PRESET_FORM, true));

    const {name, audioType} = payload;

    const elements = yield select(CurrentTaskSelectors.createLoadedElementsByTypeSelector(audioType));
    const elementIds = elements.map(element => element.id);

    const {location} = yield select(RouterSelector.selectRouterState);
    const {search} = location;
    const queryParams = queryString.parse(search);
    const brandId = parseInt(queryParams.brandId, 10);

    yield call(createPresetFlow, {name, brandId, audioType, elementIds});

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PRESET_FORM, false));
};

const updatePresetWorker = function* ({payload}) {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PRESET_FORM, true));

    const {presetId, name, audioType} = payload;

    const elements = yield select(CurrentTaskSelectors.createLoadedElementsByTypeSelector(audioType));
    const elementIds = elements.map(element => element.id);

    yield call(updatePresetFlow, {presetId, name, elementIds});

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.PRESET_FORM, false));
};

const deletePresetWorker = function* ({payload}) {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.DELETE_PRESET, true));

    const {presetId} = payload;

    yield call(deletePresetFlow, {presetId});

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.DELETE_PRESET, false));
};

const getAudioItemForElement = function* ({itemId, elementType}) {
    try {
        const cacheKey = `${elementType}/${itemId}}`;
        const cached = getAudioItemForElementCache[cacheKey];

        if (cached) {
            return cached;
        }

        const audioItem = yield call(AudioItemApi.getAudioItem, {
            itemId,
            elementType,
        });

        getAudioItemForElementCache[cacheKey] = audioItem;

        return audioItem;
    } catch (error) {
        yield call(handleSagaError, error, 'getAudioItemForElement');
    }
};

const loadElementsFromPresetWorker = function* ({payload}) {
    yield put(LoadingActions.setIsLoading(LOADING_TYPES.LOAD_ELEMENTS_FROM_PRESET, true));

    const {presetId} = payload;
    const preset = yield select(PresetSelectors.createPresetsByIdSelector(presetId));

    const audioObjectRequests = [];

    preset.elementIds.forEach(elementId => {
        audioObjectRequests.push(call(getAudioItemForElement, {
            itemId: elementId,
            elementType: preset.audioType,
        }));
    });

    const items = yield all(audioObjectRequests);

    const entries = items.filter((item, index) => {
        const hasObject = !!item;

        if (!hasObject) {
            toastInstance.warning('featuresPreset:notifications.missingAudioObject', {
                id: preset.elementIds[index],
            });
        }

        return hasObject;
    });

    if (entries.length) {
        const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);

        yield put(CurrentTaskActions.setLoadedElements([
            // Save loaded elements, except the elements of this type
            // Example: If sfx elements are loaded, clear them, but keep preProduced and bed elements
            ...Object.values(loadedElements).filter(element => {
                return element.elementType !== preset.audioType;
            }),
            // Add the elements from the new preset
            ...entries,
        ], 'user'));
    }

    switch (preset.audioType) {
        case 'preProduced':
            yield put(PresetActions.setLoadedPreProducedPresetId(presetId));
            break;
        case 'sfx':
            yield put(PresetActions.setLoadedSfxPresetId(presetId));
            break;
        case 'bed':
            yield put(PresetActions.setLoadedBedPresetId(presetId));
            break;
        default:
            break;
    }

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.LOAD_ELEMENTS_FROM_PRESET, false));

    yield put(UiActions.setModalState(ModalKeys.PRESET_DROPDOWN, null));
};

const removeElementFromLoadedPresetWorker = function* ({payload}) {
    const {elementId, presetType} = payload;
    const loadedPresetId = yield select(PresetSelectors.createLoadedPresetIdByTypeSelector(presetType));

    if (!loadedPresetId) {
        return;
    }

    const loadedPreset = yield select(PresetSelectors.createPresetsByIdSelector(loadedPresetId));

    yield call(updatePresetFlow, {
        presetId: loadedPreset.id,
        name: loadedPreset.name,
        elementIds: loadedPreset.elementIds.filter(id => id !== elementId),
    });
};

const loadPresetsWorker = function* ({payload}) {
    const {brandId, presetType} = payload;

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.LOAD_PRESETS, presetType));

    yield call(getPresetsFlow, {brandId, presetType});

    yield put(LoadingActions.setIsLoading(LOADING_TYPES.LOAD_PRESETS, null));
};

export const loadPresetsForCurrentTask = function* ({brandId, presetType}) {
    yield call(getPresetsFlow, {brandId, presetType});
};

export const loadElementsFromPreset = function* ({presetId}) {
    yield call(loadElementsFromPresetWorker, {
        payload: {
            presetId,
        },
    });
};

export const presetSaga = function* () {
    yield all([
        takeLatest(PresetActionTypes.LOAD_PRESETS, loadPresetsWorker),
        takeEvery(PresetActionTypes.CREATE_PRESET, createPresetWorker),
        takeEvery(PresetActionTypes.UPDATE_PRESET, updatePresetWorker),
        takeEvery(PresetActionTypes.DELETE_PRESET, deletePresetWorker),
        takeEvery(PresetActionTypes.LOAD_ELEMENTS_FROM_PRESET, loadElementsFromPresetWorker),
        takeEvery(PresetActionTypes.REMOVE_ELEMENT_FROM_LOADED_PRESET, removeElementFromLoadedPresetWorker),
    ]);
};
