import {combineReducers} from 'redux';
import {TaskOverviewActionTypes} from './task-overview.action-type';
import {createReducer} from '../../../utils';

export const taskOverviewReducer = combineReducers({
    entities: createReducer({
        brands: {},
        channels: {},
        dates: {},
    }, TaskOverviewActionTypes.STORE_ENTITIES),
    ids: createReducer([], TaskOverviewActionTypes.STORE_IDS),
});
