import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './DdsFilter.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import {PlaylistSelectors} from '../../../../features/playlist';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {PlayIcon, SoundIcon} from '../../../../icons';

export const DdsFilter = ({ddsFilterKey}) => {
    const ddsFilter = useMemoizedCreateSelector(PlaylistSelectors.createDdsFilterByKeySelector, ddsFilterKey);

    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <SoundIcon className={classes.icon} color="ecstasy" />

            <IconButton
                className={classes.playButton}
                color="ecstasy"
                backgroundColor="transparent"
                size={18}
                hoverStyle="color"
                onClick={() => dispatch(PlayerActions.loadDdsFilter(ddsFilter.id))}
            >
                <PlayIcon />
            </IconButton>

            <Typography weight={500} size={11} color="cello" className={classes.startTime}>
                {ddsFilter.startTime}
            </Typography>

            <Typography weight={500} size={11} color="cello" className={classes.length}>
                {ddsFilter.formattedLength}
            </Typography>

            <Typography weight={500} size={12} color="cello" lineClamp={2} className={classes.name}>
                {ddsFilter.name}
            </Typography>
        </div>
    );
};

DdsFilter.propTypes = {
    ddsFilterKey: PropTypes.string.isRequired,
};
