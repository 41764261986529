import {call, spawn} from 'redux-saga/effects';
import {restoreFilterState} from '../../../features/home-filters/store/home-filters.saga';
import {loadTaskOverview} from '../../../features/task-overview/store/task-overview.loader-saga';
import {refreshTaskOverview} from '../../../features/task-overview/store/task-overview.saga';

export const homeScreenLoaderSaga = function* () {
    yield call(loadTaskOverview);
    yield call(restoreFilterState);
    yield spawn(refreshTaskOverview);
};
