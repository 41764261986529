import {combineReducers} from 'redux';
import {FailedUploadsActionTypes} from './failed-uploads.action-type';
import {createReducer} from '../../../utils';
import {FailedUploadStatuses} from '../utils/constants';

export const failedUploadsReducer = combineReducers({
    status: createReducer(FailedUploadStatuses.NO_DATA, FailedUploadsActionTypes.SET_STATUS),
    progress: createReducer(0, FailedUploadsActionTypes.SET_PROGRESS),
    failedUploadTaskIds: createReducer([], FailedUploadsActionTypes.STORE_FAILED_UPLOAD_TASK_IDS),
});
