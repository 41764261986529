import {useWindowSize} from '@react-hookz/web';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import classes from './Element.module.scss';
import {IconButton, Typography} from '../../../../components';
import {PresetSelectors} from '../../../../features/preset/store/preset.selector';
import {useMemoizedCreateSelector} from '../../../../hooks';
import {CloseIcon, UnlinkIcon} from '../../../../icons';
import {useDisabledControls} from '../../hooks/use-disabled-controls';
import {useSortableElement} from '../../hooks/use-sortable-element';

const VerticalElement = forwardRef((props, ref) => {
    const {
        elementId,
        color,
        title,
        length,
        onRemoveFromPreset,
        onRemove,
        onClick,
        progress,
        timeLeft,
        isElementInLoadedPreset,
        ...rest
    } = props;

    const {areControlsDisabled} = useDisabledControls();

    return (
        <div
            className={clsx(classes.verticalRoot, classes[`color-${color}`], {
                [classes.notInPreset]: !isElementInLoadedPreset,
            })}
            tabIndex={-1}
            onClick={onClick}
            ref={ref}
            {...rest}
        >
            <div className={classes.topContent}>
                <Typography
                    variant="body"
                    size={12}
                    weight={500}
                    color="white"
                    hasGutters={false}
                    className={classes.title}
                    title={title}
                    lineClamp={2}
                >
                    {title}
                </Typography>
            </div>

            <Typography
                variant="body"
                size={12}
                weight={500}
                color="white"
                hasGutters={false}
                className={classes.length}
            >
                {timeLeft}
            </Typography>

            <div className={classes.actions}>
                {isElementInLoadedPreset ? (
                    <IconButton
                        color="white"
                        hoverStyle="color"
                        backgroundColor="transparent"
                        size={22}
                        iconSize={12}
                        onClick={onRemoveFromPreset}
                        style={{zIndex: 2}}
                        disabled={areControlsDisabled}
                    >
                        <UnlinkIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        color="white"
                        hoverStyle="color"
                        backgroundColor="transparent"
                        size={22}
                        iconSize={12}
                        onClick={onRemove}
                        style={{zIndex: 2}}
                        disabled={areControlsDisabled}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </div>

            <div className={classes.background} />
            <div className={classes.progress} style={{width: progress === 0 ? '7px' : `${100 - progress}%`}}>
                <Typography
                    variant="body"
                    size={12}
                    weight={500}
                    color="white"
                    hasGutters={false}
                    className={classes.length}
                >
                    {timeLeft}
                </Typography>
            </div>
        </div>
    );
});

VerticalElement.propTypes = {
    color: PropTypes.oneOf(['rose', 'sun', 'turquoise']).isRequired,
    onClick: PropTypes.func.isRequired,
    elementId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    onRemoveFromPreset: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired,
    timeLeft: PropTypes.string.isRequired,
    isElementInLoadedPreset: PropTypes.bool.isRequired,
};

const HorizontalElement = forwardRef((props, ref) => {
    const {
        elementId,
        color,
        title,
        length,
        onRemoveFromPreset,
        onRemove,
        onClick,
        progress,
        timeLeft,
        isElementInLoadedPreset,
        ...rest
    } = props;

    const {areControlsDisabled} = useDisabledControls();

    return (
        <div
            ref={ref}
            className={clsx(classes.horizontalRoot, classes[`color-${color}`], {
                [classes.notInPreset]: !isElementInLoadedPreset,
            })}
            onClick={onClick}
            {...rest}
        >
            {/* <SoundInfoIcon className={classes.soundInfoIcon} /> */}

            <div className={classes.content}>
                <div>
                    <Typography
                        variant="body"
                        size={12}
                        weight={500}
                        color="white"
                        hasGutters={false}
                        className={classes.title}
                        title={title}
                        lineClamp={2}
                    >
                        {title}
                    </Typography>

                    <Typography
                        variant="body"
                        size={12}
                        weight={500}
                        color="white"
                        hasGutters={false}
                        className={classes.length}
                    >
                        {timeLeft}
                    </Typography>
                </div>

                {isElementInLoadedPreset ? (
                    <IconButton
                        color="white"
                        hoverStyle="color"
                        backgroundColor="transparent"
                        size={22}
                        iconSize={12}
                        onClick={onRemoveFromPreset}
                        style={{zIndex: 2}}
                        disabled={areControlsDisabled}
                    >
                        <UnlinkIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        color="white"
                        hoverStyle="color"
                        backgroundColor="transparent"
                        size={22}
                        iconSize={12}
                        onClick={onRemove}
                        style={{zIndex: 2}}
                        disabled={areControlsDisabled}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </div>

            <div className={classes.background} />
            <div className={classes.progress} style={{width: progress === 0 ? '7px' : `${100 - progress}%`}} />
        </div>
    );
});

HorizontalElement.propTypes = {
    color: PropTypes.oneOf(['rose', 'sun', 'turquoise']).isRequired,
    onClick: PropTypes.func.isRequired,
    elementId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    onRemoveFromPreset: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired,
    timeLeft: PropTypes.string.isRequired,
    isElementInLoadedPreset: PropTypes.bool.isRequired,
};

export const Element = forwardRef((props, ref) => {
    const isElementInLoadedPreset = useMemoizedCreateSelector(
        PresetSelectors.createIsElementInLoadedPresetSelector,
        props.elementId,
    );

    const {width} = useWindowSize();

    if (width < 1280) {
        return <HorizontalElement isElementInLoadedPreset={isElementInLoadedPreset} {...props} ref={ref} />;
    }

    return <VerticalElement isElementInLoadedPreset={isElementInLoadedPreset} {...props} ref={ref} />;
});

Element.propTypes = {
    color: PropTypes.oneOf(['rose', 'sun', 'turquoise']).isRequired,
    onClick: PropTypes.func.isRequired,
    elementId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    onRemoveFromPreset: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired,
    timeLeft: PropTypes.string.isRequired,
};

export const SortableElement = props => {
    const {elementId, elementType, index, onEndDrag, onMoveElement, ...rest} = props;

    const {
        ref,
        handlerId,
        opacity,
    } = useSortableElement({
        elementType,
        elementId,
        index,
        onEndDrag,
        onMoveElement,
    });

    return (
        <Element elementId={elementId} {...rest} ref={ref} data-handler-id={handlerId} style={{opacity}} />
    );
};

SortableElement.propTypes = {
    ...Element.propTypes,
    elementType: PropTypes.oneOf(['preProduced', 'sfx', 'bed']).isRequired,
    index: PropTypes.number.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onMoveElement: PropTypes.func.isRequired,
};
