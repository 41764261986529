import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import classes from './VoiceInfo.module.scss';
import {ReactComponent as VoiceLevelLeftHigh} from '../../../../assets/svg/voice-level-left-high.svg';
import {ReactComponent as VoiceLevelLeftLow} from '../../../../assets/svg/voice-level-left-low.svg';
import {ReactComponent as VoiceLevelLeftMedium} from '../../../../assets/svg/voice-level-left-medium.svg';
import {ReactComponent as VoiceLevelRightHigh} from '../../../../assets/svg/voice-level-right-high.svg';
import {ReactComponent as VoiceLevelRightLow} from '../../../../assets/svg/voice-level-right-low.svg';
import {ReactComponent as VoiceLevelRightMedium} from '../../../../assets/svg/voice-level-right-medium.svg';
import {Typography} from '../../../../components';
import {TaskSelectors} from '../../../../features/task';

const VOICE_LEVEL_START_MAP = {
    1: VoiceLevelLeftLow,
    2: VoiceLevelLeftMedium,
    3: VoiceLevelLeftHigh,
};

const VOICE_LEVEL_END_MAP = {
    1: VoiceLevelRightLow,
    2: VoiceLevelRightMedium,
    3: VoiceLevelRightHigh,
};

export const VoiceInfo = () => {
    const {t} = useTranslation('featuresTask');

    const task = useSelector(TaskSelectors.selectCurrentTask);

    const taskMetadata = task?.metadata;
    const voiceLevelStart = taskMetadata?.voiceLevelStart;
    const voiceLevelEnd = taskMetadata?.voiceLevelEnd;
    const voiceMood = taskMetadata?.voiceMood;

    return (
        <div className={classes.root}>
            {voiceLevelStart && React.createElement(VOICE_LEVEL_START_MAP[task.metadata.voiceLevelStart])}

            <div className={classes.center}>
                <Typography color="white" transform="uppercase" weight={500} letterSpacing={1} hasLineHeight={false}>
                    {t(`voiceMoods.${voiceMood}`)}
                </Typography>
            </div>

            {voiceLevelEnd && React.createElement(VOICE_LEVEL_END_MAP[task.metadata.voiceLevelEnd])}
        </div>
    );
};
