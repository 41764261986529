import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './IconButton.module.scss';
import {COLOR_NAMES} from '../../utils';

export const IconButton = React.forwardRef((props, ref) => {
    const {children, className, color, backgroundColor, size, iconSize, hoverStyle, ...rest} = props;

    return (
        <button
            className={clsx(
                classes.root,
                classes[`background-color-${backgroundColor}`],
                classes[`color-${color}`],
                classes[`size-${size}`],
                classes[`hover-style-${hoverStyle}`],
                className,
            )}
            type="button"
            ref={ref}
            {...rest}
        >
            {React.cloneElement(children, {className: clsx(classes.icon, children.props.className, classes[`icon-size-${iconSize}`])})}
        </button>
    );
});

IconButton.propTypes = {
    children: PropTypes.element.isRequired,
    color: PropTypes.oneOf(COLOR_NAMES).isRequired,
    backgroundColor: PropTypes.oneOf([...COLOR_NAMES, 'transparent']).isRequired,
    size: PropTypes.oneOf([18, 22, 24, 32, 40]),
    iconSize: PropTypes.oneOf([12, 14, 18, 20, 24, 30]),
    hoverStyle: PropTypes.oneOf(['backgroundColor', 'color', 'color-opacity']),
    className: PropTypes.string,
};

IconButton.defaultProps = {
    size: 40,
    iconSize: 18,
    className: '',
    hoverStyle: 'backgroundColor',
};
