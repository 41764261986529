import {RecordingAlgorithmActionTypes} from './recording-algorithm.action-type';

export const RecordingAlgorithmActions = {
    executeNextCommand: ({shouldForce = false} = {}) => ({
        type: RecordingAlgorithmActionTypes.EXECUTE_NEXT_COMMAND,
        shouldForce,
    }),
    end: () => ({
        type: RecordingAlgorithmActionTypes.END,
    }),
    reset: () => ({
        type: RecordingAlgorithmActionTypes.RESET,
    }),
    play: () => ({
        type: RecordingAlgorithmActionTypes.PLAY,
    }),
    pause: () => ({
        type: RecordingAlgorithmActionTypes.PAUSE,
    }),
};
