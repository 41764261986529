import {createSelector} from 'reselect';

const selectEntities = state => state.channel.entities;

const createDropdownOptionsSelector = brandId => {
    return createSelector(
        [selectEntities],
        entities => {
            if (!entities.hasOwnProperty(brandId)) {
                return [];
            }

            return Object.values(entities[brandId]).sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}));
        },
    );
};

const createIdsByBrandSelector = brandId => {
    return createSelector(
        [selectEntities],
        entities => {
            if (!entities.hasOwnProperty(brandId)) {
                return [];
            }

            return Object
                .values(entities[brandId])
                .sort((a, b) => {
                    return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map(channel => {
                    return channel.id;
                });
        },
    );
};

const createChannelByIdSelector = (brandId, channelId) => {
    return createSelector(
        [selectEntities],
        entities => {
            if (!entities.hasOwnProperty(brandId)) {
                return null;
            }

            return entities[brandId][channelId];
        },
    );
};

export const ChannelSelectors = {
    selectEntities,
    createDropdownOptionsSelector,
    createIdsByBrandSelector,
    createChannelByIdSelector,
};
