import {combineReducers} from 'redux';
import {CockpitActionTypes} from './cockpit.action-type';
import {createReducer} from '../../../utils';
import {MainContentComponents} from '../utils/constants';

export const cockpitReducer = combineReducers({
    mainContentComponent: createReducer(
        MainContentComponents.MODERATION,
        CockpitActionTypes.SET_MAIN_CONTENT_COMPONENT,
    ),
    isEditingSpeakerText: createReducer(false, CockpitActionTypes.SET_IS_EDITING_SPEAKER_TEXT),
    areControlsPopped: createReducer(false, CockpitActionTypes.SET_ARE_CONTROLS_POPPED),
    areElementsPopped: createReducer(false, CockpitActionTypes.SET_ARE_ELEMENTS_POPPED),
});
