import {call, cancelled} from 'redux-saga/effects';

export const withAbortController = (request, ...args) => {
    return function* () {
        const controller = new AbortController();

        try {
            return yield call(request, ...args, {
                signal: controller.signal,
            });
        } finally {
            if (yield cancelled()) {
                controller.abort();
            }
        }
    };
};
