import {Auth} from 'aws-amplify';
import axios from 'axios';
import {CONFIG} from '../config';
import {userSwitcher} from '../features/auth/utils/switch';
import {logTailService} from '../features/log-tail/log-tail.service';
import {parseJsonWebToken} from '../utils/parse-json-web-token';

export const ddsPlanningApi = axios.create({
    baseURL: CONFIG.DDS_PLANNING.API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

ddsPlanningApi.interceptors.request.use(async config => {
    const session = await Auth.currentSession();

    if (!session) {
        return config;
    }

    let accessToken = session.getAccessToken().getJwtToken();

    if (!accessToken) {
        return config;
    }

    // Sometimes it happens that the request is sent with an expired JWT (?)
    // Seems like AWS Amplify gives us an expired token, so this is a try to refresh it again
    const data = parseJsonWebToken(accessToken);
    const isExpired = Date.now() > ((data.exp * 1000) - 5000);

    if (isExpired) {
        await new Promise(async (resolve, reject) => {
            try {
                const user = await Auth.currentAuthenticatedUser();

                user.refreshSession(session.getRefreshToken(), async (err, data) => {
                    resolve(data);
                });
            } catch (error) {
                return config;
            }
        });

        accessToken = session.getAccessToken().getJwtToken();

        if (!accessToken) {
            return config;
        }
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    const canSwitch = userSwitcher.getCanSwitch();

    if (canSwitch) {
        const email = userSwitcher.getEmail();

        if (email) {
            if (!config.params) {
                config.params = {};
            }

            // eslint-disable-next-line no-underscore-dangle
            config.params._switch_user = email;
        }
    }

    return config;
}, error => {
    // eslint-disable-next-line no-console
    console.error('ERROR:', {error});

    return logTailService.error('[AXIOS]: ' + error.message, {
        error: error.toJSON(),
        logKey: 'axios.interceptor.request',
        version: CONFIG.APP_VERSION,
    }).then(() => {
        return Promise.reject(error);
    });
});

ddsPlanningApi.interceptors.response.use(undefined, error => {
    // eslint-disable-next-line no-console
    console.error('ERROR:', {error});

    if (error?.config?.url === '/v1/playlist-item/previous' || error?.config?.url === '/v1/playlist-item/next') {
        return Promise.reject(error);
    } else if (error?.message === 'canceled') {
        return Promise.reject(error);
    }

    return logTailService.error('[AXIOS]: ' + error.message, {
        error: error.toJSON(),
        logKey: 'axios.interceptor.response',
        version: CONFIG.APP_VERSION,
    }).then(() => {
        return Promise.reject(error);
    });
});
