import {all, fork, put, race, select, take, takeEvery} from 'redux-saga/effects';
import {EditorActions} from './editor.action';
import {EditorActionTypes} from './editor.action-type';
import {EditorSelectors} from './editor.selector';
import {EditorConfig} from '../utils/constants';

const handleZoomWorker = function* ({type}) {
    const currentZoomFactor = yield select(EditorSelectors.selectZoomFactor);

    if (type === EditorActionTypes.ZOOM_IN) {
        const newZoomFactor = currentZoomFactor + EditorConfig.ZoomFactorStep;

        if (newZoomFactor > EditorConfig.MaximalZoomFactor) {
            return;
        }

        yield put(EditorActions.setZoomFactor(newZoomFactor));

        return;
    }

    const newZoomFactor = currentZoomFactor - EditorConfig.ZoomFactorStep;

    if (newZoomFactor < EditorConfig.MinimalZoomFactor) {
        return;
    }

    yield put(EditorActions.setZoomFactor(newZoomFactor));
};

const playbackAlgorithmInitializationWatcher = function* () {
    while (true) {
        const {error} = yield race({
            error: take('AUTOMATIC_PLAYBACK_FAILED'),
            success: take('PLAYBACK_ALGORITHM_INITIALIZED'),
        });

        yield put(EditorActions.setIsUserGestureRequired(!!error));
    }
};

export const editorSaga = function* () {
    yield all([
        takeEvery([EditorActionTypes.ZOOM_IN, EditorActionTypes.ZOOM_OUT], handleZoomWorker),
        fork(playbackAlgorithmInitializationWatcher),
    ]);
};
