/**
 * Receive interleaved audio frames to another thread, wait-free.
 *
 * GC _can_ happen during the initial construction of this object when hopefully
 * no audio is being output. This depends on how implementations schedule GC
 * passes. After the setup phase no GC is triggered on either side of the queue.
 */
export class AudioReader {
    /**
     * From a RingBuffer, build an object that can dequeue audio in a ring
     * buffer.
     * @constructor
     */
    constructor(ringbuf) {
        if (ringbuf.type() !== 'Float32Array') {
            throw TypeError('This class requires a ring buffer of Float32Array');
        }
        this.ringbuf = ringbuf;
    }

    /**
     * Attempt to dequeue at most `buf.length` samples from the queue. This
     * returns the number of samples dequeued. If greater than 0, the samples are
     * at the beginning of `buf`.
     *
     * Care should be taken to dequeue a number of samples that is a multiple of the
     * channel count of the audio stream.
     *
     * @param {Float32Array} buf A buffer in which to copy the dequeued
     * interleaved audio frames.
     * @return The number of samples dequeued.
     */
    dequeue(buf) {
        if (this.ringbuf.empty()) {
            return 0;
        }
        return this.ringbuf.pop(buf);
    }

    /**
     * Query the occupied space in the queue.
     *
     * @return The amount of samples that can be read with a guarantee of success.
     *
     */
    availableRead() {
        return this.ringbuf.availableRead();
    }
}

/** The base RingBuffer class
 *
 * A Single Producer - Single Consumer thread-safe wait-free ring buffer.
 *
 * The producer and the consumer can be on separate threads, but cannot change roles,
 * except with external synchronization.
 */
export class RingBuffer {
    /** Allocate the SharedArrayBuffer for a RingBuffer, based on the type and
     * capacity required
     * @param {number} capacity The number of elements the ring buffer will be
     * able to hold.
     * @param {TypedArray} type A typed array constructor, the type that this ring
     * buffer will hold.
     * @return {SharedArrayBuffer} A SharedArrayBuffer of the right size.
     * @static
     */
    static getStorageForCapacity(capacity, type) {
        if (!type.BYTES_PER_ELEMENT) {
            throw TypeError('Pass in a ArrayBuffer subclass');
        }
        const bytes = 8 + (capacity + 1) * type.BYTES_PER_ELEMENT;
        // eslint-disable-next-line no-undef
        return new SharedArrayBuffer(bytes);
    }

    /**
     * @constructor
     * @param {SharedArrayBuffer} sab A SharedArrayBuffer obtained by calling
     * {@link RingBuffer.getStorageFromCapacity}.
     * @param {TypedArray} type A typed array constructor, the type that this ring
     * buffer will hold.
     */
    constructor(sab, type) {
        if (type.BYTES_PER_ELEMENT === undefined) {
            throw TypeError('Pass a concrete typed array class as second argument');
        }

        // Maximum usable size is 1<<32 - type.BYTES_PER_ELEMENT bytes in the ring
        // buffer for this version, easily changeable.
        // -4 for the write ptr (uint32_t offsets)
        // -4 for the read ptr (uint32_t offsets)
        // capacity counts the empty slot to distinguish between full and empty.
        // eslint-disable-next-line no-underscore-dangle
        this._type = type;
        // eslint-disable-next-line no-underscore-dangle
        this._capacity = (sab.byteLength - 8) / type.BYTES_PER_ELEMENT;
        this.buf = sab;
        this.writePtr = new Uint32Array(this.buf, 0, 1);
        this.readPtr = new Uint32Array(this.buf, 4, 1);
        // eslint-disable-next-line new-cap,no-underscore-dangle
        this.storage = new type(this.buf, 8, this._capacity);
    }

    /**
     * @return the type of the underlying ArrayBuffer for this RingBuffer. This
     * allows implementing crude type checking.
     */
    type() {
        // eslint-disable-next-line no-underscore-dangle
        return this._type.name;
    }

    /**
     * Push elements to the ring buffer.
     * @param {TypedArray} elements A typed array of the same type as passed in the ctor, to be written to the queue.
     * @param {Number} length If passed, the maximum number of elements to push.
     * If not passed, all elements in the input array are pushed.
     * @param {Number} offset If passed, a starting index in elements from which
     * the elements are read. If not passed, elements are read from index 0.
     * @return the number of elements written to the queue.
     */
    push(elements, length, offset = 0) {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        // eslint-disable-next-line no-underscore-dangle
        if ((wr + 1) % this._storageCapacity() === rd) {
            // full
            return 0;
        }

        const len = length !== undefined ? length : elements.length;

        // eslint-disable-next-line no-underscore-dangle
        const toWrite = Math.min(this._availableWrite(rd, wr), len);
        // eslint-disable-next-line no-underscore-dangle
        const firstPart = Math.min(this._storageCapacity() - wr, toWrite);
        const secondPart = toWrite - firstPart;

        // eslint-disable-next-line no-underscore-dangle
        this._copy(elements, offset, this.storage, wr, firstPart);
        // eslint-disable-next-line no-underscore-dangle
        this._copy(elements, offset + firstPart, this.storage, 0, secondPart);

        // publish the enqueued data to the other side
        // eslint-disable-next-line no-undef
        Atomics.store(
            this.writePtr,
            0,
            // eslint-disable-next-line no-underscore-dangle
            (wr + toWrite) % this._storageCapacity(),
        );

        return toWrite;
    }

    /**
     * Write bytes to the ring buffer using callbacks. This create wrapper
     * objects and can GC, so it's best to no use this variant from a real-time
     * thread such as an AudioWorklerProcessor `process` method.
     * The callback is passed two typed arrays of the same type, to be filled.
     * This allows skipping copies if the API that produces the data writes is
     * passed arrays to write to, such as `AudioData.copyTo`.
     * @param {number} amount The maximum number of elements to write to the ring
     * buffer. If amount is more than the number of slots available for writing,
     * then the number of slots available for writing will be made available: no
     * overwriting of elements can happen.
     * @param {Function} cb A callback with two parameters, that are two typed
     * array of the correct type, in which the data need to be copied. It is
     * necessary to write exactly the number of elements determined by the size
     * of the two typed arrays.
     * @return The number of elements written to the queue.
     */
    writeCallback(amount, cb) {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        // eslint-disable-next-line no-underscore-dangle
        if ((wr + 1) % this._storageCapacity() === rd) {
            // full
            return 0;
        }

        // eslint-disable-next-line no-underscore-dangle
        const toWrite = Math.min(this._availableWrite(rd, wr), amount);
        // eslint-disable-next-line no-underscore-dangle
        const firstPart = Math.min(this._storageCapacity() - wr, toWrite);
        const secondPart = toWrite - firstPart;

        // This part will cause GC: don't use in the real time thread.
        // eslint-disable-next-line no-underscore-dangle
        const firstPartBuf = new this._type(
            this.storage.buffer,
            8 + wr * 4,
            firstPart,
        );
        // eslint-disable-next-line no-underscore-dangle
        const secondPartBuf = new this._type(
            this.storage.buffer,
            8,
            secondPart,
        );

        cb(firstPartBuf, secondPartBuf);

        // publish the enqueued data to the other side
        // eslint-disable-next-line no-undef
        Atomics.store(
            this.writePtr,
            0,
            // eslint-disable-next-line no-underscore-dangle
            (wr + toWrite) % this._storageCapacity(),
        );

        return toWrite;
    }

    /**
     * Read up to `elements.length` elements from the ring buffer. `elements` is a typed
     * array of the same type as passed in the ctor.
     * Returns the number of elements read from the queue, they are placed at the
     * beginning of the array passed as parameter.
     * @param {TypedArray} elements An array in which the elements read from the
     * queue will be written, starting at the beginning of the array.
     * @param {Number} length If passed, the maximum number of elements to pop. If
     * not passed, up to elements.length are popped.
     * @param {Number} offset If passed, an index in elements in which the data is
     * written to. `elements.length - offset` must be greater or equal to
     * `length`.
     * @return The number of elements read from the queue.
     */
    pop(elements, length, offset = 0) {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        if (wr === rd) {
            return 0;
        }

        const len = length !== undefined ? length : elements.length;
        // eslint-disable-next-line no-underscore-dangle
        const toRead = Math.min(this._availableRead(rd, wr), len);

        // eslint-disable-next-line no-underscore-dangle
        const firstPart = Math.min(this._storageCapacity() - rd, toRead);
        const secondPart = toRead - firstPart;

        // eslint-disable-next-line no-underscore-dangle
        this._copy(this.storage, rd, elements, offset, firstPart);
        // eslint-disable-next-line no-underscore-dangle
        this._copy(this.storage, 0, elements, offset + firstPart, secondPart);

        // eslint-disable-next-line no-undef,no-underscore-dangle
        Atomics.store(this.readPtr, 0, (rd + toRead) % this._storageCapacity());

        return toRead;
    }

    /**
     * @return True if the ring buffer is empty false otherwise. This can be late
     * on the reader side: it can return true even if something has just been
     * pushed.
     */
    empty() {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        return wr === rd;
    }

    /**
     * @return True if the ring buffer is full, false otherwise. This can be late
     * on write side: it can return true when something has just been popped.
     */
    full() {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        // eslint-disable-next-line no-underscore-dangle
        return (wr + 1) % this._storageCapacity() === rd;
    }

    /**
     * @return The usable capacity for the ring buffer: the number of elements
     * that can be stored.
     */
    capacity() {
        // eslint-disable-next-line no-underscore-dangle
        return this._capacity - 1;
    }

    /**
     * @return The number of elements available for reading. This can be late, and
     * report less elements that is actually in the queue, when something has just
     * been enqueued.
     */
    availableRead() {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        // eslint-disable-next-line no-underscore-dangle
        return this._availableRead(rd, wr);
    }

    /**
     * @return The number of elements available for writing. This can be late, and
     * report less elements that is actually available for writing, when something
     * has just been dequeued.
     */
    availableWrite() {
        // eslint-disable-next-line no-undef
        const rd = Atomics.load(this.readPtr, 0);
        // eslint-disable-next-line no-undef
        const wr = Atomics.load(this.writePtr, 0);

        // eslint-disable-next-line no-underscore-dangle
        return this._availableWrite(rd, wr);
    }

    // private methods //

    /**
     * @return Number of elements available for reading, given a read and write
     * pointer.
     * @private
     */
    _availableRead(rd, wr) {
        // eslint-disable-next-line no-underscore-dangle
        return (wr + this._storageCapacity() - rd) % this._storageCapacity();
    }

    /**
     * @return Number of elements available from writing, given a read and write
     * pointer.
     * @private
     */
    _availableWrite(rd, wr) {
        // eslint-disable-next-line no-underscore-dangle
        return this.capacity() - this._availableRead(rd, wr);
    }

    /**
     * @return The size of the storage for elements not accounting the space for
     * the index, counting the empty slot.
     * @private
     */
    _storageCapacity() {
        // eslint-disable-next-line no-underscore-dangle
        return this._capacity;
    }

    /**
     * Copy `size` elements from `input`, starting at offset `offsetInput`, to
     * `output`, starting at offset `offsetOutput`.
     * @param {TypedArray} input The array to copy from
     * @param {Number} offsetInput The index at which to start the copy
     * @param {TypedArray} output The array to copy to
     * @param {Number} offsetOutput The index at which to start copying the elements to
     * @param {Number} size The number of elements to copy
     * @private
     */
    _copy(input, offsetInput, output, offsetOutput, size) {
        for (let i = 0; i < size; i += 1) {
            output[offsetOutput + i] = input[offsetInput + i];
        }
    }
}
