import {AUDIO_ELEMENT_TYPES, FADE_OUT_VARS} from './audioContextConstants';
import recordingAlgorithm from './RecordingAlgorithm';
import VolumeChangePoint from './VolumeChangePoint';
import {Debug} from '../../lib/debug';
import {MixingPlan} from '../mixing-plan/dto/mixing-plan.dto';

class RecordingMetadataTransformer {
    /**
     * @param {Array<Number>} recordingDurations
     */
    constructor(recordingDurations) {
        this.#recordingMetadata = this.#transformRecordingMetadata(recordingDurations);
        this.#preProducedAudiosMetadata = this.#transformPreProducedAudiosMetadata();
        this.#soundEffectsMetadata = this.#transformSoundEffectsMetadata();
        this.#musicBedsMetadata = this.#transformMusicBedsMetadata();

        Debug.debug('RecordingMetadataTransformer', 'Init', {
            recording: this.#recordingMetadata,
            preProducedAudios: this.#preProducedAudiosMetadata,
            soundEffectsMetadata: this.#soundEffectsMetadata,
            musicBedsMetadata: this.#musicBedsMetadata,
            soundSettings: recordingAlgorithm.soundSettings,
        });
    }

    /**
     * @type {{stopAt: number, audioBuffer: AudioBuffer | WebGLBuffer, startAt: number}[]}
     * @private
     */
    #recordingMetadata = [];

    /**
     * @type {{stopAt: number, startAt: number, audioNode: PreProducedAudioNode}[]}
     * @private
     */
    #preProducedAudiosMetadata = [];

    /**
     * @type {{stopAt: number, startAt: number, audioNode: BaseAudioNode, volumeChangePoints: VolumeChangePoint[]}[]}
     * @private
     */
    #soundEffectsMetadata = [];

    /**
     * @type {{stopAt: number, startAt: number, audioNode: BaseAudioNode, volumeChangePoints: VolumeChangePoint[]}[]}
     * @private
     */
    #musicBedsMetadata = [];

    #firstRecordingStartAt = 0;

    /**
     * @param {Array<Number>} recordingDurations
     * @returns {{stopAt: number, startAt: number}[]|*[]}
     */
    #transformRecordingMetadata = recordingDurations => {
        if (!recordingAlgorithm.getRecordedStreams().length) {
            return [];
        }

        const recordingOffsetsMap = {};
        let previousRecordingStopAt = 0;
        let recordingOffsetIndex = 0;

        recordingAlgorithm.getPreProducedAudioNodes().forEach((preProducedAudio, index) => {
            if (preProducedAudio.skippedAt) {
                recordingOffsetIndex += 1;
                recordingOffsetsMap[recordingOffsetIndex] = index;
            }
        });

        return recordingAlgorithm.getRecordedStreams().map((recordedStream, index) => {
            let startAt = previousRecordingStopAt;

            if (index === 0 && recordingAlgorithm.getPreviousElementNode()) {
                const cueIn = recordingAlgorithm.getPreviousElementNode().audioObject.cueIn / 1000;
                const startNext = recordingAlgorithm.getPreviousElementNode().audioObject.startNext / 1000;
                startAt = startNext - (cueIn + startNext - 3);

                if (startNext === 0) {
                    startAt = recordingAlgorithm.getPreviousElementNode().duration;
                }

                /**
                 * Sometimes, the formula above gives negative values.
                 * In this case, we just set startAt to 3 seconds. Confirmed with Regiocast guys.
                 */
                if (startAt < 0) {
                    startAt = 3;
                }

                this.#firstRecordingStartAt = startAt;
            } else if (index > 0) {
                const preProducedAudio = recordingAlgorithm.getPreProducedAudioNodes()[recordingOffsetsMap[index]];
                startAt += preProducedAudio.getSkippedDuration();
            }

            const stopAt = startAt + recordingDurations[index];
            previousRecordingStopAt = stopAt;

            return {
                startAt,
                stopAt,
            };
        });
    };

    /**
     * @returns {[]|{stopAt: number, audioNode: PreProducedAudioNode, startAt: number}[]}
     */
    #transformPreProducedAudiosMetadata = () => {
        if (!recordingAlgorithm.getPreProducedAudioNodes().length) {
            return [];
        }

        const metadata = [];

        recordingAlgorithm.getPreProducedAudioNodes().forEach(audioNode => {
            audioNode.startedAt.forEach((startTime, startTimeIndex) => {
                const moveStartOffset = audioNode.getStartOffsetBasedOnSkippedDurations(startTimeIndex);
                const extendDurationOffset = audioNode.getExtendingOffsetBasedOnSkippedDurations(startTimeIndex);
                const negativeOffset = this.#calculateNegativeOffset();

                let startAt = startTime + moveStartOffset;
                let stopAt = audioNode.stoppedAt[startTimeIndex] + moveStartOffset + extendDurationOffset;

                // Include negative offset
                startAt -= negativeOffset;
                stopAt -= negativeOffset;

                // Recalculate volume change points - there's only fade out
                const volumeChangePoints = audioNode.volumeChangePoints.map(volumeChangePoint => {
                    const duration = volumeChangePoint.stopAt - volumeChangePoint.startAt;

                    volumeChangePoint.startAt = stopAt - duration + moveStartOffset;
                    volumeChangePoint.stopAt = stopAt + moveStartOffset + extendDurationOffset;

                    if (audioNode.didPlayOnStart) {
                        const cueIn = recordingAlgorithm.getPreviousElementNode()
                            ? recordingAlgorithm.getPreviousElementNode().audioObject.cueIn / 1000
                            : 0;

                        volumeChangePoint.startAt -= this.#firstRecordingStartAt + cueIn;
                        volumeChangePoint.stopAt -= this.#firstRecordingStartAt + cueIn;
                    }

                    return volumeChangePoint;
                });

                metadata.push({
                    startAt: startAt,
                    audioNode,
                    stopAt,
                    volumeChangePoints,
                    finalVolume: audioNode.volumes[startTimeIndex],
                });
            });
        });

        return metadata;
        // if (!recordingAlgorithm.getPreProducedAudioNodes().length) {
        //     return [];
        // }
        //
        // return recordingAlgorithm.getPreProducedAudioNodes().map((audioNode, index) => {
        //     let startAt = audioNode.startedAt[0];
        //
        //     startAt += this.#calculateSkippedDurationBasedOnIndex(index);
        //     startAt -= this.#calculateNegativeOffset();
        //
        //     return {
        //         startAt: startAt,
        //         stopAt: startAt + audioNode.duration,
        //         audioNode,
        //     };
        // });
    };

    /**
     * Sound effects can be played more than once.
     * For each startedAt time, return a new set of metadata.
     *
     * @returns {{stopAt: number, startAt: number, audioNode: BaseAudioNode, volumeChangePoints: VolumeChangePoint[]}[]}
     */
    #transformSoundEffectsMetadata = () => {
        if (!recordingAlgorithm.getSoundEffectAudioNodes().length) {
            return [];
        }

        const metadata = [];

        recordingAlgorithm.getSoundEffectAudioNodes().forEach(audioNode => {
            audioNode.startedAt.forEach((startTime, startTimeIndex) => {
                const moveStartOffset = audioNode.getStartOffsetBasedOnSkippedDurations(startTimeIndex);
                const extendDurationOffset = audioNode.getExtendingOffsetBasedOnSkippedDurations(startTimeIndex);
                const negativeOffset = this.#calculateNegativeOffset();

                let startAt = startTime + moveStartOffset;
                let stopAt = audioNode.stoppedAt[startTimeIndex] + moveStartOffset + extendDurationOffset;

                // if (audioNode.didPlayOnStart && startTimeIndex === 0) {
                //     const cueIn = recordingAlgorithm.getPreviousElementNode()
                //         ? recordingAlgorithm.getPreviousElementNode().audioObject.cueIn / 1000
                //         : 0;
                //
                //     startAt -= this.#firstRecordingStartAt + cueIn;
                //     stopAt -= this.#firstRecordingStartAt + cueIn;
                // }

                // Include negative offset
                startAt -= negativeOffset;
                stopAt -= negativeOffset;

                // Recalculate volume change points - there's only fade out
                const volumeChangePoints = audioNode.volumeChangePoints.map(volumeChangePoint => {
                    const duration = volumeChangePoint.stopAt - volumeChangePoint.startAt;

                    volumeChangePoint.startAt = stopAt - duration + moveStartOffset;
                    volumeChangePoint.stopAt = stopAt + moveStartOffset + extendDurationOffset;

                    if (audioNode.didPlayOnStart) {
                        const cueIn = recordingAlgorithm.getPreviousElementNode()
                            ? recordingAlgorithm.getPreviousElementNode().audioObject.cueIn / 1000
                            : 0;

                        volumeChangePoint.startAt -= this.#firstRecordingStartAt + cueIn;
                        volumeChangePoint.stopAt -= this.#firstRecordingStartAt + cueIn;
                    }

                    return volumeChangePoint;
                });

                metadata.push({
                    startAt: startAt,
                    audioNode,
                    stopAt,
                    volumeChangePoints,
                    finalVolume: audioNode.volumes[startTimeIndex],
                });
            });
        });

        return metadata;
    };

    /**
     * Music beds can be played more than once.
     * For each startedAt time, return a new set of metadata.
     *
     * @returns {{stopAt: number, startAt: number, audioNode: BaseAudioNode, volumeChangePoints: VolumeChangePoint[]}[]}
     */
    #transformMusicBedsMetadata = () => {
        if (!recordingAlgorithm.getMusicBedAudioNodes().length) {
            return [];
        }

        const metadata = [];

        recordingAlgorithm.getMusicBedAudioNodes().forEach(audioNode => {
            audioNode.startedAt.forEach((startTime, startTimeIndex) => {
                const moveStartOffset = audioNode.getStartOffsetBasedOnSkippedDurations(startTimeIndex);
                const extendDurationOffset = audioNode.getExtendingOffsetBasedOnSkippedDurations(startTimeIndex);
                const negativeOffset = this.#calculateNegativeOffset();

                let startAt = startTime + moveStartOffset;
                let stopAt = audioNode.stoppedAt[startTimeIndex] + moveStartOffset + extendDurationOffset;

                // Include negative offset
                startAt -= negativeOffset;
                stopAt -= negativeOffset;

                const elementType = audioNode.audioObject.objectElementType;

                if (elementType && !['BED', 'BB', 'DRN'].includes(elementType)) {
                    stopAt = startAt + audioNode.duration;
                }

                // Recalculate volume change points - only fade out exists for music beds
                const volumeChangePoints = audioNode.volumeChangePoints
                    .filter(volumeChangePoint => {
                        // Filter only volume change points for this occurrence
                        const volumePointStartAt = volumeChangePoint.startAt + moveStartOffset;

                        const volumePointStopAt = volumeChangePoint.stopAt + moveStartOffset + extendDurationOffset;

                        return volumePointStartAt >= startAt && volumePointStopAt <= stopAt;
                    })
                    .map(volumeChangePoint => {
                        const fadeOutDuration = volumeChangePoint.stopAt - volumeChangePoint.startAt;

                        volumeChangePoint.startAt = stopAt - fadeOutDuration;
                        volumeChangePoint.stopAt = stopAt;

                        if (audioNode.didPlayOnStart) {
                            const cueIn = recordingAlgorithm.getPreviousElementNode()
                                ? recordingAlgorithm.getPreviousElementNode().audioObject.cueIn / 1000
                                : 0;

                            volumeChangePoint.startAt += cueIn;
                            volumeChangePoint.stopAt += cueIn;

                            if (volumeChangePoint.startAt < 0) {
                                volumeChangePoint.startAt = 0;
                            }

                            if (volumeChangePoint.stopAt < 0) {
                                volumeChangePoint.stopAt = 0;
                            }
                        }

                        return volumeChangePoint;
                    });

                if (!volumeChangePoints.length) {
                    const soundSettings = recordingAlgorithm.soundSettings;
                    const duration = soundSettings.bed.previousElementFadeOutDuration;
                    const startAt = stopAt - duration;
                    const fromVolume = audioNode.volumes[startTimeIndex];
                    const toVolume = FADE_OUT_VARS.FADE_OUT_END_VOLUME;

                    const volumeChangePoint = new VolumeChangePoint(startAt, stopAt, fromVolume, toVolume);

                    volumeChangePoints.push(volumeChangePoint);
                }

                metadata.push({
                    startAt: startAt,
                    audioNode,
                    stopAt,
                    volumeChangePoints,
                    finalVolume: audioNode.volumes[startTimeIndex],
                });
            });
        });

        return metadata;
    };

    /**
     * @returns {number}
     */
    #calculateNegativeOffset = firstRecordingStartAt => {
        if (typeof firstRecordingStartAt !== 'number') {
            firstRecordingStartAt = this.#recordingMetadata[0].startAt;
        }

        const firstRecording = recordingAlgorithm.getRecordingNode();
        const initialStartOfRecording = firstRecording.startedAt[0];

        return initialStartOfRecording - firstRecordingStartAt;
    };

    /**
     * @returns {number}
     */
    #calculateModerationDuration = () => {
        const firstRecordingMetadata = this.#recordingMetadata[0];
        const lastRecordingMetadata = this.#recordingMetadata[this.#recordingMetadata.length - 1];

        let end = lastRecordingMetadata.stopAt;

        this.#soundEffectsMetadata.forEach(soundEffectMetadata => {
            if (soundEffectMetadata.stopAt > end) {
                end = soundEffectMetadata.stopAt;
            }
        });

        this.#musicBedsMetadata.forEach(musicBedMetadata => {
            if (musicBedMetadata.stopAt > end) {
                end = musicBedMetadata.stopAt;
            }
        });

        return this.#toRoundedMilliseconds(end - firstRecordingMetadata.startAt);
    };

    /**
     * @param {number} value
     * @returns {number}
     */
    #toRoundedMilliseconds = value => Math.round(value * 1000);

    #transformVolumePoints = (initialVolume, volumePoints, mainOffset) => {
        return [
            this.#createInitialVolumeValue(initialVolume),
            ...volumePoints.map(volumeChangePoint => {
                const startAt = this.#toRoundedMilliseconds(volumeChangePoint.startAt - mainOffset);
                const stopAt = this.#toRoundedMilliseconds(volumeChangePoint.stopAt - mainOffset);
                const fromVolume = volumeChangePoint.fromVolume;
                let toVolume = volumeChangePoint.toVolume;

                if (toVolume === FADE_OUT_VARS.FADE_OUT_END_VOLUME) {
                    toVolume = 0;
                }

                return {
                    startAt,
                    stopAt,
                    toVolume,
                    fromVolume,
                };
            }),
        ];
    };

    #transformBedVolumePoints = (initialVolume, volumePoints, mainOffset) => {
        return [
            this.#createInitialBedVolumeValue(initialVolume),
            ...volumePoints.map(volumeChangePoint => {
                const startAt = this.#toRoundedMilliseconds(volumeChangePoint.startAt - mainOffset);
                const stopAt = this.#toRoundedMilliseconds(volumeChangePoint.stopAt - mainOffset);
                // const fromVolume = volumeChangePoint.fromVolume;
                const fromVolume = 1; // hard-coded
                let toVolume = volumeChangePoint.toVolume;

                if (toVolume === FADE_OUT_VARS.FADE_OUT_END_VOLUME) {
                    toVolume = 0;
                }

                return {
                    startAt,
                    stopAt,
                    toVolume,
                    fromVolume,
                };
            }),
        ];
    };

    /**
     * @param volume
     * @returns {{stopAt: number, fromVolume: *, startAt: number, toVolume: *}}
     */
    #createInitialVolumeValue = volume => ({
        startAt: 0,
        stopAt: 0,
        fromVolume: volume,
        toVolume: volume,
    });

    /**
     * @returns {{stopAt: number, fromVolume: *, startAt: number, toVolume: *}}
     */
    #createInitialBedVolumeValue = () => ({
        startAt: 0,
        stopAt: 0,
        fromVolume: 1,
        toVolume: 1,
    });

    /**
     * If the mixing plan is uploaded, we'll use uploadedRecordedFilesUuids, as we don't have recordings locally.
     * If the mixing plan is not uploaded yet, we'll fall back to recordedFileNames and use File API.
     *
     * @param {Object} data
     * @property {Array<String>} data.uploadedRecordedFilesUuids
     * @property {Array<String>} data.recordedFileNames
     * @returns {MixingPlan}
     */
    getMixingPlan = ({uploadedRecordedFilesUuids, recordedFileNames} = {}) => {
        const mixingPlan = {
            recordLength: this.#calculateModerationDuration(),
            elements: [],
        };

        const mainOffset = this.#recordingMetadata[0].startAt;

        this.#recordingMetadata.forEach((recordingMetadata, index) => {
            const recording = {
                type: AUDIO_ELEMENT_TYPES.RECORDING,
                startAt: this.#toRoundedMilliseconds(recordingMetadata.startAt - mainOffset),
                volumePoints: [],
            };

            if (uploadedRecordedFilesUuids) {
                recording.externalId = uploadedRecordedFilesUuids[index];
            } else if (recordedFileNames) {
                recording.fileName = recordedFileNames[index];
            }

            mixingPlan.elements.push(recording);
        });

        this.#preProducedAudiosMetadata.forEach((preProducedAudioMetadata, index) => {
            let startAt = preProducedAudioMetadata.startAt - mainOffset;

            if (preProducedAudioMetadata.audioNode.didPlayOnStart && index === 0) {
                startAt = 0;
            } else if (startAt < 0) {
                startAt = 0;
            }

            mixingPlan.elements.push({
                externalId: preProducedAudioMetadata.audioNode.audioObject.id,
                type: AUDIO_ELEMENT_TYPES.PRE_PROD,
                startAt: this.#toRoundedMilliseconds(startAt),
                volumePoints: this.#transformVolumePoints(
                    preProducedAudioMetadata.finalVolume,
                    preProducedAudioMetadata.volumeChangePoints,
                    mainOffset,
                ),
            });
        });

        this.#soundEffectsMetadata.forEach((soundEffectAudioMetadata, index) => {
            let startAt = soundEffectAudioMetadata.startAt - mainOffset;

            if (soundEffectAudioMetadata.audioNode.didPlayOnStart && index === 0) {
                startAt = 0;
            } else if (startAt < 0) {
                startAt = 0;
            }

            mixingPlan.elements.push({
                externalId: soundEffectAudioMetadata.audioNode.audioObject.id,
                type: AUDIO_ELEMENT_TYPES.SOUND_EFFECT,
                startAt: this.#toRoundedMilliseconds(startAt),
                volumePoints: this.#transformVolumePoints(
                    soundEffectAudioMetadata.finalVolume,
                    soundEffectAudioMetadata.volumeChangePoints,
                    mainOffset,
                ),
            });
        });

        this.#musicBedsMetadata.forEach((musicBedAudioMetadata, index) => {
            let startAt = musicBedAudioMetadata.startAt - mainOffset;

            if (musicBedAudioMetadata.audioNode.didPlayOnStart && index === 0) {
                startAt = 0;
            } else if (startAt < 0) {
                startAt = 0;
            }

            mixingPlan.elements.push({
                externalId: musicBedAudioMetadata.audioNode.audioObject.id,
                type: AUDIO_ELEMENT_TYPES.MUSIC_BED,
                startAt: this.#toRoundedMilliseconds(startAt),
                volumePoints: this.#transformBedVolumePoints(
                    musicBedAudioMetadata.finalVolume,
                    musicBedAudioMetadata.volumeChangePoints,
                    mainOffset,
                ),
            });
        });

        mixingPlan.recordLength = this.#calculateModerationDuration();

        return new MixingPlan(mixingPlan);
    };
}

export default RecordingMetadataTransformer;
