import {AudioItemActionTypes} from './audio-item.action-type';

export const AudioItemActions = {
    getItems: (type, searchTerm, source, brandId) => ({
        type: AudioItemActionTypes.GET_ITEMS,
        payload: {
            type,
            searchTerm,
            source,
            brandId,
        },
    }),
    storeEntities: entities => ({
        type: AudioItemActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    storeIds: ids => ({
        type: AudioItemActionTypes.STORE_IDS,
        payload: ids,
    }),
};
