import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button} from './Button';
import classes from './LoadingButton.module.scss';
import {PrimaryButton} from './PrimaryButton';
import {LoadingBars} from '../../icons';

export const LoadingButton = ({children, isLoading, color, isDisabled, ...rest}) => {
    return (
        <PrimaryButton
            className={clsx(classes.root)}
            {...rest}
            disabled={rest.isDisabled || isLoading}
            startIcon={!isLoading ? rest.startIcon : <LoadingBars />}
        >
            {children}
        </PrimaryButton>
    );
};

LoadingButton.propTypes = {
    startIcon: Button.propTypes.startIcon,
    endIcon: Button.propTypes.endIcon,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    color: PropTypes.oneOf(['primary']),
};

LoadingButton.defaultProps = {
    startIcon: Button.defaultProps.startIcon,
    endIcon: Button.defaultProps.endIcon,
    isLoading: false,
    isDisabled: false,
    color: 'primary',
};
