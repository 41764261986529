class SkippedDuration {
    /**
     * Offset in seconds
     *
     * @type {number}
     */
    #duration = 0;

    /**
     * Type of skipped duration. Depends on when the skip happened.
     * If the skip happened while the audio node was being played, then we extend audios duration.
     * If the skip happened before the audio node was played, then we move start position.
     *
     * @type {string}
     */
    #type = this.constructor.TYPES.MOVE_START_POSITION;

    /**
     * Start time index is used to connect on which start/stop position to calculated the additional duration.
     *
     * @type {number}
     */
    #startTimeIndex = 0;

    /**
     * @enum {string}
     */
    static TYPES = {
        MOVE_START_POSITION: 'moveStartPosition',
        EXTEND_DURATION: 'extendDuration',
    };

    /**
     * @param {number} [duration=0] duration
     * @param {string} [type=moveStartPosition] type
     * @param {number} [startTimeIndex=0] type
     */
    constructor(duration = 0, type, startTimeIndex = 0) {
        if (duration) {
            this.#duration = duration;
        }

        if (type) {
            this.#type = type;
        }

        if (startTimeIndex) {
            this.#startTimeIndex = startTimeIndex;
        }
    }

    /**
     * @returns {number}
     */
    get duration() {
        return this.#duration;
    }

    /**
     * @returns {string}
     */
    get type() {
        return this.#type;
    }

    /**
     * @returns {number}
     */
    get startTimeIndex() {
        return this.#startTimeIndex;
    }
}

export default SkippedDuration;
