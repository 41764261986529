import cloneDeep from 'lodash.clonedeep';
import {invertObject} from '../../../utils/invert-object';

/**
 * @param {Object} dto - The data transfer object.
 * @param {number} dto.recordLength
 * @param {boolean} [dto.isEdited=false]
 * @param {Array} dto.elements
 * @constructor
 */
export const MixingPlan = function MixingPlan(dto) {
    this.recordLength = dto.recordLength;
    this.isEdited = dto.isEdited || false;
    this.elements = dto.elements;

    /**
     * Converts the instance to a DTO.
     * @returns {Object} The DTO representation.
     * @returns {number} return.recordLength
     * @returns {boolean} return.isEdited
     * @returns {any} return.elements
     */
    this.toDto = function () {
        return {
            recordLength: this.recordLength,
            isEdited: this.isEdited,
            elements: toRelativePositions(this.elements.map(element => ({
                externalId: element.externalId,
                startAt: element.startAt,
                type: invertObject(ElementTypes)[element.type],
                volumePoints: element.volumePoints,
            }))),
        };
    };

    /**
     * Clones the current instance.
     * @returns {MixingPlan} A new cloned instance of MixingPlan.
     */
    this.clone = function () {
        return new MixingPlan({
            recordLength: this.recordLength,
            isEdited: this.isEdited,
            elements: cloneDeep(this.elements),
        });
    };
};

const ElementTypes = {
    soundEffect: 'sfx',
    musicBed: 'bed',
    preProducedAudio: 'preProduced',
    recording: 'recording',
};

MixingPlan.fromDto = dto => {
    return new MixingPlan({
        recordLength: dto.recordLength,
        isEdited: dto.isEdited || false,
        elements: toAbsolutePositions(dto.elements.map(element => {
            return {
                ...element,
                type: ElementTypes[element.type],
            };
        })),
    });
};

/**
 * Converts all volume change point start and stop times so that they are relative to the track duration
 * instead of being relative to the audio context timeline.
 *
 * @returns {*}
 */
const toRelativePositions = elements => {
    return elements.map(element => {
        return {
            ...element,
            volumePoints: element.volumePoints.map(volumePoint => ({
                ...volumePoint,
                startAt: volumePoint.startAt === 0 ? volumePoint.startAt : volumePoint.startAt - element.startAt,
                stopAt: volumePoint.stopAt === 0 ? volumePoint.stopAt : volumePoint.stopAt - element.startAt,
            })),
        };
    });
};

/**
 * Converts all volume change point start and stop times so that they are relative to the audio context timeline
 * instead of being relative to the track duration.
 *
 * @returns {*}
 */
const toAbsolutePositions = elements => {
    return elements.map(element => {
        return {
            ...element,
            volumePoints: element.volumePoints.map(volumePoint => ({
                ...volumePoint,
                startAt: volumePoint.startAt + element.startAt,
                stopAt: volumePoint.stopAt + element.startAt,
            })),
        };
    });
};
