export const PlaylistActionTypes = {
    STORE_SHOW_KEYS: 'playlist/STORE_SHOW_KEYS',
    STORE_SHOWS: 'playlist/STORE_SHOWS',
    STORE_VIEWS: 'playlist/STORE_VIEWS',
    STORE_ENTRIES: 'playlist/STORE_ENTRIES',
    STORE_SONGS: 'playlist/STORE_SONGS',
    STORE_AUTOVERPACKUNGS: 'playlist/STORE_AUTOVERPACKUNGS',
    STORE_BLOCKS: 'playlist/STORE_BLOCKS',
    STORE_BLOCK_PROGRAMS: 'playlist/STORE_BLOCK_PROGRAMS',
    STORE_BLOCK_PROGRAM_ITEMS: 'playlist/STORE_BLOCK_PROGRAM_ITEMS',
    STORE_SPLIT_PROGRAMS: 'playlist/STORE_SPLIT_PROGRAMS',
    STORE_SPLIT_PROGRAM_ITEMS: 'playlist/STORE_SPLIT_PROGRAM_ITEMS',
    STORE_DDS_ITEMS: 'playlist/STORE_DDS_ITEMS',
    STORE_DDS_FILTERS: 'playlist/STORE_DDS_FILTERS',
    STORE_TASKS: 'playlist/STORE_TASKS',
    STORE_GENERIC_TASKS: 'playlist/STORE_GENERIC_TASKS',
    STORE_ADVERTISING_SLOTS: 'playlist/STORE_ADVERTISING_SLOTS',
    STORE_VIEW: 'playlist/STORE_VIEW',
    STORE_BLOCK: 'playlist/STORE_BLOCK',
    TOGGLE_VIEW_COLLAPSE: 'playlist/TOGGLE_VIEW_COLLAPSE',
    EXPAND_VIEW: 'playlist/EXPAND_VIEW',
    COLLAPSE_VIEW: 'playlist/COLLAPSE_VIEW',
    SET_SELECTED_REGION_FOR_BLOCK: 'playlist/SET_SELECTED_REGION_FOR_BLOCK',
    UPDATE_TASK_IN_PLAYLIST: 'playlist/UPDATE_TASK_IN_PLAYLIST',
    SET_FIRST_PLANNING_FILTER: 'playlist/SET_FIRST_PLANNING_FILTER',
    SET_ROLE: 'playlist/SET_ROLE',
    SET_SHOW_CONTENT: 'playlist/SET_SHOW_CONTENT',
    SET_SHOW_STRUCTURED: 'playlist/SET_SHOW_STRUCTURED',
    SET_SHOW_MUSIC: 'playlist/SET_SHOW_MUSIC',
    SET_SEARCH_TERM: 'playlist/SET_SEARCH_TERM',
    SET_SHOW_ONLY_MATCHES: 'playlist/SET_SHOW_ONLY_MATCHES',
    UPDATE_PLAYING_TYPE: 'playlist/UPDATE_PLAYING_TYPE',
};
