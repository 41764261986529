import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {PlayerActions} from './player.action';
import {PlayerActionTypes} from './player.action-type';
import {handleSagaError} from '../../../error.saga';
import {toastInstance} from '../../../lib/toast';
import {getClosestDayInPast} from '../../../utils';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {getAudioSources, getDdsFilter, getDdsItemByFilter} from '../api/player.provider';

const loadItemWorker = function* ({payload}) {
    try {
        const {externalId, title} = payload;

        yield all([
            put(PlayerActions.setIsOpen(true)),
            put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYER, true)),
            put(PlayerActions.setTitle(title)),
            put(PlayerActions.setSources([])),
        ]);

        const sources = yield call(getAudioSources, externalId);

        yield put(PlayerActions.setSources(sources));
    } catch (error) {
        yield call(handleSagaError, error, 'loadItemWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYER, false));
    }
};

const loadDdsFilterWorker = function* ({payload}) {
    try {
        const {ddsFilterId} = payload;

        yield all([
            put(PlayerActions.setIsOpen(true)),
            put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYER, true)),
            put(PlayerActions.setSources([])),
        ]);

        const ddsFilter = yield call(getDdsFilter, ddsFilterId);
        let scheduledAt;

        if (ddsFilter.specificDay && ddsFilter.specificTime) {
            scheduledAt = getClosestDayInPast(ddsFilter.specificDay && ddsFilter.specificTime);
        }

        const params = {
            ...(ddsFilter.externalBrandId && {brand: ddsFilter.brandId}),
            ...(ddsFilter.externalChannelId && {channel: ddsFilter.channelId}),
            ...(ddsFilter.contentTypeId && {contentType: ddsFilter.contentTypeId}),
            ...(ddsFilter.subContentTypeId && {subContentType: ddsFilter.subContentTypeId}),
            ...(ddsFilter.firstFilterId && {firstPlanningFilter: ddsFilter.firstFilterId}),
            ...(ddsFilter.secondFilterId && {secondPlanningFilter: ddsFilter.secondFilterId}),
            ...(ddsFilter.thirdFilterId && {thirdPlanningFilter: ddsFilter.thirdFilterId}),
            ...(ddsFilter.offset && {offset: ddsFilter.offset}),
            ...(scheduledAt && {scheduled: scheduledAt}),
        };

        const ddsItem = yield call(getDdsItemByFilter, params);

        if (!ddsItem) {
            yield all([
                put(PlayerActions.setIsOpen(false)),
                put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYER, false)),
                put(PlayerActions.setSources([])),
                put(PlayerActions.setTitle(null)),
            ]);

            toastInstance.error('Error!');

            return;
        }

        yield put(PlayerActions.loadItem(ddsItem.externalId, ddsItem.name));
    } catch (error) {
        yield call(handleSagaError, error, 'loadDdsFilterWorker');
    }
};

const onSetIsOpenChangeWorker = function* ({payload}) {
    if (!payload) {
        yield delay(225);

        yield all([
            put(PlayerActions.setSources([])),
            put(PlayerActions.setTitle(null)),
        ]);
    }
};

export const playerRootSaga = function* () {
    yield all([
        takeLatest(PlayerActionTypes.LOAD_ITEM, loadItemWorker),
        takeLatest(PlayerActionTypes.LOAD_DDS_FILTER, loadDdsFilterWorker),
        takeLatest(PlayerActionTypes.SET_IS_OPEN, onSetIsOpenChangeWorker),
    ]);
};
