import {createSelector} from 'reselect';

const selectUploadProgress = state => state.task.uploadProgress;

const selectCurrentTask = state => state.task.currentTask;

const selectAlternativeTaskTargetTaskId = state => state.task.alternativeTaskTargetTaskId;

const selectScheduledAt = state => state.task.scheduledAt;

const createEstimatedLengthSelector = () => createSelector([selectCurrentTask], currentTask => {
    return currentTask?.metadata?.formattedScheduledLength || null;
});

const createTaskVariationByIdSelector = taskVariationId => createSelector(
    [
        selectCurrentTask,
    ],
    task => {
        return task?.variations.find(variation => variation.id === taskVariationId);
    },
);

export const TaskSelectors = {
    selectUploadProgress,
    selectCurrentTask,
    selectAlternativeTaskTargetTaskId,
    selectScheduledAt,
    createEstimatedLengthSelector,
    createTaskVariationByIdSelector,
};
