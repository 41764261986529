import clsx from 'clsx';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import classes from './Checkbox.module.scss';
import {CheckIcon} from '../../icons';
import {Typography} from '../Typography/Typography';

export const Checkbox = forwardRef(({children, className, ...rest}, ref) => {
    return (
        <label
            className={clsx(classes.root, className, {
                [classes.disabled]: rest?.disabled,
            })}
        >
            <input type="checkbox" ref={ref} className={classes.input} {...rest} />

            <span className={clsx(classes.checkbox, {[classes.active]: rest?.checked})}>
                <CheckIcon className={classes.icon} />
            </span>

            <Typography weight={500} size={14} color="stone" hasGutters={false}>
                {children}
            </Typography>
        </label>
    );
});

Checkbox.propTypes = {
    className: PropTypes.string,
};

Checkbox.defaultProps = {
    className: '',
};
