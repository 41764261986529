import {useLayoutEffect, useRef, useState} from 'react';

export const useInitialAnimation = ({delay, exitDelay}) => {
    const [isVisible, setIsVisible] = useState(false);
    const timeoutId = useRef(null);

    useLayoutEffect(() => {
        if (delay > 0) {
            timeoutId.current = setTimeout(() => {
                setIsVisible(true);
            }, delay);
        } else {
            setIsVisible(true);
        }

        return () => {
            if (exitDelay > 0) {
                timeoutId.current = setTimeout(() => {
                    setIsVisible(false);
                }, exitDelay);
            } else {
                setIsVisible(false);
            }
        };
    }, [delay, exitDelay]);

    return {isVisible};
};
