import {useEffect, useRef, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';

export const useCanBePlayed = () => {
    const [canBePlayed, setCanBePlayed] = useState(false);
    const subscriber = useRef(null);

    useEffect(() => {
        const onUpdate = ({canBePlayed}) => {
            setCanBePlayed(canBePlayed);
        };

        subscriber.current = playbackAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.CAN_BE_PLAYED);

        return () => {
            subscriber.current.unsubscribe();
        };
    }, []);

    return {canBePlayed};
};
