import clsx from 'clsx';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {push} from 'redux-first-history';
import classes from './Variations.module.scss';
import {PrimaryButton} from '../../../../components';
import {ROUTE_PATHS} from '../../../../config/route-paths';
import {CurrentTaskActions, CurrentTaskSelectors} from '../../../../features/current-task';
import {PlayerActions} from '../../../../features/player';
import {TaskSelectors} from '../../../../features/task';
import {PlayIcon} from '../../../../icons';
import {RouterSelector} from '../../../../router.selector';
import {useDisabledControls} from '../../hooks/use-disabled-controls';

const VariationButton = ({text, isActive, isDisabled, externalId, onClick}) => {
    return (
        <button
            type="button"
            disabled={isDisabled}
            className={clsx(classes.button, {
                [classes.active]: isActive,
            })}
            onClick={onClick}
        >
            <span>
                {text}
            </span>

            {!!externalId && (
                <div className={classes.play}>
                    <PlayIcon className={classes.playIcon} />
                </div>
            )}
        </button>
    );
};

VariationButton.propTypes = {
    text: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    externalId: PropTypes.string,
};

VariationButton.defaultProps = {
    isActive: false,
    isDisabled: false,
    externalId: null,
};

export const Variations = () => {
    const task = useSelector(TaskSelectors.selectCurrentTask);
    const currentTaskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const isFreeModeEnabled = useSelector(CurrentTaskSelectors.selectIsFreeModeEnabled);
    const freeModeOriginalTaskVariationId = useSelector(CurrentTaskSelectors.selectFreeModeOriginalTaskVariationId);
    const {location} = useSelector(RouterSelector.selectRouterState);
    const {search} = location;
    const queryParams = queryString.parse(search);

    const {areControlsDisabled} = useDisabledControls();

    const dispatch = useDispatch();

    const variations = useMemo(() => {
        return task?.variations ?? [];
    }, [task]);

    const taskTitle = useMemo(() => {
        return task?.description;
    }, [task]);

    const variationConfig = useMemo(() => {
        return Array(3).fill(null).map((value, index) => {
            const taskVariation = variations[index];
            const isActive = taskVariation?.id === currentTaskVariationId;
            const variationNumber = index + 1;
            const title = `${taskTitle} [Variation ${variationNumber}]`;

            return {
                key: variationNumber,
                text: variationNumber,
                id: isFreeModeEnabled ? null : taskVariation?.id,
                isActive,
                isDisabled: !isFreeModeEnabled && (!taskVariation?.externalId || areControlsDisabled),
                externalId: !isFreeModeEnabled && taskVariation?.externalId,
                title,
                onClick: () => {
                    if (isFreeModeEnabled) {
                        dispatch(push({
                            pathname: generatePath(ROUTE_PATHS.TASK, {taskId: task.id}),
                            search: queryString.stringify({
                                ...queryParams,
                                taskVariationId: taskVariation.id,
                            }),
                        }));
                    } else if (taskVariation?.externalId) {
                        dispatch(PlayerActions.loadItem(taskVariation?.externalId, title));
                    }
                },
            };
        });
    }, [
        variations,
        currentTaskVariationId,
        taskTitle,
        isFreeModeEnabled,
        areControlsDisabled,
        dispatch,
        task,
        queryParams,
    ]);

    if (variations.length === 1) {
        return <div />;
    }

    return (
        <div className={classes.root}>
            {variationConfig.map(variation => (
                <VariationButton
                    key={variation.key}
                    text={variation.text}
                    title={variation.title}
                    id={variation.id}
                    isActive={variation.isActive}
                    isDisabled={variation.isDisabled}
                    externalId={variation.externalId}
                    onClick={variation.onClick}
                />
            ))}

            <PrimaryButton
                className={clsx(classes.freeModeButton, {
                    [classes.active]: isFreeModeEnabled,
                })}
                onClick={() => {
                    const newIsFreeModeEnabled = !isFreeModeEnabled;

                    dispatch(CurrentTaskActions.setIsFreeModeEnabled(newIsFreeModeEnabled, 'user'));

                    if (newIsFreeModeEnabled) {
                        dispatch(CurrentTaskActions.setFreeModeOriginalTaskVariationId(currentTaskVariationId));
                    } else if (freeModeOriginalTaskVariationId) {
                        if (freeModeOriginalTaskVariationId !== currentTaskVariationId) {
                            dispatch(push({
                                pathname: generatePath(ROUTE_PATHS.TASK, {taskId: task.id}),
                                search: queryString.stringify({
                                    ...queryParams,
                                    taskVariationId: freeModeOriginalTaskVariationId,
                                }),
                            }));
                        }

                        dispatch(CurrentTaskActions.setFreeModeOriginalTaskVariationId(null));
                    }
                }}
            >
                Complete all
            </PrimaryButton>
        </div>
    );
};
