import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import classes from './AllTasksCompletedModal.module.scss';
import {PrimaryButton, Typography} from '../../../../components';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../../hooks';

export const AllTasksCompletedModal = () => {
    const isOpen = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.ALL_TASKS_COMPLETED);

    return (
        <Modal
            open={isOpen}
            onClose={() => {}}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div>
                    <div className={classes.root}>
                        <Typography size={28} weight={500} color="white" variant="label">
                            <span role="img" aria-label="Confetti">🎉</span>
                        </Typography>

                        <Typography
                            transform="uppercase"
                            weight={500}
                            color="regent"
                            className={classes.title}
                            variant="h1"
                        >
                            Good job!
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={true}>
                            You have successfully completed all tasks for the selected period.
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={true}>
                            Before closing this window, ensure that all upload jobs have completed successfully.
                        </Typography>

                        <div className={classes.actions}>
                            <PrimaryButton
                                onClick={() => {
                                    window.close();
                                }}
                            >
                                Close window
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
