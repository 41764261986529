import clsx from 'clsx';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './Elements.module.scss';
import {Typography} from '../../../components';
import {LockIcon, MicrophoneIcon} from '../../../icons';
import {useCurrentPosition} from '../hooks/use-current-position';
import {usePlaybackNodes} from '../hooks/use-playback-nodes';
import {EditorActions} from '../store/editor.action';
import {EditorSelectors} from '../store/editor.selector';
import {EditorConfig} from '../utils/constants';

const Row = ({children, index}) => {
    return (
        <motion.div
            className={classes.row}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5, delay: index * 0.05 + 0.05}}
        >
            {children}
        </motion.div>
    );
};

Row.propTypes = {
    index: PropTypes.number.isRequired,
};

const BaseElement = ({length, startTime, color, hasLockIcon, hasMicrophoneIcon, title, isSelected, onClick}) => {
    const zoomFactor = useSelector(EditorSelectors.selectZoomFactor);

    const width = useMemo(() => {
        return length * EditorConfig.BaseColumnWidth / EditorConfig.MillisecondsPerColumn * zoomFactor;
    }, [length, zoomFactor]);

    const left = useMemo(() => {
        return startTime * EditorConfig.BaseColumnWidth / EditorConfig.MillisecondsPerColumn * zoomFactor;
    }, [startTime, zoomFactor]);

    return (
        <div
            className={clsx(classes.element, classes[`color-${color}`], {
                [classes.isSelected]: isSelected,
            })}
            style={{width, left}}
            onClick={onClick}
        >
            {!!hasLockIcon && (
                <LockIcon color="white" />
            )}

            {!!hasMicrophoneIcon && (
                <MicrophoneIcon color="white" />
            )}

            {!!title && (
                <Typography weight={500} size={14} color="white" lineClamp={1}>
                    {title}
                </Typography>
            )}

            <div className={classes.borderElement} />
        </div>
    );
};

BaseElement.propTypes = {
    length: PropTypes.number.isRequired,
    startTime: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
        'stone',
        'radiance',
        'rose',
        'sun',
        'turquoise',
    ]).isRequired,
    hasLockIcon: PropTypes.bool,
    hasMicrophoneIcon: PropTypes.bool,
    isSelected: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

BaseElement.defaultProps = {
    hasLockIcon: false,
    hasMicrophoneIcon: false,
    isSelected: false,
    title: null,
    onClick: () => {},
};

const PreviousAndNextElements = () => {
    const {nodes} = usePlaybackNodes('previousAndNextElements');
    const [previousElement, nextElement] = nodes;

    return (
        <Row index={0}>
            {!!previousElement && (
                <BaseElement
                    key={`${previousElement.id}/${0}`}
                    length={(previousElement.stopAt - previousElement.startAt) * 1000}
                    startTime={previousElement.startAt * 1000}
                    color="stone"
                    title={previousElement.title}
                    hasLockIcon
                />
            )}

            {!!nextElement && (
                <BaseElement
                    key={`${nextElement.id}/${1}`}
                    length={(nextElement.stopAt - nextElement.startAt) * 1000}
                    startTime={nextElement.startAt * 1000}
                    color="stone"
                    title={nextElement.title}
                    hasLockIcon
                />
            )}
        </Row>
    );
};

const PreProducedElements = () => {
    const {nodes} = usePlaybackNodes('preProducedElements');

    const selectedElementIndex = useSelector(EditorSelectors.selectSelectedElementIndex);

    const dispatch = useDispatch();

    return (
        <Row index={1}>
            {nodes.map(node => {
                return (
                    <BaseElement
                        key={node.index}
                        length={(node.duration - node.offset) * 1000}
                        startTime={node.startAt * 1000}
                        color="rose"
                        title={node.title}
                        isSelected={selectedElementIndex === node.index}
                        onClick={() => {
                            dispatch(EditorActions.setSelectedElementIndex(node.index));
                        }}
                    />
                );
            })}
        </Row>
    );
};

const RecordingElements = () => {
    const {nodes} = usePlaybackNodes('recordingElements');

    const selectedElementIndex = useSelector(EditorSelectors.selectSelectedElementIndex);

    const dispatch = useDispatch();

    return (
        <Row index={2}>
            {nodes.map((node, index) => {
                let props = {};

                if (index !== 0) {
                    props = {
                        isSelected: selectedElementIndex === node.index,
                        onClick: () => {
                            if (selectedElementIndex === node.index) {
                                dispatch(EditorActions.setSelectedElementIndex(null));
                                return;
                            }

                            dispatch(EditorActions.setSelectedElementIndex(node.index));
                        },
                    };
                }

                return (
                    <BaseElement
                        key={node.index}
                        length={node.duration * 1000}
                        startTime={node.startAt * 1000}
                        color="radiance"
                        hasMicrophoneIcon
                        {...props}
                    />
                );
            })}
        </Row>
    );
};

const SfxElements = () => {
    const {nodes} = usePlaybackNodes('sfxElements');

    const selectedElementIndex = useSelector(EditorSelectors.selectSelectedElementIndex);

    const dispatch = useDispatch();

    return (
        <Row index={3}>
            {nodes.map(node => {
                return (
                    <BaseElement
                        key={node.index}
                        length={(node.stopAt - node.startAt) * 1000}
                        startTime={node.startAt * 1000}
                        color="sun"
                        title={node.title}
                        isSelected={selectedElementIndex === node.index}
                        onClick={() => {
                            if (selectedElementIndex === node.index) {
                                dispatch(EditorActions.setSelectedElementIndex(null));
                                return;
                            }

                            dispatch(EditorActions.setSelectedElementIndex(node.index));
                        }}
                    />
                );
            })}
        </Row>
    );
};

const BedElements = () => {
    const {nodes} = usePlaybackNodes('bedElements');

    const selectedElementIndex = useSelector(EditorSelectors.selectSelectedElementIndex);

    const dispatch = useDispatch();

    return (
        <Row index={4}>
            {nodes.map(node => {
                return (
                    <BaseElement
                        key={node.index}
                        length={(node.stopAt - node.startAt) * 1000}
                        startTime={node.startAt * 1000}
                        color="turquoise"
                        title={node.title}
                        isSelected={selectedElementIndex === node.index}
                        onClick={() => {
                            if (selectedElementIndex === node.index) {
                                dispatch(EditorActions.setSelectedElementIndex(null));
                                return;
                            }

                            dispatch(EditorActions.setSelectedElementIndex(node.index));
                        }}
                    />
                );
            })}
        </Row>
    );
};

export const Elements = ({editorWidth}) => {
    const zoomFactor = useSelector(EditorSelectors.selectZoomFactor);
    const isAutoscrollEnabled = useSelector(EditorSelectors.selectIsAutoscrollEnabled);
    const {position} = useCurrentPosition();

    /**
     * Using `left` would trigger layout computations.
     * Using `translateX` would not trigger layout computations, hence making it more efficient.
     * @type {string}
     */
    const transform = useMemo(() => {
        if (isAutoscrollEnabled && editorWidth) {
            const widthPerMillisecond = EditorConfig.BaseColumnWidth / EditorConfig.MillisecondsPerColumn;
            const translateX = (editorWidth / 2) - (position * widthPerMillisecond * zoomFactor);

            return `translateX(${Math.round((translateX + Number.EPSILON) * 100) / 100}px) translateZ(0)`;
        }

        return 'translateX(0px) translateZ(0)';
    }, [editorWidth, isAutoscrollEnabled, position, zoomFactor]);

    return (
        <div
            className={classes.root}
            style={{transform}}
        >
            <PreviousAndNextElements />
            <RecordingElements />
            <PreProducedElements />
            <SfxElements />
            <BedElements />
        </div>
    );
};

Elements.propTypes = {
    editorWidth: PropTypes.number.isRequired,
};
