import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import classes from './ConfirmationDialog.module.scss';
import {CloseIcon} from '../../icons';
import {IconButton} from '../Button/IconButton';
import {PrimaryButton} from '../Button/PrimaryButton';
import {Typography} from '../Typography/Typography';

export const ConfirmationDialog = ({isOpen, onCancel, onClose, onSubmit, title, message, confirm, children}) => {
    const handleSubmit = event => {
        event.preventDefault();

        onClose();

        onSubmit();
    };

    return (
        <Modal
            open={isOpen}
            onClose={onCancel}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div className={classes.root}>
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h5" weight={500} color="regent" hasGutters={false}>
                            {title}
                        </Typography>

                        <Typography variant="body" color="regent" size={14}>
                            {message}
                        </Typography>

                        {children}

                        <div className={classes.actions}>
                            <IconButton backgroundColor="fiord" color="white" onClick={onCancel}>
                                <CloseIcon />
                            </IconButton>

                            <PrimaryButton type="submit">
                                {confirm}
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </Fade>
        </Modal>
    );
};

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
};
