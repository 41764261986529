import {SvgIcon} from '../SvgIcon/SvgIcon';

export const SaveIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path d="M14 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4l-4-4zm2 16H2V2h11.17L16 4.83V16zm-7-6c-1.107 0-2 .893-2 2s.893 2 2 2 2-.893 2-2-.893-2-2-2zM4 4h8v3H4V4z" />
        </SvgIcon>
    );
};

