import {PreloadingActionTypes} from './preloading.action-type';

export const PreloadingActions = {
    setProgress: progress => ({
        type: PreloadingActionTypes.SET_PROGRESS,
        payload: progress,
    }),
    setStatus: status => ({
        type: PreloadingActionTypes.SET_STATUS,
        payload: status,
    }),
    setIsAlertVisible: isVisible => ({
        type: PreloadingActionTypes.SET_IS_ALERT_DISPLAYED,
        payload: isVisible,
    }),
    setRemainingWaitTime: time => ({
        type: PreloadingActionTypes.SET_REMAINING_WAIT_TIME,
        payload: time,
    }),
    cancel: () => ({
        type: PreloadingActionTypes.CANCEL,
    }),
};
