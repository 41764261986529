import React from 'react';
import {ToastContainer as ReactToastContainer} from 'react-toastify';
import classes from './ToastContainer.module.scss';

export const ToastContainer = () => {
    return (
        <ReactToastContainer
            className={classes.root}
            bodyClassName={classes.body}
            toastClassName={classes.toast}
            position="bottom-right"
            autoClose={6000}
            closeOnClick={false}
            draggable={false}
            closeButton={false}
            pauseOnFocusLoss
            pauseOnHover
            hideProgressBar
            newestOnTop
        />
    );
};
