export const AudioItem = function (dto, elementType) {
    this.id = dto.externalId;
    this.title = dto.description;
    this.length = dto.length ? parseInt(dto.length, 10) : 0;
    this.cueIn = dto.cueIn ? parseInt(dto.cueIn, 10) : 0;
    this.cueOut = dto.cueOut ? parseInt(dto.cueOut, 10) : 0;
    this.fadeIn = dto.fadeIn ? parseInt(dto.fadeIn, 10) : 0;
    this.fadeOut = dto.fadeOut ? parseInt(dto.fadeOut, 10) : 0;
    this.startNext = dto.startNext ? parseInt(dto.startNext, 10) : 0;
    this.introLength = dto.introLength ? parseInt(dto.introLength, 10) : 0;
    this.elementType = elementType;
    this.objectElementType = dto.elementType;
};
