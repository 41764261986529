import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classes from './AdjacentElement.module.scss';
import {Typography} from '../../../../components';
import {PlayerActions} from '../../../../features/player';
import recordingAlgorithm from '../../../../features/recording-algorithm/RecordingAlgorithm';
import {NoSoundIcon, SoundIcon} from '../../../../icons';

const Length = ({length, countdownDirection}) => {
    const playingLengthDuration = moment.duration(length * 1000, 'ms').asMilliseconds();

    const format = countdownDirection === 'negative' ? '[-]mm:ss.SS' : 'mm:ss.SS';
    return moment.utc(Math.abs(playingLengthDuration)).format(format);
};

const Placeholder = ({position}) => {
    return (
        <div
            className={clsx(classes.root, classes.placeholder, {
                [classes.next]: position === 'next',
            })}
        >
            <NoSoundIcon className={classes.icon} />

            <div className={classes.infoWrapper}>
                <Typography className={classes.length} color="white" size={20} weight={500} variant="body" hasGutters={false}>
                    <Length length={0} countdownDirection="positive" />
                </Typography>

                <Typography
                    className={classes.title}
                    color="white"
                    size={12}
                    weight={500}
                    variant="body"
                    hasGutters={false}
                >
                    Element missing.
                </Typography>
            </div>
        </div>
    );
};

Placeholder.propTypes = {
    position: PropTypes.oneOf(['previous', 'next']).isRequired,
};

export const AdjacentElement = ({position, title, length, countdownDirection, progress, elementId}) => {
    const dispatch = useDispatch();

    const handleDoubleClick = () => {
        if (!elementId || !title) {
            return;
        }

        if (recordingAlgorithm.isInProgress()) {
            return;
        }

        dispatch(PlayerActions.loadItem(elementId, title));
    };

    if (!title) {
        return <Placeholder position={position} />;
    }

    return (
        <div
            tabIndex={-1}
            className={clsx(classes.root, {
                [classes.next]: position === 'next',
            })}
            onDoubleClick={handleDoubleClick}
        >
            <div className={classes.indicator} style={{width: `${progress}%`}}>
                <SoundIcon className={classes.icon} />
            </div>

            <SoundIcon className={classes.icon} />

            <div className={classes.infoWrapper}>
                <Typography color="white" size={20} weight={500} variant="body" hasGutters={false}>
                    <Length length={length} countdownDirection={countdownDirection} />
                </Typography>

                <Typography
                    className={classes.title}
                    color="white"
                    size={12}
                    weight={500}
                    variant="body"
                    hasGutters={false}
                    title={title}
                >
                    {title}
                </Typography>
            </div>
        </div>
    );
};

AdjacentElement.propTypes = {
    position: PropTypes.oneOf(['previous', 'next']).isRequired,
    countdownDirection: PropTypes.oneOf(['positive', 'negative']).isRequired,
    title: PropTypes.string,
    elementId: PropTypes.string,
    length: PropTypes.number,
    progress: PropTypes.number,
};

AdjacentElement.defaultProps = {
    elementId: null,
    title: null,
    length: 0,
    progress: 0,
};
