import {combineReducers} from 'redux';
import {adjacentElementsReducer} from './features/adjacent-elements/store/adjacent-elements.reducer';
import {audioItemReducer} from './features/audio-item/store/audio-item.reducer';
import {authReducer} from './features/auth';
import {brandReducer} from './features/brand';
import {channelReducer} from './features/channel';
import {cockpitNavigationReducer} from './features/cockpit-navigation';
import {contentTypeReducer} from './features/content-type';
import {currentTaskReducer} from './features/current-task/store/current-task.reducer';
import {editorReducer} from './features/editor/store/editor.reducer';
import {failedUploadsReducer} from './features/failed-uploads/store/failed-uploads.reducer';
import {genericTaskReducer} from './features/generic-task/store/generic-task.reducer';
import {homeFiltersReducer} from './features/home-filters';
import {hostRoleReducer} from './features/host-role';
import {loadingReducer} from './features/loading';
import {mixingPlanReducer} from './features/mixing-plan/store/mixing-plan.reducer';
import {playerReducer} from './features/player';
import {playlistReducer} from './features/playlist';
import {preloadingReducer} from './features/preloading/store/preloading.reducer';
import {preparedTaskVariationReducer} from './features/prepared-task-variation/store/prepared-task-variation.reducer';
import {presetReducer} from './features/preset/store/preset.reducer';
import {soundSettingsReducer} from './features/sound-settings/store/sound-settings.reducer';
import {taskReducer} from './features/task';
import {taskOverviewReducer} from './features/task-overview/store/task-overview.reducer';
import {uiReducer} from './features/ui/store/ui.reducer';
import {userReducer} from './features/user';
import {webSocketReducer} from './features/web-socket/store/web-socket.reducer';
import {routerReducer} from './lib/redux-first-history';
import {cockpitReducer} from './screens/cockpit/store/cockpit.reducer';
import {createReducer} from './utils';

const reducerTimes = (reducers, thresholdInMs = 8) => {
    Object.keys(reducers)
        .forEach(name => {
            const originalReducer = reducers[name];

            reducers[name] = (state, action) => {
                const start = window.performance.now();
                const result = originalReducer(state, action);
                const end = window.performance.now();
                const diffInMs = Math.round(((end - start) + Number.EPSILON) * 100) / 100;
                if (diffInMs >= thresholdInMs) {
                    // eslint-disable-next-line no-console
                    console.warn('Reducer "' + name + '" took ' + diffInMs + 'ms for ' + action.type);
                }
                return result;
            };
        });

    return reducers;
};

export const createRootReducer = () => combineReducers(reducerTimes({
    auth: authReducer,
    loading: loadingReducer,
    player: playerReducer,
    router: routerReducer,
    // TODO: Add these dynamically, based on the route
    adjacentElements: adjacentElementsReducer,
    audioItem: audioItemReducer,
    brand: brandReducer,
    channel: channelReducer,
    cockpit: cockpitReducer,
    cockpitNavigation: cockpitNavigationReducer,
    contentType: contentTypeReducer,
    currentTask: currentTaskReducer,
    editor: editorReducer,
    failedUploads: failedUploadsReducer,
    genericTask: genericTaskReducer,
    homeFilters: homeFiltersReducer,
    hostRole: hostRoleReducer,
    mixingPlan: mixingPlanReducer,
    playlist: playlistReducer,
    preloading: preloadingReducer,
    preparedTaskVariation: preparedTaskVariationReducer,
    preset: presetReducer,
    soundSettings: soundSettingsReducer,
    task: taskReducer,
    taskOverview: taskOverviewReducer,
    ui: uiReducer,
    user: userReducer,
    webSocket: webSocketReducer,
    hasNewVersion: createReducer(false, 'SET_HAS_NEW_VERSION'),
}));
