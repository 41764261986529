import {PreparedTaskVariation} from './prepared-task-variation.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const getPreparedTaskVariation = ({taskVariationId}) => {
    return ddsPlanningApi
        .get(`/v1/task-variation/${taskVariationId}/prepared-data`)
        .then(result => PreparedTaskVariation.fromDto(result.data));
};

const updatePreparedTaskVariation = ({taskVariationId, data}, options) => {
    return ddsPlanningApi
        .post(`/v1/task-variation/${taskVariationId}/prepared-data`, data, {
            ...options,
        })
        .then(result => PreparedTaskVariation.fromDto(result.data));
};

export const PreparedTaskVariationApi = {
    getPreparedTaskVariation,
    updatePreparedTaskVariation,
};
