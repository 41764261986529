import {END, eventChannel} from 'redux-saga';
import {all, call, put, take, takeLatest} from 'redux-saga/effects';
import {FailedUploadsActions} from './failed-uploads.action';
import {FailedUploadsActionTypes} from './failed-uploads.action-type';
import {CONFIG} from '../../../config';
import {handleSagaError} from '../../../error.saga';
import {IndexedDb} from '../../../lib/indexed-db';
import {logTailService} from '../../log-tail/log-tail.service';
import {MixingPlan} from '../../mixing-plan/dto/mixing-plan.dto';
import {completeTaskVariationRequest, uploadMixingPlan, uploadRecordingsRequest} from '../../task/api/task.provider';
import {updateTask} from '../../task/store/task.saga';
import {FailedUploadStatuses} from '../utils/constants';

export const checkFailedUploads = function* () {
    try {
        const hasFailedUploads = yield call([IndexedDb, IndexedDb.getHasFailedUploads]);

        if (hasFailedUploads) {
            yield put(FailedUploadsActions.setStatus(FailedUploadStatuses.WAITING_FOR_RETRY));
        }
    } catch (error) {
        yield call(handleSagaError, error, 'checkFailedUploads');
    }
};

const createChannel = function ({failedUpload}) {
    return eventChannel(emit => {
        const onUploadProgress = event => {
            emit({progress: Math.ceil((event.loaded * 100) / event.total)});
        };

        if (failedUpload.mixingPlan) {
            const {taskVariationId, files} = failedUpload;

            uploadRecordingsRequest(taskVariationId, files, onUploadProgress)
                .then(data => {
                    emit({data});
                    emit(END);
                }).catch(error => {
                    emit({error});
                    emit(END);
                });
        } else {
            const {taskVariationId, files, isAudioWrapped, syncOut} = failedUpload;

            completeTaskVariationRequest(taskVariationId, files[0], onUploadProgress, isAudioWrapped, syncOut)
                .then(data => {
                    emit({data});
                    emit(END);
                }).catch(error => {
                    emit({error});
                    emit(END);
                });
        }

        return () => {
            emit(END);
        };
    });
};

const retryFailedUpload = function* ({failedUpload, previousProgress, numberOfFailedUploads}) {
    const channel = yield call(createChannel, {failedUpload});

    while (true) {
        const {progress = previousProgress, data, error} = yield take(channel);

        if (error) {
            throw error;
        }

        if (data) {
            if (failedUpload.mixingPlan) {
                const mixingPlan = MixingPlan.fromDto({
                    ...failedUpload.mixingPlan,
                    elements: failedUpload.mixingPlan.elements.map((element, index) => ({
                        startAt: element.startAt,
                        externalId: element.type === 'recording' && data[index]
                            ? data[index]
                            : element.externalId,
                        type: element.type,
                        volumePoints: element.volumePoints,
                    })),
                });

                const task = yield call(uploadMixingPlan, failedUpload.taskVariationId, mixingPlan.toDto());

                yield call(updateTask, failedUpload.taskId, {
                    hasUncompletedVariations: task.hasUncompletedVariations,
                    status: task.status,
                    variations: task.variations,
                    isCompleted: task.isCompleted,
                });
            } else {
                yield call(updateTask, failedUpload.taskId, {
                    hasUncompletedVariations: data.hasUncompletedVariations,
                    status: data.status,
                    variations: data.variations,
                    isCompleted: data.isCompleted,
                });
            }

            yield call([IndexedDb, IndexedDb.deleteFailedUpload], failedUpload.taskVariationId);
        }

        yield put(FailedUploadsActions.setProgress(Math.round(previousProgress + progress / numberOfFailedUploads)));
    }
};

const retryFailedUploads = function* () {
    yield put(FailedUploadsActions.setStatus(FailedUploadStatuses.RETRY_IN_PROGRESS));

    try {
        const failedUploads = yield call([IndexedDb, IndexedDb.getAllFailedUploads]);
        const singleUploadPercentage = Math.round(100 / failedUploads.length);
        let numberOfProcessedUploads = 0;
        let numberOfSuccessfullyProcessedUploads = 0;

        for (const failedUpload of failedUploads) {
            try {
                yield call(retryFailedUpload, {
                    failedUpload,
                    previousProgress: numberOfProcessedUploads * singleUploadPercentage,
                    numberOfFailedUploads: failedUploads.length,
                });

                numberOfSuccessfullyProcessedUploads += 1;
            } catch (error) {
                // no-op
                // eslint-disable-next-line no-console
                console.error({error});

                yield call([logTailService, logTailService.error], 'ERROR: ', {
                    error: {...error},
                    request: error?.request || {},
                    response: error?.response || {},
                    logKey: 'retryFailedUploads',
                    version: CONFIG.APP_VERSION,
                });
            } finally {
                numberOfProcessedUploads += 1;
            }
        }

        if (numberOfSuccessfullyProcessedUploads === failedUploads.length) {
            yield put(FailedUploadsActions.setStatus(FailedUploadStatuses.COMPLETED_ALL_UPLOADS));
        } else {
            yield put(FailedUploadsActions.setStatus(FailedUploadStatuses.WAITING_FOR_RETRY));
        }
    } catch (error) {
        yield call(handleSagaError, error, 'retryFailedUploads');

        yield put(FailedUploadsActions.setStatus(FailedUploadStatuses.WAITING_FOR_RETRY));
    } finally {
        yield put(FailedUploadsActions.setProgress(0));
    }
};

export const extractInitialFailedUploadTaskIds = function* () {
    try {
        const failedUploads = yield call([IndexedDb, IndexedDb.getAllFailedUploads]);

        yield put(FailedUploadsActions.storeFailedUploadTaskIds(failedUploads.map(object => object.taskId)));
    } catch (error) {
        yield call(handleSagaError, error, 'extractInitialFailedUploadTaskIds');
    }
};

export const failedUploadsSaga = function* () {
    yield all([
        takeLatest(FailedUploadsActionTypes.RETRY, retryFailedUploads),
    ]);
};
