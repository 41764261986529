import {LoadingActionTypes} from './loading.action-type';

export const LoadingActions = {
    setIsLoading: (loadingType, isLoading) => ({
        type: LoadingActionTypes.SET_IS_LOADING,
        payload: {
            isLoading,
            loadingType,
        },
    }),
};
