import {useDispatch, useSelector} from 'react-redux';
import classes from './StepSelect.module.scss';
import {Select, Typography} from '../../../components';
import {EditorActions} from '../store/editor.action';
import {EditorSelectors} from '../store/editor.selector';

const Steps = [{id: 10, name: '10ms'}, {id: 20, name: '20ms'}, {id: 50, name: '50ms'}, {id: 100, name: '100ms'}, {id: 250, name: '250ms'}, {id: 500, name: '500ms'}];

export const StepSelect = () => {
    const dispatch = useDispatch();

    const steps = useSelector(EditorSelectors.selectSteps);

    return (
        <div className={classes.root}>
            <Typography color="regent" transform="uppercase" size={12} className={classes.title} variant="body" weight={500}>
                Steps
            </Typography>

            <Select
                inputId="presets"
                onChange={step => {
                    dispatch(EditorActions.setSteps(step));
                }}
                options={Steps}
                value={steps}
                InputProps={{
                    theme: 'dark',
                }}
            />
        </div>
    );
};
