import BaseAudioNode from './BaseAudioNode';

class MusicBedAudioNode extends BaseAudioNode {
    /**
     * @return {number}
     */
    getPassedTime() {
        if (!super.isPlaying) {
            return 0;
        }

        return super.audioContext.currentTime - super.lastStartedAt();
    }

    /**
     * @param offset
     */
    scheduleStart(offset) {
        super.play(offset);

        super.didPlayOnStart = true;
    }
}

export default MusicBedAudioNode;
