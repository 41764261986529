import moment from 'moment/moment';
import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {PlaylistActions} from './playlist.action';
import {PlaylistActionTypes} from './playlist.action-type';
import {PlaylistSelectors} from './playlist.selector';
import {handleSagaError} from '../../../error.saga';
import {IndexedDb} from '../../../lib/indexed-db';
import {toastInstance} from '../../../lib/toast';
import {withAbortController} from '../../../utils';
import {BroadcastChannelActions, REQUEST_TYPES} from '../../broadcast-channel';
import {CockpitNavigationActions} from '../../cockpit-navigation';
import {FailedUploadsSelectors} from '../../failed-uploads/store/failed-uploads.selector';
import {LOADING_SELECTOR, LOADING_TYPES, LoadingActions} from '../../loading';
import {TaskOverviewActions} from '../../task-overview/store/task-overview.action';
import {TaskStatuses} from '../../task/utils/task-statuses';
import {UiActions} from '../../ui/store/ui.action';
import {Task as PlaylistTask} from '../api/playlist.dto';
import {getPlaylistRequest, updatePlayingTypeRequest} from '../api/playlist.provider';
import {isMatchingListTask, isMatchingPlaylistTask} from '../util/is-matching-task';

export const getPlaylistFlow = function* (channelId, startDate) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYLIST, true));

        yield all([
            // Remove show keys so that the playlist gets removed from the DOM
            put(PlaylistActions.storeShowKeys([])),
        ]);

        const failedUploadTaskIds = yield select(FailedUploadsSelectors.selectFailedUploadTaskIds);

        /** @var {PlaylistEntities} entities */
        const entities = yield call(withAbortController(getPlaylistRequest, channelId, startDate, failedUploadTaskIds));

        for (const key of Object.keys(entities.tasks)) {
            const task = entities.tasks[key];

            if (failedUploadTaskIds.includes(task.id) && task.isTaskCompleted) {
                yield call([IndexedDb, IndexedDb.deleteFailedUpload], task.taskVariationId);
            }
        }

        yield all([
            put(PlaylistActions.storeTasks(entities.tasks, 'initial-load')),
            put(PlaylistActions.storeDdsFilters(entities.ddsFilters)),
            put(PlaylistActions.storeGenericTasks(entities.genericTasks)),
            put(PlaylistActions.storeDdsItems(entities.ddsItems)),
            put(PlaylistActions.storeAdvertisingSlots(entities.advertisingSlots)),
            put(PlaylistActions.storeSplitPrograms(entities.splitPrograms)),
            put(PlaylistActions.storeSplitProgramItems(entities.splitProgramItems)),
            put(PlaylistActions.storeBlockProgramItems(entities.blockProgramItems)),
            put(PlaylistActions.storeBlockPrograms(entities.blockPrograms)),
            put(PlaylistActions.storeBlocks(entities.blocks)),
            put(PlaylistActions.storeAutoverpackungs(entities.autoverpackungs)),
            put(PlaylistActions.storeSongs(entities.songs)),
            put(PlaylistActions.storeEntries(entities.entries)),
            put(PlaylistActions.storeViews(entities.views)),
            put(PlaylistActions.storeShows(entities.shows)),
            put(PlaylistActions.storeShowKeys(entities.showKeys)),
        ]);

        yield call(sendTaskIdsToRecordingWindow);
    } catch (error) {
        yield call(handleSagaError, error, 'getPlaylistFlow');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.PLAYLIST, false));

        yield put(BroadcastChannelActions.send({
            messageType: 'request',
            requestType: REQUEST_TYPES.CURRENT_TASK_ID,
        }));
    }
};

const toggleViewCollapseWorker = function* ({payload}) {
    const {viewKey} = payload;

    const views = yield select(PlaylistSelectors.selectViews);
    const view = views[viewKey];

    yield put(PlaylistActions.storeViews({
        ...views,
        [viewKey]: view.update({
            ...view,
            isExpanded: !view.isExpanded,
        }),
    }));
};

const expandViewWorker = function* ({payload}) {
    const {viewKey} = payload;

    const views = yield select(PlaylistSelectors.selectViews);

    if (Array.isArray(viewKey)) {
        let newViews = {...views};

        for (const key of viewKey) {
            const newView = newViews[key];

            newViews = {
                ...newViews,
                [key]: newView.update({
                    ...newView,
                    isExpanded: true,
                }),
            };
        }

        yield put(PlaylistActions.storeViews(newViews));

        return;
    }

    const view = views[viewKey];

    yield put(PlaylistActions.storeViews({
        ...views,
        [viewKey]: view.update({
            ...view,
            isExpanded: true,
        }),
    }));
};

const collapseViewWorker = function* ({payload}) {
    const {viewKey} = payload;

    const views = yield select(PlaylistSelectors.selectViews);

    if (Array.isArray(viewKey)) {
        let newViews = {...views};

        for (const key of viewKey) {
            const newView = newViews[key];

            newViews = {
                ...newViews,
                [key]: newView.update({
                    ...newView,
                    isExpanded: false,
                }),
            };
        }

        yield put(PlaylistActions.storeViews(newViews));

        return;
    }

    const view = views[viewKey];

    yield put(PlaylistActions.storeViews({
        ...views,
        [viewKey]: view.update({
            ...view,
            isExpanded: false,
        }),
    }));
};

const setSelectedRegionForBlockWorker = function* ({payload}) {
    const {blockKey, regionId} = payload;

    const blocks = yield select(PlaylistSelectors.selectBlocks);
    const block = blocks[blockKey];

    if (!block) {
        return;
    }

    yield put(PlaylistActions.storeBlocks({
        ...blocks,
        [blockKey]: block.update({
            ...block,
            selectedRegionId: regionId,
        }),
    }));

    yield call(recalculateStartTimes, {blockKey});
};

const recalculateStartTimes = function* ({blockKey}) {
    const [showId, viewId] = blockKey.split('/');

    const entries = yield select(PlaylistSelectors.selectEntries);
    const tasks = yield select(PlaylistSelectors.selectTasks);
    const ddsFilters = yield select(PlaylistSelectors.selectDdsFilters);
    const ddsItems = yield select(PlaylistSelectors.selectDdsItems);

    const view = yield select(PlaylistSelectors.createViewByKeySelector(`${showId}/${viewId}`));
    let startTime = 0;

    for (const entryKey of view.entryKeys) {
        const entry = yield select(PlaylistSelectors.createEntryByKeySelector(entryKey));

        entries[entryKey] = entry.update({
            ...entry,
            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
        });

        if (entry.songKey) {
            const song = yield select(PlaylistSelectors.createSongByKeySelector(entry.songKey));

            startTime += song.length;
        } else if (entry.autoverpackungKey) {
            const autoverpackung = yield select(
                PlaylistSelectors.createAutoverpackungByKeySelector(entry.autoverpackungKey),
            );

            startTime += autoverpackung.length;
        } else if (entry.blockKey) {
            const block = yield select(PlaylistSelectors.createBlockByKeySelector(entry.blockKey));
            const [,,,, regionKey] = block.selectedRegionId.split('/');

            if (regionKey === '0') {
                const blockProgram = yield select(
                    PlaylistSelectors.createBlockProgramByKeySelector(block.selectedRegionId),
                );

                const items = yield select(PlaylistSelectors.createBlockProgramItemsByKeysSelector(blockProgram.items));

                for (const item of items) {
                    if (item.task) {
                        const task = yield select(PlaylistSelectors.createTaskByKeySelector(item.task));

                        tasks[item.task] = task.update({
                            ...task,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += task.length;
                    } else if (item.ddsFilter) {
                        const ddsFilter = yield select(PlaylistSelectors.createDdsFilterByKeySelector(item.ddsFilter));

                        ddsFilters[item.ddsFilter] = ddsFilter.update({
                            ...ddsFilter,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += ddsFilter.length;
                    } else if (item.ddsItem) {
                        const ddsItem = yield select(PlaylistSelectors.createDdsItemByKeySelector(item.ddsItem));

                        ddsItems[item.ddsItem] = ddsItem.update({
                            ...ddsItem,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += ddsItem.length;
                    }
                }
            } else {
                const splitProgram = yield select(
                    PlaylistSelectors.createSplitProgramByKeySelector(block.selectedRegionId),
                );

                const items = yield select(PlaylistSelectors.createSplitProgramItemsByKeysSelector(splitProgram.items));

                for (const item of items) {
                    if (item.task) {
                        const task = yield select(PlaylistSelectors.createTaskByKeySelector(item.task));

                        tasks[item.task] = task.update({
                            ...task,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += task.length;
                    } else if (item.ddsFilter) {
                        const ddsFilter = yield select(PlaylistSelectors.createDdsFilterByKeySelector(item.ddsFilter));

                        ddsFilters[item.ddsFilter] = ddsFilter.update({
                            ...ddsFilter,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += ddsFilter.length;
                    } else if (item.ddsItem) {
                        const ddsItem = yield select(PlaylistSelectors.createDdsItemByKeySelector(item.ddsItem));

                        ddsItems[item.ddsItem] = ddsItem.update({
                            ...ddsItem,
                            startTime: moment.utc(view.startTime, 'HH:mm').add(startTime, 'ms').format('HH:mm:ss'),
                        });

                        startTime += ddsItem.length;
                    }
                }
            }
        }
    }

    yield all([
        put(PlaylistActions.storeEntries({...entries})),
        put(PlaylistActions.storeTasks({...tasks})),
        put(PlaylistActions.storeDdsItems({...ddsItems})),
        put(PlaylistActions.storeDdsFilters({...ddsFilters})),
    ]);
};

export const updateTaskInPlaylist = function* (taskId, newTask) {
    const tasks = yield select(PlaylistSelectors.selectTasks);
    let newTasks = {...tasks};

    const taskValues = Object.values(newTasks);
    const taskKeys = Object.keys(newTasks);

    for (const index in taskValues) {
        const taskKey = taskKeys[index];

        if (!taskKey) {
            continue;
        }

        if (newTasks[taskKey].id !== taskId) {
            continue;
        }

        // eslint-disable-next-line no-loop-func
        const variation = newTask.variations?.find(variation => {
            return variation.id === newTasks[taskKey].taskVariationId;
        });

        const newTaskDto = variation ? {
            externalId: variation.externalId,
            isTaskCompleted: variation.isCompleted,
            length: variation.duration,
            isProcessing: variation.isProcessing,
            formattedLength: variation.formattedDuration,
            // the if statement should be replaced with hasMixingPlan ? null : variation.filename
            filename: variation.filename?.endsWith('.wav') || variation.filename?.endsWith('.mp3') ? variation.filename : null,
        } : {};

        newTasks = {
            ...newTasks,
            [taskKey]: new PlaylistTask({
                ...newTasks[taskKey],
                ...newTaskDto,
                status: newTask.status,
            }),
        };
    }

    yield put(PlaylistActions.storeTasks(newTasks));

    const isCompleted = newTask.status === TaskStatuses.COMPLETED;
    const isDeleted = newTask.status === TaskStatuses.DELETED;

    if (!isCompleted && !isDeleted) {
        return;
    }

    let viewEntities = yield select(PlaylistSelectors.selectViews);
    let blockEntities = yield select(PlaylistSelectors.selectBlocks);
    let blockProgramEntities = yield select(PlaylistSelectors.selectBlockPrograms);
    let splitProgramEntities = yield select(PlaylistSelectors.selectSplitPrograms);
    const blockProgramItemEntities = yield select(PlaylistSelectors.selectBlockProgramItems);
    const entryEntities = yield select(PlaylistSelectors.selectEntries);
    const splitProgramItemEntities = yield select(PlaylistSelectors.selectSplitProgramItems);

    for (const viewKey of Object.keys(viewEntities)) {
        const view = viewEntities[viewKey];

        const viewTaskIds = [];

        for (const entryKey of view.entryKeys) {
            const entry = entryEntities[entryKey];

            if (!entry.blockKey) {
                continue;
            }

            const block = blockEntities[entry.blockKey];
            const blockProgram = blockProgramEntities[block.blockProgramKey];

            const blockTaskIds = [];
            const blockProgramTaskIds = [];

            for (const blockProgramItemKey of blockProgram.items) {
                const blockProgramItem = blockProgramItemEntities[blockProgramItemKey];

                if (!blockProgramItem.task) {
                    continue;
                }

                const task = newTasks[blockProgramItem.task];

                if (!task.isTaskCompleted && task.isCurrentUserTask) {
                    viewTaskIds.push(task.id);
                    blockTaskIds.push(task.id);
                    blockProgramTaskIds.push(task.id);
                }
            }

            blockProgramEntities = {
                ...blockProgramEntities,
                [block.blockProgramKey]: blockProgramEntities[block.blockProgramKey].update({
                    ...blockProgramEntities[block.blockProgramKey],
                    numberOfOpenTasks: [...new Set(blockProgramTaskIds)].length,
                }),
            };

            for (const splitProgramKey of block.splitProgramKeys) {
                const splitProgram = splitProgramEntities[splitProgramKey];

                const splitProgramTaskIds = [];

                for (const splitProgramItemKey of splitProgram.items) {
                    const splitProgramItem = splitProgramItemEntities[splitProgramItemKey];

                    if (!splitProgramItem.task) {
                        continue;
                    }

                    const task = newTasks[splitProgramItem.task];

                    if (!task.isTaskCompleted && task.isCurrentUserTask) {
                        viewTaskIds.push(task.id);
                        blockTaskIds.push(task.id);
                        splitProgramTaskIds.push(task.id);
                    }
                }

                splitProgramEntities = {
                    ...splitProgramEntities,
                    [splitProgramKey]: splitProgramEntities[splitProgramKey].update({
                        ...splitProgramEntities[splitProgramKey],
                        numberOfOpenTasks: [...new Set(splitProgramTaskIds)].length,
                    }),
                };
            }

            blockEntities = {
                ...blockEntities,
                [entry.blockKey]: blockEntities[entry.blockKey].update({
                    ...blockEntities[entry.blockKey],
                    numberOfOpenTasks: [...new Set(blockTaskIds)].length,
                }),
            };

            viewEntities = {
                ...viewEntities,
                [viewKey]: viewEntities[viewKey].update({
                    ...viewEntities[viewKey],
                    numberOfOpenTasks: [...new Set(viewTaskIds)].length,
                }),
            };
        }
    }

    yield all([
        put(PlaylistActions.storeViews(viewEntities)),
        put(PlaylistActions.storeBlocks(blockEntities)),
        put(PlaylistActions.storeBlockPrograms(blockProgramEntities)),
        put(PlaylistActions.storeSplitPrograms(splitProgramEntities)),
        put(TaskOverviewActions.getTaskOverview()),
    ]);
};

const updateTaskInPlaylistWorker = function* ({payload}) {
    const {taskId, task} = payload;

    yield call(updateTaskInPlaylist, taskId, task);
};

const updatePlayingTypeWorker = function* ({payload}) {
    const {playlistItemId, playingType} = payload;

    const previousValue = playingType !== 'DRY';

    const currentlyLoading = yield select(
        LOADING_SELECTOR.createLoadingByTypeSelector(LOADING_TYPES.UPDATE_PLAYING_TYPE),
    );

    try {
        yield put(LoadingActions.setIsLoading(
            LOADING_TYPES.UPDATE_PLAYING_TYPE, [...currentlyLoading, payload.playlistItemId],
        ));

        const tasks = yield select(PlaylistSelectors.selectTasks);

        yield put(PlaylistActions.storeTasks(Object.keys(tasks).reduce((accumulator, key) => {
            if (playlistItemId === tasks[key].playlistItemId) {
                accumulator[key] = new PlaylistTask({
                    ...tasks[key],
                    isDry: playingType === 'DRY',
                });
            } else {
                accumulator[key] = tasks[key];
            }

            return accumulator;
        }, {})));

        yield call(updatePlayingTypeRequest, playlistItemId, playingType);
    } catch (error) {
        toastInstance.error('We failed to update "Play dry" for a task...');

        const tasks = yield select(PlaylistSelectors.selectTasks);

        yield put(PlaylistActions.storeTasks(Object.keys(tasks).reduce((accumulator, key) => {
            if (playlistItemId === tasks[key].playlistItemId) {
                accumulator[key] = new PlaylistTask({
                    ...tasks[key],
                    isDry: previousValue,
                });
            } else {
                accumulator[key] = tasks[key];
            }

            return accumulator;
        }, {})));

        yield call(handleSagaError, error, 'updatePlayingTypeWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(
            LOADING_TYPES.UPDATE_PLAYING_TYPE, currentlyLoading.filter(id => id !== playlistItemId),
        ));
    }
};

const sendTaskIdsToRecordingWindow = function* () {
    yield delay(250);
    const tasks = yield select(PlaylistSelectors.selectTasks);
    const taskKeys = yield select(PlaylistSelectors.createTaskKeysSelector());

    // Very, very ugly, but has to be done at the moment to avoid dependency cycle; FilterSelectors can not be imported
    // TODO: This should be refactored - no time at the moment
    const filterState = yield select(state => state.homeFilters);

    // HomeViews can not be imported here, due to a dependency cycle.
    // TODO: This should be refactored - no time at the moment
    const isOnPlaylist = filterState.activeView === 'playlist';

    const startDate = filterState.startDate ? moment(filterState.startDate).format('YYYY-MM-DD') : undefined;

    const searchTerm = yield select(PlaylistSelectors.selectSearchTerm);
    const role = yield select(PlaylistSelectors.selectRole);
    const firstPlanningFilter = yield select(PlaylistSelectors.selectFirstPlanningFilter);
    const showStructured = yield select(PlaylistSelectors.selectShowStructured);

    const taskIds = taskKeys
        .sort((previousTaskKey, nextTaskKey) => {
            const previousTask = tasks[previousTaskKey];
            const nextTask = tasks[nextTaskKey];

            return previousTask.playlistItemId - nextTask.playlistItemId;
        })
        .reduce((accumulator, current, currentIndex) => {
            const task = tasks[current];

            if (isOnPlaylist && !showStructured && !isMatchingListTask(role, searchTerm, firstPlanningFilter, task)) {
                return accumulator;
            } else if (!task.isCurrentUserTask) {
                return accumulator;
            } else if (task.isTaskCompleted) {
                return accumulator;
            }

            const id = `${task.id}/${task.taskVariationId}`;

            if (!accumulator[id]) {
                accumulator[id] = {
                    taskId: task.id,
                    taskVariationId: task.taskVariationId,
                    startDate: startDate,
                    startTime: task.startTime,
                    playlistTaskKey: taskKeys[currentIndex],
                };
            }

            return accumulator;
        }, {});

    yield put(CockpitNavigationActions.storeIds(taskIds));

    yield put(BroadcastChannelActions.send({
        messageType: 'action',
        action: CockpitNavigationActions.storeIds(taskIds),
    }));
};

/**
 * @param {string} payload Search term
 * @returns {Generator<*, void, *>}
 */
const onSearchTermChange = function* ({payload}) {
    let isFirstMatch = false;
    const isFirstMatchByBlock = {};

    const views = yield select(PlaylistSelectors.selectViews);
    let viewKeys = [];

    for (const key of Object.keys(views)) {
        viewKeys.push(key);
    }

    yield put(PlaylistActions.collapseView(viewKeys));

    yield delay(250); // animation is 225ms

    const tasks = yield select(PlaylistSelectors.selectTasks);
    const taskValues = Object.values(tasks);
    let scrollToKey = null;
    viewKeys = [];

    for (const index in taskValues) {
        const task = taskValues[index];

        if (isMatchingPlaylistTask(payload, task)) {
            const taskKey = Object.keys(tasks)[index];

            const [showId, viewId, entryId, blockId, programKey] = taskKey.split('/');
            const viewKey = `${showId}/${viewId}`;
            const blockKey = `${viewKey}/${entryId}/${blockId}`;
            const regionKey = `${blockKey}/${programKey}`;

            viewKeys.push(viewKey);

            if (!isFirstMatch) {
                isFirstMatch = true;

                scrollToKey = Object.keys(tasks)[index];
            }

            if (!isFirstMatchByBlock[blockKey]) {
                isFirstMatchByBlock[blockKey] = true;

                yield put(PlaylistActions.setSelectedRegionForBlock(blockKey, regionKey));
            }
        }
    }

    yield put(PlaylistActions.expandView(viewKeys));

    if (scrollToKey) {
        yield delay(250); // animation is 225ms

        yield put(UiActions.setScrollToElement(scrollToKey, true));
    }
};

export const playlistRootSaga = function* () {
    yield all([
        takeEvery(PlaylistActionTypes.TOGGLE_VIEW_COLLAPSE, toggleViewCollapseWorker),
        takeEvery(PlaylistActionTypes.EXPAND_VIEW, expandViewWorker),
        takeEvery(PlaylistActionTypes.COLLAPSE_VIEW, collapseViewWorker),
        takeEvery(PlaylistActionTypes.SET_SELECTED_REGION_FOR_BLOCK, setSelectedRegionForBlockWorker),
        takeEvery(PlaylistActionTypes.UPDATE_TASK_IN_PLAYLIST, updateTaskInPlaylistWorker),
        takeEvery(PlaylistActionTypes.UPDATE_PLAYING_TYPE, updatePlayingTypeWorker),
        takeLatest(PlaylistActionTypes.SET_SEARCH_TERM, onSearchTermChange),
        takeLatest([
            PlaylistActionTypes.SET_SEARCH_TERM,
            PlaylistActionTypes.SET_ROLE,
            PlaylistActionTypes.SET_FIRST_PLANNING_FILTER,
            PlaylistActionTypes.SET_SHOW_STRUCTURED,
        ], sendTaskIdsToRecordingWindow),
    ]);
};
