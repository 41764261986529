const selectZoomFactor = state => state.editor.zoomFactor;

const selectIsAutoscrollEnabled = state => state.editor.isAutoscrollEnabled;

const selectIsUserGestureRequired = state => state.editor.isUserGestureRequired;

const selectSelectedElementIndex = state => state.editor.selectedElementId;

const selectSteps = state => state.editor.steps;

export const EditorSelectors = {
    selectZoomFactor,
    selectIsAutoscrollEnabled,
    selectIsUserGestureRequired,
    selectSelectedElementIndex,
    selectSteps,
};
