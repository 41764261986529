import de from 'date-fns/locale/de';
import React, {Suspense} from 'react';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import 'react-toastify/dist/ReactToastify.css';

import {App} from './App';
import './styles/global.scss';
import {CONFIG} from './config';
import {history, store} from './store';
import './lib/i18next';

registerLocale('de', de);
setDefaultLocale('en');

const container = document.getElementById('root');
const root = createRoot(container);

if (CONFIG.NODE_ENVIRONMENT === 'development') {
    window.DOWNLOAD_WAV_FILES = true;
}

root.render((
    <React.Fragment>
        <Suspense fallback="LOADING">
            <Provider store={store}>
                <Router history={history}>
                    <DndProvider backend={HTML5Backend}>
                        <App />
                    </DndProvider>
                </Router>
            </Provider>
        </Suspense>
    </React.Fragment>
));
