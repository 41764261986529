import {applyMiddleware, compose, legacy_createStore as createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {CONFIG} from './config';
import {createBroadcastChannelMiddleware} from './features/broadcast-channel';
import {logTailService} from './features/log-tail/log-tail.service';
import playbackAlgorithm from './features/recording-algorithm/PlaybackAlgorithm';
import recordingAlgorithm from './features/recording-algorithm/RecordingAlgorithm';
import {webSocketMiddleware} from './features/web-socket/store/web-socket.middleware';
import {createReduxHistory, routerMiddleware} from './lib/redux-first-history';
import {loggerMiddleware} from './lib/redux/logger';
import {monitorReducerEnhancer} from './lib/redux/monitor';
import {createRootReducer} from './reducer';
import {rootSaga} from './saga';

const sagaMiddleware = createSagaMiddleware({
    async onError(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error({
            error,
            errorInfo,
        });

        await logTailService.error('SAGA ERROR: ', {
            error,
            errorInfo,
            version: CONFIG.APP_VERSION,
        });
    },
});
const broadcastChannelMiddleware = createBroadcastChannelMiddleware('mcp');

let composeFunction = compose;

if (CONFIG.NODE_ENVIRONMENT !== 'production') {
    // eslint-disable-next-line no-underscore-dangle
    const {__REDUX_DEVTOOLS_EXTENSION_COMPOSE__} = window;

    if (typeof window === 'object' && __REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        // eslint-disable-next-line no-underscore-dangle
        composeFunction = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
}

const middlewares = [
    webSocketMiddleware,
    routerMiddleware,
    broadcastChannelMiddleware,
    sagaMiddleware,
    loggerMiddleware,
];

const appReducer = createRootReducer();

// Reset state on logout
const rootReducer = (state, action) => {
    switch (action.type) {
        case 'RESET_REDUCERS':
            return appReducer({
                router: appReducer(state, action).router,
                loading: appReducer(state, action).loading,
            }, action);

        default:
            return appReducer(state, action);
    }
};

export const store = createStore(rootReducer, composeFunction(
    applyMiddleware(...middlewares),
    monitorReducerEnhancer,
));

recordingAlgorithm.setReduxDispatch(store.dispatch);
playbackAlgorithm.setReduxDispatch(store.dispatch);

// This needs to be before creating history
sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
