import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classes from './TaskNumberIndicator.module.scss';
import {Typography} from '../../../../components';

/**
 * @enum
 */
const SIZES = {
    DEFAULT: 'default',
    SMALL: 'small',
};

export const TaskNumberIndicator = ({count, color, size, noTasksColor}) => {
    const {t} = useTranslation('screensHome');

    return (
        <div
            className={clsx(classes.root, classes[`size-${size}`], {
                [classes[`color-${color}`]]: color && color !== 'default',
                [classes[`noTasksColor-${noTasksColor}`]]: !count,
            })}
        >
            <Typography size={12} weight={500} className={classes.text}>
                {size === SIZES.SMALL ? count : t('taskNumberIndicator.numberOfTasks', {count})}
            </Typography>
        </div>
    );
};

TaskNumberIndicator.propTypes = {
    count: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['default', 'orange', 'white']),
    noTasksColor: PropTypes.oneOf(['default', 'orange', 'white', 'green']),
    size: PropTypes.oneOf(['default', 'small']),
};

TaskNumberIndicator.defaultProps = {
    color: 'default',
    noTasksColor: 'default',
    size: SIZES.DEFAULT,
};
