import {EditorActionTypes} from './editor.action-type';

export const EditorActions = {
    setZoomFactor: zoomFactor => ({
        type: EditorActionTypes.SET_ZOOM_FACTOR,
        payload: zoomFactor,
    }),
    zoomIn: () => ({
        type: EditorActionTypes.ZOOM_IN,
    }),
    zoomOut: () => ({
        type: EditorActionTypes.ZOOM_OUT,
    }),
    setIsAutoscrollEnabled: isAutoscrollEnabled => ({
        type: EditorActionTypes.SET_IS_AUTOSCROLL_ENABLED,
        payload: isAutoscrollEnabled,
    }),
    setIsUserGestureRequired: isUserGestureRequired => ({
        type: EditorActionTypes.SET_IS_USER_GESTURE_REQUIRED,
        payload: isUserGestureRequired,
    }),
    setSelectedElementIndex: elementId => ({
        type: EditorActionTypes.SET_SELECTED_ELEMENT_INDEX,
        payload: elementId,
    }),
    setSteps: steps => ({
        type: EditorActionTypes.SET_STEPS,
        payload: steps,
    }),
};
