import {SvgIcon} from '../SvgIcon/SvgIcon';

export const MarkerText = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 30 30" shapeRendering="geometricPrecision">
            <path fill="#FE1378" d="M3 0h24a3 3 0 0 1 3 3v15.394a3 3 0 0 1-1.336 2.497L15 30 1.336 20.89A3 3 0 0 1 0 18.395V3a3 3 0 0 1 3-3Z" />
            <path fill="#FFF" fillRule="nonzero" d="M8.5 8a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5Zm10 0a.5.5 0 0 1 .5.5v7a.5.5 0 1 1-1 0v-7a.5.5 0 0 1 .5-.5Zm4 2a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5Zm-12-4a.5.5 0 0 1 .5.5v11a.5.5 0 1 1-1 0v-11a.5.5 0 0 1 .5-.5Zm4 0a.5.5 0 0 1 .5.5v11a.5.5 0 1 1-1 0v-11a.5.5 0 0 1 .5-.5Zm6 0a.5.5 0 0 1 .5.5v11a.5.5 0 1 1-1 0v-11a.5.5 0 0 1 .5-.5Zm-8-2a.5.5 0 0 1 .5.5v15a.5.5 0 1 1-1 0v-15a.5.5 0 0 1 .5-.5Zm4 0a.5.5 0 0 1 .5.5v15a.5.5 0 1 1-1 0v-15a.5.5 0 0 1 .5-.5Z" />
        </SvgIcon>
    );
};
