export const PresetActionTypes = {
    STORE_ENTITIES: 'preset/STORE_ENTITIES',
    CREATE_PRESET: 'preset/CREATE_PRESET',
    UPDATE_PRESET: 'preset/UPDATE_PRESET',
    DELETE_PRESET: 'preset/DELETE_PRESET',
    REMOVE_ELEMENT_FROM_LOADED_PRESET: 'preset/REMOVE_ELEMENT_FROM_LOADED_PRESET',
    LOAD_ELEMENTS_FROM_PRESET: 'preset/LOAD_ELEMENTS_FROM_PRESET',
    SET_LOADED_PRE_PRODUCED_PRESET_ID: 'preset/SET_LOADED_PRE_PRODUCED_PRESET_ID',
    SET_LOADED_SFX_PRESET_ID: 'preset/SET_LOADED_SFX_PRESET_ID',
    SET_LOADED_BED_PRESET_ID: 'preset/SET_LOADED_BED_PRESET_ID',
    LOAD_PRESETS: 'preset/LOAD_PRESETS',
};
