import BaseAudioNode from './BaseAudioNode';
import {FADE_OUT_VARS} from '../audioContextConstants';

class SoundEffectAudioNode extends BaseAudioNode {
    /**
     * @type {number}
     */
    #onEndTimeout = 0;

    /**
     * @type {number}
     */
    #forbidFadeOutTimeout = 0;

    /**
     * @type {boolean}
     */
    #isStopping = false;

    /**
     * @returns {boolean}
     */
    get isStopping() {
        return this.#isStopping;
    }

    /**
     * @param {number} fadeOutDuration
     */
    play(fadeOutDuration) {
        super.play();

        // When the node stops playing.
        this.#onEndTimeout = setTimeout(() => {
            this.#isStopping = false;

            super.stop();
        }, (super.duration - super.offset) * 1000);

        // In case there's only 1.5 seconds left, we prevent the user to fade out the node.
        // The node will stop playing anyway, so it's pointless to fade it out then.
        this.#forbidFadeOutTimeout = setTimeout(() => {
            this.#isStopping = true;
        }, (super.duration - super.offset - fadeOutDuration) * 1000);
    }

    /**
     * @param fadeOutDuration
     * @param offset
     */
    scheduleStart(fadeOutDuration, offset) {
        super.play(offset);

        super.didPlayOnStart = true;

        // When the node stops playing.
        this.#onEndTimeout = setTimeout(() => {
            this.#isStopping = false;

            super.stop();
        }, (super.duration + offset) * 1000);

        // In case there's only 1.5 seconds left, we prevent the user to fade out the node.
        // The node will stop playing anyway, so it's pointless to fade it out then.
        this.#forbidFadeOutTimeout = setTimeout(() => {
            this.#isStopping = true;
        }, (super.duration - fadeOutDuration + offset) * 1000);
    }

    /**
     * @param {number} duration
     * @param {function} onEndCallback
     */
    fadeOut(duration, onEndCallback) {
        this.#isStopping = true;

        super.volumeTransition(duration, FADE_OUT_VARS.FADE_OUT_END_VOLUME);

        if (this.#onEndTimeout) {
            clearTimeout(this.#onEndTimeout);
        }

        this.#onEndTimeout = setTimeout(() => {
            super.stop();

            this.#isStopping = false;

            if (onEndCallback && typeof onEndCallback === 'function') {
                onEndCallback();
            }
        }, duration * 1000);

        if (this.#forbidFadeOutTimeout) {
            clearTimeout(this.#forbidFadeOutTimeout);
        }
    }

    /**
     * @return {number}
     */
    getPassedTime() {
        if (!super.isPlaying) {
            return 0;
        }

        return super.audioContext.currentTime - super.lastStartedAt();
    }
}

export default SoundEffectAudioNode;
