import {combineReducers} from 'redux';
import {FilterActionTypes} from './home-filters.action-type';
import {NOT_SELECTED} from '../../../utils';
import {HomeViews} from '../home-filters.consts';

const createFieldReducer = (fieldKey, initialState) => (state = initialState, {type, payload}) => {
    if (type === FilterActionTypes.SET_FIELD_VALUE && fieldKey === payload.fieldKey) {
        return payload.value;
    }

    return state;
};

export const homeFiltersReducer = combineReducers({
    startDate: createFieldReducer('startDate', null),
    brand: createFieldReducer('brand', NOT_SELECTED),
    channel: createFieldReducer('channel', NOT_SELECTED),
    // contentType: createFieldReducer('contentType', NOT_SELECTED), // TODO
    role: createFieldReducer('role', NOT_SELECTED),
    search: createFieldReducer('search', ''),
    activeView: createFieldReducer('activeView', HomeViews.OVERVIEW),
});
