import {combineReducers} from 'redux';
import {EditorActionTypes} from './editor.action-type';
import {FINE_TUNE_STEPS} from '../../../constants';
import {createReducer} from '../../../utils';
import {EditorConfig} from '../utils/constants';

export const editorReducer = combineReducers({
    zoomFactor: createReducer(EditorConfig.DefaultZoomFactor, EditorActionTypes.SET_ZOOM_FACTOR),
    isAutoscrollEnabled: createReducer(true, EditorActionTypes.SET_IS_AUTOSCROLL_ENABLED),
    isUserGestureRequired: createReducer(false, EditorActionTypes.SET_IS_USER_GESTURE_REQUIRED),
    selectedElementId: createReducer(null, EditorActionTypes.SET_SELECTED_ELEMENT_INDEX),
    steps: createReducer(FINE_TUNE_STEPS[0], EditorActionTypes.SET_STEPS),
});
