import {PresetActionTypes} from './preset.action-type';

export const PresetActions = {
    storeEntities: entities => ({
        type: PresetActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    createPreset: (name, audioType) => ({
        type: PresetActionTypes.CREATE_PRESET,
        payload: {
            name,
            audioType,
        },
    }),
    updatePreset: (presetId, name, audioType) => ({
        type: PresetActionTypes.UPDATE_PRESET,
        payload: {
            presetId,
            name,
            audioType,
        },
    }),
    deletePreset: presetId => ({
        type: PresetActionTypes.DELETE_PRESET,
        payload: {
            presetId,
        },
    }),
    loadElementsFromPreset: presetId => ({
        type: PresetActionTypes.LOAD_ELEMENTS_FROM_PRESET,
        payload: {
            presetId,
        },
    }),
    setLoadedPreProducedPresetId: presetId => ({
        type: PresetActionTypes.SET_LOADED_PRE_PRODUCED_PRESET_ID,
        payload: presetId,
    }),
    setLoadedSfxPresetId: presetId => ({
        type: PresetActionTypes.SET_LOADED_SFX_PRESET_ID,
        payload: presetId,
    }),
    setLoadedBedPresetId: presetId => ({
        type: PresetActionTypes.SET_LOADED_BED_PRESET_ID,
        payload: presetId,
    }),
    removeElementFromLoadedPreset: (elementId, presetType) => ({
        type: PresetActionTypes.REMOVE_ELEMENT_FROM_LOADED_PRESET,
        payload: {
            elementId,
            presetType,
        },
    }),
    loadPresets: (brandId, presetType) => ({
        type: PresetActionTypes.LOAD_PRESETS,
        payload: {
            brandId,
            presetType,
        },
    }),
};
