import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';
import {EditorSelectors} from '../store/editor.selector';

export const useSelectedNode = () => {
    const [node, setNode] = useState(null);
    const subscriber = useRef(null);
    const index = useSelector(EditorSelectors.selectSelectedElementIndex);

    useEffect(() => {
        const onUpdate = node => {
            setNode(previousNode => {
                if (previousNode && !node) {
                    return null;
                }

                if (!node) {
                    return null;
                }

                return {
                    type: node.type,
                    title: node.title,
                    startAt: node.startAt,
                    stopAt: node.stopAt,
                };
            });
        };

        subscriber.current = playbackAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.SELECTED_NODE_DATA, true, {
            index,
        });

        return () => {
            subscriber.current.unsubscribe();
        };
    }, [index]);

    return {node};
};
