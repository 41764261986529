import {createSelector} from 'reselect';

const selectGenericProductionOrderIds = state => state.genericTask.genericProductionOrderIds;

const selectGenericProductionOrders = state => state.genericTask.genericProductionOrders;

const selectGenericTasks = state => state.genericTask.genericTasks;

const selectGenericTaskVariations = state => state.genericTask.genericTaskVariations;

const selectSelectedGenericTaskVariationId = state => state.genericTask.selectedGenericTaskVariationId;

const selectIsControllerOpen = state => state.genericTask.isControllerOpen;

const createGenericProductionOrderByIdSelector = genericProductionOrderId => createSelector([
    selectGenericProductionOrders,
], genericProductionOrders => {
    return genericProductionOrders[genericProductionOrderId];
});

const createGenericTaskByIdSelector = genericTaskId => createSelector([
    selectGenericTasks,
], genericTasks => {
    return genericTasks[genericTaskId];
});

const createGenericTaskVariationByIdSelector = genericTaskVariationId => createSelector([
    selectGenericTaskVariations,
], genericTaskVariations => {
    return genericTaskVariations[genericTaskVariationId];
});

export const GenericTaskSelectors = {
    selectGenericProductionOrderIds,
    selectGenericProductionOrders,
    selectGenericTasks,
    selectGenericTaskVariations,
    selectSelectedGenericTaskVariationId,
    selectIsControllerOpen,
    createGenericProductionOrderByIdSelector,
    createGenericTaskByIdSelector,
    createGenericTaskVariationByIdSelector,
};
