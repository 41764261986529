import Grow from '@mui/material/Grow';
import {useMeasure} from '@react-hookz/web';
import clsx from 'clsx';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './Editor.module.scss';
import {Elements} from './Elements';
import {Markers} from './Markers';
import {ScaleIndicator} from './ScaleIndicator';
import {SelectedElement} from './SelectedElement';
import {StartEndTime} from './StartEndTime';
import {StepSelect} from './StepSelect';
import {Button, IconButton, Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {useScroll} from '../../../hooks/use-scroll';
import {AutoScrollIcon, ChevronLeft,
    ChevronRight,
    CloseIcon,
    LoadingBars,
    PauseIcon,
    PlayIcon,
    SaveIcon,
    ZoomInIcon,
    ZoomOutIcon} from '../../../icons';
import {LOADING_TYPES, useIsLoading} from '../../loading';
import {MixingPlanActions} from '../../mixing-plan/store/mixing-plan.action';
import {MixingPlanSelectors} from '../../mixing-plan/store/mixing-plan.selector';
import {RecordingAlgorithmActions} from '../../recording-algorithm/store/recording-algorithm.action';
import {TaskActions} from '../../task/store/task.action';
import {useCanBePlayed} from '../hooks/use-can-be-played';
import {useCurrentPosition} from '../hooks/use-current-position';
import {useIsPlaying} from '../hooks/use-is-playing';
import {useSelectedNode} from '../hooks/use-selected-node-data';
import {EditorActions} from '../store/editor.action';
import {EditorSelectors} from '../store/editor.selector';
import {EditorConfig} from '../utils/constants';

// Not reusable hook
const useButtonClickRepeater = ({callback}) => {
    const interval = useRef(null);

    const handleMouseDown = event => {
        if (event.button !== 0) {
            return;
        }

        interval.current = setInterval(callback, 125);
    };

    const handleMouseUp = useCallback(() => {
        if (interval.current) {
            clearInterval(interval.current);
        }
    }, []);

    return {
        handleMouseDown,
        handleMouseUp,
    };
};

const LegendItem = ({color, children}) => {
    return (
        <div className={classes.legendItem}>
            <div className={clsx(classes.colorIndicator, classes[`color-${color}`])} />

            <Typography transform="uppercase" color="regent" weight={500} hasLineHeight={false}>
                {children}
            </Typography>
        </div>
    );
};

LegendItem.propTypes = {
    color: PropTypes.oneOf(['stone', 'radiance', 'rose', 'sun', 'turquoise']).isRequired,
};

const Position = () => {
    const {position} = useCurrentPosition();
    const duration = moment.duration(position, 'ms').asMilliseconds();
    const formattedPosition = moment.utc(duration).format(EditorConfig.TimeFormat);

    return (
        <Typography variant="body" color="white" weight={500} hasGutters={false}>
            {formattedPosition}
        </Typography>
    );
};

const ZoomInButton = props => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);

    const handleClick = useCallback(() => {
        dispatch(EditorActions.zoomIn());
    }, [dispatch]);

    const {handleMouseDown, handleMouseUp} = useButtonClickRepeater({callback: handleClick});

    return (
        <IconButton
            backgroundColor="transparent"
            color="white"
            hoverStyle="color"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            disabled={isLoading}
            {...props}
        >
            <ZoomInIcon />
        </IconButton>
    );
};

const ZoomOutButton = props => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);

    const handleClick = useCallback(() => {
        dispatch(EditorActions.zoomOut());
    }, [dispatch]);

    const {handleMouseDown, handleMouseUp} = useButtonClickRepeater({callback: handleClick});

    return (
        <IconButton
            backgroundColor="transparent"
            color="white"
            hoverStyle="color"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            disabled={isLoading}
            {...props}
        >
            <ZoomOutIcon />
        </IconButton>
    );
};

const AutoscrollButton = () => {
    const dispatch = useDispatch();

    const isAutoscrollEnabled = useSelector(EditorSelectors.selectIsAutoscrollEnabled);
    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);

    const handleClick = useCallback(() => {
        dispatch(EditorActions.setIsAutoscrollEnabled(!isAutoscrollEnabled));
    }, [isAutoscrollEnabled, dispatch]);

    return (
        <IconButton
            backgroundColor="transparent"
            color={isAutoscrollEnabled ? 'radiance' : 'white'}
            hoverStyle="color-opacity"
            onClick={handleClick}
            disabled={isLoading}
        >
            <AutoScrollIcon />
        </IconButton>
    );
};

const Timeline = ({editorWidth, hasAnimation}) => {
    const isAutoscrollEnabled = useSelector(EditorSelectors.selectIsAutoscrollEnabled);

    const wrapperRef = useRef();

    useEffect(() => {
        if (isAutoscrollEnabled) {
            wrapperRef.current.scrollLeft = 0;
        }
    }, [isAutoscrollEnabled]);

    return (
        <div
            className={clsx(classes.editorInnerWrapper, {
                [classes.isAutoScrollEnabled]: isAutoscrollEnabled,
            })}
            style={{overflow: isAutoscrollEnabled ? 'hidden' : 'scroll'}}
            ref={wrapperRef}
        >
            {!!wrapperRef.current && (
                <TimelineContent wrapperRef={wrapperRef} editorWidth={editorWidth} hasAnimation={hasAnimation} />
            )}
        </div>
    );
};

Timeline.propTypes = {
    editorWidth: PropTypes.number,
    hasAnimation: PropTypes.bool.isRequired,
};

Timeline.defaultProps = {
    editorWidth: 0,
};

const TimelineContent = ({wrapperRef, editorWidth, hasAnimation}) => {
    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);
    const {x} = useScroll(wrapperRef);
    const isUserGestureRequired = useSelector(EditorSelectors.selectIsUserGestureRequired);

    if (isLoading) {
        return (
            <div className={classes.loadingWrapper}>
                <LoadingBars size={48} color="regent" />
            </div>
        );
    }

    if (isUserGestureRequired) {
        return (
            <div className={classes.userGestureRequiredWrapper}>
                <Typography weight={500} color="stone" size={14} align="center">
                    We could not initialize the playback automatically.
                    Please, click the play button to continue.
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.timeline}>
            <Markers editorWidth={editorWidth} hasAnimation={hasAnimation} wrapperScrollLeft={x} />

            <Elements editorWidth={editorWidth} />

            <ScaleIndicator editorWidth={editorWidth} />
        </div>
    );
};

TimelineContent.propTypes = {
    hasAnimation: PropTypes.bool.isRequired,
    wrapperRef: PropTypes.object.isRequired,
    editorWidth: PropTypes.number,
};

TimelineContent.defaultProps = {
    editorWidth: 0,
};

const PlayButton = () => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);
    const {isPlaying} = useIsPlaying();
    const {canBePlayed} = useCanBePlayed();

    const handleClick = () => {
        if (isPlaying) {
            dispatch(RecordingAlgorithmActions.pause());
            return;
        }

        dispatch(RecordingAlgorithmActions.play());
    };

    return (
        <IconButton
            backgroundColor="mako"
            color="white"
            size={40}
            className={classes.playButton}
            onClick={handleClick}
            disabled={isLoading || !canBePlayed}
        >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
    );
};

const LegendFooter = () => {
    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);
    const isEditing = useSelector(MixingPlanSelectors.selectIsEditing);
    const hasChanges = useMemoizedCreateSelector(MixingPlanSelectors.createHasMixingPlanChangesSelector, []);
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(MixingPlanActions.reset());
    };

    const handleSave = () => {
        dispatch(TaskActions.updateMixingPlan());
    };

    return (
        <div className={classes.footer}>
            <div className={classes.footerDivider} />

            <Button
                className={classes.button}
                startIcon={<CloseIcon />}
                disabled={isLoading || !isEditing}
                onClick={handleCancel}
            >
                Cancel
            </Button>

            <div className={classes.footerDivider} />

            <Button
                className={classes.button}
                startIcon={<SaveIcon />}
                disabled={isLoading || !isEditing || (isEditing && !hasChanges)}
                onClick={handleSave}
            >
                Save
            </Button>

            <div className={classes.footerDivider} />
        </div>
    );
};

const EditorFooter = () => {
    const dispatch = useDispatch();

    const selectedElementIndex = useSelector(EditorSelectors.selectSelectedElementIndex);

    const {node} = useSelectedNode();

    const startTime = node ? node.startAt * 1000 : 0;
    const endTime = node ? node.stopAt * 1000 : 0;

    return (
        <Grow in={!!selectedElementIndex && !!node} mountOnEnter unmountOnExit>
            <div className={classes.editorFooter}>
                <SelectedElement nodeType={node?.type} title={node?.title} />

                <div className={classes.editorHeaderDivider} />

                <StartEndTime startTime={startTime} endTime={endTime} />

                <div className={classes.editorHeaderDivider} />

                <StepSelect />

                <div className={classes.editorHeaderDivider} />

                <div className={classes.editorMoveActionsWrapper}>
                    <div className={classes.editorMoveAction}>
                        <Typography
                            variant="body"
                            size={11}
                            weight={500}
                            align="center"
                            color="regent"
                            transform="uppercase"
                            hasGutters={false}
                        >
                            Backward
                        </Typography>

                        <IconButton
                            size={40}
                            iconSize={30}
                            backgroundColor="transparent"
                            color="white"
                            hoverStyle="color"
                            onClick={() => {
                                dispatch(MixingPlanActions.moveElement('backward'));
                            }}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </div>

                    <div className={classes.editorHeaderDivider} />

                    <div className={classes.editorMoveAction}>
                        <Typography
                            variant="body"
                            size={11}
                            weight={500}
                            align="center"
                            color="regent"
                            transform="uppercase"
                            hasGutters={false}
                        >
                            Forward
                        </Typography>

                        <IconButton
                            size={40}
                            iconSize={30}
                            backgroundColor="transparent"
                            color="white"
                            hoverStyle="color"
                            onClick={() => {
                                dispatch(MixingPlanActions.moveElement('forward'));
                            }}
                        >
                            <ChevronRight />
                        </IconButton>
                    </div>
                </div>
            </div>
        </Grow>
    );
};

export const Editor = forwardRef((props, ref) => {
    const isLoading = useIsLoading(LOADING_TYPES.EDITOR);
    const [editorMeasurements, editorRef] = useMeasure();
    const [hasAnimation, setHasAnimation] = useState(true);

    const handleMouseEnter = () => {
        setHasAnimation(false);
    };

    const handleMouseLeave = () => {
        setHasAnimation(true);
    };

    return (
        <div className={classes.root} ref={ref}>
            <aside className={classes.sidebar}>
                <div className={classes.legendPlaceholder} />

                <div className={classes.legendWrapper}>
                    <LegendItem color="stone">
                        Before/after
                    </LegendItem>

                    <LegendItem color="radiance">
                        Recording
                    </LegendItem>

                    <LegendItem color="rose">
                        Content
                    </LegendItem>

                    <LegendItem color="sun">
                        Drop
                    </LegendItem>

                    <LegendItem color="turquoise">
                        Bed
                    </LegendItem>
                </div>

                <LegendFooter />
            </aside>

            <div className={classes.main}>
                <div className={classes.editorHeader}>
                    <PlayButton />

                    <div className={classes.currentPosition}>
                        <Typography size={12} weight={500} color="regent" transform="uppercase" letterSpacing={1}>
                            Position&nbsp;
                        </Typography>

                        {/* Force re-render when the loading changes because the component stops working once
                         the playback algorithm is reinitialized */}
                        <Position key={isLoading ? 'position-1' : 'position-2'} />
                    </div>

                    <div className={classes.actions}>
                        <AutoscrollButton />

                        <div className={classes.editorHeaderDivider} />

                        <ZoomInButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />

                        <div className={classes.editorHeaderDivider} />

                        <ZoomOutButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                    </div>
                </div>

                <div className={classes.editor} ref={editorRef}>
                    <div className={classes.mainBackground} />

                    <Timeline editorWidth={editorMeasurements?.width} hasAnimation={hasAnimation} />
                </div>

                <EditorFooter />
            </div>
        </div>
    );
});
