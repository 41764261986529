import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './UrgentTasksAlert.module.scss';
import {Container, Typography} from '../../../components';
import {ErrorIcon} from '../../../icons';

export const UrgentTasksAlert = forwardRef((props, ref) => {
    const {t} = useTranslation('featuresTaskOverview');

    return (
        <div ref={ref} className={classes.root}>
            <Container>
                <div className={classes.topContent}>
                    <div className={classes.iconWrapper}>
                        <ErrorIcon size={24} className={classes.icon} />
                    </div>

                    <div className={classes.messageWrapper}>
                        <Typography weight={500} color="orange" hasGutters={false} className={classes.title} variant="body">
                            {t('urgentTasksAlert.title')}
                        </Typography>

                        <Typography>
                            {t('urgentTasksAlert.message')}
                        </Typography>
                    </div>
                </div>
            </Container>
        </div>
    );
});
