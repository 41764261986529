import moment from 'moment';
import {Debug} from './debug';

let database;

const initialize = function () {
    return new Promise((resolve, reject) => {
        const connection = window.indexedDB.open('moderation-cockpit', 1);

        connection.onerror = function (error) {
            Debug.error('indexed-db', 'Failed to open IndexedDB.', {error});

            reject(error);
        };

        connection.onsuccess = function () {
            Debug.debug('indexed-db', 'IndexedDB has been successfully opened!');

            database = connection.result;

            resolve();
        };

        connection.onupgradeneeded = event => {
            const database = event.target.result;

            const audioObjectStore = database.createObjectStore('audio', {autoIncrement: false});

            audioObjectStore.createIndex('id', 'id', {unique: true});
            audioObjectStore.createIndex('blob', 'blob', {unique: true});
            audioObjectStore.createIndex('validUntil', 'validUntil', {unique: false});

            const failedUploadsStore = database.createObjectStore('failedUploads', {autoIncrement: false});

            failedUploadsStore.createIndex('taskId', 'taskId');
            failedUploadsStore.createIndex('taskVariationId', 'taskVariationId', {unique: true});
            failedUploadsStore.createIndex('mixingPlan', 'mixingPlan');
            failedUploadsStore.createIndex('files', 'files', {multiEntry: true});
            failedUploadsStore.createIndex('expirationDate', 'expirationDate');
            failedUploadsStore.createIndex('isAudioWrapped', 'isAudioWrapped');

            Debug.debug('indexed-db', 'Database upgraded!');
        };
    });
};

const storeAudio = function (id, blob) {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('audio', 'readwrite').objectStore('audio');

            const record = {
                id,
                blob,
                validUntil: moment().add(24, 'hours').toISOString(),
            };

            const request = objectStore.put(record, id);

            request.onsuccess = function () {
                Debug.debug('indexed-db', 'The blob has been successfully stored.');

                resolve();
            };

            request.onerror = function () {
                Debug.error('indexed-db', 'Failed to store the blob.', {error: request.error});

                reject(request.error);
            };
        } catch (error) {
            Debug.error('indexed-db', 'Failed to store the blob.', {error});

            reject(error);
        }
    });
};

const storeFailedUpload = function ({taskId, taskVariationId, mixingPlan, files, date, isAudioWrapped, syncOut}) {
    return new Promise((resolve, reject) => {
        try {
            if (moment.utc(date).isBefore(moment.utc())) {
                resolve();
                return;
            }

            const objectStore = database.transaction('failedUploads', 'readwrite').objectStore('failedUploads');

            const record = {
                taskId,
                taskVariationId,
                files,
                expirationDate: date.toISOString(),
                isAudioWrapped,
                mixingPlan,
            };

            if (isAudioWrapped) {
                record.syncOut = syncOut;
            }

            const request = objectStore.put(record, taskVariationId);

            request.onsuccess = function () {
                Debug.debug('indexed-db', 'The failed upload has been successfully stored.');

                resolve();
            };

            request.onerror = function () {
                Debug.error('indexed-db', 'Failed to store the failed upload.', {error: request.error});

                reject(request.error);
            };
        } catch (error) {
            Debug.error('indexed-db', 'Failed to store the failed upload.', {error});

            reject(error);
        }
    });
};

const getFailedUpload = function (taskVariationId) {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readonly').objectStore('failedUploads');
            const request = objectStore.get(taskVariationId);

            request.onsuccess = () => {
                if (request.result) {
                    resolve(request.result);
                }

                resolve(null);
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

const getHasFailedUploads = function () {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readonly').objectStore('failedUploads');
            const request = objectStore.getAllKeys();

            request.onsuccess = () => {
                if (request.result && request.result.length) {
                    resolve(true);
                }

                resolve(false);
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

const getAllFailedUploads = function () {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readonly').objectStore('failedUploads');
            const request = objectStore.getAll();

            request.onsuccess = () => {
                if (request.result) {
                    resolve(request.result);
                }

                resolve([]);
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

const deleteFailedUpload = taskVariationId => {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readwrite').objectStore('failedUploads');

            const request = objectStore.delete(taskVariationId);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

const purgeExpiredObjects = function () {
    return new Promise((resolve, reject) => {
        // Purge expired audio objects
        const audioObjectStore = database.transaction('audio', 'readwrite').objectStore('audio');
        const audioRequest = audioObjectStore.getAll();
        const failedUploadsObjectStore = database.transaction('failedUploads', 'readwrite').objectStore('failedUploads');
        const failedUploadsRequest = failedUploadsObjectStore.getAll();

        audioRequest.onsuccess = () => {
            const audios = audioRequest.result;

            audios.forEach(audio => {
                if (moment(audio.validUntil).isBefore(moment().subtract(24, 'hours'))) {
                    const request = audioObjectStore.delete(audio.id);

                    Debug.debug('indexed-db', `Deleted an audio with id: ${audio.id}. Reason: The audio has expired.`);

                    request.onerror = () => {
                        Debug.error('indexed-db', 'Failed to delete the audio.', {error: request.error});

                        reject(request.error);
                    };
                }
            });

            resolve();
        };

        audioRequest.onerror = error => {
            Debug.error('indexed-db', 'Failed to get audio objects for deletion.', {error});

            reject(error);
        };

        failedUploadsRequest.onsuccess = () => {
            const failedUploads = failedUploadsRequest.result;

            failedUploads.forEach(failedUpload => {
                if (moment.utc(failedUpload.expirationDate).isBefore(moment.utc())) {
                    const request = failedUploadsObjectStore.delete(failedUpload.taskVariationId);

                    // eslint-disable-next-line no-console
                    console.warn(`Deleted a failed upload with id: ${failedUpload.taskVariationId}. Reason: The failed upload has expired.`);

                    request.onerror = () => {
                        Debug.error('indexed-db', 'Failed to delete the failed upload.', {error: request.error});

                        reject(request.error);
                    };
                }
            });

            resolve();
        };

        failedUploadsRequest.onerror = error => {
            Debug.error('indexed-db', 'Failed to get failed uploads for deletion.', {error});

            reject(error);
        };
    });
};

const getAudioBlob = function (id) {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('audio', 'readonly').objectStore('audio');

            const request = objectStore.get(id);

            request.onsuccess = () => {
                if (request.result) {
                    resolve(request.result.blob);
                }

                resolve(null);
            };

            request.onerror = () => {
                // eslint-disable-next-line no-console
                console.error(request.error);
                reject(request.error);
            };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            reject(error);
        }
    });
};

const deleteExpiredFailedUploads = () => {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readwrite').objectStore('failedUploads');
            const request = objectStore.getAll();

            request.onsuccess = () => {
                const failedUploads = request.result;

                failedUploads.forEach(failedUpload => {
                    if (moment.utc(failedUpload.expirationDate).isBefore(moment.utc())) {
                        const request = objectStore.delete(failedUpload.taskVariationId);

                        request.onerror = () => {
                            reject(request.error);
                        };
                    }
                });

                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

const deleteAllFailedUploads = () => {
    return new Promise((resolve, reject) => {
        try {
            const objectStore = database.transaction('failedUploads', 'readwrite').objectStore('failedUploads');
            const request = objectStore.getAll();

            request.onsuccess = () => {
                const failedUploads = request.result;

                failedUploads.forEach(failedUpload => {
                    const request = objectStore.delete(failedUpload.taskVariationId);

                    request.onerror = () => {
                        reject(request.error);
                    };
                });

                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
};

export const IndexedDb = {
    initialize,
    storeAudio,
    storeFailedUpload,
    purgeExpiredObjects,
    getAudioBlob,
    getFailedUpload,
    deleteExpiredFailedUploads,
    deleteFailedUpload,
    deleteAllFailedUploads,
    getHasFailedUploads,
    getAllFailedUploads,
};
