export const transformDtoEntities = (list, Entity) => {
    const entities = {};
    let ids = [];

    list.forEach(dto => {
        // TODO: Implement fromDto to all entities
        const entity = Entity.hasOwnProperty('fromDto') ? Entity.fromDto(dto) : new Entity(dto);

        ids = [...ids, entity.id];

        entities[entity.id] = entity;
    });

    return {entities, ids};
};
