import SkippedDuration from '../SkippedDuration';
import VolumeChangePoint from '../VolumeChangePoint';

class BaseAudioNode {
    /**
     * @param {Blob} blob
     * @param {AudioContext} audioContext
     * @param {string} id
     * @param {MediaStreamAudioDestinationNode} destination
     * @returns {Promise<void>}
     */
    async init(blob, audioContext, id, destination) {
        const startTime = window.performance.now();

        this.#audioContext = audioContext;

        this.#sourceNode = this.#audioContext.createBufferSource();

        this.#gainNode = this.#audioContext.createGain();

        const arrayBuffer = await blob.arrayBuffer();
        const audioBuffer = await this.#audioContext.decodeAudioData(arrayBuffer);
        this.#duration = audioBuffer.duration;
        this.#sourceNode.buffer = audioBuffer;

        this.#sourceNode.connect(this.#gainNode);
        this.#gainNode.connect(destination || this.#audioContext.destination);

        const endTime = window.performance.now();

        // eslint-disable-next-line no-console
        console.table({
            id,
            startTime,
            endTime,
            duration: Math.round(((endTime - startTime) + Number.EPSILON) * 100) / 100,
        });
    }

    /**
     * @type {Array<number>}
     * @private
     */
    #startedAt = [];

    /**
     * @type {Array<number>}
     * @private
     */
    #stoppedAt = [];

    /**
     * @type {Array<number>}
     * @private
     */
    #volumes = [];

    /**
     * @type {AudioBufferSourceNode}
     * @private
     */
    #sourceNode = null;

    /**
     * @type {GainNode}
     * @private
     */
    #gainNode = null;

    /**
     * @type {boolean}
     * @private
     */
    #isPlaying = false;

    /**
     * @type {AudioContext}
     * @private
     */
    #audioContext = null;

    /**
     * @type {number}
     * @private
     */
    #duration = 0;

    /**
     * @type {number}
     * @private
     */
    #offset = 0;

    /**
     * @type {number}
     * @private
     */
    #volume = 1;

    /**
     * @type {VolumeChangePoint[]}
     * @private
     */
    #volumeChangePoints = [];

    /**
     * @type {DDSItemRecord}
     */
    #audioObject;

    /**
     * @type {Array<SkippedDuration>}
     */
    #skippedDurations = [];

    /**
     * @type {boolean}
     */
    #didPlayOnStart = false;

    /**
     * @type {boolean}
     */
    #isVolumeTransitioning = false;

    /**
     * @return {Array<number>}
     */
    get startedAt() {
        return this.#startedAt;
    }

    /**
     * @return {Array<number>}
     */
    get volumes() {
        return this.#volumes;
    }

    /**
     * @return {Array<number>}
     */
    get stoppedAt() {
        return this.#stoppedAt;
    }

    /**
     * @return {AudioBufferSourceNode}
     */
    get sourceNode() {
        return this.#sourceNode;
    }

    /**
     * @param {AudioBufferSourceNode} sourceNode
     */
    set sourceNode(sourceNode) {
        this.#sourceNode = sourceNode;
    }

    /**
     * @return {GainNode}
     */
    get gainNode() {
        return this.#gainNode;
    }

    /**
     * @param {boolean} isPlaying
     */
    set isPlaying(isPlaying) {
        this.#isPlaying = isPlaying;
    }

    /**
     * @return {boolean}
     */
    get isPlaying() {
        return this.#isPlaying;
    }

    /**
     * @return {AudioContext}
     */
    get audioContext() {
        return this.#audioContext;
    }

    /**
     * @return {number}
     */
    get duration() {
        return this.#duration;
    }

    /**
     * @param {number} duration
     */
    set duration(duration) {
        this.#duration = duration;
    }

    /**
     * @return {number}
     */
    get offset() {
        return this.#offset;
    }

    /**
     * @param {number} offset
     */
    set offset(offset) {
        this.#offset = offset;
    }

    /**
     * @return {number}
     */
    get volume() {
        return this.#volume;
    }

    /**
     * @param {number} volume
     */
    set volume(volume) {
        this.#volume = volume;

        this.#gainNode.gain.setValueAtTime(volume, this.#audioContext.currentTime);
    }

    /**
     * @return {VolumeChangePoint[]}
     */
    get volumeChangePoints() {
        return this.#volumeChangePoints;
    }

    /**
     * @returns {DDSItemRecord}
     */
    get audioObject() {
        return this.#audioObject;
    }

    /**
     * @param {DDSItemRecord} audioObject
     */
    set audioObject(audioObject) {
        this.#audioObject = audioObject;
    }

    /**
     * @returns {boolean}
     */
    get didPlayOnStart() {
        return this.#didPlayOnStart;
    }

    /**
     * @param {boolean} didPlayOnStart
     */
    set didPlayOnStart(didPlayOnStart) {
        this.#didPlayOnStart = didPlayOnStart;
    }

    /**
     * @returns {boolean}
     */
    get isVolumeTransitioning() {
        return this.#isVolumeTransitioning;
    }

    /**
     * @param {number} [startAt=0] startAt
     */
    play(startAt = 0) {
        setTimeout(() => {
            this.#isPlaying = true;
        }, startAt * 1000);

        this.#startedAt.push(this.#audioContext.currentTime + startAt);

        this.#sourceNode.start(this.#audioContext.currentTime + startAt, this.#offset);
    }

    /**
     * @param {number} [stopAt=0] stopAt
     */
    stop(stopAt = 0) {
        this.volumes.push(this.volume);

        if (stopAt > 0) {
            setTimeout(() => {
                this.#isPlaying = false;

                this.#stoppedAt.push(this.#audioContext.currentTime);
            }, (stopAt - this.#audioContext.currentTime) * 1000);
        } else {
            this.#isPlaying = false;

            this.#stoppedAt.push(this.#audioContext.currentTime);
        }

        this.#sourceNode.stop(stopAt);
    }

    /**
     * @return {boolean}
     */
    didEnd() {
        return !!this.#stoppedAt.length;
    }

    /**
     * @return {number}
     */
    lastStartedAt() {
        return this.#startedAt[this.#startedAt.length - 1];
    }

    /**
     * @param {number} duration
     * @param {number} toVolume
     * @param {boolean} shouldStopOnEnd
     */
    volumeTransition(duration, toVolume, shouldStopOnEnd = false) {
        this.#isVolumeTransitioning = true;

        const startAt = this.#audioContext.currentTime;
        const endAt = this.#audioContext.currentTime + duration;
        const fromVolume = this.#volume;

        this.#gainNode.gain.setValueAtTime(fromVolume, startAt);
        this.#gainNode.gain.linearRampToValueAtTime(toVolume, endAt);

        setTimeout(() => {
            this.#volume = toVolume;

            this.#isVolumeTransitioning = false;
        }, duration * 1000);

        this.#volumeChangePoints.push(new VolumeChangePoint(startAt, endAt, fromVolume, toVolume));

        if (shouldStopOnEnd) {
            this.stop(endAt);
        }
    }

    /**
     * @return {number}
     */
    getPassedTime() {
        if (this.didEnd()) {
            return this.#duration;
        }

        if (!this.#isPlaying) {
            return this.#offset;
        }

        return this.#audioContext.currentTime - this.lastStartedAt() + this.#offset;
    }

    /**
     * @param {number} [duration=0] duration
     * @param {SkippedDuration.TYPES} [type=moveStartPosition] type
     */
    addSkippedDuration(duration = 0, type = SkippedDuration.TYPES.MOVE_START_POSITION) {
        let startTimeIndex = this.#startedAt.length - 1;

        if (startTimeIndex < 0) {
            startTimeIndex = 0;
        }

        if (type === SkippedDuration.TYPES.EXTEND_DURATION) {
            if (this.getPassedTime() + duration > this.#duration) {
                return;
            }
        }

        this.#skippedDurations.push(new SkippedDuration(duration, type, startTimeIndex));
    }

    /**
     *
     * @param {number} startTimeIndex
     * @param {SkippedDuration.TYPES} type
     * @returns {SkippedDuration}
     */
    findSkippedDuration(startTimeIndex, type) {
        return this.#skippedDurations.find(skippedDuration => {
            return skippedDuration.startTimeIndex === startTimeIndex && skippedDuration.type === type;
        });
    }

    /**
     * @param {number} startTimeIndex
     * @returns {number}
     */
    getStartOffsetBasedOnSkippedDurations(startTimeIndex) {
        let startOffset = 0;

        const skippedDuration = this.findSkippedDuration(startTimeIndex, SkippedDuration.TYPES.MOVE_START_POSITION);

        if (skippedDuration) {
            startOffset = skippedDuration.duration;
        }

        return startOffset;
    }

    /**
     * @param {number} startTimeIndex
     * @returns {number}
     */
    getExtendingOffsetBasedOnSkippedDurations(startTimeIndex) {
        let startOffset = 0;

        const skippedDuration = this.findSkippedDuration(startTimeIndex, SkippedDuration.TYPES.EXTEND_DURATION);

        if (skippedDuration) {
            startOffset = skippedDuration.duration;
        }

        return startOffset;
    }

    calculatePlayingTime() {
        if (this.audioContext && this.audioContext.currentTime && this.lastStartedAt()) {
            return this.audioContext.currentTime - this.lastStartedAt() + this.offset;
        }

        return 0;
    }

    /**
     * @param {number} volume
     */
    reconnect(volume) {
        this.#volume = volume;

        this.#gainNode.gain.setValueAtTime(volume, this.#audioContext.currentTime);

        const buffer = this.#sourceNode.buffer;

        this.#sourceNode.stop();

        this.#sourceNode.disconnect(this.#gainNode);

        this.#sourceNode = this.#audioContext.createBufferSource();
        this.#sourceNode.buffer = buffer;

        this.#sourceNode.connect(this.#gainNode);
    }
}


export default BaseAudioNode;
