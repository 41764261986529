import {all, call, delay, put, race, select, take, takeLatest} from 'redux-saga/effects';
import {TaskOverviewActions} from './task-overview.action';
import {TaskOverviewActionTypes} from './task-overview.action-type';
import {handleSagaError} from '../../../error.saga';
import {Debug} from '../../../lib/debug';
import {AuthActionTypes} from '../../auth/store/auth.action-type';
import {FilterActions, FilterSelectors} from '../../home-filters';
import {HomeViews} from '../../home-filters/home-filters.consts';
import {UserActionTypes} from '../../user/store/user.action-type';
import {TaskOverviewProvider} from '../api/task-overview.provider';

const getTaskOverviewWorker = function* () {
    yield call(getTaskOverview);
};

export const getTaskOverview = function* () {
    try {
        const result = yield call(TaskOverviewProvider.getTaskOverview);

        // Before storing new entities and ids, check if the user is on a playlist view
        // If the user is on playlist view, and the brand/channel/date combination is now gone
        // We need to get the user back to task overview
        // Or handle this in a different way?
        const selectedBrand = yield select(FilterSelectors.createFilterFieldValueSelector('brand'));
        const selectedChannel = yield select(FilterSelectors.createFilterFieldValueSelector('channel'));
        const selectedDate = yield select(FilterSelectors.createFilterFieldValueSelector('startDate'));
        const activeView = yield select(FilterSelectors.createFilterFieldValueSelector('activeView'));

        if (activeView === HomeViews.PLAYLIST) {
            let isRemoved = false;

            if (!result.entities.brands[selectedBrand]) {
                isRemoved = true;
            }

            if (!result.entities.channels[selectedChannel]) {
                isRemoved = true;
            }

            if (!result.entities.dates[`${selectedChannel}/${selectedDate}`]) {
                isRemoved = true;
            }

            if (isRemoved) {
                yield put(FilterActions.setFieldValue('activeView', HomeViews.OVERVIEW));

                yield delay(500);
            }
        }

        yield all([
            put(TaskOverviewActions.storeEntities(result.entities)),
            put(TaskOverviewActions.storeIds(result.result)),
        ]);
    } catch (error) {
        yield call(handleSagaError, error, 'getTaskOverview');
    }
};

export const refreshTaskOverview = function* () {
    let initialRun = true;

    while (true) {
        if (!initialRun) {
            Debug.debug('overview.saga', `Refreshing...`);
            yield call(getTaskOverview);
        }

        const now = new Date();
        const passedSeconds = now.getSeconds();
        const passedMilliseconds = now.getMilliseconds();

        // Refresh on every hh:mm:00
        const millisecondsToNextMinute = (60 - passedSeconds) * 1000 - passedMilliseconds;

        Debug.debug('task-overview.saga', `Waiting for ${millisecondsToNextMinute} milliseconds to refresh.`);

        const {shouldStop} = yield race({
            shouldContinue: delay(millisecondsToNextMinute),
            shouldStop: take(AuthActionTypes.LOG_OUT),
        });

        if (shouldStop) {
            yield take(UserActionTypes.STORE_CURRENT_USER);

            initialRun = true;

            continue;
        }

        initialRun = false;
    }
};

export const taskOverviewSaga = function* () {
    yield all([
        takeLatest(TaskOverviewActionTypes.GET_TASK_OVERVIEW, getTaskOverviewWorker),
    ]);
};
