import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {memo} from 'react';
import {useDispatch} from 'react-redux';
import classes from './GenericTask.module.scss';
import {GenericTaskVariation} from './GenericTaskVariation';
import {IconButton, Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {ChevronDown} from '../../../icons';
import {GenericTaskActions} from '../store/generic-task.action';
import {GenericTaskSelectors} from '../store/generic-task.selector';
import {formatRestrictions} from '../utils/format-restrictions';

export const GenericTask = memo(({id}) => {
    const dispatch = useDispatch();

    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        id,
    );

    const isExpanded = genericTask.isExpanded;

    const brandName = genericTask?.brand?.name;
    const channelName = genericTask?.channel?.name;
    const weekday = genericTask?.weekday;
    const dayPart = genericTask?.dayPart;

    return (
        <div
            className={clsx(classes.root, {
                [classes.isExpanded]: isExpanded,
            })}
        >
            <header className={classes.header}>
                <div className={classes.headerContent}>
                    <Typography weight={500} size={14} color="white" hasGutters={false} hasLineHeight={false}>
                        {genericTask.title}
                    </Typography>

                    <Typography weight={400} size={13} color="white" hasGutters={false} hasLineHeight={false}>
                        {formatRestrictions(brandName, channelName, weekday, dayPart)}
                    </Typography>
                </div>

                <div className={classes.headerContentRight}>
                    <Typography weight={400} size={13} color="white" hasGutters={false} hasLineHeight={false}>
                        {genericTask.formattedValidUntil}
                    </Typography>

                    <div
                        className={clsx(classes.counter, {
                            [classes.noPendingVariations]: genericTask.pendingVariationsCount === 0,
                        })}
                    >
                        <Typography weight={500} size={14} color="white" hasGutters={false} hasLineHeight={false}>
                            {genericTask.pendingVariationsCount === 0 ? '-' : genericTask.pendingVariationsCount}
                        </Typography>
                    </div>

                    <IconButton
                        backgroundColor="transparent"
                        color="white"
                        iconSize={14}
                        hoverStyle="color-opacity"
                        onClick={() => {
                            dispatch(GenericTaskActions.setGenericTaskExpansion({
                                genericTaskId: id,
                                isExpanded: !isExpanded,
                            }));
                        }}
                    >
                        <ChevronDown />
                    </IconButton>
                </div>
            </header>

            <Collapse in={isExpanded} unmountOnExit>
                <ul className={classes.list}>
                    {genericTask.genericTaskVariationIds.map(id => {
                        return (
                            <li key={id}>
                                <GenericTaskVariation id={id} />
                            </li>
                        );
                    })}
                </ul>
            </Collapse>
        </div>
    );
});

GenericTask.propTypes = {
    id: PropTypes.number.isRequired,
};
