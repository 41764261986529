import {createSelector} from 'reselect';

const selectCurrentTaskId = state => state.currentTask.currentTaskId;

const selectCurrentTaskVariationId = state => state.currentTask.currentTaskVariationId;

const selectCurrentPlaylistTaskKey = state => state.currentTask.currentPlaylistTaskKey;

const selectLoadedElements = state => state.currentTask.loadedElements;

const selectPreProducedVolume = state => state.currentTask.preProducedVolume;

const selectSfxVolume = state => state.currentTask.sfxVolume;

const selectBedVolume = state => state.currentTask.bedVolume;

const selectArePreProducedElementsMuted = state => state.currentTask.arePreProducedElementsMuted;

const selectAreSfxElementsMuted = state => state.currentTask.areSfxElementsMuted;

const selectAreBedElementsMuted = state => state.currentTask.areBedElementsMuted;

const selectSpeakerText = state => state.currentTask.speakerText;

const selectIsFreeModeEnabled = state => state.currentTask.isFreeModeEnabled;

const selectFreeModeOriginalTaskVariationId = state => state.currentTask.freeModeOriginalTaskVariationId;

const createLoadedElementsByTypeSelector = type => createSelector(
    [selectLoadedElements],
    loadedElements => loadedElements.filter(element => element.elementType === type),
);

const createLoadedElementIdsByTypeSelector = type => createSelector(
    [selectLoadedElements],
    loadedElements => loadedElements.filter(element => element.elementType === type).map(element => element.id),
);

const createLoadedElementIdsSelector = createSelector(
    [selectLoadedElements],
    loadedElements => loadedElements.map(element => element.id),
);

const createLoadedElementByIdSelector = elementId => createSelector(
    [selectLoadedElements],
    loadedElements => loadedElements.find(element => element.id === elementId),
);

export const CurrentTaskSelectors = {
    selectCurrentTaskId,
    selectCurrentTaskVariationId,
    selectCurrentPlaylistTaskKey,
    selectLoadedElements,
    createLoadedElementsByTypeSelector,
    selectPreProducedVolume,
    selectSfxVolume,
    selectBedVolume,
    createLoadedElementIdsByTypeSelector,
    createLoadedElementIdsSelector,
    selectArePreProducedElementsMuted,
    selectAreSfxElementsMuted,
    selectAreBedElementsMuted,
    createLoadedElementByIdSelector,
    selectSpeakerText,
    selectIsFreeModeEnabled,
    selectFreeModeOriginalTaskVariationId,
};
