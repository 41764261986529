/**
 * @param {DataView} dataView
 * @param {number} offset
 * @param {string} string
 */
const writeUTFBytes = (dataView, offset, string) => {
    for (let i = 0; i < string.length; i += 1) {
        dataView.setUint8(offset + i, string.charCodeAt(i));
    }
};

export const encodeWav = (buffer, sampleRate) => {
    // Structure of a wav file, with a byte offset for the values to modify:
    // sample-rate, channel count, block align.
    const header = [
        // RIFF header
        0x52, 0x49, 0x46, 0x46, 0x00, 0x00, 0x00, 0x00, 0x57, 0x41, 0x56, 0x45,
        // fmt chunk. We always write 16-bit samples.
        0x66, 0x6d, 0x74, 0x20, 0x10, 0x00, 0x00, 0x00, 0x01, 0x00, 0xff, 0xff,
        0xff, 0xff, 0xff, 0xff, 0x00, 0x00, 0x00, 0x00, 0xff, 0xff, 0x10, 0x00,
        // data chunk
        0x64, 0x61, 0x74, 0x61, 0xfe, 0xff, 0xff, 0x7f,
    ];

    // Find final size: size of the header + number of samples * channel count
    // * 2 because pcm16
    let size = 0;

    for (let i = 0; i < buffer.length; i += 1) {
        size += buffer[i].length * 2;
    }

    const wav = new Uint8Array(44 + size);
    const view = new DataView(wav.buffer);

    // Copy the header, and modify the values: note that RIFF
    // is little-endian, we need to pass `true` as the last param.
    for (let i = 0; i < wav.length; i += 1) {
        wav[i] = header[i];
    }

    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, header.length + size, true);
    writeUTFBytes(view, 8, 'WAVE');
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 2, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);

    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, size, true);

    let writeIndex = header.length;

    for (let segment = 0; segment < buffer.length; segment += 1) {
        for (let sample = 0; sample < buffer[segment].length; sample += 1) {
            view.setInt16(writeIndex, buffer[segment][sample], true);

            writeIndex += 2;
        }
    }

    const blob = new Blob([view], {type: 'audio/wav'});

    if (window.hasOwnProperty('DOWNLOAD_WAV_FILES') && window.DOWNLOAD_WAV_FILES) {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = URL.createObjectURL(blob);
        a.download = `audio-${(new Date()).toISOString().replace(/[^0-9]/g, '')}.wav`;
        a.click();
    }

    return blob;
};
