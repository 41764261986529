import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './BlockProgramTab.module.scss';
import {TaskNumberIndicator} from '../TaskNumberIndicator/TaskNumberIndicator';

export const BlockProgramTab = ({count, total, label, isActive, onClick, hasUrgentTasks}) => {
    return (
        <button
            type="button"
            className={clsx(classes.root, {
                [classes.active]: isActive,
            })}
            onClick={onClick}
        >
            <span className={classes.name}>
                {label}
            </span>

            {!!total && (
                <TaskNumberIndicator
                    count={count}
                    size="small"
                    noTasksColor="green"
                    color={hasUrgentTasks ? 'orange' : 'default'}
                />
            )}
        </button>
    );
};

BlockProgramTab.propTypes = {
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    hasUrgentTasks: PropTypes.bool.isRequired,
};
