import {normalize} from 'normalizr';
import {BrandSchema} from './task-overview.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const getTaskOverview = () => {
    return ddsPlanningApi.get('/v1/task-overview').then(response => {
        const normalizedData = normalize(response.data, [BrandSchema]);

        normalizedData.result = normalizedData.result.sort((a, b) => {
            const nameA = normalizedData.entities.brands[a].name.toLowerCase();
            const nameB = normalizedData.entities.brands[b].name.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        if (!normalizedData.result.length) {
            normalizedData.entities = {
                brands: {},
                channels: {},
                dates: {},
            };
        }

        return normalizedData;
    });
};

export const TaskOverviewProvider = {
    getTaskOverview,
};
