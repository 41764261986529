import {useTranslation} from 'react-i18next';
import classes from './LoginScreen.module.scss';
import {PageHeader} from '../../../../components';
import {LoginForm} from '../LoginForm/LoginForm';

export const LoginScreen = function () {
    const {t} = useTranslation('featuresAuth');

    return (
        <div className={classes.root}>
            <PageHeader className={classes.header} subtitle={t('loginScreen.pageHeader.subtitle')} align="center">
                {t('loginScreen.pageHeader.title')}
            </PageHeader>

            <LoginForm />
        </div>
    );
};
