import {Debug} from '../../lib/debug';
import {AudioReader, RingBuffer} from '../../lib/ring-buffer';

export const RecordingNode = function (audioContext, stream) {
    this.audioContext = audioContext;
    this.stream = stream;
    this.callback = null;
    this.arrayBuffer = null;
    this.staging = null;
    this.node = null;
    this.worklet = null;
    this.reader = null;
    this.dequeueInterval = null;

    this.init = async callback => {
        try {
            this.callback = callback;

            const byteLength = 8 + (this.audioContext.sampleRate * 2 + 1) * Float32Array.BYTES_PER_ELEMENT;

            // eslint-disable-next-line no-undef
            this.arrayBuffer = new SharedArrayBuffer(byteLength);

            this.staging = new Float32Array(this.arrayBuffer.byteLength / 4 / 4 / 2);

            this.node = this.audioContext.createMediaStreamSource(this.stream);

            await this.audioContext.audioWorklet.addModule('/audio-recording-worklet.js');

            this.worklet = new AudioWorkletNode(this.audioContext, 'audio-recording-worklet', {
                numberOfInputs: 2,
                numberOfOutputs: 2,
                processorOptions: this.arrayBuffer,
            });

            this.reader = new AudioReader(new RingBuffer(this.arrayBuffer, Float32Array));

            this.node.connect(this.worklet);

            this.worklet.connect(this.audioContext.destination);

            this.dequeueInterval = setInterval(this.read, 100);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error});
        }
    };

    this.read = async () => {
        const samplesRead = this.reader.dequeue(this.staging);

        if (!samplesRead) {
            return 0;
        }

        const segment = new Int16Array(samplesRead);

        for (let i = 0; i < samplesRead; i += 1) {
            segment[i] = Math.min(Math.max(this.staging[i], -1.0), 1.0) * (2 << 14);
        }

        this.callback(segment);

        Debug.debug('RecordingNode', `Segment processed. Read: ${samplesRead}`);

        return samplesRead;
    };

    this.start = async function () {
        this.worklet.port.postMessage({
            eventType: 'START_RECORDING',
        });
    };

    this.stop = async function () {
        this.worklet.port.postMessage({
            eventType: 'STOP',
        });

        this.node.mediaStream.getAudioTracks().forEach(track => track.stop());

        clearInterval(this.dequeueInterval);

        while (await this.read()) {
            Debug.debug('RecordingNode', `Dequeueing...`);
        }

        Debug.debug('RecordingNode', `Dequeueing done!`);
    };
};
