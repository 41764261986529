import {all, call, delay, fork} from 'redux-saga/effects';
import {logTailService} from '../log-tail.service';

const flushLogs = function* () {
    while (true) {
        yield delay(3000);

        if (logTailService.synced !== logTailService.logged) {
            yield call([logTailService, logTailService.flush]);
        }
    }
};

export const logTailSagas = function* () {
    yield all([
        fork(flushLogs),
    ]);
};
