export const LoginFormActionTypes = {
    SUBMIT: 'auth/login-form/SUBMIT',
    SET_IS_LOADING: 'auth/login-form/SET_IS_LOADING',
};

export const NewPasswordFormActionTypes = {
    SUBMIT: 'auth/new-password-form/SUBMIT',
    SET_IS_LOADING: 'auth/new-password-form/SET_IS_LOADING',
    SUCCESS: 'auth/new-password-form/SUCCESS',
};

export const ResetPasswordFormActionTypes = {
    SUBMIT_SEND_CODE_FORM: 'auth/reset-password-form/SUBMIT_SEND_CODE_FORM',
    SUBMIT: 'auth/reset-password-form/SUBMIT',
    SET_IS_LOADING: 'auth/reset-password-form/SET_IS_LOADING',
    SET_STEP: 'auth/reset-password-form/SET_STEP',
    SET_EMAIL: 'auth/reset-password-form/SET_EMAIL',
};

export const AuthActionTypes = {
    LOG_OUT: 'auth/LOG_OUT',
};
