import {MixingPlan} from './mixing-plan.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const getMixingPlan = taskVariationId => {
    return ddsPlanningApi
        .get(`/v1/task-variation/${taskVariationId}/mixing-plan`)
        .then(response => MixingPlan.fromDto(response.data));
};

export const MixingPlanApi = {
    getMixingPlan,
};
