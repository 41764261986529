import clsx from 'clsx';
import PropTypes from 'prop-types';
import {memo, useLayoutEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classes from './GenericTaskVariation.module.scss';
import {IconButton, Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {
    ErrorIcon,
    HourglassTopIcon,
    MicrophoneIcon,
    PlayIcon,
    SuccessIcon,
    UploadingIcon,
} from '../../../icons';
import {PlayerActions} from '../../player';
import {UiActions} from '../../ui/store/ui.action';
import {UiSelectors} from '../../ui/store/ui.selector';
import {GenericTaskActions} from '../store/generic-task.action';
import {GenericTaskSelectors} from '../store/generic-task.selector';
import {GENERIC_TASK_VARIATION_STATUSES} from '../utils/generic-task.constants';

export const GenericTaskVariation = memo(({id}) => {
    const containerRef = useRef();
    const isScrollingRef = useRef(false);

    const dispatch = useDispatch();

    const genericTaskVariation = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskVariationByIdSelector,
        id,
    );
    const genericTask = useMemoizedCreateSelector(
        GenericTaskSelectors.createGenericTaskByIdSelector,
        genericTaskVariation.genericTaskId,
    );

    const selectedGenericTaskVariationId = useSelector(GenericTaskSelectors.selectSelectedGenericTaskVariationId);

    const isSelected = selectedGenericTaskVariationId === id;

    const shouldScrollTo = useMemoizedCreateSelector(UiSelectors.createScrollToElementSelector, id);

    const color = useMemo(() => {
        return {
            [GENERIC_TASK_VARIATION_STATUSES.PENDING]: 'cello',
            [GENERIC_TASK_VARIATION_STATUSES.COMPLETED]: 'paolo',
            [GENERIC_TASK_VARIATION_STATUSES.UPLOADING]: 'radiance',
            [GENERIC_TASK_VARIATION_STATUSES.FAILED]: 'monza',
        };
    }, []);

    const icons = useMemo(() => {
        return {
            [GENERIC_TASK_VARIATION_STATUSES.PENDING]: (
                <HourglassTopIcon color={color[GENERIC_TASK_VARIATION_STATUSES.PENDING]} size={24} />
            ),
            [GENERIC_TASK_VARIATION_STATUSES.COMPLETED]: (
                <SuccessIcon color={color[GENERIC_TASK_VARIATION_STATUSES.COMPLETED]} size={24} />
            ),
            [GENERIC_TASK_VARIATION_STATUSES.UPLOADING]: (
                <UploadingIcon color={color[GENERIC_TASK_VARIATION_STATUSES.UPLOADING]} size={24} />
            ),
            [GENERIC_TASK_VARIATION_STATUSES.FAILED]: (
                <ErrorIcon color={color[GENERIC_TASK_VARIATION_STATUSES.FAILED]} size={24} />
            ),
        };
    }, [color]);

    useLayoutEffect(() => {
        let firstTimeout = null;
        let secondTimeout = null;

        if (shouldScrollTo) {
            if (isScrollingRef.current) {
                return;
            }

            firstTimeout = setTimeout(() => {
                isScrollingRef.current = true;

                containerRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

                secondTimeout = setTimeout(() => {
                    isScrollingRef.current = false;

                    dispatch(UiActions.setScrollToElement(id, false));
                }, 225); // dispatch it at the end of the animation
            }, 225); // 225 to move it to the end of execution stack
        }

        return () => {
            if (firstTimeout) {
                clearTimeout(firstTimeout);
            }

            if (secondTimeout) {
                clearTimeout(secondTimeout);
            }
        };
        // eslint-disable-next-line
    }, [shouldScrollTo]);

    return (
        <div
            className={clsx(classes.root, classes[genericTaskVariation.status.toLowerCase()], {
                [classes.isSelected]: isSelected,
            })}
            ref={containerRef}
        >
            <div className={classes.content}>
                {icons[genericTaskVariation.status]}

                <Typography weight={500} size={14} color={color[genericTaskVariation.status]} hasGutters={false}>
                    {genericTaskVariation.title}
                </Typography>
            </div>

            <div className={classes.actions}>
                {genericTaskVariation.externalId ? (
                    <IconButton
                        backgroundColor="transparent"
                        color={color[genericTaskVariation.status]}
                        size={24}
                        iconSize={18}
                        hoverStyle="color-opacity"
                        onClick={() => {
                            dispatch(PlayerActions.loadItem(genericTaskVariation.externalId, `${genericTask.title} - ${genericTaskVariation.title}`));
                            dispatch(GenericTaskActions.setIsControllerOpen(false));
                        }}
                    >
                        <PlayIcon />
                    </IconButton>
                ) : <div />}

                <IconButton
                    backgroundColor="transparent"
                    color={color[genericTaskVariation.status]}
                    size={24}
                    iconSize={18}
                    hoverStyle="color-opacity"
                    onClick={() => {
                        dispatch(GenericTaskActions.setSelectedGenericTaskVariationId(id));
                        dispatch(GenericTaskActions.setIsControllerOpen(true));
                    }}
                >
                    <MicrophoneIcon />
                </IconButton>
            </div>
        </div>
    );
});

GenericTaskVariation.propTypes = {
    id: PropTypes.number.isRequired,
};
