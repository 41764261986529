import {USER_ROLES} from '../../../utils/user-roles';

/**
 * @param userDto
 * @constructor
 */
export const User = function User(userDto) {
    this.id = userDto.id;
    this.firstName = userDto.firstName;
    this.lastName = userDto.lastName;
    this.externalId = userDto.externalId;
    this.email = userDto.email;

    this.roles = userDto.roles;
};

User.fromDto = dto => {
    const id = dto.id;
    const firstName = dto.firstName;
    const lastName = dto.lastName;
    const externalId = dto.externalId;
    const email = dto.email;
    const roles = dto.roles;

    return new User({
        id,
        firstName,
        lastName,
        externalId,
        email,
        roles,
    });
};

/**
 * @returns {boolean}
 */
User.prototype.isAdministrator = function isAdministrator() {
    return !!this.roles.find(role => role.id === USER_ROLES.ADMINISTRATOR);
};

/**
 * @returns {string}
 */
User.prototype.getName = function isAdministrator() {
    return `${this.firstName} ${this.lastName}`;
};
