import {SvgIcon} from '../SvgIcon/SvgIcon';

export const UploadCompletedIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path d="m11 0 5.4 5.4v10.8a1.8 1.8 0 0 1-1.8 1.8H3.8A1.8 1.8 0 0 1 2 16.2V9.197a5.96 5.96 0 0 0 1.8.683v6.32h10.8V6.3h-4.057A5.982 5.982 0 0 0 11 4a5.978 5.978 0 0 0-1.528-4H11zM9.2 9l3.6 3.6h-2.25v2.7h-2.7v-2.7H5.6L9.2 9z" />
            <path fill="#00BC3F" d="M3.3 8 0 4.59l1.392-1.317L3.3 5.08 7.608 1 9 2.602z" />
        </SvgIcon>
    );
};
