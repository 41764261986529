import {forwardRef} from 'react';
import classes from './Moderation.module.scss';
import {Divider} from '../../../../components';
import {SpeakerText} from '../SpeakerText/SpeakerText';
import {Variations} from '../Variations/Variations';
import {VoiceInfo} from '../VoiceInfo/VoiceInfo';

export const Moderation = forwardRef((props, ref) => {
    return (
        <div className={classes.root} ref={ref}>
            <div className={classes.topBar}>
                <Variations />

                <div id="edit-menu" />
            </div>

            <div className={classes.content}>
                {/* <PreProducedElements /> */}

                <div className={classes.speakerInfoWrapper}>
                    <VoiceInfo />

                    <Divider />

                    <div className={classes.speakerTextWrapper}>
                        <SpeakerText />
                    </div>
                </div>
            </div>
        </div>
    );
});
