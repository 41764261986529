import {SUBSCRIPTION_TYPES} from '../../constants';

const SUBSCRIPTION_TYPES_EXCLUDED_FROM_CASHING = [
    SUBSCRIPTION_TYPES.PLAYBACK_DATA,
    SUBSCRIPTION_TYPES.SELECTED_NODE_DATA,
];

class UpdateSubscriber {
    /**
     * @type {Number}
     */
    #id;

    /**
     * @enum {SUBSCRIPTION_TYPES}
     */
    #subscriptionType;

    /**
     * @type {function}
     */
    #callback;

    #publisherInstance;

    #cache;

    #options;

    /**
     * @param {Number} id
     * @param {SUBSCRIPTION_TYPES} subscriptionType
     * @param {function} callback
     * @param publisherInstance
     * @param {object} callback
     */
    constructor(id, subscriptionType, callback, publisherInstance, options) {
        this.#id = id;
        this.#subscriptionType = subscriptionType;
        this.#callback = callback;
        this.#publisherInstance = publisherInstance;
        this.#options = options;
    }

    /**
     * @returns {Number}
     */
    get id() {
        return this.#id;
    }

    /**
     * @returns {*}
     */
    get subscriptionType() {
        return this.#subscriptionType;
    }

    notify() {
        const data = this.#publisherInstance.getSubscriptionData(this.#subscriptionType, this.#options);

        if (!SUBSCRIPTION_TYPES_EXCLUDED_FROM_CASHING.includes(this.#subscriptionType)) {
            if (this.#cache && JSON.stringify(this.#cache) === JSON.stringify(data)) {
                return;
            }

            this.#cache = data;
        }

        this.#callback(data);
    }

    unsubscribe() {
        this.#publisherInstance.unsubscribe(this);
    }
}

export default UpdateSubscriber;
