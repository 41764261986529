import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CurrentTaskActions} from './current-task.action';
import {CurrentTaskActionTypes} from './current-task.action-type';
import {CurrentTaskSelectors} from './current-task.selector';
import {handleSagaError} from '../../../error.saga';
import {toastInstance} from '../../../lib/toast';
import {AudioItemApi} from '../../audio-item/api/audio-item.api';
import {AudioItemActions} from '../../audio-item/store/audio-item.action';
import {AudioItemSelectors} from '../../audio-item/store/audio-item.selector';
import {LOADING_TYPES, LoadingActions} from '../../loading';
import {PreparedTaskVariationApi} from '../../prepared-task-variation/api/prepared-task-variation.api';

const loadElementWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEM, true));

        const {elementId} = payload;

        const element = yield select(AudioItemSelectors.createByIdSelector(elementId));
        const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);

        if (loadedElements.find(element => element.id === elementId)) {
            toastInstance.error('featuresCurrentTask:notifications.elementAlreadyLoaded');
            return;
        }

        const data = yield call(AudioItemApi.getAudioItem, {
            itemId: element.id,
            elementType: element.elementType,
        });

        yield put(CurrentTaskActions.setLoadedElements([
            ...loadedElements,
            data,
        ], 'user'));
    } catch (error) {
        yield call(handleSagaError, error, 'loadElementWorker');
    } finally {
        yield put(LoadingActions.setIsLoading(LOADING_TYPES.AUDIO_ITEM, false));
    }
};

const getPreparedTaskVariationFlow = function* ({taskVariationId}) {
    try {
        const preparedTaskVariation = yield call(PreparedTaskVariationApi.getPreparedTaskVariation, {taskVariationId});

        const audioObjectRequests = [];

        preparedTaskVariation.preProducedIds.forEach(itemId => {
            audioObjectRequests.push(call(AudioItemApi.getAudioItem, {itemId, elementType: 'preProduced'}));
        });

        preparedTaskVariation.sfxIds.forEach(itemId => {
            audioObjectRequests.push(call(AudioItemApi.getAudioItem, {itemId, elementType: 'sfx'}));
        });

        preparedTaskVariation.bedIds.forEach(itemId => {
            audioObjectRequests.push(call(AudioItemApi.getAudioItem, {itemId, elementType: 'bed'}));
        });

        const items = yield all(audioObjectRequests);

        const entities = yield select(AudioItemSelectors.selectEntities);

        const effects = [
            put(CurrentTaskActions.setSpeakerText(preparedTaskVariation.speakerText)),

            /**
             * TODO
             * Eventually, I should save the IDs only to the loaded elements, and keep the source of truth in
             * the entities reducer of audio-item module. This was a mistake and should be fixed.
             * For now, it's not a problem because we never update these items. We just read them.
             */
            put(CurrentTaskActions.setLoadedElements(items)),
            put(AudioItemActions.storeEntities({
                ...entities,
                ...items.reduce((accumulator, current) => {
                    accumulator[current.id] = current;

                    return accumulator;
                }, {}),
            })),
        ];

        if (preparedTaskVariation.bedVolume) {
            effects.push(put(CurrentTaskActions.setBedVolume(preparedTaskVariation.bedVolume)));
        }

        if (preparedTaskVariation.preProducedVolume) {
            effects.push(put(CurrentTaskActions.setPreProducedVolume(preparedTaskVariation.preProducedVolume)));
        }

        if (preparedTaskVariation.sfxVolume) {
            effects.push(put(CurrentTaskActions.setSfxVolume(preparedTaskVariation.sfxVolume)));
        }

        yield all(effects);
    } catch (error) {
        yield call(handleSagaError, error, 'getPreparedTaskVariationFlow');
    }
};

const clearLoadedElementsWorker = function* ({payload}) {
    const {elementType} = payload;

    const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);
    const newLoadedElements = loadedElements.filter(element => element.elementType !== elementType);

    yield put(CurrentTaskActions.setLoadedElements(newLoadedElements, 'user'));
};

const removeLoadedElementWorker = function* ({payload}) {
    const {elementId, elementType} = payload;

    const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);
    const newLoadedElements = loadedElements.filter(element => {
        if (element.elementType === elementType) {
            return element.id !== elementId;
        }

        return true;
    });

    yield put(CurrentTaskActions.setLoadedElements(newLoadedElements, 'user'));
};

const setOrderOfLoadedElementsWorker = function* ({payload}) {
    const {elements, elementType} = payload;

    const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);
    const newLoadedElements = loadedElements.filter(element => element.elementType !== elementType);

    yield put(CurrentTaskActions.setLoadedElements([
        ...newLoadedElements,
        ...elements,
    ], 'user'));
};

export const loadPreparedTaskVariation = function* ({taskVariationId}) {
    yield call(getPreparedTaskVariationFlow, {taskVariationId});
};

export const clearLoadedElements = function* ({elementType}) {
    const loadedElements = yield select(CurrentTaskSelectors.selectLoadedElements);
    const newLoadedElements = loadedElements.filter(element => element.elementType !== elementType);

    yield put(CurrentTaskActions.setLoadedElements(newLoadedElements, 'app'));
};

export const currentTaskSaga = function* () {
    yield all([
        takeEvery(CurrentTaskActionTypes.LOAD_ELEMENT, loadElementWorker),
        takeEvery(CurrentTaskActionTypes.CLEAR_LOADED_ELEMENTS, clearLoadedElementsWorker),
        takeEvery(CurrentTaskActionTypes.REMOVE_LOADED_ELEMENT, removeLoadedElementWorker),
        takeEvery(CurrentTaskActionTypes.SET_ORDER_OF_LOADED_ELEMENTS, setOrderOfLoadedElementsWorker),
    ]);
};
