import {createSelector} from 'reselect';

const selectFilterState = state => state.homeFilters;

const createFilterFieldValueSelector = fieldKey => createSelector(
    [FilterSelectors.selectFilterState],
    filterState => filterState[fieldKey],
);

export const FilterSelectors = {
    selectFilterState,
    createFilterFieldValueSelector,
};
