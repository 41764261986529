export const isMatchingPlaylistTask = (searchTerm, task) => {
    if (!task.isCurrentUserTask) {
        return false;
    }

    return searchTerm && task.name.toLowerCase().includes(searchTerm.toLowerCase());
};

export const isMatchingListTask = (roleId, searchTerm, firstPlanningFilter, task) => {
    if (!task.isCurrentUserTask) {
        return false;
    }

    const roleMatch = roleId === 'NOT_SELECTED' || task.hostRole.id === roleId;
    const firstPlanningFilterMatch = firstPlanningFilter === 'NOT_SELECTED' || task.firstPlanningFilter === firstPlanningFilter;
    const searchMatch = !searchTerm || task.name.toLowerCase().includes(searchTerm.toLowerCase());

    return roleMatch && firstPlanningFilterMatch && searchMatch;
};
