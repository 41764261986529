import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import classes from './ProcessingTaskModal.module.scss';
import {PrimaryButton, Typography} from '../../../../components';
import {CurrentTaskSelectors} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {TaskSelectors} from '../../../../features/task';
import {useMemoizedCreateSelector} from '../../../../hooks';

export const ProcessingTaskModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const taskVariationId = useSelector(CurrentTaskSelectors.selectCurrentTaskVariationId);
    const taskVariation = useMemoizedCreateSelector(TaskSelectors.createTaskVariationByIdSelector, taskVariationId);
    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);
    const isProcessing = !isLoading && taskVariation.isProcessing;

    useEffect(() => {
        setIsOpen(isProcessing ?? false);
    }, [isProcessing, taskVariationId]);

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div>
                    <div className={classes.root}>
                        <Typography transform="uppercase" weight={500} color="regent" className={classes.title} variant="h1">
                            Processing...
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={false}>
                            The recorded audio is currently being processed.
                            You will be notified once the audio processing has been finished.
                        </Typography>

                        <div className={classes.actions}>
                            <PrimaryButton onClick={() => setIsOpen(false)}>
                                Close and continue
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
