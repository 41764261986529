import {format} from 'date-fns';
import {de} from 'date-fns/locale';
import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import classes from './DateContainer.module.scss';
import {IconButton, Typography} from '../../../components';
import {useMemoizedCreateSelector} from '../../../hooks';
import {ChevronRight} from '../../../icons';
import {FilterActions} from '../../home-filters';
import {HomeViews} from '../../home-filters/home-filters.consts';
import {TaskOverviewSelectors} from '../store/task-overview.selectors';

// eslint-disable-next-line react/prop-types
export const DateContainer = ({brandId, channelId, dateId}) => {
    const dispatch = useDispatch();

    const playlistDate = useMemoizedCreateSelector(TaskOverviewSelectors.createDateByIdSelector, dateId);
    const date = playlistDate.date;

    const formattedDay = useMemo(() => {
        return format(new Date(date), ' - EEEE', {locale: de});
    }, [date]);

    const formattedDate = useMemo(() => {
        return format(new Date(date), 'dd.MM.yyyy', {locale: de});
    }, [date]);

    return (
        <div className={classes.root}>
            <div>
                <Typography
                    size={13}
                    weight={500}
                    color={playlistDate.hasUrgentTasks ? 'ecstasy' : 'black'}
                    hasLineHeight={false}
                >
                    {formattedDate}
                </Typography>

                <Typography
                    size={13}
                    color={playlistDate.hasUrgentTasks ? 'ecstasy' : 'stone'}
                    hasLineHeight={false}
                >
                    {formattedDay}
                </Typography>
            </div>

            <div className={classes.right}>
                <Typography
                    size={13}
                    weight={500}
                    color={playlistDate.hasUrgentTasks ? 'ecstasy' : 'teal'}
                    hasLineHeight={false}
                >
                    {playlistDate.completedCount} / {playlistDate.count}
                </Typography>

                <IconButton
                    backgroundColor={playlistDate.hasUrgentTasks ? 'ecstasy' : 'teal'}
                    color="white"
                    size={22}
                    iconSize={12}
                    onClick={() => {
                        dispatch(FilterActions.setFieldValue('brand', brandId));
                        dispatch(FilterActions.setFieldValue('channel', channelId));
                        dispatch(FilterActions.setFieldValue('startDate', format(new Date(date), 'yyyy-MM-dd')));
                        dispatch(FilterActions.setFieldValue('activeView', HomeViews.PLAYLIST));
                    }}
                >
                    <ChevronRight size={14} />
                </IconButton>
            </div>
        </div>
    );
};
