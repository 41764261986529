const selectMainContentComponent = state => state.cockpit.mainContentComponent;

const selectIsEditingSpeakerText = state => state.cockpit.isEditingSpeakerText;

const selectAreControlsPopped = state => state.cockpit.areControlsPopped;

const selectAreElementsPopped = state => state.cockpit.areElementsPopped;

export const CockpitSelectors = {
    selectMainContentComponent,
    selectIsEditingSpeakerText,
    selectAreControlsPopped,
    selectAreElementsPopped,
};
