import {ddsPlanningApi} from '../../../lib/axios';
import {DdsFilter, DdsItem} from '../../playlist/api/playlist.dto';

const getAudioType = type => {
    switch (type) {
        case 'opus':
            return `audio/ogg; codecs="opus"`;

        case 'mp3':
            return 'audio/mpeg';

        case 'wav':
            return 'audio/wav';

        default:
            return 'audio/mpeg';
    }
};

export const getAudioSources = externalId => {
    return ddsPlanningApi
        .get(`/v1/dds-items/audiofiles/${externalId}`)
        .then(response => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                const sources = [];

                // Sort audio sources, so OPUS format has priority
                response.data.forEach(audioSource => {
                    if (audioSource.type === 'opus') {
                        sources[0] = {
                            url: audioSource.url,
                            type: getAudioType(audioSource.type),
                        };
                    }

                    if (audioSource.type === 'mp3') {
                        sources[1] = {
                            url: audioSource.url,
                            type: getAudioType(audioSource.type),
                        };
                    }

                    if (audioSource.type === 'wav') {
                        sources[2] = {
                            url: audioSource.url,
                            type: getAudioType(audioSource.type),
                        };
                    }
                });

                return sources;
            }

            return [];
        });
};

export const getDdsFilter = id => {
    return ddsPlanningApi
        .get(`/v1/dds-items/filter/${id}`)
        .then(response => new DdsFilter(response.data));
};

export const getDdsItemByFilter = (params, type) => {
    return ddsPlanningApi
        .get(`/v1/dds-items/autofetch`, {params})
        .then(response => {
            const {latest, scheduled} = response.data;

            if (type === 'scheduled' && scheduled) {
                return new DdsItem(scheduled);
            }

            if (latest) {
                return new DdsItem(latest);
            }

            return null;
        });
};
