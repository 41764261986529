import queryString from 'query-string';
import {push} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';
import {isSignedIn} from './auth.saga';
import {ROUTE_PATHS} from '../../../config/route-paths';
import {END_SIDE_EFFECTS_RUNNING} from '../../../router.saga';
import {reminderStorage} from '../../../utils/reminder-storage';
import {USER_ROLES} from '../../../utils/user-roles';
import {UserActions} from '../../user/store/user.action';
import {getCurrentUser, getSwitchingUser} from '../../user/store/user.saga';
import {userSwitcher} from '../utils/switch';

export const requireAuth = function* ({payload}) {
    const isLoggedIn = yield call(isSignedIn);

    if (!isLoggedIn) {
        yield put(push(ROUTE_PATHS.LOGIN));

        return END_SIDE_EFFECTS_RUNNING;
    }

    // TODO Check if the user is part of DDSPlanning group

    const user = yield call(getCurrentUser);
    yield put(UserActions.storeCurrentUser(user));

    yield call([reminderStorage, reminderStorage.initialize], user.id);

    yield call([userSwitcher, userSwitcher.setCanSwitch], !!user.roles.find(role => {
        return role.id === USER_ROLES.ADMINISTRATOR;
    }));

    const {location} = payload;
    const {search} = location;

    const query = queryString.parse(search);

    // eslint-disable-next-line no-underscore-dangle
    if (query._switch) {
        // eslint-disable-next-line no-underscore-dangle
        yield call([userSwitcher, userSwitcher.setEmail], query._switch);

        const switchingUser = yield call(getSwitchingUser);
        yield put(UserActions.storeSwitchingUser(switchingUser));
    }
};
