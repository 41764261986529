import {createSelector} from 'reselect';

const selectIds = state => state.audioItem.ids;

const selectEntities = state => state.audioItem.entities;

const createSelectOptionsSelector = () => createSelector(
    [selectIds, selectEntities],
    (ids, entities) => ids.map(id => ({
        id,
        name: entities[id].title,
    })),
);

const createByIdSelector = elementId => createSelector(
    [selectEntities],
    entities => entities[elementId],
);

const createByTypeSelector = type => createSelector(
    [selectIds, selectEntities],
    (ids, entities) => ids.map(id => entities[id]).filter(entity => entity.type === type),
);

export const AudioItemSelectors = {
    selectIds,
    selectEntities,
    createSelectOptionsSelector,
    createByIdSelector,
    createByTypeSelector,
};
