import {transformPlaylistData} from './playlist.dto';
import {ddsPlanningApi} from '../../../lib/axios';

/**
 * @param {number} channelId
 * @param {string} startDate
 * @param {array} failedUploadTaskIds
 * @param options
 * @returns {Promise<{
 *      showKeys,
 *      blockPrograms,
 *      splitPrograms,
 *      blockProgramItems,
 *      ddsFilters,
 *      shows,
 *      songs,
 *      tasks,
 *      ddsItems,
 *      splitProgramItems,
 *      entries,
 *      views,
 *      autoverpackungs,
 *      blocks,
 *      stats
 *  }>}
 */
export const getPlaylistRequest = (channelId, startDate, failedUploadTaskIds, options = {}) => {
    return ddsPlanningApi
        .get(`/v1/channels/${channelId}/playlist-for-tasks/${startDate}`, {
            params: {
                showTaskCount: 1,
                isPersonalized: 1,
            },
            ...options,
        })
        .then(response => transformPlaylistData(response.data, startDate, failedUploadTaskIds));
};

export const updatePlayingTypeRequest = (playlistItemId, playingType) => {
    return ddsPlanningApi
        .put(`/v1/playlist-item/${playlistItemId}/playing-type`, {
            playingType,
        })
        .then(response => response.data);
};
