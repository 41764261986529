import Collapse from '@mui/material/Collapse';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import classes from './Validity.module.scss';
import {
    Checkbox,
    DatePicker,
    IconButton,
    InputGroup, LoadingWithOverlay,
    PrimaryButton,
    SecondaryButton,
    Select,
    Typography,
} from '../../../../components';
import {CurrentTaskSelectors} from '../../../../features/current-task';
import {LOADING_TYPES, useIsLoading} from '../../../../features/loading';
import {
    SoundDeviceConfiguration,
} from '../../../../features/sound-device-configuration/components/SoundDeviceConfiguration';
import {TaskSelectors} from '../../../../features/task';
import {TaskActions} from '../../../../features/task/store/task.action';
import {useInitialAnimation} from '../../../../hooks';
import {EditIcon, WarningIcon} from '../../../../icons';
import {generateTimeStamps} from '../../../../utils';
import {COCKPIT_ANIMATION_DELAYS, DURATION} from '../../../../utils/animations';

const OverrideExpirationDateForm = ({onClose}) => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.OVERRIDE_EXPIRATION_DATE_FORM);
    const taskId = useSelector(CurrentTaskSelectors.selectCurrentTaskId);
    const task = useSelector(TaskSelectors.selectCurrentTask);

    const {control, handleSubmit: onSubmit, watch, formState: {errors}} = useForm({
        defaultValues: {
            isActive: task?.isExpirationDateOverridden || false,
            date: task?.overriddenExpirationDate ? moment(task.overriddenExpirationDate).toDate() : new Date(),
            time: task?.overriddenExpirationDate ? moment(task.overriddenExpirationDate).format('HH:mm') : '00:00',
        },
    });

    const isActive = watch('isActive');

    const handleSubmit = data => {
        let dateTime = null;

        if (isActive) {
            const date = moment(data.date).format('YYYY-MM-DD');
            dateTime = `${date} ${data.time}`;
        }

        dispatch(TaskActions.overrideExpirationDate(taskId, isActive, dateTime));
    };

    const timestamps = useMemo(() => {
        return generateTimeStamps('00:00', 30, '00:00');
    }, []);

    return (
        <form onSubmit={onSubmit(handleSubmit)}>
            <div className={classes.dialogRoot}>
                <LoadingWithOverlay isLoading={isLoading} size={48} />

                <Typography weight={500} size={16} transform="uppercase" color="stone" variant="h6" hasGutters={false}>
                    Set expiration date
                </Typography>

                <Controller
                    name="isActive"
                    control={control}
                    render={({field}) => (
                        <Checkbox
                            {...field}
                            checked={field.value}
                            className={classes.checkbox}
                            disabled={isLoading}
                        >
                            Override expiration date?
                        </Checkbox>
                    )}
                />

                <Collapse in={isActive} unmountOnExit>
                    <InputGroup inputId="date" label="Date" hasGutterBottom>
                        <Controller
                            name="date"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    id="date"
                                    dateFormat="dd.MM.yyyy"
                                    disabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                        hasError: !!errors.startDate,
                                        helperText: errors?.startDate?.message,
                                    }}
                                />
                            )}
                        />
                    </InputGroup>

                    <InputGroup inputId="time" label="Time" hasGutterBottom>
                        <Controller
                            name="time"
                            control={control}
                            render={({field}) => (
                                <Select
                                    inputId="time"
                                    options={timestamps}
                                    isDisabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </InputGroup>
                </Collapse>

                <div className={classes.actions}>
                    <SecondaryButton onClick={() => onClose()} disabled={isLoading}>
                        Close
                    </SecondaryButton>

                    <PrimaryButton type="submit" disabled={isLoading}>
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </form>
    );
};

OverrideExpirationDateForm.propTypes = {
    onClose: PropTypes.func.isRequired,
};

const OverrideExpirationDateDialog = ({isOpen, onClose}) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <div>
                <OverrideExpirationDateForm onClose={onClose} />
            </div>
        </Modal>
    );
};

OverrideExpirationDateDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const OverrideValidityPeriodForm = ({onClose}) => {
    const dispatch = useDispatch();

    const isLoading = useIsLoading(LOADING_TYPES.OVERRIDE_VALIDITY_PERIOD_FORM);
    const taskId = useSelector(CurrentTaskSelectors.selectCurrentTaskId);
    const task = useSelector(TaskSelectors.selectCurrentTask);

    const {control, handleSubmit: onSubmit, formState: {errors}} = useForm({
        defaultValues: {
            startDate: task?.validFrom ? moment(task.validFrom).toDate() : new Date(),
            startTime: task?.validFrom ? moment(task.validFrom).format('HH:mm') : '00:00',
            endDate: task?.validUntil ? moment(task.validUntil).toDate() : new Date(),
            endTime: task?.validUntil ? moment(task.validUntil).format('HH:mm') : '00:00',
        },
    });

    const handleSubmit = data => {
        dispatch(TaskActions.overrideValidityPeriod(
            taskId,
            data.startDate,
            data.startTime,
            data.endDate,
            data.endTime,
        ));
    };

    const timestamps = useMemo(() => {
        return generateTimeStamps('00:00', 30, '00:00');
    }, []);

    return (
        <form onSubmit={onSubmit(handleSubmit)}>
            <div className={classes.dialogRoot}>
                <LoadingWithOverlay isLoading={isLoading} size={48} />

                <Typography weight={500} size={16} transform="uppercase" color="stone" variant="h6" hasGutters={false}>
                    Set validity period
                </Typography>

                <div className={classes.formRow}>
                    <InputGroup inputId="startDate" label="Start date">
                        <Controller
                            name="startDate"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    id="startDate"
                                    dateFormat="dd.MM.yyyy"
                                    disabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                        hasError: !!errors.startDate,
                                        helperText: errors?.startDate?.message,
                                    }}
                                />
                            )}
                        />
                    </InputGroup>

                    <InputGroup inputId="startTime" label="Start time">
                        <Controller
                            name="startTime"
                            control={control}
                            render={({field}) => (
                                <Select
                                    inputId="startTime"
                                    options={timestamps}
                                    isDisabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                        hasError: !!errors.startDate,
                                        helperText: errors?.startDate?.message,
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </InputGroup>
                </div>

                <div className={classes.formRow}>
                    <InputGroup inputId="endDate" label="End date">
                        <Controller
                            name="endDate"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    id="endDate"
                                    dateFormat="dd.MM.yyyy"
                                    disabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                        hasError: !!errors.endDate,
                                        helperText: errors?.endDate?.message,
                                    }}
                                />
                            )}
                        />
                    </InputGroup>

                    <InputGroup inputId="endTime" label="End time">
                        <Controller
                            name="endTime"
                            control={control}
                            render={({field}) => (
                                <Select
                                    inputId="endTime"
                                    options={timestamps}
                                    isDisabled={isLoading}
                                    InputProps={{
                                        theme: 'dark',
                                        hasError: !!errors.endTime,
                                        helperText: errors?.endTime?.message,
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </InputGroup>
                </div>

                <div className={classes.actions}>
                    <SecondaryButton onClick={() => onClose()} disabled={isLoading}>
                        Close
                    </SecondaryButton>

                    <PrimaryButton type="submit" disabled={isLoading}>
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </form>
    );
};

OverrideValidityPeriodForm.propTypes = {
    onClose: PropTypes.func.isRequired,
};

const OverrideValidityPeriodDialog = ({isOpen, onClose}) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <div>
                <OverrideValidityPeriodForm onClose={onClose} />
            </div>
        </Modal>
    );
};

OverrideValidityPeriodDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const OverrideExpirationDate = () => {
    const task = useSelector(TaskSelectors.selectCurrentTask);

    // const [isDialogOpen, setIsDialogOpen] = useState(false);

    const validUntil = useMemo(() => {
        if (!task?.isReusable) {
            return null;
        }

        return task?.formattedOverriddenExpirationDate || task?.formattedExpirationDate;
    }, [task]);

    if (!task?.isReusable || task?.isAlternative) {
        return null;
    }

    return (
        <div className={classes.expirationDateRoot}>
            <WarningIcon color="yellow" />

            <Typography weight={400} size={13} color="stone" hasLineHeight={false}>
                Task is valid until&nbsp;

                <Typography weight={400} size={13} color="white">
                    {validUntil}
                </Typography>
            </Typography>

            {/* <IconButton */}
            {/*     backgroundColor="transparent" */}
            {/*     color="white" */}
            {/*     size={24} */}
            {/*     iconSize={14} */}
            {/*     hoverStyle="color" */}
            {/*     onClick={() => { */}
            {/*         setIsDialogOpen(true); */}
            {/*     }} */}
            {/* > */}
            {/*     <EditIcon /> */}
            {/* </IconButton> */}

            {/* <OverrideExpirationDateDialog */}
            {/*     isOpen={isDialogOpen} */}
            {/*     onClose={() => setIsDialogOpen(false)} */}
            {/* /> */}
        </div>
    );
};

const OverrideValidityPeriod = () => {
    const task = useSelector(TaskSelectors.selectCurrentTask);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const validFrom = useMemo(() => {
        return task?.formattedValidFrom;
    }, [task]);

    const validUntil = useMemo(() => {
        return task?.formattedValidUntil;
    }, [task]);

    if (!task?.isAlternative) {
        return null;
    }

    return (
        <div className={classes.expirationDateRoot}>
            <WarningIcon color="yellow" />

            <Typography weight={400} size={13} color="stone" hasLineHeight={false}>
                Task is valid from&nbsp;

                <Typography weight={400} size={13} color="white">
                    {validFrom}
                </Typography>

                &nbsp;until&nbsp;

                <Typography weight={400} size={13} color="white">
                    {validUntil}
                </Typography>
            </Typography>

            <IconButton
                backgroundColor="transparent"
                color="white"
                size={24}
                iconSize={14}
                hoverStyle="color"
                onClick={() => {
                    setIsDialogOpen(true);
                }}
            >
                <EditIcon />
            </IconButton>

            {isDialogOpen && (
                <OverrideValidityPeriodDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                />
            )}
        </div>
    );
};

export const Validity = () => {
    const initialAnimation = useInitialAnimation({delay: COCKPIT_ANIMATION_DELAYS.VALIDITY});

    const isLoading = useIsLoading(LOADING_TYPES.COCKPIT_DATA);

    return (
        <div className={classes.root}>
            {!isLoading && (
                <>
                    <Slide direction="down" in={initialAnimation.isVisible} timeout={DURATION}>
                        <div className={classes.content}>
                            <OverrideExpirationDate />
                            <OverrideValidityPeriod />
                        </div>
                    </Slide>

                    <div className={classes.soundDeviceConfiguration}>
                        <SoundDeviceConfiguration />
                    </div>
                </>
            )}
        </div>
    );
};
