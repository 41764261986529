import moment from 'moment';

/**
 * Formats milliseconds as mm:ss
 *
 * @param {number} milliseconds
 * @param {string} format
 * @return {string}
 */
export const formatMillisecondsAsTimestamp = (milliseconds, format = 'mm:ss') => {
    const duration = moment.duration(milliseconds, 'ms').asMilliseconds();

    return moment.utc(duration).format(format);
};
