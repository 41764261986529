/* eslint no-underscore-dangle: 0 */
import {addClassNamesToElement} from '@lexical/utils';
import {DecoratorNode} from 'lexical';
import {VoiceLevelHighIcon, VoiceLevelLowIcon, VoiceLevelMediumIcon} from '../../../icons';

export class VoiceLevelNode extends DecoratorNode {
    /** @internal */
    __level;

    static getType() {
        return 'voice-level';
    }

    static clone(node) {
        return new VoiceLevelNode(node.__level, node.__key);
    }

    constructor(level, key) {
        super(key);
        this.__level = level;
    }

    createDOM(_config, _editor) {
        const element = document.createElement('span');

        addClassNamesToElement(element, _config.theme['voice-level']);

        return element;
    }

    updateDOM(_prevNode, _dom, _config) {
        return false;
    }

    isInline() {
        return true;
    }

    isSelected() {
        return false;
    }

    getLevel() {
        return this.getLatest().__level;
    }

    setLevel(level) {
        this.getWritable().__level = level;
    }

    isKeyboardSelectable() {
        return false;
    }

    isIsolated() {
        return false;
    }

    decorate(editor, config) {
        switch (this.__level) {
            case 1:
                return <VoiceLevelLowIcon size={24} />;
            case 2:
                return <VoiceLevelMediumIcon size={24} />;
            case 3:
                return <VoiceLevelHighIcon size={24} />;
            default:
                return null;
        }
    }

    exportJSON() {
        return {
            key: this.__key,
            type: this.__type,
            level: this.__level,
        };
    }

    static importJSON(serializedNode) {
        return $createVoiceLevelNode(serializedNode.level);
    }
}

export const $createVoiceLevelNode = level => {
    return new VoiceLevelNode(level);
};

export const $isVoiceLevelNode = node => {
    return node instanceof VoiceLevelNode;
};
