import {GenericTaskActionTypes} from './generic-task.action-type';

export const GenericTaskActions = {
    storeGenericProductionOrderIds: ids => ({
        type: GenericTaskActionTypes.STORE_GENERIC_PRODUCTION_ORDER_IDS,
        payload: ids,
    }),
    storeGenericProductionOrders: entities => ({
        type: GenericTaskActionTypes.STORE_GENERIC_PRODUCTION_ORDERS,
        payload: entities,
    }),
    storeGenericTasks: entities => ({
        type: GenericTaskActionTypes.STORE_GENERIC_TASKS,
        payload: entities,
    }),
    storeGenericTaskVariations: entities => ({
        type: GenericTaskActionTypes.STORE_GENERIC_TASK_VARIATIONS,
        payload: entities,
    }),
    setSelectedGenericTaskVariationId: selectedGenericTaskVariationId => ({
        type: GenericTaskActionTypes.SET_SELECTED_GENERIC_TASK_VARIATION_ID,
        payload: selectedGenericTaskVariationId,
    }),
    setIsControllerOpen: isControllerOpen => ({
        type: GenericTaskActionTypes.SET_IS_CONTROLLER_OPEN,
        payload: isControllerOpen,
    }),
    setGenericProductionOrderExpansion: ({genericProductionOrderId, isExpanded}) => ({
        type: GenericTaskActionTypes.SET_GENERIC_PRODUCTION_ORDER_EXPANSION,
        payload: {
            genericProductionOrderId,
            isExpanded,
        },
    }),
    setGenericTaskExpansion: ({genericTaskId, isExpanded}) => ({
        type: GenericTaskActionTypes.SET_GENERIC_TASK_EXPANSION,
        payload: {
            genericTaskId,
            isExpanded,
        },
    }),
    goToPreviousGenericTaskVariation: () => ({
        type: GenericTaskActionTypes.GO_TO_PREVIOUS_GENERIC_TASK_VARIATION,
    }),
    goToNextGenericTaskVariation: () => ({
        type: GenericTaskActionTypes.GO_TO_NEXT_GENERIC_TASK_VARIATION,
    }),
    scheduleUpload: ({blob}) => ({
        type: GenericTaskActionTypes.SCHEDULE_UPLOAD,
        payload: {
            blob,
        },
    }),
    upload: ({genericTaskVariationId, blob}) => ({
        type: GenericTaskActionTypes.UPLOAD,
        payload: {
            genericTaskVariationId,
            blob,
        },
    }),
};
