import {all, call, delay, fork, put} from 'redux-saga/effects';
import {CONFIG} from './config';

import {ROUTES} from './config/routes';
import {audioItemSaga} from './features/audio-item/store/audio-item.saga';
import {authRootSaga} from './features/auth';
import {broadcastChannelRootSaga} from './features/broadcast-channel/store/broadcast-channel.saga';
import {channelRootSaga} from './features/channel';
import {currentTaskSaga} from './features/current-task/store/current-task.saga';
import {editorSaga} from './features/editor/store/editor.saga';
import {failedUploadsSaga} from './features/failed-uploads/store/failed-uploads.saga';
import {genericTaskRootSaga} from './features/generic-task/store/generic-task.saga';
import {filterRootSaga} from './features/home-filters';
import {logTailSagas} from './features/log-tail/store/log-tail.saga';
import {mixingPlanSagas} from './features/mixing-plan/store/mixing-plan.saga';
import {playerRootSaga} from './features/player/store/player.saga';
import {playlistRootSaga} from './features/playlist/store/playlist.saga';
import {preloadingSaga} from './features/preloading/store/preloading.saga';
import {preparedTaskVariationSagas} from './features/prepared-task-variation/store/prepared-task-variation.sagas';
import {presetSaga} from './features/preset/store/preset.saga';
import {taskOverviewSaga} from './features/task-overview/store/task-overview.saga';
import {taskRootSaga} from './features/task/store/task.saga';
import {webSocketSaga} from './features/web-socket/store/web-socket.saga';
import {Debug} from './lib/debug';
import {routerSaga} from './router.saga';
import {cockpitSaga} from './screens/cockpit/store/cockpit.saga';

const appVersionCheckSaga = function* () {
    while (true) {
        const currentVersion = CONFIG.APP_VERSION;

        if (currentVersion === 'development') {
            break;
        }

        Debug.debug('appVersionCheckSaga', 'Checking for version...');

        const result = yield call(fetch, window.location.origin);
        const html = yield call([result, result.text]);

        const parser = new DOMParser();
        const document = parser.parseFromString(html, 'text/html');

        let hasNewVersion = false;

        document.querySelector('head').childNodes.forEach(node => {
            if (node.nodeName === 'META' && node.getAttribute('name') === 'version') {
                const newVersion = node.getAttribute('content');

                if (newVersion !== currentVersion) {
                    hasNewVersion = true;
                }
            }
        });

        if (hasNewVersion) {
            yield put({
                type: 'SET_HAS_NEW_VERSION',
                payload: true,
            });
        }

        yield delay(30 * 60 * 1000); // 30 minutes
    }
};

export const rootSaga = function* () {
    yield all([
        fork(audioItemSaga),
        fork(authRootSaga),
        fork(broadcastChannelRootSaga),
        fork(channelRootSaga),
        fork(cockpitSaga),
        fork(currentTaskSaga),
        fork(editorSaga),
        fork(failedUploadsSaga),
        fork(filterRootSaga),
        fork(genericTaskRootSaga),
        fork(mixingPlanSagas),
        fork(playerRootSaga),
        fork(playlistRootSaga),
        fork(preloadingSaga),
        fork(preparedTaskVariationSagas),
        fork(taskRootSaga),
        fork(webSocketSaga),
        fork(presetSaga),
        fork(logTailSagas),
        fork(taskOverviewSaga),
        fork(appVersionCheckSaga),
    ]);

    yield fork(routerSaga, ROUTES);
};
