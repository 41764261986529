import {SvgIcon} from '../SvgIcon/SvgIcon';

export const TaskDoneIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path d="M14.4 10.699a5.002 5.002 0 0 0-1.8-.183V6.3H8.1V1.8H1.8v14.4h6.249c.09.648.305 1.256.62 1.8H1.8A1.8 1.8 0 0 1 0 16.2V1.8A1.8 1.8 0 0 1 1.8 0H9l5.4 5.4v5.299zM3.6 9h7.2v1.8H3.6V9zm0 3.6h4.5v1.8H3.6v-1.8z" />
            <path fill="#00BC3F" d="M13.605 17.836L11 14.994l1.099-1.1 1.506 1.507L17.006 12l1.1 1.336z" />
        </SvgIcon>
    );
};
