import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useDispatch} from 'react-redux';
import classes from './TaskDeletedModal.module.scss';
import {PrimaryButton, Typography} from '../../../../components';
import {CockpitNavigationSelectors} from '../../../../features/cockpit-navigation';
import {TaskActions} from '../../../../features/task/store/task.action';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {ModalKeys} from '../../../../features/ui/utils/constants';
import {cockpitWindowController} from '../../../../features/window-manager';
import {useMemoizedCreateSelector} from '../../../../hooks';

export const TaskDeletedModal = () => {
    const dispatch = useDispatch();

    const isOpen = useMemoizedCreateSelector(UiSelectors.createActiveModalSelector, ModalKeys.TASK_DELETED_MODAL);

    const nextTask = useMemoizedCreateSelector(CockpitNavigationSelectors.createNextTaskSelector);

    return (
        <Modal
            open={isOpen}
            onClose={() => {}}
            BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
            closeAfterTransition
        >
            <Fade in={isOpen} mountOnEnter unmountOnExit>
                <div>
                    <div className={classes.root}>
                        <Typography transform="uppercase" weight={500} color="regent" className={classes.title} variant="h1">
                            Task deleted...
                        </Typography>

                        <Typography size={14} weight={500} color="white" variant="body" hasGutters={false}>
                            Oopsie! The task that you are currently working on has been deleted.
                        </Typography>

                        <div className={classes.actions}>
                            <PrimaryButton
                                onClick={() => {
                                    cockpitWindowController.closeWindow();

                                    dispatch(TaskActions.goToNextJob());
                                    dispatch(UiActions.setModalState(ModalKeys.TASK_DELETED_MODAL, false));
                                }}
                            >
                                {nextTask ? 'Go to next task' : 'Close window'}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
