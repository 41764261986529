import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './Tabs.module.scss';

export const Tab = ({label, onClick, isActive}) => {
    return (
        <button
            type="button"
            className={clsx(classes.tab, {
                [classes.active]: isActive,
            })}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

Tab.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export const TabPanel = ({children}) => {
    return (
        <div className={classes.tabPanel}>
            {children}
        </div>
    );
};

export const TabPanels = ({tabPanels, activeTab}) => {
    return (
        <div className={classes.tabPanels}>
            {tabPanels.map(tabPanel => {
                if (tabPanel.id !== activeTab) {
                    return null;
                }

                return React.createElement(tabPanel.component, {
                    key: tabPanel.id,
                    ...(tabPanel.props || {}),
                });
            })}
        </div>
    );
};

TabPanels.propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tabPanels: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })).isRequired,
};

export const Tabs = ({tabs, activeTab, onChange}) => {
    return (
        <div className={classes.root}>
            <div className={classes.tabsWrapper}>
                {tabs.map(tab => {
                    return React.createElement(tab.TabComponent || Tab, {
                        key: tab.id,
                        isActive: tab.id === activeTab,
                        onClick: () => onChange(tab.id),
                        label: tab.label,
                        ...(!tab.TabComponent ? {} : {...tab.TabProps}),
                    });
                })}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
        TabComponent: PropTypes.func,
        TabProps: PropTypes.object,
    })).isRequired,
};

Tabs.defaultProps = {};
