import {Preset, PresetDto} from './preset.dto';
import {ddsPlanningApi} from '../../../lib/axios';

const EXTERNAL_PRESET_TYPES = {
    preProduced: 'pre-produced-elements',
    sfx: 'sound-effects',
    bed: 'music-bed',
};

const getPresets = ({brandId, presetType}) => {
    return ddsPlanningApi
        .get(`/v1/presets/type/${EXTERNAL_PRESET_TYPES[presetType]}/brand/${brandId}`)
        .then(result => {
            let entities = {};

            result.data.forEach(dto => {
                entities = {
                    ...entities,
                    [dto.id]: Preset.fromDto(dto),
                };
            });

            return entities;
        });
};

const createPreset = ({brandId, audioType, name, elementIds = []}) => {
    return ddsPlanningApi
        .post(`/v1/presets`, new PresetDto({name, brandId, audioType, elementIds}))
        .then(result => Preset.fromDto(result.data));
};

const updatePreset = ({presetId, brandId, audioType, name, elementIds = []}) => {
    return ddsPlanningApi
        .put(`/v1/presets/${presetId}`, new PresetDto({name, brandId, audioType, elementIds}))
        .then(result => Preset.fromDto(result.data));
};

const deletePreset = ({presetId}) => {
    return ddsPlanningApi.delete(`/v1/presets/${presetId}`);
};

export const PresetApi = {
    getPresets,
    createPreset,
    updatePreset,
    deletePreset,
};
