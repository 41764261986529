const DefaultData = {
    previousElement: {
        duckDuration: 0.3,
        duckVolume: 0.6,
        fadeOutOnSkipDuration: 1.5,
        startVolume: 1,
    },
    nextElement: {
        duckDuration: 0.3,
        duckVolume: 1,
        fadeOutOnSkipDuration: 1.5,
        startVolume: 0.6,
    },
    preProduced: {
        defaultVolume: 1,
        fadeOutOnSkipDuration: 1.5,
        loudVolume: 1.2,
        normalVolume: 1,
        lowVolume: 0.6,
        muteVolume: 0,
    },
    sfx: {
        defaultVolume: 1,
        fadeOutOnSkipDuration: 1.5,
        loudVolume: 1,
        normalVolume: 0.6,
        lowVolume: 0.3,
        muteVolume: 0,
    },
    bed: {
        defaultVolume: 1,
        fadeOutOnSkipDuration: 1.5,
        loudVolume: 1.2,
        normalVolume: 1,
        lowVolume: 0.6,
        muteVolume: 0,
        previousElementDuckVolume: 0,
        previousElementFadeInDuration: 1.5,
        previousElementFadeOutDuration: 1.5,
    },
};

export const SoundSettings = function (data = DefaultData) {
    this.previousElement = data.previousElement;
    this.nextElement = data.nextElement;
    this.preProduced = data.preProduced;
    this.sfx = data.sfx;
    this.bed = data.bed;
};

SoundSettings.fromDto = function (dto) {
    const {bumper, stinger, preProducedElements, soundEffects, musicBeds} = dto;

    const previousElement = {
        duckDuration: bumper?.duckDuration ? bumper.duckDuration / 1000 : 0.3,
        duckVolume: bumper?.duckVolume ?? 0.6,
        fadeOutOnSkipDuration: bumper?.fadeOutOnSkipDuration ? bumper.fadeOutOnSkipDuration / 1000 : 1.5,
        startVolume: bumper?.startVolume ?? 1,
    };

    const nextElement = {
        duckDuration: stinger?.duckDuration ? stinger.duckDuration / 1000 : 0.3,
        duckVolume: stinger?.duckVolume ?? 1,
        fadeOutOnSkipDuration: stinger?.fadeOutOnSkipDuration ? stinger.fadeOutOnSkipDuration / 1000 : 1.5,
        startVolume: stinger?.startVolume ?? 0.6,
    };

    const preProduced = {
        defaultVolume: preProducedElements?.defaultVolume ?? 1,
        fadeOutOnSkipDuration: preProducedElements?.fadeOutOnSkipDuration
            ? preProducedElements.fadeOutOnSkipDuration / 1000
            : 1.5,
        loudVolume: preProducedElements?.loudVolume ?? 1.2,
        normalVolume: preProducedElements?.normalVolume ?? 1,
        lowVolume: preProducedElements?.lowVolume ?? 0.6,
        muteVolume: preProducedElements?.muteVolume ?? 0,
    };

    const sfx = {
        defaultVolume: soundEffects?.defaultVolume ?? 1,
        fadeOutOnSkipDuration: soundEffects?.fadeOutOnSkipDuration ? soundEffects.fadeOutOnSkipDuration / 1000 : 1.5,
        loudVolume: soundEffects?.loudVolume ?? 1,
        normalVolume: soundEffects?.normalVolume ?? 0.6,
        lowVolume: soundEffects?.lowVolume ?? 0.3,
        muteVolume: soundEffects?.muteVolume ?? 0,
    };

    const bed = {
        defaultVolume: musicBeds?.defaultVolume ?? 1,
        fadeOutOnSkipDuration: musicBeds?.fadeOutOnSkipDuration ? musicBeds.fadeOutOnSkipDuration / 1000 : 1.5,
        loudVolume: musicBeds?.loudVolume ?? 1.2,
        normalVolume: musicBeds?.normalVolume ?? 1,
        lowVolume: musicBeds?.lowVolume ?? 0.6,
        muteVolume: musicBeds?.muteVolume ?? 0,
        previousElementDuckVolume: musicBeds?.duckVolume ?? 0,
        previousElementFadeInDuration: musicBeds?.fadeInTime ? musicBeds.fadeInTime / 1000 : 1.5,
        previousElementFadeOutDuration: musicBeds?.fadeOutTime ? musicBeds.fadeOutTime / 1000 : 1.5,
    };

    return new SoundSettings({
        ...(previousElement && {previousElement}),
        ...(nextElement && {nextElement}),
        ...(preProduced && {preProduced}),
        ...(sfx && {sfx}),
        ...(bed && {bed}),
    });
};
