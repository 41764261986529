export const formatRestrictions = (brandName, channelName, weekday, dayPart) => {
    const orderedRestrictions = [];

    if (brandName) {
        orderedRestrictions.push(brandName);
    }

    if (channelName) {
        orderedRestrictions.push(channelName);
    }

    if (weekday) {
        orderedRestrictions.push(weekday);
    }

    if (dayPart) {
        orderedRestrictions.push(dayPart);
    }

    return orderedRestrictions.join(' / ');
};
