import {useEffect, useState} from 'react';
import {SUBSCRIPTION_TYPES} from '../../../constants';
import recordingAlgorithm from '../../../features/recording-algorithm/RecordingAlgorithm';

export const useDisabledControls = () => {
    const [areControlsDisabled, setAreControlsDisabled] = useState(false);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(false);

    useEffect(() => {
        const onUpdate = ({areControlsDisabled, isResetButtonDisabled}) => {
            setAreControlsDisabled(areControlsDisabled);
            setIsResetButtonDisabled(isResetButtonDisabled);
        };

        const subscriber = recordingAlgorithm.subscribe(onUpdate, SUBSCRIPTION_TYPES.DISABLE_RECORDING_CONTROLS);

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return {areControlsDisabled, isResetButtonDisabled};
};
