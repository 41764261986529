import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classes from './PreloadingStatus.module.scss';
import {Container, PrimaryButton} from '../../../../components';
import {ErrorIcon} from '../../../../icons';
import {PreloadingActions} from '../../store/preloading.action';
import {PreloadingSelectors} from '../../store/preloading.selector';
import {PreloadingStatuses} from '../../utils/constants';

export const PreloadingStatus = () => {
    const {t} = useTranslation('featuresPreloading');

    const dispatch = useDispatch();

    const status = useSelector(PreloadingSelectors.selectStatus);
    const progress = useSelector(PreloadingSelectors.selectProgress);
    const remainingWaitTime = useSelector(PreloadingSelectors.selectRemainingWaitTime);

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.topContent}>
                    <div className={classes.iconWrapper}>
                        <ErrorIcon size={24} className={classes.icon} />
                    </div>

                    <div className={classes.messageWrapper}>
                        <div className={classes.title}>
                            {t('alertTitle')}
                        </div>

                        <div>
                            {t(`statuses.${status}`, {remainingWaitTime})}
                        </div>
                    </div>

                    <div className={classes.actionWrapper}>
                        <PrimaryButton
                            onClick={() => dispatch(PreloadingActions.cancel())}
                            disabled={status !== PreloadingStatuses.WAITING && status !== PreloadingStatuses.RUNNING}
                        >
                            {t('cancelButtonTitle')}
                        </PrimaryButton>
                    </div>
                </div>

                <Collapse in={status === PreloadingStatuses.RUNNING}>
                    <div className={classes.progressWrapper}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: 4,
                                borderRadius: 0,
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: 'transparent',
                                },
                                [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: '#0080ff',
                                },
                            }}
                        />
                    </div>
                </Collapse>
            </Container>
        </div>
    );
};
