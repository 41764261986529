import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {useFirstMountState} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import classes from './SoundDeviceConfiguration.module.scss';
import {IconButton, InputGroup, Select, Typography} from '../../../components';
import {CheckIcon, CloseIcon, CogIcon} from '../../../icons';
import playbackAlgorithm from '../../recording-algorithm/PlaybackAlgorithm';
import recordingAlgorithm from '../../recording-algorithm/RecordingAlgorithm';
import {getDevices} from '../utils/get-devices';
import {getIsUsingPreferredDevices} from '../utils/get-used-devices';

const SoundDeviceConfigurationForm = props => {
    const {
        inputDevices,
        outputDevices,
        setIsDialogOpen,
        inputDeviceId,
        outputDeviceId,
        onSave,
        onChange,
        children,
    } = props;

    const isFirstMount = useFirstMountState();

    const {
        handleSubmit: createSubmitHandler,
        control,
        watch,
    } = useForm({
        defaultValues: {
            inputDevice: inputDeviceId,
            outputDevice: outputDeviceId,
        },
    });

    const selectedInputDeviceId = watch('inputDevice');
    const selectedOutputDeviceId = watch('outputDevice');

    useEffect(() => {
        if (isFirstMount) {
            return;
        }

        onChange({
            inputDeviceId: selectedInputDeviceId,
            outputDeviceId: selectedOutputDeviceId,
        });
        // eslint-disable-next-line
    }, [selectedInputDeviceId, selectedOutputDeviceId]);

    const handleSubmit = data => {
        if (data.inputDevice) {
            localStorage.setItem('inputDeviceId', data.inputDevice);
        }

        if (data.outputDevice) {
            localStorage.setItem('outputDeviceId', data.outputDevice);
            recordingAlgorithm.setOutputDevice(data.outputDevice);
            playbackAlgorithm.setOutputDevice(data.outputDevice);
        }

        setIsDialogOpen(false);

        onSave();
    };

    return (
        <form onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <InputGroup label="Input Device" inputId="inputDevice" hasGutterBottom>
                <Controller
                    control={control}
                    name="inputDevice"
                    render={({field}) => (
                        <Select
                            inputId="inputDevice"
                            options={inputDevices}
                            InputProps={{
                                theme: 'dark',
                                autoComplete: 'off',
                            }}
                            {...field}
                        />
                    )}
                />
            </InputGroup>

            <InputGroup label="Output Device" inputId="outputDevice">
                <Controller
                    control={control}
                    name="outputDevice"
                    render={({field}) => (
                        <Select
                            inputId="outputDevice"
                            options={outputDevices}
                            InputProps={{
                                theme: 'dark',
                                autoComplete: 'off',
                            }}
                            {...field}
                        />
                    )}
                />
            </InputGroup>

            {children}

            <div className={classes.actions}>
                <IconButton
                    backgroundColor="mako"
                    color="white"
                    iconSize={20}
                    className={classes.actionButton}
                    onClick={() => setIsDialogOpen(false)}
                >
                    <CloseIcon />
                </IconButton>

                <IconButton
                    type="submit"
                    backgroundColor="dodger"
                    color="white"
                    iconSize={20}
                    className={classes.actionButton}
                >
                    <CheckIcon />
                </IconButton>
            </div>
        </form>
    );
};

SoundDeviceConfigurationForm.propTypes = {
    setIsDialogOpen: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    inputDevices: PropTypes.arrayOf(PropTypes.object),
    outputDevices: PropTypes.arrayOf(PropTypes.object),
    inputDeviceId: PropTypes.string,
    outputDeviceId: PropTypes.string,
};

SoundDeviceConfigurationForm.defaultProps = {
    inputDevices: [],
    outputDevices: [],
    inputDeviceId: null,
    outputDeviceId: null,
};

export const SoundDeviceConfiguration = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isGettingDevices, setIsGettingDevices] = useState(true);
    const [isUsingPreferredDevices, setIsUsingPreferredDevices] = useState(true);
    const [refreshWarning, setRefreshWarning] = useState(false);
    const [isReloadRequired, setIsReloadRequired] = useState(false);
    const [devices, setDevices] = useState({
        inputDevices: [],
        outputDevices: [],
        inputDeviceId: null,
        outputDeviceId: null,
    });

    useEffect(() => {
        setIsGettingDevices(true);

        getDevices().then(result => {
            setDevices({...result});

            setIsGettingDevices(false);
        });

        getIsUsingPreferredDevices().then(result => {
            setIsUsingPreferredDevices(result);
        });
    }, [refreshWarning]);

    return (
        <div className={classes.root}>
            <Fade in={!isUsingPreferredDevices}>
                <div>
                    <Typography
                        color="monza"
                        weight={500}
                        hasLineHeight={false}
                        size={13}
                        variant="body"
                    >
                        Please, choose your studio devices.
                    </Typography>
                </div>
            </Fade>

            <IconButton
                backgroundColor="transparent"
                color="white"
                onClick={() => setIsDialogOpen(true)}
            >
                <CogIcon />
            </IconButton>

            <Modal
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
                closeAfterTransition
            >
                <Fade in={isDialogOpen} mountOnEnter unmountOnExit>
                    <div className={classes.contentRoot}>
                        <Typography
                            transform="uppercase"
                            weight={500}
                            color="regent"
                            className={classes.title}
                            variant="h1"
                        >
                            Choose your studio devices
                        </Typography>

                        <Fade in={!isGettingDevices} enter={false} appear={false} mountOnEnter unmountOnExit>
                            <div>
                                <SoundDeviceConfigurationForm
                                    inputDevices={devices.inputDevices}
                                    outputDevices={devices.outputDevices}
                                    inputDeviceId={devices.inputDeviceId}
                                    outputDeviceId={devices.outputDeviceId}
                                    setIsDialogOpen={setIsDialogOpen}
                                    onChange={({inputDeviceId}) => {
                                        if (inputDeviceId !== devices.inputDeviceId) {
                                            setIsReloadRequired(true);
                                        } else {
                                            setIsReloadRequired(false);
                                        }
                                    }}
                                    onSave={() => {
                                        setRefreshWarning(prevState => !prevState);

                                        if (isReloadRequired) {
                                            window.location.reload();
                                        }
                                    }}
                                >
                                    <Collapse in={isReloadRequired}>
                                        <div>
                                            <Typography
                                                className={classes.reloadWarning}
                                                weight={500}
                                                size={14}
                                                color="monza"
                                                align="center"
                                                variant="body"
                                            >
                                                In order to implement the changes, the application will be reloaded upon
                                                saving the settings.
                                            </Typography>
                                        </div>
                                    </Collapse>
                                </SoundDeviceConfigurationForm>
                            </div>
                        </Fade>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
