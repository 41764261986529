import {SoundSettings} from './sound-settings.dto';
// import {ddsPlanningApi} from '../../../lib/axios';
//
// const cache = {};

const getSoundSettings = ({channelId}) => {
    return SoundSettings.fromDto({});

    // Temporary disabled
    // if (cache[channelId]) {
    //     return Promise.resolve(cache[channelId]);
    // }
    //
    // return ddsPlanningApi
    //     .get(`/v1/sound-settings/channel/${channelId}`)
    //     .then(result => {
    //         const data = SoundSettings.fromDto(result.data);
    //
    //         cache[channelId] = data;
    //
    //         return data;
    //     });
};

export const SoundSettingsApi = {
    getSoundSettings,
};
