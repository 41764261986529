import React from 'react';
import {SvgIcon} from '../SvgIcon/SvgIcon';

export const ArrowLeft = React.forwardRef((props, ref) => {
    return (
        <SvgIcon {...props} ref={ref} viewBox="0 0 20 18">
            <path fillRule="evenodd" transform="matrix(-1 0 0 1 19.1 0)" d="M-.045 9.882h14.313l-5.556 5.556 1.424 1.434 8-8-8-8-1.424 1.434 5.556 5.556H-.045z" />
        </SvgIcon>
    );
});
